import {IconWrap, IconImg} from './IconButtonStyles'
const IconButton = (props) => {

    const {onClick, width, height, src, wrapperStyle, imgStyle} = props;

    const onClickHandler = (e) => {
        if(onClick) onClick(e);
    }

    return(
        <IconWrap width={width} height={height} onClick={onClick ? onClickHandler : null} style={wrapperStyle}>
            <IconImg
                src={src}
                style={imgStyle}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.style.display = 'none';
                }}
                onLoad={({ currentTarget }) => {
                    currentTarget.onload = null; // prevents looping
                    currentTarget.style.display = 'block';
                }}
            />
        </IconWrap>
    )
}

IconButton.defaultProps = {
    onClick : null,
    width : '',
    height : '',
    src : '',
    wrapperStyle : null,
    imgStyle : null

}
export default IconButton;