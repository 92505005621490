import React, {useEffect, useState} from 'react';
import {
    GroupsTopWrapStyle,
    GroupsTopListStyle,
    GroupsTopTitleStyle,
    GroupsTopTitleImgStyle,
    GroupsTopTitleImageWrap,
    GroupsTopTitleImportantStyle,
    GroupsTopListStyleWrap,
    GroupsTopListTitleStyle,
    GroupsTopListEntireImgStyle,
} from "./GroupsTopStyles";
import {Link} from 'react-router-dom';
import groupsImg from "../../../resources/mImages/participation_bg.png";
import iconAll from "../../../resources/images/icon_all.png";
import GroupsTopListComponent from "../groups/GroupsTopListComponent";
import {useStores} from "../../../stores";
import routes from "../../../constants/routes.json";
import {useTranslation} from "react-i18next";

const GroupsTopComponent = (props) => {

    const {store} = useStores();
    const {itemStore} = store;
    const onItemPopClick = props.onItemPopClick;
    const {t} = useTranslation();

    /**
     * 통계
     */
    const [groupsUserCount, setGroupsUserCount] = useState(0);
    const [groupsCount, setGroupsCount] = useState(0);

    /**
     * 사용자 가입한 그룹
     * @type {number|*}
     */
    const [groupList, setGroupList] = useState([]);
    const [selectGroupList, setSelectGroupList] = useState([]);

    /**
     * 페이지
     */
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);

    /******************************************************************************************************************/

    /**
     * 01. 이벤트
     *
     */

    /**
     * 이벤트 버튼
     */
    const buttonPrevPageEvent = () => {
        if (prevPage !== 1) {
            setPrevPage(prevPage - 1);
            groupListSelect(prevPage - 1);
        }
    }

    /**
     * 이벤트 버튼
     */
    const buttonNextPageEvent = () => {
        if (prevPage !== nextPage) {
            setPrevPage(prevPage + 1);
            groupListSelect(prevPage + 1);
        }
    };

    /******************************************************************************************************************/

    /**
     * 02. 함수
     *
     */

    /**
     * 그룹 리스트
     */
    const groupListSelect = (page) => {

        // 0개이상 3개씩 자르기
        if (groupList.length > 0) {
            setSelectGroupList(groupList.slice(3 * (page - 1), (3 * page)));
        }
    };

    /**
     * 그룹 리스트 페이지 수 체크
     */
    const checkPageList = (list, joinGroupsYn) => {

        const length = list.length;

        // 내가 가입 하지 않은 그룹
        if (joinGroupsYn === 'N') {
            // 최대 3페이지까지
            const maxLengthPage = 3;
            if (length > 3 && length <= 9) {
                let nextPage = Math.floor(length / 3);
                if (length % 3 > 0) {
                    nextPage++;
                }
                setNextPage(nextPage);
            }else if (length > 9) { // 최대 3페이지까지
                setNextPage(maxLengthPage);
            }
        }else {
            // 최대 5페이지까지
            const maxLengthPage = 5;
            if (length > 3 && length <= 15) {
                let nextPage = Math.floor(length / 3);
                if (length % 3 > 0) {
                    nextPage++;
                }
                setNextPage(nextPage);
            }else if (length > 15) { // 최대 5페이지까지
                setNextPage(maxLengthPage);
            }
        }
    };

    /******************************************************************************************************************/

    /**
     * 03. 컴포넌트
     *
     */

    /**
     * 토픽 아이템
     */

    /* ListComponent
     * 추천 그룹 map 3가지 가져오기 */
    const GroupComponentList = () => (selectGroupList || []).map((item) => {
        return <GroupsTopListComponent item={item}
                                       joinGroupsYn={
                                           props.joinGroups?.length !== 0 &&
                                           (itemStore.recommendMyGroupItems && itemStore.recommendMyGroupItems?.list?.length > 0) ? 'Y' : 'N'}
                                       onClick={onItemPopClick}/>;
    });


    /******************************************************************************************************************/

    /**
     * 04. useEffect
     *
     */

    // 01. 통계 처음 세팅
    useEffect(()=>{
        // 그룹통계 동기식 가져왔을 경우
        if (props.groupStats?.group !== undefined ) {
            setGroupsUserCount(props.groupStats['group-user']);
            setGroupsCount(props.groupStats.group);
        }
    },[props.groupStats]);

    // 02. 내가 가입한 그룹 있을 그룹리스트 처음 경우 세팅 (초기세팅)
    useEffect(()=>{
        // 가입한 그룹 없을 경우  (추천 그룹 가져오기)
        if(!props.joinGroups || !props.recommendAllGroupItems) return;

        // 가입한 그룹 있을 경우  (추천 그룹 가져오기)
        if (props.joinGroups?.length !== 0) {
            // 추천 나의 그룹 아이템
            itemStore.selectRecommendMyGroupItems({
                    page : 0,
                    size : 15
                }, ()=>{

                if(itemStore.recommendMyGroupItems.list.length === 0){
                    if (props.recommendAllGroupItems.length !== 0){
                        setGroupList(props.recommendAllGroupItems);
                        checkPageList(props.recommendAllGroupItems, 'N');
                        setSelectGroupList(props.recommendAllGroupItems.slice(0, 5));
                    }
                }else{
                    setGroupList(itemStore.recommendMyGroupItems.list);
                    checkPageList(itemStore.recommendMyGroupItems.list, 'Y');
                    setSelectGroupList(itemStore.recommendMyGroupItems.list.slice(0, 5));
                }
            });
        }else if(props.joinGroups?.length === 0) {
            // 추천 그룹 아이템 있을 경우
            if(itemStore.recommendMyGroupItems?.list?.length > 0) itemStore.setRecommendMyGroupItems(null);
            if (props.recommendAllGroupItems.length !== 0){
                setGroupList(props.recommendAllGroupItems);
                checkPageList(props.recommendAllGroupItems, 'N');
                setSelectGroupList(props.recommendAllGroupItems.slice(0, 5));
            }
        }

    },[props.joinGroups, props.recommendAllGroupItems]);

    // // 03. 내가 가입한 그룹 없을 경우 그룹리스트 처음 세팅 (초기세팅)
    // useEffect(()=>{
    //     // 가입한 그룹 없을 경우  (추천 그룹 가져오기)
    //     if (props.joinGroups?.length === 0) {
    //         // 추천 그룹 아이템 있을 경우
    //         if (props.recommendAllGroupItems.length !== 0){
    //             setGroupList(props.recommendAllGroupItems);
    //             checkPageList(props.recommendAllGroupItems, 'N');
    //             setSelectGroupList(props.recommendAllGroupItems.slice(0, 3));
    //         }
    //     }
    // },[props.recommendAllGroupItems]);


    return (

        <GroupsTopWrapStyle>

            {/* @todo
            - 가입한 그룹이 있고, 그룹 추천글이 있는 경우에만 안보여줌

            */}

            {/** 헤더 그룹 리스트 **/}
            {   (props.joinGroups === null) ? null :

                    <GroupsTopListStyleWrap className = {
                        props.joinGroups?.length !== 0 && (itemStore.recommendMyGroupItems && itemStore.recommendMyGroupItems?.list?.length > 0)
                            ? 'isMyGroups' : '' }>
                        {/** 타이틀 TEXT **/}
                        <GroupsTopListTitleStyle>
                            {
                                props.joinGroups ?
                                    (props.joinGroups?.length !== 0 && (itemStore.recommendMyGroupItems && itemStore.recommendMyGroupItems?.list?.length > 0)) ?
                                        t("MY_GROUP_NEW_ITEMS") :
                                            (props.joinGroups?.length !== 0 && (itemStore.recommendMyGroupItems && itemStore.recommendMyGroupItems?.list?.length === 0 ) && itemStore.recommendAllGroupItems && itemStore.recommendAllGroupItems?.length > 0) ? t("IN_GROUPS") :
                                                (props.joinGroups?.length === 0 && (itemStore.recommendAllGroupItems && itemStore.recommendAllGroupItems?.length > 0)) ?
                                                    t("IN_GROUPS") : null : null

                            }
                        </GroupsTopListTitleStyle>

                        {/** 전체보기 (((( 내가 가입 한 그룹 있을 경우만))))) **/}
                        {props.joinGroups?.length !== 0 && (itemStore.recommendMyGroupItems && itemStore.recommendMyGroupItems?.list?.length > 0) ?
                            <Link to={{pathname: `${routes.GROUP_ITEMS}`, state: { data: `${routes.GROUP_ITEMS}` } }}>
                                {t('SHOW_ALL')}
                                <GroupsTopListEntireImgStyle src={iconAll}/>
                            </Link> : null
                        }

                        {/** 추천 그룹 리스트 **/}
                        <GroupsTopListStyle>
                            {GroupComponentList()}
                        </GroupsTopListStyle>

                        {/** 버튼 **/}
                        {/*<GroupsTopListButtonStyle>*/}
                        {/*    <GroupsGatherBestPrev src={groupsPrevImg} prevPage={prevPage} onClick={buttonPrevPageEvent}/>*/}
                        {/*    <GroupsGatherBestPrevPage>*/}
                        {/*        {prevPage + '/'}*/}
                        {/*    </GroupsGatherBestPrevPage>*/}
                        {/*    <GroupsGatherBestPrevPage>*/}
                        {/*        {nextPage}*/}
                        {/*    </GroupsGatherBestPrevPage>*/}
                        {/*    <GroupsGatherBestNext src={groupsNextImg} prevPage={prevPage} nextPage={nextPage} onClick={buttonNextPageEvent}/>*/}
                        {/*</GroupsTopListButtonStyle>*/}

                    </GroupsTopListStyleWrap>
            }

            {/** 헤더 그룹 통계 **/}
            {
                (props.invitingGroups?.length !== 0 || (props.joinGroups?.length !== 0 && itemStore.recommendMyGroupItems?.list.length !== 0) ) ? null :
                    <GroupsTopTitleImageWrap>
                        <GroupsTopTitleImgStyle src = {groupsImg} />
                        <GroupsTopTitleStyle>
                            <GroupsTopTitleImportantStyle>{`${groupsCount}${t('NUMBER_CNT')}`}</GroupsTopTitleImportantStyle>
                            {t('IN_GROUP') + ' '}
                            <GroupsTopTitleImportantStyle>{`${groupsUserCount}${t('USER_CNT')}`}</GroupsTopTitleImportantStyle>
                            {t('COWORKER_ARE_JOINING')}
                        </GroupsTopTitleStyle>
                    </GroupsTopTitleImageWrap>
            }

        </GroupsTopWrapStyle>
    )
}

export default GroupsTopComponent;