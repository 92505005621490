import {ChipsImg, ChipsText, ChipsWrap} from "./ChipsStyles";
import {ServerConfig} from "../../../commons/config";

const Chips = (props) => {

    const {type, id, key, onClick, selected, disabled, imgId} = props;

    const onClickHandler = (e) => {
        if(disabled) return;
        if(onClick && typeof onClick === 'function') onClick(e);
    }

    return(
        <ChipsWrap id = {id} key = {key} type={type} selected={selected} disabled={disabled} onClick={onClickHandler}>
            {imgId !== null ? <ChipsImg src={`${ServerConfig.default.IMG_URL}${imgId}`} selected={selected}/> : null }
            <ChipsText>{props.children}</ChipsText>
        </ChipsWrap>
    )
}

Chips.defaultProps = {
    type : 'home',
    id : '',
    key : '',
    onClick : null,
    selected : false,
    disabled : false,
    imgId : null
}

export default Chips;

