import { action, makeObservable, observable } from "mobx";
import {EventEmitter} from 'events';

class EventStore {

    e = new EventEmitter();

    constructor() {
        makeObservable(this, {
            e : observable
        });
    }

}

export default EventStore;