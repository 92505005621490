import {CheckboxInput, CheckboxLabel, CheckboxWrap} from "./CheckboxStyles";
import {IconButton} from ".";
import Checked from "../../../resources/icons/checkedImg.svg";
import UnChecked from "../../../resources/icons/unCheckedImg.svg";
import Disabled from "../../../resources/icons/checked_disabled.svg";
import {useEffect, useState} from "react";

const Checkbox = (props) => {

    const {checked, onCheck, label, icon, checkedIcon, disabledIcon, disabled, wrapperStyle, checkLabelStyleWrap} = props;
    const [isChecked, setIsChecked] = useState(checked);

    const onCheckHandler = (e) => {
        e.preventDefault();

        if(disabled) return;

        // onCheck가 props로 전달되었을 때만
        if(!onCheck) setIsChecked(!isChecked);

        if(onCheck && typeof onCheck === 'function'){
            onCheck(!isChecked);
        }
    }

    useEffect(()=>{
        setIsChecked(checked);
    }, [checked]);

    return(
        <CheckboxWrap>
            <CheckboxInput
                type={"checkbox"}
                id={label}
                defaultChecked={isChecked}
            />
            <IconButton
                height={'20px'}
                wrapperStyle={wrapperStyle}
                src={disabled ? (disabledIcon ? disabledIcon : Disabled) : isChecked ? checkedIcon : icon}
                onClick={disabled ? null : onCheckHandler}
            />
            <CheckboxLabel
                style={checkLabelStyleWrap}
                checked={isChecked}
                htmlFor={label}
                onClick={disabled ? null : onCheckHandler}
                disabled={disabled}
            >{label}</CheckboxLabel>
        </CheckboxWrap>
    )
}

Checkbox.defaultProps = {
    checked : false,
    disabled : false,
    onCheck : null,
    label : '',
    icon : UnChecked,
    checkedIcon : Checked,
    disabledIcon : Disabled
}
export default Checkbox;