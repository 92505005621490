import {useContext, useEffect} from "react";
import {useStores} from "../../../stores";
import {observer} from "mobx-react";
import ConfirmModalModalToast from "../modal/ConfirmModalModalToast";
import ConfirmModalToast from "../modal/ConfirmModalToast";
import FileConfirmModalToast from "../modal/FileConfirmModalToast";
import ImageModal from "../modal/ImageModal";
import {BackHistoryContext} from "../../../utils/backHistoryProvider";

const ModalToastPopup = (props) => {

    const {store} = useStores();
    //데이터
    const modalToastPopupStore = store.modalToastPopupStore;
    //01. 뒤로가기 버튼을 눌렀는지 여부 저장 (window history 관련)
    let isGoBackClicked = true;
    //02. 뒤로가기시 백여부 (전페이지 state 그대로 남기기위한)
    const backHistory = useContext(BackHistoryContext).current;

    /******************************************************************************************************************/

    /**
     * 이벤트 event
     * @param e
     */

    //뒤로가기시 이벤트
    const goBackHandler = () => {

        //강제 HistoryBack 못타게
        isGoBackClicked = true;
        //현재 모달토스트창 닫기
        modalToastPopupStore.closeModalPopup();
    };


    /******************************************************************************************************************/

    /**
     * 함수 function
     */

    //확인 버튼 눌렀을 시
    const goHistoryBack = () => {

        // 강제 HistoryBack
        isGoBackClicked = false;
        // 현재 모달토스트창 닫기
        modalToastPopupStore.closeModalPopup();
    };


    /******************************************************************************************************************/

    /**
     * useEffrct
     * 1. 이미지, 파일 가지오기
     * 2. - 뒤로가기버튼 눌렀을시 모달창 닫기 (window #추가) (자동 주소 뒤로 이동)
     *    - x버튼 눌렀을시 모달창 닫기 (window # 추가) (강제 주소 이동 => window history back)
     *
     *    return => unMount 이기에 항상 뒤에 실행 -> data.open 반대로 줘야함
     */
    useEffect(()=>{

        /**
         * 모달창 오픈시 (강제로 window 주소 추가)  =>>>> hidden이기 때문에 이미 컴포넌트 존재
         */
        if(modalToastPopupStore.isOpen) {
            //모달이 켜질때마다 새로운 state를 history에 추가
            window.history.pushState({page:"modalToast"}, "modalToast", '');
            //뒤로가기 눌렀을 때 onCloseHandler 함수 실행
            window.addEventListener("popstate", goBackHandler);
            //뒤로가기시 백여부 (전페이지 state 그대로 남기기위한)
            backHistory.state = 'modal';
        }

        return () => {

            /**
             * hidden이기 때문에 ==> 미리 layOut에서 컴포넌트 생성 (hidden = true로 관리)
             *
             * 1. 맨 처음 창 open시 호출 (isOpen=true)
             * 2. 창 닫았을시 호출 (isOpen=false)
             */
            if(!modalToastPopupStore.isOpen) {
                window.removeEventListener("popstate", goBackHandler);
                //뒤로가기를 누르지 않고 종료할 경우엔 state가 새로 있으니 뒤로가기를 해줘서 popstate 이벤트를 발생시켜야 함
                if (!isGoBackClicked) {
                    window.history.back();
                }
            }
        };

    },[modalToastPopupStore.isOpen]);



    /******************************************************************************************************************/


    /**
     * 모달 switch
     * @returns {JSX.Element}
     */

    // 모달 폼 형식
    switch (modalToastPopupStore.page) {
        //모달에서 확인, 닫기 팝업토스트 창
        case "modalAlert" : {
            return (
                <>
                    <ConfirmModalModalToast
                        goHistoryBack={goHistoryBack}
                        modalToastPopupStore={modalToastPopupStore}>
                    </ConfirmModalModalToast>
                </>
            )
        }
        //기본 팝업토스트 창
        case "alert" : {
            return (
                <>
                    <ConfirmModalToast
                        goHistoryBack={goHistoryBack}
                        modalToastPopupStore={modalToastPopupStore}>
                    </ConfirmModalToast>
                </>
            )
        }
        //파일 팝업토스트 창
        case "file" : {
            return (
                <>
                    <FileConfirmModalToast
                        goHistoryBack={goHistoryBack}
                        modalToastPopupStore={modalToastPopupStore}>
                    </FileConfirmModalToast>
                </>
            )
        }
        // 이미지 상세 창
        case "image" : {
            return (
                <ImageModal
                    goHistoryBack={goHistoryBack}
                    modalToastPopupStore={modalToastPopupStore}
                />
            )
        }

        // guide 모달
        case "guide" : {
            return (
                <>
                    <ConfirmModalToast
                        goHistoryBack={goHistoryBack}
                        modalToastPopupStore={modalToastPopupStore}>
                    </ConfirmModalToast>
                </>
            )
        }

        case "guide-alert" : {
            return (
                <>
                    <ConfirmModalToast
                        goHistoryBack={goHistoryBack}
                        modalToastPopupStore={modalToastPopupStore}>
                    </ConfirmModalToast>
                </>
            )
        }

        default: {
            return (
                null
            )
        }
    };

}

export default observer(ModalToastPopup);