import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle
} from "../global/ModalStyles";
import {Button, IconButton} from "../global";
import {StickerDetailCriteria, StickerDetailDesc, StickerDetailTitle, StickerDetailWrap} from "./StickerDetailModalStyles";
import Img from "../global/Img";
import {useStores} from "../../../stores";
import {ServerConfig} from "../../../commons/config";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {receiveResponse} from "../../../utils/api";
import ReactCanvasConfetti from "react-canvas-confetti";
import {useTranslation} from "react-i18next";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";

const StickerDetailModal = (props) => {

    const {t} = useTranslation();

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const data = modalPopupStore;
    const containerRef = useRef(null);
    // const stickerIds = data.detailItem;

    // const {stickerIds, onClose} = props;
    const {store} = useStores();
    const {userStore, toastPopupStore} = store;
    const [stickerIds, setStickerIds] = useState([])
    const [idx, setIdx] = useState(0);
    const [sticker, setSticker] = useState(undefined);
    const [fireConfetti, setFireConfetti] = useState({});

    // https://github.com/ulitcos/react-canvas-confetti#API
    const confettiOption = {
        particleCount: 150,
        spread: 360,
        startVelocity: 30,
        origin: {
            y: 0.4
        }
    };

    useEffect(() => {
        setFireConfetti({});
    }, [sticker]);

    useEffect(() => {
        const sticker = userStore.stickers.find(s => s.stickerId === stickerIds[idx]);
        setSticker(sticker);

        if (sticker && !sticker.confirm) {
            receiveResponse({}, `user/stickers/${sticker.stickerId}/confirm`, 'patch').then(r => {
            });
        }
    }, [idx, stickerIds]);

    const onCloseHandler = (e) => {
        if (stickerIds.length !== idx + 1) {
            setIdx(idx + 1);
        } else if (goHistoryBack && typeof goHistoryBack === 'function') {
            userStore.setStickers(userStore.stickers.map(s => stickerIds.find(id => id === s.stickerId) ? {...s, confirm: true} : s));
            goHistoryBack();
        }

    }

    const onClickModalContainer = (e) => {
        if (e.target === containerRef.current) {
            onCloseHandler(e);
        }
    }

    const createButton = () => {
        // 해당 스티커가 대표로 설정되어있지 않으면 버튼 나오게
        if (!sticker) return;
        const _sticker = userStore.stickers.find(s => s.stickerId === sticker?.stickerId);
        if (!_sticker?.represent) {
            return <Button width={'100%'}
                           textStyle={{fontSize : '16px', lineHeight: "23px"}}
                           shape={'circle'}
                           onClick={onSubmitHandler}>{t('SET_REP_STICKER')}</Button>
        }
    }

    // 대표이미지 설정
    const onSubmitHandler = () => {
        receiveResponse({}, `user/stickers/${sticker.stickerId}/represent`, 'patch')
            .then(res => {
                if (res.status === 200) {
                    userStore.setStickers(userStore.stickers.map(s => ({
                        ...s,
                        represent: s.stickerId === sticker.stickerId
                    })));
                    toastPopupStore.openToastPopup(t('CHANGE_REP_STICKER'));
                    onCloseHandler();
                } else {
                    toastPopupStore.openToastPopup(t('FAIL_CHANGE_REP_STICKER'));
                }
            })
    }

    useLayoutEffect(() => {
        if (data.isOpen) {
            setStickerIds(modalPopupStore.detailItem);
        } else {
            setSticker(undefined);
            setFireConfetti({});
            setIdx(0);
        }
    }, [data.isOpen]);

    return <ModalContainer ref={containerRef} className={!data.isOpen ? 'hidden' : null} dark={true} onClick={onClickModalContainer}>

                {/* HEAD 컴포넌트 */}
                <ModalHeaderWrap style={{background: 'none'}}>
                    {/* 전체 타이틀 컴포넌트*/}
                    <ModalTitle style={{color: '#ffffff'}}>{t("STICKER")}</ModalTitle>
                    <ModalCloseWrap>
                        <IconButton width={'32px'}
                                    height={'32px'}
                                    onClick={onCloseHandler}
                                    src={CancelIcon}/>
                    </ModalCloseWrap>
                </ModalHeaderWrap>

                {sticker ?
                    <ModalScrollBox>
                        <StickerDetailWrap>
                            <StickerDetailTitle>{sticker.nameText}</StickerDetailTitle>
                            <Img src={`${ServerConfig.default.IMG_URL}${sticker.imgId}`}
                                 wrapStyle={{width: '324px', height: '324px', marginBottom: '24px', backgroundColor: 'none'}}
                                 imgStyle={{width: '100%', height: '100%'}}
                                 onClick={() => setFireConfetti({})}/>
                            <StickerDetailDesc>{sticker.descriptionText}</StickerDetailDesc>
                            <StickerDetailCriteria>{sticker.criteriaText}</StickerDetailCriteria>
                            <ReactCanvasConfetti style={{position: 'fixed', width: '100%', height: '100%', pointerEvents: 'none'}}
                                                 fire={fireConfetti} {...confettiOption}/>
                        </StickerDetailWrap>
                    </ModalScrollBox> : null}

                <ModalBottomWrap style={{background: 'none'}}>
                    {createButton()}
                </ModalBottomWrap>

    </ModalContainer>;
}

StickerDetailModal.defaultProps = {
    stickerIds: [],
    onClose: null,
}

export default StickerDetailModal;