import {FABWrap} from "./FABStyles";
import {IconButton} from "./index";
import AddPostNormalIcon from "../../../resources/icons/add_post_normal.svg";
import AddPostDisabledIcon from "../../../resources/icons/add_post_disabled.svg";
import AddGroupNormalIcon from "../../../resources/icons/add_group_normal.svg";

const FAB = (props) => {

    const {onClick,disabled, type} = props;

    const onClickHandler = (e) => {
        if(onClick && typeof onClick === 'function'){
            onClick(e);
        }
    }

    return(
        <FABWrap disabled={disabled} onClick={onClickHandler}>
            <IconButton
                src={
                    disabled ? AddPostDisabledIcon :
                        (type==='post' ? AddPostNormalIcon : AddGroupNormalIcon)
                }
                width={'42px'}
                height={'42px'}
            />
        </FABWrap>
    )
}

FAB.defaultProps = {
    type : 'post', // post, group
    onClick : null,
    disabled : false
}
export default FAB;