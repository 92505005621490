import {
    AttachImageHoverWrap,
    AttachImageIconWrap, AttachImageMoreText,
    AttachImageMoreWrap,
    AttachImageWrap,
    AttachImg
} from "./AttachImageStyles";
import {IconButton} from "./index";
import DeleteIcon from '../../../resources/icons/attached_img_delete.svg';
import ImageIcon from "../../../resources/icons/image.svg";

const AttachImage = (props) => {

    const {type, width, height, onClick, onDelete, src, more} = props;

    return (
        <AttachImageWrap width={width} height={height} onClick={onClick} type={type}>
            <AttachImg
                src={src}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = ImageIcon;
                }}
            />
            {
                type === 'upload' ?
                    <AttachImageHoverWrap>
                        <AttachImageIconWrap>
                            <IconButton
                                width={'20px'}
                                height={'20px'}
                                src={DeleteIcon}
                                onClick={onDelete}
                            />
                        </AttachImageIconWrap>
                    </AttachImageHoverWrap>
                    : null
            }
            {
                type === 'download' && more ?
                    <AttachImageMoreWrap>
                        <AttachImageMoreText>{`+${more}`}</AttachImageMoreText>
                    </AttachImageMoreWrap> : null
            }
        </AttachImageWrap>
    )
}

AttachImage.defaultProps = {
    width : '100px',
    height : '100px',
    onClick : null,
    onDelete : null,
    src : '',
    type : 'upload', // upload, download
    more : null
}

export default AttachImage;