import styled from "styled-components";

export const ProfileContainer = styled.div`
    display : flex;
    align-items : center;
    flex-direction: column;
    
    gap : ${({type})=>{
    if(type==='profile-view' || type==='profile-edit'){
        return '12px';
    }else{
        return '10px';
    }
}};
`

export const ProfileImageWrap = styled.div`
    position:relative;
    flex : none;
    width : ${({type})=>{
    if(type==='profile-view' || type==='profile-edit'){
        return '80px';
    }else{
        return '40px';
    }
}};
    height : ${({type})=>{
    if(type==='profile-view' || type==='profile-edit'){
        return '80px';
    }else{
        return '40px';
    }
}};
`

export const ProfileImage = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 100%;
    height : 100%;
    border-radius : 50%;
`

export const ProfileImageEditIcon = styled.img.attrs(props => ({
    src: props.src
}))`
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background: #FFFFFF;
    border: 2px solid #EAEAEA;
    border-radius : 50%;
`

export const ProfileInfoWrap = styled.div`
 
    display: flex;
    font-weight: 700;
    gap: 6px;
    
    font-size: ${({type})=>{
        if(type==='profile-view' || type==='profile-edit'){
            return '15px';
        }else{
            return '15px'
        }
    }};
    
    line-height: ${({type})=>{
        if(type==='profile-view' || type==='profile-edit'){
            return '20px';
        }else{
            return '22px'
        }
    }};

`

export const ProfileInfoRow = styled.div`
    display : flex;
    align-items : center;
    gap : 6px;
`

export const ProfileInfoRowSpan = styled.span`
    align-self: center;
    width: 3px;
    height: 3px;
    background: #AEB7C0;
    border-radius: 2px;
`

export const InfoNameTitle = styled.div`
    font-size: 12px;
    line-height: 17px;
    color : ${({theme})=>theme.colors.black};
    margin-right : 6px;
`

export const InfoNickname = styled.div`
    font-weight: 700;
    color : ${({theme})=>theme.colors.black};
`

export const InfoCompany = styled.div`
    font-weight: 500;
    color : ${({theme})=>theme.colors.gray_50};
`

export const MasterIcon = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 20px;
    height : 20px;
    margin-left : 2px;
`
export const NicknameEditIcon = styled.img.attrs(props=>({
    src : props.src
}))`
  width: 12.29px;
  height: 12.29px;
  margin-left: 5.85px;
`

export const Time = styled.div`
    font-size: 12px;
    line-height: 17px;
    color : ${({theme})=>theme.colors.gray_50};
    margin-left : 12px;
`

