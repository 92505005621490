import {
    UserInfo,
    UserInfoDot,
    UserInfoNickname,
    UserInfoText,
    UserInfoTimeImg,
    UserInfoTimeImgWrap,
    UserInfoTimeWrap,
    UserInfoWrap
} from "./UserInfoTimeStyles";
import {ServerConfig} from "../../../commons/config";
import DefaultImg from "../../../resources/icons/profile.png";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {getDateFrom, getOnlyDateFrom, getTimeFrom} from "../../../utils/dateHandler";
import EmptyIcon from "../../../resources/icons/profile.png";
import {useStores} from "../../../stores";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";

const UserInfoTime = (props) => {

    const {nickname, companyName, createDate, regUserImgId, anonymousYn, type, showTime, onClickImg, userInfoNicknameStyle, UserInfoTextStyle} = props;

    const {store} = useStores();
    const {defaultImageStore} = store;

    const [imageUrl, setImageUrl] = useState(null);

    const onClickImgHandler = (userId) => {
        if(onClickImg && typeof onClickImg === 'function') onClickImg(userId);
    }

    // 기본 제공 이미지인지 확인
    useEffect(()=>{
        if(!defaultImageStore.defaultUserImgs) return;
        if(getBoolFromYn(anonymousYn) || !regUserImgId) setImageUrl(DefaultImg);
        else{
            const idx = defaultImageStore.defaultUserImgs.findIndex(id=>id === regUserImgId);
            if(idx!==-1) setImageUrl(`${ServerConfig.default.IMG_URL}${regUserImgId}`);
            else setImageUrl(`${ServerConfig.default.IMG_THUMBNAIL_URL}${regUserImgId}`);
        }
    },[defaultImageStore.defaultUserImgs, props.regUserImgId, props.anonymousYn])

    return(
        <UserInfoTimeWrap type={type}>
            {
                type !=='admin' && imageUrl ?
                    <UserInfoTimeImgWrap className={type} onClick={getBoolFromYn(anonymousYn) ? null : e=>onClickImgHandler()}>
                        <UserInfoTimeImg
                            src={imageUrl}
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = EmptyIcon;
                            }}
                        />
                    </UserInfoTimeImgWrap> : null
            }
            <UserInfoWrap type={type}>
                <UserInfo type={type}>
                    <UserInfoNickname style={userInfoNicknameStyle}>{nickname}</UserInfoNickname>
                    { type!=='admin' ?
                        <>
                            <UserInfoDot />
                            <UserInfoText style={UserInfoTextStyle}>{companyName}</UserInfoText>
                        </>
                        : null}
                </UserInfo>
                {
                    showTime ?
                        <UserInfoText type={type}>{
                            // type === 'card' ?
                            //     getTimeFrom(createDate) :
                                (type === 'group-card' ? getOnlyDateFrom(createDate) : getDateFrom(createDate))
                        }</UserInfoText> : null
                }
            </UserInfoWrap>
        </UserInfoTimeWrap>
    )
}

UserInfoTime.defaultProps = {

    nickname : '코오롱 닉네임',
    companyName : '코오롱',
    createDate : '방금',
    regUserImgId : '',
    anonymousYn : 'N',
    type : 'card', // popup, card, group-card, admin
    showTime : true, // true, false
    onClickImg : null,
}

export default observer(UserInfoTime);