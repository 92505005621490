import {observer} from "mobx-react";
import {Chips, FAB, IconButton, NoResult, SearchField} from "../../components/presentationals/global";
import {
    GroupSearchResultWrap,
    GroupsSearchWrap,
    InterestGroupsCategoryH3,
    InterestGroupsCategorySrot,
    InterestGroupsCategorySrotWrap,
    InterestGroupsCategoryStrong,
    InterestGroupsCategoryWrap,
    ListGroupWrap,
    MyGroupsSearchWrap,
    MySearchBarRight,
    MySearchBarVisible,
    MySearchCategory,
    MySearchCategoryField,
    MySearchCategoryLabel,
    MySearchCategorySpan,
    MySearchWrap,
    MyTopicCategoryWrap,
    NoRankGroupWrap,
    RankGroupsJoinRankImg,
    RankGroupsJoinRankText,
    RankGroupsJoinRankUl,
    RankGroupsWrap,
    SearchBarRight,
    SearchBarSearch,
    SearchBarText,
    SearchBarVisible,
    SearchWrap,
    TopicCategoryWrap,
    TopicWrap
} from "./GroupsStyles";
import React, {useCallback, useContext, useEffect, useLayoutEffect} from "react";
import {receiveResponse} from "../../utils/api";
import isEmpty from "is-empty";
import {ThreeDots} from "react-loader-spinner";
import env from '../../constants/env';

import useBackControl from "../../utils/useBackControl";
import {BackHistoryContext} from '../../utils/backHistoryProvider';
import GroupsTopComponent from "../../components/presentationals/groups/GroupsTopComponent";
import scoreRankImg from "../../resources/images/gather_title01.png";
import joinRankImg from "../../resources/images/gather_title02.png";
import createRankImg from "../../resources/images/gather_title03.png";
import itemRankImg from "../../resources/images/gather_title04.png";
import NoResultImg from "../../resources/icons/no_result.svg";
import GroupsCard from "../../components/presentationals/groups/GroupsCard";
import routes from "../../constants/routes.json";
import NoPostImg from "../../resources/icons/no_post.svg";
import SearchImg from '../../resources/mImages/searchIcon.svg';
import SearchSelectedImg from '../../resources/mImages/searchSelectedIcon.svg';
import {HomeFABWrap, HomeQuickWrap} from "../home/HomeStyles";
import upMoveIcon from "../../resources/mImages/quick_top.png";
import JoinGroupList from "../../components/presentationals/global/JoinGroupList";
import InvitingGroup from "../../components/presentationals/global/InvitingGroup";
import {GroupInvitingList} from "../../components/presentationals/global/JoinGroupListStyles";
import {useTranslation} from "react-i18next";
import {
    InvitingGroupInfoUl,
    InvitingGroupInfoWrap,
    InvitingGroupTitle
} from "../../components/presentationals/global/InvitingGroupStyles";
import SearchDisabledImg from "../../resources/mImages/search_disabled.png";


const Groups = (props) => {

    const {t} = useTranslation();
    const {userStore, itemStore, toastPopupStore, interestStore, history, modalPopupStore} = props;

    const backContext = useContext(BackHistoryContext).current;
    // 전 변수값 담아두기
    const {useRemState, useRemRef} = useBackControl('Groups');

    // group state
    const [groupsState, setGroupsState] = useRemState({
        page : 0,
        activeInterestId : 'rank',
        sort : 'ranking,asc',
        searchWord : props.location?.state?.keyword
    }, 'groupsState');


    const [isLoading, setIsLoading] = useRemState(true, 'isLoading');
    const [total, setTotal] = useRemState(0, 'total');
    const [rankList, setRankList] = useRemState([], 'rankList');
    const [interestList, setInterestList] = useRemState([], 'interestList');

    // 돋보기 클릭 <모바일 추가>
    const [searchView, setSearchView] = useRemState(false, 'searchView');
    // 내그룹, 전체그룹 (my, all 선택)  <모바일 추가>
    const [isMyGroups, setIsMyGroups] = useRemState(true, 'isMyGroups');
    // 입력 검색어
    const [enterSearchWord, setEnterSearchWord] = useRemState(props.location?.state?.keyword, 'enterSearchWord');
    // 정렬 순서 클릭
    const [interestSortValue, setInterestSortValue] = useRemState('rank', 'interestSortValue');


    // 무한 스크롤 중복 방지
    const preventRef = useRemRef(true, 'preventRef');
    // 무한 스크롤 옵저버 요소
    const obsRef = useRemRef(null, 'obsRef');
    // 마지막 페이지 등록 여부
    const endRef = useRemRef(null, 'endRef');


    /******************************************************************************************************************/

    /**
     * 아이템 리스트
     * @param list
     * @param type
     * @returns {unknown[]}
     */
    const itemGroups = (list, type) => {

        return (list || []).map((item, index) => {
            return <GroupsCard index={index}
                               key={`groups-li-rank-${item.groupId}`}
                               id={`${item.groupId}`}
                               highlight={groupsState.searchWord}
                               item={item}
                               type={type}
            ></GroupsCard>
        });
    }

    /**
     * 아이템 리스트
     * @param list
     * @param type
     * @returns {unknown[]}
     */
    const noItemGroups = () => {
        return <GroupSearchResultWrap>
            <NoResult
                src={!isEmpty(groupsState.searchWord) ? NoResultImg : NoPostImg}
                title={!isEmpty(groupsState.searchWord) ? t('NO_SEARCH_ITEMS') : t('NO_CREATED_GROUP')}
                subtitle={!isEmpty(groupsState.searchWord) ? t('NO_SEARCH_ITEMS_SUBTITLE') : ''}/>
        </GroupSearchResultWrap>
    }

    /**
     * 초대 그룹 리스트
     * */
    const createInvitingGroupsList = (invitingList) => {
        return invitingList.map((item)=>{
            return <InvitingGroup
                groupId={item?.groupId}
                imgId={item?.imgId}
                userCount={item?.userCount}
                groupName={item?.groupName}
            />
        })
    }

    /**
     * chip 컴포넌트
     * @param topicGroups
     * @returns {*}
     */
    const createTopicGroups = (interests) => {

        // 랭킹 추가
        const interestRankItem = {
            interestId : "rank",
            name : 'interest.ranking',
            nameText : t('RANKING')
        };

        interests = [interestRankItem, ...interests];

        return interests.map((interest, index) => {

            return <Chips
                key={`groups-chip-${interest.interestId}`}
                type={'groups'}
                id={interest.interestId}
                selected={groupsState.activeInterestId === interest.interestId}
                onClick={(e)=>{onClickTopic(e,index,interest.interestId); onMoveScrollUpEvent();}}
                disabled={interest.interestId === 'rank' && groupsState.searchWord}
            >{interest.nameText}</Chips>
        })
    }


    /**
     * 리스트 컴포넌트
     */
    const listComponent = (interestId) => {
        // 모달 폼 형식
        switch (interestId) {
            case 'rank' : {
                return (
                    <RankGroupsWrap>
                        {/* TOP4 리스트 생성 */}
                        <ListGroupWrap>
                            <RankGroupsJoinRankText>
                                {t('TOP_FOUR_GROUPS')}
                                <RankGroupsJoinRankImg src={scoreRankImg}/>
                            </RankGroupsJoinRankText>
                            {
                                rankList?.scoreRankList?.length === 0 ?
                                    <NoRankGroupWrap>{t('NO_SUCH_GROUP')}</NoRankGroupWrap> :
                                    <RankGroupsJoinRankUl>
                                        {itemGroups(rankList?.scoreRankList, 'S')}
                                    </RankGroupsJoinRankUl>
                            }
                        </ListGroupWrap>

                        {/* 가입자수 폭등 리스트 생성 */}
                        <ListGroupWrap>
                            <RankGroupsJoinRankText>
                                {t('GROUPS_HOME_JOIN_RANK')}
                                <RankGroupsJoinRankImg src={joinRankImg}/>
                            </RankGroupsJoinRankText>
                            {
                                rankList?.joinRankList?.length === 0 ?
                                    <NoRankGroupWrap>{t('NO_SUCH_GROUP')}</NoRankGroupWrap> :
                                    <RankGroupsJoinRankUl>
                                        {itemGroups(rankList?.joinRankList, 'J')}
                                    </RankGroupsJoinRankUl>
                            }
                        </ListGroupWrap>

                        {/* 따끈따끈 신상 생성 */}
                        <ListGroupWrap>
                            <RankGroupsJoinRankText>
                                {t('GROUPS_HOME_CREATE_DATE_RANK')}
                                <RankGroupsJoinRankImg src={createRankImg}/>
                            </RankGroupsJoinRankText>
                            {
                                rankList?.createDateRankList?.length === 0 ?
                                    <NoRankGroupWrap>{t('NO_SUCH_GROUP')}</NoRankGroupWrap> :
                                    <RankGroupsJoinRankUl>
                                        {itemGroups(rankList?.createDateRankList, 'C')}
                                    </RankGroupsJoinRankUl>
                            }
                        </ListGroupWrap>

                        {/* 조잘조잘 생성 */}
                        <ListGroupWrap>
                            <RankGroupsJoinRankText>
                                {t('GROUPS_HOME_ITEM_RANK')}
                                <RankGroupsJoinRankImg src={itemRankImg}/>
                            </RankGroupsJoinRankText>
                            {
                                rankList?.itemRankList?.length === 0 ?
                                    <NoRankGroupWrap>{t('NO_SUCH_GROUP')}</NoRankGroupWrap> :
                                    <RankGroupsJoinRankUl>
                                        {itemGroups(rankList?.itemRankList, 'R')}
                                    </RankGroupsJoinRankUl>
                            }
                        </ListGroupWrap>

                    </RankGroupsWrap>
                )
            }
            default: {
                return (
                    <ListGroupWrap>
                        <InterestGroupsCategoryWrap>
                            <InterestGroupsCategoryH3>
                                <InterestGroupsCategoryStrong>
                                    {(isEmpty(groupsState.searchWord) ?
                                        interestStore.interests[groupsState.activeInterestId].nameText : groupsState.searchWord) + ' '}
                                </InterestGroupsCategoryStrong>
                                {(isEmpty(groupsState.searchWord) ? t('CATEGORY_GROUP') : t('SEARCH_RESULT')) + ' '}
                                <InterestGroupsCategoryStrong>{total}</InterestGroupsCategoryStrong>
                                {isEmpty(groupsState.searchWord) ? t('NUMBER_CNT') : t('NUMBER_CASES')}
                            </InterestGroupsCategoryH3>
                            <InterestGroupsCategorySrotWrap>
                                <InterestGroupsCategorySrot selected = {interestSortValue === 'rank' ? true : false} onClick={(e)=>{onRankSortClickEvent(e)}}>{t('RANKING_SORT')}</InterestGroupsCategorySrot>
                                <InterestGroupsCategorySrot selected = {interestSortValue === 'createDate' ? true : false} onClick={(e)=>{onCreateDateSortClickEvent(e)}}>{t('CREATE_SORT')}</InterestGroupsCategorySrot>
                            </InterestGroupsCategorySrotWrap>
                        </InterestGroupsCategoryWrap>
                        <RankGroupsJoinRankUl>
                            {interestList?.length === 0 ? noItemGroups() : itemGroups(interestList, 'I')}
                        </RankGroupsJoinRankUl>
                    </ListGroupWrap>
                )
            }
        }
    }


    /**
     * 내그룹, 내그룹 가입 안되어있을 경우
     */
    const groupsListComponent = (isMyJoinedGroups) => {

        // 내가 가입한 그룹 있을 경우
        if (isMyJoinedGroups === 'join') {
            return (
                <>
                    {/* 검색 */}
                    <MyGroupsSearchWrap id={'search_wrap'}>
                        <MySearchWrap>
                            <MySearchCategory>
                                <MySearchCategoryField>
                                    <MySearchCategoryLabel>
                                        <MySearchCategorySpan className={isMyGroups ? 'checked' : ''}
                                                              onClick={()=>{
                                                                  setIsMyGroups(true);
                                                                  setSearchView(false);
                                                                  onMoveScrollUpEvent();
                                                              }}>
                                            {'내 그룹'}
                                        </MySearchCategorySpan>
                                    </MySearchCategoryLabel>
                                    <MySearchCategoryLabel>
                                        <MySearchCategorySpan className={isMyGroups ? '' : 'checked'}
                                                              onClick={()=>{
                                                                  setIsMyGroups(false);
                                                                  setEnterSearchWord('');
                                                                  setInterestSortValue('rank');
                                                                  setIsLoading(true);
                                                                  setTotal(0);
                                                                  setGroupsState({
                                                                      activeInterestId: 'rank',
                                                                      sort: 'ranking,asc',
                                                                      searchWord : props.location?.state?.keyword,
                                                                      page: 0
                                                                  });
                                                                  onMoveScrollUpEvent();
                                                              }}>
                                            {'전체 그룹'}
                                        </MySearchCategorySpan>
                                    </MySearchCategoryLabel>
                                </MySearchCategoryField>
                                <MySearchBarRight>
                                    <SearchBarSearch className={isMyGroups ? 'hidden' : ''} selected={searchView} disabled={groupsState.searchWord}>
                                        <IconButton
                                            width={'20px'}
                                            height={'20px'}
                                            src={
                                                searchView ?
                                                    groupsState.searchWord ? SearchDisabledImg : SearchSelectedImg
                                                    : SearchImg
                                            }
                                            onClick={onSearchClickEvent}
                                        />
                                    </SearchBarSearch>
                                </MySearchBarRight>
                            </MySearchCategory>
                        </MySearchWrap>

                        {/* 검색 창 */}
                        <MySearchBarVisible className={!searchView ? 'hidden' : null}>
                            <SearchField
                                placeholder={t('SEARCH_GROUP_PLACEHOLDER')}
                                type={'G'}
                                value={enterSearchWord}
                                onChange={onSearchWordChange}
                                onDelete={onSearchWordDelete}
                                onSearch={onSearchWord}
                            />
                        </MySearchBarVisible>

                        {/* 그룹 토픽 동그라미 */}
                        {!isMyGroups ?
                            <MyTopicCategoryWrap>
                                <TopicWrap>
                                    {createTopicGroups(interestStore.interests)}
                                </TopicWrap>
                            </MyTopicCategoryWrap> : null}

                    </MyGroupsSearchWrap>

                    { isLoading ?
                        <ThreeDots
                            height={"10"}
                            wrapperStyle={{
                                width : '100%',
                                justifyContent : 'center',
                                height : '100%',
                                paddingTop : '30px',
                                marginBottom : '12px'
                            }}
                            color={'#3A6AF6'}
                        /> : isMyGroups ?
                            <JoinGroupList list={userStore.joinGroups} invitingList={userStore.invitingGroups}/>
                                :   listComponent(groupsState?.activeInterestId)

                    }
                </>
            )
        }else if (isMyJoinedGroups === 'notJoin') { // 내가 가입한 그룹 없을 경우
            return (
                <>
                    {/* 검색 */}
                    <GroupsSearchWrap id={'search_wrap'}>
                        <SearchWrap>
                            <SearchBarText>{t('GROUPS_RECOMMEND_TEXT')}</SearchBarText>
                            <SearchBarRight>
                                <SearchBarSearch selected={searchView} disabled={groupsState.searchWord}>
                                    <IconButton
                                        width={'20px'}
                                        height={'20px'}
                                        src={
                                            searchView ?
                                                groupsState.searchWord ? SearchDisabledImg : SearchSelectedImg
                                                : SearchImg
                                        }
                                        onClick={onSearchClickEvent}
                                    />
                                </SearchBarSearch>
                            </SearchBarRight>
                        </SearchWrap>

                        {/* 검색 창 */}
                        <SearchBarVisible className={!searchView ? 'hidden' : null}>
                            <SearchField
                                placeholder={t('SEARCH_GROUP_PLACEHOLDER')}
                                type={'G'}
                                value={enterSearchWord}
                                onChange={onSearchWordChange}
                                onDelete={onSearchWordDelete}
                                onSearch={onSearchWord}
                            />
                        </SearchBarVisible>

                        {/* 그룹 토픽 동그라미 */}
                        <TopicCategoryWrap>
                            <TopicWrap>
                                {createTopicGroups(interestStore.interests)}
                            </TopicWrap>
                        </TopicCategoryWrap>

                    </GroupsSearchWrap>

                    {isLoading ?
                        <ThreeDots
                            height={"10"}
                            wrapperStyle={{
                                width: '100%',
                                justifyContent: 'center',
                                height: '100%',
                                paddingTop: '30px',
                                marginBottom: '12px'
                            }}
                            color={'#3A6AF6'}
                        /> : listComponent(groupsState?.activeInterestId)
                    }
                </>
            )
        }
        else {
            return null
        }
    };

    /******************************************************************************************************************/

    /**
     * 04. 함수
     *
     */

        // 추천 그룹 API 요청 함수
    const getInterestGroups = useCallback((groupStates) => {

            // 1.흥미, 관심
            const interestId = groupStates?.activeInterestId;
            // 2.검색어
            const searchWord = groupStates?.searchWord;
            // 3.페이지
            const page = groupStates?.page;
            // 4.정렬 (랭킹순, 개설순) ranking,asc, createDate,desc
            const sort = groupStates?.sort;

            // 02. 흥미
            let params = {
                sort : sort,
                size : env.groupsContentSize,
                page : page
            }
            //검색어
            if (!isEmpty(searchWord)) {
                params = {...params, searchWord : encodeURIComponent(searchWord)}
            }
            // 토픽 흥미 (전체 아닐경우)
            if(interestId !== '0000000000') params = {...params, interestIdList : interestId};

            // 조회
            receiveResponse(params, 'groups', 'get').then(res => {
                //성공
                if(res.status === 200){
                    if(page===0){
                        //02. 흥미
                        setInterestList([...res.data.list]);
                    }else{
                        //02. 흥미
                        setInterestList([...interestList].concat(res.data.list));
                    }
                    setTotal(res.data.total);
                    setIsLoading(false);
                    preventRef.current = true;
                    endRef.current = res.data.end;
                }
            })
        }, [groupsState]);

    // 추천 그룹 API 요청 함수
    const getRankGroups = useCallback((groupStates) => {

        // 1.흥미, 관심
        const interestId = groupStates?.activeInterestId;

        // 01. 랭킹
        let params = {
            target : 'rank'
        }

        // 조회
        receiveResponse(params, 'groups', 'get').then(res => {
            //성공
            if(res.status === 200){
                if(groupStates.page===0){

                    //01. 랭킹
                    if (interestId === 'rank') {
                        setRankList(res.data);
                    }
                }

                setTotal(res.data.total);
                setIsLoading(false);
                preventRef.current = true;
                endRef.current = res.data.end;
            }
        })
    }, [groupsState]);


    /******************************************************************************************************************/

    /**
     * 05. 이벤트 함수
     *
     */
        // topic click 함수
    const onClickTopic = (e, index, interestId) => {
            preventRef.current = false;
            e.preventDefault();

            //로딩아닐경우만
            if (!isLoading) {
                setIsLoading(true);
                // topic list 초기화
                setEnterSearchWord(groupsState.searchWord);
                setGroupsState((groupsState)=>{
                    return {
                        ...groupsState,
                        activeInterestId : interestId,
                        page : 0

                    }
                });
            }
        }

    // observer 핸들러 함수
    const obsHandler = ((entries) => {
        const target = entries[0];
        if(target.isIntersecting && preventRef.current){
            if(endRef.current) return;
            preventRef.current = false;

            setGroupsState(groupsState=> {
                return {
                    ...groupsState,
                    page : groupsState.page + 1
                }
            });
        }
    });

    // enter search word change 함수
    const onSearchWordChange = (value) => {
        setEnterSearchWord(value);
        //로딩아닐경우만
        if(!value){
            // 검색 중이면 다시 불러오기
            if(groupsState.searchWord){
                if (!isLoading) {
                    preventRef.current = false;
                    setIsLoading(true);
                    setGroupsState((groupsState) => {
                        return {
                            ...groupsState,
                            searchWord: '',
                            page: 0
                        }
                    });
                }
            }
        }
    }

    // search word 전부 지우기
    const onSearchWordDelete = (e) => {
        e.preventDefault();
        preventRef.current = false;

        setEnterSearchWord('');

        //로딩아닐경우만
        if (!isLoading) {

            setIsLoading(true);
            setGroupsState((groupsState) => {
                return {
                    ...groupsState,
                    searchWord: '',
                    page: 0
                }
            });
        }
    }

    // 검색 함수
    const onSearchWord = (searchWord) => {
        preventRef.current = false;
        setEnterSearchWord(searchWord);
        let _interests = interestStore.interests;
        let _interestId = groupsState.activeInterestId === 'rank' ? _interests[0].interestId : groupsState.activeInterestId ;

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            setGroupsState((groupsState)=>{
                return {
                    ...groupsState,
                    searchWord: searchWord,
                    activeInterestId : _interestId,
                    page : 0
                }
            });
        }
    }

    // 정렬 순서 이벤트 클릭
    const onRankSortClickEvent = (e) => {
        preventRef.current = false;
        e.preventDefault();

        //로딩아닐경우만
        if (!isLoading) {
            setInterestSortValue('rank');
            setIsLoading(true);
            setGroupsState((groupsState)=>{
                return {
                    ...groupsState,
                    sort : 'ranking,asc',
                    page : 0
                }
            });
        }
    }

    // 정렬 순서 이벤트 클릭
    const onCreateDateSortClickEvent = (e) => {
        preventRef.current = false;
        e.preventDefault();

        //로딩아닐경우만
        if (!isLoading) {
            setInterestSortValue('createDate');
            setIsLoading(true);
            setGroupsState((groupsState)=>{
                return {
                    ...groupsState,
                    sort : 'createDate,desc',
                    page : 0
                }
            });
        }
    }

    // 글 쓰기 모달 오픈
    const onOpenPostModal = (e) => {
        e.preventDefault();

        // preventRef.current = true;
        //모달 기본 설정n
        modalPopupStore.initAdd(
            'cg',
            null,
            {},
            onCloseCacnelPostModal,
            onCloseOkPostModal
        );
    }

    // 글 쓰기 모달 닫기
    const onCloseCacnelPostModal = (e) => {
        toastPopupStore.openToastPopup(t('CANCEL_CREATE_GROUP'));
        backContext.isBack = false;
    }

    // 글 쓰기 모달 닫기
    const onCloseOkPostModal = (value) => {
        modalPopupStore.closeModalPopup();

        history.push(`${routes.GROUPS}/${value}`);
    }

    // 돋보기 클릭 이벤트
    const onSearchClickEvent = (e) => {
        e.preventDefault();
        if(groupsState.searchWord) return;
        const searchWrap = document.getElementById('search_wrap');
        if(searchWrap) {
            if(!searchView){
                searchWrap?.classList?.add('show');
            }else{
                searchWrap?.classList?.remove('show');
            }
        }
        setSearchView(!searchView);
    }

    // 맨위로 스크롤 이동
    const onMoveScrollUpEvent = () => {
        document?.querySelector('#scroll-box')?.scrollTo(0, 0);
    }

    /******************************************************************************************************************/


    /**
     * 06. uesEffect
     *
     */

    // /** 중요 **/
    // /*  데이터 유지 관리 떄문에 페이지 이동 시 홈이면 초기화 */
    // // 새로 페이지 왔을 경우는 초기화
    useLayoutEffect(()=>{
        if (history.action === 'PUSH') {
            /** 중요 **/
            /*  데이터 유지 관리 떄문에 페이지 이동 시 홈이면 초기화 */
            // 새로 페이지 왔을 경우는 초기화
            if (history.action === 'PUSH') {
                setIsLoading(true);
                setTotal(0);
                //01. 돋보기클릭
                setSearchView(false);
                //02. 내 그룹 텝
                setIsMyGroups(true);
                //03. 검색어
                setEnterSearchWord('');
                //04. 정렬 체크
                setInterestSortValue('rank');
                //05. 그룹 리스트 세팅
                setGroupsState({
                    activeInterestId: 'rank',
                    sort: 'ranking,asc',
                    searchWord : props.location?.state?.keyword,
                    page: 0
                });
            }

        }
    },[]);

    useEffect(()=>{
        // observer 생성
        const observer = new IntersectionObserver(obsHandler, {
            threshold : 0
        });
        // observer에 관찰 요소 등록
        if(obsRef.current) observer.observe(obsRef.current);
        return () => {
            observer.disconnect();
        };
    },[]);

    useEffect(()=>{

        // /**
        //  * 뒤로 다시 왔을경우 page변수가 + 1 안되어있으므로 방지
        //  * 전역변수 isBack 강제 false 처리 필수
        //  * page가 0일때는 타야함....
        //  */
        // if (!backContext.isBack || page == 0) getBoardItems(activeCompanyId, activeTopicGroupId, searchWord, checkBookmark, page);
        // backContext.isBack = false;

        // 페이지 이동 백 아닐경우만
        if (!backContext.isBack) {
            if (groupsState.activeInterestId === 'rank') {
                getRankGroups(groupsState);
            } else {
                if(!backContext.pageInModal){
                    getInterestGroups(groupsState);
                }
            }
            backContext.pageInModal = false;
        }else {
            // ===> 이후 페이지에서 새로고침 후 뒤로 왔을 경우
            if (rankList?.length === 0) {
                getRankGroups(groupsState);
            }
            backContext.isBack = false;
        }
    },[groupsState]);

    /*스크롤 이벤트*/
    useEffect(() => {
        const scroll = document.querySelector("#scroll-box");
        scroll?.addEventListener('scroll', handleScroll);
        return () => {
            scroll.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);

    useEffect(() => {
        if (userStore.joinGroups === null) {

        }
    }, [userStore.joinGroups]);


    const handleScroll = () => {
        const scroll = document.querySelector("#scroll-box");
        const writer = document.querySelector('#modalWrite');
        if (scroll?.scrollTop === 0) {
            writer?.classList?.add('hide');
        }
    };

    /******************************************************************************************************************/


    /**
     * 07. render
     *
     */

    return(
        <>
            {/* 01. 그룹 top 컴포넌트*/}
            <GroupsTopComponent
                groupStats = {itemStore.groupStats}
                joinGroups = {userStore.joinGroups}
                invitingGroups = {userStore.invitingGroups}
                recommendAllGroupItems = {itemStore.recommendAllGroupItems}
                onItemPopClick = {()=>{}}
            >
            </GroupsTopComponent>

            {/* 02. 초대 그룹 리스트*/}
            { userStore.invitingGroups !== null && userStore.invitingGroups?.length > 0 ?
                    <GroupInvitingList>
                        <InvitingGroupTitle>{t('INVITE_GROUP')}</InvitingGroupTitle>
                        <InvitingGroupInfoWrap>
                            <InvitingGroupInfoUl>
                                {createInvitingGroupsList(userStore.invitingGroups)}
                            </InvitingGroupInfoUl>
                        </InvitingGroupInfoWrap>
                    </GroupInvitingList> : null
            }

            {/*그룹 리스트 (01. 내가 가입한 그룹 O, 02. 내가 가입한 그룹 X */}
            {groupsListComponent(
                userStore.joinGroups !== null && userStore.joinGroups?.length > 0 ? 'join' :
                    userStore.joinGroups !== null && userStore.joinGroups?.length === 0 ? 'notJoin' : 'null')}

            {/*스크롤 감지*/}
            <div ref={obsRef}></div>

            {/*모달*/}
            <HomeQuickWrap>

                <HomeFABWrap className={'hide'} id={'modalWrite'}>
                    <IconButton width={'50px'} height={'50px'} src={upMoveIcon} onClick={onMoveScrollUpEvent}/>
                </HomeFABWrap>
                <HomeFABWrap>
                    <FAB type={'group'} onClick={onOpenPostModal}/>
                </HomeFABWrap>

            </HomeQuickWrap>

        </>
    )
}

export default observer(Groups);