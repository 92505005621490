import styled from "styled-components";

export const ChipsWrap = styled.div`

    position: relative;
    flex-direction: column;
    // justify-content: space-around;
    justify-content: center;
    display : flex;
    box-sizing : border-box;
    align-items : center;
    box-shadow: 2px 4px 20px rgba(117, 129, 141, 0.04);
    
    font-style: normal;
    font-weight: 500;

    height : ${({type}) => {
        if(type==='home'){
            return '29px';
        }else if(type==='groups'){
            return '29px';
        }
    }};
  
    border-radius: ${({type}) => {
        if(type==='home') {
            return '65px';
        }else if (type==='groups') {
            return '65px';
        }
    }}; 

    padding : ${({type}) => {
        if(type==='home'){
            return '5px 12px 6px 12px';
        }else if(type==='groups'){
            return '5px 10.5px 5px 10.5px';
        }
    }};
    
    font-size : ${({type}) => {
        if(type==='home'){
            return '12px';
        }else if(type==='groups'){
            return '12px';
        }
    }};
    
    margin-right : ${({type}) => {
        if(type==='home') {
            return '6px';
        }else if(type==='groups'){
            return '6px';
        }
    }};
    
    &:last-child {
        margin-right: 0px;
    }

    
    color : ${({selected, disabled, theme, type}) => {
        
        if(selected){
            return theme.colors.white;
        }else if(disabled){
            return theme.colors.gray_50;
        } else if(type === 'view' || type === 'edit'){
            return '#75818D';
        }
        return theme.colors.black;    
    }};
    
    line-height : ${({type}) => {
        if(type==='groups'){
            return '14px';
        }else if(type==='home'){
            return '17px';
        }
    }};
    
    border : ${({type, selected, disabled, theme}) => {
        
        // if(selected) return `1px solid ${theme.colors.black}`;
        //
        // if(disabled) return `1px solid ${theme.colors.gray_disabled}`
    
        // if(type==='home' || type==='groups'){
        //     return `1px solid #EAEAEA`;
        // }
    }};
    
    background : ${({type, selected, disabled, theme}) => {

        if(selected){
            return theme.colors.black;
        }
    
        if(disabled){
            return theme.colors.gray_disabled;
        }
    
        if(type==='home'){
            return '#F7F7F7';
        }else if (type==='groups') {
            return '#F7F7F7';
        }else{
            return theme.colors.white;
        }
    }};
    
    // hover
    // &:hover {  
    //     background : {({type, selected, disabled, theme}) => {
    //        
    //         if(selected || disabled) return;
    //        
    //         if(type==='home' || type==='groups'){
    //             return theme.colors.gray_hover;
    //         }
    //     }};
    //    
    //     border : {({type, selected, disabled, theme}) => {
    //
    //         if(selected) return 1px solid theme.colors.black;
    //    
    //         if(disabled) return 1px solid #EAEAEA
    //
    //         if(type==='border' || type==='delete-r' || type==='delete-l'){
    //             return 1px solid {theme.colors.gray_stroke_100};
    //         }
    //        
    //     }};
    // }
    
    gap : ${({type}) => {
        return '0px';
    }};
    
    flex-direction: ${({type}) => {
    }};
    
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
`

export const ChipsImg = styled.img.attrs(props=>({
    src : props.src
}))`
    padding-bottom : 8px;
    filter : ${({selected})=>selected ? 'brightness(0) invert(1)' : ''};
    -webkit-filter: ${({selected})=>selected ? 'brightness(0) invert(1)' : ''};
}};
`

export const ChipsText = styled.div`
    width: max-content;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
`

export const ChipsButton = styled.div`

    width : ${({type}) => {
        if(type==='groups'){
            return '16px';
        }else if(type==='home'){
            return '16px';
        }
    }};
    
    height : ${({type}) => {
        if(type==='groups'){
            return '18px';
        }else if(type==='home'){
            return '18px';
        }
    }};
    
    display : ${({type}) => {
        return 'none';
    }};
    
    flex : none;
`