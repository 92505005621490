import { useEffect, useRef } from 'react';

/**
 * 첫 렌더링시 useEffect 막는 함수
 * @param func
 * @param deps
 */
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        console.log(didMount.current, '@!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        if (didMount.current) return func();
        else didMount.current = true;
    }, deps);

    useEffect(() => {
        return ()=>{
            didMount.current = false;
            console.log('unmounted@@@@@@@@@@@@@@@@@@@@@@@@@@@')
        }
    },[]);
};

export default useDidMountEffect;