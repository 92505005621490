import {ScrollDiv} from "./ScrollBoxStyles";
import {forwardRef} from "react";

const ScrollBox = forwardRef((props,ref) => {
    return(
        <ScrollDiv ref={ref} className={'scroll-body'} {...props}>
            {props.children}
        </ScrollDiv>
    )
})

export default ScrollBox;