import styled from "styled-components";

export const GroupMemberSettingContainer = styled.div`
    display : flex;
    flex-direction : column;
    padding-top: 20px;
`

export const GroupMembersSection = styled.div`
    
`

export const GroupMembersSectionTitle = styled.div`
    padding-left: 16px;
    margin-bottom : 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
`

export const GroupMembersList = styled.div`
    display : flex;
    flex-direction: column;

`