import styled from "styled-components";

export const GroupSubTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color : ${({theme})=>theme.colors.black};
    margin-top : 50px;
    margin-bottom : 12px;
`

export const GroupsBodyWrap = styled.div`
    padding: 40px 110px 0px 104px;
`

export const GroupsSearchWrap = styled.div` 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const SearchWrap = styled.div`
    padding: 20px 0px 17px 16px;
    width: 100%; 
    display: table;
`

export const SearchBarText = styled.h2`
    font-weight: 700; 
    font-size: 16px; 
    line-height: 23px; 
    color: #191E29;
    vertical-align: middle; 
    display: table-cell; 
    text-align: left;
`

export const SearchBarSearch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    
    background: ${({selected, disabled})=> {
        if(disabled){
            return '#F2F4F6;';
        }
        if(selected) {
            return '#E7EDFF;'
        }
    }};
    
    border : ${({selected, disabled})=> {
        if(disabled) {
            return '1px solid #EAEAEA;'
        }
        else if(selected) {
            return '1px solid #3A6AF6;'
        }
        return '1px solid #EAEAEA;'
    
    }};

    &.hidden { display:none; }
`

export const SearchBarRight = styled.div` 
     display: flex;
     justify-content: flex-end;
     padding-right: 16px;
`

export const MySearchBarRight = styled.div` 
     position: absolute;
     top: 17px;
     right: 16px;
`


export const SearchBarVisible = styled.div` 
     &.hidden { display:none; }
     display: flex;
     width: 100%;
     padding: 0px 10px 17px 16px;
     
     &.show{
        position : sticky;
     }
`

export const MyGroupsSearchWrap = styled.div` 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
    padding: 16px 0px 0px 16px;
`

export const MySearchWrap = styled.div`
    font-weight: 500; 
    font-size: 12px;
    line-height: 23px; 
    color: #AEB7C0;
`
export const MySearchBarVisible = styled.div` 
     &.hidden { display:none; }
     display: flex;
     width: 100%;
     padding: 10px 10px 10px 0px;
`

export const MySearchCategory = styled.div`
`

export const MySearchCategoryField = styled.fieldset` 
`

export const MySearchCategoryLabel = styled.label` 
`

export const MySearchCategorySpan = styled.span` 
    display: inline-block;
    width: 100px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    position: relative;
    padding: 5px 0px 15px;
 
    &.checked {
        color: #353C49; 
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;     
        
         &::after {
            background: #191E29;
            width: 100px;
            height: 2px;
            position: absolute;
            bottom: 0px;
            left: 0px;  
         }
    }
    
    &::after {  
        content: '';
    }
    
`

export const MyTopicCategoryWrap = styled.div`
    border-top: 1px solid #F1F5F9;
    padding: 12px 0px 0px 0px;
    position: relative;
`

export const TopicCategoryWrap = styled.div`
    padding: 0px 0px 0px 16px;
    position: relative;
`

export const TopicWrap = styled.div`
    display: flex; 
    overflow-x: auto;
    padding-bottom: 21px;
`

export const JoinGroupsWrap = styled.div`
    // margin-top : 50px;
`

export const JoinGroups = styled.div`
    width : 990px;
    display : flex;
    flex-wrap: wrap;
    gap : 20px 10px;
    // min-height : 332px;
    
    justify-content : ${({noResult})=>{
        if(noResult){
            return 'center';
        }    
    }};
    
    align-items : ${({noResult})=>{
        if(noResult){
            return 'center';
        }
    }};
    
    min-height : ${({noResult})=>{
        if(noResult){
            return '332px';
        }
    }};
    
    max-height : ${({hide})=>{
        if(hide){
            return '860px';
        }    
    }};
    
    overflow : ${({hide})=>{
        if(hide){
            return 'hidden';
        }
    }};
`

export const JoinGroupsButtonWrap = styled.div`
    display : flex;
    justify-content : center;
    margin-top : 30px;
`

export const GroupSearchSubTitle = styled.div`
    font-size: 16px;
    line-height: 23px;
    margin : 24px 0 18px 0;
    font-weight : 400;
`

export const GroupSearchSubTitleWord = styled.span`
    font-weight : 700;
`

export const RankGroupsWrap = styled.div`
     
`

export const RankListGroupWrap = styled.div`
    border-bottom: 8px solid #F1F5F9;
    padding: 0px 0px 19px 0px;
`

export const ListGroupWrap = styled.div`
    border-bottom: 8px solid #F1F5F9;
    padding-top: 20px;
`

export const RankGroupsJoinRankText = styled.h3`
    font-weight: 700; 
    font-size: 16px; 
    line-height: 24px; 
    color: #191E29; 
    margin-bottom: 10px; 
    padding: 0px 0px 0px 20px;
`

export const RankGroupsJoinRankImg = styled.img`
    margin: 4px 0px 0px 6px;
`

export const NoRankGroupWrap = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    padding : 50px 0;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: #AEB7C0;
`

export const RankGroupsJoinRankUl = styled.ul`
`

export const InterestGroupsCategoryWrap = styled.div`
    display: flex;
    padding 0px 16px 13px 16px;
`

export const InterestGroupsCategoryH3 = styled.h3`
    width: 100%;
    font-weight: 500; 
    font-size: 12px; 
    line-height: 18px;
`

export const InterestGroupsCategoryStrong = styled.strong`
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
`

export const InterestGroupsCategorySrotWrap = styled.div`
    display: flex;
`

export const InterestGroupsCategorySrot = styled.div`
    font-size: 12px; 
    line-height: 17px; 
    position: relative; 
    padding-left: 9px; 
    margin-left: 15px;
    
    &::after {
        content: ''; 
        width: 4px; 
        height: 4px; 
        background: #191E29; 
        position: absolute; 
        top: 7px; 
        left: 0px; 
        border-radius: 4px;
    }
    
    font-weight : ${({selected}) => {
        if(selected){
            return 900;
        }
        return 200;
    }};
    
    color : ${({selected}) => {
        if(selected){
            return '#3A6AF6';
        }
        return '#191E29';
    }};
`

export const ReferralGroupsWrap = styled.div`
    // margin-top : 50px;
`

export const GroupSearchResultWrap = styled.div`
    text-align: center; 
    padding: 30px 0px 7px;
    width : 100%;
`

export const ReferralGroups = styled.div`
    width : 990px;
    display : flex;
    flex-wrap: wrap;
    gap : 20px 10px;
`

export const GroupFABWrap = styled.div`
    position : absolute;
    bottom : 50px;
    left : 1032px;
`