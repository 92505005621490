import {
    NoResultImage,
    NoResultImageWrap,
    NoResultSubTitle,
    NoResultTitle,
    NoResultTitleWrap,
    NoResultWrap
} from "./NoResultStyles";

const NoResult = (props) => {

    const {src, title, subtitle} = props;

    return <NoResultWrap>
            <NoResultImageWrap>
                <NoResultImage src={src}/>
            </NoResultImageWrap>
            <NoResultTitleWrap>
                {title ? <NoResultTitle>{title}</NoResultTitle> : null}
                {subtitle ? <NoResultSubTitle>{subtitle}</NoResultSubTitle> : null}
            </NoResultTitleWrap>
        </NoResultWrap>;
}

NoResult.defaultProps = {
    src : '',
    title : undefined,
    subtitle : undefined
}
export default NoResult;