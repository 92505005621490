import styled from "styled-components";

export const ProfileMyGroupsItemsWrap = styled.div`
    padding: 0px 0px 10px 0px;
`

export const ProfileMyGroupsItemsTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    margin-bottom : 10px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
   
`

export const ProfileMyGroupsItems = styled.div`
    display : flex;
    flex-direction : column;
    gap : 12px;
`

