import React, { createContext, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// 뒤로가기 변수
const initialValue = {
  current : {
    isBack : false,  //뒤로가기
    isPush : false,  //새로들어가기
    backHistory : {},
    state : 'page',
    pageInModal: false
  }
}

export const BackHistoryContext = createContext(initialValue);

//pop, push 관리
const BackHistoryProvider = ({ children }) => {
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const backhistoryStore = useRef(initialValue.current);

  const history = useHistory();

  useLayoutEffect(() => {

    if (!firstLoad && history) {
      if (history.action === 'POP') {

        //모달일 경우 back이 아니므로 true 안시킴
        if (backhistoryStore.current.state  === 'modal') {
          backhistoryStore.current.state = 'page';
          backhistoryStore.current.pageInModal = true;
          return;
        }
         backhistoryStore.current.isBack = true;
         backhistoryStore.current.isPush = false;

      } else if (history.action === 'PUSH') {
          backhistoryStore.current.isPush = true;
          backhistoryStore.current.isBack = false;
      } else{
          backhistoryStore.current.isPush = false;
          backhistoryStore.current.isBack = false;
      }

    }

    if (firstLoad) setFirstLoad(false);
  }, [location.pathname]);

  return (
    <BackHistoryContext.Provider value={backhistoryStore}>
      {children}
    </BackHistoryContext.Provider>
  );
};

export default BackHistoryProvider;