import React from 'react';
import {
    HomeTopListTopStyle,
    HomeTopListMiddleStyle,
    HomeTopListWrapStyle,
    HomeTopCard,
    HomeTopCardWrap,
    HomeTopListVoteBottom,
    HomeTopListVoteDtBottom,
    HomeTopListVoteDtSpanBottom,
    HomeTopListVoteDdBottom,
    HomeTopListVoteDdSpanBottom,
    HomeTopListBottomImgaeStyle,
    HomeTopListReactionBottom,
    HomeTopListReactionBottomLike,
    HomeTopListReactionBottomComment,
    HomeTopNoItem
} from "./HomeTopStyles";
import congratulationsImage from "../../../resources/images/congratulations_bg.png";
import childBirthImage from "../../../resources/images/childBirth.png";
import iconCommentImage from "../../../resources/images/icon_comment.png";
import UnLikeIcon from '../../../resources/icons/unlike.svg'
import LikeIcon from '../../../resources/icons/like_filled_red.svg'
import {stringToCost} from "../../../utils/textHandler";
import {useStores} from "../../../stores";
import {getArrayFromSelectVoteObject} from "../../../utils/itemContentHandler";
import {useTranslation} from "react-i18next";


const HomeTopListComponent = (probs) => {

    const {store} = useStores();
    const {modalDetailStore} = store;
    const item = probs?.item;
    const id = item.itemId;
    const callBackOnClick = probs?.onClick;
    const key = `today-hot-${id}`;
    const topicName = item.topicName;
    const likesCount = item.likesCount;
    const commentCount = item.commentCount;
    const likesDate = item.likesDate;
    const type = item?.type;

    const {t} = useTranslation();

    /**
     * 클릭 이벤트
     */
    const onClickEvent = () => {
        if(callBackOnClick && typeof callBackOnClick === 'function') {

            /**
             * 모달 창
             */
            //소통해요 'f'로 날라옴
            // 모달 디테일 생성
            modalDetailStore.init(
                'c',                   //'c', 'g' 타입
                item.itemId,           // 아이템Id
                onDeleteBoardItem,     // 닫기
                onDeleteBoardItem,     // 삭제
                onDeleteBoardItem      // 에러
            );

            callBackOnClick();
        }
    }

    // 그룹 게시글 삭제 이벤트
    const onDeleteBoardItem = () => {
    }

    // 토픽에 따른 이름 return
    const getCategoryName = (topicName) => {
        switch (topicName) {
            case 'topic.vote' :
                return t('PART_LIST');
            case 'topic.free-talk' :
            case 'topic.discount' :
            case 'topic.question' :
            case 'topic.work' :
                return t('REACTION_LIST');
            case 'topic.marriage' :
            case 'topic.childbirth' :
                return t('CONGRATULATION_LIST');
            default: {
                return (
                    null
                )
            }
        }
    }

    const bottomComponent = (topicName) => {
        // 모달 폼 형식
        switch (topicName) {
            case 'topic.vote' : {
                if(type==='noItem'){
                    return <HomeTopNoItem>{t('NO_HOT_TOPIC_ITEM')}</HomeTopNoItem>
                }

                // 투표 1등 필드
                const topicTopVoteField = item.voteStatus.topVoteField;
                // 투표 1등 수
                const topicTopVoteTotalCount = item.voteStatus.userCount;
                // 투표 총 수
                const topicTopVoteItemCount = item.voteStatus[topicTopVoteField] === 0 ? 1 : item.voteStatus[topicTopVoteField];
                // 투표 1등 퍼센트
                const topicTopVotePercent = ((100 / topicTopVoteTotalCount) * topicTopVoteItemCount).toFixed(0) + '%';

                // 투표아이템 배열
                const voteItem = getArrayFromSelectVoteObject(item.props, topicTopVoteField);
                const topicTopVoteName = voteItem[0];

                return (
                    <HomeTopListVoteBottom>
                        <HomeTopListVoteDtBottom>
                            1위 : {topicTopVoteName}
                            <HomeTopListVoteDtSpanBottom>
                                {topicTopVotePercent}
                            </HomeTopListVoteDtSpanBottom>
                        </HomeTopListVoteDtBottom>
                        <HomeTopListVoteDdBottom>
                            <HomeTopListVoteDdSpanBottom percent = {topicTopVotePercent}/>
                        </HomeTopListVoteDdBottom>
                    </HomeTopListVoteBottom>
                )
            }
            case 'topic.free-talk' :
            case 'topic.discount' :
            case 'topic.question' :
            case 'topic.work' : {
                if(type==='noItem'){
                    return <HomeTopNoItem>{t('NO_HOT_TOPIC_ITEM')}</HomeTopNoItem>
                }
                return (
                    <HomeTopListReactionBottom>
                        <HomeTopListReactionBottomLike src = {likesDate ? LikeIcon : UnLikeIcon}/>
                        <span>{ likesCount === 0 ? likesCount : stringToCost(likesCount)}</span>
                        <HomeTopListReactionBottomComment src = {iconCommentImage}/>
                        <span>{ commentCount === 0 ? commentCount : stringToCost(commentCount)}</span>
                    </HomeTopListReactionBottom>
                )
            }
            case 'topic.marriage' : {
                if(type==='noItem'){
                    return <HomeTopNoItem>{t('NO_HOT_TOPIC_ITEM')}</HomeTopNoItem>
                }
                return (
                    <HomeTopListBottomImgaeStyle src = {congratulationsImage}/>
                )
            }
            case 'topic.childbirth' : {
                return (
                    <HomeTopListBottomImgaeStyle src = {childBirthImage}/>
                )
            }
            default: {
                return (
                    null
                )
            }
        }
    }

    return(
        <HomeTopListWrapStyle id = {id} key = {key} topicName={topicName} onClick={onClickEvent}>
            <HomeTopCard>
                <HomeTopCardWrap>
                    <HomeTopListTopStyle>{getCategoryName(topicName)}</HomeTopListTopStyle>
                    <HomeTopListMiddleStyle>{item.title}</HomeTopListMiddleStyle>
                    {bottomComponent(topicName)}
                </HomeTopCardWrap>
            </HomeTopCard>
        </HomeTopListWrapStyle>
    )
}

export default HomeTopListComponent;