import {useStores} from "../../../stores";
import React, {useEffect, useRef, useState} from "react";
import {
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollNoBottomBox,
    ModalTitle
} from "../global/ModalStyles";
import {IconButton, NoResult, Profile} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import SecretIcon from "../../../resources/icons/comment_secret.svg";

import {
    ProfileWrap,
    RepStickerInfoWrap,
    ViewProfileContentsWrap,
    ViewProfileGroup,
    ViewProfileGroupName,
    ViewProfileGroupUsers,
    ViewProfileItem,
    ViewProfileItemContent,
    ViewProfileItemDate,
    ViewProfileItemTitle,
    ViewProfileItemWrap,
    ViewProfileMenu,
    ViewProfileMenus,
    ViewProfileModalWrap,
    ViewProfileNoResultWrap,
    ViewProfileProfileSetting,
    ViewProfileProfileSettingWrap,
    ViewProfileProfileWrap,
    ViewProfileRepStickerDescript,
    ViewProfileRepStickerName,
    ViewProfileRepStickerWrap,
    ViewProfileSticker,
    ViewProfileStickerDesc,
    ViewProfileStickerInfoWrap,
    ViewProfileStickerName,
    ViewProfileTabWrap
} from "./VeiwProfileModalStyles";
import Img from "../global/Img";
import {stringToCost} from "../../../utils/textHandler";
import {getDateFrom} from "../../../utils/dateHandler";
import {ServerConfig} from "../../../commons/config";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {ThreeDots} from "react-loader-spinner";
import NoPostImg from "../../../resources/icons/no_post.svg";
import {receiveResponse} from "../../../utils/api";
import env from "../../../constants/env";
import {useHistory} from "react-router-dom";
import routes from '../../../constants/routes.json';
import moment from "moment";

const ViewProfileModal = (props) => {

    const {store} = useStores();
    const {modalDetailStore, userStore, modalDetailParentStore} = store;

    // 스크롤 요소
    const scrollRef = useRef(null);

    const history = useHistory();
    const menus = [
        {id : 'items', name : '소통해요 활동'},
        {id : 'groups', name : '참여중인 그룹'},
        {id : 'stickers', name : '획득한 스티커'}
    ]

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    //데이터
    const data = modalPopupStore;
    const userId = modalPopupStore.detailItem.userId;

    const [user, setUser] = useState(null);
    const [active, setActive] = useState(menus[0].id);
    const [items, setItems] = useState(null);
    const [groups, setGroups] = useState(null);
    const [stickers, setStickers] = useState(null);
    const [repSticker, setRepSticker] = useState(null);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(new Array(menus.length).fill(true));
    const [isError, setIsError] = useState(new Array(menus.length).fill(false));
    const [modalState, setModalState] = useState({
        active : menus[0].id,
        page : 0
    });

    // 무한 스크롤 중복 방지
    const preventRef = useRef(null);
    // 무한 스크롤 옵저버 요소
    const obsRef = useRef(null);
    // 마지막 페이지 등록 여부
    const endRef = useRef(null);

    const testRef = useRef(null);
    /**
     * 닫기 이벤트
     * @param e
     */
    const onCloseHandler = (e) => {
        //닫기 버튼
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    // 탭메뉴 생성
    const createMenus = () => {
        return (
            <ViewProfileMenus>
                {menus.map(
                    menu=>(
                        <ViewProfileMenu
                            className={menu.id === active ? 'active' : ''}
                            onClick={e=>setActive(menu.id)}
                        >
                            {menu.name}
                        </ViewProfileMenu>
                    )
                )}
            </ViewProfileMenus>
        )
    }

    // 탭 메뉴 컨텐츠 생성
    const createMenusContent = (active) => {
        if(active==='items') return createItems(items);
        else if(active==='groups') return createGroups(groups);
        else if(active==='stickers') return createStickers(stickers);
    }

    // 소통해요 활동
    const createItems = (items) => {

        // 로딩중일 경우
        if(isLoading[0]){
            return <ThreeDots
                height={"10"}
                wrapperStyle={{
                    width : '100%',
                    justifyContent : 'center',
                    height : '100%',
                    paddingTop : '30px',
                    alignItems : 'center'}}
                color={'#3A6AF6'}
            />
        }

        // api error 난 경우
        if(isError[0]){
            return 'error'
        }

        if(!items) return ;
        else {
            // 작성한 글이 없을 경우
            if(items.length === 0 ){
                return (
                    <ViewProfileNoResultWrap>
                        <NoResult src={NoPostImg} title={'소통해요 활동이 없어요.'}/>
                    </ViewProfileNoResultWrap>
                )
            }

            return items.map(item=>{
                return (
                    <ViewProfileItemWrap>
                        <ViewProfileItem onClick={e=>onClickItem(item.itemId)}>
                            <ViewProfileItemTitle>{item.title}</ViewProfileItemTitle>
                            <ViewProfileItemContent>{item.content}</ViewProfileItemContent>
                            <ViewProfileItemDate>{getDateFrom(item.createDate)}</ViewProfileItemDate>
                        </ViewProfileItem>
                    </ViewProfileItemWrap>
                )
            })
        }
    }

    // 참여중인 그룹
    const createGroups = (groups) => {

        // 로딩중일 경우
        if(isLoading[1]){
            return <ThreeDots
                height={"10"}
                wrapperStyle={{
                    width : '100%',
                    justifyContent : 'center',
                    height : '100%',
                    paddingTop : '30px',
                    alignItems : 'center'}}
                color={'#3A6AF6'}
            />
        }
        // api error 난 경우
        if(isError[1]) return 'error'

        if(!groups) return;

        else{

            if(groups.length===0){
                return (
                    <ViewProfileNoResultWrap>
                        <NoResult src={NoPostImg} title={'참여중인 그룹이 없어요.'}/>
                    </ViewProfileNoResultWrap>
                )
            }

            return groups.map(group=>{
                return(
                    <ViewProfileItemWrap>
                        <ViewProfileGroup onClick={e=>onClickGroup(group.groupId)}>
                            <Img
                                wrapStyle={{width : '31px', height : '31px', flex : 'none'}}
                                src={`${ServerConfig.default.IMG_URL}${group.imgId}`}
                            />
                            <ViewProfileGroupName>{group.groupName}</ViewProfileGroupName>
                            {
                                getBoolFromYn(group.privateYn) ?
                                    <IconButton width={'16px'} height={'16px'} src={SecretIcon} /> : null
                            }
                        </ViewProfileGroup>
                        <ViewProfileGroupUsers>{`${stringToCost(group.userCount)}명 참여중`}</ViewProfileGroupUsers>
                    </ViewProfileItemWrap>
                )
            })
        }
    }

    // 획득한 스티커
    const createStickers = (stickers) => {

        // 로딩중일 경우
        if(isLoading[2]){
            return <ThreeDots
                height={"10"}
                wrapperStyle={{
                    width : '100%',
                    justifyContent : 'center',
                    height : '100%',
                    paddingTop : '30px',
                    alignItems : 'center'}}
                color={'#3A6AF6'}
            />
        }
        // api error 난 경우
        if(isError[2]) return 'error'

        if(!stickers) return;

        else{
            if(stickers.length===0){
                return (
                    <ViewProfileNoResultWrap>
                        <NoResult src={NoPostImg} title={'획득한 스티커가 없어요.'}/>
                    </ViewProfileNoResultWrap>
                )
            }

            let sorted = stickers.sort((a,b)=>{
                if(moment(b.gainedDate) - moment(a.gainedDate)>0) return 1;
                else if(moment(b.gainedDate) - moment(a.gainedDate)<0) return -1;
                else return b.sortOrder - a.sortOrder;
            });

            return sorted.map(sticker=>{
                return(

                    <ViewProfileItemWrap>
                        <ViewProfileSticker>
                            <Img
                                wrapStyle={{width : '40px', height : '40px', flex : 'none'}}
                                src={`${ServerConfig.default.IMG_URL}${sticker.imgId}`}
                            />
                            <ViewProfileStickerInfoWrap>
                                <ViewProfileStickerName>{sticker.nameText}</ViewProfileStickerName>
                                <ViewProfileStickerDesc>{sticker.criteriaText}</ViewProfileStickerDesc>
                            </ViewProfileStickerInfoWrap>
                        </ViewProfileSticker>
                    </ViewProfileItemWrap>
                )
            })
        }
    }

    const createRepStickers = (rep) => {

        return(
            <>
                <Img
                    wrapStyle={{width : '60px', height : '60px'}}
                    src={`${ServerConfig.default.IMG_URL}${rep?.imgId}`}
                />
                <RepStickerInfoWrap>
                    <ViewProfileRepStickerName>{rep?.nameText}</ViewProfileRepStickerName>
                    <ViewProfileRepStickerDescript>{rep?.descriptionText}</ViewProfileRepStickerDescript>
                </RepStickerInfoWrap>
                {/*<IconButton width={'20px'} height={'20px'} src={MoreIcon}/>*/}
            </>
        )
    }

    const onClickItem = (itemId) => {
        onCloseHandler();
        modalDetailStore.init(
            'c',             //'c', 'g' 타입
            itemId           // 아이템Id
        );
    }

    const onClickGroup = (groupId) => {
        modalPopupStore.closeModalPopup();
        if(modalDetailStore.isOpen) modalDetailStore.closeModalPopup();
        if(modalDetailParentStore.isOpen) modalDetailParentStore.closeModalPopup();
        history.push(`${routes.GROUPS}/${groupId}`);
    }

    const onClickSetting = () => {
        modalPopupStore.closeModalPopup();
        if(modalDetailStore.isOpen) modalDetailStore.closeModalPopup();
        if(modalDetailParentStore.isOpen) modalDetailParentStore.closeModalPopup();
        history.push(`${routes.PROFILE}/setting`)
    }

    const setIsLoadingAt = (idx, bool) => {
        let _isLoading = isLoading;
        _isLoading[idx] = bool;
        setIsLoading(_isLoading);
    }

    const setIsErrorAt = (idx, bool) => {
        let _isError = isError;
        _isError[idx] = bool;
        setIsError(_isError);
    }
    /**
     * API
     */
    const getUser = () => {
        receiveResponse({}, `user/get/${userId}`, 'get')
            .then(res=>{
                if(res.status===200){
                    setUser({...res.data});
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    const getItems = () => {

        // setTimeout(()=>{
        //     setIsLoading([false,false,false]);
        // },1000)

        let param = {
            size : env.PROFILE_ITEM_PAGE_SIZE,
            type: "F",
            page : page,
            regUserid : userId
        }

        receiveResponse(param,'items','get')
            .then(res=>{
                if(res.status===200){
                    if(page===0){
                        setItems([...res.data.list]);
                    }else{
                        setItems([...items].concat(res.data.list));
                    }
                    setIsLoadingAt(0,false);
                    preventRef.current = true;
                    endRef.current = res.data.end;
                }
            })
            .catch(e=>{
                setIsErrorAt(0,true);
            })
    }

    const getGroups = () => {
        receiveResponse({target: 'joined', userId}, 'groups', 'get')
            .then(res=>{
                if(res.status===200){
                    setGroups(res.data.list);
                    setIsLoadingAt(1,false);

                }
            })
            .catch(e=>{
                setIsErrorAt(1,true);
            })
    }

    const getStickers = () => {
        receiveResponse({}, `user/${userId}/stickers`, 'get')
            .then(res=>{
                if(res.status===200){
                    setStickers(res.data.filter(sticker=>sticker.owned));
                    setRepSticker(res.data.find(sticker=>sticker.represent))
                    setIsLoadingAt(2,false);
                }
            })
    }

    const initDate = () => {
        setUser(null);
        setItems(null);
        setActive(menus[0].id);
        setGroups(null);
        setStickers(null);
        setPage(0);
        setIsLoading(new Array(menus.length).fill(true));
        setIsError(new Array(menus.length).fill(false));
        setRepSticker(null);
        preventRef.current = null;
        endRef.current = null;

        let _elements = document.getElementsByClassName('view-profile-contents');

        if(_elements?.length > 0){
            for(let i=0;i<_elements.length;i++){
                _elements.item(i).scrollTop = _elements.item(i).scrollHeight;
            }
        }
    }

    //보관함 Observer 핸들러 함수
    const obsHandler = ((entries) => {
        // 보관함일 떄만
        if (active === 'items') {
            const target = entries[0];
            if (target.isIntersecting && preventRef.current) {
                if (endRef.current) return;
                preventRef.current = false;
                setPage((page) => page+1);
            }
        }
    });

    // 맨위로 스크롤 이동 (탭 바뀔 때 마다 스크롤 맨 위로)
    const onMoveScrollUpEvent = () => {
        let e = document.querySelector('#scroll-modal-box');
        if(e) e.scrollTop = 0;
    }

    // const handleScroll = () => {
    //     const scroll = document.querySelector("#scroll-modal-box");
    //     const writer = document.querySelector('#modal-modalWrite');
    //     if (scroll?.scrollTop === 0) {
    //         writer?.classList?.add('hide');
    //     }else {
    //         writer?.classList?.remove('hide');
    //     }
    // };

    useEffect(()=>{
        // 모달창 오픈될 때 아이템들 가져오기
        if(data.isOpen){
            getItems();
            getGroups();
            getStickers();
            getUser();
        }else{
            // 데이터 초기화
            initDate();
        }
    },[data.isOpen])

    useEffect(()=>{
        // page가 0이 아닐 때만 (0일 경우, isOpen useEffect에서 가져옴)
        if(page!==0) getItems()
    },[page])

    useEffect(()=>{
        onMoveScrollUpEvent();
        const observer = new IntersectionObserver(obsHandler, {
            threshold : 0
        });
        if(obsRef.current) observer.observe(obsRef.current);
        return ()=>{observer.disconnect()}
    },[active])


    /*스크롤 이벤트*/
    // useEffect(() => {
    //     const scroll = document.querySelector("#scroll-modal-box");
    //     scroll?.addEventListener('scroll', handleScroll);
    //     return () => {
    //         scroll.removeEventListener('scroll', handleScroll); //clean up
    //     };
    // }, []);

    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* x버튼 컴포넌트*/}
            <ModalHeaderWrap>

                {/* 전체 타이틀 컴포넌트*/}
                <ModalTitle>{'프로필 상세보기'}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'} height={'32px'} onClick={onCloseHandler}
                                src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>

            <ModalScrollNoBottomBox id={'scroll-modal-box'} ref={scrollRef}>

                <ViewProfileModalWrap>

                    {/*프로필 사진*/}
                    <ViewProfileProfileWrap>
                        <ProfileWrap>
                            { user ? <Profile type={'profile-view'} user={user} useThumb={false} /> : null }
                        </ProfileWrap>
                        {
                            userStore.user.userId === user?.userId ?
                                <ViewProfileProfileSettingWrap onClick={onClickSetting}>
                                    {/*<IconButton src={SettingIcon} />*/}
                                    <ViewProfileProfileSetting>프로필 설정 바로가기</ViewProfileProfileSetting>
                                </ViewProfileProfileSettingWrap> : null
                        }
                    </ViewProfileProfileWrap>

                    {/*렙 스티커*/}
                    <ViewProfileRepStickerWrap onClick={e=>setActive('stickers')}>
                        {createRepStickers(repSticker)}
                    </ViewProfileRepStickerWrap>

                    {/*텝*/}
                    <ViewProfileTabWrap>

                        {/*토픽*/}
                        {createMenus()}

                        {/*리스트*/}
                        <ViewProfileContentsWrap>
                            { active==='items' ? createItems(items) :
                                active==='groups' ? createGroups(groups) :
                                    active==='stickers' ? createStickers(stickers) : null
                            }
                            <div ref={obsRef}></div>
                        </ViewProfileContentsWrap>

                    </ViewProfileTabWrap>

                </ViewProfileModalWrap>

            </ModalScrollNoBottomBox>

            {/*/!*모달*!/*/}
            {/*<ViewProfileQuickWrap>*/}
            {/*    <ViewProfileFABWrap className={'hide'} id={'modal-modalWrite'}>*/}
            {/*        <IconButton width={'50px'} height={'50px'} src={upMoveIcon} onClick={onMoveScrollUpEvent}/>*/}
            {/*    </ViewProfileFABWrap>*/}
            {/*</ViewProfileQuickWrap>*/}


        </ModalContainer>
    )
}

ViewProfileModal.defaultProps = {
    userId : undefined
}

export default ViewProfileModal;