import styled from "styled-components";

export const GroupsTopWrapStyle = styled.div`
`


export const GroupsTopTitleImageWrap = styled.div`
    position: relative;
    padding: 22px 20px 18px 146px; 
    background: #E5EBF0;
`

export const GroupsTopTitleStyle = styled.p`
      font-size: 14px; 
      line-height: 20px;
`

export const GroupsTopTitleImgStyle = styled.img.attrs(props => ({
    src: props.src
}))`
    position: absolute;
    bottom: 0px;
    left: 20px;
`


export const GroupsTopTitleImportantStyle = styled.strong`
      font-weight: bold;
`

export const GroupsTopListStyleWrap = styled.div`
      height: 238px;         // 스크롤 문제 때문에 해놈
      
      &.isMyGroups {
        height: 271px;        // 스크롤 문제 때문에 해놈
      }
     
      background: #F7F9FB;
      padding: 20px 0px 0px 16px;
      position: relative;
      
      &>a {
          font-weight: 500; 
          font-size: 14px; 
          line-height: 23px; 
          color: #AEB7C0; 
          position: absolute;
          top: 25px;
          right: 20px;
      }
`

export const GroupsTopListTitleStyle= styled.h2`
      background: none; 
      margin-bottom: 18px;
      font-weight: 700; 
      font-size: 20px; 
      line-height: 29px; 
      display: inline-block;
`

export const GroupsTopListEntireImgStyle= styled.img`

`

export const GroupsTopListStyle = styled.ul`
      display: flex; 
      margin: 0 auto; 
      overflow-x: auto;
      padding-bottom: 24px;
      overflow-y : hidden;
`

export const GroupsTopListButtonStyle = styled.div`
      position: absolute; 
      top: 25px; 
      right: 16px; 
      line-height: 23px; 
      font-size: 16px;
`

export const GroupsGatherBestPrev = styled.img`
      width: 25px; 
      height: 25px; 
      display: inline-block;
      float: left; 
      margin-right: 4px;
      
      opacity: ${({ prevPage }) => {
          if(prevPage === 1){
              return '0.5;';
          }
      }}
         
     cursor: ${({ prevPage }) => {
         if(prevPage === 1){
             return 'unset;';
         }
     }}
`

export const GroupsGatherBestNext = styled.img`
      width: 25px; 
      height: 25px; 
      display: inline-block;
      float: right; 
      margin-left: 4px;
      
      opacity: ${({ prevPage, nextPage }) => {
          if(prevPage === nextPage){
              return '0.5;';
          }
      }}
     
      cursor: ${({ prevPage, nextPage }) => {
          if(prevPage === nextPage){
              return 'unset;';
          }
      }}
`

export const GroupsGatherBestPrevPage = styled.em`
`




export const GroupsTopListWrapStyle = styled.li`
      background: #ffffff;
      border-radius: 20px;
      margin-right: 10px;
      &:last-child {
          margin-right: 0px;
      }
`

export const GroupsTopCard = styled.div`
     height: 147px;
     
     &.isMyGroups {
        height: 180px;
     }
     
     width: 310px;
     display: block;
     padding: 20px;
`

export const GroupsTopCardWrap = styled.div`
     display: flex;
     width: 100%;
`

export const GroupsTopCardImg = styled.img`
    width: 24px;
    height: 24px;
    object-fit : contain;
    margin-right: 7px;
    display: inline-block;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
`

export const GroupsTopListTopStyle = styled.h4`

     font-size: 16px; 
     line-height: 23px; 
     background-color: unset;
     // padding: 0px;
     font-weight: 700; 
     // margin-bottom: 10px; 
     // text-align: center; 
     // display: inline-block; 
     // opacity: 0.9; 
     // border-radius: 17.5px;
     
     overflow: hidden; 
     text-overflow: ellipsis; 
     display: -webkit-box; 
     -webkit-line-clamp: 1; 
     word-break: break-all;
     -webkit-box-orient: vertical;
`

export const GroupsTopListTopDate = styled.div`
     font-style: normal;
     font-family: 'Noto Sans KR';
     float: right;
     margin-right: 7px; 
     font-weight: 500; 
     font-size: 16px; 
     line-height: 23px; 
     align-items: center;
     color: #AEB7C0;
`

export const GroupsTopListBottom = styled.dl`
    display: inline-block;
    width: 100%;
    font-size: 0px;
    
    // padding: 16px 20px; 
    // border-radius: 20px; 
    // background: #ffffff;
    // min-height: 117px;
    // &:hover {
    //     box-shadow: 2px 4px 20px rgba(117, 129, 141, 0.2);
    //     transition: box-shadow 0.1s linear;
    // }
`

export const GroupsTopListDtBottom = styled.dt`
    // margin-bottom: 10px; 
    // font-weight: 700; 
    // font-size: 17px; 
    // line-height: 25px; 
    
    
    font-weight: 700; 
    font-size: 16px; 
    line-height: 23px;
    padding: 12px 0px 0px 0px;
    margin-bottom: 6px;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    word-break: break-all;
    -webkit-box-orient: vertical;
`

export const GroupsTopListDdBottom = styled.dd`
    height: 37px;
    font-size: 12px; 
    line-height: 19px; 
    color: #AEB7C0;
    // height: 50px;
    // font-family: 'Noto Sans KR';
    // font-size: 17px; 
    // font-style: normal;
    // font-weight: 400;
    // line-height: 25px; 
    // color: #AEB7C0; 
    width: 100%; 
    background: none; 
    
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    word-break: break-all;
    -webkit-box-orient: vertical; 
`

export const GroupsTopListDdBestBottom = styled.dd`
    font-weight: 500; 
    font-size: 12px; 
    line-height: 24px; 
    display: flex; 
    align-items: center; 
    color: #AEB7C0; 
    border-top: 1px solid #E5EBF0; 
    margin-top: 10px;
    background: none;
    padding: 0px;
    padding-top: 10px; 
    border-radius: 0px;
`

export const GroupsTopListDdBestImgBottom = styled.img`
    display: inline-block; 
    position: relative; 
    width: 20px; 
    height: 20px; 
    border-radius: 13px; 
    background: #D9D9D9; 
    background-repeat: no-repeat; 
    vertical-align: top; 
    margin-right: 5px; 
    background-size: cover;
`

export const GroupsTopListDdBestStrongBottom = styled.strong`
    position: relative; 
    font-weight: 500; 
    font-size: 12px; 
    line-height: 24px; 
    display: flex; 
    align-items: center; 
    color: #191E29; 
    margin-right: 14px;
    
    &::after {
        content: ''; 
        width: 2px; 
        height: 2px; 
        background: #191E29; 
        border-radius: 2px; 
        position: absolute; 
        top: 12px; 
        right: -8px;
    }
`