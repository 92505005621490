import styled from "styled-components";

export const HomeTopWrapStyle = styled.div`
      height: 238px;
      background: #F7F9FB;
      padding: 20px 0px 0px 16px;
`

export const HomeTopTitleStyle = styled.h2`
      font-weight: 700; 
      font-size: 20px; 
      line-height: 29px; 
      margin-bottom: 18px; 
      display: inline-block; 
`

export const HomeTopListStyle = styled.ul`
      display: flex; 
      margin: 0 auto; 
      overflow-x: auto;
      padding-bottom: 24px;
      // justify-content: center;
`

export const HomeTopListWrapStyle = styled.li`
      height: 147px;
      
      // &:hover {
      //       box-shadow: 2px 4px 20px rgba(117, 129, 141, 0.2);
      //       transition: box-shadow 0.1s linear;
      // }
      
      margin-right: 14px;
      background: #EDF0FF; 
      border-radius: 20px;
      
      background: ${({ topicName }) => {
          if(topicName === 'topic.vote') {
              return  '#EDF0FF';
          } else if (topicName === 'topic.marriage' || topicName === 'topic.childbirth')
            return  '#F7F2F9 ';
          else {
            return  '#E4F4EC';
          }
      }}
`

export const HomeTopCard = styled.div`
     height: 100%; 
     display: block; 
     padding: 20px;
     width: 310px; 
`

export const HomeTopCardWrap = styled.div`
     display: inline-block; 
     width: 100%;
`

export const HomeTopListTopStyle = styled.h3`
     font-weight: 700; 
     font-size: 12px; 
     line-height: 17px; 
     margin-bottom: 10px; 
     text-align: center; 
     display: inline-block; 
     padding: 6px 10px 7px 10px; 
     background: #ffffff; 
     opacity: 0.9; 
     border-radius: 17.5px;
`

export const HomeTopListMiddleStyle = styled.p`
     font-weight: 700; 
     font-size: 16px; 
     line-height: 23px; 
     // padding: 0px 10px; 
     overflow: hidden; 
     text-overflow: ellipsis; 
     display: -webkit-box; 
     -webkit-line-clamp: 1; 
     -webkit-box-orient: vertical;
     word-break: break-all;
`

export const HomeTopNoItem = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #AEB7C0;
    padding-top : 8px;
`

export const HomeTopListVoteBottom = styled.dl`
    display: inline-block; 
    width: 100%; 
`

export const HomeTopListVoteDtBottom = styled.dt`
    font-weight: 400; 
    font-size: 12px; 
    line-height: 17px; 
    margin: 12px 0px 6px 0px;
`

export const HomeTopListVoteDtSpanBottom = styled.span`
    font-weight: 700; 
    float: right;
`

export const HomeTopListVoteDdBottom = styled.dd`
    width: 100%; 
    height: 9px; 
    background: #f6f7ff; 
    border-radius: 10px;
`

export const HomeTopListVoteDdSpanBottom = styled.span`
    height: 9px; 
    display: inline-block; 
    background: #3A6AF6; 
    border-radius: 10px; 
    vertical-align: top;
    width: ${({ percent }) => {
        return percent;
    }}
`


export const HomeTopListReactionBottom = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    padding: 20px 10px 0px 0px;
    align-items: center;
`

export const HomeTopListReactionBottomLike = styled.img`
    padding-right: 5px;
`

export const HomeTopListReactionBottomComment = styled.img`
    padding-left: 15px;
    padding-right: 5px;
`


export const HomeTopListBottomImgaeStyle = styled.img`
    height: 65px;
    min-width: 270px;
`

