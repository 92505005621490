import styled from "styled-components";

export const ListWrap = styled.div`
    display : flex;
    flex-direction : column;
    border : ${({theme})=>{
        return `1px solid #3A6AF6`;    
    }};
    width : ${({width})=>width ? width : ''};
    padding : 8px 6px;
    border-radius: 12px;
    max-height : 210px;
    max-height : ${({type})=>{
        if(type==='thin'){
            return '210px';
        }
        return '210px';
    }};
    // box-sizing : border-box;
    outline : none;
    background : ${({theme})=>theme.colors.white};
`;

export const ListItemsWrap = styled.div`

    overflow : overlay;
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
    
        background-color: #E5EBF0; 
        border-radius: 26px; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
    
        background-color: none; 
        border-radius: 26px;
    }
    
`

export const ListItem = styled.div`

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    border-radius: 12px;
    font-weight : 400;
    padding : ${({type})=>{
        if(type==='thin'){
            return '4px 8px';
        }
        return '5px 10px';
    }};
    line-height : ${({type})=>{
        if(type==='thin'){
            return '19px';
        }
        return '20px';
    }};
    font-size : ${({type})=>{
        if(type==='thin'){
            return '12px';
        }
        return '13px';
    }};
    margin : ${({type})=>{
        if(type==='thin'){
            return '0px 0';
        }
        return '4px 0';
    }};
    background : ${({theme,isSelected})=>{
        if(isSelected) {
            return theme.colors.gray_stroke_50;
        }     
    }};
    
    display : flex;
    gap : 10px;
    align-items : center;
    
    // &:hover {
    //     background : {({theme})=>theme.colors.gray_stroke_50};
    // };
`;

export const ListItemEmail = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color : ${({theme})=>theme.colors.black};
`

export const ListItemName = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
`

export const ListItemCompany = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color : ${({theme})=>theme.colors.gray_50};
`

export const ListItemNameText = styled.div`
    overflow : hidden;
    text-overflow : ellipsis;
`