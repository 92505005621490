import {useStores} from "../../../stores";
import {observer} from "mobx-react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    ProfileItemsButtons,
    ProfileItemsContainer,
    ProfileItemsList,
    ProfileItemsNav,
    ProfileItemsNoResultWrap,
    ProfileItemsPreBtn,
    ProfileItemsPreBtnImg,
    ProfileItemsTitle,
    ProfileItemsWrap, ProfileNoResultWrap, SearchWrap
} from "./ProfileItemsStyles";
import {Button, NoResult} from "../global";
import React, {useEffect, useRef, useState} from "react";
import NoPostImg from "../../../resources/icons/no_post.svg";
import {ProfileMyGroupsItems, ProfileMyGroupsItemsTitle, ProfileMyGroupsItemsWrap} from "./ProfileMyGroupsStyles";
import ProfileGroup from "../global/ProfileGroup";
import SearchPrevBtn from "../../../resources/mImages/array_prev.svg";

const ProfileMyGroups = (props) => {

    const {store} = useStores();
    const {userStore, modalPopupStore} = store;
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();

    // 무한 스크롤 중복 방지
    const preventRef = useRef(null);
    // 무한 스크롤 옵저버 요소
    const obsRef = useRef(null);
    // 마지막 페이지 등록 여부
    const endRef = useRef(null);

    const visibleRef = useRef(true);

    const {title} = props;

    const [itemsState, setItemsState] = useState({activeMenu : 0, activeGroup : 0, page : 0})
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [items, setItems] = useState(null);
    const [isVisible, setIsVisible] = useState(true);

    const [activeMenu, setActiveMenu] = useState(0);

    // 상단 메뉴 리스트
    const menus = [
        {type : 'ALL', name : t('ALL')},
        {type : 'PARTICIPATING_GROUP', name : t('PARTICIPATING_GROUP')},
        {type : 'APPLYING_GROUP', name : t('APPLYING_GROUP')},
        {type : 'INVITING_GROUP', name : t('INVITING_GROUP')},
    ];

    /******************************************************************************************************************/
    /**
     * 컴포넌트
     * @param e
     */

    // 메뉴 버튼 생성
    const createMenus = () => {
        return menus.map((menu,idx)=>(
            <Button
                shape={'circle'}
                wrapperStyle={{flex : 'none', alignItems : 'center', padding: `${idx}` === 0 ? '5px 12px 4px 12px' : '5px 9px 4px 9px'}}
                width={'fit-content'}
                height={'28px'}
                textStyle={{lineHeight:'17px', display : 'flex'}}
                type={idx === activeMenu ? 'primary' : 'secondary'}
                onClick={e=>onClickMenu(idx)}
            >{menu.name}</Button>
        ))
    }

    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    // 뒤로가기 이벤트
    const onBackHistoryClickEvent = (e) => {
        e.preventDefault();
        history.goBack();
    }

    // 버튼 누르는 경우
    const onClickMenu = (idx) => {
        switch (menus[idx].type){
            case 'PARTICIPATING_GROUP':
                userStore.updateJoinGroups();
                break;
            case 'APPLYING_GROUP' :
                userStore.updateApplicatedGroups();
                break;
            case 'INVITING_GROUP' :
                userStore.updateInvitingGroups();
                break;
        }
        setActiveMenu(idx);
    }

    // 참여 중인 그룹 컴포넌트
    const participatingGroupComponent = () => {
        if (!userStore.joinGroups || userStore.joinGroups?.length === 0) return;
        return (
            <ProfileMyGroupsItemsWrap>
                <ProfileMyGroupsItemsTitle>{t('PARTICIPATING_GROUP')} {`(${userStore.joinGroups.length})`}</ProfileMyGroupsItemsTitle>
                <ProfileMyGroupsItems>
                    {createParticipatingGroup()}
                </ProfileMyGroupsItems>
            </ProfileMyGroupsItemsWrap>
        )
    }

    // 참여 중인 그룹 리스트 생성
    const createParticipatingGroup = () => {
        if(!userStore.joinGroups) return;
        return userStore.joinGroups.map(group=>{
            return <ProfileGroup group={group} type={'PARTICIPATING_GROUP'} />
        })
    }

    // 가입 신청한 그룹 컴포넌트
    const applyingGroupComponent = () => {
        if (!userStore.applicatedGroups || userStore.applicatedGroups?.length === 0) return;
        return (
            <ProfileMyGroupsItemsWrap>
                <ProfileMyGroupsItemsTitle>{`가입 신청한 그룹 (${userStore.applicatedGroups?.length})`}</ProfileMyGroupsItemsTitle>
                <ProfileMyGroupsItems>
                    {createApplyingGroup()}
                </ProfileMyGroupsItems>
            </ProfileMyGroupsItemsWrap>
        )
    }

    // 참여 중인 그룹 리스트 생성
    const createApplyingGroup = () => {
        if(!userStore.applicatedGroups) return;
        return userStore.applicatedGroups.map(group=>{
            return <ProfileGroup group={group} type={'APPLYING_GROUP'} />
        })
    }

    // 초대 받은 그룹 컴포넌트
    const invitingGroupComponent = () => {
        if (!userStore.joinGroups || userStore.invitingGroups?.length === 0) return;
        return (
            <ProfileMyGroupsItemsWrap>
                <ProfileMyGroupsItemsTitle>{`초대받은 그룹 (${userStore.invitingGroups?.length})`}</ProfileMyGroupsItemsTitle>
                <ProfileMyGroupsItems>
                    {createInvitingGroup()}
                </ProfileMyGroupsItems>
            </ProfileMyGroupsItemsWrap>
        )
    }

    // 참여 중인 그룹 리스트 생성
    const createInvitingGroup = () => {
        if(!userStore.invitingGroups) return;
        return userStore.invitingGroups.map(group=>{
            return <ProfileGroup group={group} type={'INVITING_GROUP'} onSubmit={e=>setActiveMenu(1)} />
        })
    }

    // 선택된 메뉴 렌더링
    const createTabMenu = () => {

        const _type = menus[activeMenu].type;

        if(_type==='ALL') {
            // 전체에서 없으면 결과 없음 렌더링
            if(userStore.joinGroups?.length===0 && userStore.applicatedGroups?.length===0 && userStore.invitingGroups?.length===0){
                return(
                    <ProfileItemsNoResultWrap>
                        <ProfileNoResultWrap>
                            <NoResult src={NoPostImg} title={'내 그룹이 없어요.'}/>
                        </ProfileNoResultWrap>
                    </ProfileItemsNoResultWrap>
                )
            }
            return(
                <ProfileItemsList>
                    {participatingGroupComponent()}
                    {applyingGroupComponent()}
                    {invitingGroupComponent()}
                </ProfileItemsList>
            )
        }else if(_type==='PARTICIPATING_GROUP'){
            if(userStore.joinGroups.length===0){
                return(
                    <ProfileItemsNoResultWrap>
                        <ProfileNoResultWrap>
                            <NoResult src={NoPostImg} title={'참여 중인 그룹이 없어요.'}/>
                        </ProfileNoResultWrap>
                    </ProfileItemsNoResultWrap>
                )
            }
            return (
                <ProfileItemsList>{participatingGroupComponent()}</ProfileItemsList>
            )
        }else if(_type==='APPLYING_GROUP'){
            if(userStore.applicatedGroups.length===0){
                return(
                    <ProfileItemsNoResultWrap>
                        <ProfileNoResultWrap>
                            <NoResult src={NoPostImg} title={'가입 신청한 그룹이 없어요.'}/>
                        </ProfileNoResultWrap>
                    </ProfileItemsNoResultWrap>
                )
            }
            return (
                <ProfileItemsList>{applyingGroupComponent()}</ProfileItemsList>)
        }else if(_type==='INVITING_GROUP') {
            if(userStore.invitingGroups.length===0){
                return(
                    <ProfileItemsNoResultWrap>
                        <ProfileNoResultWrap>
                            <NoResult src={NoPostImg} title={'초대받은 그룹이 없어요.'}/>
                        </ProfileNoResultWrap>
                    </ProfileItemsNoResultWrap>
                )
            }
            return (<ProfileItemsList>{invitingGroupComponent()}</ProfileItemsList>)
        }
    }



    return(
        <>
            {/*헤더*/}
            <SearchWrap>
                <ProfileItemsPreBtn onClick={(e)=>{onBackHistoryClickEvent(e)}}>
                    <ProfileItemsPreBtnImg src={SearchPrevBtn}></ProfileItemsPreBtnImg>
                </ProfileItemsPreBtn>
                {/* title 부분 */}
                <ProfileItemsTitle>{title}</ProfileItemsTitle>
            </SearchWrap>

            <ProfileItemsContainer>

                {/*토픽*/}
                <ProfileItemsNav>
                    <ProfileItemsButtons>
                        {createMenus()}
                    </ProfileItemsButtons>
                </ProfileItemsNav>

                {/*리스트*/}
                {createTabMenu()}

            </ProfileItemsContainer>
        </>
    )
}

ProfileMyGroups.defaultProps = {

}

export default observer(ProfileMyGroups);