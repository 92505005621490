import styled from 'styled-components'

export const FooterWrap = styled.div`
    position: fixed; 
    width: 100%; 
    bottom: 0px;background: #FFFFFF; 
    border-top: 1px solid #F1F5F9;
`

export const FooterUl = styled.ul`
    display: flex;
`

export const FooterLi = styled.li`
    width: 100%;
    
    & > a { 
        width: 100%; 
        height: 61px; 
        display: block; 
        text-align: center;
        font-weight: 400; 
        font-size: 10px; 
        line-height: 14px; 
        text-align: center; 
        color: #d1d2d4; 
        padding-top: 8px;  
    }
`

export const NavLinkImg= styled.img`
`

export const NavLinkSpan = styled.span`
    display: block;
    
    &.on {
            color: #3A6AF6;
    }
`


// export const HeaderGroupFlex = styled.div`
//     position: relative;
//     width: 1450px;
//     height: 100%;
//     margin: 0 auto;
//     padding: 0px 50px 0px 55px;
// `
//
// export const HeaderLogo = styled.div`
//     float: left;
//     height: 87px;
//     width: 118px;
//     margin-right: 37px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// `
//
// export const HeaderLogoImg = styled.img`
// `
//
// export const LinkMenuWrap = styled.nav`
//     float: left;
//
// `
//
// export const LinkMenuWrapUl = styled.ul`
//        & > :first-child {
//             margin: 0px;
//        }
// `
//
// export const NavLinkWrap = styled.li`
//     float: left;
//     margin: 0px 10px;
// `
//
// export const NavLinkSpan = styled.span`
//     position: relative;
//     display: block;
//     font-size: 25px;
//     line-height: 36px;
//     text-align: center;
//     padding: 42px 20px 9px;
//
//      &.on {
//         font-weight: 700;
//
//         &:after {
//             display: block;
//             position: absolute;
//             left: 0px;
//             bottom: 0px;
//             width: 132px;
//             height: 5px;
//             background-color: #191E29;
//             content: '';
//         }
//     }
//
//     &:hover {
//         font-weight: 700;
//
//         &:after {
//             display: block;
//             position: absolute;
//             left: 0px;
//             bottom: 0px;
//             width: 132px;
//             height: 5px;
//             background-color: #191E29;
//             content: '';
//         }
//     }
// `
//
//
// // export const NavLink = styled.span`
// //
// //     color : ${({ isActive, theme }) => (isActive ? theme.colors.black : theme.colors.gray_50)};
// //     border-bottom : ${({ isActive, theme }) => (isActive ? `2px solid ${theme.colors.black}` : '')};
// //     font-size: 16px;
// //     line-height: 23px;
// //     text-align: center;
// //     height : 100%;
// //     display : block;
// // `;
//
// export const NotificationWrap = styled.div`
//     position: absolute;
//     top: 35px;
//     right: 50px;
//     width: 40px;
//     height: 40px;
// `
//
// export const NotificationButton = styled.div`
//     width: 32px;
//     height: 32px;
//     cursor : pointer;
//     position : relative;
//     bottom : 6px;
// `
//
// export const NotificationUnreadIcon = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   width: 6px;
//   height: 6px;
//   background: #7C9EFF;
//   border-radius: 3px;
// `
//
// export const NotificationMenu = styled.div`
//     display : ${({ open }) => (open ? 'block' : 'none')};
//     position: absolute;
//     right: -35px;
//     width: 407px;
//     height: 500px;
//     top: 79px;
//     padding : 12px 0;
//     background: ${({theme})=>theme.colors.white};
//     border: ${({theme})=>`1px solid ${theme.colors.gray_stroke_50}`};
//     box-sizing: border-box;
//     box-shadow: 0px 4px 40px rgba(117, 129, 141, 0.1);
//     border-radius: 10px;
//     z-index : 2;
// `
//
// export const NotificationTitleWrap = styled.div`
//   display : flex;
//   justify-content : space-between;
//   padding : 0 16px;
// `
// export const NotificationTitle = styled.div`
//     font-size: 12px;
//     line-height: 17px;
//     padding-bottom : 6px;
//
//     color: ${({theme})=>`1px solid ${theme.colors.gray_50}`};
// `
// export const NotificationDelBtn = styled.div`
//   font-size: 12px;
//   line-height: 17px;
//   color : ${({theme})=>theme.colors.gray_50};
//   cursor : pointer;
// `
//
// export const NotificationEmptyWrap = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: calc(100% - 17px - 6px); // 17: title height, 6: title padding
// `
//
// export const NotificationScrollBox = styled.div`
//
//     height: calc(100% - 17px - 6px);
//
//     overflow : overlay;
//     &::-webkit-scrollbar {
//         width: 26px;
//     }
//
//     &::-webkit-scrollbar-thumb {
//         background-color: #E5EBF0;
//         border-radius: 26px;
//         background-clip: padding-box;
//         border: 10px solid transparent;
//     }
//
//     &::-webkit-scrollbar-track {
//         background-color: none;
//         border-radius: 26px;
//     }
// `


