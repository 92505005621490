import {NoticeMessageContent, NoticeMessageDate, NoticeMessageTitle, NoticeMessageWrap} from "./NoticeMessageStyles";
import {getDateFrom} from "../../../utils/dateHandler";

const NoticeMessage = (props) => {

    const {item, onClick} = props;

    const onClickHandler = () => {
        if (onClick && typeof onClick === 'function') onClick();
    }

    return(
        <NoticeMessageWrap onClick={onClickHandler} className={item?.readDate ? 'read' : null}>
            <NoticeMessageTitle>{item?.title}</NoticeMessageTitle>
            <NoticeMessageContent>{item?.content}</NoticeMessageContent>
            <NoticeMessageDate>{getDateFrom(item?.regDate)}</NoticeMessageDate>
        </NoticeMessageWrap>
    )
}

NoticeMessage.defaultProps = {
    item : null,
    onClick : null
}

export default NoticeMessage;