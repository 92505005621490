import styled from "styled-components";

export const GroupCloseSettingContainer = styled.div`
`

// 버튼 위의 내용까지의 영역
export const GroupCloseSettingWrap = styled.div`
    display : flex;
    flex-direction : column;
    gap : 20px;
    border-bottom : 1px solid #EAEAEA;
    padding-bottom : 20px;
`

export const GroupCloseSettingItem = styled.div`
    background: #F7F9FB;
    padding: 15px 15px 19px 16px;
`

export const GroupCloseSettingItemTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    padding-left: 7px;
`

export const GroupCloseSettingItemValue = styled.div`
    
`

export const ButtonsWrap = styled.div`
    display : flex;
    padding-top: 43px;
    background: #ffffff;
    align-items : center;
    justify-content: center;
`

export const GroupCloseSettingDateWrap = styled.div`
    display: flex;
    gap: 10px;
`

export const GroupCloseSettingDate = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #FF7171;
`

export const GroupCloseSettingItemDesc = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #75818D;
    padding-left: 20px;
    white-space: pre-wrap;
    position: relative;
    
    ::after {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: #75818D;
        position: absolute;
        top: 10px;
        left: 8px;
    }
`