import styled from "styled-components";

export const GroupItemContainer = styled.div`
    height: 100%;
    background: #F1F5F9;
    position: relative;
`

export const GroupItemPreBtn = styled.div`
    position: absolute;
    top: 10px; 
    left: 16px;
`

export const GroupItemPreBtnImg = styled.img`
`

export const SearchWrap = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const SearchBarText = styled.h2`
    font-weight: 700; 
    font-size: 16px; 
    line-height: 23px; 
    text-align: center;
    padding: 10px 0px 10px 0px;
`

export const SearchBarCollectWrap = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`

export const SearchBarWrap = styled.div`
    float: right;
`

export const SearchBarBookmark = styled.div`

    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-sizing: border-box;
    background: #FFFFFF;
    /* gray_Stroke */

    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 24px;
    height: 24px;

    background : ${({disabled})=>{
        if(disabled){
            return '#F2F4F6;';
        }else {
            return '#FFFFFF;';
        }
    }};
    
    color : ${({disabled})=>{
        if(disabled){
            return '#AEB7C0;';
        }
    }};
    

`

export const GroupItemListContainer = styled.div`
    padding: 0px 0px 0px 16px;
    display: flex;
    flex-direction: column;
    background: #F7F9FB;
`

export const GroupItemsNoResultWrap = styled.div`
    margin-top : 102px;
    text-align : center;
    width : 100%;
`



export const GroupDataInfoUl = styled.ul`
    display: flex;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 25px 0px 25px 0px;
    font-size: 0px;
    width: 100%;
    justify-content: space-evenly;
`

export const GroupDataInfoLi = styled.li`
    display: inline-block; 
    padding: 0px 15px 0px 15px;
    color: #313131;
    font-weight: 500; 
    font-size: 12px; 
    line-height: 23px; 
    position: relative;
`


export const GroupDataBar = styled.div`
    display: flex; 
    position: relative; 
    height: 47px;
    width: 2px; 
    background-color: #EAEAEA; 
    content: '';
`


export const GroupDataInfText = styled.strong`
    font-weight: 700; 
    color: #000000;
    font-size: 16px; 
`

export const GroupDataInfoWrap = styled.div`
    text-align: center; 
`

export const GroupDataImageWrap = styled.div`
    padding-top: 50px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const GroupDataImage = styled.img`
    width : 120px;
    height : 120px;
    border-radius: 12px;
    background: rgb(217, 217, 217);
    object-fit : contain;
`

export const GroupDataInfoTitle = styled.h2`
    background: #ffffff;
    padding-top: 24px;
    margin : 0 auto;
    font-weight: 700; 
    font-size: 20px; 
    line-height: 29px;
    padding-bottom: 25px;
`


export const GroupHeaderSettingImg = styled.img`
    &.hidden { display: none; }
    
    vertical-align: initial;
`

export const GroupHeaderUrl = styled.span`
    padding-top: 7px;
    font-weight: 500 !important; 
    font-size: 14px; 
    line-height: 20px;
    color: #75818D; 
    display: block;
`

export const GroupNickNameWrap = styled.div`
    padding-top: 14px;
    font-weight: 500;
    font-size: 14px; 
    line-height: 20px;
    color: #75818D; 
    
    align-items: center;
    display: flex;
    justify-content: center;
`

export const GroupHeaderUrlImg = styled.img`
    vertical-align: middle; 
    margin-left: 3px;
    width : 15px;
    height : 15px;
`

export const GroupDataWrap = styled.div`
    position: relative;
    padding: 20px 25px 15px 25px;
`

export const GroupDataInfoDetail = styled.p`
    font-weight: 400; 
    font-size: 14px; 
    line-height: 20px; 
    color: #75818D;
    padding-bottom: 25px;
    text-align: initial;
  
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    word-break: break-all;
    -webkit-box-orient: vertical;
`

export const GroupDataInfoBottom = styled.div`
    padding-top: 20px;
    display: flex;
    justify-content: center;
`

export const GroupDataInfoTextBottom = styled.div`
    font-weight: 500; 
    font-size: 14px; 
    line-height: 20px; 
    color: #AEB7C0;
`