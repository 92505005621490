import { action, makeObservable, observable } from "mobx";

class ToastPopupStore {

    isOpen = false;
    popupText = 'enter the text';
    style = {};

    handler = null;

    constructor() {
        makeObservable(this, {
            isOpen : observable,
            popupText : observable,
            style : observable,
            handler : observable,
            openToastPopup : action,
            closeToastPopup : action
        });
    }

    openToastPopup = (popupText, style) => {

        clearTimeout(this.handler);

        this.popupText = popupText;
        this.style = style;
        this.isOpen = true;

        this.handler = setTimeout(()=>{
            this.isOpen = false;
            this.style = {};
        },2000);

    }

    closeToastPopup = () => {
        this.isOpen = false;
    }
}

export default ToastPopupStore;