import React, {useEffect, useState} from 'react';
import {
    HomeTopWrapStyle,
    HomeTopListStyle,
    HomeTopTitleStyle,
} from "./HomeTopStyles";
import HomeTopListComponent from "../home/HomeTopListComponent";
import {useTranslation} from "react-i18next";

const HomeTopComponent = (props) => {

    /**
     * 그룹 아이템
     */
    const {t} = useTranslation();
    const congratulationList = props.itemStore?.congratulationList;
    const partList = props.itemStore?.partList;
    const reactionList = props.itemStore?.reactionList;
    const onItemPopClick = props.onItemPopClick;

    const congratulationListLength = congratulationList === undefined ? 0 : congratulationList.length;
    const partListLength = partList === undefined ? 0 : partList.length;
    const reactionListLength = reactionList === undefined ? 0 : reactionList.length;

    /* 인덱스
     * */
    const [congratulationListIndex, setCongratulationListIndex] = useState(0);
    const [partListIndex, setPartListIndex] = useState(0);
    const [reactionListIndex, setReactionListIndex] = useState(0);

    /**
     * 초 카운트
     */
    const [second, setSecond] = useState(0);

    /* hotTopic 3가지 아이템
     * */
    const [hotTopicItems, setHotTopicItems] = useState([]);


    /* ListComponent
     * 핫 토픽 map 3가지 가져오기 */
    const HomeTopListComponentList = () => (hotTopicItems || []).map((item) => {
        return <HomeTopListComponent item={item}
                                     onClick={onItemPopClick}
                />;
    });

    /**
     * 오늘의 핫 토픽
     * 10초마다 돌리는 함수
     * (현재 10초)
     */
    const getTimeHotTopicList = () => {

        setTimeout(()=>{

            //초기화
            let hotTopicItems = [];
            // 파트 배열 추출
            const partItemArray = [];
            // 추출된 반응아이템
            const reactionItemArray = [];
            // 축하 배열 추출
            const congratulationItemArray = [];

            // 01. 참여 ( 2개 이상시 )
            if (partListLength > 1) {

                // 총 인덱스 길이 범위
                if (partListLength - 1 >= partListIndex) {
                    let randomIndex = Math.floor(Math.random() * partListLength);

                    // 무한 반복 (다르게 나오게)
                    while (randomIndex === partListIndex) {
                        randomIndex = Math.floor(Math.random() * partListLength);

                        //달라지면 멈춤
                        if (randomIndex !== partListIndex) break;
                    }

                    partItemArray.push(partList[randomIndex]);

                    //핫 아이템
                    hotTopicItems = [...hotTopicItems, ...partItemArray];
                    setPartListIndex(randomIndex);

                }else {
                    setPartListIndex(0);
                }
            }else if (partListLength === 1) {
                partItemArray.push(partList[0]);
                hotTopicItems = [...hotTopicItems, ...partItemArray];
            }

            // 02. 반응 ( 2개 이상시 )
            if (reactionListLength > 1) {

                // 총 인덱스 길이 범위
                if ( reactionListLength - 1 >= reactionListIndex ) {
                    let randomIndex = Math.floor(Math.random() * reactionListLength);

                    // 무한 반복 (다르게 나오게)
                    while (randomIndex === reactionListIndex) {
                        randomIndex = Math.floor(Math.random() * reactionListLength);

                        //달라지면 멈춤
                        if (randomIndex !== reactionListIndex) break;
                    }

                    reactionItemArray.push(reactionList[randomIndex]);

                    //핫 아이템
                    hotTopicItems = [...hotTopicItems, ...reactionItemArray];
                    setReactionListIndex(randomIndex);

                }else {
                    setReactionListIndex(0);
                }
            }else if (reactionListLength === 1) {
                reactionItemArray.push(reactionList[0]);
                hotTopicItems = [...hotTopicItems, ...reactionItemArray];
            }

            // 03. 축하 ( 2개 이상시 )
            if (congratulationListLength > 1) {

                // 총 인덱스 길이 범위
                if (congratulationListLength - 1 >= congratulationListIndex) {
                    let randomIndex = Math.floor(Math.random() * congratulationListLength);

                    // 무한 반복 (다르게 나오게)
                    while (randomIndex === congratulationListIndex) {
                        randomIndex = Math.floor(Math.random() * congratulationListLength);

                        //달라지면 멈춤
                        if (randomIndex !== congratulationListIndex) break;
                    }

                    congratulationItemArray.push(congratulationList[randomIndex]);

                    //핫 아이템
                    hotTopicItems = [...hotTopicItems, ...congratulationItemArray];
                    setCongratulationListIndex(randomIndex);

                }else {
                    setCongratulationListIndex(0);
                }
            }else if (congratulationListLength === 1) {
                congratulationItemArray.push(congratulationList[0]);
                hotTopicItems = [...hotTopicItems, ...congratulationItemArray];
            }

            //핫 토픽 세팅
            setHotTopicItems(hotTopicItems);
            //5초뒤
            setSecond(second + 1);
        }, 10000);

    };

    /**
     * 오늘의 핫 토픽
     * 20초마다 돌리는 함수
     * (현재 20초)
     */
    const getInitTopicList = () => {

        //초기화
        let hotTopicItems = [];

        // 01. 참여 ( 2개 이상시 )
        if (partListLength > 0) {
            let randomIndex = Math.floor(Math.random() * partListLength);

            // 파트 배열 추출
            const partItemArray = [];
            partItemArray.push(partList[randomIndex]);

            //핫 아이템
            hotTopicItems = [...hotTopicItems, ...partItemArray];
            setPartListIndex(randomIndex);
        }else if(partList !== undefined && partListLength === 0){
            hotTopicItems = [...hotTopicItems, {type : 'noItem', topicName : 'topic.vote'}]
        }

        // 02. 반응 ( 2개 이상시 )
        if (reactionListLength > 0) {
            let randomIndex = Math.floor(Math.random() * reactionListLength);

            // 추출된 반응아이템
            const reactionItemArray = [];
            reactionItemArray.push(reactionList[randomIndex]);

            //핫 아이템
            hotTopicItems = [...hotTopicItems, ...reactionItemArray];
            setReactionListIndex(randomIndex);
        }else if(reactionList !== undefined && reactionListLength === 0){
            hotTopicItems = [...hotTopicItems, {type : 'noItem', topicName : 'topic.free-talk'}];
        }

        // 03. 축하 ( 2개 이상시 )
        if (congratulationListLength > 0) {
            let randomIndex = Math.floor(Math.random() * congratulationListLength);

            const congratulationItemArray = [];
            let itemPage = '';
            congratulationItemArray.push(congratulationList[randomIndex]);

            //핫 아이템
            hotTopicItems = [...hotTopicItems, ...congratulationItemArray];
            setCongratulationListIndex(randomIndex);
        }else if(congratulationList !== undefined && congratulationListLength === 0){
            hotTopicItems = [...hotTopicItems, {type : 'noItem', topicName : 'topic.marriage'}];
        }

        //핫 토픽 세팅
        setHotTopicItems(hotTopicItems);
    };
    //
    // /* useEffect
    //  * commonDidUpdate
    //  */
    // useEffect(()=>{
    //
    //     //초마다 변경
    //     if (second !== 0) {
    //         // 초마다 실행
    //         getTimeHotTopicList();
    //     }
    // },[second]);

    useEffect(()=>{

        // // 처음 세팅 실행
        // if (second === 0 && props.itemStore.length !== 0) {
        //     getInitTopicList();
        //     //초카운트
        //     setSecond(second + 1);
        // }

        getInitTopicList();

    },[props.itemStore]);

    return(
        <HomeTopWrapStyle>
            <HomeTopTitleStyle>{t('TODAY_HOT_TOPIC')}</HomeTopTitleStyle>
            <HomeTopListStyle>{HomeTopListComponentList()}</HomeTopListStyle>
        </HomeTopWrapStyle>
    )
}

export default HomeTopComponent;