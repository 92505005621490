import {
    FooterLi,
    FooterUl,
    FooterWrap,
    NavLinkImg,
    NavLinkSpan
} from './FooterNavStyles'

import {Link, useLocation} from 'react-router-dom';
import routes from '../constants/routes.json';
import navOnCommunicateImg from '../resources/mImages/nav01_on.png';
import navOffCommunicateImg from '../resources/mImages/nav01_off.png';
import navOnGroupsImg from '../resources/mImages/nav02_on.png';
import navOffGroupsImg from '../resources/mImages/nav02_off.png';
import navOnNoticeImg from '../resources/mImages/nav03_on.png';
import navOffNoticeImg from '../resources/mImages/nav03_off.png';
import navOnProfileImg from '../resources/mImages/nav04_on.png';
import navOffProfileImg from '../resources/mImages/nav04_off.png';
import {useTranslation} from "react-i18next";

const FooterNav = (props) => {

    const {t} = useTranslation();
    const location = useLocation();

    const menus = [
        {id: 'menu1', name: t('FOOTER_MENU_COMMUNICATE'), path: routes.HOME, onImg: navOnCommunicateImg, offImg: navOffCommunicateImg},
        {id: 'menu2', name: t('FOOTER_MENU_GROUP'), path: routes.GROUPS, onImg: navOnGroupsImg, offImg: navOffGroupsImg},
        {id: 'menu3', name: t('FOOTER_MENU_NOTICE'), path: routes.NOTIFICATION,onImg: navOnNoticeImg, offImg: navOffNoticeImg},
        {id: 'menu4', name: t('FOOTER_MENU_PROFILE'), path: routes.PROFILE_SETTING, onImg: navOnProfileImg, offImg: navOffProfileImg},
    ];


    const handleLinkClick = (e, menuPath) => {

        if (e.type === 'click') {
            if (location.pathname === menuPath) {
                e.preventDefault();
                window.location.reload();
            }
        }

        // if (currentPath === location.pathname) window.location.reload(); // 새로고침
    };

    const createMenus = (menus) => {
        return menus.map((menu, index) => {
            return (
                <FooterLi key={`menu-${index}`}>
                    <Link to={menu.path} onClick={(e)=>{handleLinkClick(e, menu.path);}}>
                        <NavLinkImg src = {
                            location.pathname.includes(menu.path.includes('group') ? 'group' : menu.path)
                                || location.pathname.includes(menu.path.includes('profile') ? 'profile' : menu.path) ? menu.onImg : menu.offImg}/>
                        <NavLinkSpan
                            className={
                            location.pathname.includes(menu.path.includes('group') ? 'group' : menu.path)
                                || location.pathname.includes(menu.path.includes('profile') ? 'profile' : menu.path) ? 'on' : ''}
                        >{menu.name}</NavLinkSpan>
                    </Link>
                </FooterLi>
            )
        })
    }

    return (
        <FooterWrap>
            <FooterUl>
                {createMenus(menus)}
            </FooterUl>
        </FooterWrap>
    )
}

export default FooterNav