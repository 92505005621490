import {
    CloseButtonWrap,
    DownloadButtonWrap,
    ImageHeaderWrap,
    ImageModalContainer,
    ImageSliderWrap,
    ModalImage,
} from "./ImageModalStyles";
import {IconButton} from "../global";
import {useEffect, useRef} from "react";
import {ServerConfig} from "../../../commons/config";
import {observer} from "mobx-react";
import {useStores} from "../../../stores";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import {ModalScrollNoBottomBox} from "../global/ModalStyles";
import {appWebViewFileViewEvent, webViewAppNativeEvent} from "../../../utils/appBridgeEvnet";
import ProfileIcon from "../../../resources/icons/profile.png";
import {useLocation} from "react-router-dom";
import routes from "../../../constants/routes.json";

const ImageModal = (props) => {

    const {store} = useStores();
    const {imageModalStore, authStore} = store;
    const isAppReady = authStore.isAppReady;
    const location = useLocation();

    const modalToastPopupStore = props.modalToastPopupStore;
    const goHistoryBack = props.goHistoryBack;

    const containerRef = useRef(null);
    const closeRef = useRef(null);
    const downloadRef = useRef(null);

    const swiperContainerRef = useRef(null);
    const swiperInstanceRef = useRef(null);

    const createImages = (images) => {
        return images.map((image, index) =>
            <div className={'swiper-slide'}
                 aria-label={index}
                 aria-valuetext={index}>
                {/*{currentIndex}*/}
                <ModalImage
                    src={`${ServerConfig.default.FILE_URL}${image?.fileId}`}
                    onError={(e)=>{e.target.onerror = null; e.target.src= ProfileIcon}}
                />
            </div>
        )
    }

    const onFileDownload = () => {
        if (document.querySelector("#swiper-wrapper > div.swiper-slide.swiper-slide-active")) {
            const fileIndex = document.querySelector("#swiper-wrapper > div.swiper-slide.swiper-slide-active").ariaValueText;

            const filePath = `${ServerConfig.default.FILE_URL}${modalToastPopupStore.images[fileIndex].fileId}`;
            const fileId = `${modalToastPopupStore.images[fileIndex].fileId}`;

            //앱 이벤트
            appWebViewFileViewEvent(isAppReady, fileId, filePath);
        }
    }

    // 이미지 슬라이드 생성
    useEffect(()=> {
        if (modalToastPopupStore.isOpen) {
            swiperInstanceRef.current = new window.Swiper(swiperContainerRef.current, {
                initialSlide: `${modalToastPopupStore.currentIndex}`,
                spaceBetween: 30,
                centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                    clickable: true,
                },
            });
        } else {
            if (swiperInstanceRef.current) {
                swiperInstanceRef.current.destroy();
            }
        }

    },[modalToastPopupStore.isOpen]);

    return(
        <ImageModalContainer isOpen={modalToastPopupStore.isOpen} ref={containerRef}>

            <ImageHeaderWrap ref={closeRef}>
                <div ref={swiperInstanceRef} className="swiper-pagination"></div>
                <CloseButtonWrap onClick={e=> goHistoryBack()}>
                    <IconButton width={'36px'} height={'36px'} src={CancelIcon} />
                </CloseButtonWrap>
            </ImageHeaderWrap>

            {/*이미지*/}
            <ModalScrollNoBottomBox id = {'swiper_img'} className={'imageModal'}>
                <ImageSliderWrap>
                    <div ref={swiperContainerRef} className={'swiper mySwiper'}>
                        <div className={'swiper-wrapper'} id={'swiper-wrapper'}>
                            {createImages(modalToastPopupStore.images)}
                        </div>
                    </div>
                </ImageSliderWrap>

                {/*하단 이미지 다운*/}
                <div style={{display: 'flex', margin : '40px 0 30px 0', justifyContent: 'center'}}>
                    <DownloadButtonWrap ref={downloadRef} onClick={e=>onFileDownload()}>
                        {'이미지 뷰어로 보기'}
                    </DownloadButtonWrap>
                </div>
            </ModalScrollNoBottomBox>

        </ImageModalContainer>
    )
}

ImageModal.defaultProps = {
    images : [],
    onClose : null,
    index : 0
}

export default observer(ImageModal);