import {
    HomeBodyWrap,
    HomeContentWrap,
    HomeFABWrap,
    HomeQuickWrap,
    HomeSearchResultWrap,
    HomeSearchSubTitle,
    HomeSearchSubTitleWord,
    HomeSearchWrap,
    SearchBar,
    SearchBarBookmark,
    SearchBarRight,
    SearchBarSearch,
    SearchBarText,
    SearchBarTextWrap,
    SearchBarVisible,
    TopicsWrap
} from "./HomeStyles";
import {Chips, FAB, IconButton, NoResult, SearchField} from "../../components/presentationals/global";

import {useCallback, useContext, useEffect, useLayoutEffect} from "react";
import {observer} from "mobx-react";
import {ThreeDots} from "react-loader-spinner";
import {receiveResponse} from "../../utils/api";
import HomeTopComponent from "../../components/presentationals/home/HomeTopComponent";
import isEmpty from "is-empty";
import NoResultImg from "../../resources/icons/no_result.svg";
import NoPostImg from "../../resources/icons/no_post.svg";
import {useTranslation} from "react-i18next";
import Item from "../item/Item";
import Checkbox from "../../components/presentationals/global/Checkbox";
import UnSecretIcon from "../../resources/icons/group_icon.svg";
import SecretIcon from "../../resources/icons/group_icon_check.svg";
import BookmarkFilledIcon from "../../resources/icons/bookmark_filled.svg";
import BookmarkIcon from "../../resources/mImages/bookmarkIcon.svg";
import BookmarkDisabledIcon from "../../resources/icons/bookmark_gray.svg";
import SearchImg from '../../resources/mImages/searchIcon.svg';
import SearchSelectedImg from '../../resources/mImages/searchSelectedIcon.svg';
import SearchDisabledImg from '../../resources/mImages/search_disabled.png';
import upMoveIcon from "../../resources/mImages/quick_top.png";
import {BackHistoryContext} from "../../utils/backHistoryProvider";
import useBackControl from "../../utils/useBackControl";
import env from "../../constants/env";

const Home = (props) => {

    /**
     * 01. 전역변수
     *
     */
    // 전역변수
    const {userStore, topicStore, toastPopupStore, history, modalPopupStore, itemStore, modalDetailStore, eventStore} = props;

    // 다국어 처리에 대한 hook
    const {t} = useTranslation();

    // event 처리 위한 object
    const e = eventStore.e;

    // 뒤로가기시 저장
    const backContext = useContext(BackHistoryContext).current;

    // 전 변수값 담아두기
    const {useRemState, useRemRef} = useBackControl('Home');

    /**
     * state값 유지 (뒤로가기시)
     * 02. state값 관리
     *
     */

    // 데이터값
    const [homeState, setHomeState] = useRemState({
            activeCompanyId : 'all',
            activeTopicGroupId : '0000000000',
            searchWord : '',
            checkBookmark : false,
            page : 0
    }, 'homeState');

    const [isLoading, setIsLoading] = useRemState(true, 'isLoading');
    const [total, setTotal] = useRemState(0, 'total');
    const [boardItems, setBoardItems] = useRemState([], 'boardItems');
    const [searchView, setSearchView] = useRemState(false, 'searchView');

    // 입력 검색어
    const [enterSearchWord, setEnterSearchWord] = useRemState('', 'enterSearchWord');
    // 무한 스크롤 중복 방지
    const preventRef = useRemRef(null, 'preventRef');
    // 무한 스크롤 옵저버 요소
    const obsRef = useRemRef(null, 'obsRef');
    // 마지막 페이지 등록 여부
    const endRef = useRemRef(null, 'endRef');
    // 상단의 글쓰기 버튼
    const homeWriteRef = useRemRef(null, 'homeWriteRef');

    // // 아이템 div
    // const itemDivRef = useRef(null);
    // // 상단의 글쓰기 버튼
    // const homeWriteRef = useRef(null);

    /******************************************************************************************************************/


    /**
     * chip 컴포넌트
     * @param topicGroups
     * @returns {*}
     */
    const createTopicGroups = (topicGroups) => {

        return topicGroups.map((topicGroupId, index) => {
            return <Chips
                key={`topic-chip-${topicGroupId.topicGroupId}`}
                type={'home'}
                id={topicGroupId.topicGroupId}
                selected={homeState.activeTopicGroupId === topicGroupId.topicGroupId}
                onClick={e=>onClickTopic(e,index,topicGroupId.topicGroupId)}
            >{topicGroupId.nameText}</Chips>
        })
    }

    /**
     * Item 카드 컴포넌트
     * @param item
     * @param index
     * @returns {JSX.Element}
     */
    const boardItemToCard = (item, index) => {
        return <>
                    <Item id={index}
                          key={`board-item-${item.itemId}`}
                          type={'card'}
                          post={item}
                          isSearch={!isEmpty(homeState.searchWord)}
                          highlight={isEmpty(homeState.searchWord) && isLoading ? '' : homeState.searchWord}
                          // onDelete={e => onDeleteBoardItem(index)}
                          onClick={() => {onItemClickEvent(item)} }/>
               </>
    }


    /******************************************************************************************************************/

    /**
     * 04. 함수
     *
     */

    // 리스트 (회사, 소통, 검색어, 북마크, 페이지
    const getBoardItems = useCallback((homeState) => {
            // company id에 따라 반환하는 api 필요
            const companyId = homeState.activeCompanyId;
            const topicGroupId = homeState.activeTopicGroupId;
            const searchWord = homeState.searchWord;
            const checkBookmark = homeState.checkBookmark;
            const page = homeState.page;

            let param = {
                size : env.homeContentSize,
                type: "F",
                page : page
            }

            // 회사
            if(companyId !== 'all') param = {...param, companyId};
            // 토픽그룹
            if(topicGroupId !== '0000000000') param = {...param, topicGroupId};
            // 키워드
            if(!isEmpty(searchWord)) param = {...param, searchWord : encodeURIComponent(searchWord)};
            // 북마크
            if(checkBookmark) param = {...param, target : 'bookmark'};

            receiveResponse(param,'items','get').then(res=>{
                if(res.status===200){
                    if(page===0){
                        setBoardItems([...res.data.list]);
                    }else{
                        setBoardItems([...boardItems].concat(res.data.list));
                    }
                    setTotal(res.data.total);
                    setIsLoading(false);
                    preventRef.current = true;
                    endRef.current = res.data.end;
                }
            });
        }, [homeState]);

    // 게시글 리스트 생성 함수
    const createBoardList = () => (boardItems || []).map(boardItemToCard);

    // 키워드 검색일 경우 판단 (home 진입 시)
    const initHome = () => {
        const {location, history} = props;
        if(location){
            const keyword = location?.state?.keyword;
            if(keyword){
                history.replace({ ...props.history.location, state : {} });
            }
        }
    }


    /******************************************************************************************************************/

    /**
     * 05. 클릭 이벤트 함수
     *
     */
    // 내 회사 글 클릭 함수
    const onClickMyCompany = (checked) => {

        let companyId = 'all';
        if (checked) {
            companyId = userStore.user.companyId;
        }

        // 검색 상태에서 토픽 누르는경우 검색창에 입력해줘야함
        setEnterSearchWord(homeState.searchWord);

        //로딩아닐경우만
        if (!isLoading) {
            if (homeState.activeCompanyId !== companyId) {
                setIsLoading(true);
                preventRef.current = false;
                setHomeState((homeState)=>{
                    return {
                        ...homeState,
                        page: 0,
                        activeCompanyId: companyId
                    }
                });
            }
        }
    }

    // topic click 함수
    const onClickTopic = (e, index, topicGroupId) => {
        e.preventDefault();
        // 검색 상태에서 토픽 누르는경우 검색창에 입력해줘야함
        setEnterSearchWord(homeState.searchWord);
        //로딩아닐경우만
        if (!isLoading){
            //topic list 초기화
            preventRef.current = false;
            if (homeState.activeTopicGroupId !== topicGroupId) {
                setIsLoading(true);
                setHomeState((homeState)=>{
                    return {
                        ...homeState,
                        page : 0,
                        activeTopicGroupId : topicGroupId
                    }
                });
            }
        }
    }

    // enter search word change 함수
    const onSearchWordChange = (value) => {

        // 다 지웠을 시
        if (value.length === 0) {
            if (!isLoading) {
                //키워드 있으면
                if (homeState.searchWord) {
                    preventRef.current = false;
                    setIsLoading(true);
                    setHomeState((homeState)=>{
                        return {
                            ...homeState,
                            page: 0,
                            searchWord: ''
                        }
                    });
                }
            }
        }

        setEnterSearchWord(value);
    }

    // search word 전부 지우기
    const onSearchWordDelete = (e) => {
        e.preventDefault();
        //입력 검색어
        setEnterSearchWord('');

        //로딩아닐경우만
        if (!isLoading) {
            preventRef.current = false;
            //키워드 있으면
            if (homeState.searchWord) {
                setIsLoading(true);
                setHomeState((homeState)=>{
                    return {
                        ...homeState,
                        page: 0,
                        searchWord: ''
                    }
                });
            }
        }

    }

    // // 최근 검색어 클릭
    const onSearchWord = (searchWord) => {

        setEnterSearchWord(searchWord);

        //로딩아닐경우만
        if (!isLoading) {
            if (homeState.searchWord !== searchWord) {
                setIsLoading(true);
                preventRef.current = false;
                setHomeState((homeState)=>{
                    return {
                        ...homeState,
                        page: 0,
                        checkBookmark: false,
                        searchWord: searchWord
                    }
                });
            }
        }
    }

    // 아이템 클릭 이벤트
    const onItemClickEvent = (item) => {
        /**
         * 모달 창
         */
        //소통해요 'f'로 날라옴
        // 모달 디테일 생성
        modalDetailStore.init(
            'c',                   //'c', 'g' 타입
            item.itemId,           // 아이템Id
            onCloseBoardItem,      // 닫기
            onDeleteBoardItem,     // 삭제
            onCloseBoardItem       // 에러
        );
    }

    // 그룹 게시글 닫기 이벤트
    const onCloseBoardItem = () => {
    }

    // Observer 핸들러 함수
    const obsHandler = ((entries) => {
        const target = entries[0];
        if(target.isIntersecting && preventRef.current){
            if(endRef.current) return;
            preventRef.current = false;
            setHomeState((homeState)=>{
                return {
                    ...homeState,
                    page : homeState.page + 1
                }
            });
        }
    });

    // 글 쓰기 모달 오픈
    const onOpenPostModal = (e) => {
        e.preventDefault();

        //모달 기본 설정n
        modalPopupStore.initAdd(
            'c',
            (topicStore.topics || []).map(topic=>({id : topic.topicId, name : topic.name, nameText : topic.nameText})),
            {},
            onCloseCacnelPostModal,
            onCloseOkPostModal
        );
    }

    // 글 쓰기 모달 닫기
    const onCloseCacnelPostModal = (e) => {
        toastPopupStore.openToastPopup('게시글 작성을 취소하였습니다.');
    }

    // 글 쓰기 모달 닫기
    const onCloseOkPostModal = (e) => {
        modalPopupStore.closeModalPopup();
        preventRef.current = false;
        setIsLoading(true);
        setEnterSearchWord('');
        /**
         * 모든 초기화
         */
        // 초기상태일 경우 강제 set시키기
        if (homeState.activeCompanyId === 'all' && homeState.activeTopicGroupId === '0000000000'
            && homeState.searchWord === '' && homeState.checkBookmark === false
            && homeState.page === 0 ) {
            getBoardItems(homeState)
        } else {
            setHomeState((homeState) => {
                return {
                    activeCompanyId: 'all',
                    activeTopicGroupId: '0000000000',
                    page: 0,
                    searchWord: '',
                    checkBookmark: false
                }
            });
        }
        toastPopupStore.openToastPopup('게시글이 등록되었습니다.');
    }

    // 그룹 게시글 삭제 이벤트
    const onDeleteBoardItem = (index) => {
        setBoardItems(boardItems.slice(0,index).concat(boardItems.slice(index+1,boardItems.length)))
    }

    // 팝업상세 클릭 이벤트
    const onItemPopClick = () => {
        //setSelectedItem(boardItems[1].itemId);
    }

    // 북마크 클릭 이벤트
    const onToggleBookmark = (e) => {
        e.preventDefault();
        // 검색 상태에서 토픽 누르는경우 검색창에 입력해줘야함
        setEnterSearchWord(homeState.searchWord);
        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            preventRef.current = false;
            setHomeState((homeState)=>{
                return {
                    ...homeState,
                    // boardItems: [],
                    page: 0,
                    checkBookmark: !homeState.checkBookmark,
                    // isLoading: true
                }
            });
        }
    }

    // 돋보기 클릭 이벤트
    const onSearchClickEvent = (e) => {
        e.preventDefault();
        if(homeState.searchWord) return;
        const searchWrap = document.getElementById('search_wrap');
        if(searchWrap) {
            if(!searchView){
                searchWrap?.classList?.add('show');
            }else{
                searchWrap?.classList?.remove('show');
            }
        }
        setSearchView(!searchView);
    }

    // 전달된 링크의 item 오픈
    const onOpenLink = () => {
        const searchParams = new URLSearchParams(props.location.search);
        const itemId = searchParams.get('itemId');
        if(itemId){
            window.history.replaceState({}, null, props.location.pathname);
            modalDetailStore.init(
                'c',             //'c', 'g' 타입
                itemId           // 아이템Id
            );
        }
    }

    // <이벤트> 이벤트 리스너 등록 (게시글 삭제 이벤트)
    const createEventListener = () => {
        e.removeAllListeners('delete',()=>{});
        e.on('delete', ()=>{
            // 초기화 시키기
            preventRef.current = false;
            endRef.current = false;
            setIsLoading(true);
            setEnterSearchWord('');
            setHomeState((homeState)=>{
                return {
                    activeCompanyId: 'all',
                    activeTopicGroupId: '0000000000',
                    page: 0,
                    searchWord: '',
                    checkBookmark: false,
                    total: 0
                }
            });
        });
    }

    // 맨위로 스크롤 이동
    const onMoveScrollUpEvent = () => {
        document?.querySelector('#scroll-box')?.scrollTo(0, 0);
    }

    /******************************************************************************************************************/


    /**
     * 06. uesEffect
     *
     */

    // /** 중요 **/
    // /*  데이터 유지 관리 떄문에 페이지 이동 시 홈이면 초기화 */
    // // 새로 페이지 왔을 경우는 초기화
    useLayoutEffect(()=>{
        if (history.action === 'PUSH') {
            // 초기화 시키기
            preventRef.current = false;
            endRef.current = false;
            setIsLoading(true);
            setSearchView(false);
            setEnterSearchWord('');
            setHomeState((homeState)=>{
                return {
                    activeCompanyId : 'all',
                    activeTopicGroupId : '0000000000',
                    page : 0,
                    searchWord : '',
                    checkBookmark : false
                }
            });

        }
    },[]);


    useEffect(()=>{
        initHome();
        onOpenLink();
        // 삭제에 대한 event 리스너 생성
        createEventListener();

        //window.appBridge.dispatch({asdfasdfasdf})

        // observer 생성
        const observer = new IntersectionObserver(obsHandler, {
            threshold : 0
        });

        // observer에 관찰 요소 등록
        if(obsRef.current) observer.observe(obsRef.current);

        return ()=>{
            e.removeAllListeners('delete',()=>{});
            observer.disconnect();
        }
    },[]);


    useEffect(()=>{

        // 페이지가 push일때 (조회 안태우기)
        if (backContext.isPush) {
            backContext.isPush = false;
            return;
        }else if (backContext.isBack) { // 페이자가 back일때 (조회 안태우기)
            backContext.isBack = false;
            return;
        }
        /*랜더링 타는 이유*/
        //01. prevent.current = true
        //02. 뒤로가기시, 다시 소통해요 클릭시 (하단 푸터)
        getBoardItems(homeState);

    },[homeState]);

    /*스크롤 이벤트*/
    useEffect(() => {
        const scroll = document.querySelector("#scroll-box");
        scroll?.addEventListener('scroll', handleScroll);
        return () => {
            scroll.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);

    
    const handleScroll = () => {
        const scroll = document.querySelector("#scroll-box");
        const writer = document.querySelector('#modalWrite');
        if (scroll?.scrollTop === 0) {
            writer?.classList?.add('hide');
        }
    };

    /******************************************************************************************************************/

    /**
     * 07. render
     *
     */
    return(
        <>
            {/*홈 핫 토픽*/}
            <HomeTopComponent
                itemStore = {itemStore.recommendCommunicateItems}
                onItemPopClick = {onItemPopClick}>
            </HomeTopComponent>

            {/*홈 검색*/}
            <HomeSearchWrap id={'search_wrap'} searchView={searchView}>
                {/*회색 검색바*/}
                <SearchBar>
                    <SearchBarTextWrap>
                        <SearchBarText>{'실시간 게시글'}</SearchBarText>
                    </SearchBarTextWrap>
                    <Checkbox label={'내 회사 글만 보기'}
                              checked={userStore.user.companyId === homeState.activeCompanyId}
                              icon={UnSecretIcon}
                              checkedIcon={SecretIcon}
                              onCheck={onClickMyCompany}/>

                    <SearchBarRight>
                        <SearchBarSearch selected={searchView} disabled={homeState.searchWord}>
                            <IconButton
                                width={'20px'}
                                height={'20px'}
                                src={
                                    searchView ?
                                        homeState.searchWord ? SearchDisabledImg : SearchSelectedImg
                                        : SearchImg
                                }
                                onClick={onSearchClickEvent}
                            />
                        </SearchBarSearch>
                        <SearchBarBookmark disabled = {homeState.searchWord === '' ? false : true}>
                            <IconButton
                                width={'24px'}
                                height={'24px'}
                                src={
                                    homeState.searchWord ? BookmarkDisabledIcon :
                                        (homeState.checkBookmark ? BookmarkFilledIcon : BookmarkIcon)
                                }
                                onClick={homeState.searchWord === '' ? onToggleBookmark : null}
                            />
                        </SearchBarBookmark>
                    </SearchBarRight>
                </SearchBar>

                {/* 검색 창 */}
                <SearchBarVisible id={'home_search'} className={!searchView ? 'hidden' : ''}>
                    <SearchField
                        placeholder={'어떤 내용이 궁금하신가요?'}
                        type={'FI'}
                        value={enterSearchWord}
                        onChange={onSearchWordChange}
                        onDelete={onSearchWordDelete}
                        onSearch={onSearchWord}
                    />
                </SearchBarVisible>

                {/*토픽 동그라미*/}
                <TopicsWrap>
                    {createTopicGroups(topicStore.topicGroups)}
                </TopicsWrap>

            </HomeSearchWrap>

            {/* 홈 바디 */}
            <HomeBodyWrap>

            {/*리스트*/}
                {
                    !isLoading ?
                        //리스트
                        <HomeContentWrap>
                            {
                                !isEmpty(homeState.searchWord) ?
                                    //홈서치 타이틀
                                    <HomeSearchSubTitle>
                                        <HomeSearchSubTitleWord>{homeState.searchWord?.replaceAll(' ', '\u00A0')}</HomeSearchSubTitleWord>
                                        {` 검색결과 `}
                                        <HomeSearchSubTitleWord>{`${total}`}</HomeSearchSubTitleWord>
                                        {`건`}
                                    </HomeSearchSubTitle> : null
                            }
                            {
                                // 검색 없을 경우
                                (!isEmpty(homeState.searchWord) && boardItems.length===0)
                                || (isEmpty(homeState.searchWord) && boardItems.length===0
                                    && !isLoading) ?
                                    <HomeSearchResultWrap>
                                        <NoResult
                                            src={!isEmpty(homeState.searchWord) && boardItems.length === 0 ? NoResultImg : NoPostImg}
                                            title={!isEmpty(homeState.searchWord) && boardItems.length === 0 ? t('NO_SEARCH_ITEMS') : t('NO_ITEMS')}
                                            subtitle={!isEmpty(homeState.searchWord) && boardItems.length === 0 ? t('NO_SEARCH_ITEMS_SUBTITLE') : ''}
                                        />
                                    </HomeSearchResultWrap> : null
                            }

                            {/*리스트*/}
                            { createBoardList() }

                        </HomeContentWrap> :

                        //로딩바
                        <ThreeDots
                            height={"10"}
                            wrapperStyle={{
                                width : '100%',
                                justifyContent : 'center',
                                height : '100%',
                                paddingTop : '30px'}}
                            color={'#3A6AF6'}
                        />

                }

            </HomeBodyWrap>


            {/*스크롤 감지*/}
            <div ref={obsRef} style={{padding: '35px'}}></div>

            {/*모달*/}
            <HomeQuickWrap>
                
                <HomeFABWrap className={'hide'} id={'modalWrite'}>
                    <IconButton width={'50px'} height={'50px'} src={upMoveIcon} onClick={onMoveScrollUpEvent}/>
                </HomeFABWrap>
                {
                    topicStore.topics && topicStore.topics?.length > 0 ?
                        <HomeFABWrap>
                            <FAB onClick={onOpenPostModal}/>
                        </HomeFABWrap> : null
                }
            </HomeQuickWrap>


        </>
    )
}

export default observer(Home);