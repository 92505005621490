import styled from 'styled-components';

export const NotificationMessageWrap = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 30px;
  gap: 5px;
  
  :not(:last-child) {
    border-bottom: 1px solid #F1F5F9;
  }
  
  &.read {
    opacity : 0.5;
  }
  
`

export const NotificationMessageTitle = styled.div`

  font-size: 12px;
  line-height: 17px;
  color: ${({theme}) => theme.colors.gray_50};
`

export const NotificationMessageIcon = styled.div`

  width: 24px;
  height: 24px;
  background: ${({theme}) => theme.colors.gray_stroke_50};
  padding: 4px;
  border-radius: 50%;
`

export const NotificationMessageDesc = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    padding-left: 6px;
    // width: 100%;
    color: ${({theme}) => theme.colors.black};
    overflow : hidden;
`

export const NotificationMessageGroupName = styled.div`
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    color: #000000;
    padding-bottom: 3px;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
`

export const NotificationMessageContent = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding-bottom: 3px;
    word-break : break-all;
    // overflow: hidden; 
    // text-overflow: ellipsis; 
    // display: -webkit-box; 
    // -webkit-line-clamp: 1; 
    // -webkit-box-orient: vertical;
`
export const NotificationMessageDetail = styled.div`
  display: flex;
  align-items: center;
`
export const NotificationGroupBadge = styled.span`
  margin-right: 2px;
  font-weight: 700;
  font-size: 9px;
  height: 13px;
  line-height: 13px;
  color: #FFFFFF;
  background: #AEB7C0;
  border-radius: 6.5px;
  padding: 0 5px;
`
export const NotificationMessageInfoWrap = styled.span`
  font-weight: 500;
  font-size: 10px;
  color: #7C9EFF;
  padding : 10px 30px;
`
export const NotificationMessageInfo = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #7C9EFF;
  max-width: ${({half = false}) => half ? '95px' : '210px'};
`
export const NotificationMessageCreated = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    flex : none;
`

export const NotificationInfoBottom = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #AEB7C0;
    display : flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const NotificationItemTitleWrap = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;    
    color : #3A6AF6;
`

export const NotificationItemTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;

    overflow: hidden;
    text-overflow: ellipsis;
    color : #3A6AF6;
`

export const TitleSpan = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    flex : none;
    color : #3A6AF6;
`

export const DotSpan = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    flex : none;
    margin : 0 5px;
`