import KO from "../resources/icons/language_ko.svg";
import EN from "../resources/icons/language_en.svg";
import JA from "../resources/icons/language_ja.svg";
import CHI from "../resources/icons/language_chi.svg";
import env from "../constants/env";

// 언어 별 아이콘 가져오기
export const getLanguageIcon = (lan) => {

    if(!lan) return;

    const _lan = lan.toUpperCase();

    switch (_lan){
        case env.LOCALE_KO :
            return KO;
            break;
        case env.LOCALE_JA :
            return JA;
            break;
        case env.LOCALE_CHI :
            return CHI;
            break;
        default :
            return EN;
    }
}

// 언어 code를 env의 코드에 매핑
export const getLanguageEnvCode = (lan) => {

    if(!lan) return;

    const _lan = lan.toUpperCase();

    if(_lan.includes(env.LOCALE_KO)) return env.LOCALE_KO;
    else if(_lan.includes(env.LOCALE_JA)) return env.LOCALE_JA;
    else if(_lan.includes(env.LOCALE_CHI)) return env.LOCALE_CHI;
    else return env.LOCALE_EN;

}

export const getSystemLanguage = () => {

    let _language = navigator.language.toUpperCase();

    if(_language.includes(env.LOCALE_KO)) return env.LOCALE_KO.toLowerCase();
    else if(_language.includes(env.LOCALE_JA)) return env.LOCALE_JA.toLowerCase();
    else if(_language.includes(env.LOCALE_CHI)) return env.LOCALE_CHI.toLowerCase();

    return env.LOCALE_EN.toLowerCase();

}