import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle,
} from "../global/ModalStyles";
import {Button, IconButton} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import {
    EditStickerCheckIcon,
    EditStickerImg,
    EditStickerImgWrap,
    EditStickerItem,
    EditStickerList,
    EditStickerLockImg,
    EditStickerLockWrap,
    EditStickerPreviewEmptyTxt,
    EditStickerPreviewEmptyWrap,
    EditStickerPreviewSelectedImg,
    EditStickerPreviewSelectedTxt,
    EditStickerPreviewSelectedWrap,
    EditStickerPreviewWrap,
    EditStickerSelectedWrap,
    EditStickerTxt, StickerRepTxt, StickerRepWrap,
} from "./EditStickerModalStyles";
import {useEffect, useRef, useState} from "react";
import {useStores} from "../../../stores";
import {ServerConfig} from "../../../commons/config";
import LockFilledIcon from "../../../resources/icons/lock_filled.svg";
import CheckedCircleIcon from '../../../resources/icons/mobile_checked_circle.png';
import {receiveResponse} from "../../../utils/api";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";

const EditStickerModal = (props) => {

    const {t} = useTranslation();

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    //데이터
    const data = modalPopupStore;

    const {onClose} = props;
    const {store} = useStores();
    const {userStore, toastPopupStore} = store;
    const repStickerId = (userStore.stickers.filter(s => s.represent).map(s => s.stickerId) ?? [null])[0];
    const [selectedSticker, setSelectedSticker] = useState((userStore.stickers.filter(s => s.represent))[0] ?? {stickerId: null, sticker: null})
    const [changeable, setChangeable] = useState(false);
    const scrollRef = useRef();

    const selectSticker = (sticker) => {
        setSelectedSticker(sticker);
        if (sticker.stickerId && (!repStickerId || repStickerId !== sticker.stickerId)) {
            setChangeable(true);
        } else {
            setChangeable(false);
        }
    };

    const createStickers = () => userStore.stickers.map((item) => <EditStickerItem key={`sticker-${item.stickerId}`}
                                                                                   className={item.owned ? 'owned' : null}
                                                                                   onClick={item.owned ? () => selectSticker(item) : null}>
        <EditStickerImgWrap>
            <EditStickerImg src={`${ServerConfig.default.IMG_URL}${item.imgId}`} className={!item.owned ? 'blur' : ''}/>
            {   item.represent ? <StickerRepWrap><StickerRepTxt children={t('REPRESENT')}/></StickerRepWrap> :
                item.stickerId === selectedSticker.stickerId ? <EditStickerSelectedWrap>
                <EditStickerCheckIcon src={CheckedCircleIcon}/></EditStickerSelectedWrap> : undefined
            }
            {!item.owned ?
                <EditStickerLockWrap><EditStickerLockImg src={LockFilledIcon}/></EditStickerLockWrap> : undefined}
        </EditStickerImgWrap>
        <EditStickerTxt className={item.owned ? '' : 'lock'}>{item.nameText}</EditStickerTxt>
    </EditStickerItem>);

    const onSubmitHandler = () => receiveResponse({}, `user/stickers/${selectedSticker.stickerId}/represent`, 'patch').then(res => {
        if (res.status === 200) {
            userStore.setStickers(userStore.stickers.map(s => ({...s, represent: s.stickerId === selectedSticker.stickerId})));
            toastPopupStore.openToastPopup(t('CHANGE_REP_STICKER'));
            modalPopupStore.closeModalPopup();
        } else {
            toastPopupStore.openToastPopup(t('FAIL_CHANGE_REP_STICKER'));
        }
    });

    /**
     * 닫기 이벤트
     * @param e
     */
    const onCloseHandler = (e) => {
        //닫기 버튼
        // setInputNickname(userStore.user.nickName);
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    useEffect(()=>{
        if(data.isOpen){
            if(scrollRef && scrollRef.current ) scrollRef.current.scrollTop = 0;
        }else{
            setSelectedSticker((userStore.stickers.filter(s => s.represent))[0]);
            setChangeable(false);
        }
    },[data.isOpen])

    useEffect(()=>{
        if(userStore?.stickers && userStore?.stickers?.length > 0) setSelectedSticker((userStore.stickers.filter(s => s.represent))[0]);
    },[userStore.stickers])

    return (
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalTitle>{t('MY_STICKER')}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'}
                                height={'32px'}
                                onClick={onCloseHandler}
                                src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollBox ref={scrollRef}>
                <EditStickerPreviewWrap>
                    {!repStickerId && !selectedSticker.stickerId
                        ? <EditStickerPreviewEmptyWrap>
                            <EditStickerPreviewEmptyTxt children={'대표 스티커를\n선택해 주세요'}/>
                        </EditStickerPreviewEmptyWrap>
                        : <EditStickerPreviewSelectedWrap>
                            <EditStickerPreviewSelectedImg src={`${ServerConfig.default.IMG_URL}${selectedSticker.imgId}`}/>
                            <EditStickerPreviewSelectedTxt>
                                <strong>{selectedSticker.nameText}</strong>
                                <br/>
                                {selectedSticker.descriptionText}
                            </EditStickerPreviewSelectedTxt>
                        </EditStickerPreviewSelectedWrap>}
                </EditStickerPreviewWrap>

                <EditStickerList>
                    {createStickers()}
                </EditStickerList>
            </ModalScrollBox>

            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button type={'mobile'}
                        width={'100%'}
                        textStyle={{fontSize : '16px', lineHeight: "23px"}}
                        disabled={!changeable}
                        onClick={onSubmitHandler}>설정하기</Button>
            </ModalBottomWrap>

        </ModalContainer>
    )
}

EditStickerModal.defaultProps = {
    onClose: null,
}

export default observer(EditStickerModal);