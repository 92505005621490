import {
    ActivityItemBottom,
    ActivityItemCommentTitle,
    ActivityItemCommentTitleWrap,
    ActivityItemContent,
    ActivityItemContentTitle,
    ActivityItemContentTitleWrap,
    ActivityItemContentWrap,
    ActivityItemDate,
    ActivityItemGroupName,
    ActivityItemInfoWrap,
    ActivityItemWrap,
    TitleSpan
} from "./ActivityItemStyles";
import Img from "./Img";
import BookmarkIcon from "../../../resources/icons/bookmark_filled.svg";
import {ServerConfig} from "../../../commons/config";
import {IconButton} from "./index";
import {getDateFrom} from "../../../utils/dateHandler";
import {useStores} from "../../../stores";
import React, {useEffect, useState} from "react";
import {matchPath, useLocation} from "react-router-dom";
import routes from "../../../constants/routes.json";
import defaultImg from "../../../resources/images/testimg.png";
import {observer} from "mobx-react";

const ActivityItem = (props) => {

    const {store} = useStores();
    const {eventStore, defaultImageStore} = store;
    const e = eventStore.e;
    const location = useLocation();

    const {page, type, onClick, onClickGroup, onClickBookmark} = props;

    const [item, setItem] = useState(props.item);
    const [title, setTitle] = useState(props.title);
    const [content, setContent] = useState(props.content);
    const [regDate, setRegDate] = useState(props.regDate);
    const [groupName, setGroupName] = useState(props.groupName);
    const [imgId, setImgId] = useState(props.imgId);


    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */


    const onClickHandler = () => {
        if (onClick && typeof onClick === 'function') onClick();
    }

    const onClickGroupHandler = () => {
        if (onClickGroup && typeof onClickGroup === 'function') onClickGroup();
    }

    const onClickBookmarkHandler = (event) => {
        event.stopPropagation();
        if (onClickBookmark && typeof onClickBookmark === 'function') onClickBookmark();
    }

    // <이벤트> 이벤트 리스너 등록 (게시글 업데이트 이벤트 Item에서 수정 시 발행)
    const createEventListener = () => {
        e.removeAllListeners(`update-${props.item.itemId}`, () => {
        });
        // card 삭제 시, 게시글 업데이트
        e.on(`update-${item.itemId}`, (args) => {
            const _event = args.event;
            const _post = args.post;
            if (item.itemId === args.itemId) {
                switch (_event) {
                    case 'MODIFY_ITEM' :
                        if (_post) {
                            setItem(_post);
                            setTitle(_post?.title);
                            setGroupName(_post?.groupName);
                            setContent(_post?.content);
                            setRegDate(_post?.regDate);
                            setGroupName(_post?.group?.groupName);
                            setImgId(_post?.group?.imgId);
                        }
                        break;
                    case 'ADD_COMMENT' :
                        // 코멘트 페이지에서만
                        if (matchPath(location.pathname, `${routes.PROFILE}/comment`)) e.emit('delete', () => {
                        })
                        break
                }
            }
        });
    }

    /******************************************************************************************************************/
    /**
     * useEffect
     * @param e
     */

    useEffect(() => {
        createEventListener();
        return () => {
            e.removeAllListeners(`update-${props.item.itemId}`, () => {
            });
        }
    }, [])


    /******************************************************************************************************************/
    /**
     * render
     * @param e
     */


    return (
        <ActivityItemWrap page={page} type={type}>
            {/*{*/}
            {/*    page === 'F' ?*/}
            {/*        <IconButton*/}
            {/*            wrapperStyle={{*/}
            {/*                width : '29px',*/}
            {/*                height : '29px',*/}
            {/*                background : '#191E29',*/}
            {/*                flex : 'none',*/}
            {/*                borderRadius : '50%',*/}
            {/*                display : 'flex',*/}
            {/*                alignItems : 'center',*/}
            {/*                justifyContent : 'center'*/}
            {/*            }}*/}
            {/*            imgStyle={{*/}
            {/*                width : '19px',*/}
            {/*                height : '16px',*/}
            {/*                filter : 'brightness(0) invert(1)',*/}
            {/*                webkitFilter: 'brightness(0) invert(1)'*/}
            {/*            }}*/}
            {/*            src={CommunicateIcon}*/}
            {/*        /> :*/}
            {/*        <Img*/}
            {/*            onClick={onClickGroupHandler}*/}
            {/*            wrapStyle={{width : '29px', height : '29px', cursor : 'pointer', flex : 'none'}}*/}
            {/*            src={`${ServerConfig.default.IMG_URL}${imgId}`}*/}
            {/*        />*/}
            {/*}*/}

            {
                page === 'G' ?
                    <ActivityItemInfoWrap>
                        {
                            defaultImageStore.defaultGroupImgs ?
                                <Img onClick={onClickGroupHandler}
                                     wrapStyle={{width: '23px', height: '23px', borderRadius: '5px', flex: 'none'}}
                                     src={
                                         item.imgId === null ? defaultImg :
                                             defaultImageStore.defaultGroupImgs.findIndex(img => img === imgId) !== -1 ?
                                                 `${ServerConfig.default.IMG_URL}${imgId}` :
                                                 `${ServerConfig.default.IMG_THUMBNAIL_URL}${imgId}`
                                     }
                                /> : null
                        }
                        <ActivityItemGroupName onClick={onClickGroupHandler}>{groupName}</ActivityItemGroupName>
                        {
                            type === 'bookmark' ? <IconButton src={BookmarkIcon}
                                                              wrapperStyle={{
                                                                  flex: 'none',
                                                                  position: 'absolute',
                                                                  right: '32px'
                                                              }}
                                                              onClick={onClickBookmarkHandler}/> : null
                        }
                    </ActivityItemInfoWrap> : null
            }

            <ActivityItemContentWrap onClick={onClickHandler}>
                {
                    type !== 'comment' ?
                        <ActivityItemContentTitleWrap>
                            <ActivityItemContentTitle>{title}</ActivityItemContentTitle>
                            {
                                type === 'bookmark' && page === 'F' ? <IconButton src={BookmarkIcon}
                                                                                  wrapperStyle={{
                                                                                      flex: 'none',
                                                                                      position: 'absolute',
                                                                                      right: '16px'
                                                                                  }}
                                                                                  onClick={onClickBookmarkHandler}/> : null
                            }
                        </ActivityItemContentTitleWrap> : null
                }
                <ActivityItemContent>{content}</ActivityItemContent>
                <ActivityItemBottom>
                    {
                        type === 'comment' ?
                            <>
                                <TitleSpan>[</TitleSpan>
                                <ActivityItemCommentTitleWrap>
                                    <ActivityItemCommentTitle>{title}</ActivityItemCommentTitle>
                                </ActivityItemCommentTitleWrap>
                                <TitleSpan>]</TitleSpan>
                            </> : null

                    }
                    <ActivityItemDate type={type}>
                        {
                            type === 'comment' ? `· ${getDateFrom(regDate)}` : getDateFrom(regDate)
                        }
                    </ActivityItemDate>
                </ActivityItemBottom>
            </ActivityItemContentWrap>

        </ActivityItemWrap>
    )
}

ActivityItem.defaultProps = {
    page : 'F', // 소통해요, 모여봐요
    type : 'item', // item, comment, bookmark
    item : null,

    title : '', // 글 제목
    content : '', // 내용
    regDate : '', // 등록 일
    groupName : '', // 그룹 이름
    imgId : '', // 그룹 이미지 아이디디

    onClick : null,
    onClickGroup : null,
    onClickBookmark : null
}
export default observer(ActivityItem);