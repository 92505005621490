import UserStore from "./userStore";
import AuthStore from "./authStore";
import TopicStore from "./topicStore";
import ItemStore from "./itemStore";
import InterestStore from "./interestStore";
import ToastPopupStore from "./toastPopupStore";
import ModalPopupStore from "./modalPopupStore";
import ModalToastPopupStore from "./modalToastPopupStore";
import ModalDetailStore from "./modalDetailStore";
import ModalDetailParentStore from "./modalDetailParentStore";
import EventStore from "./eventStore";
import { useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import GroupDetailStore from "./groupDetailStore";
import DefaultImageStore from "./defaultImageStore";

export const useStores = () => {
    return useContext(MobXProviderContext);
}

const userStore = new UserStore();
const authStore = new AuthStore();
const topicStore = new TopicStore();
const itemStore = new ItemStore();
const interestStore = new InterestStore();
const toastPopupStore = new ToastPopupStore();
const modalToastPopupStore = new ModalToastPopupStore();      // 모달 4번째
const modalPopupStore = new ModalPopupStore();                // 모달 3번째
const modalDetailStore = new ModalDetailStore();              // 모달 2번째
const modalDetailParentStore = new ModalDetailParentStore();  // 모달 1번째
const eventStore = new EventStore();
const groupDetailStore = new GroupDetailStore();
const defaultImageStore = new DefaultImageStore();

const rootStore = { userStore, authStore, topicStore, itemStore, interestStore,
    toastPopupStore, modalPopupStore, modalToastPopupStore, modalDetailStore, modalDetailParentStore,
    eventStore, groupDetailStore, defaultImageStore }

export default rootStore;