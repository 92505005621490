import {
    GroupDataWrap,
    GroupHeaderImg,
    GroupHeaderImgWarp,
    GroupHeaderWrap,
    GroupTabLi,
    GroupTabUl,
    GroupTabWrap,
    GroupWrap,
    GroupNotJoinedContainer,
    GroupNotJoinedText,
    GroupNotJoinedWrap,
    GroupResultWrap,
    GroupListWrap,
    GroupList,
    GroupListNotice,
    GroupListNoticeWrap,
    GroupListNoticeImg,
    GroupListAllNotice,
    GroupListPaging,
    GroupListPagingPrevNext,
    GroupListPagingPrevImg,
    GroupListPagingNextImg,
    GroupListPagingNumber,
    GroupNotJoinedTextWrap,
    GroupStoreNavWrap,
    GroupFilesWrap,
    GroupStoreContainer,
    GroupStoreNavMyFileWrap,
    GroupLockerImgWrap,
    GroupLockerDateWrap,
    GroupLockerDate,
    GroupLockerImgNickName,
    GroupTabText,
    GroupHeaderCoverImg,
    GroupKickWrap,
    SearchWrap,
    GroupItemPreBtn,
    GroupItemPreBtnImg,
    SearchBarBookmark,
    GroupInformationWrap,
    GroupInformationDataWrap,
    GroupInformationInfoWrap,
    GroupInformationInfoBtn,
    SearchBarRight,
    SearchBarSearch,
    GroupFABWrap,
    GroupQuickWrap,
    GroupSearchSubTitle,
    GroupSearchSubTitleWord,
    SearchBarVisible,
    GroupListNoticeTitle,
    GroupListNoticeImgWrap,
    GroupListTitle,
    GroupNotJoinedTransparentContainer,
    GroupHomeWrap,
    GroupNotJoinedTitleText,
    GroupNotJoinedCloseWrap,
    GroupHeaderSettingImg,
    GroupHeaderSettingWrap,
    GroupCloseResultWrap,
    GroupNoResultWrap
} from "./GroupStyles";
import {
    Button,
    Card,
    FAB,
    GroupFile,
    IconButton,
    NoResult,
    SearchField
} from "../../components/presentationals/global";

import NoPostImg from "../../resources/icons/no_post.svg";
import SecretIcon from "../../resources/icons/lock_filled_gray.svg";
import Img from "../../components/presentationals/global/Img";
import groupDefalutImg from "../../resources/images/group_info_photo.png"
import groupNoticeImg from "../../resources/images/all_href_icon.png";
import BookmarkFilledIcon from "../../resources/icons/bookmark_filled.svg";
import BookmarkDisabledIcon from "../../resources/icons/bookmark_gray.svg";
import BookmarkIcon from "../../resources/mImages/bookmarkIcon.svg";
import groupBtnPriorityPrevImg from "../../resources/mImages/btn_page_priority_prev.png";
import groupBtnPriorityNextImg from "../../resources/mImages/btn_page_priority_next.png";
import groupBtnPrevImg from "../../resources/mImages/btn_page_prev.png";
import groupBtnNextImg from "../../resources/mImages/btn_page_next.png";
import UnMyCheckIcon from "../../resources/icons/group_icon.svg";
import myCheckIcon from "../../resources/icons/group_icon_check.svg";
import DisableMyCheckIcon from "../../resources/icons/group_icon_check_disabled.svg";
import NoticeImg from '../../resources/mImages/icon_post_notif.png';
import VoteIcon from "../../resources/icons/vote_in_group.svg";
import SecretTitleIcon from "../../resources/icons/comment_secret.svg";


import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Link} from 'react-router-dom';
import {observer} from "mobx-react";
import {receiveResponse} from "../../utils/api";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import routes from "../../constants/routes.json";
import isEmpty from "is-empty";
import {useStores} from "../../stores";
import {ServerConfig} from "../../commons/config";
import env from "../../constants/env";
import {Highlighter, stringToCost} from "../../utils/textHandler";
import {ThreeDots} from "react-loader-spinner";
import Checkbox from "../../components/presentationals/global/Checkbox";
import moment from "moment";
import {getBoolFromYn} from "../../utils/boolHandler";
import {getFileList, getIsVideoFile, getIsZIPFile} from "../../utils/fileHandler";
import {useTranslation} from "react-i18next";
import {getDDayFromDate} from "../../utils/dateHandler";
import NoResultImg from "../../resources/icons/no_result.svg";
import SearchPrevBtn from "../../resources/mImages/array_prev.svg";
import GroupInformationBtnImg from "../../resources/mImages/arr_blue_icon.png"
import SearchImg from "../../resources/mImages/searchIcon.svg";
import upMoveIcon from "../../resources/mImages/quick_top.png";
import groupSettingImg from "../../resources/icons/groupSettingIcon.png"
import SearchSelectedImg from "../../resources/mImages/searchSelectedIcon.svg";
import {appWebViewFileViewEvent} from "../../utils/appBridgeEvnet";
import SearchDisabledImg from "../../resources/mImages/search_disabled.png";

const Group = (props) => {

    const {store} = useStores();
    const {userStore, toastPopupStore, modalPopupStore, topicStore, modalToastPopupStore, modalDetailParentStore, groupDetailStore, modalDetailStore, eventStore, authStore, defaultImageStore} = store;
    const isAppReady = authStore.isAppReady;
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();

    // event 처리 위한 object
    const e = eventStore.e;
    // 그룹 상세
    const [group, setGroup] = useState(null);
    // 그룹 게시글
    const [boardItems, setBoardItems] = useState([]);
    // 그룹 공지글
    const [noticeItems, setNoticeItems] = useState([]);
    // 그룹 이미지 파일
    const [imgFiles, setImgFiles] = useState([]);
    // 그룹 파일
    const [otherFiles, setOtherFiles] = useState([]);
    // 텝 선택
    const [activeTab, setActiveTab] = useState(0);
    // 이미지 파일 상세 리스트 (보관함 이미지 상세 팝업)
    const [fileInfoList, setFileInfoList] = useState([]);
    // 하단의 고정 그룹 정보 끄기 위한 state
    const [isOpenBottomPopup, setIsOpenBottomPopup] = useState(true);

    // 입력 검색어
    const [enterSearchWord, setEnterSearchWord] = useState(props.location?.state?.keyword);
    // 게시글 로딩 여부
    const [isLoading, setIsLoading] = useState(true);
    // 검색 결과 갯수
    const [total, setTotal] = useState(0);
    // 공지 검색 결과 갯수
    const [noticeTotal, setNoticeTotal] = useState(0);
    // 가입 직후, 게시글 상세 모달 열 것인지 확인
    const [openItemId, setOpenItemId] = useState(null);

    // 서치뷰 설정
    const [searchView, setSearchView] = useState(false);

    // 그룹 페이지 state
    const [groupState, setGroupState] = useState({
        page : 0,
        searchWord : '',
        checkBookmark: false
    });

    // 유저 여부
    const [userInfo, setUserInfo] = useState({
        isManagerJoined : null,
        isAssistantJoined : null,
        isJoined : null,
        isApplicateJoined : null,
        isSecreted : null,
        isInvited : null
    });

    // 보관함 페이지 state
    const [lockerState, setLockerState] = useState({
        page : 0,
        activeStoreTab : 0,
        searchWord : '',
        selectedMyItem : false
    });

    // // 내가 올린 파일, 이미지만 보기 체크
    // const [selectedMyItem, setSelectedMyItem] = useState(false);
    // 선택 페이지
    const [prevPage, setPrevPage] = useState(1);
    // 선택 페이지
    const [nextPage, setNextPage] = useState(1);
    // 총 페이지
    const [totalPage, setTotalPage] = useState(0);
    // 페이지 단위
    const pageSlice = 5;
    // 날짜 처리 변수
    let beforeDate = '';

    // 무한 스크롤 중복 방지
    const preventRef = useRef(null);
    // 무한 스크롤 옵저버 요소
    const obsRef = useRef(null);
    // 마지막 페이지 등록 여부
    const endRef = useRef(null);

    /******************************************************************************************************************/
    /**
     * 데이터
     * @param e
     */

    // 그룹 navigation (게시글, 보관함)
    const navItems = [
            {
                id:0,
                nameText: t('ITEMS'),
                onClick : (e) => {
                }
            },
            {
                id:1,
                nameText: t('LOCKER'),
                onClick : (e)=> {
                }
            }
        ]

    // 그룹 보관함 nav
    const storeNavItems = [
        {
            id : 0,
            nameText : t('IMAGE')
        },
        {
            id : 1,
            nameText : t('FILE')
        }
    ];

    /******************************************************************************************************************/
    /**
     * 컴포넌트
     * @param e
     */

    // 그룹 nav 생성
    const createGroupNav = () => {
        return navItems.map((item,index)=>{
            return  <GroupTabLi
                selected = {activeTab === index}
                onClick = {e=>onClickNav(e, index)}
            >
                <GroupTabText>{item.nameText}</GroupTabText>
            </GroupTabLi>
        })
    }

    // 그룹 리스트 컴포넌트 (TAB or Default)
    const getListComponent = () => {

        //관리자 or 그룹찹여자 or 보조자 or 폐쇄 된 경우 (텝)
        if ((userInfo.isManagerJoined || userInfo.isJoined || userInfo.isAssistantJoined) && getDDayFromDate(group?.closingDate) >= 0) {
            return (
                <GroupWrap>
                    <GroupTabWrap>
                        <GroupTabUl>
                            {createGroupNav()}
                        </GroupTabUl>

                        <SearchBarRight>
                            <SearchBarSearch
                                selected={searchView}
                                disabled={activeTab === 0 ? groupState?.searchWord : lockerState?.searchWord}
                            >
                                <IconButton
                                    width={'20px'}
                                    height={'20px'}
                                    src={
                                        searchView ?
                                            activeTab === 0 ?
                                                groupState.searchWord ? SearchDisabledImg : SearchSelectedImg
                                                : lockerState.searchWord ? SearchDisabledImg : SearchSelectedImg
                                            : SearchImg}
                                    onClick={onSearchClickEvent}
                                />
                            </SearchBarSearch>
                            <SearchBarBookmark
                                disabled={(activeTab === 0 && groupState?.searchWord === '') ? false : true}>
                                <IconButton
                                    width={'24px'}
                                    height={'24px'}
                                    src={
                                        groupState?.checkBookmark && activeTab === 0 ? BookmarkFilledIcon :
                                            (activeTab === 0 && !groupState?.searchWord) ? BookmarkIcon : BookmarkDisabledIcon
                                    }
                                    onClick={(activeTab === 0 && groupState?.searchWord === '') ? onToggleBookmark : null}
                                />
                            </SearchBarBookmark>
                        </SearchBarRight>
                    </GroupTabWrap>

                    {/* 검색 창 */}
                    <SearchBarVisible id={'search_wrap'}>
                        <SearchField
                            placeholder={
                                activeTab === 0 ? t("GROUP_ITEM_SEARCH_PLACEHOLDER") :
                                    lockerState.activeStoreTab === 0 ?
                                        t("GROUP_IMAGE_SEARCH_PLACEHOLDER")
                                        : t("GROUP_FILE_SEARCH_PLACEHOLDER")
                            }
                            type={activeTab === 0 ? 'GI' : 'GF'}
                            value={enterSearchWord}
                            onChange={onSearchWordChange}
                            onDelete={onSearchWordDelete}
                            onSearch={onSearchWord}
                        />
                    </SearchBarVisible>

                    <GroupListWrap>
                        {getSelectedComponent(activeTab)}
                        <div ref={obsRef} style={{padding: '20px'}}></div>
                    </GroupListWrap>
                </GroupWrap>
            );
        }else return (

            // !isLoading ?
            /*게시글 리스트 컴포넌트*/
            <GroupWrap>
                <GroupListWrap>
                    <GroupList>
                        {getGroupList()}
                    </GroupList>
                </GroupListWrap>
            </GroupWrap>
        );

    };

    /////////////////////////////////////////////01, 게시글//////////////////////////////////////////////////

    // 그룹 리스트 (게시판, 보관함) 컴포넌트
    const getSelectedComponent = (activeTab) => {

        if (activeTab === 0) {
            return (
                <>
                    {/*공지사항 컴포넌트*/}
                    {
                        noticeItems.length !== 0 && isEmpty(groupState?.searchWord) ?
                            <>
                                <GroupListNoticeTitle>
                                    {t("NOTICE")}
                                    <GroupListAllNotice onClick={()=>{onModalDetailParentClickEvent(props.match.params.id)}}>
                                        {t('SHOW_ALL') + '(' + noticeTotal + ')'}
                                        <GroupListNoticeImg src = {groupNoticeImg}/>
                                    </GroupListAllNotice>
                                </GroupListNoticeTitle>
                                <GroupListNoticeWrap>
                                    <GroupListNoticeImgWrap>
                                        <GroupListNoticeImg src={NoticeImg} ></GroupListNoticeImg>
                                    </GroupListNoticeImgWrap>
                                    <GroupListNotice disabled = { noticeItems.length === 0 ? true : false} onClick={()=>{onModalDetailClickEvent(noticeItems[0]?.itemId)}}>
                                        {
                                            noticeItems[0]?.topicId === (topicStore.topics || []).filter(t=>t.name === 'topic.vote')[0]?.topicId ?
                                                <IconButton width={'18px'} src={VoteIcon} wrapperStyle={{display : 'inline-block', marginRight : '5px'}}/> : null
                                        }
                                        {noticeItems[0]?.title}
                                    </GroupListNotice>
                                </GroupListNoticeWrap>
                            </>: null
                    }
                    {
                        /*게시글 리스트 컴포넌트*/
                        <GroupList>
                            {isEmpty(groupState?.searchWord) ?
                                <GroupListTitle>{t("GROUP_ITEMS")}</GroupListTitle> : null}

                            {!isLoading ?
                                <>
                                    {
                                        !isEmpty(groupState?.searchWord) ?
                                            <GroupSearchSubTitle>
                                                <GroupSearchSubTitleWord>{groupState?.searchWord?.replaceAll(' ', '\u00A0')}</GroupSearchSubTitleWord>
                                                {` ${t('SEARCH_RESULT')} `}
                                                <GroupSearchSubTitleWord>{total}</GroupSearchSubTitleWord>
                                                {t('NUMBER_CASES')}
                                            </GroupSearchSubTitle> : null
                                    }

                                    {/*그룹 리스트*/}
                                    {getGroupList()}

                                    {/*페이징 컴포넌트*/}
                                    {pagingComponent()}
                                </> :
                                <ThreeDots
                                    height={"10"}
                                    wrapperStyle={{
                                        width: '100%',
                                        justifyContent: 'center',
                                        height: '100%',
                                        paddingTop: '30px'
                                    }}
                                    color={'#3A6AF6'}
                                />}
                        </GroupList>
                    }
                </>
            );
        }else if (activeTab === 1){
            return (
                <GroupStoreContainer>
                    {/*그룹 네비*/}
                    <GroupStoreNavWrap>
                        {createGroupStoreNav()}
                        <GroupStoreNavMyFileWrap>
                            <Checkbox label={lockerState?.activeStoreTab === 0 ? t('SHOW_MY_ADDED_IMAGE') : t('SHOW_MY_ADDED_FILE')}
                                      checked={lockerState?.selectedMyItem}
                                      icon={UnMyCheckIcon}
                                      checkedIcon={myCheckIcon}
                                      onCheck={onMyFileImgClickEvent}
                                      disabled={lockerState.searchWord}
                                      disabledIcon={lockerState.searchWord ? DisableMyCheckIcon : null}
                            />
                        </GroupStoreNavMyFileWrap>
                    </GroupStoreNavWrap>

                    {/*파일*/}
                    <GroupFilesWrap>
                        {
                            !isLoading ?
                                <>
                                    {
                                        !isEmpty(lockerState?.searchWord) ?
                                            <GroupSearchSubTitle>
                                                <GroupSearchSubTitleWord>{lockerState?.searchWord?.replaceAll(' ', '\u00A0')}</GroupSearchSubTitleWord>
                                                {` ${t('SEARCH_RESULT')} `}
                                                <GroupSearchSubTitleWord>{total}</GroupSearchSubTitleWord>
                                                {t('NUMBER_CASES')}
                                            </GroupSearchSubTitle> : null
                                    }
                                    {createFilesList()}
                                </> :
                                <ThreeDots
                                    height={"10"}
                                    wrapperStyle={{
                                        width : '100%',
                                        justifyContent : 'center',
                                        height : '100%',
                                        paddingTop: '30px'}}
                                    color={'#3A6AF6'}
                                />
                        }
                    </GroupFilesWrap>
                </GroupStoreContainer>
            );
        }

    }

    // 그룹 아이템 리스트 생성
    const createGroupItems = () => boardItems.map((item, index) => {
        return (
            <Card id={index}
                  type={'board'}
                  key={`group-li-${item.groupId}`}
                  isSearch={!isEmpty(groupState?.searchWord)}
                  isVote={item.topicId === (topicStore.topics || []).filter(t=>t.name === 'topic.vote')[0]?.topicId}
                  item={item}
                  cursor={(userInfo.isJoined || userInfo.isManagerJoined || userInfo.isAssistantJoined)
                  && (getDDayFromDate(group.closingDate) > 0 && !group.kickDate) ? true : false}
                  highlight={isEmpty(groupState?.searchWord) ? '' : groupState?.searchWord}
                  onClick={() => {
                      onModalDetailClickEvent(item.itemId)
                  }}
            ></Card>
        )
    });

    // 그룹 없는 컵포넌트
    const noGroupItems = (type) => {

        let titleText = '';
        let subTitleText = '';

        if (type === 'I') {
            if(lockerState?.searchWord) {
                titleText = t('NO_SEARCH_ITEMS');
                subTitleText = t('NO_SEARCH_ITEMS_SUBTITLE');
            }
            else titleText = t('NO_IMAGES_TITLE');
        }else if (type === 'F') {
            if(lockerState?.searchWord) {
                titleText = t('NO_SEARCH_ITEMS');
                subTitleText = t('NO_SEARCH_ITEMS_SUBTITLE');
            }
            else titleText = t('NO_FILES_TITLE');
        }else if (type === 'B') {
            if(groupState?.searchWord) {
                titleText = t('NO_SEARCH_ITEMS');
                subTitleText = t('NO_SEARCH_ITEMS_SUBTITLE');
            }
            else titleText = t('NO_REGIST_ITEMS');
        }

        return (
            <GroupNoResultWrap>
                <NoResult
                    src={
                        (activeTab === 0 && !isEmpty(groupState?.searchWord)) ||
                        (activeTab === 1 && !isEmpty(lockerState?.searchWord)) ? NoResultImg : NoPostImg}
                    title={titleText}
                    subtitle={subTitleText}
                />
            </GroupNoResultWrap>
        )
    };

    // 비밀글 컵포넌트
    const secretItems = () => {
        return (
            <GroupResultWrap>
                <IconButton width={'35px'} height={'35px'} src={SecretIcon} />
                {/*<GroupResultWrapTitle>{t('SECRET_GROUP_CANNOT_VIEW')}</GroupResultWrapTitle>*/}
            </GroupResultWrap>
        )
    };

    // paging 컴포넌트
    const pagingComponent = () => {
        if(totalPage > 1) {
            return (
                <GroupListPaging>
                    <GroupListPagingPrevNext onClick={(e)=>{onPriorityPrevPageClickEvent(e)}}>
                        <GroupListPagingPrevImg src = {groupBtnPriorityPrevImg} display = {prevPage === 1} />
                    </GroupListPagingPrevNext>
                    <GroupListPagingPrevNext onClick={(e)=>{onPrevPageClickEvent(e)}}>
                        <GroupListPagingPrevImg src = {groupBtnPrevImg} display = {prevPage === 1} />
                    </GroupListPagingPrevNext>
                    {pagingList()}
                    <GroupListPagingPrevNext onClick={(e)=>{onNextPageClickEvent(e)}}>
                        <GroupListPagingNextImg src = {groupBtnNextImg} display = {totalPage === nextPage} />
                    </GroupListPagingPrevNext>
                    <GroupListPagingPrevNext onClick={(e)=>{onPriorityNextPageClickEvent(e)}}>
                        <GroupListPagingNextImg src = {groupBtnPriorityNextImg} display = {totalPage === nextPage} />
                    </GroupListPagingPrevNext>
                </GroupListPaging>
            )
        }else {
            return (
                null
            )
        }
    };

    // 페이지 리스트 배열
    const pagingList = () => {
        const result = [];

        // 페이지는 index=>page랑 다름
        for (let i = prevPage; i <= nextPage; i++) {
            result.push(
                <GroupListPagingNumber
                    key={'paging-index' + `${i - 1}`}
                    onClick={(e) => {onPageClickEvent(e, i - 1)}}
                    selected={ i - 1 === groupState?.page}>
                    {i}
                </GroupListPagingNumber>);
        }
        return result;
    }

    /////////////////////////////////////////////02, 보관함 //////////////////////////////////////////////////

    // 보관함 (사진, 파일) 리스트
    const createFilesList = () => {

        // 사진
        if(lockerState.activeStoreTab === 0) {
            if (imgFiles.length === 0) return noGroupItems('I');
            else return imgFiles.map((img, index) => {
                // 이전 날짜
                let viewDate = false;

                if (index === 0){
                    beforeDate = moment(img.createDate).format('YYYY.MM.DD');
                    viewDate = true;
                } else if (index > 0) {
                    if (beforeDate !== moment(img.createDate).format('YYYY.MM.DD')) {
                        beforeDate = moment(img.createDate).format('YYYY.MM.DD');
                        viewDate = true;
                    }else {
                        viewDate = false;
                    }
                }

                return <>
                    { viewDate ?
                        <GroupLockerDateWrap id={`group-lockerImg-date-${beforeDate}`}>
                            <GroupLockerDate>{beforeDate}</GroupLockerDate>
                        </GroupLockerDateWrap> : null}
                    <GroupLockerImgWrap>
                        <Img wrapStyle={{width : '145px', height : '145px'}}
                             imgStyle={{width : '100%', height : '100%'}}
                             src={`${ServerConfig.default.THUMBNAIL_URL}${img.fileId}`}
                             onClick={()=>{openImageModal(img.itemId, imgFiles[index].fileId)}}/>
                        <GroupLockerImgNickName>{
                            isEmpty(lockerState.searchWord) ? img.nickname :
                                <Highlighter
                                    text={img.nickname}
                                    highlight={lockerState.searchWord}
                                    type={"content"}
                                />
                        }</GroupLockerImgNickName>
                    </GroupLockerImgWrap>
                </>
            })
        }else{  //파일
            if (otherFiles.length === 0) return noGroupItems('F');
            else return otherFiles.map((file, index) => {
                // 이전 날짜
                let viewDate = false;
                if (index === 0 ){
                    beforeDate = moment(file.createDate).format('YYYY.MM.DD');
                    viewDate = true;
                } else if (index > 0) {
                    if (beforeDate !== moment(file.createDate).format('YYYY.MM.DD')) {
                        beforeDate = moment(file.createDate).format('YYYY.MM.DD');
                        viewDate = true;
                    }else {
                        viewDate = false;
                    }
                }
                return <>
                    {viewDate ? <GroupLockerDateWrap id={`group-lockerFile-date-${beforeDate}`}>
                        <GroupLockerDate>{beforeDate}</GroupLockerDate>
                    </GroupLockerDateWrap> : null}
                    <GroupFile
                        {...file}
                        highlight={lockerState.searchWord}
                        onDownload={e=>onFileDownload(file)}
                    />
                </>
            });
        }
    }

    // 보관함 nav 생성
    const createGroupStoreNav = () => {
        return storeNavItems.map((item,index)=>{
            return <Button
                type={lockerState.activeStoreTab === index ? '' : 'secondary'}
                onClick={e=>onClickStoreNav(index)}
                key={`group-store-nav-${index}`}
                wrapperStyle={{width : '66px' , height : '28px', marginRight: '10px'}}
                shape={'circle'}
            >{item.nameText}</Button>
        })
    }

    /////////////////////////////////////////////03. 공통 하단 ///////////////////////////////////////////////

    // 그룹 가입 하단 고정 팝업 컴포넌트
    const joinGroupPopupComponent = () => {

        // // 보관함일 경우
        // if ( activeTab === 1 ) return;

        let type = '';

        // 폐쇄된 그룹일 경우 (가입 했는데 폐쇄되어있을 경우)
        if(userInfo.isJoined && group.closingDate && getDDayFromDate(group.closingDate) > 0) type = 'close';
        // 강퇴당한 경우
        else if(group.kickDate) type = 'kick';
        else {

            //01. 관리자일 경우
            if (userInfo.isManagerJoined) return null;
            //02. 부관리자일 경우
            if (userInfo.isAssistantJoined) return null;
            //03. 그룹 가입했을 경우
            if (userInfo.isJoined) return null;


            if (userInfo.isInvited) {  // 1-1. 초대 있을 경우
                type = 'invite';
            } else if (userInfo.isSecreted) {  // 1-2. 비밀일 경우
                // 1-2-(3). 승인대기
                type = userInfo.isApplicateJoined ? 'acceptApply' : 'secret';
            } else type = 'join';
        }



        switch (type) {
            case 'close' :
                return (
                    <>
                        <GroupNotJoinedCloseWrap>
                            <GroupNotJoinedText>
                                {t('GROUP_CLOSING')}<span style={{color : '#FF7171'}}>{`D-${getDDayFromDate(group.closingDate)}`}</span>
                            </GroupNotJoinedText>
                            <GroupNotJoinedTextWrap>
                                <Button
                                    type={'secondary'}
                                    shape={'circle'}
                                    width={'105px'}
                                    height={'36px'}
                                    onClick={e=>setIsOpenBottomPopup(false)}
                                >{t('JOIN_GROUP')}</Button>
                            </GroupNotJoinedTextWrap>
                        </GroupNotJoinedCloseWrap>
                    </>
                )
            case 'kick' :
                // 하나만 가져오기
                if (boardItems?.length > 1) setBoardItems([boardItems[0]]);
                return (
                    <>
                        <GroupNotJoinedTransparentContainer/>
                        <GroupKickWrap>{t('KICKED_GROUP_CANNOT_APPLY')}</GroupKickWrap>
                    </>
                )
            case 'invite':
                // 하나만 가져오기
                if (boardItems?.length > 1) setBoardItems([boardItems[0]]);
                return (
                    <>
                        <GroupNotJoinedTransparentContainer/>
                        <GroupNotJoinedWrap>
                            <GroupNotJoinedText>
                                <GroupNotJoinedTitleText>
                                    {' ' + group?.groupName + ' '}
                                    {t('JOIN_GROUP_POPUP_INVITE')}
                                </GroupNotJoinedTitleText>
                            </GroupNotJoinedText>
                            <GroupNotJoinedTextWrap>
                                <Button
                                    type={'secondary'}
                                    shape={'circle'}
                                    width={'81px'}
                                    height={'36px'}
                                    onClick={openDeclinePopup}
                                >{t('REJECT')}</Button>
                                <Button
                                    type={'mobile'}
                                    shape={'circle'}
                                    width={'81px'}
                                    height={'36px'}
                                    onClick={onAcceptInvitedGroupClickEvent}
                                >{t('ACCEPT_INVITE')}</Button>
                            </GroupNotJoinedTextWrap>
                        </GroupNotJoinedWrap>
                    </>
                )
            case 'secret':
                // 하나만 가져오기
                if (boardItems?.length > 1) setBoardItems([boardItems[0]]);
                return (
                    <>
                        <GroupNotJoinedTransparentContainer/>
                        <GroupNotJoinedWrap>
                            <GroupNotJoinedText>
                                <GroupNotJoinedTitleText>
                                    {group?.groupName + ' '}
                                    {t('JOIN_GROUP_POPUP_SECRET_JOIN')}
                                    <br/>
                                    {t('JOIN_GROUP_POPUP_SECRET_JOIN_GUIDE')}
                                </GroupNotJoinedTitleText>
                            </GroupNotJoinedText>
                            <Button
                                type={'mobile'}
                                shape={'circle'}
                                width={'105px'}
                                height={'36px'}
                                onClick={()=>{onAcceptJoinGroupClickEvent()}}
                            >{t('DO_APPLY_GROUP')}</Button>
                        </GroupNotJoinedWrap>
                    </>
                )
            case 'acceptApply':
                // 하나만 가져오기
                if (boardItems?.length > 1) setBoardItems([boardItems[0]]);
                return (
                    <>
                        <GroupNotJoinedTransparentContainer/>
                        <GroupNotJoinedWrap>
                            <GroupNotJoinedText>
                                {t('JOIN_GROUP_POPUP_ACCEPT_APPLY')}
                            </GroupNotJoinedText>
                            <GroupNotJoinedTextWrap>
                                <Button
                                    type={'secondary'}
                                    shape={'circle'}
                                    width={'108px'}
                                    height={'36px'}
                                    onClick={openCancelApplyingGroupPopup}
                                >{t('CANCEL_APPLYING_GROUP')}</Button>
                            </GroupNotJoinedTextWrap>
                        </GroupNotJoinedWrap>
                    </>
                )
            case 'join' :
                // 하나만 가져오기
                if (boardItems?.length > 1) setBoardItems([boardItems[0]]);
                return (
                    <>
                        <GroupNotJoinedTransparentContainer/>
                        <GroupNotJoinedWrap>
                            <GroupNotJoinedText>
                                {t('MORE_WANT_TO_SHOW_ITEMS', {cnt: stringToCost(group?.itemCount) || 0})}
                                <br/>
                                {' ' + group?.groupName + ' '}
                                {t('JOIN_GROUP_POPUP_JOIN')}
                            </GroupNotJoinedText>
                            <Button
                                type={'mobile'}
                                shape={'circle'}
                                width={'81px'}
                                height={'36px'}
                                onClick={onJoinGroupClickEvent}
                            >{t('DO_APPLY')}</Button>
                        </GroupNotJoinedWrap>
                    </>
                )
            default :
                return (
                    null
                )
        }
    };

    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    // 뒤로가기 이벤트
    const onBackHistoryClickEvent = (e) => {
            e.preventDefault();
            history.goBack();
    }

    // 맨처음 이전 페이지 클릭 이벤트
    const onPriorityPrevPageClickEvent = (e) => {
        e.preventDefault();

        //1페이지면
        if (prevPage === 1) return;
        else {
            const newPrevPage = 1;
            setPrevPage(newPrevPage);

            // 이전 새로운 뒷자리
            let newNextPage = newPrevPage + (pageSlice - 1);
            // 마지막일 경우는 마지막
            setNextPage(newNextPage);

            //로딩아닐경우만
            if (!isLoading) {
                setIsLoading(true);

                setGroupState((groupState) => {
                    return {
                        ...groupState,
                        page: newPrevPage - 1 // page는 index 기준 0부터 시작이므로
                    }
                });
            }
        }
    }

    // 이전 페이지 클릭 이벤트
    const onPrevPageClickEvent = (e) => {
        e.preventDefault();

        // 1 페이지면
        if (prevPage === 1) return;
        else {
            // 몫
            const comparePageCount = Math.floor(prevPage / pageSlice);
            // 이전 새로운 앞자리
            const newPrevPage = (pageSlice * (comparePageCount - 1)) + 1;
            setPrevPage(newPrevPage);

            // 이전 새로운 뒷자리
            let newNextPage = newPrevPage + (pageSlice - 1);
            // 마지막일 경우는 마지막
            setNextPage(newNextPage);

            //로딩아닐경우만
            if (!isLoading) {
                setIsLoading(true);

                setGroupState((groupState) => {
                    return {
                        ...groupState,
                        page: newNextPage - 1 // page는 index 기준 0부터 시작이므로
                    }
                });
            }
        }
    }

    // 맨마지막 페이지 클릭 이벤트
    const onPriorityNextPageClickEvent = (e) => {
        e.preventDefault();

        // 마지막과 total 같을 경우
        if (totalPage === nextPage) return;
        else {
            const comparePageCount = Math.floor(totalPage / pageSlice);
            // 이전 새로운 앞자리
            const newPrevPage = (pageSlice * comparePageCount) + 1;
            setPrevPage(newPrevPage);

            // 다음 새로운 뒷자리
            let newNextPage = newPrevPage + (pageSlice - 1);
            // 다음이 총 totalPage 넘었을 시
            if (newNextPage > totalPage) {
                newNextPage = totalPage;
            }
            //마지막일 경우는 마지막
            setNextPage(newNextPage);

            //로딩아닐경우만
            if (!isLoading) {
                setIsLoading(true);

                setGroupState((groupState) => {
                    return {
                        ...groupState,
                        page: newNextPage - 1 // page는 index 기준 0부터 시작이므로
                    }
                });
            }
        }
    }

    // 다음 페이지 클릭 이벤트
    const onNextPageClickEvent = (e) => {
        e.preventDefault();

        // 마지막과 total 같을 경우
        if (totalPage === nextPage) return;
        else {
            // // total이 더 클경우만
            // if (totalPage > nextPage) {
            // 몫
            const comparePageCount = Math.floor(nextPage / pageSlice);
            // 이전 새로운 앞자리
            const newPrevPage = (pageSlice * comparePageCount) + 1;
            setPrevPage(newPrevPage);

            // 다음 새로운 뒷자리
            let newNextPage = newPrevPage + (pageSlice - 1);
            // 다음이 총 totalPage 넘었을 시
            if (newNextPage > totalPage) {
                newNextPage = totalPage;
            }
            //마지막일 경우는 마지막
            setNextPage(newNextPage);

            //로딩아닐경우만
            if (!isLoading) {
                setIsLoading(true);

                setGroupState((groupState) => {
                    return {
                        ...groupState,
                        page: newPrevPage - 1 // page는 index 기준 0부터 시작이므로
                    }
                });
            }
        }
    }

    // 페이지 클릭 이벤트
    const onPageClickEvent = (e, index) => {
        e.preventDefault();

        //같으면 리턴
        if (index === groupState?.page) return;

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            setGroupState((groupState) => {
                return {
                    ...groupState,
                    page: index // page는 index 기준 0부터 시작이므로
                }
            });
        }
    }

    // 북마크 클릭 이벤트
    const onToggleBookmark = (e) => {
        e.preventDefault();

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            if(groupState?.searchWord) setEnterSearchWord(groupState?.searchWord);
            setGroupState((groupState) => {
                return {
                    ...groupState,
                    page: 0,
                    checkBookmark: !groupState?.checkBookmark,
                }
            });
        }
    }

    // 글 쓰기 모달 오픈
    const onOpenPostModal = (e) => {

        /**
         * 투표 기능 추가
         */
        let dropDownItems = [
            {
                id: 0,
                name : 'Default',
                nameText: t('GROUP_MODAL_NORMAL_ITEM'),
            }
        ]
        let voteTopic = (topicStore.topics || []).filter(t=>t.name === 'topic.vote')[0];
        if(voteTopic) dropDownItems.push({id: voteTopic.topicId, name: voteTopic.name, nameText: voteTopic.nameText});

        //모달 기본 설정n
        modalPopupStore.initAdd(
            'g',
            dropDownItems,
            {...group, canNotice : groupDetailStore.isManager || groupDetailStore.isAssistant},
            onCloseCancelPostModal,
            onCloseOkPostModal
        );
    }

    // 글 쓰기 모달 닫기
    const onCloseCancelPostModal = (e) => {
        toastPopupStore.openToastPopup(t('CANCEL_ADD_ITEM'));
    }

    // 글 쓰기 모달 닫기(성공)
    const onCloseOkPostModal = (e) => {

        modalPopupStore.closeModalPopup();
        setIsLoading(true);
        setBoardItems([]);

        // 01. 공지사항 조회
        getNoticeGroup();
        // 02. 초기값 세팅
        setGroupState({
            page : 0,
            searchWord : '',
            checkBookmark: false
        });
        // 03. 텝 0 게시글 세팅
        setActiveTab(0);
        // 04. 스크롤 초기화
        initScroll();
        // 05. 그룹 정보 업데이트 (게시글 수 초기화)
        groupDetailStore.updateGroup(group.groupId);
        toastPopupStore.openToastPopup(t('COMPLETE_ADD_ITEM'));
    }

    // 그룹 nav 클릭 함수
    const onClickNav = (e, index) => {

        if (index === activeTab) return;

        preventRef.current = false;
        endRef.current = false;
        e.preventDefault();
        setActiveTab(index);

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);

            // 01. 게시글
            if (index === 0) {
                groupDetailStore.updateGroup(group.groupId);
                setGroupState((groupState) => {
                    return {
                        page: 0,
                        searchWord: '',
                        checkBookmark: false
                        // ...groupState
                    }
                });
            } else if (index === 1) { // 02. 보관함
                setLockerState((lockerState) => {
                    return {
                        // ...lockerState
                        page: 0,
                        activeStoreTab: 0,
                        searchWord: '',
                        selectedMyItem: false
                    }
                });
            }
            setEnterSearchWord('');
        }
    }

    // 검색 이벤트
    const onSearchWord = (searchWord) => {

        preventRef.current = false;
        setEnterSearchWord(searchWord);

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);

            // 01. 게시글
            if (activeTab === 0) {
                setGroupState((groupsState) => {
                    return {
                        ...groupsState,
                        searchWord: searchWord,
                        checkBookmark: false,
                        page: 0
                    }
                });
            } else {  // 02. 보관함
                setLockerState((lockerState) => {
                    return {
                        ...lockerState,
                        searchWord: searchWord,
                        selectedMyItem: false,
                        page: 0
                    }
                });
            }
        }
    }

    // enter search word change 함수
    const onSearchWordChange = (value) => {
        setEnterSearchWord(value);

        if(!value){
            if(activeTab===0){
                if(groupState.searchWord){
                    preventRef.current = false;
                    setIsLoading(true);
                    setGroupState({
                        ...groupState,
                        searchWord: '',
                        page: 0
                    })
                }
            }else{
                if(lockerState.searchWord){
                    preventRef.current = false;
                    setIsLoading(true);
                    setLockerState({
                        ...lockerState,
                        searchWord: '',
                        page : 0
                    })
                }
            }
        }
    }

    // search word 전부 지우기 이벤트
    const onSearchWordDelete = (e) => {

        e.preventDefault();
        preventRef.current = false;

        setEnterSearchWord('');
        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            if(activeTab===0){
                setGroupState({
                    ...groupState,
                    searchWord: '',
                    page: 0
                })
            }else{
                setLockerState({
                    ...lockerState,
                    searchWord: '',
                    page : 0
                })
            }
        }
    }

    // 01-1. 그룹 가입하기 이벤트 API 함수
    const onJoinGroupClickEvent = () => {
        receiveResponse({},`groups/${props.match.params.id}/join`,'patch')
            .then((res)=>{
                if(res.status===200){
                    userStore.updateJoinGroups();
                    groupDetailStore.init(props.match.params.id, userStore.user.userId);

                    //리조회
                    getBoardItems(groupState);

                    // 가입 완료 후, 전달받은 itemId가 있으면 글 상세 오픈
                    if(openItemId){
                        modalDetailStore.init(
                            'g',             //'c', 'g' 타입
                            openItemId           // 아이템Id
                        );
                        setOpenItemId(null);
                    }
                    toastPopupStore.openToastPopup(t('COMPLETE_JOINED_GROUP', { groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName}));
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 01-2. 그룹 탈퇴 API 함수
    const onQuitGroupClickEvent = () => {
        receiveResponse({},`groups/${props.match.params.id}/quit`,'patch')
            .then((res)=>{
                if(res.status===200){
                    props.history.replace(routes.GROUPS);
                    userStore.updateJoinGroups();
                    toastPopupStore.openToastPopup(t('COMPLETE_QUIT_GROUP', { groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName}));
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 02-1. 그룹 신청 가입하기 이벤트 API 함수
    const onAcceptJoinGroupClickEvent = () => {
        //모달 기본 설정n
        modalPopupStore.initAdd(
            'ga',
            [],
            {groupId : props.match.params.id},
            onCloseCacnelApplyModal,
            onCloseOkApplyModal
        );
    }

    // 글 쓰기 모달 닫기
    const onCloseCacnelApplyModal = (e) => {
        toastPopupStore.openToastPopup(t('CANCEL_JOINED_GROUP'));
    }

    // 글 쓰기 모달 닫기 (성공)
    const onCloseOkApplyModal = (e) => {
        modalPopupStore.closeModalPopup();
        toastPopupStore.openToastPopup(
            t('COMPLETE_APPLY_GROUP',
                { groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName})
        );
    }

    // 가입 신청 취소 팝업 띄우기
    const openCancelApplyingGroupPopup = () => {
        modalToastPopupStore.initDefault(
            'alert',
            t('CONFIRM_CANCEL_APPLY_GROUP'),
            t('YES'),
            t('NO'),
            onAcceptJoinGroupCancelClickEvent
        );
        modalToastPopupStore.openModalPopup();
    }

    // 02-2. 그룹 신청 가입하기 취소 이벤트 API 함수
    const onAcceptJoinGroupCancelClickEvent = () => {
        if(!modalToastPopupStore.isCancel) return;
        receiveResponse({},`groups/${props.match.params.id}/cancel-apply`,'patch', {'Content-Type': 'multipart/form-data'})
            .then((res)=>{
                if(res.status===200){
                    if (res.data) {
                        toastPopupStore.openToastPopup(t('COMPLETE_CANCEL_APPLY_GROUP'));
                    } else {
                        // 가입 신청 거절 실패 시
                        toastPopupStore.openToastPopup(t('FAIL_CANCEL_APPLY_GROUP'));
                    }
                    userStore.updateJoinGroups();
                    userStore.updateApplicatedGroups();
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 초대 거절 모달 띄우기
    const openDeclinePopup = () => {
        modalToastPopupStore.initDefault(
            'alert',
            t('CONFIRM_REJECT_INVITE', {groupName : group.groupName}),
            t('YES'),
            t('NO'),
            onDeclineInvitedGroupClickEvent
        );
        modalToastPopupStore.openModalPopup();
    }

    // 03-1. 초대 수락하기 이벤트 API 함수
    const onAcceptInvitedGroupClickEvent = () => {
        receiveResponse({},`groups/${props.match.params.id}/accept-invite`,'patch')
            .then((res)=>{
                if(res.status===200){
                    userStore.updateJoinGroups();
                    userStore.updateInvitingGroups();
                    groupDetailStore.init(props.match.params.id, userStore.user.userId);
                    //리조회
                    getBoardItems(groupState);
                    toastPopupStore.openToastPopup(t('COMPLETE_JOIN_GROUP',{ groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName}));
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 03-2. 초대 거절하기 이벤트 API 함수
    const onDeclineInvitedGroupClickEvent = () => {
        if(!modalToastPopupStore.isCancel) return;
        receiveResponse({},`groups/${props.match.params.id}/decline-invite`,'patch')
            .then((res)=>{
                if(res.status===200){
                    userStore.updateJoinGroups();
                    userStore.updateInvitingGroups();

                    toastPopupStore.openToastPopup(t('REJECT_INVITE_GROUP' , { groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName}));
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 상세 모달창 클릭 이벤트
    const onModalDetailClickEvent = (itemId) => {
        /**
         * 모달 창
         */
        if(getDDayFromDate(group.closingDate) <= 0) return;

        if(group.kickDate) return;

        if (userInfo.isJoined || userInfo.isManagerJoined || userInfo.isAssistantJoined) {
            //소통해요 'f'로 날라옴
            // 모달 디테일 생성
            modalDetailStore.init(
                'g',                  //'c', 'g' 타입
                itemId,          // 아이템Id
                onCloseBoardItem,     // 닫기
                onCloseBoardItem,     // 삭제
                onCloseBoardItem      // 에러
            );
        }else {
            openAlertPopup();
        }
    }

    // 그룹 게시글 삭제 이벤트
    const onCloseBoardItem = () => {
    }

    // 공지 모달창 클릭 이벤트
    const onModalDetailParentClickEvent = (itemId) => {

        /**
         * 모달 창
         */
        //소통해요 'f'로 날라옴
        // 모달 디테일 생성
        modalDetailParentStore.init(
            'g',                  //'c', 'g' 타입
            itemId,               // 아이템Id
            onCloseBoardItem,     // 닫기
            onCloseBoardItem,     // 삭제
            onCloseBoardItem      // 에러
        );

        modalDetailParentStore.openModalPopup();

    }

    // <댓글> 이미지 모달 open
    const openImageModal = (itemId, fileId) => {
        getItemFileArray(itemId, fileId);
    }

    //////////////////////////////////////////////////////////보관함 이벤트///////////////////////////////////////////////

    // 그룹 보관함 (사진, 파일) nav 클릭 함수
    const onClickStoreNav = (index) => {

        if( index === lockerState.activeStoreTab ) return;

        preventRef.current = false;
        endRef.current = false;

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            setEnterSearchWord('');
            setLockerState((lockerState)=>{
                return {
                    ...lockerState,
                    activeStoreTab: index,
                    selectedMyItem : false,
                    searchWord : '',
                    page: 0
                }
            });
        }
    }

    // 보관함 (내파일 클릭 이벤트)
    const onMyFileImgClickEvent = () => {

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            preventRef.current = false;
            endRef.current = false;
            setEnterSearchWord('');
            setLockerState((lockerState)=>{
                return {
                    ...lockerState,
                    selectedMyItem: !lockerState.selectedMyItem,
                    searchWord: '',
                    page: 0
                }
            });
        }
    };

    // 파일 다운로드 이벤트
    const onFileDownload = (file) => {

        const fileName = `${file?.fileOriginName}.${file?.fileExt.toLowerCase()}`;
        if(getIsVideoFile(fileName) || getIsZIPFile(fileName)){
            toastPopupStore.openToastPopup(t('CANNOT_OPEN_FILE_IN_MOBILE'));
            return;
        }
        const fileId = file?.fileId
        const filePath = `${ServerConfig.default.FILE_URL}${fileId}`;
        //앱 이벤트
        appWebViewFileViewEvent(isAppReady, fileId, filePath);
    }

    // 보관함 Observer 핸들러 함수
    const obsHandler = ((entries) => {
        // 보관함일 떄만
        if (activeTab === 1) {
            const target = entries[0];
            if (target.isIntersecting && preventRef.current) {
                if (endRef.current) return;
                preventRef.current = false;
                setLockerState((lockerState) => {
                    return {
                        ...lockerState,
                        page: lockerState.page + 1
                    }
                });
            }
        }
    });

    /******************************************************************************************************************/
    /**
     * 함수
     * @param e
     */

        // 공지 아이템 조회 API 함수
    const getNoticeGroup = () => {

            if (!props.match.params.id) return;

            let params = {
                groupId : props.match.params.id,
                type: 'G',
                noticeOnly : true
            }

            receiveResponse(params, `items`,'get')
                .then((res)=>{
                    if(res.status === 200) {
                        setNoticeItems(res.data.list);
                        setNoticeTotal(res.data.total);
                    }
                })
                .catch((e)=>{
                    console.log(e);
                });
        }

    // 상세 리스트
    const getGroupList = () => {
        //01. 비밀일경우 (01. 관리자 x or 02. 그룹 참여자 x or 03. 그룹 보조자)
        if ( userInfo.isSecreted && !(userInfo.isManagerJoined || userInfo.isJoined || userInfo.isAssistantJoined) ) {
            return secretItems();
        }else {
            return boardItems?.length > 0 ? createGroupItems() : noGroupItems('B');
        }
    };

    // 그룹 아이템 조회 API 함수
    const getBoardItems = (groupState) => {

        if (activeTab === 1) return;

        const size = env.GROUP_ITEMS_REQUEST_SIZE;
        const page = groupState?.page;
        const searchWord = groupState?.searchWord;
        const checkBookmark = groupState?.checkBookmark;

        let param = {
            size : size,
            page : page,
            groupId : props.match.params.id,
            type : 'G',
            excludeNotice : true
        }

        // 01. 검색어
        if (!isEmpty(searchWord)) param = {...param, searchWord : encodeURIComponent(searchWord)}
        // 02. 북마크
        if (checkBookmark) param = {...param, target : 'bookmark'}

        receiveResponse(param,'items','get').then(res=>{
            if(res.status===200){

                setBoardItems([...res.data.list]);

                // 토탈 총 수
                setTotal(res.data.total);

                // 초기세팅
                if (page === 0) {
                    // 01. total 마지막 page
                    setTotalPage(Math.ceil(res.data.total / size));
                    // 02. 이전 페이지
                    setPrevPage(1);
                    // 03. 다음 페이지
                    if (Math.ceil(res.data.total / size) > 5) {
                        setNextPage(pageSlice);
                    } else {
                        setNextPage(Math.ceil(res.data.total / size));
                    }
                }

                setIsLoading(false);
                endRef.current = res.data.end;

                initScroll();

            }else {
                history.replace(routes.GROUPS);
            }
        });
    }

    // 그룹 보관함 API 함수
    const getBoardLockerItems = (lockerState) => {

        // 게시글일 경우
        if (activeTab === 0) return;

        let params = {
            size: env.LOCKER_ITEMS_REQUEST_SIZE,
            page : lockerState.page,
            groupId : props.match.params.id,
            type : lockerState.activeStoreTab === 0 ? 'IMG' : 'OTHER'
        }

        // 01. 나의 보관함
        if (lockerState.selectedMyItem) params = { ...params, mineYn : 'Y'}
        // 02. 검색어
        if(!isEmpty(lockerState.searchWord)) params = { ...params, searchWord : encodeURIComponent(lockerState.searchWord)}

        receiveResponse(params, `groups/${props.match.params.id}/locker`,'get')
            .then(res=>{
                if(lockerState.page === 0){
                    if (lockerState.activeStoreTab === 0) setImgFiles([...res.data.list]);
                    else setOtherFiles([...res.data.list]);
                }else{
                    if (lockerState.activeStoreTab === 0) setImgFiles([...imgFiles].concat(res.data.list));
                    else setOtherFiles([...otherFiles].concat(res.data.list));
                }
                // 토탈 총 수
                setTotal(res.data.total);

                setIsLoading(false);
                preventRef.current = true;
                endRef.current = res.data.end;
            })
            .catch(e=>{
                console.log(e);
            })

    }

    // 01. 관리자인지 여부 함수 (id로 비교)
    // 상세group =>  managerId
    const getIsManagersJoined = (group) => {
        if(group){
            if (group.managerId === userStore.user.userId) {
                setUserInfo((userInfo)=>{
                    return {
                        ...userInfo,
                        isManagerJoined : true
                    }
                });
            }else{
                setUserInfo((userInfo)=>{
                    return {
                        ...userInfo,
                        isManagerJoined : false
                    }
                });
            }
        }
    };

    // 02. 부관리자인지 여부 함수 (id로 비교)
    // joinGroups = groupId
    const getIsAssistantJoined = (list) => {
        if(list){
            if (list.filter(user => getBoolFromYn(user.assistantYn) && user.userId === userStore.user.userId).length > 0) {
                setUserInfo((userInfo)=>{
                    return {
                        ...userInfo,
                        isAssistantJoined : true
                    }
                });
            }else{
                setUserInfo((userInfo)=>{
                    return {
                        ...userInfo,
                        isAssistantJoined : false
                    }
                });
            }
        }
    };

    // 03. 조인 여부 파악 함수
    // joinGroups = groupId
    const getIsJoined = (list) => {
        if (list.filter(user => user.groupId === props.match.params.id).length > 0) {
            setUserInfo((userInfo)=>{
                return {
                    ...userInfo,
                    isJoined : true
                }
            });
        }else{
            setUserInfo((userInfo)=>{
                return {
                    ...userInfo,
                    isJoined : false
                }
            });
        }
    };

    // 04. 조인 승인 여부 파악 함수
    // applicatedGroups = groupId
    const getIsApplicateJoined = (list) => {
        if (list.filter(user => user.groupId === props.match.params.id).length > 0) {
            setUserInfo((userInfo)=>{
                return {
                    ...userInfo,
                    isApplicateJoined : true
                }
            });
        }else{
            setUserInfo((userInfo)=>{
                return {
                    ...userInfo,
                    isApplicateJoined : false
                }
            });
        }
    };

    // 05. 비밀그룹 파악 함수
    // 상세group = privateYn
    const getIsSecreted = (group) => {
        if(group){
            if (group.privateYn === 'Y') {
                setUserInfo((userInfo)=>{
                    return {
                        ...userInfo,
                        isSecreted : true
                    }
                });
            }else{
                setUserInfo((userInfo)=>{
                    return {
                        ...userInfo,
                        isSecreted : false
                    }
                });
            }
        }
    };

    // 06. 초대 요청 함수
    // invitingGroups = groupId
    const getIsInvited = (list) => {
        if (list.filter(group => group.groupId=== props.match.params.id).length > 0) {
            setUserInfo((userInfo)=>{
                return {
                    ...userInfo,
                    isInvited : true
                }
            });
        }else{
            setUserInfo((userInfo)=>{
                return {
                    ...userInfo,
                    isInvited : false
                }
            });
        }
    };

    // 스크롤 초기화
    const initScroll = () => {
        document.querySelector("#scroll-box")?.scrollTo(0, 0);
    }

    // (폐쇄, 강퇴 제외) 미가입 상태에서
    const openAlertPopup = () => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            t('GROUP_ITEMS_NOT_JOINED_TEXT'),
            '',
            null,
            t('CONFIRM'),
            t('NO'),
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 전달된 링크의 item 오픈
    const onOpenLink = () => {
        // 폐쇄된 그룹, 강퇴된 그룹일 경우 열지 않기
        const searchParams = new URLSearchParams(props.location.search);
        const itemId = searchParams.get('itemId');
        if(itemId){
            if(!groupDetailStore.isJoined) {
                openAlertPopup();
                setOpenItemId(itemId);
            }else{
                modalDetailStore.init(
                    'g',             //'c', 'g' 타입
                    itemId           // 아이템Id
                );
            }
            window.history.replaceState({}, null, location.pathname);
        }
    }

    // 내 그룹 새 글에서 전달 받은 state있으면 popup open
    const onOpenState = () => {
        const _state = location.state;
        if(_state?.itemId){
            if(!groupDetailStore.isJoined) {
                openAlertPopup();
                setOpenItemId(_state?.itemId);
            }else{
                modalDetailStore.init(
                    'g',             //'c', 'g' 타입
                    _state.itemId           // 아이템Id
                );
            }
            // state로 전달된 값 삭제
            window.history.replaceState('itemId',null);
        }
    }

    const getItemFileArray = (itemId, fileId) => {
        receiveResponse({}, `items/${itemId}`,'get')
            .then((res)=>{
                if(res.status===200){
                    if(!res.data) throw new Error('no items');

                    //인덱스 찾기
                    let _index =  res.data.fileInfoList.findIndex(images => images.fileId === fileId);
                    const files = getFileList(res.data.fileInfoList);

                    modalToastPopupStore.initImages('image', files.imgs, fileId, _index, ()=>{});
                    modalToastPopupStore.openModalPopup();
                }
            })
            .catch((e)=>{
                history.replace(routes.HOME);
            })
    }

    // page 벗어날 경우, state remove 시켜주기
    const removeState = () => {
        setIsLoading(true);
        setGroupState(null);
        setLockerState(null);
        setActiveTab(0);
        setEnterSearchWord('');
        setBoardItems(null);
        setUserInfo({
            isManagerJoined : null,
            isAssistantJoined : null,
            isJoined : null,
            isApplicateJoined : null,
            isSecreted : null,
            isInvited : null
        })

    }

    // page에 진입할 경우, state init 시켜주기
    const initGroupState = () => {
        setIsLoading(true);
        setGroupState({
            page : 0,
            searchWord : '',
            checkBookmark: false
        });
    }

    const initLockerState = () => {
        setLockerState({
            page : 0,
            activeStoreTab : 0,
            searchWord : '',
            selectedMyItem : false
        });
    }

    // <이벤트> 이벤트 리스너 등록 (게시글 삭제 이벤트)
    const createEventListener = () => {

        e.removeAllListeners('delete',()=>{});

        // card 삭제 시, 게시글 업데이트
        e.on('delete', (args)=>{
            groupDetailStore.init(groupDetailStore.group.groupId, userStore.user.userId)
                .then(()=>{
                    if(activeTab===0) setGroupState(null);
                })
        });

        //e.removeAllListeners('update-notice',()=>{});

        // card 수정 시, 공지사항 업데이트
        e.on('update-notice',(args)=>{
            getNoticeGroup();
            if(args?.isUpdated) {
                setGroupState(null);
            }
        })
    }

    // 돋보기 클릭 이벤트
    const onSearchClickEvent = (e) => {
        e.preventDefault();

        if(activeTab===0){
            if(groupState.searchWord) return;
        }else{
            if(lockerState.searchWord) return;
        }

        const searchWrap = document.getElementById('search_wrap');
        if(searchWrap) {
            if(!searchView){
                searchWrap?.classList?.add('show');
                searchWrap?.classList.remove('hidden');
            }else{
                searchWrap?.classList?.remove('show');
                searchWrap?.classList.add('hidden');
            }
        }
        setSearchView(!searchView);
    }

    // 맨위로 스크롤 이동
    const onMoveScrollUpEvent = () => {
        document?.querySelector('#scroll-box')?.scrollTo(0, 0);
    }

    // 그룹 페이지
    const onGroupInformationClick = (e) => {
        history.push(`${routes.GROUP_INFORMATION}/${group?.groupId}`);
    }

    const handleScroll = () => {
        const scroll = document.querySelector("#scroll-box");
        const writer = document.querySelector('#modalWrite');
        if (scroll?.scrollTop === 0) {
            writer?.classList?.add('hide');
        }
    };

    /******************************************************************************************************************/
    /**
     * useEffrct
     * @param e
     */

    // 초기세팅
    useLayoutEffect(()=>{

        // userstore에 userId 있을 경우만 실행
        if(!userStore.user.userId) return;
        const userId = userStore.user.userId;
        // init group
        const match = matchPath(location.pathname,{
            path : `${routes.GROUPS}/:id`
        });

        if(match && match.isExact) {
            // 그룹 정보 조회
            groupDetailStore.init(match.params.id, userId);
            // 그룹 추천글 조회
            groupDetailStore.updateRecommend(match.params.id);
            userStore.updateJoinGroups();
            userStore.updateApplicatedGroups();
            userStore.updateInvitingGroups();
        };

        // return 함수로 그룹 상세 페이지 벗어날 경우 초기화
        // return () => {
        //     console.log("TEST@!#!@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
        //     groupDetailStore.removeInfo();
        // }

    },[location.pathname, userStore.user.userId]);


    // 그룹텝 설정
    useEffect(()=>{
        if(activeTab === 1){
            // observer 생성
            const observer = new IntersectionObserver(obsHandler, {
                threshold : 0
            });

            // observer에 관찰 요소 등록
            if(obsRef.current) observer.observe(obsRef.current);

            return () => {
                observer.disconnect();
            }
        }
    },[activeTab])

    // 초기세팅
    useEffect(()=>{
        // 01. 공지사항 조회
        getNoticeGroup();

        // 그룹에서 그룹으로 페이지 바뀔 경우
        return () => {
            groupDetailStore.removeInfo();
            removeState();
            setOpenItemId(null);
        }

    },[props.match.params.id]);

    // 게시글 조회
    useEffect(()=>{
        if(!groupState) initGroupState();
        else{ if (activeTab === 0) getBoardItems(groupState); }
    },[groupState]);

    // 보관함 조회
    useEffect(()=>{
        if(!lockerState) initLockerState();
        else{ if (activeTab === 1){getBoardLockerItems(lockerState);}}
    },[lockerState]);

    // 01. 내가 가입 그룹 조인 세팅
    useEffect(()=>{
        if (userStore.joinGroups !== null) {
            getIsJoined(userStore.joinGroups);
            getIsAssistantJoined(userStore.joinGroups);
        }
    },[userStore.joinGroups]);

    // 02. 내가 가입 신청 그룹 조인 세팅
    useEffect(()=>{
        if (userStore.applicatedGroups !== null) {
            getIsApplicateJoined(userStore.applicatedGroups);
        }
    },[userStore.applicatedGroups]);

    // 03. 내가 가입 초대받은 그룹 조인 세팅
    useEffect(()=>{
        if (userStore.invitingGroups !== null) {
            getIsInvited(userStore.invitingGroups);

        }
    },[userStore.invitingGroups]);

    // 04. 그룹 상세 정보 세팅
    useEffect(()=>{
        if (groupDetailStore.group !== null) {
            createEventListener();
            getIsManagersJoined(groupDetailStore.group);
            getIsSecreted(groupDetailStore.group);
            setGroup(groupDetailStore.group);
        }
    },[groupDetailStore.group]);

    // 05. 전달 받은 item이 있으면 open
    useEffect(()=>{
        // 폐쇄된 그룹이나 강퇴당한 그룹일 경우 팝업 열지않기 추가
        if(!groupDetailStore.group) return;

        let _kickDate = groupDetailStore.group.kickDate;
        let _closingDate = getDDayFromDate(groupDetailStore.group.closingDate);

        if(!_kickDate && _closingDate > 0) {
            onOpenLink();
            onOpenState();
        }
    },[groupDetailStore.isJoined, groupDetailStore.group])

    useEffect(()=>{

        if(!groupDetailStore.isJoined) return;
        if(!groupDetailStore.group) return;

        receiveResponse({datetime: moment().format()}, `groups/${groupDetailStore.group.groupId}/view-date`, 'patch', {'Content-Type': 'multipart/form-data'}).then(res => {
            if(res){
                if (userStore.joinGroups !== null){
                    userStore.setJoinGroups(userStore.joinGroups.map(jg => jg.groupId === groupDetailStore.group.groupId ? {...jg, unread: false} : jg));
                }
            }
        });
    },[groupDetailStore.isJoined, groupDetailStore.group])

    // 이벤트 리스너 등록
    useEffect(()=>{
        return ()=>{
            e.removeAllListeners('delete',()=>{});
            e.removeAllListeners('update-notice',()=>{});
            groupDetailStore.removeInfo();
            removeState();
        }
    },[]);

    /*스크롤 이벤트*/
    useEffect(() => {
        const scroll = document.querySelector("#scroll-box");
        scroll?.addEventListener('scroll', handleScroll);
        return () => {
            scroll.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);

    useEffect(()=>{
        if(lockerState.activeStoreTab === 0){
            if(obsRef && obsRef.current && !endRef.current && preventRef.current && imgFiles && imgFiles?.length > 0){
                if(window.innerHeight > obsRef.current.getBoundingClientRect().y){
                    setLockerState((lockerState) => {
                        return {
                            ...lockerState,
                            page: lockerState.page + 1
                        }
                    });
                }
            }
        }else{
            if(obsRef && obsRef.current && !endRef.current && preventRef.current && otherFiles && otherFiles?.length > 0){
                if(window.innerHeight > obsRef.current.getBoundingClientRect().y){
                    setLockerState((lockerState) => {
                        return {
                            ...lockerState,
                            page: lockerState.page + 1
                        }
                    });
                }
            }
        }
    },[imgFiles, otherFiles])
    /******************************************************************************************************************/

    return(

        <>
            {/*상단*/}
            <SearchWrap>
                <GroupItemPreBtn onClick={(e)=>{onBackHistoryClickEvent(e)}}>
                    <GroupItemPreBtnImg src={SearchPrevBtn}></GroupItemPreBtnImg>
                </GroupItemPreBtn>

                <GroupHeaderSettingWrap>
                    <Link to = {`${routes.GROUP_SETTING}/${props.match.params.id}`}>
                        <GroupHeaderSettingImg
                            className={ ((userInfo.isJoined || userInfo.isManagerJoined || userInfo.isAssistantJoined) && getDDayFromDate(group?.closingDate) >= 0 ) ? null : 'hidden'}
                            src = {groupSettingImg}
                        />
                    </Link>
                </GroupHeaderSettingWrap>
            </SearchWrap>

            {/*body*/}
            <GroupHomeWrap>
                { getDDayFromDate(groupDetailStore.group?.closingDate) > 0 ?
                    <>
                        {/*그룹 헤더*/}
                        <GroupHeaderWrap>
                            {
                                group?.coverImgId ?
                                    <GroupHeaderCoverImg
                                        src = {`${ServerConfig.default.IMG_URL}${group?.coverImgId}`}
                                    /> : null
                            }
                            <GroupHeaderImgWarp>
                                {
                                    group?.imgId ?
                                        <GroupHeaderImg
                                            src = {`${ServerConfig.default.IMG_URL}${group?.imgId}`}
                                        /> : null
                                }
                            </GroupHeaderImgWarp>
                        </GroupHeaderWrap>

                        {/*그룹 정보*/}
                        <GroupInformationWrap>
                            <GroupInformationDataWrap>
                                <GroupHeaderSettingImg
                                    className={getBoolFromYn(group?.privateYn) ? null : 'hidden'}
                                    src={SecretTitleIcon}
                                />
                                {group?.groupName}
                            </GroupInformationDataWrap>
                            <GroupInformationInfoWrap onClick={(e)=>{onGroupInformationClick(e);}}>
                                {t("GROUP_INFORMATION")}
                                <GroupInformationInfoBtn src={GroupInformationBtnImg}/>
                            </GroupInformationInfoWrap>
                        </GroupInformationWrap>

                        <GroupDataWrap>

                            {/* 상세 리스트 */}
                            {(userInfo.isManagerJoined !== null && userInfo.isJoined !== null
                                && userInfo.isInvited !== null && userInfo.isApplicateJoined !== null
                                && userInfo.isSecreted !== null) ?
                                getListComponent() : null}

                            {/*가입 하단 고정 팝업 컴포넌트*/}
                            { (userInfo.isManagerJoined !== null && userInfo.isJoined !== null
                                && userInfo.isInvited !== null && userInfo.isApplicateJoined !== null
                                && userInfo.isSecreted !== null && isOpenBottomPopup) ?
                                <GroupNotJoinedContainer id={'group-not-joined-container'}>
                                    {joinGroupPopupComponent()}
                                </GroupNotJoinedContainer> : null
                            }

                        </GroupDataWrap>

                        { (userInfo.isJoined || userInfo.isManagerJoined || userInfo.isAssistantJoined) && getDDayFromDate(group?.closingDate) > 0 ?
                                <GroupQuickWrap>
                                    <GroupFABWrap className={'hide'} id={'modalWrite'}>
                                        <IconButton width={'50px'} height={'50px'} src={upMoveIcon} onClick={onMoveScrollUpEvent}/>
                                    </GroupFABWrap>
                                    <GroupFABWrap>
                                        <FAB onClick={onOpenPostModal}/>
                                    </GroupFABWrap>
                                </GroupQuickWrap> : null
                        }

                    </>
                :
                    <GroupCloseResultWrap>
                        <NoResult src={NoPostImg} title={t('MY_PROFILE_CLOSE_ALERT')} />
                    </GroupCloseResultWrap>
                }

            </GroupHomeWrap>
        </>
    )
}

export default observer(Group);