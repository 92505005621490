import styled from "styled-components";

export const ActivityItemWrap = styled.div`
    
    position: relative;
    display : flex;
    flex-direction: column;
    width : 100%;
    
    // F, G, comment, bookmark
    height:  ${ ({page, type}) => {
      if (type === 'comment' && page === 'F') return '99px';
      else if (type === 'comment' && page === 'G') return '122px'; 
      else if (page === 'F') return '122px'; 
      else if (page === 'G') return '157px'; 
    }};

    margin-bottom: 10px;                                                                                                                                                
    
    background: #FFFFFF;
    border: 1px solid #F1F5F9;
    border-radius: 20px;
    padding: 16px 20px 15px 20px;
    align-items : flex-start;
    overflow : hidden;
`

export const ActivityItemInfoWrap = styled.div`
    display: flex;
`

export const ActivityItemGroupName = styled.div`
    margin-left: 6px;

    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 9px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
`

export const ActivityItemContentWrap = styled.div`
    width: 100%;
    
`

export const ActivityItemContentTitleWrap = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin: 0px 20px 9px 0px;
    overflow: hidden;
    display : flex;
`

export const ActivityItemContentTitle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow : 1;
`

export const ActivityItemContent = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #AEB7C0;
    margin: 0px 0px 5px 0px;
    height: 38px;
    
    white-space: pre-wrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    word-break: break-all;
    -webkit-box-orient: vertical;
`

export const ActivityItemBottom = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #AEB7C0;
    
    display : flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const ActivityItemCommentTitleWrap = styled.div`
    font-weight: 700;
    font-size: 12px;
    color: #191E29;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;    
`

export const ActivityItemCommentTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    color: #191E29;
`

export const TitleSpan = styled.span`
    flex : none;
    color: #191E29;
`

export const ActivityItemDate = styled.div`
    flex : none;
    margin-left : ${({type})=>type==='comment' ? '5px' : ''};
`

