import styled from "styled-components";

export const ViewProfileProfileWrap = styled.div`
    display : flex;
    flex-direction: column;
`

export const ProfileWrap = styled.div`
    flex-grow : 1;
`

export const ViewProfileProfileSettingWrap = styled.div`
    padding-top: 10px;
    display : flex;
    align-items : center;
    justify-content: center;
`
export const ViewProfileProfileSetting = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 18px;

    background: #FFFFFF;
    border: 1px solid #E5EBF0;
    border-radius: 100px;
`

export const ViewProfileModalWrap = styled.div`
    background : ${({theme})=>theme.colors.white};
    padding-top : 20px;
    position : relative;
    display : flex;
    flex-direction : column;
    gap : 10px;
    height : 100%;
`

export const ViewProfileRepStickerWrap = styled.div`
    background: #F7F7F7;
    border-radius: 10px;
    display : flex;
    padding : 8px 16px 8px 16px;
    width : 100%;
    align-items : center;
    gap : 5px;
`

export const RepStickerInfoWrap = styled.div`
    display: flex;
    flex-direction: column;
`

export const ViewProfileRepStickerName = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break : break-all;
    
`

export const ViewProfileRepStickerDescript = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break : break-all;
    
`

export const ViewProfileTabWrap = styled.div`
    position : relative;
    flex-grow : 1;
    display : flex;
    flex-direction : column;
`

export const ViewProfileMenus = styled.div`
    display : flex;
    gap : 5px;
    justify-content: space-between;
    
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
    margin-left: -16px;
    margin-right: -16px;
    
`

export const ViewProfileMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width : 114px;
    height : 54px;
    
    box-sizing : border-box;
    font-weight: 400;
    font-size: 13px;
    line-height: 38px;
    text-align: center;
    color: #AEB7C0;
    
    &.active {
        border-bottom: 3px solid #191E29;
        font-weight: 700;
        color: #191E29;
    }   
`

export const ViewProfileContentsWrap = styled.div`
    background: #F7F9FB;
    margin-left: -16px;
    margin-right: -16px;
    padding: 15px 16px 30px 16px;
    flex-grow : 1;
`

export const ViewProfileItemWrap = styled.div`
    margin-bottom: 8px;
    background: #FFFFFF;
    padding: 19px 16px 19px 18px;
    width : 100%;
    border-radius : 20px;
    display : flex;
    flex-direction : column;
`

export const ViewProfileItem = styled.div`
`

export const ViewProfileItemTitle = styled.div`
    font-size: 13px;
    line-height: 17px;
    color: #191E29;
    margin-bottom : 5px;
    overflow : hidden;
    text-overflow : ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const ViewProfileItemContent = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #AEB7C0;
    overflow : hidden;
    text-overflow : ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const ViewProfileItemDate = styled.div`
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;

`

export const ViewProfileGroup = styled.div`
    display : flex;
    align-items : center;
    gap : 10px;
    padding-bottom: 5px;
`

export const ViewProfileGroupImgTitle = styled.div`
    display : flex;
    align-items : center;
    gap : 10px;
`

export const ViewProfileGroupName = styled.div`
    flex-grow : 1;
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break : break-all;
    
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`

export const ViewProfileGroupUsers = styled.div`
    flex : none;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
`

export const ViewProfileSticker = styled.div`
    display : flex;
    align-items : center;
    gap : 10px;
`

export const ViewProfileStickerInfoWrap = styled.div`
    flex-direction: column;
    display : flex;
    gap : 2px;
`

export const ViewProfileStickerName = styled.div`
    width : fit-content;
    
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const ViewProfileStickerDesc = styled.div`
    width : fit-content;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;

    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const ViewProfileNoResultWrap = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    align-items : center;
    justify-content : center;
    flex-grow : 1;
`

export const ViewProfileQuickWrap = styled.div`
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    z-index: 3;
`

export const ViewProfileFABWrap = styled.div`

    display: block; 
    width: 50px; 
    height: 50px; 
    filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.1)); 
    margin: 10px 0px 0px 0px;
    
    &.hide{
        display : none;
    }
`