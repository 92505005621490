import {useContext, useEffect} from "react";
import {useStores} from "../../../stores";
import {observer} from "mobx-react";

import CommunicateModal from "../modal/CommunicateModal";
import ReportModal from "../modal/ReportModal";
import InviteModal from "../modal/InviteModal";
import GroupModal from "../modal/GroupModal";
import EditProfileImgModal from "../modal/EditProfileImgModal";
import EditNicknameModal from "../modal/EditNicknameModal";
import EditStickerModal from "../modal/EditStickerModal";
import GroupApplyModal from "../modal/GroupApplyModal";
import {CreateGroupModal} from "./index";
import {BackHistoryContext} from "../../../utils/backHistoryProvider";
import EditGroupImageModal from "../modal/EditGroupImageModal";
import StickerDetailModal from "../modal/StickerDetailModal";
import LanguageModal from "../modal/LanguageModal";
import ViewProfileModal from "../modal/ViewProfileModal";
import EditGroupCoverImageModal from "../modal/EditGroupCoverImageModal";


const Modal = (props) => {

    const {store} = useStores();
    // 모달 데이터
    const modalPopupStore = store.modalPopupStore;
    // 모달토스트 데이터
    const modalToastPopupStore = store.modalToastPopupStore;
    //01. 뒤로가기 버튼을 눌렀는지 여부 저장 (window history 관련)
    let isGoBackClicked = true;
    //02. 뒤로가기시 백여부 (전페이지 state 그대로 남기기위한)
    const backHistory = useContext(BackHistoryContext).current;

    /******************************************************************************************************************/

    /**
     * 이벤트 event
     * @param e
     */

    //뒤로가기 버튼 눌렀을 시 이벤트
    const goBackHandler = (event) => {

        //강제 HistoryBack 못타게
        isGoBackClicked = true;

        /**
         * *****************모달 토스트 (상 위) ***********************
         *상위에서 백버튼시
         *
         * 1. 링크로 바로 들어갔을 시  state가 null
         * 2. 다음 모달토스트 창 열려 있을 경우        (계속 뒤로가기 눌렀을 시)
         * 3. 다음 모달토스트 창에서 x버튼 눌렀을 경우  (모달 토스트창에서 네, 아니오 눌렀을 시)      ----> 다음 모달에서 historyBack하면서 이벤트 발생(모달, 모달)
         */
        // 01. 링크로 바로 들어갔을 시 state가 null
         if (window.history.state === null) {
             //현재 모달창 닫을지 말지 결정할 경우
             if (modalPopupStore.openYn) {   //닫고 싶을 경우
                 //현재 모달 팝업창 닫기
                 modalPopupStore.closeModalPopup();
             }else { //닫기 싫을 경우
                 //다시 push 해줌 (자동 historyBack 됬으므로)
                 window.history.pushState({page:"modal"}, "modal", '');
                 //callBack함수  (다시 새로운 팝업토스트창 열기)
                 modalPopupStore.callBack();
             }
             return;
         }
        //02. 상위에서 왔을 시
        else if (event.target.history.state.page === 'modal' || event.target.history.state.page === 'modalToast') {
            return;
        }

        /**
         * ******************모달  (현 재) ***************************
         * 현재 백버튼시
         *
         *  %%%%%%%% 뒤로가기시 팝업창을 닫지 안닫을지 여부 결정
         */
        // 현재
        else {
            //현재 모달창 닫을지 말지 결정할 경우
            if (modalPopupStore.openYn) {   //닫고 싶을 경우
                //현재 모달 팝업창 닫기
                modalPopupStore.closeModalPopup();
            }else { //닫기 싫을 경우
                //다시 push 해줌 (자동 historyBack 됬으므로)
                window.history.pushState({page:"modal"}, "modal", '');
                //callBack함수  (다시 새로운 팝업토스트창 열기)
                modalPopupStore.callBack();
            }
        }

    };

    /******************************************************************************************************************/

    /**
     * 함수 function
     */

    //x버튼 클릭시 함수
    const goHistoryBack = () => {

        //강제 HistoryBack
        isGoBackClicked = false;
        // //모달토스트팝업창 초기화
        // modalToastPopupStore.setIsBeforeButtonClicked(false);
        //현재 모달 팝업창 닫기
        modalPopupStore.closeModalPopup();
    };

    //ok 버튼 클릭시 함수
    const onSubmit = (value) => {

        //강제 HistoryBack
        isGoBackClicked = false;
        //submit callBack 호출
        modalPopupStore.submitCallBack(value);
    };

    //page 이동 함수
    const onPagePush = (value) => {
        modalPopupStore.submitCallBack(value);
    };


    /******************************************************************************************************************/

    /**
     * useEffrct
     * 1. 이미지, 파일 가지오기
     * 2. - 뒤로가기버튼 눌렀을시 모달창 닫기 (window #추가) (자동 주소 뒤로 이동)
     *    - x버튼 눌렀을시 모달창 닫기 (window # 추가) (강제 주소 이동 => window history back)
     *    
     *    return => unMount 이기에 항상 뒤에 실행 -> data.open 반대로 줘야함
     */
    useEffect(()=>{

        /**
         * 모달창 오픈시 (강제로 window 주소 추가)  =>>>> hidden이기 때문에 이미 컴포넌트 존재
         */
        if(modalPopupStore.isOpen) {
            //모달이 켜질때마다 새로운 state를 history에 추가
            window.history.pushState({page:"modal"}, "modal", '');
            //뒤로가기 눌렀을 때 onCloseHandler 함수 실행
            window.addEventListener("popstate", goBackHandler);
            //뒤로가기시 백여부 (전페이지 state 그대로 남기기위한)
            backHistory.state = 'modal';
        }

        return () => {

            /**
             * hidden이기 때문에 ==> 미리 layOut에서 컴포넌트 생성 (hidden = true로 관리)
             *
             * 1. 맨 처음 창 open시 호출 (isOpen=true)
             * 2. 창 닫았을시 호출 (isOpen=false)
             */
            if(!modalPopupStore.isOpen) {
                window.removeEventListener("popstate", goBackHandler);
                //뒤로가기를 누르지 않고 종료할 경우엔 state가 새로 있으니 뒤로가기를 해줘서 popstate 이벤트를 발생시켜야 함
                if (!isGoBackClicked) {
                    window.history.back();
                }
            }
        };

    },[modalPopupStore.isOpen]);



    /******************************************************************************************************************/


    /**
     * 모달 switch
     * @returns {JSX.Element}
     */

    // 모달 폼 형식
    switch (modalPopupStore.page) {
        //소통해요
        case "c" : {
            return (
                <>
                    <CommunicateModal
                        goHistoryBack={goHistoryBack}
                        onSubmit={onSubmit}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}
                        modalToastPopupStore={modalToastPopupStore}>
                    </CommunicateModal>
                </>
            )
        }
        //모여봐요
        case "g" : {
            return (
                <>
                    <GroupModal
                        goHistoryBack={goHistoryBack}
                        onSubmit={onSubmit}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}
                        modalToastPopupStore={modalToastPopupStore}>
                    </GroupModal>
                </>
            )
        }
        //신고하기
        case "r" : {
            return (
                <>
                    <ReportModal
                        goHistoryBack={goHistoryBack}
                        onSubmit={onSubmit}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}>
                    </ReportModal>
                </>
            )
        }
        //초대해요
        case "i" : {
            return (
                <>
                    <InviteModal
                        goHistoryBack={goHistoryBack}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}>
                    </InviteModal>
                </>
            )
        }
        // 프로필 이미지 수정
        case "p" : {
            return (
                <>
                    <EditProfileImgModal
                        goHistoryBack={goHistoryBack}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}
                    />
                </>
            )
        }

        // 닉네임 수정
        case "n" : {
            return (
                <>
                    <EditNicknameModal
                        goHistoryBack={goHistoryBack}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}
                    />
                </>
            )
        }
        // 스티커 수정
        case "s" : {
            return (
                <>
                    <EditStickerModal
                        goHistoryBack={goHistoryBack}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}
                    />
                </>
            )
        }
        // 그룹 만들기 모달
        case "cg" : {
            return (
                <>
                    <CreateGroupModal
                        goHistoryBack={goHistoryBack}
                        onPagePush={onPagePush}
                        isOpen={modalPopupStore.isOpen}
                        modalPopupStore={modalPopupStore}
                    />
                </>
            )
        }
        // 그룹 가입신청 모달
        case "ga" : {
            return (
                <>
                    <GroupApplyModal
                        goHistoryBack={goHistoryBack}
                        onSubmit={onSubmit}
                        isOpen={modalPopupStore.isOpen}
                        userStore={store.userStore}
                        modalPopupStore={modalPopupStore}
                        modalToastPopupStore={modalToastPopupStore}
                    />
                </>
            )
        }

        // 그룹 대표 이미지 설정
        case "gi" : {
            return (
                <EditGroupImageModal
                    goHistoryBack={goHistoryBack}
                    isOpen={modalPopupStore.isOpen}
                    modalPopupStore={modalPopupStore}
                />
            )
        }

        // 그룹 커버 이미지 설정
        case "gci" : {
            return (
                <EditGroupCoverImageModal
                    goHistoryBack={goHistoryBack}
                    isOpen={modalPopupStore.isOpen}
                    modalPopupStore={modalPopupStore}
                />
            )
        }

        // 스티커 뷰 모달
        case "sv" : {
            return (
                <StickerDetailModal
                    goHistoryBack={goHistoryBack}
                    isOpen={modalPopupStore.isOpen}
                    modalPopupStore={modalPopupStore}
                />
            )
        }

        // 언어 설정 모달
        case "ls" : {
            return (
                <LanguageModal
                    goHistoryBack={goHistoryBack}
                    isOpen={modalPopupStore.isOpen}
                    modalPopupStore={modalPopupStore}
                />
            )
        }

        // 사용자 프로필 조회
        case "vp" : {
            return (
                <ViewProfileModal
                    goHistoryBack={goHistoryBack}
                    isOpen={modalPopupStore.isOpen}
                    modalPopupStore={modalPopupStore}
                />
            )
        }
        default: {
            return (
                null
            )
        }
    };

}

export default observer(Modal);