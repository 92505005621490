import {ToastPopupText, ToastPopupWrap} from "./ToastPopupStyles";
import {IconButton} from "./index";
import {observer} from "mobx-react";
import {useStores} from "../../../stores";
import CloseIcon from "../../../resources/icons/modal_cancel.svg"
import {useEffect, useLayoutEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

const ToastPopup = (props) => {

    const {text, onClose} = props;
    const toastRef = useRef();
    const location = useLocation();

    const {store} = useStores();
    const {toastPopupStore} = store;

    const style = toastPopupStore.style;

    const onCloseHandler = (e) => {
        // if(onClose) onClose();
        toastPopupStore.closeToastPopup();
    }

    const obsHandler = (mutationList) =>{
        for(const mutation of mutationList){
            if(mutation.type === 'childList'){
                toastRef.current.style.top = mutation.target.scrollHeight + 50 + 'px';
            }
        }
    }

    useEffect(()=>{
        const observer = new MutationObserver(obsHandler);
        // const match = matchPath(location.pathname,{
        //     path : `${routes.GROUPS}/:id`
        // });
        // if(match && match.isExact){
        //     let container = document.getElementById('group-not-joined-container');
        //     if(container) observer.observe(container, {attributes : true, subtree : true, childList : true})
        // }
        return ()=>{
            observer.disconnect();
            if(toastRef.current) toastRef.current.style.top = '50px';
        }

    },[location.pathname]);

    useLayoutEffect(()=>{
        if(!toastPopupStore.isOpen) toastRef.current.style.top = '50px';
        // else {
        //     let container = document.getElementById('group-not-joined-container');
        //     if(container) toastRef.current.style.top = container.scrollHeight + 50 + 'px';
        // }
    },[toastPopupStore.isOpen])

    return(
        <ToastPopupWrap className={!toastPopupStore.isOpen ? 'hidden' : null} ref={toastRef}>
            <ToastPopupText>{toastPopupStore.popupText}</ToastPopupText>
            <IconButton width={'32px'} height={'32px'} onClick={onCloseHandler} src={CloseIcon} />
        </ToastPopupWrap>
    )
}

// ToastPopup.defaultProps = {
//     text : 'enter the toast text',
//     onClose : null
// }

export default observer(ToastPopup);