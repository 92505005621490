import styled from "styled-components";


export const AuthErrorWrap = styled.div` 
    
    padding: 0px 16px;
`

export const AuthErrorLogoWrap = styled.div` 
     margin: 140px 0px 40px 0px; 
     text-align: center; 
     font-size: 0px;
`

export const AuthErrorTitle = styled.h1` 
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #191E29;
    margin-bottom: 140px;
`

export const AuthErrorLogoImg = styled.img` 
    
`

export const AuthErrorContent = styled.div` 
     margin: 63px 0px 40px 0px; 
     text-align: center; 
     font-size: 0px;
`


