import styled from 'styled-components';

export const IconWrap = styled.div`
  width : ${({width}) => (width || "")};
  height : ${({height}) => (height || "")};
  // color : ${({color}) => (color ? color : "#191E29")};
  flex : none;
  display : inline-block;
`

export const IconImg = styled.img.attrs(props=>({
    src : props.src
}))`
  width : 100%;
  height : 100%;
  vertical-align : top;
`