import {
    GoBackButtonWrap,
    ItemContainer,
    TitleText,
} from "../item/ItemStyles";
import {
    Card,
    Chips,
    IconButton,
    NoResult,
    ItemDetail
} from "../../components/presentationals/global";
import {
    HomeContentWrap,
    TopicsWrap
} from "../home/HomeStyles";
import {
    JoinGroups
} from "../../pages/groups/GroupsStyles";


import BackIcon from "../../resources/icons/back.svg";
import NoGroupImg from "../../resources/icons/no_group.svg";
import NoPostImg from "../../resources/icons/no_post.svg";

import {useRef, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {receiveResponse} from "../../utils/api";
import isEmpty from "is-empty";
import routes from "../../constants/routes.json";
import {ThreeDots} from "react-loader-spinner";
import {getISOFormat} from "../../utils/dateHandler";

const Activity = (props) => {
    const {userStore, toastPopupStore, history} = props;
    const type = props.match.params.type;
    const searchParams = new URLSearchParams(props.location.search);

    // 목록
    const [list, setList] = useState([]);
    // 로딩상태
    const [isLoading, setIsLoading] = useState(false);
    // 전체 개수
    const [total, setTotal] = useState(null);
    // 페이징
    const [paging, setPaging] = useState({page: 0, size: 10});
    // 무한 스크롤 중복 방지
    const preventRef = useRef(null);
    // 무한 스크롤 옵저버 요소
    const obsRef = useRef(null);
    // 마지막 페이지 등록 여부
    const endRef = useRef(null);
    // 게시글/댓글 여부(소통해요, 모여봐요 한정)
    const [isBoardItem, setIsBoardItem] = useState((searchParams.get('isBoardItem') ?? 'true') === 'true');
    // 소통해요/모여봐요 여부(소통해요, 모여봐요, 북마크 한정)
    const [isCommunication, setIsCommunication] = useState((searchParams.get('isCommunication') ?? 'true') === 'true');
    // 소통해요, 모여봐요, 북마크에서 선택한 대상 정보
    const [selectedItemId, setSelectedItemId] = useState(null);

    // observer 핸들링
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting && preventRef.current) {
                if (endRef.current) return;
                preventRef.current = false;
                setPaging({...paging, page: paging.page + 1});
            }
        }, {threshold: 0});

        if (obsRef.current) observer.observe(obsRef.current);
        return () => observer.disconnect();
    }, [paging]);
    useEffect(() => {
        const getList = () => {
            if (paging.page === 0) {
                setList([]);
            }

            const successFunc = res => {
                if (res.status === 200) {
                    setList((paging.page === 0 ? [] : list).concat(res.data.list));
                    if (type !== 'communication' && type !== 'meeting' && type !== 'bookmark') {
                        setTotal(res.data.total);
                    }
                    preventRef.current = true;
                    endRef.current = res.data.end;
                }
                setIsLoading(false);
            };
            setIsLoading(true);
            ({
                invited: () => receiveResponse({target: 'invited', ...paging}, 'groups', 'get'),
                joined: () => receiveResponse({target: 'joined', ...paging}, 'groups', 'get'),
                communication: (isBoardItem
                    ? () => receiveResponse({target: 'mine', type: 'C', ...paging}, 'items', 'get')
                    : () => receiveResponse({target: 'mine', type: 'C', ...paging}, 'comments', 'get')),
                meeting: (isBoardItem
                    ? () => receiveResponse({target: 'mine', type: 'M', ...paging}, 'items', 'get')
                    : () => receiveResponse({target: 'mine', type: 'M', ...paging}, 'comments', 'get')),
                bookmark: (isCommunication
                    ? () => receiveResponse({target: 'bookmark', type: 'C', ...paging}, 'items', 'get')
                    : () => receiveResponse({target: 'bookmark', type: 'M', ...paging}, 'items', 'get')),
            }[type])().then(successFunc);
        };

        getList();
    }, [paging, type]);

    const createTab = () => {
        const tabList = [];
        if (['communication', 'meeting'].includes(type)) {
            tabList.push({
                key: 'tab-item',
                children: '게시글',
                selected: isBoardItem,
                onClick: () => {
                    endRef.current = true;
                    setIsBoardItem(true);
                    setPaging({...paging, page: 0});
                    history.replace({...props.history.location, search: '?isBoardItem=true'});
                },
            });
            tabList.push({
                key: 'tab-comment',
                children: '댓글',
                selected: !isBoardItem,
                onClick: () => {
                    endRef.current = true;
                    setIsBoardItem(false);
                    setPaging({...paging, page: 0});
                    history.replace({...props.history.location, search: '?isBoardItem=false'});
                },
            });
        }
        if (['bookmark'].includes(type)) {
            tabList.push({
                key: 'tab-communication',
                children: '소통해요',
                selected: isCommunication,
                onClick: () => {
                    endRef.current = true;
                    setIsCommunication(true);
                    setPaging({...paging, page: 0});
                    history.replace({...props.history.location, search: '?isCommunication=true'});
                },
            });
            tabList.push({
                key: 'tab-meeting',
                children: '모여봐요',
                selected: !isCommunication,
                onClick: () => {
                    endRef.current = true;
                    setIsCommunication(false);
                    setPaging({...paging, page: 0});
                    history.replace({...props.history.location, search: '?isCommunication=false'});
                },
            });
        }
        return !isEmpty(tabList) ? <TopicsWrap>
            {tabList.map((tab) => <Chips key={`tab-${tab.id}`} type={'solid'} {...tab}/>)}
        </TopicsWrap> : null;
    };
    const openDetailModal = (itemId, isCommunication) => {
        if (isCommunication !== undefined) {
            setIsCommunication(isCommunication);
        }
        setSelectedItemId(itemId);
    };
    const Empty = () => <JoinGroups noResult={true}>{({
        invited: <NoResult src={NoGroupImg} title={'초대받은 그룹이 없어요.'}/>,
        joined: <NoResult src={NoGroupImg} title={'참여중인 그룹이 없어요.'}/>,
        communication: (isBoardItem
            ? <NoResult src={NoPostImg} title={'작성한 게시글이 없어요.'}/>
            : <NoResult src={NoPostImg} title={'작성한 댓글이 없어요.'}/>),
        meeting: (isBoardItem
            ? <NoResult src={NoPostImg} title={'작성한 게시글이 없어요.'}/>
            : <NoResult src={NoPostImg} title={'작성한 댓글이 없어요.'}/>),
        bookmark: <NoResult src={NoPostImg} title={'북마크한 게시글이 없어요.'}/>,
    }[type])}</JoinGroups>;
    const createContent = () => list.map((item, idx) => ({
        invited: <Card key={`card-${idx}`} type={'group'} item={item} onClick={() => history.push(`${routes.GROUPS}/${item.groupId}`)}
                       isInvited={true} onAccept={() => acceptInvite(item)} onDecline={() => declineInvite(item)}/>,
        joined: <Card key={`card-${idx}`} type={'group'} item={item} onClick={() => history.push(`${routes.GROUPS}/${item.groupId}`)}
                      onClickPin={() => item.pinDate ? unpin(item) : pin(item)} isUsePin={true}/>,
        communication: (isBoardItem
            ? <Card key={`card-${idx}`} type={'board'} item={item} onClick={() => openDetailModal(item.itemId, true)}/>
            : <Card key={`card-${idx}`} type={'comment'} item={item} onClick={() => openDetailModal(item.itemId, true)}/>),
        meeting: (isBoardItem
            ? <Card key={`card-${idx}`} type={'group-board'} item={item} onClick={() => openDetailModal(item.itemId, false)}
                    showBoard/>
            : <Card key={`card-${idx}`} type={'comment'} item={item} onClick={() => openDetailModal(item.itemId, false)}
                    showBoard/>),
        bookmark: (isCommunication
            ? <Card key={`card-${idx}`} type={'board'} item={item} onClick={() => openDetailModal(item.itemId)}/>
            : <Card key={`card-${idx}`} type={'group-board'} item={item} onClick={() => openDetailModal(item.itemId)}
                    showBoard/>),
    }[type]));

    // 초대받은 그룹
    const removeInviteByBoardId = (groupId) => {
        setList(list.filter(item => item.groupId !== groupId));
        setTotal(total - 1);
    };
    const acceptInvite = (target) => receiveResponse({}, `groups/${target.groupId}/accept-invite`, 'patch').then(res => {
        if (res.status === 200 && res.data) {
            removeInviteByBoardId(target.groupId);
            userStore.setUserState({
                ...userStore.userState,
                meetingInviteCount: userStore.userState.meetingInviteCount - 1,
                meetingJoinCount: userStore.userState.meetingJoinCount + 1,
            });
            toastPopupStore.openToastPopup(`'${target.groupName}' 그룹에 가입되었습니다.`);
        } else {
            toastPopupStore.openToastPopup('가입에 실패했습니다. 초대 상태를 다시 확인해주세요.');
        }
    });
    const declineInvite = (target) => receiveResponse({}, `groups/${target.groupId}/decline-invite`, 'patch').then(res => {
        if (res.status === 200 && res.data) {
            removeInviteByBoardId(target.groupId);
            userStore.setUserState({
                ...userStore.userState,
                meetingInviteCount: userStore.userState.meetingInviteCount - 1,
            });
            toastPopupStore.openToastPopup(`'${target.groupName}' 초대를 거절했습니다.`);
        } else {
            toastPopupStore.openToastPopup('초대 거절에 실패했습니다. 초대 상태를 다시 확인해주세요.');
        }
    });
    // 참여중인 그룹
    const setPinDate = (groupId, pinDate) => {
        userStore.setJoinGroups(list.map(item => item.groupId === groupId ? {...item, pinDate: pinDate} : item));
        setList(userStore.joinGroups);
    }
    const pin = (target) => {
        if (list.filter(item => item.pinDate).length === 4) {
            toastPopupStore.openToastPopup('최대 4개까지만 설정할 수 있습니다.');
            return;
        }
        receiveResponse({}, `groups/${target.groupId}/pin`, 'patch').then(res => {
            if (res.status === 200 && res.data) {
                setPinDate(target.groupId, getISOFormat());
                toastPopupStore.openToastPopup(`핀을 설정했습니다.`);
            } else {
                toastPopupStore.openToastPopup('핀 설정을 실패했습니다.');
            }
        })
    };
    const unpin = (target) => receiveResponse({}, `groups/${target.groupId}/unpin`, 'patch').then(res => {
        if (res.status === 200 && res.data) {
            setPinDate(target.groupId, null);
            toastPopupStore.openToastPopup(`핀을 해제했습니다.`);
        } else {
            toastPopupStore.openToastPopup('핀 해제를 실패했습니다.');
        }
    });
    const deletePost = (itemId) => {
        setSelectedItemId(null);
        setList(list.filter(item => item.itemId !== itemId));
    };
    // 글 상세 창에서 키워드 클릭시
    const onClickKeyword = (keyword) => {
        let route;

        if (isCommunication) {
            route = routes.HOME
        } else {
            route = `${routes.GROUPS}/${selectedItemId}`
        }

        props.history.push({
            pathname: route,
            state: {
                keyword
            }
        })
    }

    return <ItemContainer>
        <GoBackButtonWrap>
            <IconButton width={'42px'} height={'42px'} src={BackIcon} onClick={() => history.go(-1)}/>
        </GoBackButtonWrap>

        <TitleText>{{
            invited: '초대받은 그룹', joined: '참여중인 그룹', communication: '소통해요 내가 쓴 게시글/댓글', meeting: '모여봐요 내가 쓴 게시글/댓글', bookmark: '북마크',
        }[type]}<strong className={'count'}>{total}</strong></TitleText>

        {createTab()}

        <HomeContentWrap>
            {isLoading ? <ThreeDots
                height={"10"}
                wrapperStyle={{
                    width: '100%',
                    justifyContent: 'center',
                    height: '100%',
                    paddingTop: '30px'}}
                color={'#3A6AF6'}
            /> : null}
            {isEmpty(list) ? Empty() : null}
            {!isEmpty(list) ? createContent() : null}
        </HomeContentWrap>

        <div ref={obsRef}></div>

        {selectedItemId ? <ItemDetail
            onClose={e => setSelectedItemId(null)}
            itemId={selectedItemId}
            type={'popup'}
            page={isCommunication ? 'c' : 'g'}
            onDelete={deletePost}
            onError={e => setSelectedItemId(null)}/> : null}
    </ItemContainer>;
}

export default observer(Activity);
