import styled from "styled-components";

export const SettingUserWrap = styled.div`
    width : 100%;
    height : 53px;
    padding : 5px 16px;
    display : flex;
    position : relative;
    align-items : center;
    gap : 10px;
    margin-bottom: 10px;
`

export const SettingUserImageWrap = styled.div`
    position:relative;
    flex : none;
    width : 40px;
    height : 40px;
`

export const SettingUserImage = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 100%;
    height : 100%;
    border-radius : 50%;
`

export const SettingUserInfoWrap = styled.div`
    line-height: 20px;
    flex-grow : 1;
`

export const SettingUserName = styled.div`
    display : flex;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #191E29;
    gap : 7px;
    padding-bottom: 3px;
`

export const SettingUserCompany = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
`

export const SettingUserMenuWrap = styled.div`
    display : flex;
    flex-direction : column;
    align-items : flex-end;
    position : relative;
`

export const SettingUserMenuButton = styled.div`
    border-radius: 30px;
    z-index : 2;
    
    // &:hover {
    //     background: #EAEAEA;
    // }
`

export const SettingUserMenu = styled.div`
    margin-top : 5px;
    z-index : 3;
    position : absolute;
    top : 20px;
    width : 150px;
`

