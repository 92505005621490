import { ConfirmModal } from "../global/index";
import {observer} from "mobx-react";

const ConfirmModalToast = (props) => {

    // const {store} = useStores();
    const modalToastPopupStore = props.modalToastPopupStore;
    const goHistoryBack = props.goHistoryBack;

    //데이터
    const data = modalToastPopupStore;

    // 데이터 아이템
    const page = data.page;
    const isOpen = data.isOpen;
    const setCancelPopup = data.setCancelPopup;
    const titleTxt = data.titleTxt;
    const guideTitle = data.guideTitle;
    const guideText = data.guideText;
    const okText = data.okText;
    const cancelText = data.cancelText;

    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    //취소 버튼
    const onCancelHandler = (e) => {
        //1. 이전 모달창 닫기 여부 (취소)
        setCancelPopup(false);
        //2. 현재 모달토스트팝업창 강제뒤로가기
        goHistoryBack();
    }

    // ok 버튼 (창을 다 닫기)
    const onOkHandler = (e) => {
        //1. 이전 모달창 닫기 여부 (ok)
        setCancelPopup(true);
        //2. 이전 토스트팝업창 버튼 눌렀을시 여부
        goHistoryBack();
    }

    /******************************************************************************************************************/

    /**
     *
     * render
     * @returns {JSX.Element}
     */

    return (
        <>
            <ConfirmModal
                page={page}
                isOpen={isOpen}
                title={titleTxt}
                guideTitle={guideTitle}
                guideText={guideText}
                onCancel={e=>onCancelHandler(e)}
                onOk={e=>onOkHandler(e)}
                onCancelText={cancelText}
                onOkText={okText}
            />
        </>
    );

}

export default observer(ConfirmModalToast);