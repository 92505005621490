import styled from "styled-components";

export const ModalContainer = styled.div`
    width : 100vw;
    height : 100vh;
    background-color : ${({dark})=> {
        if(dark){
            return 'rgba(25, 30, 41, 0.8)';
        }else{
            return `#FFFFFF`;
        }
    }};
    
    display : block;
    justify-content : center;
    position : fixed;
    top : 0;
    left : 0;
    right: 0;
    z-index : 5;
 
    &.hidden { display:none; }
`

export const ModalScrollBox = styled.div`

    padding : 10px 16px 20px 16px;
    overflow : overlay;
    height: calc(100% - (40px + 44px + 48px));
    
    &::-webkit-scrollbar {
        // width: 22px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
    &.file {
        height: calc(100% - (44px + 127px));
    }
    
    &.groupNotice {
        height: calc(100% - (44px + 174px));
    }
`



export const ModalScrollNoBottomBox = styled.div`

    padding : 0px 16px 0px 16px;
    overflow : overlay;
    height: calc(100% - 44px);
    
    &::-webkit-scrollbar {
        // width: 22px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
    &.imageModal {
        background: #191E29; 
        width: 100%;
    }
`

export const ModalDetailScrollBox = styled.div`

    padding : 0px 16px 20px 16px;
    overflow : overlay;
    height: calc(100% - 44px);
    
    &::-webkit-scrollbar {
        // width: 22px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
    &.imageModal {
        background: #191E29; 
        width: 100%;
    }
`

export const ModalWrapInner = styled.div`
    display : flex;
    flex-direction : column;
    gap : 10px;
`

export const ModalHeaderWrap = styled.div`
    height: 44px;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const ModalCloseWrap = styled.div`
    position : absolute;
    top: 10px;
    right : 16px;
    z-index : 3;
`

export const ModalTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding : 0 16px;
    position : relative;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    height: 44px;
    padding-top: 10px;
    justify-content: center;
`

export const ModalSubTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #191E29;
`

export const ModalBody = styled.div`
    position : relative;
    border : ${({theme, disabled, focus})=> {
        if(disabled){
            return '1px solid #FF7171';
        }else if (focus) {
            return '1px solid #3A6AF6';
        }else{
            return `1px solid ${theme.colors.gray_stroke_100}`;
        }
    }};
    border-radius: 10px;
    // padding : 16px 20px;
`

export const ModalBodyTitleWrap = styled.div`
    // display : flex;
    // border-bottom : #F7F9FB;
    // padding-bottom : 10px;
    gap : 4px;
    box-sizing: border-box;

    position: relative;
    height: 48px;

    background: ${({type})=>type==='modify' ? '#F2F4F6' : '#FFFFFF'};
    border: 1px solid #E5EBF0;
    border-radius: 10px;
    padding: 12px 20px;
    display : flex;
    
    border : ${({theme, disabled})=> {
        if(disabled){
            return '1px solid #FF7171';
        }else{
            return '1px solid #E5EBF0';
        }
    }};
    
    &.focus {
        border : ${({theme, disabled})=> {
            if(disabled){
                return '1px solid #FF7171';
            }else{
                return '1px solid #3A6AF6';
            }
        }};
    }
    &.focus1 {
        border : 1px solid #3A6AF6;
    }
    &.focus2 {
        border : 1px solid #3A6AF6;
    }
    &.focus3 {
        border : 1px solid #3A6AF6;
    }
    &.focus4 {
        border : 1px solid #3A6AF6;
    }
    &.focus5 {
        border : 1px solid #3A6AF6;
    }
    
    &.disabled {
        background : #F2F4F6;
    }
    
`

export const ModalBodyTitleInput = styled.input`
    position: relative;
    flex-grow : 1;
    white-space:nowrap;
    background : inherit;

    &.focus {
        colors: '#3A6AF6';
        
        border : ${({theme, disabled})=> {
            if(disabled){
                return '1px solid #FF7171';
            }else{
                return '1px solid #3A6AF6';
            }
        }};
    }
    
    /* H3-Medium */
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline : none;
    border : none;
    width: 100%;
    
    /* gray50 */
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: ${({theme}) => theme.colors.gray_50};
    };
  
   ::-ms-input-placeholder { /* Microsoft Edge */
       color: ${({theme}) => theme.colors.gray_50};
   };
   
   ::placeholder {
        color: ${({theme}) => theme.colors.gray_50};
    };
  
   //  flex-grow : 1;
   color : ${({theme,type})=> {
       if(type==='modify') return '#75818D';
       return theme.colors.black;
   }};
  
`

export const ModalBodyContent = styled.div`
    height : ${({page,form})=>{
        if(form==='Vote') return '226px';
        else if(form==='Sell' || form==='Buy') return '284px';
        return '325px';
    }};
    
    cursor : text;
    position : relative;
    padding : 12px 20px;
    
    overflow : overlay;
    
    &::-webkit-scrollbar {
        // width: 20px;
        display: none;
    }
    

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`

export const ModalBodyContentInput = styled.textarea`
    width : 100%;
    display : block;
    outline : none;
    border : none;
    resize : none;
    word-break : break-all;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    position: relative;
    
    ::placeholder {
        color: ${({theme}) => theme.colors.gray_50};
    }
  
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${({theme}) => theme.colors.gray_50};
    }
  
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${({theme}) => theme.colors.gray_50};
    }
`

export const ModalBodyContentFilesWrap = styled.div`
    margin-top : 10px;
    display : flex;
    flex-direction : column;
    gap : 10px;
`

export const ModalBodyContentFiles = styled.div`
    width : 100%;
    display : flex;
    gap : 10px;
    flex-wrap : wrap;
`

export const ModalCountWrap = styled.div`
    display : flex;
    justify-content : flex-end;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding : 0 20px 12px 20px;
`

export const ModalTitleCountWrap = styled.div`
    display : flex;
    flex : none;
`

export const ModalCountLen = styled.div`
    font-size : 14px;
    font-weight : 500;
 
    color : ${({disabled, entered}) => {
        if(disabled) return '#FF7171';
        if(entered) return '#191E29';
        return '#AEB7C0';
    }};
`

export const ModalCount = styled.div`
    color : #AEB7C0;
    font-size : 14px;
    font-weight : 500;
`

export const ModalKeywords = styled.div`
    margin : 11px 0 14px 0;
    // padding : 14px 20px;
    // line-height : 20px;
    // color : ${({theme}) => theme.colors.gray_50};
    // border : ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
    // border-radius: 10px;
`

export const ModalBottom = styled.div`
    display : flex;
    align-items : center;
    justify-content : space-between;
    margin-bottom: 15px;
`

export const ModalAttachFiles = styled.div`
    display : flex;
    align-items : center;
    gap : 8px;
`

export const ModalBottomCheckBoxes = styled.div`
    display : flex;
    gap : 10px;
`

export const ModalAttachFile = styled.div`
    display : flex;
    align-items : center;
    gap : 4px;
`

export const ModalAttachFileText = styled.div`
    font-weight: 400;
    font-size: 16px;
    font-size: 16px;
    line-height: 20px;
`

export const ModalLoadingWrap = styled.div`
    position : absolute;
    display : flex;
    justify-content : center;
    align-items : center;
    top : 0;
    left : 0;
    width : 100%;
    height :  100%;
    border-radius: 30px;
`

export const ModalBodyNoticeMemoWrap = styled.a`

    font-weight: 500;
    font-size: 14px;
    /* or 20px */
    
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
    /* gray50 */
    color: #AEB7C0;

`

// export const ModalButtonWrap = styled.div`
//     padding : 20px 0px 0px 0px;
// `

export const ModalBottomWrap = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0px;
    padding: 20px 16px;
    background : #FFFFFF;
    backface-visibility : hidden;
    -webkit-backface-visibility : hidden;
`