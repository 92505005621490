import styled from "styled-components";

export const EditStickerModalBody = styled.div`
  height: 540px;
`
export const EditStickerPreviewWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`
export const EditStickerPreviewEmptyWrap = styled.div`
  position: relative;

  display: flex;
  justify-content: center;

  width: 120px;
  height: 120px;
  border: 1px dashed #AEB7C0;
  
  border-radius: 7.97838px;
`
export const EditStickerPreviewEmptyTxt = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 110px;
  height: 49px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;
  color: #AEB7C0;
`
export const EditStickerPreviewSelectedWrap = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`
export const EditStickerPreviewSelectedImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 110px;
  height: 110px;
  border-radius: 10px;
`
export const EditStickerPreviewSelectedTxt = styled.div`
  width: 100%;
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  color: #191E29;
`

export const EditStickerList = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
`
export const EditStickerItem = styled.div`
  text-align: center;
`
export const EditStickerImgWrap = styled.div`
  position: relative;
  text-align: center;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  border: 1px solid #E5EBF0;
  margin-bottom: 8px;
`
export const EditStickerSelectedWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  border: 2px solid #3A6AF6;
`
export const EditStickerCheckIcon = styled.img.attrs(props => ({
    src: props.src
}))`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
`

export const StickerRepWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  border: 2px solid #353C49;
  overflow: hidden;
`

export const StickerRepTxt = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  padding: 1px 4px 0 5px;

  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  vertical-align: middle;
  color: #FFFFFF;

  background: #353C49;
  border-radius: 0 0 0 10px;
`

export const EditStickerLockWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  border-radius: 10px;
  background-color: #F7F9FB;
`
export const EditStickerLockImg = styled.img.attrs(props => ({
    src: props.src
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`
export const EditStickerImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;

  &.blur {
    filter: blur(7.22222px) grayscale(1);
  }
`
export const EditStickerTxt = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  &.lock {
    color: #AEB7C0;
  }
`