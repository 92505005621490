import styled from "styled-components";

export const ToastPopupContainer = styled.div`
    width : 100vw;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    justify-content : center;
    display : flex;
`

export const ToastPopupWrap = styled.div`
    background: #353C49;
    color : ${({theme})=>theme.colors.white};
    width: 90%;
    border-radius: 14px;
    padding : 14px 20px 14px 20px;
    display : flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items : center;
    z-index : 7;
    // margin : 0 auto;
    top : 50px;
    left:50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position : absolute;
    &.hidden{display:none;}
`

export const ToastPopupText = styled.div`
    flex-grow : 1;
`