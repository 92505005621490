import path from "path-browserify";
import {getByteSize, getStandardExtStr} from "../../../utils/fileHandler";
import {Button, IconButton} from "./index";
import AlertIcon from "../../../resources/icons/file_alert.svg";
import env from "../../../constants/env";
import {
    FileModalButtons,
    FileModalContainer, FileModalDot,
    FileModalFileExt,
    FileModalFileName,
    FileModalFileNameWrap,
    FileModalFilesItem,
    FileModalFileSize,
    FileModalFilesList,
    FileModalFilesTitle,
    FileModalFilesWrap, FileModalStandardItem, FileModalStandardList, FileModalStandardTitle, FileModalStandardWrap,
    FileModalSubTitle,
    FileModalTitle,
    FileModalWrap
} from "./FileConfirmModalStyles";
import {useTranslation} from "react-i18next";

const FileConfirmModal = (props) => {

    const {t} = useTranslation();
    const {isOpen, type, onOk, files, isFailAll} = props;

    const onOkHandler = (e) => {
        if(onOk && typeof onOk === 'function') onOk();
    }

    const createFilesList = (files) => {

        let _all = [];
        let _exts = [];
        let _sizes = [];
        let _errors = [];

        files.forEach(file=>{
            if(!file.errorCheck){
                _errors.push(file);
                return;
            }
            else if(!file.extCheck && !file.sizeCheck){
                _all.push(file);
                return;
            }
            else if(!file.extCheck) {
                _exts.push(file);
                return;
            }
            else if(!file.sizeCheck){
                _sizes.push(file);
                return;
            }
        })

        let newFiles = [..._all,..._sizes,..._exts,..._errors];

        return newFiles.map(file=>{

            const base = path.parse(file.file.name);
            const name = base.name;
            const ext = base.ext;
            const size = file.file.size;
            const sizeCheck = file.sizeCheck;
            const extCheck = file.extCheck;
            const errorCheck = file.errorCheck;

            return(
                <FileModalFilesItem>
                    <FileModalFileNameWrap>
                        {/*<FileModalFileName>{`${name}.`}</FileModalFileName>*/}
                        <FileModalDot />
                        <FileModalFileName alert={!errorCheck}>{name}</FileModalFileName>
                        <FileModalFileExt alert={!extCheck || !errorCheck}>{ext}</FileModalFileExt>
                    </FileModalFileNameWrap>
                    <FileModalFileSize alert={!sizeCheck || !errorCheck}>{!errorCheck ? t('CORRUPTED_FILE') : getByteSize(size)}</FileModalFileSize>
                </FileModalFilesItem>
            )
        })


    }

    const getSubTitle = (files) => {
        if(isFailAll)
            return t('FILE_CONFIRM_POPUP_FAIL_ALL')
        if(files.length === 0){
            return t('FILE_CONFIRM_POPUP_FAIL_LENGTH',{maxLen : env.totalFileLength})
        }else{
            return t('FILE_CONFIRM_POPUP_FAIL_EXT');
        }
    }

    return(
        <FileModalContainer className={!isOpen ? 'hidden' : null}>
            <FileModalWrap>
                <FileModalTitle>{t('ALERT')}</FileModalTitle>
                <FileModalSubTitle>
                    {getSubTitle(files)}
                </FileModalSubTitle>
                {
                    files.length > 0 ?
                        <>
                            <FileModalFilesWrap>
                                <FileModalFilesTitle>{t('EXCEPTED_ATTACHED_FILES')}</FileModalFilesTitle>
                                <FileModalFilesList>
                                    {
                                        createFilesList(files)
                                    }
                                </FileModalFilesList>
                            </FileModalFilesWrap>
                            <FileModalStandardWrap>
                                <FileModalStandardTitle>
                                    <IconButton width={'24px'} height={'24px'} src={AlertIcon}/>
                                    <div>{t('ATTACH_STANDARD')}</div>
                                </FileModalStandardTitle>
                                <FileModalStandardList>
                                    <FileModalStandardItem>
                                        <div style={{lineHeight : '20px'}}><FileModalDot bg={'#75818D'}/></div>
                                        <div>{t('ATTACH_STANDARD_SIZE',{maxFileSize : env.maxFileSize })}</div>
                                    </FileModalStandardItem>
                                    <FileModalStandardItem>
                                        <div style={{lineHeight : '20px'}}><FileModalDot bg={'#75818D'}/></div>
                                        <div>{getStandardExtStr()}</div>
                                    </FileModalStandardItem>
                                </FileModalStandardList>
                            </FileModalStandardWrap>
                        </> : null
                }
                <FileModalButtons>
                    <Button width={'102px'} onClick={e=>onOkHandler(e)}>{t('CONFIRM')}</Button>
                </FileModalButtons>
            </FileModalWrap>
        </FileModalContainer>
    )
}

FileConfirmModal.defaultProps = {
    isOpen : false,
    onOk : null,
    files : [],
    isFailAll : false // 모두 실패 했는지, 아닌지
}

export default FileConfirmModal;