import {receiveResponse} from "../../../utils/api";
import {useStores} from "../../../stores";
import {useEffect} from "react";
import {observer} from "mobx-react";

const Firebase = (props) => {

    const {store} = useStores();
    const {authStore, userStore} = store;

    useEffect(() => {
        const token = authStore.appToken;
        const userId = userStore.user?.userId;

        // appToken 받을 경우
        if(!token || !userId) return;

        //모바일 토큰 등록
        receiveResponse({token}, `fcm-tokens`, 'post', {'Content-Type': 'multipart/form-data'}) .then(res=>{
            if(res.status===200) {
                authStore.setFmcTokenId(res.data);
                console.log("MOBILE Token 성공");
            }
        }).catch(e=>{
            console.log(e);
        })

    }, [authStore.appToken, userStore.user.userId]);

};

export default observer(Firebase);