import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalScrollBox,
    ModalSubTitle,
    ModalTitle
} from "../global/ModalStyles";
import {Button, Checkbox, DropDown, IconButton, Loading} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import {useStores} from "../../../stores";
import {
    GroupCheckbox,
    GroupCheckboxDesc,
    GroupCheckboxWrap,
    GroupModalBody,
    GroupModalCount,
    GroupModalDetailCountWrap,
    GroupModalDetailInput,
    GroupModalDetailInputWrap,
    GroupModalImgs,
    GroupModalNameCountWrap,
    GroupModalNameInput,
    GroupModalNameInputWrap,
    GroupModalSection,
    GroupModalSectionPadding,
    GroupModalSectionsWrap,
    GroupModalWrap,
    GroupStickerAddImg,
    GroupStickerAddImgWrap,
    GroupStickerCheckIcon,
    GroupStickerImg,
    GroupStickerImgWrap,
    GroupStickerItem,
    GroupStickerSelectedWrap
} from "./CreateGroupModalStyles";
import {ServerConfig} from "../../../commons/config";
import SelectedCircleIcon from "../../../resources/icons/checked_circle.svg";
import UnSecretIcon from "../../../resources/icons/group_icon.svg";
import AddGroupImgIcon from "../../../resources/icons/add_group_img.svg";
import {useCallback, useEffect, useRef, useState} from "react";
import isEmpty from "is-empty";
import {receiveResponse} from "../../../utils/api";
import {useTranslation} from "react-i18next";
import {numberToText, removeBlank} from "../../../utils/textHandler";
import env from "../../../constants/env";
import SecretIcon from "../../../resources/icons/group_icon_check.svg";
import {getYnFromBool} from "../../../utils/boolHandler";
import EmptyIcon from "../../../resources/icons/attach_img_gray.svg";
import {getAvailableFiles} from "../../../utils/fileHandler";
import {observer} from "mobx-react";

const CreateGroupModal = (props) => {

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const onPagePush = props.onPagePush;

    //데이터
    const data = modalPopupStore;

    //다국어 처리에 대한 hook
    const {t} = useTranslation();
    const {store} = useStores();
    const {userStore, toastPopupStore, interestStore, modalToastPopupStore, defaultImageStore} = store;
    const {onClose, history} = props;
    const scrollRef = useRef(null);

    // 드롭다운 아이템
    const [items, setItems] = useState([
        {id : undefined, nameText : t("CREATE_GROUP_CATEGORY"), imgId : null},
        ...interestStore.interests.filter((interest) => interest.interestId !== '0000000000').map(interest=>({
            id:interest.interestId,
            nameText : interest.nameText,
            imgId : interest.imgId
        }))
    ]);

    // 현재 선택된 관심사 (드롭다운)
    const [selectedInterestIndex, setSelectedInterestIndex] = useState(0);
    // 현재 선택된 이미지
    const [selectedImg, setSelectedImg] = useState(null);
    // 그룹 이름
    const [groupName, setGroupName] = useState('');
    // 그룹 설명
    const [groupDetail, setGroupDetail] = useState('');
    // 공개, 비공개 여부
    const [isSecret, setIsSecret] = useState(false);
    // imgs
    const [provideImgs, setProvideImgs] = useState([]);
    // 파일 첨부 ref
    const fileRef = useRef();
    // 파일 state
    const [file, setFile] = useState(null);
    // 파일 url state
    const [fileUrl, setFileUrl] = useState(null);
    // 작성 되었는지 확인
    const [isEditing, setIsEditing] = useState(false);
    // 제목 포커스
    const [isTitleFocus, setIsTitleFocus] = useState(false);
    // 내용 포커스
    const [isContentFocus, setIsContentFocus] = useState(false);
    // 로딩 중 state
    const [isLoading, setIsLoading] = useState(false);

    /******************************************************************************************************************/
    /**
     * 컴포넌트
     * @param e
     */

        // 이미지 생성 컴포넌트
    const createGroupImgs = () => {
        return provideImgs.map(imgId=>{
            return <GroupStickerItem>
                <GroupStickerImgWrap onClick={()=> {
                    setSelectedImg(imgId);
                    if(!isEditing) setIsEditing(true);
                }}>
                    <GroupStickerImg src={`${ServerConfig.default.IMG_URL}${imgId}`}/>
                    {
                        imgId === selectedImg ?
                            <GroupStickerSelectedWrap>
                                <GroupStickerCheckIcon src={SelectedCircleIcon}/>
                            </GroupStickerSelectedWrap> : null
                    }
                </GroupStickerImgWrap>
            </GroupStickerItem>
        })
    }


    /******************************************************************************************************************/
    /**
     * 함수
     * @param e
     */

        // 초기 세팅
    const initdata = () => {
            setSelectedInterestIndex(0);
            setSelectedImg(null);
            setGroupName('');
            setGroupDetail('');
            setIsSecret(false);
            setFile(null);
            if(fileUrl) URL.revokeObjectURL(fileUrl);
            setFileUrl(null);
            setIsEditing(false);
            setIsLoading(false);
        }

    //이미지 함수 API
    const getProvideImgIds = useCallback(() => {
        receiveResponse({useTo: 'I'}, 'imgs', 'get').then(res => {
            if (res.status === 200) {
                setProvideImgs([...res.data]);
            }
        });
    }, []);


    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

        // 모달 드롭다운 선택
    const onSelect = (index) => {
        setSelectedInterestIndex(index);
        if(!isEditing) setIsEditing(true);
    }

    // 그룹 네임 변경 이벤트
    const onChangeGroupName = e => {
        setGroupName(e.target.value);
        if(!isEditing) setIsEditing(true);
    }

    // 그룹 상세 변경 이벤트
    const onChangeGroupDetail = e => {
        setGroupDetail(e.target.value.replaceAll('\n', ''));
        if(!isEditing) setIsEditing(true);
    }

    // 파일 변경 이벤트
    const onChangeFile = (e) => {
        if(e.target.files.length===0) return;
        setIsLoading(true);
        getAvailableFiles(e.target.files).then(res=>{
            let _file = res;
            if(_file.others?.length > 0 || _file.fails?.length > 0) {
                openFileAlert();
            }else{
                setSelectedImg(null);
                setFile(e.target.files[0]);
                if(fileUrl) URL.revokeObjectURL(fileUrl);
                setFileUrl(URL.createObjectURL(e.target.files[0]));
                if(!isEditing) setIsEditing(true);
            }
            fileRef.current.value = '';
        }).finally(()=>{setIsLoading(false)});
    };

    // 파일 컨펌 알럿 띄우기
    const openFileAlert = () => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            t('FILE_CONFIRM_POPUP_FAIL_ONLY_ONE'),
            t('ATTACH_STANDARD'),
            [t('ATTACH_STANDARD_SIZE',{maxFileSize : env.maxFileSize}), `${[...env.imgFileForms].join(', ').toUpperCase()}`],
            t('CONFIRM'),
            t('NO'),
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 저장 이벤트
    const onSubmitHandler = () => {

        setIsLoading(true);

        const formData = new FormData();

        if (selectedImg) {
            formData.append('imgId', selectedImg);
        } else {
            formData.append('img', file);
        }

        formData.append('groupName', groupName);
        formData.append('groupDetail', groupDetail);
        formData.append('groupType', 'M');
        formData.append('interestId', items[selectedInterestIndex].id);
        formData.append('privateYn', getYnFromBool(isSecret));

        receiveResponse(formData, 'groups', 'post', {"Content-Type": "multipart/form-data"}).then(res => {
            if (res.status === 200) {
                userStore.updateJoinGroups();
                onPagePush(res.data);
                toastPopupStore.openToastPopup(
                    t('COMPLETE_CREATE_GROUP',
                        { groupName : groupName.length > 8 ? `${groupName.substr(0,8)}...` : groupName}));
            } else {
                toastPopupStore.openToastPopup(t('FAIL_CREATE_GROUP', { groupName : groupName.length > 8 ? `${groupName.substr(0,8)}...` : groupName}));
            }
        }).finally(()=>{setIsLoading(false)});
    };

    const onCloseModalToastHandler = () => {
        /**
         * OK(true)      ->   닫기
         * 아니오(false)  ->   팝업창 유지
         */
        if(modalToastPopupStore.isCancel){

            //window.History.Back 뒤로가기 (모달 window)
            goHistoryBack();
            // toastPopupStore.openToastPopup(t('CANCEL_CREATE_GROUP'));
            return;
        }
    }

    const onCloseHandler = () => {
        /**
         * editing이면
         * 모달 팝업창 띄우기
         */
        if(isEditing){
            modalToastPopupStore.initDefault(
                'modalAlert',
                t('CANCEL_CREATE_GROUP_POPUP'),
                t('YES'),
                t('NO'),
                onCloseModalToastHandler
            );
            modalToastPopupStore.openModalPopup();
            return;
        }

        //window.History.Back 뒤로가기 (모달 window)
        // toastPopupStore.openToastPopup(t('CANCEL_CREATE_GROUP'));
        goHistoryBack();
    }


    /**
     * useEffrct
     */
    /******************************************************************************************************************/

    useEffect(() => {
        // 기본 제공 이미지 조회
        if(defaultImageStore.defaultGroupImgs!==null) setProvideImgs([...defaultImageStore.defaultGroupImgs])
    }, [defaultImageStore.defaultGroupImgs]);

    useEffect(()=>{
        if (data.isOpen) {
            if(scrollRef && scrollRef.current) scrollRef.current.scrollTop = 0;
        }else {
            // 닫을 때 초기 데이터 (초기화시키기)
            initdata();
        }
    },[data.isOpen]);

    useEffect(()=>{
        if(isEditing) data.setOpenYnPopup(false, onCloseHandler);
    },[isEditing])

    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>
            <Loading isLoading={isLoading}>
                <GroupModalWrap>
                    <ModalCloseWrap>
                        <IconButton width={'32px'}
                                    height={'32px'}
                                    onClick={onCloseHandler}
                                    src={CancelIcon}/>
                    </ModalCloseWrap>
                    <ModalTitle>{t('CREATE_GROUP')}</ModalTitle>
                    <ModalScrollBox ref={scrollRef}>
                        <GroupModalBody>
                            {/*<ScrollBox>*/}
                            <GroupModalSectionsWrap>
                                <GroupModalSection>
                                    <DropDown
                                        items={items}
                                        selectedIndex={selectedInterestIndex}
                                        onSelect={onSelect}
                                    />
                                    <GroupModalNameInputWrap className={isTitleFocus ? 'focus' : ''} disabled={groupName?.length > env.maxGroupNameLen}>
                                        <GroupModalNameInput
                                            placeholder={t('CREATE_GROUP_NAME_PLACEHOLDER')}
                                            value={groupName}
                                            onChange={onChangeGroupName}
                                            onFocus={e=>setIsTitleFocus(true)}
                                            onBlur={e=>setIsTitleFocus(false)}
                                        />
                                        <GroupModalNameCountWrap>
                                            <GroupModalCount entered={groupName?.length || 0 !== 0}
                                                             disabled={
                                                                 groupName?.length > env.maxGroupNameLen}>{groupName.length}</GroupModalCount>
                                            {`/${env.maxGroupNameLen}`}
                                        </GroupModalNameCountWrap>
                                    </GroupModalNameInputWrap>
                                    <GroupModalDetailInputWrap className={isContentFocus ? 'focus' : ''} disabled={groupDetail?.length > env.maxGroupDetailLen}>
                                        <GroupModalDetailInput
                                            placeholder={t('CREATE_GROUP_DETAIL_PLACEHOLDER')}
                                            value={groupDetail}
                                            onChange={onChangeGroupDetail}
                                            onFocus={e=>setIsContentFocus(true)}
                                            onBlur={e=>setIsContentFocus(false)}
                                        />
                                        <GroupModalDetailCountWrap>
                                            <GroupModalCount entered={groupDetail?.length || 0 !== 0}
                                                             disabled={
                                                                 groupDetail?.length > env.maxGroupDetailLen}>{numberToText(groupDetail?.length || 0)}</GroupModalCount>
                                            {`/${numberToText(env.maxGroupDetailLen)}`}
                                        </GroupModalDetailCountWrap>
                                    </GroupModalDetailInputWrap>
                                    <GroupCheckboxWrap>
                                        <GroupCheckbox>
                                            <Checkbox
                                                checked={!isSecret}
                                                label={t('UN_PRIVATE_GROUP')}
                                                icon={UnSecretIcon}
                                                checkedIcon={SecretIcon}
                                                onCheck={e=> {
                                                    setIsSecret(false);
                                                    if(!isEditing) setIsEditing(true);
                                                }}
                                            />
                                            <GroupCheckboxDesc>{`(${t('JOIN_IMMEDIATELY')})`}</GroupCheckboxDesc>
                                        </GroupCheckbox>
                                        <GroupCheckbox>
                                            <Checkbox
                                                checked={isSecret}
                                                label={t('PRIVATE_GROUP')}
                                                icon={UnSecretIcon}
                                                checkedIcon={SecretIcon}
                                                onCheck={e=> {
                                                    setIsSecret(true);
                                                    if(!isEditing) setIsEditing(true);
                                                }}
                                            />
                                            <GroupCheckboxDesc>{`(${t('REQUIRE_MANAGER_CONFIRM')})`}</GroupCheckboxDesc>
                                        </GroupCheckbox>
                                    </GroupCheckboxWrap>
                                </GroupModalSection>

                                <GroupModalSection>
                                    <GroupModalSectionPadding/>
                                    <ModalSubTitle>{t('REP_IMAGE_TITLE')}</ModalSubTitle>
                                    <GroupModalImgs>
                                        <GroupStickerItem>
                                            <GroupStickerImgWrap
                                                border={true}
                                                onClick={(e)=>fileRef.current.click()}
                                            >
                                                <GroupStickerAddImgWrap>
                                                    <GroupStickerAddImg src={AddGroupImgIcon} />
                                                </GroupStickerAddImgWrap>
                                            </GroupStickerImgWrap>
                                        </GroupStickerItem>
                                        {/* 파일 선택 시 나타남 */}
                                        {
                                            file ?
                                                <GroupStickerItem>
                                                    <GroupStickerImgWrap
                                                        border={true}
                                                        onClick={(e)=>setSelectedImg('')}
                                                    >
                                                        {
                                                            file ? <GroupStickerImg
                                                                src={fileUrl}
                                                                onError={({currentTarget}) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = EmptyIcon;
                                                                }}
                                                            /> : null
                                                        }
                                                        {
                                                            file && !selectedImg ?
                                                                <GroupStickerSelectedWrap>
                                                                    <GroupStickerCheckIcon src={SelectedCircleIcon}/>
                                                                </GroupStickerSelectedWrap> : null
                                                        }
                                                    </GroupStickerImgWrap>
                                                </GroupStickerItem> : null
                                        }
                                        {createGroupImgs()}
                                    </GroupModalImgs>
                                </GroupModalSection>
                            </GroupModalSectionsWrap>
                            {/*</ScrollBox>*/}
                        </GroupModalBody>
                    </ModalScrollBox>
                    <ModalBottomWrap>
                        <Button
                            textStyle={{fontSize : '16px', lineHeight: "23px"}}
                            shape={'circle'}
                            width={'100%'}
                            disabled={
                                selectedInterestIndex === 0 ||
                                isEmpty(removeBlank(groupName)) ||
                                isEmpty(removeBlank(groupDetail)) ||
                                (isEmpty(selectedImg) && isEmpty(file)) ||
                                groupName?.length > env.maxGroupNameLen ||
                                groupDetail?.length > env.maxGroupDetailLen
                            }
                            onClick={onSubmitHandler}
                        >{t('CREATE_GROUP_BUTTON')}</Button>
                    </ModalBottomWrap>
                </GroupModalWrap>
            </Loading>
            <input type={'file'} ref={fileRef} style={{display: 'none'}} accept={'image/*'} onChange={onChangeFile}/>
        </ModalContainer>
    )
}

CreateGroupModal.defaultProps = {
    onClose : null
}
export default observer(CreateGroupModal);