import {
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollNoBottomBox,
    ModalSubTitle,
    ModalTitle
} from "../global/ModalStyles";
import {
    IconButton,
    TextField
} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import {
    InviteModalBody,
    InviteModalInputListWrap,
    InviteModalInputWrap,
    InviteModalText,
    InviteModalUsersList,
    InviteModalUsersTitle,
    InviteModalUsersWrap
} from "./InviteModalStyles";

import {useEffect, useRef, useState} from "react";
import {receiveResponse} from "../../../utils/api";
import List from "../global/List";
import {observer} from "mobx-react";
import InvitingUser from "../global/InvitingUser";
import {useStores} from "../../../stores";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import env from "../../../constants/env";

const InviteModal = (props) => {

    const {t} = useTranslation();

    const timeRef = useRef(null);
    const history = useHistory();

    const {store} = useStores();
    const {toastPopupStore, groupDetailStore, eventStore, modalToastPopupStore} = store;

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;

    //데이터
    const data = modalPopupStore;

    // 데이터 아이템
    const detailItem = data.detailItem;

    // 그룹아이디
    const groupId = detailItem.groupId;

    // 수락 대기중인 사용자들
    const [invitingUsers, setInvitingUsers] = useState(null);
    // 사용자 검색
    const [users, setUsers] = useState([]);
    // 사용자 리스트 오픈
    const [openUserList, setOpenUserList] = useState(false);
    // 선택된 사용자
    const [selectedUser, setSelectedUser] = useState(null);

    // 입력되는 input
    const [value, setValue] = useState('');

    const invitingUsersRef = useRef(null);

    // 스크롤 방지를 위한
    const e = eventStore.e;

    /******************************************************************************************************************/

    /**
     * 이벤트
     * @param e
     */


    /**
     * 닫기이벤트
     * @param e
     */
    const onCloseHandler = (e) => {
        //닫기 버튼
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    /**
     * 사용자 선택 이벤트
     * @param index
     */
    const onSelectUser = (index) => {
        let user = users[index];
        setSelectedUser({
            userId: user.userId ?? user.account,
            name : user.name,
            email : user.mail
        })
        setUsers([]);
        setOpenUserList(false);
    }

    /******************************************************************************************************************/


    /**
     * 함수
     */

    /**
     * 수락 대기 사용자 API
     */
    const getInvitingUsers = () => {
        receiveResponse({}, `groups/${groupId}/invite`,'get')
            .then(res=>{
                if(res.status===200){
                    setInvitingUsers(res.data);
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    /**
     * 사용자 조회 API
     * @param value
     */
    const getUsers = async (value) => {

        clearTimeout(timeRef.current);

        setValue(value);
        if(value.length < 2){
            setUsers([]);
            return;
        }

        timeRef.current = setTimeout(
            () => {
                receiveResponse({}, `user/search?target=if&searchWord=${value}`).then(res=>{
                    if(res.status===200){
                        console.log(res.data);
                        setUsers(res.data);
                    }else{
                        setUsers([]);
                    }
                }).catch(e=>{
                    console.log(e);
                });
            },1000)
    }

    // 이미 그룹에 가입한 멤버일 경우
    const openAlertPopup = () => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            t('ALREADY_JOINED_USER'),
            '',
            null,
            env.modalConfirmButtonText,
            env.modalCanclButtonText,
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 강퇴당한 사용자를 초대한 경우
    const openKickAlertPopup = () => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            t('KICK_USER_REQUEST_INVITE'),
            '',
            null,
            env.modalConfirmButtonText,
            env.modalCanclButtonText,
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    /**
     * 사용자 초대 API
     * @param userId
     */
    const onInvite = (selectedUser) => {

        // @todo 강퇴된 사용자인지, 체크하는 로직 추가

        if(groupDetailStore.userList.filter(user=>user.userId===selectedUser.userId).length>0) {
            openAlertPopup();
            return;
        }

        if(invitingUsers.filter(user=>user.userId ?? user?.account === selectedUser.userId).length>0){
            openReInvitePopup(selectedUser);
            return;
        }

        receiveResponse({userId : selectedUser.userId}, `groups/${groupId}/invite`,'patch',{'Content-Type': 'multipart/form-data'})
            .then(res=>{
                if(res.status === 200) {
                    setSelectedUser(null);
                    setValue('');
                    toastPopupStore.openToastPopup(t('COMPLETE_REQUEST_INVITE'));
                    getInvitingUsers();
                }else{
                    console.log(res);
                }
            })
            .catch(e=>{
                const {response} = e;
                const {data} = response;
                if(data){
                    const {code} = data;
                    if(code && code==='group.kick-user') openKickAlertPopup();
                }
            })
    }

    // 초대 취소
    const onCancel = async (selectedUser) => {
        // @todo 초대 취소 callback
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse({userId : selectedUser?.userId ?? selectedUser?.account},`groups/${groupId}/cancel-invite`,'patch',{'Content-Type': 'multipart/form-data'})
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        getInvitingUsers();
                        toastPopupStore.openToastPopup(t('CANCEL_REQUEST_INVITE', {name : selectedUser?.name}));
                    }
                }
            })
    }
    //

    // // 초대장 재전송 핸들러
    const onReInvite = async (user) => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse({userId : user?.userId ?? user?.account},`groups/${groupId}/invite`,'patch',{'Content-Type': 'multipart/form-data'})
            .then(res=>{
                if(res.status===200){
                    // selectedUser 존재, 그 사람이 수락 대기중에 있는 경우 (재전송)
                    if(selectedUser && user?.account === selectedUser?.account){
                        setSelectedUser(null);
                        setValue('');
                    }
                    toastPopupStore.openToastPopup(t('COMPLETE_REQUEST_RE_INVITE' , {name : user?.name}));
                }
            })
    }
    //
    // 초대 취소 팝업 오픈
    const openCancelInvitePopup = (selectedUser) => {
        modalToastPopupStore.initDefault(
            'alert',
            `${selectedUser.name}님에게 보낸 초대를 취소하시겠습니까?`,
            '네',
            '아니요',
            e=>onCancel(selectedUser)
        );
        modalToastPopupStore.openModalPopup();
    }

    // // 재초대 팝업 오픈
    const openReInvitePopup = (selectedUser) => {
        modalToastPopupStore.initDefault(
            'alert',
            `${selectedUser.name}님에게 초대장을 재전송하시겠습니까?`,
            '네',
            '아니요',
            e=>onReInvite(selectedUser)
        );
        modalToastPopupStore.openModalPopup();
    }

    // 스크롤 막기
    // invitingUsers 스크롤 막기
    const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    const createEventListener = () => {
        e.removeAllListeners('invitingUsers-scroll',()=>{});
        e.on('invitingUsers-scroll', (args)=>{
            if(invitingUsersRef && invitingUsersRef.current){
                if(args==='prevent'){
                    invitingUsersRef.current.addEventListener('wheel',preventScroll);
                }else{
                    invitingUsersRef.current.removeEventListener('wheel',preventScroll);
                }
            }
        });
    }

    const initData = () => {
        setInvitingUsers(null);
        setUsers([]);
        setSelectedUser(null);
        setValue('');
    }

    /******************************************************************************************************************/


    /**
     * 컴포넌트
     */

    /**
     * 프로필 컴포넌트
     * @returns {*}
     */
    const createInvitingUsers = () => {
        return invitingUsers.map((user,index)=>{
            return (
                <InvitingUser
                    key={`inviting-user-${index}`}
                    user={user}
                    groupId={groupId}
                    onCancel={e=>openCancelInvitePopup(user)}
                    onReInvite={e=>openReInvitePopup(user)}/>
            )
        })
    }


    /**
     * 수락 대기중 사용자 컴포넌트
     *
     */
    /**********************************************************************************************/

    useEffect(()=>{

        if (data.isOpen) {
            getInvitingUsers();
            createEventListener();
        }else {
            // 닫을 때 초기 데이터 (초기화시키기)
            clearTimeout(timeRef.current);
            initData();
            e.removeAllListeners(`invitingUsers-scroll`,()=>{});
        }
    },[data.isOpen]);



    /**********************************************************************************************/


    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>
            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalCloseWrap>
                    <IconButton
                        width={'32px'}
                        height={'32px'}
                        src={CancelIcon}
                        onClick={onCloseHandler}
                    />
                </ModalCloseWrap>
                <ModalTitle>{t("GROUP_INVITE")}</ModalTitle>
            </ModalHeaderWrap>

            <ModalScrollNoBottomBox>
                <InviteModalBody>
                    <ModalSubTitle>{t('INVITE_MODAL_SUBTITLE')}</ModalSubTitle>
                    <InviteModalText>{t('INVITE_MODAL_GUIDE')}</InviteModalText>
                </InviteModalBody>

                <InviteModalInputWrap>
                    <TextField
                        size={'s'}
                        type={'invite'}
                        placeholder={t('INVITE_MODAL_PLACEHOLDER')}
                        buttonText={t('INVITE')}
                        onChange={getUsers}
                        onFocus={e=>setOpenUserList(true)}
                        onBlur={e=>setOpenUserList(false)}
                        onClickDelete={e=> {
                            setSelectedUser(null)
                            setValue('');
                            setUsers([]);
                        }}
                        onClick={e=>onInvite(selectedUser)}
                        disabled={selectedUser ? true : false}
                        buttonDisabled={selectedUser ? false : true}
                        wrapperStyle={{width: '95px'}}
                        value={
                            selectedUser
                                ? `${selectedUser?.name} (${selectedUser?.userId}@kolon.com)` : value}
                    />
                    {
                        openUserList && users.length !==0 ?
                            <InviteModalInputListWrap>
                                <List
                                    type={'users'}
                                    width={'100%'}
                                    items={users.map((user)=>({
                                        email : user.mail,
                                        name : `${user.name} ${user.titleName}`,
                                        company : user.companyName
                                    }))}
                                    onSelect={onSelectUser}
                                />
                            </InviteModalInputListWrap> : null
                    }
                </InviteModalInputWrap>

                {
                    invitingUsers === null ? null :
                        (
                            invitingUsers.length!==0 ?
                                <InviteModalUsersWrap>
                                    <InviteModalUsersTitle>{t('WAITING_ACCEPT')}</InviteModalUsersTitle>
                                    <InviteModalUsersList id={'invitingUsers'} ref={invitingUsersRef}>
                                        {createInvitingUsers()}
                                    </InviteModalUsersList>
                                </InviteModalUsersWrap> : null
                        )
                }
            </ModalScrollNoBottomBox>

        </ModalContainer>
    )
}

InviteModal.defaultProps = {
    onClose : null,
    groupId : null
}

export default observer(InviteModal);