import Obituary from '../resources/icons/obituary.svg';
import Marriage from '../resources/icons/marriage.svg';
import ChildBirth from '../resources/icons/childbirth.svg';
import Img from "../components/presentationals/global/Img";
import env from "../constants/env";
import {SellContentName, SellContentRow, SellContentValue, SellContentWrap} from "../pages/item/ItemStyles";
import {getBoolFromYn} from "./boolHandler";
import {stringToContact, stringToCost} from "./textHandler";
import {t} from 'i18next';

export const createTopicImg = (topicName) => {

    let src;

    if(topicName.includes('obituary')){
        src = Obituary;
    }else if(topicName.includes('marriage')){
        src = Marriage;
    }else if(topicName.includes('childbirth')){
        src = ChildBirth
    }else{
        return;
    }

    return <Img
        src={src}
        imgStyle={{width : '207px', height : '160px'}}
        wrapStyle={{width : '100%', height : '165px', flex : 'none', paddingBottom: '5px'}}
    />
}

export const createPropsContent = (topicName, props, freecycleYn, multipleYn) => {

    if(!props) return;

    try {
        const form = getModalForm(topicName);
        const _props = JSON.parse(props);
        if(!_props) return null;
        if (form === 'Sell' | form === 'Buy') {
            return (
                <SellContentWrap>
                    <SellContentRow>
                        <SellContentName>{form === 'Sell' ? t('SELL_PRICE') : t('BUY_PRICE')}</SellContentName>
                        <SellContentValue>{getBoolFromYn(freecycleYn) ? t('FREE_PRICE') : `￦ ${stringToCost(_props.cost)}`}</SellContentValue>
                    </SellContentRow>
                    <SellContentRow>
                        <SellContentName>{form === 'Sell' ? t('SELL_CONTACT') : t('BUY_CONTACT')}</SellContentName>
                        <SellContentValue>{stringToContact(_props.contact)}</SellContentValue>
                    </SellContentRow>
                </SellContentWrap>
            )
        }
    }catch (e) {
        return null;
    }
}

export const getModalForm = (topicName) => {

    switch (topicName){
        case "topic.free-talk" :
            return "Free";
        case "topic.obituary" :
            return "Obituary"
        case "topic.marriage" :
            return "Marriage"
        case "topic.childbirth" :
            return "ChildBirth"
        case "topic.discount" :
            return "Discount"
        case "topic.sell" :
            return "Sell"
        case "topic.buy" :
            return "Buy"
        case "topic.lost-item" :
            return "LostItem"
        case "topic.lost-item-owner" :
            return "LostItemOwner"
        case "topic.vote" :
            return "Vote"
        case "topic.question" :
            return "Question"
        case "topic.work" :
            return "Work"
        default :
            return "Default"
    }

}

/**
 * @description 게시글 props로 부터 key인 value를 return
 * @param props
 * @param key
 * @return {*}
 */
export const getValueFromProps = (props, key) => {

    // props가 없으면 null
    if(!props) return;

    // key가 없으면 return
    if(!props[key]) return;

    return props[key];
}

/**
 * @description 게시글 props로 부터 가격 추출 (env.costKey 설정)
 * @param props
 * @return {*}
 */
export const getCostFromProps = (props) => {
    return getValueFromProps(props, env.costKey);
}

/**
 * @description 게시글 props로 부터 연락처 추출 (env.contact 설정)
 * @param props
 * @return {*}
 */
export const getContactFromProps = (props) => {
    return getValueFromProps(props, env.contactKey);
}

export const createSellProps = (cost, contact) => {
    return {cost, contact};
}

/**
 * @description string array로 부터 전송을 위한 object 추출 (vote를 생성할 때)
 * @param arr (array)
 */
export const getVoteObjectFromArray = (arr=[]) => {
    let items = {};
    arr.forEach((item,index) => {
        items[`item${index+1}`] = item;
    });
    return items;
}

/**
 * props로 부터 item list (선택지) 배열로 반환
 * @param props
 * @return {*[]}
 */
export const getArrayFromVoteObject = (props) => {
    let _props = getObjectFromString(props);
    if(!_props) return null;
    return Object.keys(_props).map(key=>_props[key]);
}


/**
 * @description string array ['item1', 'item2'...] 에서 {item1 : true, item2 : false...} 로 반환
 * @param arr
 * @return {{}}
 */
export const getVoteReqParamFromArray = (arr = []) => {
    let params = {};
    arr.forEach((item,index) => {
        params[item] = true;
    });
    return params;
}

/**
 * voteLog로 부터 선택한 아이템 리스트 (string) 추출
 * @param voteLog
 * @return {*[]}
 */
export const getArrayFromVoteLog = (voteLog) => {
    try {
        let arr = [];
        Object.keys(voteLog).forEach((item)=>{
            if(typeof voteLog[item] === "boolean" && voteLog[item]){
                arr.push(item);
            }
        })
        return arr;
    }catch (e){
        return [];
    }
}

/**
 * @description string에서 json 객체 파싱
 * @param string
 * @return {any}
 */
export const getObjectFromString = (string) => {
    try {
        return JSON.parse(string);
    }catch (e){
        return null;
    }
}

/**
 * props로 부터 item list (선택지) 조건 배열만
 * @param props
 * @return {*[]}
 */
export const getArrayFromSelectVoteObject = (props, propsKey) => {
    let _props = getObjectFromString(props);
    if(!_props) return null;

    return Object.keys(_props).filter((key)=>{
        if (propsKey === key) return _props[key]
    }).map(key=>_props[key]);

}

/**
 * userId Time Hash 변환
 * @param props
 * @return {*[]}
 */
export const getHashUserId= (userId) => {
    if (!userId) return '';
    return btoa(userId);
}


