import {useTranslation} from "react-i18next";
import {useStores} from "../../../stores";
import {
    GroupModalImgs,
    GroupStickerCheckIcon,
    GroupStickerImg,
    GroupStickerImgWrap,
    GroupStickerItem,
    GroupStickerSelectedWrap
} from "./CreateGroupModalStyles";
import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle,
} from "../global/ModalStyles";
import {Button, IconButton} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import isEmpty from "is-empty";
import CheckedCircleIcon from "../../../resources/icons/checked_circle.svg";
import {useCallback, useLayoutEffect, useRef, useState} from "react";
import {ServerConfig} from "../../../commons/config";
import {receiveResponse} from "../../../utils/api";

const EditGroupCoverImageModal = (props) => {

    /**
     * modalPopupStore.detailItem에 group 정보가 들어있음
     */

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const data = modalPopupStore;
    const group = data.detailItem;

    //다국어 처리에 대한 hook
    const {t} = useTranslation();
    const {store} = useStores();
    const {userStore, toastPopupStore, interestStore} = store;
    const scrollRef = useRef(null);

    // 현재 선택된 이미지
    const [selectedCoverImg, setSelectedCoverImg] = useState(null);
    // imgs
    const [provideImgs, setProvideImgs] = useState([]);

    // 닫기 이벤트
    const onCloseHandler = (e) => {
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    // 이미지 저장
    const onSubmitHandler = (e) => {
        data.submitCallBack(selectedCoverImg);
        modalPopupStore.closeModalPopup();
    }

    /**
     * 컴포넌트
     * @param e
     */

        // 이미지 생성 컴포넌트
    const createGroupImgs = () => {
            return provideImgs.map(imgId=>{
                return <GroupStickerItem className={'coverImg-setting'}>
                    <GroupStickerImgWrap
                        className={'coverImg-setting'}
                        onClick={()=> {
                            setSelectedCoverImg(imgId);
                        }}
                    >
                        <GroupStickerImg src={`${ServerConfig.default.IMG_URL}${imgId}`}/>
                        {
                            imgId === selectedCoverImg ?
                                <GroupStickerSelectedWrap>
                                    <GroupStickerCheckIcon src={CheckedCircleIcon}/>
                                </GroupStickerSelectedWrap> : null
                        }
                    </GroupStickerImgWrap>
                </GroupStickerItem>
            })
        }

    // button disabled
    const isDisabled = () => {
        if(!selectedCoverImg || !group?.selectedCoverImgId) return true;
        return selectedCoverImg === group.selectedCoverImgId;
    };

    // 이미지 선택 현황을 초기화 함
    const initSelected = () => {
        setSelectedCoverImg(group.selectedCoverImgId);
    }

    /**
     * ===============================================================================
     * API
     */

        // 기본 제공되는 관심사 이미지 가져오기
    const getProvideImages = useCallback(async () => {
            await receiveResponse({useTo : 'C'},'imgs','get')
                .then(res=>{
                    if(res.status===200){
                        setProvideImgs([...res.data]);
                    }
                })
                .catch(e=>{
                    console.log(e);
                })
        },[])

    /**
     * API
     * ===============================================================================
     *
     */

    useLayoutEffect(()=>{
        if (data.isOpen) {
            // 기본 제공 이미지를 조회
            if(!isEmpty(provideImgs)) initSelected();
            else getProvideImages().then(()=>{initSelected()})

        }

    },[data.isOpen]);

    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                {/* 전체 타이틀 컴포넌트*/}
                <ModalTitle>{'커버 색상 변경'}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'}
                                height={'32px'}
                                onClick={onCloseHandler}
                                src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollBox ref={scrollRef}>
                <GroupModalImgs className={'coverImg-setting'}>
                    {createGroupImgs()}
                </GroupModalImgs>
            </ModalScrollBox>


            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button
                    textStyle={{fontSize : '16px', lineHeight: "23px"}}
                    shape={'circle'}
                    width={'100%'}
                    disabled={isDisabled()}
                    onClick={onSubmitHandler}
                >저장하기</Button>
            </ModalBottomWrap>

        </ModalContainer>
    )
}

EditGroupCoverImageModal.defaultProps = {
}
export default EditGroupCoverImageModal;