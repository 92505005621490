import styled from "styled-components";

export const TextFieldWrap = styled.div`

    box-sizing : border-box;
    border-radius : 12px;
    width : 100%;
    position : relative;
    display : flex;
    align-items : center;
    gap : 10px;
    padding : 6px 12px 6px 20px;
    
    border : ${({theme, isFocus}) => {
        if(isFocus) return `1px solid #3A6AF6`
        return `1px solid ${theme.colors.gray_stroke_100}`
    }};
    
    // &:hover{
    //     border : {({theme}) => 1px solid #3A6AF6};
    // }
    
`

export const Input = styled.input`

    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    display : block;
    outline : none;
    border : none;
    flex-grow : 1;
    
    width : ${({type}) => { if (type === 'invite') return '100%';}};
    
    &:disabled {
        background : ${({theme}) => theme.colors.white};
        color: ${({theme}) => theme.colors.black};
    }
    
    ::placeholder {
        color: #AEB7C0;
    }
  
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #AEB7C0;
    }
  
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #AEB7C0;
    }
`