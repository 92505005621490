import styled, {keyframes, css} from "styled-components";

export const ProfileWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title title"
    "profile interest"
    "sticker sticker"
    "splitter splitter"
    "state state";
`

export const ProfileContainer = styled.div`
    width : 100%;
    background : transparent;
    height : calc(100vh - 87px);
    padding-top : 62px;
    display : flex;
    gap : 80px;
`


/**
 * ===========================================================================
 * Profile Menus (왼쪽 내 활동)
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */

export const ProfileMenusWrap = styled.div`
    width : 177px;
    flex : none;
`

export const ProfileMenusTitle = styled.div`
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    color: #191E29;
    margin-bottom : 36px;
    padding-left : 20px;
`

export const ProfileMenus = styled.div`
    display : flex;
    gap : 10px;
    flex-direction : column;
`

export const ProfileMenu = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #353C49;
 
    background: #F7F7F7;
    border-radius: 10px;
    padding: 12px 16px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    
    // &:hover{
    //     background: #DEE4EA;
    //     border-radius: 10px;
    //     font-weight: 700;
    //     color: #7C9EFF;
    // }
`

export const ProfileMenuImg = styled.img`

`

/**
 * Profile Menus End
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 * ===========================================================================
 */

/**
 * ===========================================================================
 * Profile Contents (오른쪽 설정)
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const ProfileContentsWrap = styled.div`
    flex-grow : 1;
    padding-right : 16px;
    overflow : overlay;
    
    &::-webkit-scrollbar {
        // width: 6px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`

export const ProfileContentsTitle = styled.div`
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    color: #191E29;
`

export const ProfileSettingTitle = styled.div`
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    color: #191E29;
`

export const ContainerTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;

  color: #191E29;

  grid-area: title;
`

export const WidgetTitleWrap = styled.div`
  overflow: auto;

  > :first-child {
    float: left;
  }

  > :last-child {
    float: right;
  }

  margin-bottom: 28px;
`
export const WidgetTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;

  color: #191E29;
`
export const WidgetMenus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  color: #AEB7C0;
`
export const WidgetMenu = styled.div`

`

export const Splitter = styled.hr`
  border: 1px solid #E5EBF0;
  margin: 44px 0 !important;
  grid-area: splitter;
`

// ProfileEditor
export const ProfileEditorWrap = styled.div`
  width: 471px;
  height: 104px;
  background: #F1F5F9;
  border-radius: 20px;

  margin-top: 15px;
  margin-bottom: 15px;
  padding: 24px;

  grid-area: profile;
`

// Interest
export const InterestWrap = styled.div`
  grid-area: interest;
`
export const InterestList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

// Sticker
export const StickerWrap = styled.div`
  grid-area: sticker;
`
export const StickerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`
export const StickerItem = styled.div`
  width: 104px;
  text-align: center;
`
export const StickerImgWrap = styled.div`
  position: relative;
  text-align: center;
  width: 104px;
  height: 104px;
  border-radius: 10px;
  border: 1px solid #E5EBF0;
  margin-bottom: 8px;
`
export const StickerRepWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  border: 2px solid #353C49;
  overflow: hidden;
`
export const StickerRepTxt = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  padding: 1px 4px 0 5px;

  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  color: #FFFFFF;

  background: #353C49;
  border-radius: 0 0 0 10px;
`
export const StickerLockWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  border-radius: 10px;
  background-color: #F7F9FB;
`
export const StickerLockImg = styled.img.attrs(props => ({
    src: props.src
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`
export const StickerImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;

  &.blur {
    filter: blur(7.22222px) grayscale(1);
  }
`
export const StickerTxt = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  &.lock {
    color: #AEB7C0;
  }
`

// State
export const UserStateList = styled.div`
  > *:not(:last-child) {
    margin-bottom: 28px;
  }

  grid-area: state;
`
export const UserStateItem = styled.div`
  clear: both;
  height: 23px;
  line-height: 23px;
  vertical-align: middle;
 
`
export const UserStateTitle = styled.div`
  float: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  
  >strong.count{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #7C9EFF;
    margin-left: 12px;
  }
`
export const UserStateIcon = styled.div`
  float: right;
  width: 6.59px;
  height: 6.59px;
  border: 1.5px solid #191E29;
  border-left: none;
  border-bottom: none;
  margin-top: 8px;
  transform: rotate(45deg);
`