import {
    ListItem,
    ListItemCompany,
    ListItemEmail,
    ListItemName,
    ListItemNameText,
    ListItemsWrap,
    ListWrap
} from "./ListStyles";
import {useEffect, useRef, useState, createRef} from "react";

const List = (props) => {

    const {type,onSelect,width,onBlur,initSelected, isUserList} = props;
    const [items, setItems] = useState(props.items);
    const [selected, setSelected] = useState(initSelected);
    const ref = useRef(null);
    const scrollRef = useRef(null);
    const inputRefs = useRef(props.items.map(()=>createRef()));

    const handleKeyDown = (e) => {
        let to = selected
        if(e.key === 'ArrowUp' || e.key === 'ArrowDown'){
            e.preventDefault();
            if(e.key==='ArrowUp'){
                if(selected===0) return;
                to = selected-1;
            }else if(e.key==='ArrowDown'){
                if(selected===items.length-1) return;
                to = selected+1;
            }
            setSelected(to);
            scrollToItem(to);
        }else if(e.key==='Enter'){
            setSelected(to);
            onSelect(to);
        }
        return;
    };

    const scrollToItem = (index) => {
        inputRefs.current[index].scrollIntoView({block: 'nearest'});
    }

    // click했을 때 선택
    const onSelectItem = (e,index,onClick) => {
        setSelected(index);
        // props로 받은 onSelect가 있으면 동작
        if(onSelect){
            onSelect(index);
        }

        // 각 list마다 동작해야 하는 함수 있으면 실행
        if(onClick){
            onClick(e);
        }
    }

    const createListItems = (items) => {

        return items.map((item,index)=>{
            return <ListItem
                key={item.id}
                id={item.id}
                type={type}
                isSelected={index===selected}
                ref={(elem) => (inputRefs.current[index] = elem)}
                onClick={(e)=>onSelectItem(e,index,item.onClick)}
            >{
                type === 'users' ?
                    <>
                        <ListItemEmail>{item.email}</ListItemEmail>
                        <ListItemName>{item.name}</ListItemName>
                        <ListItemCompany>{item.company}</ListItemCompany>
                    </> : <ListItemNameText>{item.nameText}</ListItemNameText>

            }</ListItem>
        })
    }

    useEffect(() => {
        if(!(type==='keyword' || type==='users')){
            ref.current.focus();
        }
        inputRefs.current[selected]?.scrollIntoView({block: 'nearest'});

    }, []);

    useEffect(()=>{
        setItems(props.items);
    },[props.items])

    useEffect(()=>{
        if(initSelected!==-1){
            ref.current.focus();
        }
        setSelected(initSelected);
    },[initSelected]);

    return(
        <ListWrap
            type={type}
            width={width}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            ref={ref}
            onBlur={onBlur}
            onMouseDown={e=>e.preventDefault()}
        >
            <ListItemsWrap>
                {createListItems(items)}
            </ListItemsWrap>
        </ListWrap>

    )
}

List.defaultProps = {
    type : 'thick', // thick, thin, keyword, users

    items : [

        {id : 'list1', name : 'list1'},
        {id : 'list2', name : 'list2'},
        {id : 'list3', name : 'list3'},
        {id : 'list4', name : 'list4'},
        {id : 'list5', name : 'list5'},
        {id : 'list6', name : 'list6'},
        {id : 'list7', name : 'list7'},
        {id : 'userId', email : 'etest1@kolon.com', name: '이광훈 주임', company : '코오롱베니트'}
    ],
    onSelect : null,
    width : null,
    onBlur : null,
    initSelected : -1
}

export default List;