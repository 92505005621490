import {action, makeObservable, observable, runInAction} from "mobx";
import {receiveResponse} from "../utils/api";
import isEmpty from 'is-empty';

class TopicStore {

    topics = [];

    topicGroups = [];

    constructor() {
        makeObservable(this, {
            topics: observable,
            topicGroups: observable,
            setTopics: action,
            init: action
        });
    }

    init = async () => {
        if (isEmpty(this.topics)) {
            const res = await receiveResponse({}, 'topics', 'get');
            runInAction(() => {
                if (res.status === 200) {
                    console.log('topicStore.topics', res.data);
                    this.topics = res.data;
                }
            });
        }

        if  (isEmpty(this.topicGroups)) {
            const res = await receiveResponse({}, 'topic-groups', 'get');
            runInAction(() => {
                if (res.status === 200) {
                    console.log('topicStore.topic-groups', res.data);
                    this.topicGroups = res.data;
                }
            });
        }
    }

    setTopics = (topics) => {
        this.topics = [...topics];
    }
}

export default TopicStore;