import {Cookies} from "react-cookie";
import env from '../constants/env';
import jwtDecode from "jwt-decode";

export const getSSOToken = () => {

    const cookie = new Cookies();

    const configLevel = process.env.NODE_ENV || 'production';

    return cookie.get(env.ssoToken);
}

export const getCookieValue = (key) => {
    const cookie = new Cookies();
    return cookie.get(key);
}

export const setCookieValue = (key, value) => {
    const cookie = new Cookies();
    cookie.set(key, value);
}

export const validateJwt = (token) => {

    let result = true;

    /**
     * @todo
     * access token 재발급 시간 로직 개선해야함 (계속 refresh 함)
     * 서버 시간이 1분 왜 느림?
     */
    try{
        const decode = jwtDecode(token);
        const now = new Date();

        if (decode.exp * 1000 < now.getTime()){
            result=false;
        }

    }catch (e){
        result = false
    }
    return result;
}