import {useContext, useEffect} from "react";
import {useStores} from "../../../stores";
import {observer} from "mobx-react";

import ItemDetail from "../modal/modalPage/ItemDetail";
import {BackHistoryContext} from "../../../utils/backHistoryProvider";

const ModalDetail = (props) => {

    const {store} = useStores();
    //02. 모달 디테일 데이터
    const modalDetailStore = store.modalDetailStore;
    //03. 모달 데이터
    const modalPopupStore = store.modalPopupStore;
    //04. 모달토스트 데이터
    const modalToastPopupStore = store.modalToastPopupStore;

    //01. 뒤로가기 버튼을 눌렀는지 여부 저장 (window history 관련)
    let isGoBackClicked = true;
    //02. 뒤로가기시 백여부 (전페이지 state 그대로 남기기위한)
    const backHistory = useContext(BackHistoryContext).current;


    /******************************************************************************************************************/

    /**
     * 이벤트 event
     * @param e
     */

    //뒤로가기 버튼 눌렀을 시 이벤트
    const goBackHandler = (event) => {

        //강제 HistoryBack 못타게
        isGoBackClicked = true;


        /**
         * *****************모달 토스트 (상 위) ***********************
         *상위에서 백버튼시
         *
         * 1. 링크로 바로 들어갔을 시  state가 null
         * 2. 다음 모달토스트 창 열려 있을 경우        (계속 뒤로가기 눌렀을 시)
         * 3. 다음 모달토스트 창에서 x버튼 눌렀을 경우  (모달 토스트창에서 네, 아니오 눌렀을 시)      ----> 다음 모달에서 historyBack하면서 이벤트 발생(모달, 모달)
         */
        // 01. 링크로 바로 들어갔을 시 state가 null
         if (window.history.state === null) {
             //현재 모달창 닫을지 말지 결정할 경우
             modalDetailStore.closeModalPopup();
             return;
         }
        //02. 상위에서 왔을 시
        else if (event.target.history.state.page === 'modalDetail' || event.target.history.state.page === 'modal' || event.target.history.state.page === 'modalToast') {
            return;
        }

        /**
         * ******************모달  (현 재) ***************************
         * 현재 백버튼시
         *
         *  %%%%%%%% 뒤로가기시 팝업창을 닫지 안닫을지 여부 결정
         */
        // 현재 자신일 경우
        else {
             modalDetailStore.closeModalPopup();
        }
    };

    /******************************************************************************************************************/

    /**
     * 함수 function
     */

    //x버튼 클릭시 함수 (닫기)
    const goHistoryBack = () => {

        //강제 HistoryBack
        isGoBackClicked = false;
        //삭제로 세팅
        modalDetailStore.setCallBackType('close');
        //모달창 닫기
        modalDetailStore.closeModalPopup();
    };

    //삭제 버튼 클릭시 함수 (닫기)
    const goDeleteBack = () => {

        //강제 HistoryBack
        isGoBackClicked = false;
        //삭제로 세팅
        modalDetailStore.setCallBackType('delete');
        //모달창 닫기
        modalDetailStore.closeModalPopup();
    };

    //에러발생 버튼 클릭시 함수 (닫기)
    const goErrorBack = () => {

        //강제 HistoryBack
        isGoBackClicked = false;
        //삭제로 세팅
        modalDetailStore.setCallBackType('error');
        //모달창 닫기
        modalDetailStore.closeModalPopup();
    };

    /******************************************************************************************************************/

    /**
     * useEffrct
     * 1. 이미지, 파일 가지오기
     * 2. - 뒤로가기버튼 눌렀을시 모달창 닫기 (window #추가) (자동 주소 뒤로 이동)
     *    - x버튼 눌렀을시 모달창 닫기 (window # 추가) (강제 주소 이동 => window history back)
     *    
     *    return => unMount 이기에 항상 뒤에 실행 -> data.open 반대로 줘야함
     */
    useEffect(()=>{

        /**
         * 모달창 오픈시 (강제로 window 주소 추가)  =>>>> hidden이기 때문에 이미 컴포넌트 존재
         */
        if(modalDetailStore.isOpen) {
            //모달이 켜질때마다 새로운 state를 history에 추가
            window.history.pushState({page:"modalDetail"}, "modalDetail", '');
            //뒤로가기 눌렀을 때 onCloseHandler 함수 실행
            window.addEventListener("popstate", goBackHandler);
            //뒤로가기시 백여부 (전페이지 state 그대로 남기기위한)
            backHistory.state = 'modal';
        }

        return () => {

            /**
             * hidden이기 때문에 ==> 미리 layOut에서 컴포넌트 생성 (hidden = true로 관리)
             *
             * 1. 맨 처음 창 open시 호출 (isOpen=true)
             * 2. 창 닫았을시 호출 (isOpen=false)
             */
            if(!modalDetailStore.isOpen) {
                window.removeEventListener("popstate", goBackHandler);
                //뒤로가기를 누르지 않고 종료할 경우엔 state가 새로 있으니 뒤로가기를 해줘서 popstate 이벤트를 발생시켜야 함
                if (!isGoBackClicked) {
                    window.history.back();
                }
            }
        };

    },[modalDetailStore.isOpen]);



    /******************************************************************************************************************/


    /**
     * 모달 switch
     * @returns {JSX.Element}
     *
     * page
     * type
     * onClose => 닫힘 콜백
     * itemId => {} 데이터
     * onDelete => 삭제 콜백
     * onError => error 콜백
     */

    return (
            <ItemDetail
                page={modalDetailStore.page}
                type={modalDetailStore.type}
                isOpen={modalDetailStore.isOpen}
                onClose={goHistoryBack}
                itemId={modalDetailStore.itemId}
                onDelete={goDeleteBack}
                onError={goErrorBack}
            />
    );

}

export default observer(ModalDetail);