import styled from "styled-components";

export const InviteModalBody = styled.div`
    padding : 15px 11px 16px 23px;
    background: #F7F9FB;
`

export const InviteModalText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color : ${({theme})=>theme.colors.gray_50};
    white-space: pre-wrap;
    margin : 10px 0;
`

export const InviteModalInputWrap = styled.div`
    position : relative;
    display: flex;
    padding: 20px 0px 40px 0px; 
`

export const InviteModalInput = styled.input`
    flex-grow : 1;
    
`

export const InviteModalInputListWrap = styled.div`
    position : absolute;
    width : 100%;
    margin-top : 55px;
    z-index : 3;
`

export const InviteModalUsersWrap = styled.div`
`

export const InviteModalUsersTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    margin-bottom : 20px;
`

export const InviteModalUsersList = styled.div`
    display : flex;
    gap : 10px;
    flex-direction : column;
    max-height : 343px;
    
    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`