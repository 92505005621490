import {
    ConfirmModalAlertTitle,
    ConfirmModalButtons,
    ConfirmModalContainer,
    ConfirmModalTitle,
    ConfirmModalWrap
} from "./ConfirmModalStyles";

import {Button, IconButton} from "../global";
import {
    FileModalStandardItem,
    FileModalStandardList,
    FileModalStandardTitle,
    FileModalStandardWrap
} from "../global/FileConfirmModalStyles";
import AlertIcon from "../../../resources/icons/file_alert.svg";
import isEmpty from "is-empty";

const ConfirmModal = (props) => {

    const {page, isOpen, title, guideTitle, guideText, onCancel, onOk, onCancelText, onOkText} = props;

    const onCancelHandler = (e) => {
        if(onCancel) onCancel();
    }

    const onOkHandler = (e) => {
        if(onOk) onOk();
    }

    return(
        <ConfirmModalContainer className={!isOpen ? 'hidden' : null}>
            <ConfirmModalWrap>
                {
                    page==='guide-alert' ?
                        <ConfirmModalAlertTitle>{'알림'}</ConfirmModalAlertTitle> : null
                }
                <ConfirmModalTitle className={page}>{title}</ConfirmModalTitle>
                {/* 가이드가 필요한 경우 (file confirm 모달에서 가져옴) */}
                {
                    page==='guide' || page==='guide-alert' ?
                        !isEmpty(guideTitle) ?
                            <FileModalStandardWrap>
                                <FileModalStandardTitle>
                                    <IconButton width={'24px'} height={'24px'} src={AlertIcon}/>
                                    <div style={{wordBreak : 'break-all'}}>{guideTitle}</div>
                                </FileModalStandardTitle>
                                <FileModalStandardList>
                                    {
                                        !isEmpty(guideText) ?
                                            guideText.map(text=>
                                                <FileModalStandardItem>
                                                    <div>{`- `}</div>
                                                    <div>{text}</div>
                                                </FileModalStandardItem>) : null

                                    }
                                </FileModalStandardList>
                            </FileModalStandardWrap> : null
                        : null
                }
                <ConfirmModalButtons>
                    {
                        page !=='guide-alert' ?
                            <Button type={'secondary'}
                                    width={'94px'}
                                    height={'42px'}
                                    textStyle = {{ fontSize : '14px' }}
                                    onClick={onCancelHandler}>{onCancelText}
                            </Button> : null
                    }
                    <Button type={'primary'}
                            width={'94px'}
                            height={'42px'}
                            textStyle = {{ fontSize : '14px' }}
                            onClick={onOkHandler}>{onOkText}
                    </Button>
                </ConfirmModalButtons>
            </ConfirmModalWrap>
        </ConfirmModalContainer>
    )
}

ConfirmModal.defaultProps = {
    page : '',
    title : 'Enter the Modal Title',
    guideTitle : '',
    guideText : '',
    onCancel : null,
    onOk : null,
    onCancelText : '취소',
    onOkText : '확인'

}
export default ConfirmModal;