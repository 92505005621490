import styled from "styled-components";

export const UserInfoTimeWrap = styled.div`
    display : flex;
    gap : ${({type})=>type==='group-card' || type==='applying' ? '10px' : '5px'};
    align-items : center;
    flex-grow : 1;
`

export const UserInfoTimeImgWrap = styled.div`
    width : 28px;
    height : 28px;
    flex : none; 
    border-radius: 14px;
    background-size: cover;
    
    // &.group-card {
    //     width : 35px;
    //     height : 35px;
    // }
    //
    // &.applying{
    //     width : 35px;
    //     height : 35px;
    // }
`

export const UserInfoTimeImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

export const UserInfoWrap = styled.div`
   display : flex;
   flex-grow : 1;
`

export const UserInfo = styled.div`
    display : flex;
    gap : 6px;
    align-items : center;
    flex-grow : 1;
`

export const UserInfoNickname = styled.div`
    font-size: 12px;
    line-height: 14px;
    color : ${({theme})=>theme.colors.black};
    font-weight: 700;
`

export const UserInfoSpan = styled.span`
    width: 2px;
    height: 2px;
    background: #000000;
    border-radius: 2px;
`

export const UserInfoDot = styled.span`
    width: 2px;
    height: 2px;
    background: #000000;
    border-radius: 2px;
`

export const UserInfoText = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #AEB7C0;
    font-weight: 400;
    padding-right : ${({type}) => type === 'card' || type==='applying' ? '16px' : '0px'};
    text-align: right;
`