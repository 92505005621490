import {action, makeObservable, observable, runInAction} from "mobx";
import {receiveResponse} from "../utils/api";

class DefaultImageStore {

    defaultUserImgs = null;
    defaultGroupImgs = null;

    constructor() {
        makeObservable(this, {

            defaultUserImgs : observable, // 사용자 기본 이미지
            defaultGroupImgs : observable, // 그룹 기본 이미지

            updateDefaultUserImgs: action,
            updateDefaultGroupImgs: action
        });
    }

    init = async () => {
        await this.updateDefaultUserImgs();
        await this.updateDefaultGroupImgs();
    }

    updateDefaultUserImgs = async () => {

        const res = await receiveResponse({useTo: 'U'}, 'imgs', 'get');
        runInAction(() => {
            console.log('defaultImageStore.defaultImg', res);
            this.defaultUserImgs = [...res.data];
        });
    }

    updateDefaultGroupImgs = async () => {
        const res = await receiveResponse({useTo: 'I'}, 'imgs', 'get');
        runInAction(() => {
            console.log('defaultImageStore.defaultGroupImg', res);
            this.defaultGroupImgs = [...res.data];
        });
    }
}

export default DefaultImageStore;