import styled from "styled-components";

export const ItemDetailModalWrap = styled.div`
    width : 100%;
    height : 100%;
    background: ${({theme})=>theme.colors.white};
    position : relative;
    padding : 0px 16px 0px 19px;
    
`

export const ItemDetailModalCloseWrap = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 19px;
`

export const ItemDetailModalInner = styled.div`
    width : 100%;
    height : 100%;
    background: ${({theme})=>theme.colors.white};
    position : relative;
    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 24px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`