import axios from 'axios';
import {ServerConfig} from "../commons/config";
import isEmpty from 'is-empty';
import {getCookieValue, validateJwt} from "./ssoHandler";
import routes from '../constants/routes.json';
import jwtDecode from "jwt-decode";
import env from "../constants/env";
import {t} from "i18next";

// @todo
// refresh 여러번 되는 이슈 (최초 토큰 갱신 이후에는 요청을 보관해놨다가 갱신 이후 재요청 필요)
// refresh가 db에서 삭제되는 경우 처리
//

let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

const setAxiosInterceptors = (history, store) => {

    const {modalToastPopupStore} = store;

    const openAlert = (title) => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            title,
            '',
            null,
            t('CONFIRM'),
            '',
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 요청 전 토큰 유효성 검사를 위한 인터셉터
    axios.interceptors.request.use(async (config) => {
        // 토큰을 필요로 하지 않는 요청들에 대한 처리
        const pathname = new URL(config.url).pathname;
        const search = window.location.search;
        const redirect = window.location.pathname;
        const {userStore, authStore} = store;


        // console.log('find me @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        // console.log(config);
        // console.log(new URL(config.url));

        config.redirectPath = window.location.pathname;

        // console.log(config);
        // console.log('find me @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');

        /**
         * 인증이 필요 없는 api 입력
         */
        if (
            // 인증 관련 요청
            pathname.startsWith('/api/auth') ||
            // 온보딩 사용자 체크, 가입
            pathname.startsWith('/api/user/nickNameCheck') ||
            // 사용자 등록
            pathname === '/api/user/save' ||
            // 공통사
            pathname === '/api/interest/interests'
        ) {

            return config;
        }

        // access, refresh 가져오기
        const refreshToken = await getCookieValue(env.refreshToken);
        const accessToken = await getCookieValue(env.accessToken);

        // refresh 이상있는 경우 로그인 페이지로
        if(!validateJwt(refreshToken)){
            history.push({
                pathname : routes.AUTH_ERROR,
                state : {redirect},
                search
            });
            return config;
        }

        const userId = jwtDecode(refreshToken).userId;

        if(userStore.user?.userId){
            if(userId!==userStore.user.userId){
                window.location.reload();
            }
        }

        if(!validateJwt(accessToken)){

            const retryOriginalRequest = new Promise((resolve) => {
                addRefreshSubscriber((accessToken) => {
                    config.headers.Authorization = "Bearer " + accessToken;
                    resolve(config);
                });
            });

            if(!isTokenRefreshing){
                isTokenRefreshing = true;
                await receiveResponse({refreshToken}, 'auth/refreshtoken', 'post')
                    .then(res => {
                        console.log(res);
                        if (res.status === 200) {
                            // accessToken 재설정
                            const {accessToken, refreshToken} = res.data;
                            // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                            config.headers.Authorization = `Bearer ${accessToken}`;
                            authStore.init(refreshToken, accessToken);

                            // 새로운 토큰으로 지연되었던 요청 진행
                            onTokenRefreshed(accessToken);
                            isTokenRefreshing = false;
                            refreshSubscribers = [];

                        } else {
                            refreshSubscribers = [];
                            isTokenRefreshing = false;
                            history.push({
                                pathname : routes.AUTH,
                                state : {redirect},
                                search
                            });
                            console.error('토큰 갱신 실패', res);
                            // throw new axios.Cancel('토큰 갱신 실패');
                            return Promise.reject(config);
                        }
                    })
                    .catch(e=>{
                        refreshSubscribers = [];
                        isTokenRefreshing = false;
                        history.push({
                            pathname : routes.AUTH,
                            state : {redirect},
                            search
                        });
                        console.error('토큰 갱신 실패', e);
                        return Promise.reject(config);
                    });
            }

            return retryOriginalRequest;
        }

        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;

    }, (error) => {
        return Promise.reject(error);
    });

    // 요청 이후에 응답을 체크하는 인터셉터
    // 만료 이전의 토큰은 있는데, DB 토큰 테이블에서 지워진 경우 401이 발생할수 있음
    // 해당 현상 처리 용도
    // 요청 전 토큰 유효성 검사를 위한 인터셉터
    axios.interceptors.response.use(async (res) => {
        return res;
    }, (error) => {

        if(error.response.status === 401) {
            if(window.location.pathname !== routes.AUTH) {
                console.error('재로그인 필요', error);
                history.push(routes.AUTH);
                return Promise.reject(error);
            }
        }else {
            const code = error?.response?.data?.code;
            if(code){
                if(code === 'item.not-exists'){
                    openAlert(t('DELETED_ITEM'));
                }else if(code === 'user.constraint'){
                    openAlert(t('USER_CONSTRAINT'));
                }
            }
        }
        return Promise.reject(error);
    });
}

const receiveResponse = (
    params,
    route,
    method = 'get',
    header = {'Content-Type': 'application/json;charset=UTF-8'}

    // multi part인 경우
    //"Content-Type": "multipart/form-data"
) => {

    const headers = {
        ...header,
        Accept: '*/*'
    };

    // if (!empty(authInfo)) {
    //     axios.defaults.headers.common.Authorization = `Bearer ${authInfo.accessToken}`;
    // }
    // axios.defaults.headers.common.Authorization = `Bearer eyJhbGciOiJSUzI1NiJ9.eyJ1c2VySWQiOiJrd2FuZ2h1bl9sZWUxIiwiaWF0IjoxNjU1MjAwNDc3LCJleHAiOjE2NTUyMDA1Mzd9.L02Fb4CSMad43tKlP7cdIx7p1pk6gSlKOTfrwLGlo0X5k1QAq7mEsSCR7Km7EZoxWzo_Dn9KIjnYPtodX0eTklDe7WbM0NaRp3TXClnREsJn3REfilKjnYEh7ob-4jP2cCbDQT2ySlD4D8CpHnVDGFH8t4edMC8978UKggMwlDPV0WZNg05BJxvQ04PTZeLPoKLSMkWM88Mr8A4VRT8JxS6xcDY4MtwLt4upxe78f9euBlwaefjxBoa_RSsVwxFa9fYUU01vwhpxjrvKEfMyWsZ5drVh4Ay2P0QKg0Q6MVwK6GZqyFhKSrP4bcnAHVAjgMj4q1x0NG8mameSbqfJzg`;
    axios.defaults.withCredentials = true;

    // axios.defaults.headers.common.Authorization = "Test";
    // axios.defaults.headers.common.

    const receivedRoute = route;
    return new Promise((resolve, reject) => {

        let fullURL = `${ServerConfig.default.API_URL}${receivedRoute}`;

        if (method === 'get') {
            if (!isEmpty(params)) {
                fullURL = `${fullURL}?`;
                const keys = Object.keys(params);
                const len = keys.length;

                for (let i = 0; i < len; i++) {
                    if(i===len-1) fullURL += `${keys[i]}=${params[keys[i]]}`;
                    else fullURL += `${keys[i]}=${params[keys[i]]}&`;
                }
            }
        }

        axios({
            method,
            url: fullURL,
            data: params,
            headers
        })
            .then(response => resolve(response))
            .catch(error => {
                if (!isEmpty(error.response)) {
                    const {response} = error;
                    if (response.status >= 500) return reject(error);
                    return resolve(response);
                }
                return reject(error);
            });
    });
};

const signIn = (params) => {
    return receiveResponse(params, 'auth/signin', 'post')
        .then((res) => {
            if (res.status === 200) {
                return Promise.resolve(res);
            }
            return Promise.reject(res);
        }).catch((error) => {
            return Promise.reject(error);
        });
}

const signQueryIn = (params, hash) => {


    return receiveResponse(params, 'auth/signin' + hash, 'get')
        .then((res) => {
            if (res.status === 200) {
                return Promise.resolve(res);
            }
            return Promise.reject(res);
        }).catch((error) => {
            return Promise.reject(error);
        });
}

const signInAD = (params) => {
    return receiveResponse(params, 'auth/signin?type=AD', 'post')
        .then((res) => {
            if (res.status === 200) {
                return Promise.resolve(res);
            }
            return Promise.reject(res);
        }).catch((error) => {
            return Promise.reject(error);
        });
}

const getFileBlob = (url) => {
    return new Promise(function (resolve, reject) {
        try {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.onerror = function () {
                reject("Network error.")
            };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(xhr.response)
                } else {
                    reject("Loading error:" + xhr.statusText)
                }
            };
            xhr.send();
        } catch (err) {
            reject(err.message)
        }
    });
}

// 사용자 검색
const getUsersByNickname = (nickname) => {
    return receiveResponse({}, `user/users/${nickname}`, 'get')
        .then((res) => {
            if (res.status === 200) {
                return Promise.resolve(res);
            }
            return Promise.reject(res);
        }).catch((error) => {
            return Promise.reject(error);
        });
}

// 키워드 검색
const getKeywords = (keyword) => {
    return receiveResponse({keyword}, 'keywords', 'get')
        .then((res) => {
            if (res.status === 200) {
                return Promise.resolve(res);
            }
            return Promise.reject(res);
        }).catch((error) => {
            return Promise.reject(error);
        });
}

// return form data
const getFormData = (params, fileColumn) => {
    let formData = new FormData();
    const keys = Object.keys(params);
    for(let i=0;i<keys.length;i++){
        console.log(params[keys[i]])
        if(keys[i]===fileColumn){
            params[keys[i]].forEach(file=>formData.append(keys[i],file));
            continue;
        }
        formData.append(keys[i],params[keys[i]]);
    }
    return formData;
}

export {
    receiveResponse,
    signIn,
    signInAD,
    signQueryIn,
    setAxiosInterceptors,
    getFileBlob,
    getUsersByNickname,
    getKeywords,
    getFormData
}