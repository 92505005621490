import {
    ApplyingUserButtons,
    ApplyingUserContent,
    ApplyingUserContentWrap,
    ApplyingUserWrap
} from "./ApplyingUserStyles";
import {Button, UserInfoTime} from "./index";
import {receiveResponse} from "../../../utils/api";
import {useStores} from "../../../stores";
import {useTranslation} from "react-i18next";

const ApplyingUser = (props) => {

    const {store} = useStores();
    const {toastPopupStore, modalToastPopupStore, modalPopupStore} = store;
    const {user, group, onSubmit, onAccept} = props;
    const {t} = useTranslation();
    /**
     * ==============================================================
     * API
     */
        // 가입 수락
    const onAcceptApply = async () => {
            const userId = user.userId;
            await receiveResponse(
                {userId},
                `groups/${group.groupId}/accept-apply`,
                'patch',
                {"Content-Type": "multipart/form-data"}
            )
                .then((res)=>{
                    if(res.status===200){
                        if(res.data){
                            toastPopupStore.openToastPopup(t('ACCEPT_APPLY_GROUP', {nickname : user?.nickName}));
                            onSubmitHandler();
                            onAcceptHandler()
                        }else{

                        }
                    }
                })
                .catch(e=>{
                    console.log(e);
                })
        }

    // 가입 거절
    const onDeclineApply = async () => {
        if(!modalToastPopupStore.isCancel) return;
        const userId = user.userId;
        await receiveResponse(
            {userId},
            `groups/${group.groupId}/decline-apply`,
            'patch',
            {"Content-Type": "multipart/form-data"}
        )
            .then((res)=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(t('REJECT_APPLY_GROUP', {nickname : user?.nickName}));
                        onSubmitHandler();
                    }
                }
            })
    }

    // 가입 거절 모달 띄우기
    const openDeclineModal = () => {
        modalToastPopupStore.initDefault(
            'alert',
            '가입요청을 거절하시겠습니까?',
            '네',
            '아니요',
            onDeclineApply
        );
        modalToastPopupStore.openModalPopup();
    }

    /**
     * ==============================================================
     * Handler
     */
    const onSubmitHandler = () => {
        if(onSubmit && typeof onSubmit === 'function') onSubmit();
    }

    const onAcceptHandler = () => {
        if(onAccept && typeof onAccept === 'function') onAccept();
    }
    // 상단 프로필 사진 누를 경우
    const onClickImgHandler = (userId) => {
        modalPopupStore.initAdd(
            'vp',null, {userId : userId},()=>{},()=>{}
        );

    }

    return(
        <ApplyingUserWrap>
            <ApplyingUserContentWrap>
                <UserInfoTime
                    type={'applying'}
                    nickname={user?.nickName}
                    companyName={user?.companyName}
                    createDate={user?.applyDate}
                    regUserImgId={user?.imgId}
                    onClickImg={e=>onClickImgHandler(user?.userId)}
                />
                <ApplyingUserContent>{user?.answer}</ApplyingUserContent>
                <ApplyingUserButtons>
                    <Button
                        type={'secondary'}
                        shape={'circle'}
                        width={'64px'}
                        height={'32px'}
                        onClick={openDeclineModal}
                    >거절</Button>
                    <Button
                        shape={'circle'}
                        width={'64px'}
                        height={'32px'}
                        onClick={onAcceptApply}
                    >수락</Button>
                </ApplyingUserButtons>
            </ApplyingUserContentWrap>
        </ApplyingUserWrap>
    )
}

ApplyingUser.defaultProps = {
    user : null,
    group : null,
    onSubmit : null,
    onAccept : null
}

export default ApplyingUser;