import {
    InvitingGroupCnt,
    InvitingGroupInfo,
    InvitingGroupInfoBtn,
    InvitingGroupInfoImg,
    InvitingGroupInfoLi,
    InvitingGroupName,
} from "./InvitingGroupStyles";
import {ServerConfig} from "../../../commons/config";
import {numberToText} from "../../../utils/textHandler";
import {receiveResponse} from "../../../utils/api";
import React, {useState} from "react";
import {useStores} from "../../../stores";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {Link} from 'react-router-dom';
import routes from "../../../constants/routes.json";
import btnImg from "../../../resources/mImages/group_invitation_arr.png"
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";

const InvitingGroup = (props) => {

    const {groupId, imgId, userCount, groupName, onAccept, onReject} = props;

    const {t} = useTranslation();
    const [isAcceptInvite, setIsAcceptInvite] = useState(false);
    const {store} = useStores();
    const {userStore, modalToastPopupStore, toastPopupStore, groupDetailStore, defaultImageStore} = store;
    const history = useHistory();
    const location = useLocation();
    /**
     * api (초대 수락, 초대 거절)
     */

    /**
     * @todo
     * 같은 페이지인 경우, groupDetailStore 업데이트 시켜주기
     *
     * @return {Promise<void>}
     */

        // 초대 수락
    const onAcceptHandler = async () => {
            await receiveResponse({},`groups/${groupId}/accept-invite`,'patch')
                .then(res=>{
                    if(res.status===200){
                        if(onAccept && typeof onAccept === 'function') onAccept();
                        toastPopupStore.openToastPopup(t('COMPLETE_JOIN_GROUP', { groupName : groupName.length > 8 ? `${groupName.substr(0,8)}...` : groupName}));
                        setIsAcceptInvite(true);
                        updateSamePathGroup();
                        // 초대받은 그룹 리스트 변화가 있음을 알림 > LNB에서 페이지가 변화할 때 마다 체크
                        userStore.setIsInvitingGroupsUpdate(true);
                    }
                })
                .catch(e=>{
                    console.log(e);
                });
        }

    // 초대 거절
    const onRejectHandler = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse({},`groups/${groupId}/decline-invite`,'patch')
            .then(res=>{
                if(res.status===200){
                    if(onReject && typeof onReject === 'function') onReject();
                    userStore.updateInvitingGroups();
                    toastPopupStore.openToastPopup(t('REJECT_INVITE_GROUP', { groupName : groupName.length > 8 ? `${groupName.substr(0,8)}...` : groupName}));
                }
            })
            .catch(e=>{
                console.log(e);
            });
    }

    // 초대 거절 모달 띄우기
    const openDeclinePopup = () => {
        modalToastPopupStore.initDefault(
            'alert',
            `${groupName}그룹 초대를 거절하시겠습니까?`,
            '네',
            '아니요',
            onRejectHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 같은 path에 있는 경우, 업데이트
    const updateSamePathGroup = () => {
        if(matchPath(location.pathname,`${routes.GROUPS}/${groupId}`)){
            groupDetailStore.init(groupId,userStore.user.userId).then(()=>{
                userStore.updateInvitingGroups();
                userStore.updateJoinGroups();
            });
        }
    }


    return(
            <InvitingGroupInfoLi>
                <Link to={`${routes.GROUPS}/${groupId}`}>
                    {
                        defaultImageStore.defaultGroupImgs ?
                            <InvitingGroupInfoImg
                                src={defaultImageStore.defaultGroupImgs.findIndex(img => img === imgId) !== -1 ?
                                    `${ServerConfig.default.IMG_URL}${imgId}` :
                                    `${ServerConfig.default.IMG_THUMBNAIL_URL}${imgId}`}
                            /> : null
                    }
                    <InvitingGroupInfo>
                        <InvitingGroupName>{groupName}</InvitingGroupName>
                        <InvitingGroupCnt>
                            {
                                isAcceptInvite ? t('JOIN_CONFIRM')
                                    : t('ARE_JOINING', {cnt : numberToText(userCount)})

                            }
                        </InvitingGroupCnt>
                    </InvitingGroupInfo>
                    <InvitingGroupInfoBtn src={btnImg}/>
                </Link>
            </InvitingGroupInfoLi>
    )
}

InvitingGroup.defaultProps = {
    groupId : 'groupId',
    imgId : 'imgId',
    userCount : 0,
    groupName : 'groupName',
    onAccept : null,
    onReject : null
}

export default observer(InvitingGroup);