import styled from "styled-components";

export const FileModalContainer = styled.div`
    width : 100vw;
    height : 100vh;
    background-color : rgba(25,30,41,0.3);
    display : flex;
    align-items : center;
    justify-content : center;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 6;
    &.hidden{display:none;}
`

export const FileModalWrap = styled.div`
    background : ${({theme})=>theme.colors.white};
    padding : 20px;
    border-radius: 30px;
    position : relative;
    width : 320px;
`

export const FileModalTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    margin-bottom : 20px;
`

export const FileModalSubTitle = styled.div`
    display : flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    align-items: center;
    color : ${({theme})=>theme.colors.black};
    min-height : 46px;
    margin-bottom : 14px;
    white-space : pre-wrap;
    
`

export const FileModalButtons = styled.div`
    justify-content : center;
    display : flex;
    gap : 10px;
`

export const FileModalFilesWrap = styled.div`
    padding : 16px 20px;
    background: ${({theme})=>theme.colors.gray_stroke_50};
    border-radius: 10px;
    margin-bottom : 10px;
`

export const FileModalFilesTitle = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-bottom : 9px;
`

export const FileModalFilesList = styled.ul`
`

export const FileModalFilesItem = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display : flex;
    gap : 10px;
    &.hidden{display:none;}
`

export const FileModalFileNameWrap = styled.div`
    display : flex;
    width : 165px;
    align-items : center;
`

export const FileModalDot = styled.span`
    display : inline-block;
    width: 4px;
    height: 4px;
    background: ${({bg})=>bg || '#000000'};
    border-radius: 2px;
    flex : none;
    margin-right : 5px;
    vertical-align : middle;
`

export const FileModalFileName = styled.div`
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    color : ${({alert})=>{
        if(alert) return '#FF7171';
    }};
`

export const FileModalFileExt = styled.div`
    color : ${({alert})=>{
    if(alert) return '#FF7171';
}};
    text-align : right;
`

export const FileModalFileSize = styled.div`
    color : ${({theme, alert})=> {
    if(alert) return '#FF7171';
    return theme.colors.gray_50;
}};
    width : 65px;
    text-align : right;
`

export const FileModalStandardWrap = styled.div`
    background: ${({theme})=>theme.colors.gray_disabled};
    border-radius: 10px;
    padding : 16px 20px;
    margin-bottom : 10px;
`

export const FileModalStandardTitle = styled.div`
    font-size: 14px;
    line-height : 21px;
    display : flex;
    gap : 3px;
    color: #75818D;
    align-items : flex-start;
`

export const FileModalStandardList = styled.div`
    
`

export const FileModalStandardItem = styled.div`
    line-height : 20px;
    color: #75818D;
    font-weight: 400;
    font-size: 12px;
    display : flex;
    // gap : 3px;
    // align-items : center;
`