import styled from 'styled-components';

export const NoticeMessageWrap = styled.div`
  display: block;
  padding: 11px 26px;

  border-bottom: 1px solid #E5EBF0;
  
  &.read {
    opacity : 0.5;
  }
  
`

export const NoticeMessageTitle = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #191E29;
    margin-bottom: 3px;
        
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const NoticeMessageContent = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
    margin-bottom: 3px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
`

export const NoticeMessageDate = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
    
`