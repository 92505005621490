import styled from "styled-components";

export const AppLinkContainer = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    height : 100vh;
    padding : 16px;
`

export const AppLinkText = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color :#191E29;
    white-space : pre-wrap;
    margin : 55px 0 70px 0;
    text-align : center;
    padding : 0 28px;
`

export const AppLinkButton = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    padding: 12px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #3A6AF6;
`

export const AppLinkButtonText = styled.div`
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`