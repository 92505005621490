import {
    GroupsCardWrap,
    GroupsCardStyle,
    GroupsCardNumber,
    GroupsCardImg,
    GroupsCardDl,
    GroupsCardDt,
    GroupsCardDd,
    GroupsCardP,
    GroupsCardNumberImg,
    GroupsCardInterestSpan,
    GroupsCardNumberSpan,
    GroupsCardDtLockImg,
    GroupsCardSelectBottom,
    GroupsCardSelectBottomStrong,
    GroupsCardText
} from "./GroupsCardStyles";
import {Link} from "react-router-dom";
import routes from "../../../constants/routes.json";
import React from "react";
import {useStores} from "../../../stores";
import rankImg from "../../../resources/images/gather_r.png"
import defaultImg from "../../../resources/images/testimg.png"
import lockImg from "../../../resources/images/lock.png"
import env from '../../../constants/env';
import {ServerConfig} from "../../../commons/config";
import isEmpty from "is-empty";
import {Highlighter} from "../../../utils/textHandler";
import {getDateFrom, getDateFromNoTime} from "../../../utils/dateHandler";
import {observer} from "mobx-react";

const GroupsCard = (props) => {

    /**
     *
     * @type {string} : board, group-board, group, search, comment
     */
    const { index, type, item, onClick, highlight } = props;
    const {store} = useStores();
    const {defaultImageStore} = store;

    const bottomComponent = (type) => {
        // 모달 폼 형식
        switch (type) {
            case 'S' : {
                return (
                    <GroupsCardSelectBottom>
                        <GroupsCardInterestSpan>{item.interestNameText}</GroupsCardInterestSpan>
                    </GroupsCardSelectBottom>
                )
            }
            case 'J' : {
                return <GroupsCardSelectBottom>
                           <GroupsCardInterestSpan>{item.interestNameText}</GroupsCardInterestSpan>
                           {env.groupsHomeJoinBottomJoinRankText + ' '}
                           <GroupsCardSelectBottomStrong>
                               {item.userCount + '명 가입'}
                           </GroupsCardSelectBottomStrong>
                       </GroupsCardSelectBottom>
            }
            case 'C' : {
                return <GroupsCardSelectBottom>
                           <GroupsCardInterestSpan>{item.interestNameText}</GroupsCardInterestSpan>
                           {env.groupsHomeJoinBottomCreateDateRankText + ' '}
                           <GroupsCardSelectBottomStrong>
                               {getDateFromNoTime(item.createDate)}
                           </GroupsCardSelectBottomStrong>
                       </GroupsCardSelectBottom>

            }
            case 'R' : {
                return <GroupsCardSelectBottom>
                           <GroupsCardInterestSpan>{item.interestNameText}</GroupsCardInterestSpan>
                           <GroupsCardSelectBottomStrong>
                               {getDateFrom(item.lastActivityDate)}
                           </GroupsCardSelectBottomStrong>
                           {env.groupsHomeJoinBottomItemRankText + ' '}
                       </GroupsCardSelectBottom>
            }
            default: {
                return (
                    <GroupsCardSelectBottom>
                        <GroupsCardInterestSpan>{item.interestNameText}</GroupsCardInterestSpan>
                    </GroupsCardSelectBottom>
                )
            }
        }
    }

    const topComponent = (type) => {
        // 모달 폼 형식
        switch (type) {
            case 'S' :
            case 'J' :
            case 'C' :
            case 'R' : {
                return <>
                           <GroupsCardNumberImg src={rankImg}></GroupsCardNumberImg>
                           <GroupsCardNumber>
                               <GroupsCardNumberSpan>{index + 1}</GroupsCardNumberSpan>
                           </GroupsCardNumber>
                       </>
            }
            default: {
                return (
                    null
                )
            }
        }
    }



    return (
            <GroupsCardStyle>
                <Link to={`${routes.GROUPS}/${item.groupId}`}
                      key={`groups-rank-${item.groupId}`}
                    // onClick={(e)=>{ if(item.privateYn === 'Y') e.preventDefault();}}
                      state={{ data: `${routes.GROUPS}` }}>
                    <GroupsCardWrap>

                        {/*순위*/}  {/*랭킹 리스트만*/}
                        {topComponent(type)}

                        {/*흥미*/}
                        {
                            defaultImageStore.defaultGroupImgs ?
                                <GroupsCardImg
                                    src={item.imgId === null ? defaultImg :
                                        defaultImageStore.defaultGroupImgs.findIndex(img=>img === item.imgId)!==-1 ?
                                            `${ServerConfig.default.IMG_URL}${item.imgId}` :
                                            `${ServerConfig.default.IMG_THUMBNAIL_URL}${item.imgId}`}
                                />: null
                        }
                        {/*테이블*/}
                        <GroupsCardDl>
                            <GroupsCardDt>
                                {item.privateYn === 'Y' ? <GroupsCardDtLockImg src={lockImg}/> : null}
                                <GroupsCardText className={!isEmpty(highlight) ? 'searching' : ''}>{
                                    isEmpty(highlight) ?
                                        item.groupName :
                                        <Highlighter
                                            text={item.groupName}
                                            highlight={highlight}
                                            type={"title"}
                                        />
                                }</GroupsCardText>
                            </GroupsCardDt>
                            <GroupsCardDd>
                                {/*<GroupsCardDdSpanWrap>*/}
                                {/*    <GroupsCardSpanImg src={crownImg}></GroupsCardSpanImg>*/}
                                {/*    <GroupsCardSpan>{item.managerNickname}</GroupsCardSpan>*/}
                                {/*</GroupsCardDdSpanWrap>*/}
                                <GroupsCardP>{
                                    isEmpty(highlight) ?
                                        item.groupDetail :
                                        <Highlighter
                                            text={item.groupDetail}
                                            highlight={highlight}
                                            type={"content"}
                                        />
                                }</GroupsCardP>
                                {bottomComponent(type)}
                            </GroupsCardDd>
                        </GroupsCardDl>
                    </GroupsCardWrap>
                </Link>
            </GroupsCardStyle>
    )
}

export default observer(GroupsCard);