import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalSubTitle,
    ModalTitle
} from "../global/ModalStyles";
import {
    Button,
    Checkbox,
    IconButton
} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import {
    ReportList,
    ReportModalBody,
    ReportText
} from "./ReportModalStyles";
import {useEffect, useState} from "react";
import env from "../../../constants/env";
import {receiveResponse} from "../../../utils/api";
import routes from "../../../constants/routes.json";
import {getMaxLenCheck} from "../../../utils/boolHandler";
import isEmpty from "is-empty";
import {removeBlank} from "../../../utils/textHandler";

const ReportModal = (props) => {

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const onSubmit = props.onSubmit;

    //const {onClose, onSubmit, onChange} = props;

    //데이터
    const data = modalPopupStore;

    // 데이터 아이템
    const detailItem = data.detailItem;

    // 기타 내용
    const [reportText, setReportText] = useState('');
    // 선택된 신고사유 목록
    const [select, setSelect] = useState([]);
    // 내용 포커스
    const [isContentFocus, setIsContentFocus] = useState(false);


    /**
     * 내용 아이템
     * @type {string[]}
     */
    const items = [
        '욕설/비하발언',
        '특정인 비방',
        '개인 사생활 침해',
        '보안 유출',
        '혐오감 유발',
        '게시글/댓글 도배',
        '홍보성 컨텐츠(광고)',
        '지나친 정치/종교적 컨텐츠'
    ];

    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    /**
     * 닫기 이벤트
     * @param e
     */
    const onCloseHandler = (e) => {
        //닫기 버튼
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    /**
     * 글 입력 이벤트
     * @param e
     */
    const onChangeHandler = (e) => {
        // 글자수 안이면
        if(getMaxLenCheck(e.target.value.length, env.maxReportReasonLen)) {
            setReportText(e.target.value);
        }
    }

    /**
     * 신고하기 이벤트
     * @param e
     */
    const onSubmitHandler = (e) => {

        let reason = '';
        if (select.length !== 0) {
            reason = select.join(',');

            //빈값이 아닐경우
            if (reportText !== '') {
                reason = `${reason},${reportText}`;
            }
        }else {
            reason = reportText;
        }


        if(detailItem?.commentId){
            reportComment(reason);
        }else{
            reportBoardItem(reason);
        }
    }

    /******************************************************************************************************************/


    /**
     * 함수
     */

    /**
     * 초기화 함수
     */
    const initdata = () => {
        // 1. 초기화
        setSelect([]);
        setReportText('');
    }

    /**
     * 기타 검사
     * @param form
     * @returns {*|boolean}
     */
    const getIsDisabled = () => {
        return select?.length === 0 && (isEmpty(removeBlank(reportText)) || reportText?.length > env.maxReportReasonLen)
    }

    /**
     * <게시글> 신고하기 API
     * @param reason
     * @param cb
     */
    const reportBoardItem = (reason) => {
        receiveResponse({}, `items/${detailItem?.itemId}/report?reason=${encodeURIComponent(reason)}`, 'patch')
            .then(res=>{
                if(res.status === 200) {
                    onSubmit();

                    // 신고 3개
                    if(res.data.count === 3){
                        props.history.replace(routes.HOME);
                    }
                }
            }).catch((e)=>{
            console.log(e)
        });
    }

    const reportComment = (reason) => {
        receiveResponse({}, `comments/${detailItem?.commentId}/report?reason=${encodeURIComponent(reason)}`, 'patch')
            .then(res=>{
                if(res.status === 200) {
                    onSubmit();
                    // @todo
                    // 신고 3번일 경우
                }
            }).catch((e)=>{
            console.log(e)
        });
    }
    /******************************************************************************************************************/

    /**
     * useEffrct
     */

    useEffect(()=>{

        if (data.isOpen) {

        }else {
            // 닫을 때 초기 데이터 (초기화시키기)
            initdata();
        }
    },[data.isOpen]);


    /******************************************************************************************************************/


    /**
     * 컴포넌트
     * @param items
     * @returns {*}
     */
    const createReportList = (items) => {
        return items.map((item)=>{
            return <Checkbox
                size={'l'}
                label={item}
                checked={select.includes(item)}
                checkLabelStyleWrap={{fontSize : '14px'}}
                onCheck={() => {
                    !select.includes(item)
                        ? setSelect((select) => [...select, item])
                        : setSelect(select.filter((elem) => elem !== item));
                }}
            />
        })
    }


    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                {/** 상단 전체 타이틀 **/}
                <ModalTitle>신고하기</ModalTitle>
                <ModalCloseWrap>
                    <IconButton
                        width={'32px'}
                        height={'32px'}
                        onClick={onCloseHandler}
                        src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>


            {/* 내용 컴포넌트 */}
            <ModalScrollBox>
                {/** 상단 제목 **/}
                <ModalSubTitle>신고사유를 선택해주세요.</ModalSubTitle>

                {/** 중단 내용 **/}
                <ReportModalBody>
                    <ReportList>
                        {createReportList(items)}
                    </ReportList>
                    <ReportText
                        className={isContentFocus ? 'focus' : ''}
                        maxLength={env.maxReportReasonLen}
                        placeholder={'기타 신고사유를 작성해주세요.'}
                        onChange={e=>onChangeHandler(e)}
                        value={reportText}
                        onFocus={e=>setIsContentFocus(true)}
                        onBlur={e=>setIsContentFocus(false)}
                    />
                </ReportModalBody>
            </ModalScrollBox>


            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button
                    type={'mobile'}
                    width={'100%'}
                    textStyle={{fontSize : '16px', lineHeight: "23px"}}
                    disabled={getIsDisabled()}
                    onClick={onSubmitHandler}
                >신고하기</Button>
            </ModalBottomWrap>


        </ModalContainer>
    )
}

ReportModal.defaultProps = {
    onClose : null,
    onSubmit : null,
    onChange : null,
    items : ['욕설/비하발언', '특정인 비방', '개인 사생활 침해', '보안 유출',
        '혐오감 유발', '게시글/댓글 도배', '홍보성 컨텐츠(광고)', '지나친 정치/종교적 컨텐츠']
}

export default ReportModal;