import { action, makeObservable, observable } from "mobx";
import * as React from 'react';

class ModalDetailParentStore {

    /**
     * c    : 소통해요
     * g    : 모여봐요
     */
    page = 'c';

    /**
     * 팝업
     * 열기, 닫기
     */
    isOpen = false;

    /**
     * 아이템 객체
     */
    itemId = '';

    /**
     * 열기 함수
     * 창이 닫았을 시 발생 콜백 함수
     */
    openModalPopup = () => {};

    /**
     * 닫기 함수
     * 창이 닫았을 시 발생 콜백 함수
     */
    closeModalPopup = () => {};

    /**
     * 콜백 type
     *
     * close
     * delete
     * error
     */
    callBackType = 'close';

    /**
     * 닫기 callBack
     * 창이 닫았을 시 발생 콜백 함수
     */
    closeCallBack = () => {};

    /**
     * 삭제시 callBack
     * 확인 버튼 눌렀을 시 발생 콜백 함수
     */
    deleteCallBack = () => {};

    /**
     * 에러시 callBack
     * 확인 버튼 눌렀을 시 발생 콜백 함수
     */
    errorCallBack = () => {};

    constructor() {
        makeObservable(this, {
            page : observable,
            isOpen: observable,
            itemId: observable,
            callBackType : observable,

            init: action,
            openModalPopup: action,
            closeModalPopup: action
        });
    }


    /**
     * 생성
     * 모달창 처음 세팅
     * page
     * item
     * 닫기 콜백
     * 삭제 콜백
     * 에러 콜백
     *
     */
    init = (page, itemId, closeCallBack, deleteCallBack, errorCallBack) => {
        this.page = page;
        this.itemId = itemId;

        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }

        if (deleteCallBack && typeof deleteCallBack === 'function') {
            this.deleteCallBack = deleteCallBack;
        }

        if (errorCallBack && typeof errorCallBack === 'function') {
            this.errorCallBack = errorCallBack;
        }
    };

    /**
     * 모달창 오픈
     */
    openModalPopup = () => {
        this.isOpen = true;
    }

    /**
     * 모달창 닫기
     */
    closeModalPopup = () => {
        this.isOpen = false;
        this.itemId = '';

        if (this.callBackType === 'close') {
            this.closeCallBack();
        }else if (this.callBackType === 'delete') {
            this.deleteCallBack();
        }else if (this.callBackType === 'error') {
            this.errorCallBack();
        }
    }

    /**
     * 셋 콜백 타입
     */
    setCallBackType = (callBackType) => {
        this.callBackType = callBackType;
    }

}

export default ModalDetailParentStore;

