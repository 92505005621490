import styled from "styled-components";

export const FamilyEventContainer = styled.div`
    width : 100%;
    position : relative;
    height: 100%;
`

export const FamilyEventWrap = styled.div`
    width : 100%;
    height : calc(100% - 70px);
    background: ${({theme})=>theme.colors.white};
    position : relative;
    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 24px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`

export const ContentContainer = styled.div`
    position: relative;
    display: block;
    font-size: 0px;
    padding: 0px 19px 0px 16px;
    border-bottom: 1px solid #E5EBF0;
    
`

// title 영역
export const userInfoWrap = styled.div`
    position: relative; 
    margin-top: 10px; 
    padding-right: 16px;
}`

export const TitleTopicWrap = styled.div`
    display : flex;
    gap : 7px;
    align-items : center;
    margin-bottom : 10px;
`

export const TitleTopic = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3A6AF6;
`

export const TitleVoteCount = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3A6AF6;
`

export const TitleTextWrap = styled.div`
    white-space : pre-wrap;
    word-break : break-all;
`

export const TitleText = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: ${({theme,isSearching})=>isSearching ? theme.colors.gray_50 : theme.colors.black};
    padding: 6px 40px 0px 0px;
`

export const MenuWrap = styled.div`
    position : absolute;
    display : flex;
    flex-direction : column;
    align-items : flex-end;
    right : 15px;
    top : 60px;
`


export const MenuButton = styled.div`
    border-radius: 30px;
    z-index : 2;
    // &:hover {
    //     background: #EAEAEA;
    // }
`

export const Menu = styled.div`
    margin-top : 5px;
    z-index : 2;
`

export const ContentTitleAndSection = styled.div`
    display : flex;
`

export const Content = styled.div`
    width: 100%;
`

export const VoteWrap = styled.div`
`

export const ContentSection = styled.div`

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 6px 21px 0px 0px;
    margin-bottom: 12px;
    color: ${({theme,isSearching})=>isSearching ? theme.colors.gray_50 : theme.colors.black};
    white-space: pre-wrap;
`


export const ContentFilesWrap = styled.div`
    pointer-events: auto;
    display: flex;
    flex-direction: column;
`

export const ContentFiles = styled.div`
    display : flex;
    // overflow-x: auto;
    position : relative;
    gap : ${({image})=>image ? '10px' : '5px'};
    padding-bottom: 10px;
    margin-bottom: 2px;
    flex-wrap : wrap;

`
export const ContentOptions = styled.div`
    display : flex;
    //gap : 10px;
    position : relative;
    padding-bottom: 20px;
    
`

export const ContentOption = styled.div`
    flex : none;
    display : flex;
    gap : 4px;
    align-items : center;
    border-radius: 7px;
    padding-right: 16px;
`

export const BookmarkButton = styled.div`
    border-radius: 30px;
    position : absolute;
    right : 0px;
`

export const ContentOptionText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
`

// 댓글 영역
export const CommentsWrap = styled.div`
    display : ${({isOpenComments}) => isOpenComments ? 'block' : 'none'};
     > :first-child {
        border-top: 0px;
    }
    
`

// export const Comments

// 추천글 영역


export const FamilyEventCommentText = styled.div`
    font-weight: 400;
    font-size: 14px;
    color : #75818D;
    line-height : 23px;
`

export const FamilyEventCommentCount = styled.span`
    font-weight: 700;
    color : #75818D;
`

export const FamilyEventCommentWrap = styled.div`
    display : flex;
    height: 45px;
    justify-content : center;
    gap : 5px;
    padding : 13px;
   
    margin-left : ${({theme,re})=>re ? '120px' : ''};
    background: #F7F9FB;
    border-top: 1px solid #E5EBF0;
`
