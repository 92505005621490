import {observer} from "mobx-react";
import {
    CloseBtnImg,
    ClosePreBtn,
    CloseTitle,
    CloseTopWrap,
    CloseWrap,
    CloseTabWrap,
    CloseTabContentWrap
} from "./CloseStyles";
import React, {useEffect} from "react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import routes from "../../../constants/routes.json";
import {useStores} from "../../../stores";
import {useTranslation} from "react-i18next";
import SearchPrevBtn from "../../../resources/mImages/array_prev.svg";
import GroupCloseSetting from "../../../components/presentationals/groupSetting/GroupCloseSetting";


const Close = (props) => {

    const {t} = useTranslation()
    const {store} = useStores();
    const {userStore, toastPopupStore, groupDetailStore} = store;
    const location = useLocation();
    const history = useHistory();

    // 권한이 없을 경우, 그룹 상세 페이지로 돌려보내기
    const denyAccess = () => {
        const match = matchPath(location.pathname,{
            path : `${routes.GROUP_SETTING_CLOSE}/:id`
        })
        if(match && match.isExact){
            history.replace(`${routes.GROUPS}/${match.params.id}`);
        }
    }

    /**
     ************* 상단
     * x버튼 클릭시
     * @param e
     */

    // 뒤로가기 이벤트
    const onBackHistoryClickEvent = (e) => {
        e.preventDefault();
        history.goBack();
    }

    /**
     * ====================================================================
     * API
     */
    // 그룹 정보 받아오기
    const getGroupInfo = async () => {

        const _userId = userStore.user.userId;

        const match = matchPath(location.pathname,{
            path : `${routes.GROUP_SETTING_CLOSE}/:id`
        })

        if(match && match.isExact){
            await groupDetailStore.init(match.params.id, _userId,denyAccess,location.pathname)
                .then(()=>{
                })
        }
    }

    // 그룹 정보 업데이트
    // 참여중인 그룹 리스트 업데이트 필요
    const updateGroupInfo = async (cb) => {
        const _userId = userStore.user.userId;
        const _group = groupDetailStore.group;
        await groupDetailStore.init(_group.groupId,_userId,denyAccess,location.pathname)
            .then(()=>{
                if(cb && typeof cb === 'function') cb();
            })
        await userStore.updateJoinGroups();
    }

    useEffect(()=>{
        // user 정보 있을때만 조회 가능
        if(userStore.user.userId) getGroupInfo();
        // login이동

    },[userStore.user.userId]);


    return(
        <CloseWrap>

                {/* 그룹관리 X */}
                <CloseTopWrap>
                    <ClosePreBtn onClick={(e)=>{onBackHistoryClickEvent(e)}}>
                        <CloseBtnImg src={SearchPrevBtn}/>
                    </ClosePreBtn>
                    <CloseTitle>{t("CLOSE_GROUP")}</CloseTitle>
                </CloseTopWrap>

                {/* 그룹 설정 */}
                { groupDetailStore.group ?
                    <CloseTabWrap>
                        <CloseTabContentWrap>
                            <GroupCloseSetting group={groupDetailStore.group} onSubmit={updateGroupInfo} />
                        </CloseTabContentWrap>
                    </CloseTabWrap> : null
                }

        </CloseWrap>
    )
}

Close.defaultProps = {

}

export default observer(Close);