import styled from "styled-components";

export const AttachImageWrap = styled.div`
    width : ${({width})=>width ? width : ''};
    height : ${({height})=>height ? height : ''};
    position : relative;
    border-radius: 12px;
    background : ${({type})=> type==='upload' ? '#191E29' : ''};
    flex : none;
`

export const AttachImg = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 100%;
    height : 100%;
    border-radius: 12px;
    object-fit : contain;
}};
`

export const AttachImageHoverWrap = styled.div`
    width : 100%;
    height : 100%;
    border-radius: 12px;
    position : absolute;
    background : rgba(25, 30, 41, 0.4);
    top : 0;
    opacity : 1;
`

export const AttachImageIconWrap = styled.div`
    position : absolute;
    z-index : 2;
    top : 6px;
    right : 6px;
`

export const AttachImageMoreWrap = styled.div`
    width : 100%;
    height : 100%;
    border-radius: 12px;
    position : absolute;
    background : rgba(25, 30, 41, 0.4);
    top : 0;
    display : flex;
    justify-content : center;
    align-items : center;
`

export const AttachImageMoreText = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color : #fff;
`
