import {observer} from "mobx-react";
import {useEffect} from "react";
import routes from "../../constants/routes.json";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import ProfileSetting from "../../components/presentationals/profile/ProfileSetting";
import ProfileItems from "../../components/presentationals/profile/ProfileItems";
import ProfileComment from "../../components/presentationals/profile/ProfileComment";
import ProfileBookmark from "../../components/presentationals/profile/ProfileBookmark";
import ProfileMyGroups from "../../components/presentationals/profile/ProfileMyGroups";

const Profile = (props) => {

    const location = useLocation();
    const history = useHistory();

    const {userStore, interestStore} = props;
    // // 관심사
    // const [visibleEditInterestModal, setVisibleEditInterestModal] = useState(false);
    // const openEditInterestModal = () => {
    //     setVisibleEditInterestModal(true);
    // };
    // // 대표 스티커 변경
    // const [visibleEditStickerModal, setVisibleEditStickerModal] = useState(false);
    // const openViewAllStickerModal = () => {
    //     setVisibleEditStickerModal(true);
    // };
    // // 스티커 확인
    // const [visibleStickerDetailModal, setVisibleStickerDetailModal] = useState(false);
    // const [stickerId, setStickerId] = useState(undefined);
    // const openViewStickerDetailModal = (sticker) => {
    //     if(sticker.owned) {
    //         setStickerId(sticker.stickerId);
    //         setVisibleStickerDetailModal(true);
    //     }
    // };

    useEffect(() => {
        userStore.updateUserState();
    }, []);

    // 사용자 현황
    const openUserState = (target) => props.history.push({pathname: `${routes.PROFILE_ACTIVITY}/${target.type}`});

    // const createInterests = () => interestStore.interests.filter(item => userStore.interests.includes(item.interestId))
    //     .map((item) => <Chips key={`profile-interest-chip-${item.interestId}`}
    //                           type={'view'}>{item.interest}</Chips>);
    //
    // const createStickers = () => userStore.stickers.slice(0, 8).map((item) => <StickerItem
    //     key={`sticker-${item.stickerId}`}>
    //     <StickerImgWrap onClick={() => openViewStickerDetailModal(item)}>
    //         <StickerImg src={`${ServerConfig.default.IMG_URL}${item.imgId}`} className={!item.owned ? 'blur' : ''}/>
    //         {item.represent ? <StickerRepWrap><StickerRepTxt children={'대표'}/></StickerRepWrap> : undefined}
    //         {!item.owned ? <StickerLockWrap><StickerLockImg src={LockFilledIcon}/></StickerLockWrap> : undefined}
    //     </StickerImgWrap>
    //     <StickerTxt className={item.owned ? '' : 'lock'}>{item.owned ? item.sticker : '???'}</StickerTxt>
    // </StickerItem>);
    //
    // const createUserState = () => [
    //     {field: 'meetingInviteCount', name: '초대받은 그룹', type: 'invited'},
    //     {field: 'meetingJoinCount', name: '참여중인 그룹', type: 'joined'},
    //     {field: 'communication', name: '소통해요 내가 쓴 게시글/댓글', type: 'communication'},
    //     {field: 'meeting', name: '모여봐요 내가 쓴 게시글/댓글', type: 'meeting'},
    //     {field: 'bookmark', name: '북마크', type: 'bookmark'}].map(target =>
    //     <UserStateItem key={`user-state-${target.field}`} onClick={() => openUserState(target)}>
    //         <UserStateTitle>{target.name}<strong className={'count'}>{userStore.userState[target.field]}</strong></UserStateTitle>
    //         <UserStateIcon/>
    //     </UserStateItem>);

    return (
        <Switch>
            <Route
                path={routes.PROFILE_SETTING}
                render={()=> <ProfileSetting title = {'내 설정'} />}
            />
            <Route
                path={routes.PROFILE_ITEMS}
                render={()=> <ProfileItems title = {'작성 글'} />}
            />
            <Route
                path={routes.PROFILE_COMMENT}
                render={()=> <ProfileComment title = {'작성 댓글'} />}
            />
            <Route
                path={routes.PROFILE_BOOKMARK}
                render={()=> <ProfileBookmark title = {'즐겨 찾는 글'} />}
            />
            <Route
                path={routes.PROFILE_MY_GROUPS}
                render={()=> <ProfileMyGroups title = {'내 그룹'} />}
            />
        </Switch>

    )
}

export default observer(Profile);
