import styled, {css, keyframes} from "styled-components";

const open = keyframes`
    0%{
        clip-path: inset(0 0 0 100%);
    }
    100%{
        clip-path: inset(0 0 0 0);
    }
`

const close = keyframes`
    0%{
        clip-path: inset(0 0 0 0);
    }
    100%{
        clip-path: inset(0 0 0 100%);
    }
`

export const SearchFieldContainer = styled.div`

    height: 40px;
    position : relative;
    background: #FFFFFF;
    border: 1px solid #E5EBF0;
    border-radius: 12px;
    display : flex;
    padding : 6px 10px;
    align-items : center;
    width : ${({width})=>width ? width : '100%'};
    gap : 4px;
    
    &.focus {
        border: 1px solid #3A6AF6;
    }
`

export const SearchFieldWrap = styled.div`

    border-radius : 10px;
    // width : 100%;
    position : relative;
    border-radius : 10px;
    display : flex;
    // padding : 15px 20px 15px 60px;
    // background : ${({theme}) => theme.colors.gray_disabled};
    gap : 8px;
    align-items : center;
    text-align : right;
    clip-path: ${({dropdown})=>dropdown ? 'inset(0 0 0 100%)' : 'none'};
    
    ${({dropdown})=> {
        if(dropdown) {
            return css`
            
                padding : 8px 0;
                border-radius : 18px;
                overflow : hidden;
                
                &.open{
                    animation : ${open} 0.5s forwards;
                }

                &.close{
                    animation : ${close} 0.5s forwards;
                }
            `
        }
    }}
    
`

export const SearchFieldInputWrap = styled.div`
    display : flex;
    align-items : center;
    flex-grow : 1;
    
    ${({dropdown})=> {
        if(dropdown) {
            return css`
                margin : 0 32px 0 20px;
            `
        }
    }}
`

export const Input = styled.input`
    font-size: 14px;
    line-height: 20px;
    width : 100%;
    display : block;
    outline : none;
    border : none;
    flex-grow : 1;
    ::placeholder {
        color: ${({theme}) => theme.colors.gray_50};
        
        opacity: ${({isFocus, theme}) => {
            if(isFocus) return 0.5;
            return 1;
        }};
        
    }
  
   :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: ${({theme}) => theme.colors.gray_50};
        
       opacity: ${({isFocus}) => {
           if(isFocus) return 0.5;
           return 1;
       }};
   }
  
   ::-ms-input-placeholder { /* Microsoft Edge */
       color: ${({theme}) => theme.colors.gray_50};
       opacity: ${({isFocus}) => {
           if(isFocus) return 0.5;
           return 1;
       }};
   }
   
   -webkit-border-radius:0;
   -webkit-appearance:none;
   
`

export const DeleteButtonWrap = styled.div`
    opacity : ${({value})=>value==='' ? 0 : 1};
`

export const RecentSearchWrap = styled.div`
    z-index : 1;
    position : absolute;
    left : 0;
    top : 39px;
    margin-top : 5px;
    width : 100%;
    padding : 20px 20px;
    background : ${({theme})=>theme.colors.white};
    box-shadow: 0px 4px 40px rgba(117, 129, 141, 0.1);
    border-radius: 10px;
    border : ${({theme})=>`1px solid ${theme.colors.gray_stroke_50}`};
`

export const RecentSearchHeader = styled.div`
    margin-bottom : 20px;
    display : flex;
    justify-content : space-between;
`

export const RecentSearchHeaderTitle = styled.div`
    font-size: 14px;
    line-height: 20px;
    color : ${({theme})=>theme.colors.gray_50};
`

export const RecentSearchHeaderDeleteAll = styled.div`
    font-size: 12px;
    line-height: 17px;
    color : ${({theme})=>theme.colors.gray_50};
`

export const RecentSearchWords = styled.div`
    display : flex;
    flex-direction : row;
    flex-wrap : wrap;
    gap : 16px 13px;
    width : 100%;
`

export const RecentSearchWordWrap = styled.div`
    display : flex;
    justify-content : center;
    gap : 2px;
    padding-bottom : 8px;
    border-bottom : ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
    // margin-top : 16px;
    // flex : none;
    // white-space: nowrap;
    width : 100%;
    align-items : center;
    
`

export const RecentSearchWordText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex-grow : 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const EmptyRecentWords = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin : 0 auto;
    padding-bottom : 13px;
`
