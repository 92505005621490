import styled from "styled-components";

export const ImageModalContainer = styled.div`
    width : 100vw;
    height : 100vh;
    background-color : rgba(25,30,41,0.3);
    align-items : center;
    justify-content : center;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 5;
    display : ${({isOpen})=>isOpen ? 'flex' : 'none'};
    flex-direction : column;
`

export const ImageHeaderWrap = styled.div`
    width: 100%;
    height: 44px;
    background: #191E29;
    position: relative;
`

export const ImageHeaderNumber = styled.h2`
    width: 100%;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding: 10px 0px 10px 16px;
`

export const ImageSliderWrap = styled.div`
   background: #191E29;
   padding-top: 20%; 
   min-height: 325px;
   margin-left: -16px;
   margin-right: -16px;
`

export const ImageSlider = styled.div`
    width : 1018px;
    height : 543px;
    overflow : hidden;
    position : relative;
`

export const ImagesCnt = styled.div`
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #191E29;
    padding : 3px 16px;
    position : absolute;
    z-index : 1;
    background: #FFFFFF;
    border-radius: 14.5px;
    top : 20px; 
    right : 20px;
`

export const ImagesWrap = styled.div`
    position : relative;
    display : flex;
    transition : ${({transition})=>transition};
    transform : ${({currentIndex})=>`translateX(-${currentIndex * 1018}px)`};
`

export const ModalImageWrap = styled.div`
    width : 1018px;
    height : 543px;
    flex : none;
    background : black;
    text-align : center;
`

export const ModalImage = styled.img.attrs(props=>({
    src : props.src
}))`
    height: 100%;
    width: 100%;
    object-fit: contain;
}};
`

export const PrevButtonWrap = styled.div`
    display : ${({isActive})=>isActive ? 'block' : 'none'};
    position : absolute;
    left : -82px;
    top : 258px;
    z-index : 6;
`

export const NextButtonWrap = styled.div`
    display : ${({isActive})=>isActive ? 'block' : 'none'};
    position : absolute;
    right : -82px;
    top : 258px;
    z-index : 6;
`

export const DownloadButtonWrap = styled.div`
    background: #353C49;
    border-radius: 18.5px;
    padding: 8px 39px 9px;
    display : flex;
    align-items : center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color : #fff;
    gap : 10px;
`

export const ModalPreviewImages = styled.div`
    height : 93.71px;
    display : flex;
    gap : 10px;
`

export const ModalPreviewImageWrap = styled.div`
    width : 93.71px;
    height : 93.71px;
    position : relative;
`

export const ModalPreviewSelected = styled.div`
    border: 4px solid #191E29;
    border-radius: 12px;
    width : 100%;
    height : 100%;
    position : absolute;
`

export const ModalPreviewImage = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 100%;
    height : 100%;
    background : transparent;
    border-radius: 12px;
}};
`

export const CloseButtonWrap = styled.div`
    position: absolute;
    top: 3px;
    right: 16px;
    z-index: 11;
`