import { useEffect, useLayoutEffect, useContext, useRef, useState } from 'react';
import { BackHistoryContext } from './backHistoryProvider';

const useBackControl = (prefixKey) => {
    // load값
    const [load, setLoad] = useState(false);
    // 전역변수 isBack
    const backContext = useContext(BackHistoryContext).current;
    const key = prefixKey;

    if (!backContext.backHistory[key]) {
        //state 저장
        backContext.backHistory[key] = {
            scrollPos: 0,
            state: {},  // { [key] : any }
            ref: {}     // { [key] : any }
        }
    }

    //앞으로 갈경우 유지
    const useMount = (callback, deps) => {
        useEffect(() => {
            const isBack = backContext.isBack && Boolean(backContext.backHistory[key]);

            // 앞으로 갈경우
            if (!isBack) {
                callback();
            }
        }, [...deps]);
    }

    //뒤로 갈경우 유지
    const useActive = (callback, deps) => {
        useEffect(() => {
            const isBack = backContext.isBack && Boolean(backContext.backHistory[key]);

            //뒤로갈 경우
            if (isBack) {
                setTimeout(() => {
                    callback();
                }, 1);
            }
        }, [...deps]);
    }

    //처음 상태 값 유지
    const useRemState = (state, keyName) => {
        const memoryValue = backContext.backHistory[key]?.state[keyName] ?? state;

        const resultState = useState(memoryValue);

        if(load) (backContext.backHistory[key]).state[keyName] = resultState[0];

        return resultState;
    };

    //처음 ref 값 유지
    const useRemRef = (ref, keyName) => {
        const memoryValue = backContext.backHistory[key]?.ref[keyName]?.current ?? ref;

        const resultRef = useRef(memoryValue);

        if(load) (backContext.backHistory[key]).ref[keyName] = resultRef;

        return resultRef;
    };

    // 화면 업데이트 전 실행 ( 컴포넌트 랜더링 - 실행 - 화면 업데이트 )
    useLayoutEffect(() => {
        setLoad(true);
    }, []);

    // 화면 업데이트 후 실행 ( 컴포넌트 랜더링 - 화면 업데이트 -  실행 )
    useEffect(() => {
        const isBack = backContext.isBack && Boolean(backContext.backHistory[key]?.scrollPos);

        // 뒤로가기일시
        if (isBack) {
            document.querySelector("#scroll-box")?.scrollTo(0, backContext.backHistory[key]?.scrollPos ?? 0);
        }

        let scrollPos = 0;
        const saveScroll = () => {
            scrollPos = document.querySelector("#scroll-box")?.scrollTop || 0;
        };

        document.querySelector("#scroll-box")?.addEventListener('scroll', saveScroll);

        return () => {
            document.querySelector("#scroll-box")?.removeEventListener('scroll', saveScroll);
            (backContext.backHistory[key]).scrollPos = scrollPos;
        };
    }, []);

    return {
        useMount,
        useActive,
        useRemState,
        useRemRef
    };
};

export default useBackControl;