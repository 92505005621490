export default {
    colors: {
        /**
         * theme color
         */
        black : '#191E29',
        gray_100 : '#353C49',
        gray_50 : '#AEB7C0',
        gray_hover : '#DEE4EA',
        gray_disabled : '#F2F4F6',
        gray_stroke_100 : '#E5EBF0',
        gray_stroke_50 : '#F1F5F9',
        blue : '#7C9EFF',
        white : '#FFFFFF',
        red : '#FA4D4D'
    },

    breakpoints: {
        sm: 'screen and (max-width: 640px)',
        md: 'screen and (max-width: 768px)',
        lg: 'screen and (max-width: 1024px)',
        xl: 'screen and (max-width: 1280px)'
    },


}
