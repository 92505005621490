import styled from "styled-components";

export const EditProfileImgModalBody = styled.div`
  margin: 48px 0 28px 0;
`
export const EditProfileImgSelectedImgWrap = styled.div`
  margin: 0 0 56px 0;
  display: flex;
  justify-content: center;
`
export const EditProfileImgSelectedImg = styled.img.attrs(props => ({
    src: props.src
}))`
  border-radius: 70px;
  width: 140px;
  height: 140px;
`

export const EditProfileImgChoiceWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`
export const EditProfileImgChoiceItemWrap = styled.div`
  position: relative;
  width: 78px;
  height: 78px;
  background: #F2F4F6;
  border: 1px solid #E5EBF0;
  border-radius: 45px;
`
export const EditProfileImgChoiceFileIcon = styled.img.attrs(props => ({
    src: props.src
}))`
  margin: 23px;
  width: 32px;
  height: 32px;
`
export const EditProfileImgChoiceItem = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;
  border-radius: 45px;
`
export const EditProfileImgCheckedWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 30, 41, 0.4);
  border: 2px solid #000000;
  border-radius: 45px;
`
export const EditProfileImgCheckIcon = styled.img.attrs(props => ({
    src: props.src
}))`
  position: absolute;
  top: -3px;
  right: -3px;
`