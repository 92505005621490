import env from '../constants/env';
import PPTIcon from "../resources/icons/ppt.svg";
import WordIcon from "../resources/icons/wordfile.svg";
import PDFIcon from "../resources/icons/pdf.svg";
import ExcelIcon from "../resources/icons/excel.svg";
import ImageIcon from "../resources/icons/image.svg";
import FileIcon from "../resources/icons/files.svg";
import isEmpty from "is-empty";
import {getFileBlob} from "./api";
import ServerConfig from "../commons/config/ServerConfig";
import {fromBlob} from "file-type/browser";
import path from "path-browserify";

export const getIsImageFile = (fileName) => {

    if(fileName.toLowerCase().match(env.imgFileFormsReg) || env.imgFileForms.includes(fileName.toLowerCase())){
        return true;
    }
    return false;
}

export const getIsOtherFile = (fileName) => {

    if(getIsPDFFile(fileName)){
        return true;
    }else if(getIsWordFile(fileName)){
        return true;
    }else if(getIsExcelFile(fileName)){
        return true;
    }else if(getIsPPTFile(fileName)){
        return true;
    }else if(getIsZIPFile(fileName)){
        return true;
    }else if(getIsVideoFile(fileName)){
        return true;
    }
    return false;
}

export const getIsPDFFile = (fileName) => {

    if(fileName.toLowerCase().match(env.pdfFileFormsReg) || env.pdfFileForms.includes(fileName.toLowerCase())){
        return true;
    }
    return false;
}

export const getIsWordFile = (fileName) => {
    if(fileName.toLowerCase().match(env.wordFileFormsReg) || env.wordFileForms.includes(fileName.toLowerCase())){
        return true;
    }
    return false;
}

export const getIsExcelFile = (fileName) => {
    if(fileName.toLowerCase().match(env.excelFileFormsReg) || env.excelFileForms.includes(fileName.toLowerCase())){
        return true;
    }
    return false;
}

export const getIsPPTFile = (fileName) => {
    if(fileName.toLowerCase().match(env.pptFileFormsReg) || env.pptFileForms.includes(fileName.toLowerCase())){
        return true;
    }
    return false;
}

export const getIsZIPFile = (fileName) => {
    if(fileName.toLowerCase().match(env.zipFileFormsReg) || env.zipFileForms.includes(fileName.toLowerCase())){
        return true;
    }
    return false;
}

export const getIsVideoFile = (fileName) => {
    if(fileName.toLowerCase().match(env.mp4FileFormsReg) 
        || env.mp4FileForms.includes(fileName.toLowerCase())
        || fileName.toLowerCase().match(env.movFileFormsReg)
        || env.movFileForms.includes(fileName.toLowerCase())
    ){
        return true;
    }
    return false;
}

export const getExtIcon = (ext) => {
    const lowerExt = ext.toLowerCase();
    if (getIsPPTFile(lowerExt)) {
        return PPTIcon;
    } else if (getIsWordFile(lowerExt)) {
        return WordIcon;
    } else if (getIsPDFFile(lowerExt)) {
        return PDFIcon;
    } else if (getIsExcelFile(lowerExt)) {
        return ExcelIcon;
    } else if (getIsImageFile(lowerExt)) {
        return ImageIcon;
    }
    return FileIcon;
}

export const validateTotalFilesSize = (fileList=[]) => {
    let filesSize = 0;
    fileList.forEach(file=>filesSize+=file.size);
    if(filesSize>env.totalFileSize) return false;
    return true;
}

export const validateFileSize = (file={name : 'name', size : 0}) => {
    if(file.size>env.maxFileSizeByte) return false;
    return true;
}

export const validateTotalFilesLength = (fileList=[]) => {
    if(fileList.length>env.totalFileLength) return false;
    return true;
}

export const getDataTransfer = (fileList = []) => {
    const dataTransfer = new DataTransfer();
    fileList.forEach(file=>dataTransfer.items.add(file));
    console.log(dataTransfer.files);
    return dataTransfer.files;
}

// 다운로드 파일 이름을 추출하는 함수
export const extractDownloadFilename = (response) => {
    console.log(response);
    const disposition = response.headers["content-disposition"];
    const fileName = decodeURI(
        disposition
            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            .replace(/['"]/g, "")
    );
    return fileName;
};

/**
 *
 * @param size : byte size
 * @return {string} : KB, MB, GB, TB 단위로 return
 */
export const getByteSize = (size) => {

    const byteUnits = ["KB", "MB", "GB", "TB"];

    for (let i = 0; i < byteUnits.length; i++) {
        size = size / 1024;

        if (size < 1024) return size.toFixed(2) + byteUnits[i];
    }
};

/**
 *
 * @return {string} : 가능한 확장자 String 반환
 */
export const getStandardExtStr = () => {

    const extStr = [...env.imgFileForms, ...env.otherFileForms];

    return extStr.join(', ').toUpperCase();
}

/**
 *
 * @param files : FileObjects
 * @description file objects들을 file, image, fail file list로 return
 */
export const getAvailableFiles = async (newFiles) => {

    let fails = [];
    let imgs = [];
    let others = [];

    let _newFiles = [];

    // 비동기 for문을 돌리기 위한 데이터 가공
    for(let i=0;i<newFiles.length;i++){
        _newFiles.push(newFiles[i]);
    }

    for await (let newFile of _newFiles){

        let fail = {file : newFile, sizeCheck : true, extCheck : true, errorCheck : true};

        // 이미지 파일인 경우 : 파싱해서 체크하기
        // 그냥 파일인 경우 : 확장자만 체크하기

        const fileInfo = path.parse(newFile.name);
        // 파일 확장자 없는 경우 손상된 파일로 표시
        if(!fileInfo.name || !fileInfo.ext || newFile.size === 0) {
            fail = {...fail, errorCheck: false};
            fails.push(fail);
            continue;
        }else{
            // 1. 파일 용량 체크
            if(!validateFileSize(newFile)){
                fail = {...fail, sizeCheck : false}
            }
            // 2. 파일 이름의 확장자가 image인 경우, 실제로 이미지 파일인지 체크 해야함
            if(getIsImageFile(newFile.name)){
                await fromBlob(newFile).then(res=>{

                    if(!res) {
                        fail = {...fail, errorCheck: false};
                        fails.push(fail);
                        return;
                    }

                    const _fileInfo = path.parse(newFile.name);
                    let _newFile = new File([newFile], `${_fileInfo.name}.${res.ext}`,{type : res.mime});

                    // 만약 file name은 이미지인데, 실제로 이미지가 아니면
                    if(!getIsImageFile(_newFile.name)){
                        fail = {...fail, extCheck : false}
                    }else{
                        // 진짜 이미지면 이미지에 추가
                        if(fail?.sizeCheck) imgs = [...imgs, _newFile];
                    }
                });
            }else{
                // 둘다 아니기 때문에 확장자 문제
                if(!getIsOtherFile(newFile.name)) fail = {...fail, extCheck : false};
                else if(fail?.sizeCheck) others = [...others, newFile];
            }

            if(!fail?.sizeCheck || !fail?.extCheck) fails.push(fail);

        }

        // console.log(fileInfo);
        //
        //
        // await fromBlob(newFile).then(res=>{
        //     console.log(newFile);
        //     console.log(res);
        //     if(!res){
        //         // 파일 크기 0인 파일은 여기서 걸러짐 (이상있는 파일)
        //         fail = {...fail, errorCheck: false};
        //         fails.push(fail);
        //         return;
        //     }else{
        //
        //         const _fileInfo = path.parse(newFile.name);
        //         let _newFile = new File([newFile], `${_fileInfo.name}.${res.ext}`,{type : res.mime});
        //
        //         fail = {...fail, file : _newFile};
        //
        //         // 1. 파일 용량 체크
        //         if(!validateFileSize(_newFile)){
        //             fail = {...fail, sizeCheck : false}
        //         }
        //
        //         // 2. 확장자 체크
        //         if(!getIsImageFile(_newFile.name) && !getIsOtherFile(_newFile.name)){
        //             fail = {...fail, extCheck : false}
        //         }
        //
        //         if(!fail?.sizeCheck || !fail?.extCheck || !fail?.errorCheck) {
        //             fails.push(fail);
        //             return;
        //         };
        //
        //         // 나머지 업로드
        //         if(getIsImageFile(_newFile.name)){
        //             // 1. 이미지
        //             // 그렇지 않으면 news에 추가
        //             // @todo : same file에 대한 정의 필요
        //             imgs = [...imgs, _newFile];
        //
        //         }else{
        //             // 그렇지 않으면 other news에 추가
        //             others = [...others, _newFile];
        //         }
        //
        //     }
        // })
    }
    return {imgs,others,fails};
}

/**
 *
 * @param files
 * @param cb
 * @return {Promise<[]|*[]>}
 * @description api 요청으로 부터 가져온 file정보를 File Object list로 반환
 */

export const getFileObjs = async (files, cb) => {
    // 파일 구분하기
    if(isEmpty(files)) return [];

    let fileObjs = [];

    for(let i=0;i<files.length;i++){
        let file = files[i];
        await getFileBlob(`${ServerConfig.default.FILE_URL}${file?.fileId}`).then((blob)=>{
            const newFile = new File([blob],`${file?.fileOriginName}.${file?.fileExt}`);
            fileObjs.push(newFile);
        });
        // if(i===fileObjs.length-1) return fileObjs;
    }
    return fileObjs;
}

/**
 *
 * @param files@description api 요청으로 부터 가져온 file 정보를 image, other로 구분
 */
export const getFileList = (files) => {
    let imgs = [];
    let others = [];
    files?.forEach((file)=>{
        if(getIsImageFile(file?.fileExt)){
            imgs.push(file);
        }else{
            others.push(file);
        }
    });
    return {imgs, others};
}

export const checkFilesLength = (newFiles, attachedFiles) => {

    if(newFiles.length + attachedFiles.length > env.totalFileLength) return false;

    return true;

}