import {
    ModalBody,
    ModalBodyContent,
    ModalBodyContentInput,
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalCount,
    ModalCountLen,
    ModalCountWrap,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle,
    ModalWrapInner
} from "../global/ModalStyles";

import {Button, IconButton} from "../global/index";

import {useCallback, useEffect, useRef, useState} from "react";
import isEmpty from 'is-empty';
import {receiveResponse} from "../../../utils/api";
import {mentionTransformer, numberToText, removeBlank} from "../../../utils/textHandler";
import env from "../../../constants/env";
import {observer} from "mobx-react";
import CancelIcon from '../../../resources/icons/modal_cancel.svg';
import {useStores} from "../../../stores";


const GroupApplyModal = (props) => {

    // const {store} = useStores();
    const {store} = useStores();
    const {toastPopupStore} = store;

    const userStore = props.userStore;
    const modalPopupStore = props.modalPopupStore;
    const modalToastPopupStore = props.modalToastPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const onSubmit = props.onSubmit;

    //데이터
    const data = modalPopupStore;

    // 데이터 아이템
    const detailItem = data.detailItem;
    const page = data.page;

    // text 필드 ref
    const textRef2 = useRef();

    // 내용
    const [content, setContent] = useState('');
    // 게시글 등록, 수정 로딩 모달
    const [isLoading, setIsLoading] = useState(false);
    // 내용 포커스
    const [isContentFocus, setIsContentFocus] = useState(false);


    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    /**
     ************* 상단
     * x버튼 클릭시
     * @param e
     */
    const onCloseHandler = () => {
        /**
         * editing이면
         * 모달 팝업창 띄우기
         */
        if(getIsEditing()){
            //setOpenConfirmModal(true);
            modalToastPopupStore.initDefault(
                'modalAlert',
                '작성하던 가입 신청 글이 있어요.\n가입 신청을 취소하시겠어요?',
                env.modalOkButtonText,
                env.modalCanclButtonText,
                onCloseModalToastHandler
            );
            modalToastPopupStore.openModalPopup();
            return;
        }

        //window.History.Back 뒤로가기 (모달 window)
        goHistoryBack();
    }

    /**
     ************ 중간
     * 내용 (컨텐츠) 이벤트
     * @param e
     */
    const onChangeContent = (e) => {
        e.preventDefault();
        //내용
        setContent(e.target.value);
    }

    /**
     ********* 하단
     * 글쓰기 버튼
     * @param e
     */
    const onSubmitHandler = (e) => {

        let params = {
            groupId : detailItem?.groupId
        }

        addBoardItem(params, () => {
            setIsLoading(true);
        });
    }

    /**
     ****************** 팝업 토스트 열었을 시
     *
     * 팝업 토스트 닫기 함수
     * callBack
     * @param e
     */
    const onCloseModalToastHandler = () => {

        /**
         * OK(true)      ->   닫기
         * 아니오(false)  ->   팝업창 유지
         */
        if(modalToastPopupStore.isCancel){

            //window.History.Back 뒤로가기 (모달 window)
            goHistoryBack();
            return;
        }
    }


    /******************************************************************************************************************/


    /**
     * 함수
     */

    // 초기 세팅
    const initdata = () => {
        // 1. 초기화
        setContent('');
        setIsLoading(false);
    }

    // 게시물 등록 함수 API
    const addBoardItem = (params, onLoading) => {

        const formData = new FormData();
        formData.append('answer' , content);

        //onLoading중 처리
        if (onLoading) {
            onLoading();
        }

        receiveResponse(formData,`groups/${params.groupId}/apply-join`,'patch', {'Content-Type': 'multipart/form-data'})
            .then((res)=>{
                if(res.status===200) {
                    userStore.updateJoinGroups();
                    userStore.updateApplicatedGroups();
                    onSubmit();
                }else {
                    setIsLoading(false);
                    console.log(res);
                    toastPopupStore.openToastPopup('가입 신청에 실패했습니다.');
                }
            })
            .catch(e=>{
                setIsLoading(false);
                toastPopupStore.openToastPopup('가입 신청에 실패했습니다.');
                console.log(e)
            })

    }

    // 스크롤 함수
    const handleResizeHeight = useCallback(()=>{
        textRef2.current.style.height = 'auto';
        textRef2.current.style.height = textRef2.current.scrollHeight + 'px';
    },[]);

    // 제목, 내용, 이미지, 기타피일 여부
    const getIsEditing = () => {
        return !isEmpty(removeBlank(content));
    }


    /******************************************************************************************************************/

    /**
     * useEffrct
     */
    useEffect(()=>{

        if (data.isOpen) {
            // 1. 내용 스크롤 조정
            if (textRef2.current !== undefined) handleResizeHeight();
        }else {
            // 닫을 때 초기 데이터 (초기화시키기)
            initdata();
        }
    },[data.isOpen]);


    useEffect(()=>{
        // 모달창 닫기여부 설정
        if ( ( content !== '' && content !== undefined ) ) data.setOpenYnPopup(false, onCloseHandler);
        // 스크롤 생성
        handleResizeHeight();
    }, [content]);


    /******************************************************************************************************************/

    /**
     *
     * 컴포넌트
     * COMPONENT
     *
     *
     * @returns {JSX.Element}
     */

    //컨텐츠(내용) 컴포넌트
    const contentComponent = (
        <ModalBody focus={isContentFocus ? 'focus' : ''} disabled={mentionTransformer(content)?.length > env.applyContentMaxLen}>
            <ModalBodyContent page={page} onClick={e=>textRef2.current.focus()}>
                {/*<ScrollBox>*/}
                <ModalBodyContentInput
                    name={"mix"}
                    ref={textRef2}
                    placeholder={env.joinGroupPopupModalApplyContentText}
                    onChange={onChangeContent}
                    value={content || ''}
                    onFocus={e=>setIsContentFocus(true)}
                    onBlur={e=>setIsContentFocus(false)}
                    //defaultValue={defaultContentText}
                />
                {/*</ScrollBox>*/}
            </ModalBodyContent>
            <ModalCountWrap>
                <ModalCountLen
                    entered={content?.length || 0 !== 0}
                    disabled={
                        content?.length > env.applyContentMaxLen}
                >{numberToText(content?.length || 0)}</ModalCountLen>
                <ModalCount>/{numberToText(`${env.applyContentMaxLen}`)}</ModalCount>
            </ModalCountWrap>
        </ModalBody>
    )

    //모달 apply 컴포넌트 틀
    const modalApplyComponent = (
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalTitle>{env.joinGroupPopupModalApplyTitleText}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'} height={'32px'} onClick={onCloseHandler}
                                src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollBox>
                {/*내용 컴포넌트*/}
                <ModalWrapInner>
                    {contentComponent}
                </ModalWrapInner>
            </ModalScrollBox>

            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button
                    shape={'circle'}
                    type={'mobile'}
                    textStyle={{fontSize : '16px', lineHeight: "23px"}}
                    width={'100%'}
                    disabled={(isEmpty(removeBlank(content)) || content?.length > env.applyContentMaxLen)}
                    onClick={onSubmitHandler}
                >{env.joinGroupPopupModalApplyTitleText}</Button>
            </ModalBottomWrap>
        </ModalContainer>
    )


    // apply     : 가입 신청
    return (
        <>
            {modalApplyComponent}
        </>
    )
}

export default observer(GroupApplyModal);