import styled from "styled-components";

export const InvitingGroupWrap = styled.div`
    // background: #FFFFFF; 
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05); 
    // border-radius: 15px; 
    // padding: 16px 24px; 
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
`

export const InvitingGroupTitle = styled.h5`
    font-weight: 500; 
    font-size: 12px; 
    line-height: 17px; 
    color: #AEB7C0; 
`

export const InvitingGroupInfoWrap = styled.div`
    display : block;
`

export const InvitingGroupInfoUl = styled.ul`
    display: flex; 
    overflow-x: auto;
    padding-bottom: 10px;

`

export const InvitingGroupInfoLi = styled.li`
   
    & > a {
        display: flex;
        align-items: center; 
        position: relative; 
        background: #F1F5F9; 
        border-radius: 12px; 
        min-width: 310px;
        max-width: 310px; 
        width: max-content; 
        margin-top: 5px; 
        padding: 16px 40px 17px 16px; 
        margin-right: 10px;
    }
`

export const InvitingGroupInfoImg = styled.img`    
    width: 40px;
    height: 40px;
    background: #D9D9D9;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
    position: absolute;
`


export const InvitingGroupInfo = styled.dl`
    padding-left: 50px;
`

export const InvitingGroupName = styled.dt`
    width: 200px;
    font-weight: 700; 
    font-size: 12px; 
    line-height: 17px; 
    color: #353C49;
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
`

export const InvitingGroupCnt = styled.dd`
    font-weight: 500; 
    font-size: 10px; 
    line-height: 14px; 
    color: #75818D;
`

export const InvitingGroupInfoBtn = styled.img`    
    position: absolute; 
    top: 22px; 
    right: 16px;

`

export const InvitingGroupButtons = styled.div`
    display : flex;
    width : 100%;
    gap : 8px;
    margin-top : 10px;
`