import styled from "styled-components";

export const ReportModalBody = styled.div`
    padding : 24px 0 0 0;
`

export const ReportList = styled.div`
    display : flex;
    gap : 22px;
    flex-direction : column;
    margin-bottom : 20px;
`

export const ReportListItem = styled.div`
    
`

export const ReportText = styled.textarea`
    width : 100%;
    min-height: 123px;
    resize : none;
    background: #FFFFFF;
    border: ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
    border-radius: 10px;
    padding : 13px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color : ${({theme})=>theme.colors.black};
    outline : none;
    
    &.focus {
        border : 1px solid #3A6AF6;
    }
    
    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
    ::placeholder {
        color: ${({theme})=>theme.colors.gray_50};
    }
  
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${({theme})=>theme.colors.gray_50};
    }
  
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${({theme})=>theme.colors.gray_50};
    }
    
`

