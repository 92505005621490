import styled from "styled-components";

export const GroupModalWrap = styled.div`
    background : ${({theme})=>theme.colors.white};
    position : relative;
    width: 100%;
    height: 100%;
`

export const GroupModalBody = styled.div`
    height : 519px;
    margin-bottom : 16px;
`

export const GroupModalButtonWrap = styled.div`
    padding : 0 30px;
`

export const GroupModalSectionsWrap = styled.div`
`

export const GroupModalSection = styled.div`
    display : flex;
    flex-direction : column;
    gap : 10px;
`

export const GroupModalSectionPadding = styled.div`
    padding-top:10px
`

export const GroupModalNameInputWrap = styled.div`
    display : flex;
    padding : 14px 12px;
    border-radius: 12px;
    gap : 4px;
    
    border : ${({disabled})=> {
        if(disabled){
            return '1px solid #FF7171';
        }else{
            return '1px solid #E5EBF0';
        }
    }};
    
    &.focus {
        border : ${({theme, disabled})=> {
            if(disabled){
                return '1px solid #FF7171';
            }else{
                return '1px solid #3A6AF6';
            }
        }};
    }
    
`

export const GroupModalNameInput = styled.input`

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    flex-grow : 1;
    outline : none;
    border: 0 solid black;
    width: 100%;
    
    
    ::placeholder {
        color: #AEB7C0;
    }
  
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #AEB7C0;
    }
  
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #AEB7C0;
    }
   
`;

export const GroupModalNameCountWrap = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    flex : none;
    display : flex;
    color: #AEB7C0;
`

export const GroupModalCount = styled.div`
    color : ${({disabled, entered}) => {
        if(disabled) return '#FF7171';
        if(entered) return '#191E29';
        return '#AEB7C0';
    }};
`

export const GroupModalDetailCountWrap = styled.div`
    display : flex;
    justify-content : flex-end;
    padding : 0 12px 12px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    flex : none;
    display : flex;
    color: #AEB7C0;
`

export const GroupModalDetailInputWrap = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    border : ${({disabled})=> {
        if(disabled){
                return '1px solid #FF7171';
        }else{
            return '1px solid #E5EBF0';
        }
    }};
    
    &.focus {
        border : ${({theme, disabled})=> {
            if(disabled){
                return '1px solid #FF7171';
            }else{
                return '1px solid #3A6AF6';
            }
        }};
    }
    
`

export const GroupCheckboxWrap = styled.div`
    background: #FFFFFF;
    // border: 1px solid #EAEAEA;
    // border-radius: 10px;
    display : flex;
    gap : 20px;
`

export const GroupCheckbox = styled.div`
    display : flex;
    align-items : center;
    gap : 4px;
    flex : none;
`

export const GroupCheckboxDesc = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #AEB7C0;
`

// export const GroupModalName
export const GroupModalDetailInput = styled.textarea`
    color : #191E29;
    font-weight: 400;
    resize : none;
    height : 150px;
    width : 100%;
    font-size: 14px;
    line-height: 21px;
    padding : 13px 12px;
    outline : none;
    border: 0 solid black;
    border-radius: 12px;
    
    ::placeholder {
        color: ${({theme})=>theme.colors.gray_50};
    }
  
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${({theme})=>theme.colors.gray_50};
    }
  
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${({theme})=>theme.colors.gray_50};
    }
    
    overflow : overlay;
    
    &::-webkit-scrollbar {
        // width: 18px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
`

export const GroupModalImgs = styled.div`
    display : flex;
    gap : 10px;
    flex-wrap : wrap;
    place-content : flex-start;
    justify-content: center;

    &.img-setting{
        gap : 20px 20px;
        padding-top : 9px;
    }
    
    &.coverImg-setting{
        gap : 10px 10px;
        padding-top : 9px;
    }
`

export const GroupStickerItem = styled.div`
  width: 68px;
  text-align: center;
  box-sizing : border-box;
  
  &.img-setting{
    width : 100px;
  }
  
  &.coverImg-setting {
    width : 146px;
  }
  
  
`

export const GroupStickerImgWrap = styled.div`
  position: relative;
  text-align: center;
  width: 68px;
  height: 68px;
  border-radius: 6px;
  box-sizing : border-box;
  border : ${({border, theme})=>border ? `1px solid ${theme.colors.gray_stroke_100}` : 'none'};
  
  &.img-setting {
    width: 100px;
    height: 100px;
  }
  
  &.coverImg-setting{
    width : 146px;
    height : 70px;
    border: 1px solid #E5EBF0;
  }
 
`

export const GroupStickerSelectedWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 6px;
  border: ${({theme})=>`2px solid ${theme.colors.black}`};
  // background : red;
`

export const GroupStickerCheckIcon = styled.img.attrs(props => ({
    src: props.src
}))`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
`

export const GroupStickerImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position : absolute;
  left : 0;
`

export const GroupStickerAddImgWrap = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    border-radius: 6px;
    background : ${({attached})=>(attached ? "rgba(0,0,0,0.3)" : "none")};
    z-index : 2;
    position : absolute;
    top : 0;
    left : 0;
`

export const GroupStickerAddImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 36px;
  height: 36px;
  z-index : 1;
`