import styled from "styled-components";

export const CommentContainer = styled.div`
    display : 'flex';
    width : 100%;
    gap : 10px;
    align-items : flex-start;
    border-top : 1px solid #E5EBF0; 
    position : relative;
    padding :  ${({type})=>type==='reComment' ? '12px 20px 13px 19px' : '12px 20px 13px 16px'};
   
`

export const ReCommentImg = styled.img`
    width : 20px;
    height : 20px;
    flex : none;
`

export const CommentImgWrap = styled.div`
    width : 20px;
    height : 20px;
    flex : none;
`

export const CommentImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;
  border-radius : 100%;
`

export const CommentWrap = styled.div`
    // width : 100%;
    flex-grow : 1;
    overflow : hidden;
    box-sizing : border-box;
}};
`

// export const CommentWrap = styled.div`
//     // width : 990px;
//     width : 100%;
//     box-sizing : border-box;
//     // padding : 12px 30px;
//     position : relative;
//     border-bottom : ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
//
//     background : ${({upperCommentId, theme})=>{
//         if(upperCommentId) return '#F7F9FB';
//         return theme.colors.white;
//     }};
//     padding : ${({isModify, type})=>{
//         if(type==='board') {
//             if(isModify) return '12px 0px';
//             return '12px 30px';
//         }else if(type==='re'){
//             if(isModify) return '12px ';
//             return '12px 30px 12px 42px';
//         }
//         if(isModify) return '12px 0px';
//         return '12px 30px';
//     }};
// `

export const CommentContentWrap = styled.div`
    // padding: 12px 20px;
`

export const CommentContent = styled.div`
    padding: 8px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    word-break : break-all;
    text-align : ${({isCenter})=> isCenter ? 'center' : ''};
`

// export const CommentContent = styled.div`
//     padding : 4px 0 12px 0;
//     font-size: 16px;
//     font-weight : 400;
//     line-height : 23px;
//     white-space: pre-wrap;
//     word-break : break-all;
// `

export const CommentHeader = styled.div`
    display : flex;
    position : relative;
    font-size: 13px;
    line-height: 17px;
    align-items : center;
    gap : 5px;
`

export const CommentNickname = styled.div`
    font-weight: 700;
    color: #191E29;
`


export const CommentDot = styled.span`
    width: 2px;
    height: 2px;
    background: #000000;
    border-radius: 2px;
`

export const CommentCompany = styled.div`
    color: #AEB7C0;
`

export const CommentWriter = styled.div`
    background: #FFFFFF;
    /* primary */
    border: 1px solid #006AFE;
    border-radius: 11.5px;
    padding : 0 3px;
    font-size: 10px;
    line-height: 12px;
    box-sizing : border-box;
    color: #006AFE;
`

export const CommentMenuWrap = styled.div`
    z-index: 1;
    position : absolute;
    right : 15px;
    top : 10px;
    display : flex;
    flex-direction : column;
    align-items : flex-end;
`

export const CommentMenuButton = styled.div`
    border-radius: 30px;
    z-index : 2;
    
    // &:hover {
    //     background: #EAEAEA;
    // }
`

export const CommentMenu = styled.div`
    margin-top : 5px;
    z-index : 2;
`

export const CommentBottom = styled.div`
    padding-left: 15px;
    display : flex;
    gap : 20px;
    align-items: center;
`

export const CommentRe = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #AEB7C0;
    
    &.write {
         color: #3A6AF6;
    }
`

export const CommentLikeWrap = styled.div`
    display : flex;
    gap: 5px;
    align-items : center;
`

export const CommentLikeCnt = styled.div`
    font-size: 13px;
    line-height: 20px;
    color: #AEB7C0;
`

export const CommentDate = styled.div`
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    flex-grow : 1;
    text-align : right;
`

export const ReCommentWrap = styled.div`
    padding : 12px;
    background : #F7F9FB;
    border-bottom : ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
    display : ${({openRe}) => openRe ? 'block' : 'none'};
`

export const CommentFilesWrap = styled.div`
    display : flex;
    padding-top: 7px;
    flex-direction : column;
`

export const CommentFilesRow = styled.div`
    display : flex;
    gap : 5px 10px;
    // overflow-x: overlay;
    padding-bottom: 7px;
    flex-wrap : wrap;
`

export const CommentImage = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 100%;
    height : 100%;
`

export const WriterBadge = styled.span`
  padding: 3px 8px;
  background: #FFFFFF;
  border: 1px solid #E5EBF0;
  border-radius: 20px;

  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #191E29;
`