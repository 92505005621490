import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {setAxiosInterceptors} from "./api";
import {useStores} from "../stores";

export const AxiosInterceptor = () => {

    const history = useHistory();
    const {store} = useStores();

    useEffect(() => {
        setAxiosInterceptors(history, store);
    }, [])
    return null;
}