import {action, makeObservable, observable, runInAction} from "mobx";
import isEmpty from "is-empty";
import {receiveResponse} from "../utils/api";

class InterestStore {

    interests = []

    constructor() {
        makeObservable(this, {
            interests: observable,
            init: action,
            setInterests: action,
        });
    }

    init = async () => {
        if(isEmpty(this.interests)){
            const res = await receiveResponse({},'interest/interests','get');
            runInAction(()=>{
                console.log('InterestStore.interests', res.data);
                this.interests = [...res.data];
            });
        }
    }

    setInterests = (interests) => {
        this.interests = [...this.interests, ...interests];
    }
}

export default InterestStore;