import styled from "styled-components";

export const VoteContainer = styled.div`
    margin-top : 10px;
`

export const VoteContentWrap = styled.div`
    display : grid;
    gap : 10px;
    margin : 10px 0;
`

export const VoteRe = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #7C9EFF;
    text-align : left;
    display : flex;
    width : fit-content;
    align-items: center;
`

export const VoteBarWrap = styled.div`
    background: #F7F9FB;
    border-radius: 20px;
    position : relative;
    padding : 5px 10px 5px 10px;
    align-items : center;
`

export const VoteBarInner = styled.div`
    background: #DEE4EA;
    border-radius: 20px;
    position : absolute;
    left : 0;
    height : 100%;
    top : 0;
    width : ${({width}) => width};
`

export const VoteBarContentWrap = styled.div`
    display : flex;
    position : relative;    
    height: 22px;
`

export const VoteBarLeft = styled.div`
    display : flex;
    gap : 7px;
    flex-grow :1;
    align-items : center; 
`

export const VoteBarRight = styled.div`
    display : flex;
    flex : none;
    align-items : center;
    gap : 15px;
`

export const VoteBarItemName = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding-left: 6px;
`

export const VoteBarCount = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
`

export const VoteBarRate = styled.div`
    width: 40px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #191E29;
`