import {
    GroupMemberSettingContainer,
    GroupMembersList,
    GroupMembersSection
} from "./GroupMemberSettingStyles";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {useStores} from "../../../stores";
import SettingUser from "../global/SettingUser";
import {useTranslation} from "react-i18next";

const GroupMemberSetting = (props) => {

    /**
     * 그룹 부관리자, 일반멤버 메뉴 다름
     */
    const {t} = useTranslation();
    const {store} = useStores();
    const {userStore, toastPopupStore} = store;
    const location = useLocation();
    const history = useHistory();

    const {group, userList, onAppoint, onDismiss, onKick, onHandOver, isManager, isAssistant} = props;
    // 그룹장
    const [manager, setManager] = useState([]);
    // 그룹 관리자
    const [assistants, setAssistants] = useState([]);
    // 일반 멤버
    const [members, setMembers] = useState([]);

    const getUserList = (userList) => {

        let _manager = [];
        let _assistants = [];
        let _members = [];

        userList?.forEach(user=>{
            if(getBoolFromYn(user.managerYn)) _manager.push(user);
            else if(getBoolFromYn(user.assistantYn)) _assistants.push(user);
            else _members.push(user);
        });

        setManager(_manager);
        setAssistants(_assistants);
        setMembers(_members);
    }

    const onAppointHandler = () => {
        if(onAppoint && typeof onAppoint ==='function') onAppoint();
    }

    const onDismissHandler = () => {
        if(onDismiss && typeof onDismiss ==='function') onDismiss();
    }

    const onKickHandler = () => {
        if(onKick && typeof onKick ==='function') onKick();
    }

    const onHandOverHandler = () => {
        if(onHandOver && typeof onHandOver ==='function') onHandOver();
    }


    /**
     * =========================================================
     * render
     */

    /*그룹장*/
    const createManagerList = () => {
        // if(manager?.length===0) return;
        return(
            <GroupMembersSection>
                {/*<GroupMembersSectionTitle>{`그룹장 (${manager?.length})`}</GroupMembersSectionTitle>*/}
                <GroupMembersList>
                    {manager.map(user=> (
                        <SettingUser
                            key={`group-member-setting-manager-${user?.userId}`}
                            groupId={group?.groupId}
                            user={user}
                            isManager={isManager}
                            isAssistant={isAssistant}
                            onAppoint={onAppointHandler}
                            onDismiss={onDismissHandler}
                            onKick={onKickHandler}
                            onHandOver={onHandOverHandler}
                        />
                    ))}
                </GroupMembersList>
            </GroupMembersSection>
        )
    };

    /*그룹 관리자*/
    const createAssistantList = () => {
        // if(assistants?.length===0) return;
        return (
            <GroupMembersSection>
                {/*<GroupMembersSectionTitle>{`그룹 관리자 (${assistants?.length})`}</GroupMembersSectionTitle>*/}
                <GroupMembersList>
                    {assistants.map(user => (
                        <SettingUser
                            key={`group-member-setting-assistant-${user?.userId}`}
                            user={user}
                            groupId={group?.groupId}
                            isManager={isManager}
                            isAssistant={isAssistant}
                            onAppoint={onAppointHandler}
                            onDismiss={onDismissHandler}
                            onKick={onKickHandler}
                            onHandOver={onHandOverHandler}
                        />
                    ))}
                </GroupMembersList>
            </GroupMembersSection>
        )
    }

    /*그룹 멤버*/
    const createMembersList = () => {
        // if(members?.length===0) return;
        return (
            <GroupMembersSection>
                {/*<GroupMembersSectionTitle>{`그룹 멤버 (${members?.length})`}</GroupMembersSectionTitle>*/}
                <GroupMembersList>
                    {members.map(user => (
                        <SettingUser
                            key={`group-member-setting-member-${user?.userId}`}
                            user={user}
                            groupId={group?.groupId}
                            isManager={isManager}
                            isAssistant={isAssistant}
                            onAppoint={onAppointHandler}
                            onDismiss={onDismissHandler}
                            onKick={onKickHandler}
                            onHandOver={onHandOverHandler}
                        />
                    ))}
                </GroupMembersList>
            </GroupMembersSection>
        )
    }

    useEffect(()=>{
        getUserList(userList);
    },[userList]);

    return(
        <GroupMemberSettingContainer>
            {createManagerList()}
            {createAssistantList()}
            {createMembersList()}
        </GroupMemberSettingContainer>
    )
}

GroupMemberSetting.defaultProps = {
    group : null,
    onSubmit : null,
    onAppoint : null,
    onDismiss : null,
    onKick : null,
    onHandOver: null,
    userList : null
}

export default GroupMemberSetting