import {
    CardContainer,
    CardHeader,
    CardHeaderName,
    CardImage,
    CardImageCnt,
    CardImageCntWrap,
    CardImageWrap,
    CardWrap,
    Content,
    Option,
    OptionText,
    OptionTime,
    OptionWrap,
    Section,
    Title,
    TitleWrap,
    UserInfoWrap,
    OptionBookmark,
    TitleContentWrap,
    TitleContentImgWrap
} from "./CardStyles";
import BookmarkIcon from '../../../resources/icons/bookmark_gray.svg'
import VoteIcon from '../../../resources/icons/vote_in_group.svg'
import BookmarkFilledIcon from '../../../resources/icons/bookmark_filled.svg'
import MoreImageIcon from '../../../resources/icons/more_image.svg'
import UnLikeIcon from '../../../resources/icons/unlike.svg'
import LikeIcon from '../../../resources/icons/like_filled_red.svg'
import CommentIcon from '../../../resources/icons/comment.svg'
import ViewIcon from '../../../resources/icons/view_black.svg'
import {ServerConfig} from "../../../commons/config";
import {getDateFrom} from "../../../utils/dateHandler";
import {Highlighter} from "../../../utils/textHandler";
import isEmpty from 'is-empty';
import {useEffect, useState} from "react";
import {IconButton, UserInfoTime} from "./index";
import Img from "./Img";
import {useStores} from "../../../stores";
import {getFileList} from "../../../utils/fileHandler";
import {receiveResponse} from "../../../utils/api";
import EmptyIcon from "../../../resources/icons/attach_img_gray.svg";
import routes from "../../../constants/routes.json";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";

const Card = (props) => {

    /**
     * @todo
     *
     */

    /**
     *
     * @type {string} : board, group-board, group, search, comment
     */
    const { type, item, onClick, highlight, onClickPin, isInvited, onAccept, onDecline, isSearch, showBoard = false, cursor = true, isVote } = props;
    const {store} = useStores();
    const {eventStore, toastPopupStore, defaultImageStore} = store;
    const {t} = useTranslation();
    // 데이터 동기화 이벤트 처리를 위한 object
    const e = eventStore.e;
    const history = useHistory();

    // item state
    const [isPined, setIsPined] = useState(type==='group' ? item.pinDate : false);
    const [showPin, setShowPin] = useState(false);
    const [isAttachFile, setIsAttachFile] = useState(false);
    const [itemState, setItemState] = useState(item);

    // 북마크 여부
    const [isBookmark, setIsBookmark] = useState(item.bookmarkDate);

    // img, other 파일이 구분된 state
    const [files, setFiles] = useState(getFileList(item?.fileInfoList));

    const onClickHandler = (e) => {
        if(onClick && typeof onClick === 'function'){
            onClick();
        }
    }

    // 카드 부분의 헤더를 누르는 경우, 그룹 페이지로 이동
    const onClickHeader = (e) => {
        e.stopPropagation();
        if(item && item?.group) history.push(`${routes.GROUPS}/${item.group.groupId}`);
    }

    const onClickPinHandler = (e) => {
        if(onClickPin && typeof onClickPin === 'function'){
            e.preventDefault();
            e.stopPropagation();
            onClickPin();
        }
    }

    const onAcceptHandler = (e) => {
        if(onAccept && typeof onAccept === 'function') {
            e.preventDefault();
            e.stopPropagation();
            onAccept();
        }
    }
    const onDeclineHandler = (e) => {
        if(onDecline && typeof onDecline === 'function') {
            e.preventDefault();
            e.stopPropagation();
            onDecline();
        }
    }

    const onMouseOver = (e) => {
        setShowPin(true);
    }

    const onMouseLeave = (e) => {
        setShowPin(false)
    }

    // 북마크 api
    const toggleBookmark = (event) => {
        event.stopPropagation();
        //커서 투루일때만
        if (cursor) {
            let route = `items/${item.itemId}`;
            if (isBookmark) route = `${route}/unbookmark`;
            else route = `${route}/bookmark`;
            receiveResponse({}, route, 'patch').then((res) => {
                if (res.status === 200) {
                    if (isBookmark) {
                        toastPopupStore.openToastPopup(t('UN_BOOKMARK_ITEM_COMPLETE'));
                        setIsBookmark(false);
                    } else {
                        setIsBookmark(true);
                        toastPopupStore.openToastPopup(t('BOOKMARK_ITEM_COMPLETE'));
                    }

                    // e.emit('card-update',{itemId : item?.itemId}); // groupItems에 이벤트 발행
                }
            })
        }
    }

    // <게시글> 상세조회 API
    const getDetails = () => {
        const _itemId = itemState.itemId;
        receiveResponse({}, `items/${_itemId}`,'get')
            .then((res)=>{
                if(res.status===200){
                    if(!res.data) throw new Error('no items');
                    setItemState(res.data);
                }
            })
            .catch((e)=>{
                console.log()
            })
    }

    // <이벤트> 이벤트 리스너 등록
    const createEventListener = () => {
        // 좋아요, 북마크, 댓글 같이 업데이트만 필요한 경우
        // card 에서만 이벤트 리스닝
        e.removeAllListeners(`update-${itemState.itemId}`,()=>{});
        e.on(`update-${itemState.itemId}`, (args)=>{
            const _event = args.event;
            if(itemState.itemId === args.itemId){
                switch (_event){
                    case 'LIKE_CHANGE' :
                    case 'BOOKMARK_CHANGE' :
                        setItemState(args.post);
                        break;
                    case 'MODIFY_ITEM' :
                        getDetails();
                        // 공지글일 경우 Group에서 공지 업데이트를 위해 이벤트 전송
                        if(getBoolFromYn(itemState.noticeYn)) e.emit('update-notice')
                    case 'ADD_COMMENT' :
                        getDetails();
                }
            }
        })
    }

    useEffect(()=>{
        setIsPined(item.pinDate);
        setIsBookmark(item.bookmarkDate);
        return () => {
            e.removeAllListeners(`update-${itemState.itemId}`,()=>{});
        }
    },[]);

    useEffect(()=>{
        setItemState(item);
        setIsBookmark(item.bookmarkDate);
        setFiles(getFileList(item?.fileInfoList))

        createEventListener();

    },[item]);

    useEffect(()=>{
        setIsBookmark(itemState.bookmarkDate);
        setFiles(getFileList(itemState.fileInfoList));
    },[itemState]);

    return(
        <CardContainer
            type={type}
            onClick={onClickHandler}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            isSearch={isSearch}
            isInvited={isInvited}
        >
            <CardWrap cursor={cursor}>
                {/* 전체보기에서는 그룹 사진, 이름*/}
                {
                    type === 'all' ?
                        <CardHeader onClick={onClickHeader}>
                            {
                                defaultImageStore.defaultGroupImgs ?
                                    <Img
                                        wrapStyle={{width : '24px', height : '24px', borderRadius : '6px'}}
                                        src={
                                            defaultImageStore.defaultGroupImgs.findIndex(img=>img === itemState.group?.imgId)!==-1 ?
                                                `${ServerConfig.default.IMG_URL}${itemState.group?.imgId}` :
                                                `${ServerConfig.default.IMG_THUMBNAIL_URL}${itemState.group?.imgId}`
                                        }
                                    /> : null
                            }
                            <CardHeaderName>{itemState.group?.groupName}</CardHeaderName>
                            <OptionBookmark>
                                <IconButton
                                    width={'24px'}
                                    height={'24px'}
                                    src={isBookmark ? BookmarkFilledIcon : BookmarkIcon}
                                    onClick={e=>toggleBookmark(e)}
                                />
                            </OptionBookmark>
                        </CardHeader> : null
                }
                {/* 게시글에 대한 내용 wrap*/}
                <Section type={type}>
                    <TitleContentImgWrap>
                        <TitleContentWrap>
                            {/*타이틀*/}
                            <TitleWrap>
                                {isVote ? <IconButton src={VoteIcon} wrapperStyle={{'margin-right' : '6px'}} /> : null}
                                <Title isSearch={!isEmpty(highlight)}>
                                    {
                                        isEmpty(highlight) ? itemState.title :
                                            <Highlighter
                                                text={itemState.title}
                                                highlight={highlight}
                                                type={"title"}
                                            />
                                    }
                                </Title>
                            </TitleWrap>
                            {/*본문*/}
                            <Content isSearch={!isEmpty(highlight)}>
                                {
                                    isEmpty(highlight) ?
                                        itemState.content :
                                        <Highlighter
                                            text={itemState.content}
                                            highlight={highlight}
                                            type={"content"}
                                        />
                                }
                            </Content>
                        </TitleContentWrap>
                        {files.imgs.length !== 0 ?
                            <CardImageWrap>
                                <CardImage
                                    src={`${ServerConfig.default.THUMBNAIL_URL}${files.imgs[0].fileId}`}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = EmptyIcon;
                                    }}
                                />
                                {
                                    files.imgs.length > 1 ?
                                        <CardImageCntWrap>
                                            <IconButton width={'20px'} height={'20px'} src={MoreImageIcon}/>
                                            <CardImageCnt>{files.imgs.length - 1}</CardImageCnt>
                                        </CardImageCntWrap> : null
                                }
                            </CardImageWrap>
                            : null
                        }
                        {
                            type !== 'all' ?
                                <OptionBookmark>
                                    <IconButton
                                        width={'24px'}
                                        height={'24px'}
                                        src={isBookmark ? BookmarkFilledIcon : BookmarkIcon}
                                        onClick={e=>toggleBookmark(e)}
                                    />
                                </OptionBookmark> : null
                        }
                    </TitleContentImgWrap>
                    <UserInfoWrap>
                        <UserInfoTime
                            regUserImgId={itemState.regUserImgId}
                            nickname={itemState.nickname}
                            companyName={itemState.companyName}
                            type={'group-card'} showTime={false}
                        />
                    </UserInfoWrap>
                    <OptionWrap>
                        <Option>
                            <IconButton width={'20px'} height={'20px'} src={itemState.likesDate ? LikeIcon : UnLikeIcon}/>
                            <OptionText>{itemState.likesCount}</OptionText>
                        </Option>
                        <Option>
                            <IconButton width={'20px'} height={'20px'} src={CommentIcon}/>
                            <OptionText>{itemState.commentCount}</OptionText>
                        </Option>
                        {/*<Option>*/}
                        {/*    <IconButton width={'20px'} height={'20px'} src={ViewIcon}/>*/}
                        {/*    <OptionText>{itemState.viewCount}</OptionText>*/}
                        {/*</Option>*/}
                        <OptionTime>{getDateFrom(itemState.createDate)}</OptionTime>
                    </OptionWrap>
                </Section>
            </CardWrap>
        </CardContainer>
    )
}

Card.defaultProps = {
    type : 'board', // board(그룹 게시판 용), all(전체 모아보기 용)
    item : {
        "anonymousYn": "Y",
        "attachYn": "Y",
        "groupId": "string",
        "commentCount": 0,
        "companyId": "string",
        "content": "string",
        "createDate": "2022-06-05T10:17:32.194Z",
        "deleteDate": "2022-06-05T10:17:32.194Z",
        "itemId": "string",
        "keyword": "string",
        "likesCount": 0,
        "likesDate": "2022-06-05T10:17:32.194Z",
        "modUserid": "string",
        "modifyDate": "2022-06-05T10:17:32.194Z",
        "nickname": "string",
        "noticeYn": "Y",
        "qnaYn": "Y",
        "regDate": "2022-06-05T10:17:32.194Z",
        "regUserid": "string",
        "reportCount": 0,
        "reportDate": "2022-06-05T10:17:32.194Z",
        "thumbFileInfo": {
            "commentId": "string",
            "createDate": "2022-06-05T10:17:32.194Z",
            "fileDelComYn": "Y",
            "fileDelYn": "Y",
            "fileExt": "string",
            "fileId": "string",
            "fileName": "string",
            "fileOrder": 0,
            "fileOriginName": "string",
            "fileSize": 0,
            "fileThumbExt": "string",
            "fileThumbName": "string",
            "itemId": "string",
            "modUserid": "string",
            "modifyDate": "2022-06-05T10:17:32.194Z",
            "regUserid": "string"
        },
        "title": "string",
        "topicId": "string",
        "viewCount": 0,
        "board":{},
    },
    onClick : null,
    onClickPin : null,
    highlight : '',
    isUsePin : false,
    isSearch : false,
    isVote : false // 투표 글인지
}

export default observer(Card);