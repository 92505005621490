import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {observer} from "mobx-react";
import routes from "../constants/routes.json"
import {AxiosInterceptor} from "../utils/axiosInterceptor";
import {AppBridge} from '../utils/appBridge'

import Layout from "../layout/Layout";
import AuthRoute from "./AuthRoute";
import Firebase from "../components/presentationals/global/Firebase";
import Auth from "../pages/auth/Auth";
import AuthError from "../pages/auth/error/error";
import Profile from "../pages/profile/Profile";
import Home from "../pages/home/Home";
import Item from "../pages/item/Item";
import FamilyEvent from "../pages/web/FamilyEvent";
import Groups from "../pages/groups/Groups";
import GroupItems from "../pages/groupItems/GroupItems";
import Group from "../pages/group/Group";
import Activity from "../pages/activity/Activity";
import Invite from "../pages/invite/Invite";
import AppLink from "../utils/appLink";
import BackHistoryProvider from "../utils/backHistoryProvider";
import GroupSetting from "../pages/groupSetting/GroupSetting";
import GroupInformation from "../pages/groupInformation/GroupInformation";
import GroupSettingInformation from "../pages/groupSetting/information/Information";
import GroupSettingApply from "../pages/groupSetting/apply/Apply";
import GroupSettingClose from "../pages/groupSetting/close/Close";
import Notification from "../pages/notification/Notification";
import {useStores} from "../stores";
import {useTranslation} from "react-i18next";
import Show from "../pages/show/Show";

const Router = () => {

    const {store} = useStores();
    const {toastPopupStore} = store;
    const {t} = useTranslation();

    return(
        <BrowserRouter>
            <AxiosInterceptor/>        { /*토큰 정보*/ }
            <AppBridge/>               { /*앱 이벤트*/ }
            <Firebase/>                {/*파이어베이스 알림*/}
            <Switch>
                {/** 기본이면 home  **/}
                <Route
                    exact
                    path={"/"}
                    render={()=><Redirect to={routes.HOME} />}
                />
                {/** Link 페이지 **/}
                <Route
                    exact
                    path={`${routes.APPLINK}/*`}
                    render={(props)=> <AppLink {...props} />}
                />
                {/** 모바일 처음 페이지 **/}
                <Route
                    exact
                    path={routes.AUTH}
                    render={(props)=> <Auth {...props} />}
                />
                {/** 모바일 처음 페이지 **/}
                <Route
                    exact
                    path={routes.AUTH_ERROR}
                    render={(props)=> <AuthError {...props} />}
                />

                {/** 토큰 벨리데이트 체크  **/}
                <BackHistoryProvider>
                    <Layout>
                        <Switch>
                            <AuthRoute path={routes.HOME} exact Component={Home} />
                            <AuthRoute path={`${routes.HOME}/:id`} exact Component={Item} />
                            <AuthRoute path={routes.GROUPS} exact Component={Groups} />
                            <AuthRoute path={routes.GROUP_ITEMS} exact Component={GroupItems} />
                            <AuthRoute path={`${routes.GROUP_SETTING}/:id`} exact Component={GroupSetting} />
                            <AuthRoute path={`${routes.GROUP_INFORMATION}/:id`} exact Component={GroupInformation} />
                            <AuthRoute path={`${routes.GROUP_SETTING_INFORMATION}/:id`} exact Component={GroupSettingInformation} />
                            <AuthRoute path={`${routes.GROUP_SETTING_APPLY}/:id`} exact Component={GroupSettingApply} />
                            <AuthRoute path={`${routes.GROUP_SETTING_CLOSE}/:id`} exact Component={GroupSettingClose} />
                            <AuthRoute path={`${routes.GROUPS}/:id`} exact Component={Group} />
                            <AuthRoute path={`${routes.PROFILE_ACTIVITY}/:type`} exact Component={Activity} />
                            <AuthRoute path={routes.INVITE} exact Component={Invite} />
                            <AuthRoute path={routes.NOTIFICATION} exact Component={Notification} />
                            <AuthRoute path={`${routes.PROFILE}/*`} exact Component={Profile} />
                            <AuthRoute path={routes.SHOW} exact Component={Show} />

                            {/*/!*웹용 경조사페이지*!/*/}
                            <AuthRoute path={routes.FAMILYEVENT} exact Component={FamilyEvent} />

                            {/* case 1. redirect */}
                            <Route render={()=> {
                                toastPopupStore.openToastPopup(t('PAGE_NOT_FOUND'));
                                return <Redirect to={routes.HOME}/>
                            }} />
                        </Switch>
                    </Layout>
                </BackHistoryProvider>
            }} />
            </Switch>
        </BrowserRouter>
    )
}

export default observer(Router);
