import {
    CommentBottom,
    CommentCompany,
    CommentContainer,
    CommentContent,
    CommentContentWrap,
    CommentDate, CommentDot,
    CommentFilesRow,
    CommentFilesWrap,
    CommentHeader,
    CommentImg,
    CommentImgWrap,
    CommentLikeCnt,
    CommentLikeWrap,
    CommentMenu,
    CommentMenuButton,
    CommentMenuWrap,
    CommentNickname,
    CommentRe,
    CommentWrap,
    CommentWriter,
    ReCommentImg
} from "./CommentStyles";
import {AttachFile, AttachImage, IconButton, List} from ".";
import UnLikeIcon from "../../../resources/icons/comment_unlike.svg";
import LikeIcon from '../../../resources/icons/like_filled_red.svg'
import MoreIcon from "../../../resources/icons/more.svg";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {ServerConfig} from "../../../commons/config";
import {getFileList, getIsVideoFile, getIsZIPFile} from "../../../utils/fileHandler";
import {getDateFrom} from "../../../utils/dateHandler";
import {useStores} from "../../../stores";
import {PostHighlighter} from "../../../utils/textHandler";
import DefaultImg from "../../../resources/icons/profile.png";
import {receiveResponse} from "../../../utils/api";
import SecretIcon from '../../../resources/icons/comment_secret.svg';
import ReCommenIconImage from '../../../resources/mImages/recomment.png';
import env from "../../../constants/env";
import {observer} from "mobx-react";
import {appWebViewFileViewEvent, webViewAppNativeEvent} from "../../../utils/appBridgeEvnet";
import {useTranslation} from "react-i18next";
import isEmpty from "is-empty";
import routes from "../../../constants/routes.json";
import {useLocation} from "react-router-dom";

const Comment = (props) => {
    /**
     * @todo
     * 좋아요, 댓글 수정, 댓글 삭제, 댓글 신고, 다운로드 api 개발
     * 댓글에서도 image modal 띄우기 삽가능
     * 삭제, 신고 댓글인 경우 bottom 안나오게
     * 다국어 언어팩으로 설정 필요
     */
    const location = useLocation();
    const {store} = useStores();
    const {toastPopupStore, userStore, modalToastPopupStore, modalPopupStore, modalDetailStore, authStore, eventStore, defaultImageStore} = store;
    const {t} = useTranslation();

    // 앱이벤트 준비
    const isAppReady = authStore.isAppReady;
    // 이벤트
    const e = eventStore.e;

    const {
        type,
        commentContent,
        nickname,
        userId,
        companyId,
        companyName,
        upperCommentId,
        createDate,
        deleteDate,
        commentLikesCount,
        likesDate,
        anonymousYn,
        commentSecretYn,
        myNickname,
        myUserId,
        commentId,
        reportDate,
        reportCount,
        regUserImgId,
        itemId,
        fileInfoList,
        hasChild,
        status,
        itemRegUserId,
        parentUserId,
        onSubmit,
        onDelete,
        onReport,
        onToggleLike,
        callback,
        selectedCommentId,
        onClickImg,
        page
    } = props;
    // 대댓글 오픈
    const [openRe, setOpenRe] = useState(false);
    // 메뉴 오픈
    const [openMenu, setOpenMenu] = useState(false);
    // 수정 모드 open
    const [isModify, setIsModify] = useState(false);
    // 신고 메뉴 오픈
    const [openReport, setOpenReport] = useState(false);
    // 좋아요 여부
    const [isLike, setIsLike] = useState(likesDate ? true : false);
    // 좋아요 카운트
    const [likeCnt, setLikeCnt] = useState(commentLikesCount);
    // 이미지 파일
    const [imgFiles, setImgFiles] = useState([]);
    // 이미지 파일 아닌 파일
    const [otherFiles, setOtherFiles] = useState([]);
    // 댓글 메뉴 (다른 사람 댓글)
    const menuItems = [
        {
            id:0,
            nameText:'신고하기',
            onClick : (e)=> {
                onReportComment()
                setOpenMenu(false);
            }
        }];
    // 댓글 메뉴 (내 댓글) @todo 삭제 모달 open
    const myMenuItems = [
        {id:0, nameText:'댓글 수정',
            onClick : (e)=> {
                                setIsModify(true);
                                setOpenMenu(false);
                                setOpenRe(false);

                                //댓글 수정할때 (comment, reComment)
                                if (!isModify) {

                                    if (callback && typeof callback === 'function') {
                                        const args = {
                                            type: type,
                                            isOpen: true,
                                            isModify: true,
                                            selectedCommentId: commentId
                                        }
                                        callback(props, args);
                                    }
                                    /*부모 댓글 쓰기 닫기*/
                                    // if(onCloseWriteComments && typeof onCloseWriteComments === 'function') {
                                    //     onCloseWriteComments();
                                    // }
                                }
                           }

        },
        {id:1,
            nameText:'댓글 삭제',
            onClick : (e)=> { if (!isModify) {
                    openDeleteModal(e);
                    setOpenMenu(false);
                }
            }
        }
    ];

    // 댓글 메뉴 (그룹장일 경우 삭제하기 권한 추가)
    const masterMenuItems = [
        {id:0, nameText : t('REPORT'), onClick : (e)=> {onReportComment(); setOpenMenu(false);}},
        {id:1, nameText : t('DELETE_COMMENT'), onClick : (e)=> { openDeleteModal(e); setOpenMenu(false);}}
    ];

    // 권한에 따른 메뉴 가져오기
    const getMenus = () => {
        const _group = props?.group;

        // 모여봐요 글
        if(_group && _group.groupId!==env.communicateGroupId){
            // 내 댓글인 경우 내 댓글 메뉴
            if(userStore.user.userId===userId) return myMenuItems;
            // 내 댓글이 아닌데 그룹장일 경우
            if(userStore?.user.userId === _group.managerId) return masterMenuItems;
            return menuItems;
        }else{
            // 소통해요 글
            if(userStore.user.userId===userId) return myMenuItems;
            else return menuItems;
        }
    }

    // 권한에 따른 메뉴 가져오기
    const getIsPageMenu = () => {
        if (page === 'web') {
            // 소통해요 글
            if(userStore.user.userId===userId) return true;
            else return false;
        }else if (page === 'app') {
            return true;
        }
    }

    /******************************************************************************************************************/


    /**
     * 01. 이벤트
     *
     */

    // 대댓글 오픈 함수
    const onClickRe = () => {

        // 수정일경우 막기
        if (isModify) return;

        // 대댓글 열려있을 떄 막기
        if (openRe) return;

        // 선택된댓글과 다를경우
        if (selectedCommentId !== commentId) {
            setOpenRe(true);
            if (callback && typeof callback === 'function') {
                const args = {
                    type: 'reComment',
                    isOpen: true,
                    isModify: false,
                    selectedCommentId: commentId
                }
                callback(props, args);
            }


            // /*부모 댓글 쓰기 닫기*/
            // if(onCloseWriteComments && typeof onCloseWriteComments === 'function') {
            //     onCloseWriteComments();
            // }
            // }else { //대댓글 닫을때
            //
            //     const event = "INSERT-RCOMMENT";
            //     /*부모 댓글 쓰기 열기*/
            //     if(callback && typeof callback === 'function') {
            //         callback();
            //     }
            // }
        }
    }

    // 댓글 메뉴 오픈 함수
    const openCommentMenu = (e) => {
        setOpenMenu(!openMenu);
    }

    // 리스트 blur callback
    const onBlurList = (e) => {
        setOpenMenu(false);
    }

    // 새 댓글 수정 취소
    const cancelModify = (e) => {
        setIsModify(false);
        /*부모 댓글 쓰기 열기*/
        // if(onOpenWriteComments && typeof onOpenWriteComments === 'function') {
        //     onOpenWriteComments();
        // }
    }

    // 대댓글 댓글 수정 취소
    const reCancelModify = (e) => {
        setOpenRe(false);
        /*부모 댓글 쓰기 열기*/
        // if(onOpenWriteComments && typeof onOpenWriteComments === 'function') {
        //     onOpenWriteComments();
        // }
    }

    // 댓글 신고 취소
    const onCancelReport = (e) => {
        toastPopupStore.openToastPopup(t('CANCEL_REPORT_COMMENT'));
    }

    // 댓글 신고 완료
    const onReportHandler = (e) => {
        modalPopupStore.closeModalPopup();
        if(onReport && typeof onReport === 'function') onReport();
        toastPopupStore.openToastPopup(t("COMPLETE_REPORT_COMMENT"));
    }

    // 댓글 신고하기 (팝업모달창 등록)
    const onReportComment = (e) => {
        if(reportDate){
            openAlertPopup(t('ALREADY_REPORTED_COMMENT'));
        }else{
            //모달 기본 설정n
            modalPopupStore.initAdd(
                "r",
                [],
                props,
                onCancelReport,
                onReportHandler
            );
        }
    }

    // 댓글 수정, 대댓글 callback
    const onSubmitHandler = () =>{
        if(onSubmit && typeof onSubmit === 'function') onSubmit();
        setIsModify(false);
        setOpenRe(false);

        /*부모 댓글 쓰기 열기*/
        // if(onOpenWriteComments && typeof onOpenWriteComments === 'function') {
        //     onOpenWriteComments();
        // }
    }

    // 댓글 좋아요, 좋아요 취소
    const onLikeChange = () => {
        let route = isLike ? `comments/${commentId}/unlike` : `comments/${commentId}/like`;
        receiveResponse({},route,'patch')
            .then(res=>{
                if(res.status===200){
                    if(res.data.result) {
                        setLikeCnt(res.data.count);
                        setIsLike(!isLike);
                        if(onToggleLike && typeof onToggleLike === 'function') onToggleLike();
                    };
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 댓글 삭제 modaltoastpopup open
    const openDeleteModal = (e) => {
        e.preventDefault();
        modalToastPopupStore.initDefault(
            'alert',
            t("CONFIRM_DELETE_COMMENT"),
            t("DELETE"),
            t("CANCEL"),
            onDeleteHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 이미 신고한 게시글일 경우, 팝업 띄우기
    const openAlertPopup = (title) => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            title,
            '',
            null,
            t("CONFIRM"),
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 댓글 삭제
    const onDeleteHandler = () => {
        if(!modalToastPopupStore.isCancel) return;
        receiveResponse({},`comments/${commentId}`,'delete')
            .then(res=>{
                if(res.status===200){
                    if(onDelete && typeof onDelete === 'function') onDelete();
                    toastPopupStore.openToastPopup(t('DELETE_COMMENT_COMPLETE'));
                };
            })
            .catch(e=>{
                console.log(e);
            });
    }

    // 이미지 모달창
    const openImageModal = (imgFiles, fileId, index) => {

        if (location.pathname === routes.FAMILYEVENT) {
            //web 경조사 이벤트
            const fileName = `${imgFiles[index]?.fileOriginName}.${imgFiles[index]?.fileExt.toLowerCase()}`;
            const filePath = `${ServerConfig.default.FILE_URL}${imgFiles[index]?.fileId}`;

            webViewAppNativeEvent(fileId, filePath, fileName);
        }else {
            //앱 이벤트
            modalToastPopupStore.initImages('image',imgFiles, fileId, index, ()=>{});
            modalToastPopupStore.openModalPopup();
        }
    }

    // 이벤트 리스터
    // <이벤트> 이벤트 리스너 등록
    const createEventListener = () => {
        // 댓글중, 수정중 단독 처리
        // e.removeAllListeners(`update-comment-${commentId}`,()=>{});
        e.on(`update-comment-${commentId}`, ()=>{
            // const _event = args.event;
            // //댓글이 같을 경우
            // if(commentId === args.commentId) {
            setOpenRe(false);
            setIsModify(false);


            // }
        })
    }

    /******************************************************************************************************************/


    /**
     * 02. 컴포넌트
     *
     * 2024.05.09
     * 숨김 처리도 삭제처럼 기능 추가
     * status === 'BLOCKED'
     *
     */

    // 댓글 내용 return
    const createCommentContent = () => {
        let myUserId = userStore.user.userId;
        if((status==='DELETED' || status==='BLOCKED' || reportCount >= env.reportCount) && hasChild) return t('DELETED_COMMENT');
        if(getBoolFromYn(commentSecretYn) && (myUserId!==userId && myUserId !== itemRegUserId && myUserId !== parentUserId)) return t('SECRET_COMMENT_CONTENT');
        return commentContent;
    }

    // 닉네임익명 return
    const createNickname = () => {
        if(getBoolFromYn(anonymousYn)){
            if(userStore.user.userId === userId) return t('ANONYMOUS_MINE');
            return t('ANONYMOUS');
        }
        return nickname;
    }

    /**
     * 2024.05.09
     * 숨김 처리도 삭제처럼 기능 추가
     * status === 'BLOCKED'
     * */
    const createSecretIcon = () => {
        let myUserId = userStore.user.userId;
        if((status==='DELETED' || status==='BLOCKED' || reportCount >= env.reportCount) && hasChild) return;
        if(getBoolFromYn(commentSecretYn) && (myUserId===userId || myUserId === itemRegUserId || myUserId === parentUserId)){
            return <IconButton width={'22px'} height={'22px'} src={SecretIcon} wrapperStyle={{padding : '2.5px', marginRight : '3.5px'}}/>
        }
    }

    /**
     * 2024.05.09
     * 숨김 처리도 삭제처럼 기능 추가
     * status === 'BLOCKED'
     * */
    const createFiles = () => {
        if(!props.fileInfoList) return null;
        else if(status==='DELETED' || status==='BLOCKED' || reportCount >=env.reportCount) return null;
        else if(getBoolFromYn(commentSecretYn) && !(myUserId===userId || myUserId === itemRegUserId || myUserId === parentUserId)) return null;
        return(
            !isEmpty(imgFiles) || !isEmpty(otherFiles) ?
                <CommentFilesWrap>
                    {!isEmpty(imgFiles) ? <CommentFilesRow>{createImgFiles()}</CommentFilesRow> : null}
                    {!isEmpty(otherFiles) ? <CommentFilesRow>{createOtherFiles()}</CommentFilesRow> : null}
                </CommentFilesWrap> : null
        )
    }

    const createImgFiles = () => {
        return imgFiles.map((imgFile, index)=>
            <AttachImage
                type={'download'}
                width={'100px'}
                height={'100px'}
                onClick={e=>openImageModal(imgFiles, imgFile?.fileId, index)}
                src={`${ServerConfig.default.THUMBNAIL_URL}${imgFile?.fileId}`}
            />
        )
    }

    const createOtherFiles = () => {
        return otherFiles.map(otherFile=>
            <AttachFile
                type={'download'}
                fileName={`${otherFile?.fileOriginName}.${otherFile?.fileExt.toLowerCase()}`}
                onClick={(e)=>onFileDownload(otherFile)}
            />
        )
    }


    const onClickImgHandler = (userId) => {
        if(onClickImg && typeof onClickImg === 'function') onClickImg(userId);
    }

    /******************************************************************************************************************/

    /**
     * 03. 함수
     *
     */

    const getFiles = (fileInfoList) => {
        let files = getFileList(fileInfoList);
        setImgFiles(files.imgs);
        setOtherFiles(files.others);
    }

    // 파일 다운로드
    const onFileDownload = (file) => {
        const fileName = `${file?.fileOriginName}.${file?.fileExt.toLowerCase()}`;
        if(getIsVideoFile(fileName)|| getIsZIPFile(fileName)){
            toastPopupStore.openToastPopup(t('CANNOT_OPEN_FILE_IN_MOBILE'));
            return;
        }
        const fileId = file?.fileId
        const filePath = `${ServerConfig.default.FILE_URL}${fileId}`;

        if (location.pathname === routes.FAMILYEVENT) {
            //web 경조사 이벤트
            webViewAppNativeEvent(fileId, filePath, fileName);
        }else {
            //앱 이벤트
            appWebViewFileViewEvent(isAppReady, fileId, filePath);
        }
    }

    /******************************************************************************************************************/


    /**
     * 06. uesEffect
     *
     */

    useLayoutEffect(()=>{
        getFiles(props?.fileInfoList);
    },[props.fileInfoList]);

    useEffect(()=>{
        setIsLike(props.likesDate ? true : false);
    },[props.likesDate]);

    useEffect(()=>{
        setLikeCnt(props.commentLikesCount);
    },[props.commentLikesCount])

    // 모달 닫힐 때 초기화
    useEffect(()=>{
            createEventListener();
        return() => {
            setOpenRe(false);
            setIsModify(false);
            e.removeAllListeners(`update-comment-${commentId}`,()=>{});
        }
    },[]);

    return(
        <>
            <CommentContainer type={type}>
                {
                    /**
                     * 2024.05.09
                     * 숨김 처리도 삭제처럼 기능 추가
                     * status === 'BLOCKED'
                     * */
                    deleteDate || reportCount >= env.reportCount || status === 'BLOCKED' ? null :

                        /*... 메뉴*/
                        !isModify && getIsPageMenu() ?
                            <CommentMenuWrap>
                                <CommentMenuButton>
                                    <IconButton src={MoreIcon} width={'24px'} height={'24px'} onClick={openCommentMenu}/>
                                </CommentMenuButton>
                                {
                                    openMenu ?
                                        <CommentMenu>
                                            <List
                                                items={getMenus()}
                                                onBlur={onBlurList}
                                                type={'thin'}
                                            />
                                        </CommentMenu> : null
                                }
                            </CommentMenuWrap> : null
                }

                {/*텍스트박스*/}
                <CommentWrap>

                    <CommentContentWrap>

                        {/* 헤더 */}
                        {
                            /**
                             * 2024.05.09
                             * 숨김 처리도 삭제처럼 기능 추가
                             * status === 'BLOCKED'
                             * */
                            deleteDate || reportCount >= env.reportCount || status === 'BLOCKED' ? null :
                                <CommentHeader>
                                    {/*아이콘 이미지*/}
                                    { type === 'reComment' ? <ReCommentImg src={ReCommenIconImage}/> : null }

                                    {
                                        defaultImageStore.defaultGroupImgs ?
                                            <CommentImgWrap onClick={getBoolFromYn(anonymousYn) ? null : e=>onClickImgHandler(userId)}>
                                                <CommentImg
                                                    src={
                                                        regUserImgId && !getBoolFromYn(anonymousYn) ?
                                                            defaultImageStore.defaultUserImgs.findIndex(img => img === regUserImgId) !== -1 ?
                                                                `${ServerConfig.default.IMG_URL}${regUserImgId}` :
                                                                `${ServerConfig.default.IMG_THUMBNAIL_URL}${regUserImgId}`
                                                            : DefaultImg}
                                                />
                                            </CommentImgWrap>
                                            : null
                                    }
                                    <CommentNickname>{createNickname()}</CommentNickname>
                                    <CommentDot/>
                                    <CommentCompany>{companyName}</CommentCompany>
                                    {!getBoolFromYn(anonymousYn) && userId === itemRegUserId ? <CommentWriter>글 작성자</CommentWriter> : null }
                                </CommentHeader>
                        }

                        {/* 내용 */}
                        <CommentContent isCenter={reportCount >= env.reportCount}>
                            {createSecretIcon()}
                            {PostHighlighter(createCommentContent())}
                            {createFiles()}
                        </CommentContent>

                    </CommentContentWrap>

                    {/* 대댓글, 좋아요 */}
                    <CommentBottom>
                        {
                            /**
                             * 2024.05.09
                             * 숨김 처리도 삭제처럼 기능 추가
                             * status === 'BLOCKED'
                             * */
                            deleteDate || reportCount >= env.reportCount || status === 'BLOCKED' ? null :
                                <CommentLikeWrap onClick={onLikeChange}>
                                    <IconButton width={'20px'} height={'20px'} src={!isLike ? UnLikeIcon : LikeIcon}/>
                                    <CommentLikeCnt>{likeCnt}</CommentLikeCnt>
                                </CommentLikeWrap>
                        }
                        {upperCommentId && !isModify ?
                            null :
                            <CommentRe className={openRe || isModify ? 'write' : ''} onClick={onClickRe}>
                                { upperCommentId && isModify ? '대댓글 수정중' :
                                    !upperCommentId && isModify ? '댓글 수정중' :
                                        openRe ? '대댓글 작성중' : '대댓글'}
                            </CommentRe>
                        }
                        <CommentDate>{getDateFrom(createDate)}</CommentDate>
                    </CommentBottom>

                </CommentWrap>
            </CommentContainer>


            {/*/!*댓글에 글쓰기*!/*/}
            {/*{ isModify || openRe ?*/}
            {/*    <CommentField*/}
            {/*        {...(isModify && !openRe ? props : null)}*/}
            {/*        isOpen={isModify || openRe ? true : false}*/}
            {/*        isModify={isModify}*/}
            {/*        onSubmit={onSubmitHandler}*/}
            {/*        cancelModify={cancelModify}*/}
            {/*        reCancelModify={reCancelModify}*/}
            {/*        type={openRe ? 'reComment' : type}*/}
            {/*        upperCommentId={!isModify ? commentId : upperCommentId}*/}
            {/*        itemId={itemId}*/}
            {/*    /> : null*/}
            {/*}*/}


        </>
    )
}

Comment.defaultProps = {
    "type" : "comment", // comment, Recomment
    "myUserId" : '',
    "commentId": "0000030002",
    "itemId": "0000029002",
    "upperCommentId": null,
    "userId": "solram81",
    "regUserImgId": "",
    "nickname": "최강롯데",
    "companyId": "LS",
    "commentContent" : "comment",
    "anonymousYn": "N",
    "commentSecretYn": "N",
    "deleteDate": null,
    "commentLikesCount": 0,
    "reportCount": 0,
    "status": "NORMAL",
    "likesDate": null,
    "reportDate": null,
    "fileInfoList": [
        {
            "fileId": "0000020002",
            "itemId": "0000029002",
            "commentId": "0000030002",
            "fileOrder": 1,
            "fileOriginName": "캡처1",
            "fileExt": "PNG",
            "fileName": "ae5474ec-38f2-4cee-bf1f-365a0ae22af8",
            "fileSize": 25359,
            "fileThumbName": "ae5474ec-38f2-4cee-bf1f-365a0ae22af8-thumb",
            "fileThumbExt": "jpg",
            "fileDelYn": "N",
            "fileDelComYn": "N",
            "downCount": 14,
            "nickname": "최강롯데",
            "regUserImgId": "0000000112",
            "regUserid": "solram81",
            "modUserid": "solram81",
            "createDate": "2022-11-03T10:20:55",
            "modifyDate": "2022-11-03T10:20:55",
            "itemTitle": "sdf"
        }
    ],
    "companyName": "코오롱베니트",
    "groupType": null,
    "groupName": null,
    "title": null,
    "createDate": "2022-11-03T10:20:55",
    "modifyDate": "2022-11-03T10:20:55",
    onSubmit : null,
    onReport : null,
    onToggleLike : null,
    itemRegUserId : '', // 댓글이 달린 item(게시글) id
    parentUserId : '',
    callback : null,
    selectedCommentId : null,
    page: 'app'
}

export default observer(Comment);