import styled from "styled-components";

export const GroupWrap = styled.div`
    position: relative; 
    padding: 20px 16px 13px 20px;
`

export const GroupTitle = styled.h3`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
`

export const GroupInvitingList = styled.div`
    padding: 10px 0px 0px 18px;
    // margin-top : 15px;
    // display : flex;
    // gap : 15px;
    // flex-direction : column;
`

export const GroupListInfo = styled.div`    
    display : flex;
    gap : 10px;
    align-items : center;
    padding : 0 3.5px 15px 3.5px;
    margin-top : 15px;
    position : relative;
    word-break : break-all;
`

export const GroupListInfos = styled.div`
    padding-bottom : 50px;
`

export const GroupInfo = styled.div`
    flex-grow : 1;
`

export const GroupName = styled.div`
    display : flex;
    gap : 4px;
`

export const GroupNameText = styled.div`
    width: 200px;
    font-weight: 700; 
    font-size: 14px; 
    line-height: 20px; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
`

export const GroupNameActivity = styled.div`
    font-weight: 500; 
    font-size: 12px; 
    line-height: 17px; 
    color: #AEB7C0;
    
    &.closing {
        color : #FF7171;
    }
`

export const GroupNameTxt = styled.span`
  position: relative;
  word-break: break-all;
`

export const GroupUnreadIcon = styled.div`
  position: absolute;
  top: -5px;
  left : 0px;
  width: 6px;
  height: 6px;
  background: #FF7171;
  border-radius: 3px;
`