import styled from "styled-components";

export const GroupFileWrap = styled.div`
    box-sizing: border-box;
    width: 343px;
    height: 95px;
    background: #FFFFFF;
    border: ${({theme})=>`1px solid ${theme.colors.gray_stroke_50}`};
    box-shadow: 2px 4px 20px rgba(117, 129, 141, 0.04);
    border-radius: 15px;
    padding : 15px 18px 15px 18px;
    display : flex;
    flex-wrap : nowrap;
    gap : 25px;
`

export const GroupFileIconWrap = styled.div`
`

export const GroupFileContent = styled.div`
    overflow : hidden;
    flex-grow : 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const GroupFileDownloadIconWrap = styled.div`
    display : flex;
    align-items : center;
    flex: none;
    justify-content: center;
    flex-direction: column;
`

export const GroupFileTitleWrap = styled.div`
    display : flex;
    padding-left : 6px;
    padding-bottom: 6px;
    

    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
    
    &.searching {
        color : #AEB7C0;
    }
`

export const GroupFileName = styled.div`
    overflow: hidden; 
    text-overflow: ellipsis; 
    
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical; 
    
    font-weight: 700;
    // font-size: 12px;ㅅ
    // line-height: 17px;
    // color: #191E29;
`
export const GroupFileExt = styled.div`
    flex : none;
    
    font-weight: 700;
    // font-size: 12px;
    // line-height: 17px;
    // color: #191E29;
`

export const GroupFileSubTitleWrap = styled.div`
    display : flex;
    gap : 16px;
    font-size: 12px;
    padding-left : 6px;
`

export const GroupFileVolume = styled.div`
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #75818D;
`

export const GroupFileNickname = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #75818D;
`