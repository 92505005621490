import styled from "styled-components";

export const InformationWrap = styled.div`
    width : 100%;
    background : transparent;
`

// export const GroupSettingContainer = styled.div`
//     width : 100%;
//     background : transparent;
//     height : calc(100vh - 87px);
// `

export const InformationTopWrap = styled.div`
    height: 43px;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const InformationPreBtn = styled.div`
    position: absolute;
    top: 10px; 
    left: 16px;
`

export const InformationBtnImg = styled.img`
`

export const InformationTitle = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #191E29;
    // padding : 40px 70px 30px 70px;
`

export const InformationSave = styled.div`
    position: absolute;
    top: 15px; 
    right: 20px;
    
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #191E29;
    
    &.on {
        color: #3A6AF6;
    }
`

export const InformationTabWrap = styled.div`

`

export const InformationTabContentWrap = styled.div`
    background: #FFFFFF;
    width : 100%;
    height : 100%;
    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
`
