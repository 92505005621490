import styled from "styled-components";

export const ItemContainer = styled.div`
    width : 100%;
    position : relative;
    
    &.popup {
        height: calc(100% - 44px);
    }
`

export const ItemWrap = styled.div`
    width : 100%;
    height : calc(100% - 70px);
    background: ${({theme})=>theme.colors.white};
    position : relative;
    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 24px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`

export const ContentContainer = styled.div`
    position: relative;
    display: block;
    font-size: 0px;
   
    &.popup {
        padding: 0px 19px 0px 16px;
        border-bottom: 1px solid #E5EBF0;
    }
    
    &.card{
        padding: 16px 0px 14px 16px;
        border-bottom: 1px solid #E5EBF0;
    }
`
// // content 영역
// export const ContentWrap = styled.div`
//     // border-bottom : 2px solid rgba(234, 234, 234, 1);
//     &.popup{
//         padding : 10px 0 20px 0;
//     }
// `


// title 영역
export const userInfoWrap = styled.div`
    position: relative; 
    margin-top: 10px; 
    padding-right: 16px;
    
    // &.popup{
    //     padding-bottom : 22px;
    //     border-bottom : 2px solid rgba(234, 234, 234, 1);
    //     margin-bottom : 10px;
    // }
}`

export const TitleTopicWrap = styled.div`
    display : flex;
    gap : 7px;
    align-items : center;
    margin-bottom : 10px;
`

export const TitleTopic = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #3A6AF6;
`

export const TitleVoteCount = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #3A6AF6;
`

export const TitleTextWrap = styled.div`
    white-space : pre-wrap;
    word-break : break-all;
`

export const GoBackButtonWrap = styled.div`
    position : fixed;
    left : 409px;
    top : 118px;
    z-index : 2;
`

export const TitleInfoWrap = styled.div`
    display : flex;
`

export const TitleText = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: ${({theme,isSearching})=>isSearching ? theme.colors.gray_50 : theme.colors.black};
    padding: 6px 40px 0px 0px;
    
    

    
    // > strong.count {
    //     margin-left: 12px;
    //     font-weight: 700;
    //     font-size: 24px;
    //     line-height: 33px;
    //     color: #7C9EFF;
    // }
    
    &.card {
        overflow: hidden; 
        text-overflow: ellipsis; 
        display: -webkit-box; 
        -webkit-line-clamp: 1; 
        -webkit-box-orient: vertical;
    }
`

export const MenuWrap = styled.div`
    position : absolute;
    display : flex;
    flex-direction : column;
    align-items : flex-end;
    right : 0;
    top : 6px;
`


export const MenuButton = styled.div`
    border-radius: 30px;
    z-index : 2;
    // &:hover {
    //     background: #EAEAEA;
    // }
`

export const Menu = styled.div`
    margin-top : 5px;
    z-index : 2;
`

export const ContentTitleAndSection = styled.div`
    display : flex;
`

export const Content = styled.div`
    width: 100%;
    position : relative;
`

export const ContentSectionWrap = styled.div`
`

export const VoteWrap = styled.div`
    &.card { 
        padding-right: 16px;
    }
`

export const ContentSection = styled.div`

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 6px 21px 0px 0px;
    margin-bottom: 12px;
    color: ${({theme,isSearching})=>isSearching ? theme.colors.gray_50 : theme.colors.black};
    white-space: pre-wrap;
    
    &.card {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break : break-all;
    }
    
    // &.more {
    //     height : ${({isMore})=>isMore ? '' : '46px'};
    //     overflow : ${({isMore})=>isMore ? '' : 'hidden'};
    //     // text-overflow : ${({isMore})=>isMore ? '' : 'ellipsis'};
    //     display: ${({isMore})=>isMore ? '' : '-webkit-box'};
    //     -webkit-line-clamp: ${({isMore})=>isMore ? '' : '2'};
    //     // -webkit-box-orient: ${({isMore})=>isMore ? '' : 'vertical'};
    // }
`

export const ContentMore = styled.div`

    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
`

export const ContentMoreText = styled.a`
    box-sizing : border-box;
    // &:hover {
    //     border-bottom : 1.5px solid #191E29;
    //     height : 23px;
    // }
`

export const ContentFilesWrap = styled.div`
    pointer-events: auto;
    display: flex;
    flex-direction: column;
`

export const ContentFiles = styled.div`
    display : flex;
    // overflow-x: auto;
    position : relative;
    gap : ${({image})=>image ? '10px' : '5px'};
    padding-bottom: 10px;
    margin-bottom: 2px;
    flex-wrap : wrap;

`
export const ContentOptions = styled.div`
    display : flex;
    //gap : 10px;
    position : relative;
    
    &.popup{
         padding-bottom: 20px;
    }
`

export const ContentOption = styled.div`
    flex : none;
    display : flex;
    gap : 4px;
    align-items : center;
    border-radius: 7px;
    padding-right: 16px;
    
    // &:hover{
    //     background: #F2F4F6;
    // }

`

export const BookmarkButton = styled.div`
    border-radius: 30px;
    position : absolute;
    
    &.card {
        right : 16px;
    }
    &.popup {
        right : 0px;
    }
    
    // &:hover {
    //     background: #EAEAEA;
    // }
`

export const ContentOptionText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
`

// 댓글 영역
export const CommentsWrap = styled.div`
    display : ${({isOpenComments}) => isOpenComments ? 'block' : 'none'};
     > :first-child {
        border-top: 0px;
    }
    
`

export const CommentsList = styled.div`
`

// export const Comments

// 추천글 영역



export const ItemMoreCommentWrap = styled.div`
    display : flex;
    height: 45px;
    justify-content : center;
    gap : 5px;
    padding : 13px;
   
    margin-left : ${({theme,re})=>re ? '120px' : ''};
    background: #F7F9FB;
    border-top: 1px solid #E5EBF0;
`

export const ItemMoreCommentText = styled.div`
    font-weight: 400;
    font-size: 14px;
    color : #75818D;
    line-height : 23px;
`

export const ItemMoreCommentCount = styled.span`
    font-weight: 700;
    color : #75818D;
`

export const SellContentWrap = styled.div`
    width: calc(100% - 16px);
    background: #F7F9FB;
    border-radius: 12px;
    padding : 10px 15px;
    margin-bottom: 7px;
`

export const SellContentRow = styled.div`
    display : flex;
    align-items : center;
    gap : 16px;
`

export const SellContentName = styled.div`
    width: 72px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
`

export const SellContentValue = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
`

export const AttachFilesMore = styled.div`
    width: 36px;
    height: 36px;
    background: #F7F9FB;
    border-radius: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
    padding : 9px 11px;
`

export const CommentFieldLoadingWrap = styled.div`
    position : absolute;
    width : 100%;
    height : 100%;
    top : 0;
    left : 0;
    border-radius: 10px;
`