import {useStores} from "../../../stores";
import {useHistory, useLocation} from "react-router-dom";
import {
    GroupApplyList,
    GroupApplyNoResultWrap,
    GroupApplySettingContainer,
    GroupApplyTitle
} from "./GroupApplySettingStyles";
import ApplyingUser from "../global/ApplyingUser";
import {NoResult} from "../global";
import NoPostImg from "../../../resources/icons/no_post.svg";

const GroupApplySetting = (props) => {

    const {store} = useStores();
    const {userStore, toastPopupStore} = store;
    const location = useLocation();
    const history = useHistory();

    const {group, applyingUserList, onSubmit, onAccept} = props;

    // 가입 신청한 user list 생성
    const createUserList = (applyingUserList) => {
        if (!applyingUserList) return;
        return applyingUserList.map((user)=><ApplyingUser user={user} group={group} onSubmit={onSubmit} onAccept={onAccept}/>)
    }

    return(
        <GroupApplySettingContainer>
            <GroupApplyTitle>
                {applyingUserList ? `미응답 가입 신청 (${applyingUserList.length})` : null}
            </GroupApplyTitle>
            <GroupApplyList>
                {
                    applyingUserList ?
                        applyingUserList.length > 0 ?
                            createUserList(applyingUserList) :
                            <GroupApplyNoResultWrap>
                                <NoResult src={NoPostImg} title={'미응답 가입 신청이 없어요.'}/>
                            </GroupApplyNoResultWrap>
                        : null

                }
            </GroupApplyList>
        </GroupApplySettingContainer>
    )

}

GroupApplySetting.defaultProps = {
    group : null,
    applyingUserList : null,
    onSubmit : null,
    onAccept : null
}

export default GroupApplySetting;