import {
    NotificationTopTitle,
    //NotificationButton,
    NotificationDelBtn,
    NotificationEmptyWrap,
    NotificationMenu,
    NotificationUnreadIcon,
    NotificationSettingWrap,
    NotificationSwitch,
    NotificationSwitchInput,
    NotificationSwitchSlider,
    NotificationSettingRight,
    NotificationSettingTitle,
    NotificationSettingDesc,
    NotificationSwitchWrap,
    NotificationTopicWrap,
    NotificationTopicTitle,
    NotificationTopicTitleWrap,
     NotificationTopicTitleSpan
} from "../notification/NoticeStyles";
import {NoResult} from "../../components/presentationals/global";
import isEmpty from "is-empty";
import EmptyNotificationIcon from "../../resources/icons/no_notification.svg";
import EmptyNoticeIcon from "../../resources/icons/no_notice.svg";
import NotificationMessage from "../../components/presentationals/global/NotificationMessage";
import {receiveResponse} from "../../utils/api";
import routes from "../../constants/routes.json";
import React, {useEffect, useRef, useState} from "react";
import {useStores} from "../../stores";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {getISOFormat} from "../../utils/dateHandler";
import {getBoolFromYn} from "../../utils/boolHandler";
import NoticeMessage from "../../components/presentationals/global/NoticeMessage";
import {useTranslation} from "react-i18next";
import {ThreeDots} from "react-loader-spinner";
import {appWebViewPushEvent} from "../../utils/appBridgeEvnet";
import {observer} from "mobx-react";

const Notification = (props) => {

    const {store} = useStores();
    const {userStore, toastPopupStore, modalDetailStore, groupDetailStore, authStore} = store;
    const isAppReady = authStore.isAppReady;
    const history = useHistory();
    const location = useLocation();
    // 다국어 처리에 대한 hook
    const {t} = useTranslation();

    // 공지 알림 푸시
    const [isNotification, setIsNotification] = useState(false);

    const [standDate,] = useState(getISOFormat());
    const [latestDate, setLatestDate] = useState(standDate);
    const [checkedDate, setCheckedDate] = useState(standDate);

    const listRef = useRef(null);
    const [list, setList] = useState([]);

    const noticeListRef = useRef(null);
    const [noticeList, setNoticeList] = useState([]);

    const [page, setPage] = useState(0);
    const [end, setEnd] = useState(false);

    // 텝 셀렉트 (0: 서비스알림, 1: 공지사항)
    const [activeTab, setActiveTab] = useState(0);
    // 로딩 여부
    const [isLoading, setIsLoading] = useState(true);

    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    const noticeButtonRef = useRef(null);
    const noticeMenuRef = useRef(null);

    /******************************************************************************************************************/


    /**
     * 01. 이벤트
     *
     */

    // 공지 리스트 모두 지우기
    const onClickDeleteBtn = (e) => {
        e.preventDefault();
        receiveResponse({}, 'notifications', 'delete').then(res => {
            setList([]);
        });
    }


    // 공지 리스트안에 클릭 이벤트
    const clickNotification = (e, item) => {
        // 읽음 처리
        receiveResponse({}, `notifications/${item.notificationId}/mark-as-read`, 'patch').then(res => {
            if (res.status === 200) {
                setList((list ?? []).map(n => n.notificationId === item.notificationId && !n.readDate ? {
                    ...n,
                    readDate: getISOFormat()
                } : n));
            }
        });
        // 알림별 처리
        const info = JSON.parse(item.info);
        const match = matchPath(location.pathname,{
            path : `${routes.GROUPS}/${info.groupId}`
        });
        switch (item.type) {
            case 'system.lift-constraint':
            case 'system.put-constraint' :
            case 'free.hide-item' :
            case 'free.hide-comment' :
            case 'free.hide-child-comment' :
            /* 2024.05.07
            * 소통해요
            * 알림 추가
            * 1.시스템 관리자가 게시글 삭제
            * 2.시스템 관리자가 댓글 삭제
            * 3.시스템 관리자가 대댓글 삭제
            * 4.게시글 신고로 인한 삭제
            * 5.댓글 신고로 인한 삭제
            * 6.대댓글 신고로 인한 삭제
            * */
            case 'free.manager-delete-item' :
            case 'free.manager-delete-comment' :
            case 'free.manager-delete-item' :
            case 'free.manager-delete-child-comment' :
            case 'free.reported-hide-item' :
            case 'free.reported-hide-comment' :
            case 'free.reported-hide-child-comment' :
                history.push(`${routes.HOME}`);
                break;

            case 'free.comment' :
            case 'free.comment-child' :
            case 'free.like-child-comment' :
            case 'free.like-comment' :
            case 'free.like-item' :

                modalDetailStore.init(
                    'c',             //'c', 'g' 타입
                    info.itemId,           // 아이템Id
                    ()=>{},     // 닫기
                    ()=>{},     // 삭제
                    ()=>{}      // 에러
                );
                return;

            case 'group-item.comment' :
            case 'group-item.comment-child' :
            case 'group-item.like-child-comment' :
            case 'group-item.like-comment' :
            case 'group-item.like-item' :
                if(match && match.isExact) groupDetailStore.init(info.groupId, userStore.user.userId);
                history.push({pathname : `${routes.GROUPS}/${info.groupId}`, state : {itemId : info.itemId}});
                break;

            case 'show.like-item' :
            case 'show.reported-hide-item' :
            case 'show.manager-delete-item' :
                history.push({pathname : routes.SHOW});
                break;

            default:
                if(match && match.isExact) groupDetailStore.init(info.groupId, userStore.user.userId);
                history.push(`${routes.GROUPS}/${info.groupId}`);
                break;
        }
    }

    // 알림 전체 읽음 처리
    const clickReadAll = () => {
        // 전체 읽음 처리
        receiveResponse({}, `notifications/mark-as-read`, 'patch').then(res => {
            if (res.status === 200) {
                setList((list ?? []).map(n => ({
                    ...n,
                    readDate: getISOFormat()
                })))
            };
        });
    }

    const clickNotice = (e, item) => {

        // 읽음 처리
        receiveResponse({}, `notices/${item.itemId}/mark-as-read`, 'patch').then(res => {
            if (res.status === 200) {
                setNoticeList((noticeList ?? []).map(n => n.itemId === item.itemId && !n.readDate ? {
                    ...n,
                    readDate: getISOFormat()
                } : n));

                modalDetailStore.init(
                    'n',             // n은 시스템 공지
                    item.itemId,           // 아이템Id
                    ()=>{},     // 닫기
                    ()=>{},     // 삭제
                    ()=>{}      // 에러
                );

            }
        });
    }

    // 공지사항 전체 읽음 처리

    const clickReadAllNotice = () => {
        // 안읽은 공지가 있을 경우만
        if(noticeList.filter(n=>!n.readDate).length > 0){
            receiveResponse({}, `notices/mark-as-read`, 'patch').then(res => {
                if (res.status === 200) {
                    setNoticeList((noticeList ?? []).map(n => ({
                        ...n,
                        readDate: getISOFormat()
                    })))
                };
            });
        }
    }

    // 그룹 게시글 삭제 이벤트
    // const onDeleteBoardItem = () => {
    //     setVisibleUnreadIcon(false);
    // }


    /******************************************************************************************************************/


    /**
     * 02. 함수
     *
     */

        // scroll 이 끝에 도착하면 page 증가
    const onScroll = () => {
            if (listRef.current) {
                const {scrollTop, scrollHeight, clientHeight} = listRef.current;
                if (scrollTop + clientHeight === scrollHeight) {
                    // 목록의 끝에 도달하지 않았을 경우에만 페이지를 증가
                    if (!end) {
                        setPage(page + 1);
                    }
                }
            }
        };

    const onClickSwitch = (e) => {

        e.preventDefault();

        //반대
        let mobilePushYn = isNotification ? 'F' : 'T';

        receiveResponse({},`user/change-mobile-push?mobilePushYn=${mobilePushYn}`,'patch')
            .then(res=>{
                if(res.status===200) {
                    setIsNotification(!isNotification);
                    // userStore user 초기화
                    userStore.updateUser();
                    //앱전달
                    appWebViewPushEvent(isAppReady, !isNotification);
                }
            })
            .catch(e=>{
                toastPopupStore.openToastPopup('알림 설정 변경을 실패했습니다.');
                console.log(e);
            })
    }

    const getUnReadIcon = (list) => {

        if(!list) return;
        const cnt = list.filter(n=>!n.readDate).length;
        if(cnt===0) return ;
        else if(cnt>0 && cnt < 1000) return (<NotificationUnreadIcon>{cnt}</NotificationUnreadIcon>);
        else return (<NotificationUnreadIcon>{'999+'}</NotificationUnreadIcon>);

    }

    const onClickNav = (e, index) => {

        if (index === activeTab) return;
        e.preventDefault();
        setActiveTab(index);
    }

    // 스크롤 초기화
    const initScroll = () => {
        document.querySelector("#scroll-box")?.scrollTo(0, 0);
    }

    /******************************************************************************************************************/


    /**
     * 03. 컴포넌트
     *
     */

    // 알림 리스트 생성
    const createNotification = () => {
            return list.map((n, i) => <NotificationMessage key={`notification-${i}`} item={n} onClick={clickNotification}/>);
        }

    // 공지 리스트 생성
    const createNotice = () => {
        return noticeList.map((n, i) => <NoticeMessage key={`notice-${i}`} item={n} onClick={e=>clickNotice(e,n)}/>);
    }

    // 리스트 컴포넌트
    const listComponent = (activeTab) => {

        // 서비스알림
        if (activeTab === 0) {
            return (
                !isLoading ?
                    <NotificationMenu ref={menuRef}>
                    {
                        isEmpty(list) ?
                            <NotificationEmptyWrap>
                                <NoResult src={EmptyNotificationIcon} title={'새로운 알림이 없어요'} subtitle={''}/>
                            </NotificationEmptyWrap> : createNotification()

                    }
                    </NotificationMenu> :
                    <ThreeDots
                        height={"10"}
                        wrapperStyle={{
                            width: '100%',
                            justifyContent: 'center',
                            height: '100%',
                            paddingTop: '30px'}}
                        color={'#3A6AF6'}
                    />
            );
        }else if (activeTab === 1) { // 공지사항
            return (
                <NotificationMenu ref={noticeMenuRef}>
                    {
                        isEmpty(noticeList) ?
                            <NotificationEmptyWrap>
                                <NoResult src={EmptyNoticeIcon} title={'새로운 공지사항이 없어요'} subtitle={''}/>
                            </NotificationEmptyWrap> : createNotice()

                    }
                </NotificationMenu>
            );
        }else {
            return null;
        }

    }



    /******************************************************************************************************************/

    const getNewNotification = async () => {

        // 최근의 알림만 받아오기
        let _standDate = list.length > 0 ? list[0].createDate : standDate;



        await receiveResponse({
            standDate : _standDate,
            size: 10000,
            after : true
        }, 'notifications', 'get')
            .then(res => {
                if (res.status === 200 && !isEmpty(res.data.list)) {
                    const newList = res.data.list;
                    setList(prevList => {
                        const prevIdList = prevList.map(item => item.notificationId);
                        return newList.filter(n => !prevIdList.find((pId) => pId === n.notificationId)).concat(prevList);
                    });
                    if (newList.length > 0) {
                        // 새로운 알림이 있으면, 제일 최근 것의 날짜로 설정
                        setLatestDate(newList[0].createDate);
                    }
                }
            })
            .catch(e=>{
                console.log('notification update fail');
                console.log(e);
            })
    }
    /******************************************************************************************************************/


    /**
     * 04. useEffect
     *
     */

    // page가 증가하면 설정된 페이지의 데이터 조회
    // useEffect(() => {
    //     if(userStore.user?.notificationViewDate){
    //         receiveResponse({
    //             standDate,
    //             page,
    //             size : 1000,
    //             after: false,
    //         }, 'notifications', 'get').then(res => {
    //             if (res.status === 200) {
    //                 setList(list.concat(res.data.list));
    //                 setEnd(res.data.end);
    //                 // 첫 로딩 시 변경된 조회일시 사이에 새 알림이 있을 경우 unread icon 표시
    //                 if (page === 0) {
    //                     const dt = res.data.list[0]?.createDate;
    //                     if (dt) {
    //                         setVisibleUnreadIcon(userStore.user.notificationViewDate < dt && dt <= standDate);
    //                     }
    //                 }
    //             }
    //         });
    //     }
    // }, [page, standDate, userStore.user]);
    // //
    // // 알림창을 여는 경우 또는 푸시로 알림이 온 경우에 새로운 알림이 있는지 조회
    // useEffect(() => {
    //     if (openNotification) {
    //         receiveResponse({
    //             standDate,
    //             size: 1000,
    //         }, 'notifications', 'get').then(res => {
    //             if (res.status === 200 && !isEmpty(res.data.list)) {
    //                 const newList = res.data.list;
    //                 setList(prevList => {
    //                     const prevIdList = prevList.map(item => item.notificationId);
    //                     return newList.filter(n => !prevIdList.find((pId) => pId === n.notificationId)).concat(prevList);
    //                 });
    //                 if (newList.length > 0) {
    //                     // 새로운 알림이 있으면, 제일 최근 것의 날짜로 설정
    //                     setLatestDate(newList[0].createDate);
    //                 }
    //                 if (!openNotification) {
    //                     setVisibleUnreadIcon(true);
    //                 }
    //             }
    //         });
    //     }
    // }, [standDate, openNotification, checkNew]);
    //
    // 알림 창을 열었을 때 제일 상단에 있는 알림으로 알림 마지막 조회일 업데이트
    useEffect(() => {
        //서비스 알림 눌렀을 때
        if (activeTab === 0 && latestDate !== checkedDate) {
            receiveResponse({datetime: latestDate},
                `user/view-notification`, 'patch', {'Content-Type': 'multipart/form-data'}).then(() => {
                setCheckedDate(latestDate);
            });
        }
    }, [latestDate, checkedDate, activeTab]);

    // 최초 불러올 경우 알림 리스트 불러오기 (최대 999개까지만 보여짐)
    useEffect(() => {
        // 알림 목록 불러오기
        receiveResponse({all : true}, 'notifications', 'get').then(res => {
            if (res.status === 200) {
                const _list = res.data.list;
                setList(list.concat(_list));
            }
            setIsLoading(false);
        });
    }, []);

    // 페이지 이동될 때 마다, 공지사항 업데이트
    useEffect(()=>{
        receiveResponse({page : 0, size : 999,}, 'notices', 'get')
            .then(res => {
                if (res.status === 200) {
                    const _list = res.data.pagedList.list;
                    setNoticeList(_list);
                }
                setIsLoading(false);
            });
    },[location.pathname]);

    // // 밖에 클릭 (버튼)
    // useEffect(() => {
    //     const onClickOutside = (e) => {
    //         if (e && openNotification && buttonRef.current && menuRef.current) {
    //             if (!buttonRef.current.contains(e.target) && !menuRef.current.contains(e.target)) {
    //                 setOpenNotification(false);
    //             }
    //         }
    //     };
    //     document.addEventListener('click', onClickOutside);
    //
    //     return () => document.removeEventListener('click', onClickOutside);
    //
    // }, [buttonRef, menuRef, openNotification]);

    // // 밖에 클릭 (공지 사항)
    // useEffect(() => {
    //     const onClickOutside = (e) => {
    //         if (e && openNotice && noticeButtonRef.current && noticeMenuRef.current) {
    //             if (!noticeButtonRef.current.contains(e.target) && !noticeMenuRef.current.contains(e.target)) {
    //                 setOpenNotice(false);
    //             }
    //         }
    //     };
    //     document.addEventListener('click', onClickOutside);
    //
    //     return () => document.removeEventListener('click', onClickOutside);
    //
    // }, [noticeButtonRef, noticeMenuRef, openNotice]);


    useEffect(()=>{
        if (userStore.user) setIsNotification(getBoolFromYn(userStore.user.mobilePushYn));
    },[userStore.user]);

    // tab 바뀔 때, 스크롤 초기화
    useEffect(()=>{
        initScroll();
    },[activeTab])

    return (
        <>
            {/* 헤더 */}
            <NotificationTopTitle>{t("NOTIFICATION")}</NotificationTopTitle>

            {/* 푸시 */}
            <NotificationSettingWrap>
                <NotificationSettingRight>
                    <NotificationSettingTitle>{t('NOTIFICATION_APP_ACCEPT')}</NotificationSettingTitle>
                    <NotificationSettingDesc>{t('NOTIFICATION_APP_PUSH_TEXT')}</NotificationSettingDesc>
                </NotificationSettingRight>
                <NotificationSwitchWrap>
                    <NotificationSwitch onClick={e=>onClickSwitch(e)}>
                        <NotificationSwitchSlider className={isNotification ? 'on' : 'off'}/>
                    </NotificationSwitch>
                </NotificationSwitchWrap>
            </NotificationSettingWrap>

            {/* 텝 (서비스알림, 공지사항) */}
            <NotificationTopicWrap>
                <NotificationTopicTitle>
                    <NotificationTopicTitleWrap>
                        <NotificationTopicTitleSpan
                            className={activeTab === 0 ? 'checked' : ''}
                            onClick={(e)=>{onClickNav(e, 0)}}>
                            {t('NOTIFICATION_SERVICE')}
                        </NotificationTopicTitleSpan>
                        <NotificationTopicTitleSpan
                            className={activeTab === 1 ? 'checked' : ''}
                            onClick={(e)=>{onClickNav(e, 1)}}>
                            {t('NOTIFICATION_NOTICE')}
                        </NotificationTopicTitleSpan>
                        <NotificationDelBtn onClick={activeTab === 0 ? clickReadAll : clickReadAllNotice }>{t('NOTIFICATION_ALL_READ')}</NotificationDelBtn>
                    </NotificationTopicTitleWrap>
                </NotificationTopicTitle>
            </NotificationTopicWrap>

            {/*리스트*/}
            {listComponent(activeTab)}

        </>
    );
}

export default observer(Notification);