import { action, makeObservable, observable } from "mobx";
import * as React from 'react';
import isEmpty from "is-empty";
import {getIsImageFile} from "../utils/fileHandler";
import {getModalForm} from "../utils/itemContentHandler";

class ModalPopupStore {

    /**
     * c    : 소통해요
     * g    : 모여봐요
     * r    : 신고하기
     */
    page = 'c';

    /**
     * 팝업
     * 열기, 닫기
     */
    isOpen = false;

    /**
     * ***************************************************
     * 모달 컴포넌트 2개사용시 사용
     *
     * 닫기 여부 결정 (다음 모달 토스트창 사용할 경우)
     * true  : 강제 닫기
     * false : 닫기 전 작업 필요
     * ***************************************************
     */
    openYn = true;

    /**
     * 팝업 제목
     */
    titleTxt = '';

    /**
     * 팝업 내용
     */
    popupText = '';

    /**
     * add    : 신규
     * modify : 수정
     *
     */
    type = 'add';


    /**
     * 0000000000   Default   : 기본
     * 0000000001   Free      : 자유
     * 0000000002   Condole   : 경조사
     * 0000000003   Marry     : 결혼
     * 0000000004   Baby      : 출산
     * 0000000005   Discount  : 할인
     * 0000000006   Sell      : 팔다
     * 0000000007   Buy       : 사다
     * 0000000008   lostItem  : 물건 찾아요
     * 0000000009   lostItemOwner  : 주인 찾아요
     * 0000002002   Vote      : 투표
     * 0000002003   Question  : 질문
     * 0000002004   Work      : 업무
     **/

    form = 'Default';

    /**
     * 토픽 데이터들
     */
    topicitems = [];

    /**
     * 상세 데이터
     */
    detailItem = {};

    /**
     * 기존 이미지들
     */
    imgFiles = [];

    /**
     * 기존 파일들
     */
    otherFiles = [];

    /**
     * 닫기 callBack
     * 창이 닫았을 시 발생 콜백 함수
     */
    closeCallBack = () => {};

    /**
     * 서브밋 callBack
     * 확인 버튼 눌렀을 시 발생 콜백 함수
     */
    submitCallBack = () => {};

    /**
     * callBack
     */
    callBack = () => {};

    constructor() {
        makeObservable(this, {
            page : observable,
            isOpen: observable,
            openYn: observable,
            titleTxt: observable,
            popupText: observable,
            type: observable,
            form: observable,
            topicitems: observable,
            detailItem: observable,
            imgFiles: observable,
            otherFiles: observable,

            initAdd: action,
            initModify: action,
            setOpenYnPopup: action,
            setForm: action,
            openModalPopup: action,
            closeModalPopup: action
        });
    }


    /**
     * Add
     * 모달창 처음 세팅
     */
    initAdd = (page, topicitems, detailItem, closeCallBack, submitCallBack) => {
        this.openYn = true;
        this.type = "add";
        this.form = 'Default';

        this.page = page;
        this.topicitems = topicitems;
        this.detailItem = detailItem;


        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }

        if (submitCallBack && typeof submitCallBack === 'function') {
            this.submitCallBack = submitCallBack;
        }

        this.openModalPopup();
    };

    /**
     * Modify
     * 모달창 처음 세팅
     */
    initModify = (page, topicitems, detailItem, closeCallBack, submitCallBack) => {
        this.openYn = true;
        this.type = "modify";

        this.page = page;
        this.topicitems = topicitems;
        this.detailItem = detailItem;

        let detailItemImgFiles = [];
        let detailItemOtherFiles = [];

        //이미지, 다른파일 구분
        if(!isEmpty(detailItem.fileInfoList)) {
            detailItem.fileInfoList.forEach((file)=>{
                if(getIsImageFile(file?.fileExt)){
                    detailItemImgFiles.push(file);
                }else{
                    detailItemOtherFiles.push(file);
                }
            });
        }

        this.imgFiles = detailItemImgFiles;
        this.otherFiles =  detailItemOtherFiles;
        // 포멧 형식
        // if (detailItem.topicId !== undefined) {
        //
        //     if (detailItem.topicId === '0000000001') {
        //         this.form = 'Free';
        //     } else if (detailItem.topicId === '0000000002') {
        //         this.form = 'Condole';
        //     } else if (detailItem.topicId === '0000000003') {
        //         this.form = 'Marry';
        //     } else if (detailItem.topicId === '0000000004') {
        //         this.form = 'Baby';
        //     } else if (detailItem.topicId === '0000000005') {
        //         this.form = 'Discount';
        //     } else if (detailItem.topicId === '0000000006') {
        //         this.form = 'Sell';
        //     } else if (detailItem.topicId === '0000000007') {
        //         this.form = 'Buy';
        //     } else if (detailItem.topicId === '0000000008') {
        //         this.form = 'lostItem';
        //     } else if (detailItem.topicId === '0000000009') {
        //         this.form = 'lostItemOwner';
        //     } else if (detailItem.topicId === '0000002002') {
        //         this.form = 'Vote';
        //     } else if (detailItem.topicId === '0000002003') {
        //         this.form = 'Question';
        //     } else if (detailItem.topicId === '0000002004') {
        //         this.form = 'Work';
        //     } else {
        //         this.form = 'Default';
        //     }
        // }
        if(detailItem.topicName !== undefined){
            this.form = getModalForm(detailItem.topicName);
        }

        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }

        if (submitCallBack && typeof submitCallBack === 'function') {
            this.submitCallBack = submitCallBack;
        }

        this.openModalPopup();
    };

    /**
     * ((사용할 component에서 사용))
     * 모달창 닫기 여부
     */
    setOpenYnPopup = (openYn, callBack) => {
        this.openYn = openYn;
        if(callBack && typeof callBack === 'function') {
            this.callBack = callBack;
        }
    }

    /**
     * 모달창 form 수정
     */
    setForm = (topicName) => {
        this.form = getModalForm(topicName);
    }

    /**
     * 모달창 오픈
     */
    openModalPopup = () => {
        this.isOpen = true;
    }

    /**
     * 모달창 닫기
     */
    closeModalPopup = () => {
        this.isOpen = false;
        this.closeCallBack();
        this.closeCallBack = ()=>{};
        this.submitCallBack = ()=>{};
        this.callBack = () => {};
    }
}


export default ModalPopupStore;