import styled from 'styled-components'

export const NotificationTopTitle = styled.h2`
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    padding: 20px 0px 0px 16px;
`

export const NotificationWrap = styled.div`
    position: absolute; 
    top: 35px; 
    right: 50px; 
    width: 40px; 
    height: 40px;
`

export const NoticeWrap = styled.div`
    position: absolute; 
    top: 35px; 
    right: 100px; 
    width: 40px; 
    height: 40px;
`

export const NotificationButton = styled.div`
    width: 32px;
    height: 32px;

    position : relative;
    bottom : 6px;
`

export const NotificationUnreadIcon = styled.div`
  position: absolute;
  top: 0px;
  left : 26px;
  padding: 0px 5px;
  background: #FF7171;
  border-radius: 10.5px;
  color: #FFFFFF;
`

export const NotificationMenu = styled.div`
    padding-top: 5px;
`

export const NotificationTitleWrap = styled.div`
  display : flex;
  align-items : center;
  gap : 10px;
  padding : 0 30px;
  margin-bottom : 15px;
`

export const NotificationTitle = styled.div`
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    flex-grow : 1;
    color: ${({theme})=>`1px solid ${theme.colors.gray_50}`};
`

export const NotificationDelBtn = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #75818D;
    position: absolute;
    bottom: 17px;

    right: 17px;
`

export const NotificationEmptyWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 134px;
  // height: calc(100% - 36px); // 17: title height, 6: title padding
  flex-grow :1;
`

export const NotificationScrollBox = styled.div`
    display : flex;
    flex-direction : column;
    height: calc(100% - 36px);

    overflow : overlay;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`

export const NotificationSettingWrap = styled.div`
    background: #F7F9FB;
    border-radius: 10px;
    position: relative;
    padding: 11px 20px;
    margin: 10px 16px 0px;
    display: flex;
    gap : 7px;
`

export const NotificationSettingRight = styled.div`
    
`

export const NotificationSettingTitle = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #191E29;
`

export const NotificationSettingDesc = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #75818D;
`

export const NotificationSwitchWrap = styled.div`
    position: absolute;
    top: 18px;
    right: 20px;
`

export const NotificationSwitch = styled.label`
    position : relative;
    display : inline-block;
    width : 32px;
    height : 20px;
`

export const NotificationSwitchInput = styled.input`
    opacity : 0;
    width : 0;
    height : 0;
`

export const NotificationSwitchSlider = styled.span`
    position: absolute;
 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    
    background : #AEB7C0;
    
    &.on{
        background : #3A6AF6
    }
    
    &:before{
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: #FFFFFF;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
        
    }
    
    &.on:before{
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
    }
   
`

export const NotificationTopicWrap = styled.div`
    padding: 16px 0px 0px 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const NotificationTopicTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #AEB7C0;
`

export const NotificationTopicTitleWrap = styled.fieldset`
`

export const NotificationTopicTitleSpan = styled.span`
  
    color: #353C49;
    display: inline-block;
    width: 100px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    position: relative;
    color: #AEB7C0;
    padding: 5px 0px 15px;
    
    :: after {
        content: '';
        width: 100px; 
        height: 2px; 
        background: none; 
        position: absolute; 
        bottom: 0px; 
        left: 0px;
    }
    
    &.checked {
        color: #353C49;
        ::after {
            width: 100px; 
            height: 2px; 
            background: #191E29; 
            position: absolute; 
            bottom: 0px; 
            left: 0px;
        }
    }
`
