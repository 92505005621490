import theme from "../themes/theme";
import env from "../constants/env";
import qs from 'qs';
import routes from '../constants/routes.json';

export const Highlighter = ({ text = "", highlight = "", type="title"}) => {

    if(!text){
        return;
    }

    if (!highlight.trim()) {
        return <span>{text}</span>;
    }

    let _highlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    const regex = new RegExp(`(${_highlight})`,'i');
    const parts = text.split(regex);

    return (
        <span>
          {parts.filter(String).map((part, i) => {
              return regex.test(part) ? (
                  <span style={{'color' : theme.colors.black}}>{part}</span>
              ) : (
                  <span>{part}</span>
              );
          })}
        </span>
    );
};

export const UrlHighlighter = (text) => {

    if(!text){
        return;
    }

    const parts = text.split(env.mentionReg);
    console.log(parts);

    return (
        <span>
          {
              parts.map((part, i) => {
                  return env.urlReg.test(part) ?
                      <a
                          target={"_blank"}
                          key={i}
                          href={
                              part.startsWith('http://') || part.startsWith('https://')
                                  ? part : `https://${part}`
                          }
                          style={{'color': theme.colors.blue, textDecoration: 'underline'}}>{part}</a>
                      : <span key={i}>{part}</span>
              })
          }
        </span>
    )
}

export const PostHighlighter = (text) => {

    if (!text) {
        return;
    }

    const parts = text.split(env.postReg);

    return (
        <span style={{'vertical-align': 'top'}}>
          {
              parts.map((part, i) => {
                  if (env.urlReg.test(part)) {
                      if (part.startsWith(`http://${env.LINK_DOMAIN}${routes.APPLINK}`) || part.startsWith(`https://${env.LINK_DOMAIN}${routes.APPLINK}`)) {
                          const url = new URL(part);
                          return <a
                              target={"_blank"}
                              key={i}
                              onClick={() => {
                                  window.location.href = `${url.pathname.replace('applink/', '')}${url.search}`
                              }}
                              style={{'color': theme.colors.blue, textDecoration: 'underline'}}>{part}
                          </a>
                      } else {
                          return <a
                              target={"_blank"}
                              key={i}
                              href={part.startsWith('http://') || part.startsWith('https://') ? part : `https://${part}`}
                              style={{'color': theme.colors.blue, textDecoration: 'underline'}}
                          >{part}</a>

                      }
                  } else if (env.mentionReg.test(part)) {
                      return <span
                          key={i}
                          style={{'color': theme.colors.blue}}>{mentionTransformer(part)}</span>
                  }
                  return <span key={i}>{part}</span>

              })
          }
        </span>
    )
}


export const validateAsterisk = (text='') => {
    return text.replaceAll('&lt;','<')
        .replaceAll('&gt;','>')
        .replaceAll('&amp;','&');
}

export const textValidator = (text='', maxLen=20) => {
    if(text.length > maxLen){
        return text.substr(0,maxLen);
    }
    return text;

    // 제목 : 20자 까지
    // 내용 : 5000자 까지
    // 키워드 : 공백 입력 금지,
    // 닉네임 : 10자, 공백 금지, 한글, 영어, 특수문자 사용용}
}

export const getParamsFromUrl = (search) => {
    return qs.parse(search, {
        ignoreQueryPrefix: true
    });
}

export const mentionTransformer = (value) => {
    if(value){
        return value.replaceAll(/\[\[(.*?)\]\]/g, (arr => {
            let json = JSON.parse(arr);
            return json[0].map(e => `${e.prefix}${e.value}`).join(', ');
        }))
    }
    return value;
}

export const removeBlank = (value) => {

    if(value){
        return value.replaceAll(/\s*/g, "").replaceAll(" ", "");
    }
    return value;
}

export const getUrlList = (value) => {

    if(!value){
        return;
    }

    return value.match(env.urlReg);

}

export const numberToText = (number) => {
    if(number){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return number;
}

export const stringToCost = (value) => {
    if(!value) return '';

    return value
        .toString()
        .replaceAll(' ', '')
        .replace(/(^0+)/, "")
        .replace(/[^0-9,]+$/,"")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 받은 값에 3자리수마다 콤마를 추가
}

export const stringToContact = (value) => {
    if(!value) return '';
    return value
        .replaceAll(' ', '')
        .replace(/[^0-9]+$/g, '')
        .replace(
            /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
            '$1-$2-$3'
        )
        .replace('--', '-');
}
