import styled from "styled-components";

export const DropDownWrap = styled.div`
    width : ${({width})=>width ? width : '100%'};
    position : relative;
`

export const DropDownSelectWrap = styled.div`
    border : ${({theme,isOpen})=> {
        if(isOpen) return `1px solid #3A6AF6`;
        return `1px solid ${theme.colors.gray_stroke_100}`
    }};
    padding : ${({type})=>{
        if(type==='thin') return '2px 10px'; 
        return '12px 16px';
    }};
    display : flex;
    gap : ${({type})=>{
        if(type==='thin') return '5px';
        return '14px';
    }}; 
    
    background : ${({isDisabled})=> isDisabled ? '#F2F4F6' : ''};
    
    align-items: center;
    border-radius: 12px;
    box-sizing : border-box;
    
    // &:hover {
    //     border : {({theme})=>1px solid {theme.colors.gray_50}};
    // }
`

export const DropDownSelectText = styled.div`
    color : ${({theme})=>theme.colors.black};
    font-weight: 500;
    line-height : 20px;
     : 14px;
    flex : 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    
    font-size : ${({type})=>{
        if(type==='thin') return '12px';
        else return '14px';
    }};
`

export const DropDownListWrap = styled.div`
    position : absolute;
    width : 100%;
    z-index : 2;
    top : ${({type})=>{
        if (type === 'thin') return '37px';
        return '57px';
    }};
    // display : ${({isOpen})=>isOpen ? 'block' : 'none'};
`