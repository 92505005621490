import {
    VoteBarContentWrap,
    VoteBarCount,
    VoteBarInner,
    VoteBarItemName,
    VoteBarLeft,
    VoteBarRate,
    VoteBarRight,
    VoteBarWrap,
    VoteContainer,
    VoteContentWrap,
    VoteRe
} from "../../presentationals/global/VoteStyles";
import {useEffect, useState} from "react";
import {getArrayFromVoteLog, getObjectFromString, getVoteReqParamFromArray} from "../../../utils/itemContentHandler";
import {Button, IconButton} from "./index";
import CheckedIcon from '../../../resources/icons/group_icon_check.svg';
import UnCheckedIcon from '../../../resources/icons/group_icon.svg';
import ProfileIcon from '../../../resources/icons/profile.png';
import ReVoteIcon from '../../../resources/icons/vote_re.svg';

import Img from "./Img";
import {useStores} from "../../../stores";
import {ServerConfig} from "../../../commons/config";
import {receiveResponse} from "../../../utils/api";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {numberToText} from "../../../utils/textHandler";

const Vote = (props) => {

    const {t} = useTranslation();
    const {itemId, items, voteStatus, voteLog, onSubmit, multipleYn, type} = props;
    const {store} = useStores();
    const {userStore, defaultImageStore} = store;

    // 선택한 item들 ( ['item1', 'item2' ...]);
    const [selectedItems, setSelectedItems] = useState(getArrayFromVoteLog(voteLog))
    // 선택지
    const [voteItems, setVoteItems] = useState(getObjectFromString(items));
    // 투표 중인지 여부 (초기값은 투표내역이 있으면 true, 없으면 false)
    const [isVoting, setIsVoting] = useState(getArrayFromVoteLog(voteLog).length!==0 ? false : true);

    // voteLog에서 true면 체크 표시

    // submit 시 {itemId, item1 : true ...}

    // 투표 선택지 선택
    const toggleItem = (item) => {

        if (type==='card') return;
        const index = selectedItems.indexOf(item);
        if(index!==-1){
            setSelectedItems(selectedItems.slice(0,index).concat(selectedItems.slice(index+1,selectedItems.length)));
        }else{
            if(getBoolFromYn(multipleYn)){
                setSelectedItems([...selectedItems, item]);
            }else{
                setSelectedItems([item])
            }
        }
    }

    // 투표하기로 전환
    const toggleIsVoted = (e) => {
        if (type==='card') return;
        setSelectedItems(getArrayFromVoteLog(voteLog));
        setIsVoting(!isVoting);
    }

    // 투표 submit
    const onSubmitHandler = async () => {
        if (type==='card') return;
        const params = getVoteReqParamFromArray(selectedItems);
        await receiveResponse(params,`items/${itemId}/vote`,'patch',{'Content-Type': 'multipart/form-data'})
            .then(res=>{
                if(res.status===200){
                    if(onSubmit && typeof onSubmit === 'function') onSubmit();
                    setIsVoting(false);
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // voteLog 바뀌는경우 다시 설정
    useEffect(()=>{
        setSelectedItems(getArrayFromVoteLog(voteLog));
    },[voteLog])

    useEffect(()=>{
        setVoteItems(getObjectFromString(items))
    },[items])

    useEffect(()=>{
        setIsVoting(getArrayFromVoteLog(voteLog).length!==0 ? false : true);
    },[voteLog])

    const createVoteBars = () => {
        if(!voteItems) return;

        return (
            Object.keys(voteItems).map((item)=>(
                <VoteBarWrap onClick={e=>toggleItem(item)}>
                    <VoteBarInner width={`${(((voteStatus[item] || 0)/(voteStatus?.userCount || 0)) || 0) * 100}%`}/>
                    <VoteBarContentWrap>
                        <VoteBarLeft>
                            { isVoting ?
                                <IconButton
                                    width = {'18px'}
                                    height = {'18px'}
                                    src = {selectedItems.indexOf(item)!==-1 ? CheckedIcon : UnCheckedIcon}
                                /> : null
                            }
                            <VoteBarItemName>{voteItems[item]}</VoteBarItemName>
                            {
                                !isVoting ?
                                    voteLog[item] ?
                                    <Img
                                        wrapStyle={{width : '22px', height : '22px', borderRadius : '20px'}}
                                        src={
                                            userStore.user.imgId && defaultImageStore.defaultUserImgs ?
                                                defaultImageStore.defaultUserImgs.findIndex(img=>img === userStore.user.imgId)!==-1 ?
                                                    `${ServerConfig.default.IMG_URL}${userStore.user.imgId}` :
                                                    `${ServerConfig.default.IMG_THUMBNAIL_URL}${userStore.user.imgId}`
                                                : ProfileIcon}
                                    /> : null
                                    :null
                            }
                        </VoteBarLeft>
                        <VoteBarRight>
                            <VoteBarCount title={numberToText(voteStatus[item] || 0)}>
                                {`${Number(voteStatus[item] || 0) > 1000 ? '999+' : numberToText(voteStatus[item] || 0)}${t('VOTE_CNT')}`}
                            </VoteBarCount>
                            <VoteBarRate>{`${Math.floor((((voteStatus[item] || 0)/(voteStatus?.userCount || 0)) || 0) * 100)}%`}</VoteBarRate>
                        </VoteBarRight>
                    </VoteBarContentWrap>
                    {/*<VoteBarInner width={'50%'}/>*/}
                </VoteBarWrap>
            ))
        )
    };

    return(
        <VoteContainer>
            <VoteContentWrap>
                {createVoteBars()}
            </VoteContentWrap>
            <Button
                type={isVoting ? 'mobile' : 'secondary'}
                height={'36px'}
                width={'100%'}
                onClick={isVoting ? onSubmitHandler : e=>toggleIsVoted(e)}
                disabled={isVoting && selectedItems.length===0}
            >{isVoting ? t('DO_VOTE') : t('DO_RE_VOTE')}</Button>
        </VoteContainer>
    )
}

Vote.defaultProps = {
    itemId : '',
    items : null, // item vote props (object) {item1 : '선택지1', item2 : '선택지2' }
    voteStatus : null, // 총 인원, item 선택 현황 (item별 count)
    voteLog : null, // 내 투표 현황 (true, false)
    onSubmit : null, // 투표 제출 이후 동작
    multipleYn : 'N' // 중복 체크 가능한지
}

export default observer(Vote);