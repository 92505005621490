import {AttachFileName, AttachFileWrap, FileExt, FileName} from "./AttachFileStyles";
import {IconButton} from "./index";
import FileIcon from '../../../resources/icons/attached_file.svg';
import DeleteIcon from '../../../resources/icons/attached_file_delete.svg';
import {useState} from "react";
import path from 'path-browserify';

const AttachFile = (props) => {

    const {type, onClick, fileName, onDelete} = props;
    const [isHover, setIsHover] = useState(false);

    const onMouseOver = (e) => {
        setIsHover(true);
    }

    const onMouseLeave = (e) => {
        setIsHover(false);
    }

    const onClickHandler = (e) => {
        if(onClick) onClick();
    }


    return(
        <AttachFileWrap onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} type={type} onClick={onClickHandler} title={fileName}>
            <IconButton
                src={FileIcon}
                width={'24px'}
                height={'24px'}
            />
            <AttachFileName>
                <FileName>{path.parse(fileName).name}</FileName><FileExt>{path.parse(fileName).ext}</FileExt>
            </AttachFileName>
            {
                type==='upload' ?
                    <IconButton
                        src={DeleteIcon}
                        width={'20px'}
                        height={'20px'}
                        onClick={onDelete}
                    /> :
                    <div style={{'width' : '20px', 'height' : '20px', 'flex' : 'none'}}></div>
            }
        </AttachFileWrap>
    )
}

AttachFile.defaultProps = {
    type : 'upload', // upload, download
    onClick : null,
    fileName : 'text',
    onDelete : null
}

export default AttachFile;