import {
    ModalAttachFile,
    ModalAttachFiles,
    ModalAttachFileText,
    ModalBody,
    ModalBodyContent,
    ModalBodyContentFiles,
    ModalBodyContentFilesWrap,
    ModalBodyContentInput,
    ModalBodyTitleInput,
    ModalBodyTitleWrap,
    ModalBottom,
    ModalBottomCheckBoxes,
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalCount,
    ModalCountLen,
    ModalCountWrap,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle,
    ModalTitleCountWrap,
    ModalWrapInner
} from "../global/ModalStyles";

import {AttachFile, AttachImage, Button, Checkbox, DropDown, IconButton,} from "../global/index";

import {checkFilesLength, getAvailableFiles} from "../../../utils/fileHandler";

import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";

import isEmpty from 'is-empty';
import {getBoolFromYn, getMaxLenCheck, getYnFromBool} from "../../../utils/boolHandler";
import {getFileBlob, receiveResponse} from "../../../utils/api";
import ServerConfig from "../../../commons/config/ServerConfig";
import {numberToText, removeBlank, stringToContact, stringToCost} from "../../../utils/textHandler";
import Loading from "../global/Loading";
import env from "../../../constants/env";
import {observer} from "mobx-react";
import AttachFileIcon from '../../../resources/icons/attach_file.svg';
import CancelIcon from '../../../resources/icons/modal_cancel.svg';
import RemoveIcon from '../../../resources/icons/vote_remove.svg';

import {
    createSellProps,
    getArrayFromVoteObject,
    getContactFromProps,
    getCostFromProps,
    getObjectFromString,
    getVoteObjectFromArray
} from "../../../utils/itemContentHandler";
import {useTranslation} from "react-i18next";


const CommunicateModal = (props) => {

    const {t} = useTranslation();
    // const {store} = useStores();
    const modalPopupStore = props.modalPopupStore;
    const modalToastPopupStore = props.modalToastPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const onSubmit = props.onSubmit;

    //데이터
    const data = modalPopupStore;

    // 데이터 아이템
    const topicitems = data.topicitems;
    const detailItem = data.detailItem;
    const form = data.form;
    const type = data.type;
    const page = data.page;
    const imgs = data.imgFiles;
    const others = data.otherFiles;

    // 익명 여부
    const [isAnonymous, setIsAnonymous] = useState(getBoolFromYn(detailItem?.anonymousYn));
    // 공지 여부
    const [isNotice, setIsNotice] = useState(getBoolFromYn(detailItem?.noticeYn));
    // 토픽 인덱스
    const [selectedTopic, setSelectedTopic] = useState(0);
    // 이미지 첨부 ref
    const imgRef = useRef();
    // 파일 첨부 ref
    const fileRef = useRef();
    // text 필드 ref
    const textRef = useRef();
    // 스크롤 되는 box ref
    const inputScrollRef = useRef();


    // 이미지 파일 리스트
    const [imgFiles, setImgFiles] = useState([]);
    // 이미지 파일 object url 리스트
    const [imgFilesUrl, setImgFilesUrl] = useState([]);
    // 파일 리스트
    const [otherFiles, setOtherFiles] = useState([]);
    // 업로드 실패 파일들
    const [failFiles, setFailFiles] = useState([]);
    // 제목
    const [title, setTitle] = useState(detailItem?.title);
    // 내용
    const [content, setContent] = useState(detailItem?.content?.replaceAll('\r\n','\n'));
    // 게시글 등록, 수정 로딩 모달
    const [isLoading, setIsLoading] = useState(false);
    // 제목 포커스
    const [isTitleFocus, setIsTitleFocus] = useState(false);
    // 가격 포커스
    const [isPriceFocus, setIsPriceFocus] = useState(false);
    // 연락처 포커스
    const [isPhoneFocus, setIsPhoneFocus] = useState(false);
    // 투표 포커스
    const [isVoteFocus, setIsVoteFocus] = useState({
        vote1 : false,
        vote2 : false,
        vote3 : false,
        vote4 : false,
        vote5 : false
    });
    // 내용 포커스
    const [isContentFocus, setIsContentFocus] = useState(false);

    /**
     * 신규추가
     *
     */
    // 복수선택 가능
    const [isMultiple, setIsMultiple] = useState(getBoolFromYn(detailItem?.multipleYn));
    // 무료나늠
    const [isFreecycle, setIsFreecycle] = useState(getBoolFromYn(detailItem?.freecycleYn));
    // 게시글 props (중고(팝니다, 삽니다), 투표)
    const [itemProps, setItemProps] = useState(detailItem?.props);
    // 가격
    const [cost, setCost] = useState(getCostFromProps(getObjectFromString(detailItem?.props)));
    // 연락처
    const [contact, setContact] = useState(getContactFromProps(getObjectFromString(detailItem?.props)));
    // 투표 항목
    const [voteItems, setVoteItems] = useState(getArrayFromVoteObject(detailItem?.props) || ['','']);

    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    /**
     ************* 상단
     * x버튼 클릭시
     * @param e
     */
    const onCloseHandler = () => {
        /**
         * editing이면
         * 모달 팝업창 띄우기
         */
        if(isLoading) return;

        if(getIsEditing(form)){
            //setOpenConfirmModal(true);
            modalToastPopupStore.initDefault(
                'modalAlert',
                t('CONFIRM_CANCEL_MODIFY_ITEM'),
                t('YES'),
                t('NO'),
                onCloseModalToastHandler
            );
            modalToastPopupStore.openModalPopup();
            return;
        }

        //window.History.Back 뒤로가기 (모달 window)
        goHistoryBack();
    }

    /**
     * ************ 상단
     * 토픽 드랍다운 리스트 클릭 이벤트
     * @param index
     */
    const onClickTopic = (index) => {

        //컨텐트 내용
        let defaultContentText = '';
        if (index === 1) {
            defaultContentText = t('CONDOLE_MODAL_TEXT');
        }else if (index === 2) {
            defaultContentText = t('MARRY_MODAL_TEXT');
        }else if (index === 3) {
            defaultContentText = t('BABY_MODAL_TEXT');
        }

        setSelectedTopic(index);
        setContent(defaultContentText);
        setTitle('');
        setVoteItems(['','']);
        setContact('');
        setCost('');
        setIsFreecycle(false);
        setIsMultiple(false);
        setImgFiles([]);
        setOtherFiles([]);
        setIsAnonymous(false);

        // 이미지 url들 전부 삭제
        if(imgFilesUrl.length>0){
            imgFilesUrl.forEach(url=>URL.revokeObjectURL(url));
            setImgFilesUrl([]);
        }
        modalPopupStore.setForm(topicitems[index].name);
    }

    /**
     * 토픽 dropdown 리스트 클릭 시
     */
    const openChangeTopicPopup = async (index) => {

        if(getIsEditing(form)){
            modalToastPopupStore.initDefault(
                'alert',
                t('TOPIC_CHANGE_ON_EDITING_POPUP'),
                t('YES'),
                t('NO'),
                e=> {
                    if (!modalToastPopupStore.isCancel) return;
                    onClickTopic(index)
                }
            );
            modalToastPopupStore.openModalPopup();
        }else{
            onClickTopic(index);
        }
        // return index;
        //window.History.Back 뒤로가기 (모달 window)
        // goHistoryBack();
    }

    /**
     ************ 상단
     * 제목 이벤트
     * @param e
     */
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    /**
     ************ props용
     * 가격 이벤트
     * @param e
     */
    const onChangeCost = (e) => {
        if(isFreecycle) return;
        const value = e.target.value;
        const regex = new RegExp(`^[0-9,]{0,${env.maxCostLen}}$`,'gi');
        const numCheck = regex.test(value.replaceAll(',', '')); // 입력값이 숫자와 콤마(,)인지 확인 (불린값이 나옴)
        if (!numCheck && value) return; // 숫자가 아닌 문자로 이루어져 있으면 pass! (입력이 x)
        setCost(stringToCost(value.replaceAll(',', '')));
    }

    /**
     ************ props용
     * 연락처 이벤트
     * @param e
     */
    const onChangeContact = (e) => {
        const value = e.target.value;
        const regex = new RegExp(`^[0-9-]{0,${env.maxContactLen}}$`,'gi');
        const numCheck = regex.test(value.replaceAll('-', '')); // 입력값이 숫자와 -인지 확인 (불린값이 나옴)
        if (!numCheck && value) return; // 숫자가 아닌 문자로 이루어져 있으면 pass! (입력이 x)
        setContact(stringToContact(value.replaceAll('-', '')));
    }

    /**
     ************ 중간
     * 내용 (컨텐츠) 이벤트
     * @param e
     */
    const onChangeContent = (e) => {
        e.preventDefault();
        //내용
        setContent(e.target.value);
    }

    /**
     *********** 중간
     * 이미지 파일 x 버튼
     * @param e
     * @param index
     */
    const onDeleteImgFiles = (e,index) => {
        const _deleteUrl = imgFilesUrl[index]
        setImgFiles(imgFiles.slice(0,index).concat(imgFiles.slice(index+1,imgFiles.length)));
        setImgFilesUrl(imgFilesUrl.slice(0,index).concat(imgFilesUrl.slice(index+1,imgFilesUrl.length)));
        URL.revokeObjectURL(_deleteUrl);
    }

    /**
     *********** 중간
     * 기타피알 x 버튼
     * @param e
     * @param index
     */
    const onDeleteOtherFiles = (e,index) => {
        setOtherFiles(otherFiles.slice(0,index).concat(otherFiles.slice(index+1,otherFiles.length)));
    }

    /**
     * 공지사항 체크 이벤트
     * @param e
     */
    const onCheckIsNotice = (e) => {
        setIsNotice(!isNotice);
    }

    /**
     * 무료나눔 체크 이벤트
     * @param e
     */
    const onCheckIsFreecycle = (e) => {
        if(!isFreecycle) setCost('');
        setIsFreecycle(!isFreecycle);
    }

    /**
     * 공지사항 체크 이벤트
     * @param e
     */
    const onCheckIsMultiple = (e) => {
        if(type==='modify') return;
        setIsMultiple(!isMultiple);
    }

    /**
     *********** 하단
     * (이미지) 파일 첨부 클릭 이벤트
     * @param e
     */
    const onClickFileAttach = (e) => {
        e.preventDefault();
        fileRef.current.click();
    }

    /**
     ********** 하단
     * 익명 체크 이벤트
     * @param e
     */
    const onCheckIsAnonymous = (e) => {
        setIsAnonymous(!isAnonymous);
    }

    /**
     ********* 하단
     * 글쓰기 버튼
     * @param e
     */
    const onSubmitHandler = (e) => {


        let params = {
            content,
            title,
            fileList : [...imgFiles,...otherFiles]
        }

        //{
        //     "item1": "선택지1",
        //     "item2": "선택지2",
        //     "item3": "선택지3",
        //     "item4": "선택지4",
        //     "item5": "선택지5",
        // }

        /**
         * Default   : 기본
         * Free      : 자유
         * Discount  : 할인
         * QA        : 질문
         * Condole   : 경조사
         * Marry     : 결혼
         * Baby      : 출산
         * Praise    : 칭찬
         * Sell      : 팔다
         * Buy       : 사다
         * Vote      : 투표
         *
         */

        //01.팝니다, 02.삽니다, 03.투표
        let propsItems = {
            item1 : "",
            item2 : "",
            item3 : "",
            item4 : "",
            item5 : ""
        };

        if (form === 'Sell' || form === 'Buy') {
            params = {
                ...params,
                props : JSON.stringify(createSellProps(cost,contact)),
                freecycleYn : getYnFromBool(isFreecycle)
            }

        }else if (form === 'Vote') {
            params = {
                ...params,
                props : JSON.stringify(getVoteObjectFromArray(voteItems)),
                multipleYn : getYnFromBool(isMultiple)
            }
        }else {  // 나머지는 익명 넣기
            //소통해요
            params = {
                ...params,
                anonymousYn : getYnFromBool(isAnonymous),
            }
        }

        //소통해요
        params = {
            ...params,
            topicId : topicitems[selectedTopic].id,
        }

        //글쓰기
        if (type === 'add') {
            addBoardItem(params, () => {
                setIsLoading(true);
            });
        }else if (type === 'modify') { //수정하기
            modifyBoardItem(params, () => {
                setIsLoading(true);
            });
        }

    }

    /**
      * DISPLAY = HIDDEN (숨어있음)
      *
      * 기타 파일 클릭 이벤트 (파일 선택할 경우)
      * @param e
     */
    const onChangeFile = (e) => {

        setIsLoading(true);

        if (!checkFilesLength(e.target.files, [...imgFiles, ...otherFiles])) {
            fileRef.current.value = '';
            setIsLoading(false);
            openFileConfirmModal();
            return;
        }

        getAvailableFiles(e.target.files).then(res=>{
            let newFiles = res;
            setImgFiles([...imgFiles, ...newFiles.imgs]);
            // 이미지 미리보기 url 생성
            setImgFilesUrl([...imgFilesUrl, ...newFiles.imgs.map(img=>URL.createObjectURL(img))]);
            setOtherFiles([...otherFiles, ...newFiles.others]);

            if(newFiles.fails.length>0){
                if(e.target.files.length === newFiles.fails.length) openFileConfirmModal([...newFiles.fails],true);
                else openFileConfirmModal([...newFiles.fails],false);
            };

        }).finally(()=>{
            fileRef.current.value = '';
            setIsLoading(false);
        });
    }

    // file confirm modal open
    const openFileConfirmModal = (failFiles=[], isFailAll) => {
        modalToastPopupStore.initFiles('file',failFiles,isFailAll,()=>{});
        modalToastPopupStore.openModalPopup();
    }

    /**
     ****************** 팝업 토스트 열었을 시
     *
     * 팝업 토스트 닫기 함수
     * callBack
     * @param e
     */
    const onCloseModalToastHandler = () => {

        /**
         * OK(true)      ->   닫기
         * 아니오(false)  ->   팝업창 유지
         */
        if(modalToastPopupStore.isCancel){

            //window.History.Back 뒤로가기 (모달 window)
            goHistoryBack();
            return;
        }
    }


    /******************************************************************************************************************/


    /**
     * 함수
     */

    // 초기 세팅
    const initdata = () => {
        //수정일 수 있으므로
        let defaultContentText = '';
        let selectedTopic = 0;

        if (form === "Condole" && type === "add") {
            defaultContentText = t('CONDOLE_MODAL_TEXT');
            selectedTopic = 1;
        }else if (form === "Marry" && type === "add") {
            defaultContentText = t('MARRY_MODAL_TEXT');
            selectedTopic = 2;
        }else if (form === "Baby" && type === "add") {
            defaultContentText = t('BABY_MODAL_TEXT');
            selectedTopic = 3;
        }

        // 1. 초기화
        setContent(defaultContentText);
        setTitle('');
        setSelectedTopic(selectedTopic);
        setIsAnonymous(false);
        setFailFiles([]);
        setImgFiles([]);
        setOtherFiles([]);
        setIsMultiple(false);
        setIsFreecycle(false);
        setCost('');
        setContact('');
        setVoteItems(['','']);

            // 이미지 url들 전부 삭제
        if(imgFilesUrl.length>0){
            imgFilesUrl.forEach(url=>URL.revokeObjectURL(url));
            setImgFilesUrl([]);
        }
    }

    // 수정 세팅
    const initModifyData = async () => {
        setIsLoading(true);
        // 내용
        setContent(detailItem.content.replaceAll('\r\n','\n'));
        // 제목
        setTitle(detailItem.title);
        // 익명
        setIsAnonymous(getBoolFromYn(detailItem.anonymousYn));
        // 공지
        setIsNotice(getBoolFromYn(detailItem.noticeYn));
        //복수선택(투표)
        setIsMultiple(getBoolFromYn(detailItem.multipleYn));
        //무료나눔(팔기)
        setIsFreecycle(getBoolFromYn(detailItem.freecycleYn));
        // 가격
        setCost(getCostFromProps(getObjectFromString(detailItem?.props)) || '');
        // 연락처
        setContact(getContactFromProps(getObjectFromString(detailItem?.props)));
        // 투표
        setVoteItems(getArrayFromVoteObject(detailItem?.props));
        // 파일
        getModifyFiles();
    }

    // 게시물 등록 함수 API
    const addBoardItem = (params, onLoading) => {

        const formData = new FormData();
        formData.append('groupId' , '0000000001');

        const keys = Object.keys(params);

        for(let i=0; i<keys.length; i++){
            if(keys[i]==='fileList'){
                params[keys[i]].forEach(file=>formData.append(keys[i],file));
                continue;
            }
            formData.append(keys[i],params[keys[i]]);
        }

        //onLoading중 처리
        if (onLoading) {
            onLoading();
        }

        receiveResponse(formData, 'items', 'post', {"Content-Type": "multipart/form-data"})
            .then(res=>{
                if(res.status===200) {
                    onSubmit();
                }
            }).catch((e)=>{
            console.log(e)
        }).finally(()=>{setIsLoading(false)});
    }

    // 게시물 수정 API
    const modifyBoardItem = (params, onLoading) => {

        const formData = new FormData();
        formData.append('groupId', detailItem?.groupId);

        const keys = Object.keys(params);

        for(let i=0;i<keys.length;i++){
            if(keys[i]==='fileList'){
                params[keys[i]].forEach(file=>formData.append(keys[i],file));
                continue;
            }
            formData.append(keys[i],params[keys[i]]);
        }

        // onLoading중 처리
        if (onLoading) {
            onLoading();
        }

        receiveResponse(formData, `items/${detailItem?.itemId}`, 'post', {"Content-Type": "multipart/form-data"})
            .then(res=>{
                if(res.status===200) {
                    onSubmit();
                }
            }).catch((e)=>{
                console.log(e);
            }).finally(()=> {
                setIsLoading(false);
            })
    }

    // 스크롤 함수
    const handleResizeHeight = useCallback(()=>{
        textRef.current.style.height = 'auto';
        if(!isEmpty(content) || !isEmpty(imgFiles) || !isEmpty(otherFiles)) {
            textRef.current.style.height = textRef.current.scrollHeight + 'px';
        }
    },[content, imgFiles, otherFiles]);

    // 작성 중인지 여부
    const getIsEditing = (form) => {
        if(form==='Buy' || form==='Sell'){
            setIsAnonymous(false);
            return !isEmpty(removeBlank(title)) || !isEmpty(removeBlank(content)) || !isEmpty(cost) || !isEmpty(contact) || !isEmpty(imgFiles) || !isEmpty(otherFiles);
        }else if (form==='Vote'){
            return !isEmpty(removeBlank(title)) || !isEmpty(removeBlank(content)) || isEditingVoteItem() || !isEmpty(imgFiles) || !isEmpty(otherFiles);
        }else{
            return !isEmpty(removeBlank(title)) || !isEmpty(removeBlank(content)) || !isEmpty(imgFiles) || !isEmpty(otherFiles);
        }
    }

    // 이미지 파일 가져오기
    const getImgFiles = async (imgs) => {
        if(isEmpty(imgs)) return [];
        let imgFiles = [];
        for(let i=0;i<imgs.length;i++){
            let file = imgs[i];
            await getFileBlob(`${ServerConfig.default.FILE_URL}${file?.fileId}`).then((blob)=>{
                const newFile = new File([blob],`${file?.fileOriginName}.${file?.fileExt}`);
                imgFiles.push(newFile);
            });
            if(i===imgs.length-1) return imgFiles;
        }
    }

    // 기타 파일 가져오기
    const getOtherFiles = async (others) => {
        if(isEmpty(others)) return [];
        let otherFiles = [];
        for(let i=0;i<others.length;i++){
            let file = others[i];
            await getFileBlob(`${ServerConfig.default.FILE_URL}${file?.fileId}`).then((blob)=>{
                const newFile = new File([blob],`${file?.fileOriginName}.${file?.fileExt}`);
                otherFiles.push(newFile);
            });
            if(i===others.length-1) return otherFiles;
        }
    }

    // 수정 시, 파일 정보 가져오기
    const getModifyFiles = () => {
        //이미지 불러오기
        getImgFiles(imgs)
            .then(files => {
                setImgFiles(files);
                setImgFilesUrl(files.map(file=>URL.createObjectURL(file)));
            })
            .then(()=>{
                //파일 불러오기
                return getOtherFiles(others);
            })
            .then((files)=>{
                setOtherFiles(files);
            })
            .catch(e=>{
                console.log(e);
            })
            .finally(()=>{
                setIsLoading(false);
            });
    }

    // 이미지 파일 생성
    const createImgFiles = (imgFilesUrl) => {
        return imgFilesUrl.map((imgFileUrl,index) =>
            <AttachImage type={'upload'} src={imgFileUrl} onDelete={(e)=>onDeleteImgFiles(e,index)}/>
        )
    }

    // 기타 파일 생성
    const createOtherFiles = (files) => {
        return files.map((file,index) =>
            <AttachFile type={'upload'} fileName={file.name} onDelete={(e)=>onDeleteOtherFiles(e,index)}/>
        );
    };

    /**
     * @todo
     * vote 항목 삭제, 항목 수정
     */
    const addVoteItem = () => {
        setVoteItems([...voteItems, '']);
    }

    // 투표 항목 삭제 함수
    const removeVoteItem = (index) => {
        setVoteItems(voteItems.slice(0,index).concat(voteItems.slice(index+1,voteItems.length)));
    }

    // 투표 항목 리스트 출력
    const createVoteItems = (voteItems) => {

        if(!voteItems) return;
        return voteItems.map((voteItem,index)=>(
            <ModalBodyTitleWrap className={ (index === 0 && isVoteFocus.vote1) || (index === 1 && isVoteFocus.vote2)
                                            || (index === 2 && isVoteFocus.vote3) || (index === 3 && isVoteFocus.vote4)
                                            || (index === 4 && isVoteFocus.vote5) ? 'focus' : ''} type={type}>
                <ModalBodyTitleInput
                    maxLength={env.maxVoteItemValueLen}
                    disabled={type==='modify'}
                    type={type}
                    key={index}
                    placeholder={t('VOTE_ITEM_PLACEHOLDER', {index : index+1, itemLen : env.maxVoteItemValueLen})}
                    value={voteItems[index]}
                    onInput={e=>{
                        // 글자수 안이면
                        if(getMaxLenCheck(e.target.value.length, env.maxVoteItemValueLen)) {
                            setVoteItems((prev) => {
                                const result = [...prev];

                                // // 글자수 안이면
                                // if(getMaxLenCheck(e.target.value.length, env.maxReportReasonLen)) {
                                //     setReportText(e.target.value);
                                // }
                                // if(type!=='modify') result[index] = textValidator(e.target.value,env.maxVoteItemValueLen);
                                if(type!=='modify') result[index] = e.target.value;
                                return result;
                            })
                        }
                    }}
                    onFocus={e=>{ if(index===0) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote1: true}});}
                                  else if(index===1) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote2: true}});}
                                  else if(index===2) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote3: true}});}
                                  else if(index===3) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote4: true}});}
                                  else if(index===4) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote5: true}});}
                    }}
                    onBlur={e=>{ if(index===0) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote1: false}});}
                                 else if(index===1) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote2: false}});}
                                 else if(index===2) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote3: false}});}
                                 else if(index===3) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote4: false}});}
                                 else if(index===4) {setIsVoteFocus((voteFocus)=>{return {...voteFocus, vote5: false}});}
                    }}
                />
                { index >= 2 && type !== 'modify' ?
                    <IconButton
                        src={RemoveIcon}
                        onClick={e=>removeVoteItem(index)}
                    /> : null}
            </ModalBodyTitleWrap>
        ))
    }

    // 투표항목 빈 값이 있는지 검사
    const hasEmptyVoteItem = () => {
        if(!voteItems) return true;
        let result = false;
        for(let i=0; i<voteItems.length;i++){
            if(!removeBlank(voteItems[i])){
                result = true;
                break;
            }
        }
        return result;
    }

    // 투표 항목 작성중인것이 있는지 검사
    const isEditingVoteItem = () => {
        if(!voteItems) return false;
        let result = false;
        for(let i=0; i<voteItems.length;i++){
            if(removeBlank(voteItems[i])){
                result = true;
                break;
            }
        }
        return result;
    }

    // form에 따른 disabled return
    const getIsDisabled = (form) => {
        if(form==='Buy' || form==='Sell'){
            return isEmpty(removeBlank(title)) || isEmpty(removeBlank(content))
                || content?.length > env.contentMaxLen || title?.length > env.titleMaxLen ||
                (isFreecycle && !isEmpty(cost)) || (!isFreecycle && isEmpty(cost)) || isEmpty(contact)
        }else if (form==='Vote'){
            return isEmpty(removeBlank(title)) || isEmpty(removeBlank(content))
                || content?.length > env.contentMaxLen || title?.length > env.titleMaxLen ||
                hasEmptyVoteItem()
        }else{
            return isEmpty(removeBlank(title)) || isEmpty(removeBlank(content))
                || content?.length > env.contentMaxLen || title?.length > env.titleMaxLen
        }
    }
    /******************************************************************************************************************/

    /**
     * useEffrct
     */
    useEffect(()=>{

        if (data.isOpen) {
            // 1. 수정일 경우 수정포멧 초기화
            if (type === "modify") {
                initModifyData();
            }
        }else {
            // 닫을 때 초기 데이터 (초기화시키기)
            initdata();
            setIsLoading(false);
        }
    },[data.isOpen]);

    useEffect(()=>{
        // 모달창 닫기여부 설정
        if(getIsEditing(form)) data.setOpenYnPopup(false, onCloseHandler);
    }, [content, title, imgFiles, otherFiles, cost, contact, voteItems]);

    useLayoutEffect(()=>{
        if(content || imgFiles || otherFiles) handleResizeHeight();
        // 파일 등록, 삭제 시 focus
        if(imgFiles || otherFiles){
            if(inputScrollRef && inputScrollRef.current ) inputScrollRef.current.scrollTop = inputScrollRef.current.scrollHeight;
        }
    },[content, imgFiles, otherFiles]);

    /******************************************************************************************************************/

    /**
     *
     * 컴포넌트
     * COMPONENT
     *
     *
     * @returns {JSX.Element}
     */

    //드랍다운 컴포넌트
    const dropDownComponent = (
        <DropDown
            items={topicitems}
            selectedIndex={selectedTopic}
            onSelect={openChangeTopicPopup}
            isDisabled={type === "modify" ? true : false}
        />
    )

    //제목 컴포넌트
    const titleComponent = (
        <ModalBodyTitleWrap className={isTitleFocus ? 'focus' : ''} disabled={title?.length > env.titleMaxLen}>
            <ModalBodyTitleInput
                placeholder={
                    form === 'Sell' ?
                        t('SELL_TITLE_PLACEHOLDER') :
                        form === 'Buy' ?
                            t('BUY_TITLE_PLACEHOLDER')
                            : t('COMMUNICATE_MODAL_TITLE_PLACEHOLDER')
                }
                value={title || ''}
                onChange={onChangeTitle}
                onFocus={e=>setIsTitleFocus(true)}
                onBlur={e=>setIsTitleFocus(false)}
            />
            <ModalTitleCountWrap>
                <ModalCountLen
                    entered={title?.length!==0}
                    disabled={
                        title?.length > env.titleMaxLen}
                >{numberToText(title?.length || 0)}</ModalCountLen>
                <ModalCount>/{numberToText(`${env.titleMaxLen}`)}</ModalCount>
            </ModalTitleCountWrap>
        </ModalBodyTitleWrap>
    )

    // sell (팝니다, 삽니다) 컴포넌트
    const sellComponent = (
        <>
            {/* 가격 */}
            <ModalBodyTitleWrap className={isFreecycle ? 'disabled' : isPriceFocus ? 'focus' : ''}>
                <ModalBodyTitleInput
                    disabled={isFreecycle}
                    placeholder={form === 'Sell' ? `￦ ${t('SELL_PRICE')}` : `￦ ${t('BUY_PRICE')}`}
                    value={cost}
                    onChange={onChangeCost}
                    onFocus={e=>setIsPriceFocus(true)}
                    onBlur={e=>setIsPriceFocus(false)}
                />
                {
                    form==='Sell' ? <Checkbox label={'무료 나눔'} checked={isFreecycle} onCheck={onCheckIsFreecycle}/> : null
                }
            </ModalBodyTitleWrap>
            {/* 연락처 */}
            <ModalBodyTitleWrap className={isPhoneFocus ? 'focus' : ''}>
                <ModalBodyTitleInput
                    placeholder={form === 'Sell' ? t('SELL_CONTACT') : t('BUY_CONTACT')}
                    value={contact}
                    onChange={onChangeContact}
                    onFocus={e=>setIsPhoneFocus(true)}
                    onBlur={e=>setIsPhoneFocus(false)}
                />
            </ModalBodyTitleWrap>
        </>
    )

    // 투표 컴포넌트
    const voteComponent = (
        <>
            {createVoteItems(voteItems)}
            {/* 항목 추가하기 버튼 */}
            {
                voteItems?.length < env.maxVoteItemLen && type!=='modify' ?
                    <Button
                        type={'tertiary'}
                        textStyle={{fontSize : '14px'}}
                        width={'100%'}
                        onClick={addVoteItem}
                    >{t('ADD_VOTE_ITEM')}</Button> :null
            }
        </>
    )

    // 컨텐츠(내용) 컴포넌트
    const contentComponent = (
        <ModalBody focus={isContentFocus ? 'focus' : ''} disabled={content?.length > env.contentMaxLen}>
            <ModalBodyContent
                page={page}
                form={form}
                ref={inputScrollRef}
                onClick={e=>textRef.current.focus()}>
                {/*<ScrollBox>*/}
                    <ModalBodyContentInput
                        ref={textRef}
                        placeholder={
                            form === 'Sell' ?
                                t('SELL_CONTENT_PLACEHOLDER') :
                                form === 'Buy' ?
                                    t('BUY_CONTENT_PLACEHOLDER')
                                    : t('COMMUNICATE_MODAL_CONTENT_PLACEHOLDER')
                        }
                        onChange={onChangeContent}
                        value={content || ''}
                        onFocus={e=>setIsContentFocus(true)}
                        onBlur={e=>setIsContentFocus(false)}
                    />
                    {
                        !isEmpty(imgFiles) || !isEmpty(otherFiles) ?
                            <ModalBodyContentFilesWrap>
                                {
                                    !isEmpty(imgFiles) ?
                                        <ModalBodyContentFiles>
                                            {createImgFiles(imgFilesUrl)}
                                        </ModalBodyContentFiles>
                                        : null
                                }
                                {
                                    !isEmpty(otherFiles) ?
                                        <ModalBodyContentFiles>
                                            {createOtherFiles(otherFiles)}
                                        </ModalBodyContentFiles>
                                        : null
                                }
                            </ModalBodyContentFilesWrap> : null
                    }
                {/*</ScrollBox>*/}
            </ModalBodyContent>
            <ModalCountWrap>
                <ModalCountLen
                    entered={content?.length!==0}
                    disabled={
                        content?.length > env.contentMaxLen}
                >{numberToText(content?.length || 0)}</ModalCountLen>
                <ModalCount>/{numberToText(`${env.contentMaxLen}`)}</ModalCount>
            </ModalCountWrap>
        </ModalBody>
    )

    // 기타 컴포넌트
    const fileEctContent = (
        <ModalBottom>
            <ModalAttachFiles>
                <ModalAttachFile onClick={onClickFileAttach}>
                    <IconButton
                        width={'24px'}
                        height={'24px'}
                        src={AttachFileIcon}
                    />
                    <ModalAttachFileText>{t('ATTACH')}</ModalAttachFileText>
                </ModalAttachFile>
            </ModalAttachFiles>
            <ModalBottomCheckBoxes>
                {
                    form==='Vote' ?
                        <Checkbox
                            wrapperStyle={{height: '24px'}}
                            checkLabelStyleWrap={{fontSize:'16px'}}
                            disabled={type==='modify'}
                            checked={isMultiple}
                            label={t('MULTIPLE_POSSIBLE')}
                            onCheck={onCheckIsMultiple}
                        /> : null
                }
                {
                    form !== 'Sell' && form !== 'Buy' ?
                        <Checkbox
                            wrapperStyle={{height: '24px'}}
                            checkLabelStyleWrap={{fontSize:'16px'}}
                            checked={isAnonymous}
                            label={t('ANONYMOUS')}
                            onCheck={onCheckIsAnonymous}
                        /> : null
                }
            </ModalBottomCheckBoxes>
        </ModalBottom>
    )

    // 바텀 컴포넌트
    const bottomComponent = (
        <>
            <Button
                type={'mobile'}
                shape={'circle'}
                height={'48px'}
                width={'100%'}
                textStyle={{fontSize : '16px', lineHeight: "23px"}}
                disabled={getIsDisabled(form)}
                onClick={onSubmitHandler}
            >{type === 'add' ? t('ADD_ITEM') : t('MODIFY_ITEM')}</Button>
            {/* 파일 첨부 */}
            <input
                type={'file'}
                ref={fileRef}
                style={{'display': 'none'}}
                accept={'*'}
                multiple={'multiple'}
                onChange={onChangeFile}
            />
        </>
    )

    //모달 default 컴포넌트 틀
    const modalDefaultComponent = (
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>
            <Loading isLoading={isLoading}>
                {/* HEAD 컴포넌트 */}
                <ModalHeaderWrap>

                    {/* 전체 타이틀 컴포넌트*/}
                    <ModalTitle>{type === 'add' ? t('COMMUNICATE_ADD_ITEM') : t('MODIFY_ITEM')}</ModalTitle>
                    <ModalCloseWrap>
                        <IconButton width={'32px'} height={'32px'} onClick={onCloseHandler}
                                    src={CancelIcon}/>
                    </ModalCloseWrap>
                </ModalHeaderWrap>

                {/* 내용 컴포넌트 */}
                <ModalScrollBox className={'file'}>
                    <ModalWrapInner>

                        {/* 드랍다운 컴포넌트*/}
                        {dropDownComponent}

                        {/* 제목 컴포넌트*/}
                        {titleComponent}

                        {/* 가격, 연락처 컴포넌트 (팝니다. 삽니다.) */}
                        { form === 'Sell' || form === 'Buy' ? sellComponent : null}

                        {/*투표 컴포넌트*/}
                        { form === 'Vote' ? voteComponent : null}

                        {/*내용 컴포넌트*/}
                        {contentComponent}

                    </ModalWrapInner>
                </ModalScrollBox>

                {/* BOTTOM 컴포넌트 */}
                <ModalBottomWrap>
                    {/* 파일 컴포넌트*/}
                    {fileEctContent}

                    {/* 글쓰기 컴포넌트*/}
                    {bottomComponent}
                </ModalBottomWrap>

            </Loading>
        </ModalContainer>
    )


    // Default   : 기본
    // Free      : 자유
    // Discount  : 할인
    // QA        : 질문
    // Condole   : 경조사
    // Marry     : 결혼
    // Baby      : 출산
    // Sell      : 팔다
    // Buy       : 사다
    // Vote      : 투표

    // 모달 폼 형식
    switch (data.form) {
        case "Default" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "ChildBirth" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Free" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Discount" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Question" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Obituary" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Marriage" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Sell" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Buy" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "LostItem" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "LostItemOwner" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Vote" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        case "Work" : {
            return (
                <>
                    {modalDefaultComponent}
                </>
            )
        }
        default: {
            return (
                null
            )
        }
    };

}

export default observer(CommunicateModal);