import {ThemeProvider} from "styled-components";
import theme from "./themes/theme";
import {Provider} from "mobx-react";
import Router from "./routes/Router";
import {CookiesProvider} from "react-cookie";
import rootStore from './stores';

const App = () => {

    return (
        <ThemeProvider theme={theme}>
            <CookiesProvider>
                <Provider store={rootStore}>
                    <Router/>
                </Provider>
            </CookiesProvider>
        </ThemeProvider>
    );
}

export default App;