import {action, makeObservable, observable, runInAction} from "mobx";
import {receiveResponse} from "../utils/api";
import isEmpty from 'is-empty';
import {getBoolFromYn} from "../utils/boolHandler";
import {getDDayFromDate} from "../utils/dateHandler";
import {matchPath} from "react-router-dom";
import routes from "../constants/routes.json";

class GroupDetailStore {

    // 그룹 상세 정보
    group = null;
    // 참여중인 사용자 정보
    userList = null;
    // 그룹 내 인기글
    recommend = null;
    // 미응답 가입 신청 리스트
    applyingUserList = null;
    // 그룹장 여부
    isManager = false;
    // 부그룹장 여부
    isAssistant = false;
    // 가입 여부
    isJoined = false;

    constructor() {
        makeObservable(this, {

            group: observable,
            userList: observable,
            recommend : observable,
            applyingUserList : observable,
            isManager : observable,
            isAssistant : observable,
            isJoined : observable,

            init: action,
            updateGroup: action,
            updateUserList: action,
            updateRecommend : action

        });
    }

    init = async (groupId, userId, denyCb, pathname) => {

        const detail = await receiveResponse({}, `groups/${groupId}`, 'get');
        runInAction(() => {
            if (detail.status === 200) {
                console.log('groupDetailStore.group, groupDetailStore.userList', detail.data);
                const _group = detail.data.group
                const _userList = detail.data.userList;
                // 가입 여부
                const _isJoined = _userList.filter(user=>user.userId === userId).length > 0;
                // 관리자 여부
                const _isManager = _group.managerId === userId;
                // 부관리자 여부
                const _isAssistant = _userList.filter(user=>getBoolFromYn(user.assistantYn) && user.userId === userId).length > 0;

                // 가입 하지 않았거나 관리자가 아니거나 부관리자가 아니면 access 못하는 콜백 실행
                if(pathname){
                    if(matchPath(pathname, {path : `${routes.GROUP_SETTING}/:id`})?.isExact){
                        if(!_isJoined || getDDayFromDate(_group.closingDate) <= 0 ){
                            if(denyCb && typeof denyCb === 'function') {
                                denyCb()
                                return;
                            }
                        }
                    }else if(matchPath(pathname, {path : `${routes.GROUP_SETTING_APPLY}/:id`})?.isExact){
                        if(!_isJoined || _isJoined && !(_isManager || _isAssistant) || getDDayFromDate(_group.closingDate) <= 0){
                            if(denyCb && typeof denyCb === 'function') {
                                denyCb()
                                return;
                            }
                        }
                    }else if(matchPath(pathname, {path : `${routes.GROUP_SETTING_INFORMATION}/:id`})?.isExact || matchPath(pathname, {path : `${routes.GROUP_SETTING_CLOSE}/:id`})?.isExact){
                        if(!_isJoined || !_isManager || getDDayFromDate(_group.closingDate) <= 0){
                            if(denyCb && typeof denyCb === 'function') {
                                denyCb()
                                return;
                            }
                        }
                    }
                }

                this.group = _group;
                this.userList = _userList;
                this.isJoined = _isJoined;
                this.isManager = _isManager;
                this.isAssistant = _isAssistant;

                // 그룹장이나 부그룹장이면 가입 신청 리스트 조회
                if(this.isManager || this.isAssistant) this.updateApplyingUserList(groupId);
            }
        });
    }

    updateGroup = async (groupId) => {
        const res = await receiveResponse({}, `groups/${groupId}`, 'get');
        runInAction(() => {
            if (res.status === 200) {
                console.log('groupDetailStore.group', res.data.group);
                this.group = res.data.group;
            }
        });
    }

    updateUserList = async (groupId) => {
        const res = await receiveResponse({}, `groups/${groupId}`, 'get');
        runInAction(() => {
            if (res.status === 200) {
                console.log('groupDetailStore.userList', res.data.userList);
                this.userList = res.data.userList;
            }
        });
    }

    updateApplyingUserList = async (groupId) => {
        const res = await receiveResponse({}, `groups/${groupId}/apply`, 'get');
        runInAction(() => {
            if (res.status === 200) {
                console.log('groupDetailStore.applyingUserList', res.data);
                this.applyingUserList = res.data;
            }
        });
    }

    updateRecommend = async (groupId) => {

        let params = {
            type : "I",
            target : "recommend",
            groupId
        };

        const recommend = await receiveResponse(params, `items`,'get');

        runInAction(() => {
            if (recommend.status === 200) {
                console.log('groupDetailStore., groupDetailStore.recommend', recommend.data);
                this.recommend = recommend.data;
            }
        });
    }

    removeInfo = () => {
        this.group = null;
        this.userList = null;
        this.recommend = null;
        this.applyingUserList = null;
        this.isManager = false;
        this.isAssistant = false;
        this.isJoined = false;
    }
}

export default GroupDetailStore;