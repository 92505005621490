import {
    GroupInfo,
    GroupListInfo,
    GroupListInfos,
    GroupName,
    GroupNameActivity,
    GroupNameText,
    GroupTitle,
    GroupUnreadIcon,
    GroupWrap
} from "./JoinGroupListStyles";
import {useHistory} from "react-router-dom";
import {IconButton, NoResult} from "./index";
import {useCallback} from "react";

import NoResultImg from "../../../resources/icons/no_post.svg";
import PinIcon from "../../../resources/icons/pin_filled.svg";
import UnPinIcon from "../../../resources/icons/pin_normal.svg";
import MasterIcon from "../../../resources/icons/master.svg";

import {receiveResponse} from "../../../utils/api";
import {getDDayFromDate, getISOFormat, timeForToday} from "../../../utils/dateHandler";
import {useStores} from "../../../stores";
import Img from "./Img";
import {ServerConfig} from "../../../commons/config";
import routes from "../../../constants/routes.json";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";


const JoinGroupList = (props) => {

    const {store} = useStores();
    const {userStore, defaultImageStore} = store;

    const {t} = useTranslation();

    const {list = [], invitingList=[]} = props;
    const history = useHistory();

    // 그룹 pin toggle (정렬)
    const togglePin = useCallback((joinGroups,groupId,index) => {
        let route;
        if(joinGroups[index].pinDate){
            route = `groups/${groupId}/unpin`
        }else{
            route = `groups/${groupId}/pin`
        }
        // 정렬하는 로직 필요
        receiveResponse({}, route, 'patch').then(res => {
            if(res.status === 200){
                if(res.data){
                    userStore.setJoinGroups(joinGroups.map((group)=>{
                        if(group.groupId===groupId){
                            return {...group, pinDate : (group.pinDate ? null : getISOFormat())}
                        }
                        return group;
                    }))
                }
            }
        }).catch((e)=>{
            console.log(e);
        });
    },[]);

    const joinGroupComponent = (group,index) => (
        <GroupListInfo onClick={() => history.push(`${routes.GROUPS}/${group.groupId}`)}>
            {
                defaultImageStore.defaultGroupImgs ?
                    <Img
                        src={
                            defaultImageStore.defaultGroupImgs.findIndex(img=>img === group.imgId)!==-1 ?
                                `${ServerConfig.default.IMG_URL}${group.imgId}` :
                                `${ServerConfig.default.IMG_THUMBNAIL_URL}${group.imgId}`
                        }
                        wrapStyle={{width : '40px', height : '40px', flex : 'none'}}
                    /> : null
            }
            <GroupInfo>
                <GroupName>
                    { group.managerId === userStore.user.userId ? <IconButton src={MasterIcon}/> : null}
                    <GroupNameText>{group.groupName}</GroupNameText>
                </GroupName>
                <GroupNameActivity className={group.closingDate ? 'closing' : ''}>
                    {
                        group.closingDate ?
                            `${t("CLOSE_GROUP_APPLYING")} : D-${getDDayFromDate(group?.closingDate)} ` :
                            `${t("LAST_ACTIVITY")} : ${group.lastActivityDate ?
                                timeForToday(group.lastActivityDate)
                                : timeForToday(group.createDate)}`
                    }
                </GroupNameActivity>
            </GroupInfo>
            <IconButton
                onClick={e=> {
                    e.stopPropagation();
                    togglePin(userStore.joinGroups, group.groupId, index)
                }}
                src={group.pinDate ? PinIcon : UnPinIcon}
            />
            {group.unread ? <GroupUnreadIcon /> : null}
        </GroupListInfo>
    )

    //
    const createJoinGroupList = (list) => {
        return list.map((item,index)=>{
            return joinGroupComponent(item,index);
        })
    }

    return <GroupWrap>
        {
            list.length !== 0 ?
                <>
                    {/*<Button width={'100%'} shape={'circle'}>{t('GROUP_ALL_ITEMS')}</Button>*/}
                    <GroupTitle>{t('PARTICIPATING_GROUP')} {list.length + '개'}</GroupTitle>
                </> : null
        }
        {
            list.length === 0 ?
                (invitingList.length===0 ? <NoResult src={NoResultImg} title={t('NO_PARTICIPATING_GROUP')} /> : null) :
                <GroupListInfos>
                    {createJoinGroupList(list)}
                </GroupListInfos>
        }
    </GroupWrap>;
}

export default observer(JoinGroupList);