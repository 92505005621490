import {useStores} from "../../stores";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import routes from "../../constants/routes.json";
import {receiveResponse} from "../../utils/api";
import {ThreeDots} from "react-loader-spinner";

const Invite = (props) => {

    const {store} = useStores();
    const {toastPopupStore} = store;
    const history = useHistory();
    const searchParams = new URLSearchParams(props.location.search);
    const response = searchParams.get('response');
    const groupId = searchParams.get('groupId');

    useEffect(() => {
        if (response === 'accept') {
            receiveResponse({}, `groups/${groupId}/accept-invite`, 'patch').then(res => {
                if (res.status === 200 && res.data) {
                    toastPopupStore.openToastPopup('그룹에 가입되었습니다.');
                    moveGroupPage();
                } else {
                    toastPopupStore.openToastPopup('가입에 실패했습니다. 초대 상태를 다시 확인해주세요.');
                    moveInvitedPage();
                }
            }, err => {
                toastPopupStore.openToastPopup('초대수락에 실패했습니다. 초대 상태를 다시 확인해주세요.');
                moveInvitedPage();
                console.info(err);
            });
        } else if (response === 'decline') {
            receiveResponse({}, `groups/${groupId}/decline-invite`, 'patch').then(res => {
                if (res.status === 200 && res.data) {
                    toastPopupStore.openToastPopup('그룹 초대를 거절했습니다.');
                    moveInvitedPage();
                } else {
                    toastPopupStore.openToastPopup('초대 거절에 실패했습니다. 초대 상태를 다시 확인해주세요.');
                    moveInvitedPage();
                }
            });
        } else {
            toastPopupStore.openToastPopup('잘못된 응답입니다.');
            moveInvitedPage();
        }
    }, []);

    const moveGroupPage = () => history.replace(`${routes.GROUPS}/${groupId}`);
    const moveInvitedPage = () => history.replace(`${routes.PROFILE_ACTIVITY}/invited`);

    return <ThreeDots
        height={"10"}
        wrapperStyle={{
            width : '100%',
            justifyContent : 'center',
            height : '100%',
            paddingTop: '30px'}}
        color={'#3A6AF6'}
    />;
}

export default Invite;