import {
    GroupFileContent,
    GroupFileDownloadIconWrap,
    GroupFileExt,
    GroupFileIconWrap,
    GroupFileName,
    GroupFileNickname,
    GroupFileSubTitleWrap,
    GroupFileTitleWrap,
    GroupFileVolume,
    GroupFileWrap
} from "./GroupFileStyles";
import {IconButton} from "./index";
import {
    getByteSize,
    getIsExcelFile,
    getIsImageFile,
    getIsVideoFile,
    getIsPDFFile,
    getIsPPTFile,
    getIsWordFile
} from "../../../utils/fileHandler";
import PPTIcon from "../../../resources/icons/ppt.svg";
import WordIcon from "../../../resources/icons/wordfile.svg";
import PDFIcon from "../../../resources/icons/pdf.svg";
import ExcelIcon from "../../../resources/icons/excel.svg";
import ImageIcon from "../../../resources/icons/image.svg";
import FileIcon from "../../../resources/icons/files.svg";
import DownloadIcon from "../../../resources/icons/download.svg";
import MP4Icon from "../../../resources/icons/mp4.svg";
import isEmpty from "is-empty";
import {Highlighter} from "../../../utils/textHandler";

const GroupFile = (props) => {

    const {fileExt, fileId, nickname, fileSize, fileOriginName, modifyDate, createDate, onDownload, highlight} = props;

    const getIcon = (fileExt) => {

        let f = fileExt.toLowerCase();

        if(getIsPPTFile(f)){
            return PPTIcon;
        }else if(getIsWordFile(f)){
            return WordIcon;
        }else if(getIsPDFFile(f)){
            return PDFIcon;
        }else if(getIsExcelFile(f)){
            return ExcelIcon;
        }else if(getIsImageFile(f)){
            return ImageIcon;
        }else if(getIsVideoFile(f)){
            return MP4Icon
        }
        return FileIcon;
    }

    const onDownloadHandler = (e) => {
        if(onDownload && typeof onDownload === 'function'){
            onDownload();
        }
    }

    return(
        <GroupFileWrap>
            <GroupFileContent>
                <GroupFileIconWrap>
                    <IconButton width={'25px'} height={'25px'} src={getIcon(fileExt)}/>
                </GroupFileIconWrap>
                <GroupFileTitleWrap className={!isEmpty(highlight) ? 'searching' : ''}>
                    <GroupFileName>{
                        isEmpty(highlight) ? fileOriginName :
                            <Highlighter
                                text={fileOriginName}
                                highlight={highlight}
                                type={"title"}
                            />
                    }</GroupFileName>
                    <GroupFileExt>{`.${fileExt}`}</GroupFileExt>
                </GroupFileTitleWrap>
                <GroupFileSubTitleWrap>
                    <GroupFileNickname>{isEmpty(highlight) ? nickname :
                        <Highlighter
                            text={nickname}
                            highlight={highlight}
                            type={"content"}
                        />}</GroupFileNickname>
                </GroupFileSubTitleWrap>
            </GroupFileContent>
            <GroupFileDownloadIconWrap>
                <IconButton
                    width={'25px'}
                    height={'25px'}
                    src={DownloadIcon}
                    onClick={onDownloadHandler}
                />
                <GroupFileVolume>{getByteSize(fileSize)}</GroupFileVolume>
            </GroupFileDownloadIconWrap>
        </GroupFileWrap>
    );
}

GroupFile.defaultProps = {
    fileExt : 'jpg',
    onDownload : null
}

export default GroupFile;