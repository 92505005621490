import {
    DotSpan,
    NotificationInfoBottom,
    NotificationItemTitle,
    NotificationItemTitleWrap,
    NotificationMessageContent,
    NotificationMessageCreated,
    NotificationMessageDesc,
    NotificationMessageDetail,
    NotificationMessageGroupName,
    NotificationMessageWrap,
    TitleSpan
} from './NotificationMessageStyles'
import {IconButton} from "./index";
import {getDateFrom} from "../../../utils/dateHandler";
import CommunicateIcon from "../../../resources/icons/profile_setting_communicate.svg";
import CommonIcon from "../../../resources/icons/notification_common.svg";

import Img from "./Img";
import {ServerConfig} from "../../../commons/config";
import {observer} from "mobx-react";
import {useStores} from "../../../stores";

const NotificationMessage = (props) => {

    /*
    props : 언급한 사람, 게시글 id, type
     */
    const {item, onClick} = props;
    const {store} = useStores();
    const {defaultImageStore} = store;

    const onClickHandler = (e) => {
        if (onClick && typeof onClick === 'function') {
            onClick(e, item);
        }
    }
    const isGroup = () => {
        const info = JSON.parse(item.info)
        return info.groupType === 'G' ||
            ['renamed-board', 'invited-board', 'new-notice-board'].includes(item.type);
    };
    // const getInfo = () => {
    //     const info = JSON.parse(item.info);
    //     switch (item.type) {
    //         case 'renamed-board':
    //             return <><NotificationMessageInfoWrap children={'['}/>
    //                 <NotificationMessageInfo half={true}>{info.fromBoardName}</NotificationMessageInfo>
    //                 <NotificationMessageInfoWrap children={'] > ['}/>
    //                 <NotificationMessageInfo half={true}>{info.groupName}</NotificationMessageInfo>
    //                 <NotificationMessageInfoWrap children={']'}/></>;
    //         case 'invited-board':
    //         case 'new-notice-board':
    //             return <><NotificationMessageInfoWrap children={'['}/>
    //                 <NotificationMessageInfo>{info.groupName}</NotificationMessageInfo>
    //                 <NotificationMessageInfoWrap children={']'}/></>;
    //         case 'commented-item':
    //         case 'commented-comment':
    //         case 'mentioned-item':
    //         case 'mentioned-comment':
    //         case 'mentioned-child-comment':
    //         case 'liked-item':
    //         case 'liked-comment':
    //         case 'liked-child-comment':
    //             return <><NotificationMessageInfoWrap children={'['}/>
    //                 <NotificationMessageInfo>{info.groupType === 'F' ? info.title : info.groupName}</NotificationMessageInfo>
    //                 <NotificationMessageInfoWrap children={']'}/></>;
    //     }
    //     return ':)';
    // }

    // type에 따른 icon 반환
    const getIcon = () => {

        const info = JSON.parse(item.info);

        switch (item.type){
            case 'system.lift-constraint':
            case 'system.put-constraint' :
                return (
                    <IconButton
                        wrapperStyle={{
                            width: '29px',
                            height: '29px',
                            top: '6px',
                            background: '#191E29',
                            flex: 'none',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        imgStyle={{
                            width: '19px',
                            height: '16px',
                            filter: 'brightness(0) invert(1)',
                            webkitFilter: 'brightness(0) invert(1)'
                        }}
                        src={CommonIcon}
                    />
                )
            case 'free.hide-item' :
            case 'free.hide-comment' :
            case 'free.hide-child-comment' :
            case 'free.comment' :
            case 'free.comment-child' :
            case 'free.like-child-comment' :
            case 'free.like-comment' :
            /* 2024.05.07
            * 소통해요
            * 알림 추가
            * 1.시스템 관리자가 게시글 삭제
            * 2.시스템 관리자가 댓글 삭제
            * 3.시스템 관리자가 대댓글 삭제
            * 4.게시글 신고로 인한 삭제
            * 5.댓글 신고로 인한 삭제 
            * 6.대댓글 신고로 인한 삭제 
            * */
            case 'free.manager-delete-item' :
            case 'free.manager-delete-comment' :
            case 'free.manager-delete-item' :
            case 'free.manager-delete-child-comment' :
            case 'free.reported-hide-item' :
            case 'free.reported-hide-comment' :
            case 'free.reported-hide-child-comment' :

            case 'show.like-item' :
            case 'show.reported-hide-item' :
            case 'show.manager-delete-item' :
                return(
                    <IconButton
                        wrapperStyle={{
                            width : '29px',
                            height : '29px',
                            top: '6px',
                            background : '#191E29',
                            flex : 'none',
                            borderRadius : '50%',
                            display : 'flex',
                            alignItems : 'center',
                            justifyContent : 'center'
                        }}
                        imgStyle={{
                            width : '19px',
                            height : '16px',
                            filter : 'brightness(0) invert(1)',
                            webkitFilter: 'brightness(0) invert(1)'
                        }}
                        src={CommunicateIcon}
                    />
                )
            case 'group.hide' :
            case 'group.hide-item' :
            case 'group.hide-comment' :
            case 'group.hide-child-comment' :
            case 'group-item.comment' :
            case 'group-item.comment-child' :
            case 'group-item.like-child-comment' :
            case 'group-item.like-comment' :
            case 'group-item.like-item' :
                return(
                    <Img
                        wrapStyle={{width : '29px', height : '29px', top: '6px', borderRadius : '50%', flex : 'none'}}
                        src={defaultImageStore.defaultGroupImgs.findIndex(img => img ===info.groupImgId) !== -1 ?
                            `${ServerConfig.default.IMG_URL}${info.groupImgId}` :
                            `${ServerConfig.default.IMG_THUMBNAIL_URL}${info.groupImgId}`}
                    />
                )
            /*모여봐요*/
            default :
                return(
                    <Img
                        wrapStyle={{width : '29px', height : '29px', top: '6px', borderRadius : '50%', flex : 'none'}}
                        src={defaultImageStore.defaultGroupImgs.findIndex(img => img ===info.groupImgId) !== -1 ?
                            `${ServerConfig.default.IMG_URL}${info.groupImgId}` :
                            `${ServerConfig.default.IMG_THUMBNAIL_URL}${info.groupImgId}`}
                    />
                )
        }

    }

    const getInfo = () => {

        const info = JSON.parse(item.info);

        switch (item.type){
            case 'system.lift-constraint':
            case 'system.put-constraint' :
                return(
                    <NotificationMessageCreated>{getDateFrom(item.createDate)}</NotificationMessageCreated>
                )
            case 'free.comment' :
            case 'free.comment-child' :

            case 'free.like-child-comment' :
            case 'free.like-comment' :
            case 'free.like-item' :

            case 'free.manager-delete-item' :
            case 'free.manager-reported-hide-item' :
            case 'free.reported-hide-item' :

            case 'free.manager-delete-comment' :
            case 'free.manager-delete-child-comment' :
            case 'free.reported-hide-comment' :
            case 'free.reported-hide-child-comment' :

            case 'group-item.comment' :
            case 'group-item.comment-child' :
            case 'group-item.like-child-comment' :
            case 'group-item.like-comment' :
            case 'group-item.like-item' :

            case 'group-item.group-manager-delete-item' :
            case 'group-item.group-manager-delete-comment' :
            case 'group-item.group-manager-delete-child-comment' :

            case 'group-item.manager-delete-item' :
            case 'group-item.manager-delete-comment' :
            case 'group-item.manager-delete-child-comment' :

            case 'group-item.reported-hide-item' :
            case 'group-item.reported-hide-comment' :
            case 'group-item.reported-hide-child-comment' :

                return(
                    <>
                        <NotificationInfoBottom>
                            <TitleSpan>[</TitleSpan>
                            <NotificationItemTitleWrap>
                                <NotificationItemTitle>
                                    {info.itemTitle?.length > 8 ? `${info.itemTitle.substr(0,8)}...` : info.itemTitle }
                                </NotificationItemTitle>
                            </NotificationItemTitleWrap>
                            <TitleSpan>]</TitleSpan>
                            <DotSpan>{' · '}</DotSpan>
                        </NotificationInfoBottom>
                        <NotificationMessageCreated>{getDateFrom(item.createDate)}</NotificationMessageCreated>
                    </>
                )

            case 'show.like-item' :
            case 'show.reported-hide-item' :
            case 'show.manager-delete-item' :
                return(
                    <>
                        <NotificationInfoBottom>
                            <TitleSpan>[</TitleSpan>
                            <NotificationItemTitleWrap>
                                <NotificationItemTitle>
                                    {info.postTitle?.length > 8 ? `${info.postTitle.substr(0,8)}...` : info.postTitle }
                                </NotificationItemTitle>
                            </NotificationItemTitleWrap>
                            <TitleSpan>]</TitleSpan>
                            <DotSpan>{' · '}</DotSpan>
                        </NotificationInfoBottom>
                        <NotificationMessageCreated>{getDateFrom(item.createDate)}</NotificationMessageCreated>
                    </>
                )

            default :
                return(
                    <NotificationMessageCreated>{getDateFrom(item.createDate)}</NotificationMessageCreated>
                )
        }

    }

    return <NotificationMessageWrap onClick={onClickHandler} className={item.readDate ? 'read' : null}>
        {
            defaultImageStore.defaultGroupImgs ? getIcon() : null
        }
        <NotificationMessageDesc>
            {
                isGroup() ?
                    <NotificationMessageGroupName>
                        {JSON.parse(item.info).groupName}
                    </NotificationMessageGroupName> : null
            }
            <NotificationMessageContent dangerouslySetInnerHTML={{__html: item.message}}/>
            <NotificationMessageDetail>
                {/*{isGroup() ? <NotificationGroupBadge>그룹</NotificationGroupBadge> : null}*/}
                {getInfo()}
            </NotificationMessageDetail>
        </NotificationMessageDesc>
    </NotificationMessageWrap>;
}

NotificationMessage.defaultProps = {
    "notificationId": "string",
    "userId": "string",
    "createDate": "2022-06-05T10:17:32.194Z",
    "readDate": null,
    "type": "string",
    "info": "string",
    "message": "string"
}

export default observer(NotificationMessage);