import {
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollNoBottomBox,
    ModalTitle
} from "../../../global/ModalStyles";
import {IconButton, NoResult} from "../../../global";
import CancelIcon from "../../../../../resources/icons/modal_cancel.svg";
import {
    NoticeItemContent,
    NoticeItemDate,
    NoticeItemTitle,
    NoticeItemWrap,
    NoticeListWrap,
    NoticeModalBody, NoticeNoResultWrap
} from "../NoticeStyles";
import env from "../../../../../constants/env";
import {useEffect, useRef, useState} from "react";
import {receiveResponse} from "../../../../../utils/api";
import {timeForToday} from "../../../../../utils/dateHandler";
import NoResultImg from "../../../../../resources/icons/no_notice.svg";
import {useStores} from "../../../../../stores";
import {useTranslation} from "react-i18next";

const Notice = (props) => {

    const {t} = useTranslation();
    const {onClose, groupId, onDelete, onError, page, isOpen, modalDetailStore} = props;
    const [items, setItems] = useState(null);
    const {store} = useStores();
    const {eventStore} = store;
    const e = eventStore.e;
    const scrollRef = useRef(null);
    /**********************************************************************************************/

    /**
     * 이벤트
     * @param e
     */

    // 아이콘 클릭 이벤트
    const onClickHandler = (itemId) => {

        // 모달상세 팝업
        /**
         * 모달 창
         */
        // 모달 디테일 생성
        modalDetailStore.init(
            'g',                  // 'c', 'g' 타입
            itemId,               // 아이템Id
            onCloseBoardItem,     // 닫기
            onCloseBoardItem,     // 삭제
            onCloseBoardItem      // 에러
        );

    }

    const onCloseHandler = (e) => {
        if(onClose && typeof onClose === 'function'){
            //window.History.Back 뒤로가기 (모달 window)
            onClose();
        }
    }

    const onDeleteHandler = (e) => {
        if(onDelete && typeof onDelete === 'function'){
            //window.History.Back 뒤로가기 (모달 window)
            onDelete();
        }
    }

    const onErrorHandler = (e) => {
        if(onError && typeof onError === 'function'){
            //window.History.Back 뒤로가기 (모달 window)
            onError();
        }
    }

    // 그룹 게시글 삭제 이벤트
    const onCloseBoardItem = () => {
    }

    // <이벤트> 이벤트 리스너 등록 (게시글 삭제 이벤트)
    const createEventListener = () => {
        // card 삭제 시, 게시글 업데이트
        e.on('update-notice', (args)=>{
            getNoticeItems();
        });
    }

    const removeEventListener = () => {
        e.removeAllListeners('update-notice',()=>{});
    }

    // 스크롤 초기화
    const initScroll = () => {
        if(scrollRef && scrollRef.current) scrollRef.current.scrollTop = 0;
    }

    /**********************************************************************************************/


    /**
     * 함수
     * @param e
     */

    // 공지 아이템 가져오기 API
    const getNoticeItems = (e) => {

        const params = {
            groupId : groupId,
            type : 'G',
            noticeOnly : true
        }

        receiveResponse(params,'items','get').then((res)=>{
            if(res.status===200){
                setItems(res.data.list);
            }
        }).catch((e)=>{
            console.log(e);
        })
    }

    /**********************************************************************************************/


    /**
     * 컴포넌트
     * @param e
     */

    // 컴포넌트
    const createNoticeItems = (items) => {
        return items.map((item)=>(
            <NoticeItemWrap onClick={e=>onClickHandler(item.itemId)}>
                <NoticeItemTitle>{item.title}</NoticeItemTitle>
                <NoticeItemContent>{item.content}</NoticeItemContent>
                <NoticeItemDate>{timeForToday(item.createDate)}</NoticeItemDate>
            </NoticeItemWrap>
        ))
    }

    /**********************************************************************************************/

    // useEffect(()=>{
    //     getNoticeItems();
    // },[]);

    /**
     * useEffrct
     */
    useEffect(()=>{

        if (isOpen) {
            initScroll();
            getNoticeItems();
            createEventListener();
        }else {
            // // 닫을 때 초기 데이터 (초기화시키기)
            // initdata();
        }
    },[isOpen]);

    /**********************************************************************************************/


    return(
        <ModalContainer className={!isOpen ? 'hidden' : null} >

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalCloseWrap>
                    <IconButton onClick={onCloseHandler} src={CancelIcon}/>
                </ModalCloseWrap>
                <ModalTitle>
                    {t('NOTICE')}
                </ModalTitle>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollNoBottomBox ref={scrollRef}>
                <NoticeModalBody result={items?.length !== 0}>
                    {
                        items === null ? null :
                            (
                                items.length !== 0 ?
                                    <NoticeListWrap>
                                        {createNoticeItems(items)}
                                    </NoticeListWrap>
                                    :
                                    <NoticeNoResultWrap>
                                        <NoResult src={NoResultImg} subtitle={t('THERE_ARE_NO_NOTICE')}/>
                                    </NoticeNoResultWrap>
                            )
                    }
                </NoticeModalBody>
            </ModalScrollNoBottomBox>

        </ModalContainer>
    )
}

Notice.defaultProps = {
    onClose : null,
    onClick : null,
    items : []
}
export default Notice;