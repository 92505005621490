import {IconButton, List} from ".";
import {useState, useEffect} from "react";
import {
    DropDownListWrap,
    DropDownSelectText,
    DropDownSelectWrap,
    DropDownWrap
} from "./DropDownStyles";
import DropDownIcon from '../../../resources/icons/dropdown.svg';


const DropDown = (props) => {

    const {items, onSelect, selectedIndex, isDisabled, type, width} = props;

    // 선택된 요소 (index)
    const [selected, setSelected] = useState(selectedIndex);
    const [isOpen, setIsOpen] = useState(false);

    const openList = (e) => {
        if(isDisabled) return;
        setIsOpen(!isOpen);
    }

    // list를 선택 했을 때
    const onSelectList = (index) => {
        if(isDisabled) return;
        setIsOpen(false);
        if(onSelect && typeof onSelect === 'function')onSelect(index);
        else setSelected(index);
    }

    // enter를 눌렀을 경우
    const onEnterList = (index) => {
        setSelected(items[index]);
    }

    const onBlurList = (e) => {
        setIsOpen(false);
    }

    useEffect(() => {
        // state 값 변경처리
        setSelected(selectedIndex);
    }, [selectedIndex]);

    return(
        <DropDownWrap width={width} isDisabled={isDisabled} >
            <DropDownSelectWrap isOpen={isOpen} onClick={openList} type={type} isDisabled={isDisabled}>
                <DropDownSelectText type={type}>{items[selected]?.nameText}</DropDownSelectText>
                <IconButton
                    width={'24px'}
                    height={'24px'}
                    onClick={openList}
                    src={DropDownIcon}
                />
            </DropDownSelectWrap>
            <DropDownListWrap type={type}>
                {
                    isOpen && !isDisabled ? <List
                        type={type}
                        width={'100%'}
                        items={items}
                        onSelect={onSelectList}
                        initSelected={selected}
                        onBlur={onBlurList}
                    ></List> : null
                }
            </DropDownListWrap>
        </DropDownWrap>

    )
}

DropDown.defaultProps = {
    items : [{id : 0, name : 'list0'}, {id : 1, name : 'list1'}, {id : 2, name : 'list2'}],
    width : '100%',
    onSelect : null,
    selectedIndex : 0,
    type : 'thick' // thick, thin
}

export default DropDown;