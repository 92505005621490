/**
 * auth 결과에 따라 page redirect
 * @param props
 * @constructor
 */
import {useEffect, useRef, useState} from "react";
import {receiveResponse, signIn, signQueryIn} from "../../utils/api";
import routes from '../../constants/routes.json';
import env from "../../constants/env";
import {useStores} from "../../stores";
import {observer} from "mobx-react";
import {useCookies} from "react-cookie";
import {getSystemLanguage} from "../../utils/languageHandler";
import {AuthContainer, AuthImageWrap, LoadingImage, LoadingText} from "./AuthStyles";
import LoadingImg from "../../resources/icons/loading_img.svg";
import {useTranslation} from "react-i18next";
import queryString from 'query-string';
import {appWebViewAuth, getOS, getQueryStringParams} from "../../utils/appBridgeEvnet";
import {getHashUserId} from "../../utils/itemContentHandler";

const AuthPage = (props) => {

    const timeRef = useRef(null);
    // 다국어 처리에 대한 hook
    const {t} = useTranslation();
    const [, setCookie] = useCookies();

    const [time, setTime] = useState(0);
    const [error, setError] = useState(null);

    const {store} = useStores();
    const {topicStore, authStore, interestStore, userStore, modalPopupStore, modalToastPopupStore, toastPopupStore} = store;

    // 사용자 정보 초기화
    const init = async (userId, refreshToken, accessToken) => {
        // 토큰 저장
        await authStore.init(refreshToken, accessToken);
        // 사용자 정보, 관심사 저장
        await userStore.init(userId);
        // 토픽 목록 저장
        await topicStore.init();
        // 관심사 목록 저장
        await interestStore.init();
    }

    // 사용자 등록
    const saveUser = (userId) => {
        const param = {
            userId,
            language : getSystemLanguage()
        }
        return receiveResponse(param,'user/save','post');
    }

    const createLoadingDot = () => {
        let cnt = time % 5 + 1;
        let dots = '';
        for( let i=0 ; i<cnt ; i++){
            dots = dots + '.';
        }
        return dots;
    }

    useEffect(()=>{

        // 기존 사용자 정보 초기화
        userStore.removeUserInfo();

        //01. 모바일 앱, 웹 받기
        let mobileLocationSearch = window.location.search;

        //02. 시간화면
        clearInterval(timeRef.current);
        modalPopupStore.setOpenYnPopup(false,()=>{});
        modalPopupStore.closeModalPopup();
        modalToastPopupStore.closeModalPopup();
        toastPopupStore.closeToastPopup();

        timeRef.current = setInterval(()=>{setTime((time)=>time+1)},500);

        // 01. search값 hash 존재   =>   앱에서 진입 (앱에서는 보안상 hash값 암호화 되어서옴)
        if (mobileLocationSearch.includes('hash') || mobileLocationSearch.includes('userId')) {

            // 02. web전용 (경조사 페이지)
            if (mobileLocationSearch.includes('userId')) {
                const searchParamObject = getQueryStringParams(mobileLocationSearch);
                mobileLocationSearch = '?hash=' + getHashUserId(searchParamObject.userId);
            }
            // else if (mobileLocationSearch.includes('notificationId')) { // 03. notificationId (푸시 읽기용)
            //     const searchParamObject = getQueryStringParams(mobileLocationSearch);
            //     mobileLocationSearch = '?hash=' + searchParamObject.hash;
            // }

            let qs = queryString.parse(mobileLocationSearch);
            if (Object.keys(qs).length === 0) return;
            const param = {
            }

            // onboarding redirect 시 계속 들어오게됨
            const search = props.location.search;
            const redirect = props.location.state?.redirect;

            let _search = new URLSearchParams();
            let searchParams = '';

            new URLSearchParams(search).forEach((v,k)=>{
                // if(k==='itemId') _search.append(k,v);
                if(env.ALLOWED_URL_PARAMETERS.findIndex(p => p === k)!==-1) _search.append(k,v);
            });

            if(_search.toString()) searchParams = `?${_search.toString()}`;

            // alert(mobileLocationSearch);
            //02. 모바일
            signQueryIn(param, mobileLocationSearch)
                .then(res=>{
                    // 인증 성공 시 정보 저장 후 home으로 redirect
                    if(res.status===200){
                        // 인증 성공 시 정보 저장 후 home으로 redirect
                        // alert('signQueryIn Success');
                        init(res.data.userId, res.data.refreshToken, res.data.accessToken).then(()=>{
                            // alert(`move to page ${redirect}`);
                            props.history.replace({
                                pathname : redirect && redirect !== routes.AUTH ? redirect : routes.HOME,
                                search : searchParams
                            });

                            //앱 인증 여부
                            setTimeout(function () {
                                appWebViewAuth(true);
                            }, 100);

                        }).catch(e=>{
                            // init에 실패한 경우 catch
                            console.log(e.toString());
                            // alert('error 1');
                            props.history.replace({
                                //오류 페이지 이동
                                pathname : routes.AUTH_ERROR,
                                search : searchParams
                            });

                            //앱 인증 여부
                            setTimeout(function () {
                                appWebViewAuth(false);
                            }, 100);
                        });
                    }else {
                        // 유효기간 만료 -> 에러페이지이동
                        if (res.data.code === "invalid-hash-time") {
                            console.log("invalid-hash-time");
                            props.history.replace({
                                //오류 페이지 이동
                                pathname : routes.AUTH_ERROR,
                                search : searchParams
                            });
                            // alert('error 2');
                        }
                    }
                })
                .catch(e=>{
                    // signin 살패한 경우 catch
                    // 사용자가 등록되지 않은 경우
                    const _status = e?.status || e.response.status;
                    const _statusCode = e?.data?.statusCode || e.response.data.statusCode;

                    if(_status===401){
                        const _userId = e?.data?.payload?.userId || e.response.data.payload.userId;
                        return saveUser(_userId)
                    }else if(_status===500){
                        // @todo incident 일 경우, 로그인 페이지로 (ad인증)
                        props.history.replace({
                            //오류 페이지 이동
                            pathname : routes.AUTH_ERROR,
                            search : searchParams
                        });
                        // alert('error 3');
                        console.log(e);
                    }else{
                        props.history.replace({
                            //오류 페이지 이동
                            pathname : routes.AUTH_ERROR,
                            search : searchParams
                        });
                        console.log(e);
                        // alert('error 4');
                    }
                })
                .then(res=>{
                    if(res?.status===200){
                        return signQueryIn(param, mobileLocationSearch)
                    }
                })
                .then(res=>{
                    if(res?.status===200){
                        // 인증 성공 시 정보 저장 후 home으로 redirect
                        init(res.data.userId, res.data.refreshToken, res.data.accessToken).then(()=>{
                            props.history.replace({
                                pathname : redirect && redirect !== routes.AUTH  ? redirect : routes.HOME,
                                search : searchParams
                            });
                        }).catch(e=>{
                            // 사용자 등록 후 재인증에 실패한 경우 catch
                            console.log(e);
                            // alert('error 5');
                            props.history.replace({
                                //오류 페이지 이동
                                pathname : routes.AUTH_ERROR,
                                search : searchParams
                            });
                        });;
                    }
                })
                .catch(e=>{
                    console.log(e);
                    props.history.replace({
                        //오류 페이지 이동
                        pathname : routes.AUTH_ERROR,
                        search : searchParams
                    });
                })
        }else {   //기존 ACCESSTOKEN만료시
            const param = {
                deviceType: getOS(),
                osType : navigator.userAgent
            }

            // onboarding redirect 시 계속 들어오게됨
            const search = props.location.search;
            const redirect = props.location.state?.redirect;

            let _search = new URLSearchParams();
            let searchParams = '';

            //아이템 아이디만 가지오기
            new URLSearchParams(search).forEach((v,k)=>{
                // if(k==='itemId') _search.append(k,v);
                if(env.ALLOWED_URL_PARAMETERS.findIndex(p => p === k)!==-1) _search.append(k,v);
            });

            if(_search.toString()) searchParams = `?${_search.toString()}`;

            // 1. 인증 시작
            signIn(param)
                .then(res=>{
                    // 인증 성공 시 정보 저장 후 home으로 redirect
                    console.log(res);
                    if(res.status===200){
                        // 인증 성공 시 정보 저장 후 home으로 redirect
                        init(res.data.userId, res.data.refreshToken, res.data.accessToken).then(()=>{
                            props.history.replace({
                                pathname : redirect && redirect !== routes.AUTH ? redirect : routes.HOME,
                                search : searchParams
                            });
                        }).catch(e=>{
                            // init에 실패한 경우 catch
                            console.log(e.toString());
                            // alert('error 6');
                            props.history.replace({
                                //오류 페이지 이동
                                pathname : routes.AUTH_ERROR,
                                search : searchParams
                            });
                        });
                    }
                })
                .catch(e=>{
                    // signin 살패한 경우 catch
                    // 사용자가 등록되지 않은 경우
                    const _status = e?.status || e.response.status;
                    const _statusCode = e?.data?.statusCode || e.response.data.statusCode;

                    if(_status===401){
                        const _userId = e?.data?.payload?.userId || e.response.data.payload.userId;
                        return saveUser(_userId)
                    }else if(_status===500){

                        //01. WEB 페이지일경우 userId가지오기
                        const _userId = e?.data?.payload?.userId || e.response.data.payload.userId;
                        return saveUser(_userId)
                        // alert('error 7');
                        // @todo incident 일 경우, 로그인 페이지로 (ad인증)
                        props.history.replace({
                            //오류 페이지 이동
                            pathname : routes.AUTH_ERROR,
                            search : searchParams
                        });
                        console.log(e);

                    }else{
                        // alert('error 8');
                        props.history.replace({
                            //오류 페이지 이동
                            pathname : routes.AUTH_ERROR,
                            search : searchParams
                        });
                        console.log(e);
                    }
                })
                .then(res=>{
                    if(res?.status===200){
                        return signIn(param)
                    }
                })
                .then(res=>{
                    if(res?.status===200){
                        // 인증 성공 시 정보 저장 후 home으로 redirect
                        init(res.data.userId, res.data.refreshToken, res.data.accessToken).then(()=>{
                            props.history.replace({
                                pathname : redirect && redirect !== routes.AUTH ? redirect : routes.HOME,
                                search : searchParams
                            });
                        }).catch(e=>{
                            // alert('error 9');
                            // 사용자 등록 후 재인증에 실패한 경우 catch
                            props.history.replace({
                                //오류 페이지 이동
                                pathname : routes.AUTH_ERROR,
                                search : searchParams
                            });
                            console.log(e);
                        });
                    }
                })
                .catch(e=>{
                    // alert('error 10');
                    props.history.replace({
                        //오류 페이지 이동
                        pathname : routes.AUTH_ERROR,
                        search : searchParams
                    });
                    console.log(e);
                })
        }

        return ()=>{
            clearInterval(timeRef.current);
        }
    },[]);

    return (
        <AuthContainer>
            <AuthImageWrap>
                <LoadingImage src={LoadingImg} />
                <LoadingText>{'마카롱 포장중'}<span>{createLoadingDot()}</span></LoadingText>
            </AuthImageWrap>
        </AuthContainer>
    )
}

export default observer(AuthPage);