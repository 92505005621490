export const getBoolFromYn = (Yn) => {
    if(Yn==='Y' || Yn==='YES' || Yn==='1' || Yn==='TRUE' || Yn==='T') return true;
    return false;
}

export const getYnFromBool = (bool) => {
    if(bool) return 'Y';
    else return 'N';
}

export const getMaxLenCheck = (contentLen, maxLen) => {
    if (contentLen <= maxLen) {
        return true;
    }else return false;
}