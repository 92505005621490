import styled from "styled-components";

export const CheckboxInput = styled.input`
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    width: 1px;
`;

export const CheckboxWrap = styled.div`
    display : flex;
    align-items: center;
    gap : 5px;
`

export const CheckboxLabel = styled.div`
    flex : none;
    // font-weight: 400;
    color : ${({checked,theme,disabled})=>disabled ? '#E5EBF0' : checked ? theme.colors.black : theme.colors.gray_50};
    
    // font-size: 17px;
    // line-height: 25px;
    
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    
    
`