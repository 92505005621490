import styled from "styled-components";

export const Languages = styled.div`
    display : flex;
    flex-direction : column;
    gap : 10px;
`

export const Language = styled.div`
    padding : 10px 14px;
    border-radius: 10px;
    background: #FFFFFF;
    display : flex;
    gap : 15px;
    align-items : center;
    
    // &:hover {
    //     background: #F7F7F7;
    // }
    
    &.active {
        background: #DEE4EA;
    }
`

export const LanguageText = styled.div`
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #191E29;
    flex-grow : 1;
`

