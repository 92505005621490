import {observer} from "mobx-react";
import {FileConfirmModal} from "../global";

const FileConfirmModalToast = (props) => {

    // const {store} = useStores();
    const modalToastPopupStore = props.modalToastPopupStore;
    const goHistoryBack = props.goHistoryBack;

    //데이터
    const data = modalToastPopupStore;

    // 데이터 아이템
    const isOpen = data.isOpen;
    const setCancelPopup = data.setCancelPopup;
    const failFiles = data.failFiles;
    const isFailAll = data.isFailAll;
    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

        //취소 버튼
    const onCancelHandler = (e) => {
            //1. 이전 모달창 닫기 여부 (취소)
            setCancelPopup(false);
            //2. 현재 모달토스트팝업창 강제뒤로가기
            goHistoryBack();
        }

    // ok 버튼 (창을 다 닫기)
    const onOkHandler = (e) => {
        //1. 이전 모달창 닫기 여부 (ok)
        setCancelPopup(true);
        //2. 이전 토스트팝업창 버튼 눌렀을시 여부
        goHistoryBack();
    }

    /******************************************************************************************************************/

    /**
     *
     *
     * @returns {JSX.Element}
     */

    return (
        <>
            <FileConfirmModal
                isOpen={isOpen}
                onOk={e=>onOkHandler()}
                files={failFiles}
                isFailAll={isFailAll}
            />
        </>
    );

}

export default observer(FileConfirmModalToast);