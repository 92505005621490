import {LoadingImage} from "../auth/AuthStyles";
import NoPostImg from "../../resources/icons/no_post.svg";
import {AppLinkButton, AppLinkButtonText, AppLinkContainer, AppLinkText} from "../../utils/appLinkStyles";
import {useTranslation} from "react-i18next";
import routes from "../../constants/routes.json";
import {useHistory} from "react-router-dom";

const Show = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const toHome = () => {
        history.replace(routes.HOME);
    }


    return(
        <AppLinkContainer>
            <LoadingImage src={NoPostImg} />
            <AppLinkText>{t('SHOW_TEXT')}</AppLinkText>
            <AppLinkButton onClick={toHome}>
                <AppLinkButtonText>{t('MOVE_TO_HOME')}</AppLinkButtonText>
            </AppLinkButton>
        </AppLinkContainer>
    )
}

export default Show;