import {action, makeObservable, observable, runInAction} from "mobx";
import {receiveResponse} from "../utils/api";

class UserStore {

    user = {};
    interests = [];
    stickers = [];
    userState = {};
    joinGroups = null;
    applicatedGroups = null;
    invitingGroups = null;
    isInvitingGroupsUpdate = false;

    constructor() {
        makeObservable(this, {
            user: observable, // 사용자 정보
            interests: observable, // 사용자의 관심사
            stickers: observable, // 사용자가 획득한 스티커
            userState: observable, //
            joinGroups: observable,        // 내가 가입한 그룹
            applicatedGroups: observable,  // 내가 가입신청한 목록
            invitingGroups : observable,   // 사용자가 받은 초대
            isInvitingGroupsUpdate : observable, // 사용자가 받은 초대가 업데이트 되었는지 flag
            init: action,

            updateUser: action,
            updateInterest: action,
            updateStickers: action,
            updateUserState: action,
            updateJoinGroups: action,
            updateApplicatedGroups: action,
            updateInvitingGroups : action,

            setUser: action, // 4버전의 @action과 동일합니다.
            setInterests: action,
            setStickers: action,
            setUserState: action,
            setJoinGroups: action,
            setApplicatedGroups: action,
            setInvitingGroups : action,
            setIsInvitingGroupsUpdate : action,

            removeUserInfo : action
        });
    }

    init = async (userId) => {
        await this.updateUser(userId);
        await this.updateInterest(userId);
        await this.updateStickers(userId);
        await this.updateUserState();
        await this.updateJoinGroups();
        await this.updateApplicatedGroups();
        await this.updateInvitingGroups();
    }

    updateUser = async (userId = this.user?.userId) => {
        if(!userId){
            return ;
        }

        const res = await receiveResponse({}, `user/get/${userId}`, 'get');
        runInAction(() => {
            console.log('userStore.user', res);
            this.user = {...res.data};
        });
    }

    updateInterest = async (userId = this.user?.userId) => {
        if(!userId){
            return ;
        }

        const res = await receiveResponse({}, `interest/interests/${userId}`, 'get');
        runInAction(() => {
            console.log('userStore.interest', res);
            this.interests = [...res.data];
        });
    }

    updateStickers = async (userId = this.user?.userId) => {
        if(!userId){
            return ;
        }

        const res = await receiveResponse({}, `user/${userId}/stickers`, 'get');
        runInAction(() => {
            console.log('userStore.sticker', res.data);
            this.setStickers(res.data);
        });
    }

    updateUserState = async () => {
        const res = await receiveResponse({}, 'user/state', 'get');
        runInAction(() => {
            console.log('userStore.userState', res.data);
            this.setUserState(res.data);

        });
    }

    updateJoinGroups = async () => {
        const res = await receiveResponse({target: 'joined', pinFirst : true}, 'groups', 'get');
        runInAction(() => {
            console.log('userStore.joinGroups', res.data.list);
            this.setJoinGroups(res.data.list);
        });
    }

    updateApplicatedGroups = async () => {
        const res = await receiveResponse({target: 'applicated'}, 'groups', 'get');
        runInAction(() => {
            console.log('userStore.applicatedGroups', res.data.list);
            this.setApplicatedGroups(res.data.list);
        });
    }

    updateInvitingGroups = async () => {
        const res = await receiveResponse({target: 'invited'}, 'groups', 'get');
        runInAction(() => {
            console.log('userStore.invitingGroups', res.data.list);
            this.setInvitingGroups(res.data.list);
        });
    }

    setUser = (user) => {
        this.user = {...this.user, ...user}
    }

    setInterests = (interests) => {
        this.interests = interests;
    }

    setStickers = (stickers) => {
        this.stickers = (stickers??[]).sort((a, b) => {
            return a.stickerId - b.stickerId;
        });
    }

    setUserState = (userState) => {
        this.userState = {...this.userState, ...userState};
    }

    setJoinGroups = (joinGroups) => {
        this.joinGroups = (joinGroups??[]).sort((a, b) => {
            if (a.pinDate && b.pinDate) return new Date(b.pinDate) - new Date(a.pinDate);
            else if (a.pinDate) return -1;
            else if (b.pinDate) return 1;
            else return new Date(b.joinDate) - new Date(a.joinDate);
        });
    }

    setApplicatedGroups = (applicatedGroups) => {
        this.applicatedGroups = applicatedGroups;
    }

    setInvitingGroups = (invitingGroups) => {
        this.invitingGroups = invitingGroups;
    }

    setIsInvitingGroupsUpdate = (isUpdate) => {
        this.isInvitingGroupsUpdate = isUpdate;
    }

    removeUserInfo = () => {
        this.user = {};
        this.interests = [];
        this.stickers = [];
        this.userState = {};
        this.joinGroups = null;
        this.applicatedGroups = null;
        this.invitingGroups = null;
        this.isInvitingGroupsUpdate = false;
    }
}

export default UserStore;