import {action, makeObservable, observable, runInAction} from "mobx";
import {receiveResponse} from "../utils/api";
import isEmpty from 'is-empty';

class ItemStore {

    recommendCommunicateItems = null;

    recommendAllGroupItems = null;

    recommendMyGroupItems = null;

    groupStats = {};

    constructor() {
        makeObservable(this, {
            recommendCommunicateItems: observable,
            recommendAllGroupItems: observable,
            recommendMyGroupItems: observable,
            groupStats: observable,

            init: action,
            updateRecommendCommunicateItems : action,
            selectRecommendMyGroupItems: action,
            setRecommendMyGroupItems : action
        });
    }

    init = async () => {

        // 소통해요 추천
        if (isEmpty(this.recommendCommunicateItems)) {
            let params = {
                type: "F",
                target : "recommend"
            }
            const res = await receiveResponse(params, 'items', 'get');
            runInAction(() => {
                if (res.status === 200) {
                    console.log('topicStore.recommend-Communicates', res.data);
                    this.recommendCommunicateItems = res.data;
                }
            });
        }

        // 그룹 추천
        if (isEmpty(this.recommendAllGroupItems)) {
            let params = {
                type: "G",
                target : "recommend"
            }
            const res = await receiveResponse(params, 'items', 'get');
            runInAction(() => {
                if (res.status === 200) {
                    console.log('topicStore.recommend-All-Groups', res.data);
                    this.recommendAllGroupItems = res.data;
                }
            });
        }

        // 그룹 통계
        if (isEmpty(this.groupStats)) {
            const res = await receiveResponse({},'stats', 'get');
            runInAction(() => {
                if (res.status === 200) {
                    console.log('topicStore.group-Stats', res.data);
                    // "group-user": 114,
                    //  "group": 67
                    //  "group": 67
                    this.groupStats = res.data;
                }
            });
        }

    }

    updateRecommendCommunicateItems = async () => {

        let params = {
            type: "F",
            target : "recommend"
        }

        const res = await receiveResponse(params, 'items', 'get');

        runInAction(() => {
            if (res.status === 200) {
                console.log('topicStore.recommend-Communicates', res.data);
                this.recommendCommunicateItems = res.data;
            }
        });
    }

    // 내가 가입한 그룹 추천 (15개)
    selectRecommendMyGroupItems = async (params, callback) => {

        // page : page,
        // size : 15
        params = { ...params, target : "joined",}
        const res = await receiveResponse(params, 'items', 'get');

        runInAction(() => {
            if (res.status === 200) {
                console.log('topicStore.recommend-MyGroupItems', res.data);
                this.recommendMyGroupItems = res.data;
                callback();
            }
        });
    }

    setRecommendMyGroupItems = (list) => {
        this.recommendMyGroupItems = list;
    }


}

export default ItemStore;