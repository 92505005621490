import styled from "styled-components";

export const CommentFiledContainer = styled.div`
   
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    
    
    // &.modify {
    //     position: initial;
    // }
    //
    // &.reComment {
    //     position: initial;
    //     padding : 0px 16px;
    // }
`

export const CommentFieldImgWrap = styled.div`
    width : 50px;
    height : 50px;
`

export const CommentFieldImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`

export const CommentFieldWrap = styled.div`
    width : 100%;
    background: #FFFFFF;
    
    
    
    
    /*
    box-sizing : border-box;
    border: ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
    position : relative;
    background : ${({theme})=>theme.colors.white};
    padding : 10px 20px;
    display : ${({type})=>{
        if(type==='reComment') return 'flex';
    }};

    align-items : ${({type})=>{
        if(type==='reComment') return 'flex-start';
    }};

    border-radius : ${({type})=>{
        if(type==='reComment') return '32px';
        return '10px';
    }};

    padding : ${({type})=>{
        if(type==='reComment') return '11px';
        return '20px 30px';
    }};
    */
    

    border-top: 1px solid #EAEAEA;
    
`

export const CommentFieldHeaderWrap = styled.div`
    display : flex;
    gap : 16px;
    align-items : center;
    flex : none;
    
    padding-bottom : ${({type})=>{
        if(type==='re') return;
        return '10px';
    }};
    
    padding-right : ${({type})=>{
        if(type==='re') return '20px';
    }};
    
    padding : ${({type})=>{
        if(type==='re') return '9px 20px 9px 21px';
    }};
`

export const CommentFieldNickname = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${({theme})=>theme.colors.black};
`

export const CommentFieldWirteWrap = styled.div`
    background: #F1F5F9;
    padding : 8px 20px 8px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    gap: 10px;
`

export const CommentFieldWirte = styled.strong`
    font-weight: 700;
`

export const CommentFieldWirteContent = styled.div`
    padding-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const CommentFieldInputWrap = styled.div`

    max-height : 200px;
    min-height : 30px;
    padding : 10px 10px 6px 10px;
    overflow : overlay;
    display: block;
    position : relative;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    
    &.close {
        display: none;
    }
    
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 17px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: ${({theme})=>theme.colors.gray_stroke_100};
        border-radius: 17px;
    } 
`

export const CommentFieldInput = styled.textarea`
    width : 100%;
    display : block;
    outline : none;
    border : none;
    resize: none;
    font-size : 14px;
    line-height : 20px;
    overflow: hidden;
    
    ::placeholder {
        color: ${({theme}) => theme.colors.gray_50};
    }
  
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${({theme}) => theme.colors.gray_50};
    }
  
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${({theme}) => theme.colors.gray_50};
    }
   
`

export const CommentDefulatFieldWrap = styled.div`
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E5EBF0;
    border-radius: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #AEB7C0;
    
    padding: 20px;
    display: flex;
    align-items: center;
`


export const CommentFieldFileWrap = styled.div`
    padding-top : 10px;
    display : flex;
    gap : 5px;
    width : 100%;
    flex-wrap : wrap;
`

export const CommentFieldBottoms = styled.div`
    height : 69px;
    position : relative;
    display : flex;
    padding: 10px 0px 20px 0px;
`

export const CommentFieldDefaultBottoms = styled.div`
    height : 69px;
    position : relative;
    display : flex;
    padding: 10px 0px 20px 0px;
    // gap : 10px;
    // border-top: 1px solid rgba(0,0,0,0.1);
`

export const CommentFieldButtons = styled.div`
    display : flex;
    gap : 5px;
`

export const CommentFieldMenuWrap = styled.div`
    position : absolute;
    z-index : 2;
    left : ${({type})=>{
        if(type==='m') return;
        if(type!=='re') return '-38px';
    }};
    margin-top : ${({type})=>{
        if(type!=='re') return '2px'
    }};

    top : ${({type})=>{
        if(type==='re') return '42px'
    }};
    right : ${({type})=>{
        if(type==='re') return '94px'
    }};
`

export const CommentFieldIcons = styled.div`
    display : flex;
    gap : 5px;
    padding-left : 10px;
    flex : none;
    align-items : center;
    flex-grow : 1;
`

export const CommentFieldDefaultIcons = styled.div`
    display : flex;
    gap : 5px;
    padding-left : 10px;
    flex : none;
    align-items : center;
`



export const CommentDefaultFieldSubmit = styled.div`
    justify-content: center;
    display : flex;
    padding-left: 14px;
    padding-right: 20px;
    align-items : center;
    width : 100%;
`

export const CommentFieldSubmit = styled.div`
    display : flex;
    gap : 10px;
    align-items : center;
`

export const SubmitButtons = styled.div`
    display : flex;
    gap : 5px;
    padding-right: 10px
`

export const SubmitButton = styled.div`
    padding: 5px 17px;
    border-radius: 16px;
    font-size: 15px;
    line-height: 22px;
    color: ${({disabled})=>disabled ? '#75818D' : '#FFFFFF'};
    background: ${({disabled})=>disabled ? '#F2F4F6' : '#353C49'};
`

export const CancelButton = styled.div`
    padding: 4px 17px;
    border-radius: 16px;
    font-size: 15px;
    line-height: 22px;
    color : #191E29;
    background: #FFFFFF;
    
    border: 1px solid #E5EBF0;
`