import {Redirect, Route, useLocation} from "react-router-dom";
import routes from '../constants/routes.json';
import {getCookieValue, validateJwt} from "../utils/ssoHandler";
import {useStores} from "../stores";
import env from "../constants/env";
import {useEffect} from "react";

const AuthRoute = ({Component, computedMatch, ...rest}) => {

    const refreshToken = getCookieValue(env.refreshToken);
    const location = useLocation();
    const {store} = useStores();

    useEffect(()=>{
        // alert(location.pathname);
    },[])

    return(
            <Route
                {...rest}
                render={(props) => {
                    if(validateJwt(refreshToken)){
                        return <Component {...props} {...store} />
                    }else{
                        return <Redirect to={{
                            pathname : routes.AUTH,
                            search : location.search,
                            state : { redirect : `${location.pathname}`}
                        }} />
                    }
                }}
            />
    )
}

export default AuthRoute;