import { action, makeObservable, observable } from "mobx";

class AuthStore {

    accessToken = '';
    refreshToken = '';
    isAuth = false;
    //앱 이벤트 연동 정보
    isAppReady = false;
    //앱 푸시 토큰
    appToken = '';
    //토큰 아이디
    fmcTokenId = '';

    constructor() {
        makeObservable(this, {
            accessToken : observable,
            refreshToken: observable,
            isAuth : observable,
            isAppReady : observable,
            appToken : observable,
            fmcTokenId : observable,
            init: action,
            setRefreshToken: action,
            setAccessToken: action,
            setIsAuth : action,
            setIsAppReady : action,
            setAppToken : action,
            setFmcTokenId : action
        });
    }

    init = (refreshToken, accessToken) => {
        this.refreshToken = refreshToken;
        this.accessToken = accessToken;
        this.isAuth = true;
    };

    setRefreshToken = (refreshToken) => {
        this.refreshToken = refreshToken;
    }

    setAccessToken = (accessToken) => {
        this.accessToken = accessToken;
    }

    setIsAuth = (isAuth) => {
        this.isAuth = isAuth;
    }

    setIsAppReady = (isAppReady) => {
        this.isAppReady = isAppReady;
    }

    setAppToken = (appToken) => {
        this.appToken = appToken;
    }

    setFmcTokenId = (fmcTokenId) => {
        this.fmcTokenId = fmcTokenId;
    }
}

export default AuthStore;