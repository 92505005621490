import {ButtonText, ButtonWrap} from "./ButtonStyles";

const Button = (props) => {

    const {type, shape, disabled, width, height, textStyle, wrapperStyle, onClick} = props;

    const onClickHandler = () => {
        if(disabled) return;
        if(onClick && typeof onClick === 'function'){
            onClick();
        };
    }

    return(
        <ButtonWrap
            type={type}
            shape={shape}
            disabled={disabled}
            width={width}
            height={height}
            style={wrapperStyle}
            onClick={e=>onClickHandler()}
        >
            <ButtonText style={textStyle} type={type} disabled={disabled}>
                {props.children}
            </ButtonText>
        </ButtonWrap>
    )
}

Button.defaultProps = {
    type : 'mobile', // mobile, primary, secondary, tertiary (default : mobile)
    shape : 'round', // round, circle (default : default)
    disabled : false,
    width : '102px',
    height : '48px',
    textStyle : null,
    wrapperStyle : null,
    onClick : null
}

export default Button;