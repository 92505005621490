import {
    CommentDefaultFieldSubmit,
    CommentDefulatFieldWrap,
    CommentFieldBottoms,
    CommentFieldDefaultBottoms,
    CommentFieldDefaultIcons,
    CommentFieldFileWrap,
    CommentFieldIcons,
    CommentFieldInput,
    CommentFieldInputWrap,
    CommentFieldSubmit,
    CommentFieldWirte,
    CommentFieldWirteContent,
    CommentFieldWirteWrap,
    CommentFieldWrap,
    CommentFiledContainer,
    SubmitButtons
} from "./CommentFiledStyles";
import {AttachFile, AttachImage, Button, Checkbox, IconButton} from "./index";
import UnSecretIcon from '../../../resources/icons/comment_unsecret.svg';
import SecretIcon from '../../../resources/icons/comment_secret.svg';
import FileIcon from '../../../resources/icons/attach_file.svg';

import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {getBoolFromYn, getMaxLenCheck, getYnFromBool} from "../../../utils/boolHandler";
import {checkFilesLength, getAvailableFiles, getFileObjs} from "../../../utils/fileHandler";
import isEmpty from 'is-empty';
import {removeBlank, textValidator} from "../../../utils/textHandler";
import {getFormData, receiveResponse} from "../../../utils/api";
import {useStores} from "../../../stores";
import env from "../../../constants/env";

const CommentField = (props) => {

    const {
        type,
        isModify,
        itemId,
        placeholder,
        onSubmit,
        onChange,
        upperCommentId,
        commentId,
        isOpen,
        parentProps,
        callback,
        selectedCommentId,
        loadingCallback,
        page
    } = props;
    // comment ref
    const textRef = useRef();
    // 이미지 첨부 ref
    const imgRef = useRef();
    // 파일 첨부 ref
    const fileRef = useRef();
    // 스크롤 box ref
    const inputScrollRef = useRef();
    // 바텀 댓글 open ref
    const bottomCommentRef = useRef();

    // 익명 여부
    const [isAnonymous, setIsAnonymous] = useState(getBoolFromYn(props?.anonymousYn));
    // 비밀 댓글 여부
    const [isSecret, setIsSecret] = useState(getBoolFromYn(props?.commentSecretYn));
    // 댓글 내용
    const [commentContent, setCommentContent] = useState(props?.commentContent || '');
    // 이미지, 파일 첨부
    const [imgFiles, setImgFiles] = useState([]);
    // 이미지 파일 object url 리스트
    const [imgFilesUrl, setImgFilesUrl] = useState([]);
    // 파일 첨부
    const [otherFiles, setOtherFiles] = useState([]);
    // 댓글 입력창 오픈 여부
    const [isOpenCommentsWrite, setIsOpenCommentsWrite] = useState(false);

    // 내 이미지 가져오기
    const {store} = useStores();
    const {userStore, toastPopupStore, modalToastPopupStore} = store;

    // 이전 댓글쓰기 스크롤 창 높이 (초기값)
    const beforeInputScrollClentHeight = 41;



    /******************************************************************************************************************/


    /**
     * 01. 함수
     *
     *
     */


    /**
     * @todo
     * 1. 댓글 최대 1000자
     * 2. 사진 파일 : 최대 10개까지 첨부 > 파일 알럿 띄우기
     * 3.
     *
     */
    const handleResizeHeight = () => {

        if (textRef.current) {
            textRef.current.style.height = '25px';
            // content 없을 경우 scroll 0 되는 것 방지
            if (!isEmpty(commentContent) || !isEmpty(imgFiles) || !isEmpty(otherFiles))
                textRef.current.style.height = textRef.current.scrollHeight + 'px';
        }

        if (inputScrollRef.current) {
            // 텍스트 인풋 박스 없을 경우 리턴
            if (inputScrollRef.current.clientHeight === 0) return;

            // 기존 높이에서 넘어갈 경우
            if (beforeInputScrollClentHeight < inputScrollRef.current.clientHeight) {
                document.querySelector("#itemDetailModal").style.height = `calc(100% - ${bottomCommentRef.current.clientHeight}px)`;
                // document.querySelector("#itemDetailModal").scrollTop = document.querySelector("#itemDetailModal").scrollTop + (inputScrollRef.current.clientHeight - commentWriteHeight);
            } else if (beforeInputScrollClentHeight === inputScrollRef.current.clientHeight) { // 전값이랑 같을 경우
                // 이미지파일 삭제, 다른 파일 삭제, 빈 글 (열려 있을 경우)
                if (isOpenCommentsWrite) document.querySelector("#itemDetailModal").style.height = `calc(100% - ${bottomCommentRef.current.clientHeight}px)`;
                else return;
            }
        }
    }

    // 수정 취소
    const cancelModifyHandler = () => {
        //취소시 초기화
        if (callback && typeof callback === 'function') {
            initBottomClentHeight();

            setCommentContent('');
            setIsSecret(false);
            setIsAnonymous(false);
            setOtherFiles([]);
            setImgFiles([]);
            setIsOpenCommentsWrite(false);

            // 이미지 url들 전부 삭제
            if(imgFilesUrl.length > 0){
                imgFilesUrl.forEach(url=>URL.revokeObjectURL(url));
                setImgFilesUrl([]);
            }

            callback();
        }

    }

    // 등록 이후 댓글 초기화
    const clearComment = (e) => {
        initBottomClentHeight();
        document.querySelector("#itemDetailModal").scrollTop = 0;


        setCommentContent('');
        setIsSecret(false);
        setIsAnonymous(false);
        setOtherFiles([]);
        setImgFiles([]);
        setIsOpenCommentsWrite(false);

        // 이미지 url들 전부 삭제
        if(imgFilesUrl.length > 0){
            imgFilesUrl.forEach(url=>URL.revokeObjectURL(url));
            setImgFilesUrl([]);
        }
    }

    // 파일 초기화  (로딩)
    const initFiles = () => {

        //로딩페이지
        if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(true);

        getFileObjs(props?.fileInfoList).then((files) => {
            getAvailableFiles(files).then(attachedFiles=>{
                setImgFiles(attachedFiles.imgs);
                setImgFilesUrl(attachedFiles.imgs.map(file=>URL.createObjectURL(file)));
                setOtherFiles(attachedFiles.others);
            })
        }).finally(()=>{ //로딩페이지
        if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(false);})
    }

    // 댓글 내용 초기화
    const initCommentContents = () =>{
        setCommentContent(props?.commentContent);
        setIsSecret(getBoolFromYn(props?.commentSecretYn));
        setIsAnonymous(getBoolFromYn(props?.anonymousYn));
    }

    // 댓글 창 열기 함수
    const setCloseCommentsWrite = () => {
        setIsOpenCommentsWrite(true);
    }

    // 댓글 창 닫기 함수
    const setOpenCommentsWrite = () => {
        setIsOpenCommentsWrite(false);
    }

    // 댓글 바텀 초기화
    const initBottomClentHeight = () => {
        if (document.querySelector("#itemDetailModal")) document.querySelector("#itemDetailModal").style.height = 'calc(100% - 70px)';
    };

    // 대댓글 작성중에 닉네임 보여줄지 여부
    /**
     * 2024.05.09
     * 숨김 처리도 삭제처럼 기능 추가
     * status === 'BLOCKED'
     * */
    const isShowReCommentNickname = (parentProps) => {
        // 1. 삭제되거나 신고된 댓글
        if(parentProps.deleteDate || parentProps.status==='DELETED' || parentProps.status==='BLOCKED') return false;
        // 2. 익명 댓글
        if(getBoolFromYn(parentProps.anonymousYn)) return false;
        // 3. 비밀 댓글
        if(getBoolFromYn(parentProps.commentSecretYn)){
            // 3-1. 내가 글 작성자인 경우
            if(userStore.user.userId === parentProps.itemRegUserId) return true;
            // 3-2. 내가 비밀댓글 작성자인 경우
            if(userStore.user.userId === parentProps.userId) return true;
            // 그렇지 않은 경우에는 안보이게
            return false;
        }
        return true;
    }

    // 대댓글 작성중에 내용 보여줄지 여부
    /**
     * 2024.05.09
     * 숨김 처리도 삭제처럼 기능 추가
     * status === 'BLOCKED'
     * */
    const getReCommentContent = (parentProps) => {
        // 1. 삭제되거나 신고된 댓글은 안보여짐
        if(parentProps.deleteDate || parentProps.status==='DELETED' || parentProps.status==='BLOCKED' ) return;
        // 2. 비밀 댓글은 아래의 경우가 아닐때만 보여짐
        if(getBoolFromYn(parentProps.commentSecretYn)){
            if(userStore.user.userId !== parentProps.itemRegUserId && userStore.user.userId !== parentProps.userId) return;
        }
        return parentProps.commentContent;
    }
    /******************************************************************************************************************/


    /**
     * 02. 이벤트
     *
     */

    // 파일 첨부 클릭
    const onClickFileAttach = (e) => {
        fileRef.current.click();
    }

    // 디폴트 인풋 클릭 이벤트
    const onClickCommentsInputWrap = (e) => {
        setIsOpenCommentsWrite(true);
    }

    // 대댓글, 댓글수정 취소
    const onClickCancelComments = () => {

        //취소시 초기화
        if (callback && typeof callback === 'function') {
            setIsOpenCommentsWrite(false);
            setCommentContent('');
            setIsSecret(false);
            setIsAnonymous(false);
            setOtherFiles([]);
            setImgFiles([]);

            // 이미지 url들 전부 삭제
            if(imgFilesUrl.length>0){
                imgFilesUrl.forEach(url=>URL.revokeObjectURL(url));
                setImgFilesUrl([]);
            }
            document.querySelector("#itemDetailModal").style.height = 'calc(100% - 70px)';

            //초기화넘기기
            callback();
        }
    }

    // content change
    const onChangeHandler = (e) => {
        // 글자수 안이면
        if(getMaxLenCheck(e.target.value.length, env.commentMaxLen)) {
            setCommentContent(textValidator(e.target.value,env.commentMaxLen));
            if (onChange && typeof onChange === 'function') {
                onChange(textValidator(e.target.value,env.commentMaxLen));
            }
        }
    }

    // 익명 체크
    const onCheckIsAnonymous = () => {
        setIsAnonymous(!isAnonymous);
    }

    // 비밀 체크
    const onCheckIsSecret = () => {
        setIsSecret(!isSecret);
    }

    // 파일 첨부 (로딩)
    const onChangeFile = (e) => {
        // 닫혀있을 떄 열기
        if (!isOpenCommentsWrite) {
            setIsOpenCommentsWrite(true);
        }

        //로딩페이지
        if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(true);

        if (!checkFilesLength(e.target.files, [...imgFiles, ...otherFiles])) {
            fileRef.current.value = '';
            //로딩페이지
            if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(false);
            openFileConfirmModal();
            return;
        }

        getAvailableFiles(e.target.files).then(res=>{
            let newFiles = res;
            setImgFiles([...imgFiles, ...newFiles.imgs]);
            setImgFilesUrl([...imgFilesUrl, ...newFiles.imgs.map(img=>URL.createObjectURL(img))]);
            setOtherFiles([...otherFiles, ...newFiles.others]);

            if(newFiles.fails.length>0){
                if(e.target.files.length === newFiles.fails.length) openFileConfirmModal([...newFiles.fails],true);
                else openFileConfirmModal([...newFiles.fails],false);
            };
            fileRef.current.value = '';

        }).finally(()=>{//로딩페이지
            if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(false);}
        );
    }

    // file confirm modal open
    const openFileConfirmModal = (failFiles=[]) => {
        modalToastPopupStore.initFiles('file',failFiles);
        modalToastPopupStore.openModalPopup();
    }

    const onDeleteImgFiles = (e, index) => {
        const _deleteUrl = imgFilesUrl[index]
        setImgFiles(imgFiles.slice(0,index).concat(imgFiles.slice(index+1,imgFiles.length)));
        setImgFilesUrl(imgFilesUrl.slice(0,index).concat(imgFilesUrl.slice(index+1,imgFilesUrl.length)));
        URL.revokeObjectURL(_deleteUrl);
    }

    const onDeleteOtherFiles = (e, index) => {
        setOtherFiles(otherFiles.slice(0, index).concat(otherFiles.slice(index + 1, otherFiles.length)));
    }

    // 제출 (등록, 수정) 로딩
    const onSubmitHandler = (e) => {

        //로딩페이지
        if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(true);

        let params = {
            'itemId': itemId,
            'anonymousYn': getYnFromBool(isAnonymous),
            'commentSecretYn': getYnFromBool(isSecret),
            'commentContent': commentContent,
            'fileList': [...imgFiles, ...otherFiles],
            // 'commentId': commentId
        }

        //등록일 떄는 comments upperCommentId(commentId 부모걸 등록)
        //수정일 때는 commetId upperCommentId(지꺼)
        let routes = !isModify ? 'comments' : `comments/${commentId}`;

        // 대댓글 ID
        if (upperCommentId) {
            params = {...params, upperCommentId}
        }

        receiveResponse(getFormData(params,'fileList'), routes, 'post', {'Content-Type': 'multipart/form-data'})
            .then(res => {
                if (res.status === 200) {
                    // @todo submit callback
                    clearComment();

                    //댓글 아이쳄 초기화 콜백
                    callback();

                    toastPopupStore.openToastPopup(isModify ? '댓글이 수정되었습니다.' : '댓글이 등록되었습니다.');
                    if(onSubmit && typeof onSubmit === 'function') onSubmit();
                }
            })
            .catch(e => {
                console.log(e);
            }).finally(()=>{
            //로딩페이지
            if (loadingCallback && typeof loadingCallback === 'function') loadingCallback(false);
        })
    }

    /******************************************************************************************************************/


    /**
     * 03. 컴포넌트
     *
     */

        // 이미지 파일 생성
    const createImgFiles = (imgFilesUrl) => {
            return imgFilesUrl.map((imgFileUrl,index) =>
                <AttachImage type={'upload'} src={imgFileUrl} onDelete={(e)=>onDeleteImgFiles(e,index)}/>
            )
        }

    const createOtherFiles = (files) => {
        return files.map((file, index) =>
            <AttachFile type={'upload'} fileName={file.name} onDelete={(e) => onDeleteOtherFiles(e, index)}/>
        );
    };

    const createCommentFieldBottoms = (type) => {
        switch (type) {
            // open
            case "open" : {
                return (
                    <CommentFieldBottoms>
                        <CommentFieldIcons>
                            {
                                page === 'web' ? null :
                                    <IconButton width={'24px'} height={'24px'} src={FileIcon} onClick={onClickFileAttach}/>
                            }
                        </CommentFieldIcons>
                        <CommentFieldSubmit>
                            <Checkbox wrapperStyle={{height:'18px'}} checked={isSecret} icon={UnSecretIcon} checkedIcon={SecretIcon} onCheck={onCheckIsSecret}/>
                            <Checkbox height={'24px'} checkLabelStyleWrap={{fontSize:'14px'}} label={'익명'} checked={isAnonymous} onCheck={onCheckIsAnonymous} />
                            <SubmitButtons>
                                {
                                    isModify ?
                                        <>
                                            <Button
                                                type={'secondary'}
                                                shape={'circle'}
                                                width={'50px'}
                                                height={'30px'}
                                                onClick={cancelModifyHandler}
                                            >취소</Button>

                                            <Button
                                                type={'mobile'}
                                                shape={'circle'}
                                                width={'50px'}
                                                height={'30px'}
                                                disabled={isEmpty(removeBlank(commentContent))}
                                                onClick={onSubmitHandler}>수정</Button>
                                        </> :

                                        <>
                                            <Button
                                                type={'secondary'}
                                                shape={'circle'}
                                                width={'60px'}
                                                height={'30px'}
                                                onClick={onClickCancelComments}>취소</Button>
                                            <Button
                                                type={'mobile'}
                                                shape={'circle'}
                                                width={'75px'}
                                                height={'30px'}
                                                disabled={isEmpty(removeBlank(commentContent))}
                                                onClick={onSubmitHandler}>남기기</Button>
                                        </>

                                }
                            </SubmitButtons>
                        </CommentFieldSubmit>
                    </CommentFieldBottoms>
                )
            }
            case "close" : {
                return (
                    <CommentFieldDefaultBottoms>
                        <CommentFieldDefaultIcons>
                            {page === 'web' ? null :
                                <IconButton width={'24px'} height={'24px'} src={FileIcon} onClick={onClickFileAttach}/>
                            }
                        </CommentFieldDefaultIcons>
                        <CommentDefaultFieldSubmit onClick={onClickCommentsInputWrap}>
                            <CommentDefulatFieldWrap>
                                {placeholder}
                            </CommentDefulatFieldWrap>
                        </CommentDefaultFieldSubmit>
                    </CommentFieldDefaultBottoms>
                )
            }
            default: {
                return (
                    null
                )
            }
        }
    }

    /******************************************************************************************************************/


    /**
     * 04. useEffect
     *
     */


    useLayoutEffect(()=>{
        setCommentContent(props.commentContent);
    },[props.commentContent]);

    useLayoutEffect(()=>{
        setIsAnonymous(getBoolFromYn(props.anonymousYn));
    },[props.anonymousYn]);

    useLayoutEffect(()=>{
        setIsSecret(getBoolFromYn(props.commentSecretYn));
    },[props.commentSecretYn]);

    useLayoutEffect(()=>{
        initFiles();
    },[props.fileInfoList]);

    useLayoutEffect(()=>{
        setIsOpenCommentsWrite(props.isCommentsWriteOpen);
    },[props.isCommentsWriteOpen]);

    useLayoutEffect(()=>{
        if(commentContent || imgFiles || otherFiles) handleResizeHeight();
        if(imgFiles || otherFiles){
            if(inputScrollRef && inputScrollRef.current ) inputScrollRef.current.scrollTop = inputScrollRef.current.scrollHeight;
        }
    },[commentContent,imgFiles,otherFiles]);

    useEffect(()=>{
        return ()=>{
            if(imgFilesUrl.length > 0) imgFilesUrl.forEach(url=>URL.revokeObjectURL(url)); // 이미지 url들 전부 삭제
        }
    },[]);

    // open시 커서이동
    useLayoutEffect(()=>{

        // 수정 또는 댓글쓰기
        if (isOpenCommentsWrite || isModify) {
            if (textRef.current) textRef.current.focus();
            if (document.querySelector("#itemDetailModal")) {
                document.querySelector("#itemDetailModal").style.height = `calc(100% - ${bottomCommentRef.current.clientHeight}px)`;
                // // document.querySelector("#itemDetailModal").scrollTop = document.querySelector("#itemDetailModal").scrollTop + inputScrollRef.current.clientHeight - 10;
                // setCommentWriteHeight(inputScrollRef.current.clientHeight);
            }
        }
        else if (!isOpenCommentsWrite || !isModify) {
            if (document.querySelector("#itemDetailModal")) {
                // if (document.querySelector("#itemDetailModal").scrollTop !== 0) {
                //     document.querySelector("#itemDetailModal").scrollTop = document.querySelector("#itemDetailModal").scrollTop - commentWriteHeight + 10;
                // }
                document.querySelector("#itemDetailModal").style.height = 'calc(100% - 70px)';
            }
        }
    }, [isOpenCommentsWrite, isModify, selectedCommentId]);  //(댓글창, 수정, 대댓글)

    //open시
    useEffect(()=>{
        if (isOpen) {
            // // 높이 초기화
            // if (document.querySelector("#itemDetailModal")) {
            //     document.querySelector("#itemDetailModal").style.height = 'calc(100% - 70px)';
            // }
        }else {
            //모달 닫을 때 초기화
            clearComment();
        }
    },[isOpen]);

    return (
        <>
            {/* 댓글 쓰기 (, 댓글 수정, 대댓글) */}
            <CommentFiledContainer>
                <CommentFieldWrap ref={bottomCommentRef}>
                    {/* 대댓글 작성중 */}
                    { isOpenCommentsWrite && upperCommentId && parentProps && !isModify ?
                        <CommentFieldWirteWrap>
                            {/* 닉네임 */}
                            {
                                isShowReCommentNickname(parentProps) ?
                                    <>
                                        <CommentFieldWirte>{parentProps.nickname}</CommentFieldWirte>{'님에게 대댓글 작성중'}
                                    </> : '대댓글 작성중'
                            }
                            {/* 내용 */}

                            <CommentFieldWirteContent>{getReCommentContent(parentProps)}</CommentFieldWirteContent>
                        </CommentFieldWirteWrap> : null
                    }

                    {/*댓글 글쓰기   (댓글쓰기, 댓글 수정, 대댓글) */}
                    <CommentFieldInputWrap className={ isOpenCommentsWrite ? 'open' : 'close'} ref={inputScrollRef}>
                        <CommentFieldInput
                            maxLength={env.commentMaxLen}
                            placeholder={placeholder}
                            ref={textRef}
                            onChange={onChangeHandler}
                            value={commentContent}
                        />
                        {
                            imgFiles && imgFiles.length !== 0 ?
                                <CommentFieldFileWrap>
                                    {createImgFiles(imgFilesUrl)}
                                </CommentFieldFileWrap> : null
                        }
                        {
                            otherFiles && otherFiles.length !== 0 ?
                                <CommentFieldFileWrap>
                                    {createOtherFiles(otherFiles)}
                                </CommentFieldFileWrap> : null
                        }
                    </CommentFieldInputWrap>

                    {/*댓글 버튼*/}
                    {createCommentFieldBottoms(isOpenCommentsWrite ? 'open' : 'close')}
                </CommentFieldWrap>
            </CommentFiledContainer>

            <input
                type={'file'}
                ref={fileRef}
                hidden={true}
                accept={'*'}
                multiple={'multiple'}
                onChange={(e)=>{onChangeFile(e)}}
            />
        </>
    )
}

CommentField.defaultProps =
{

    type : 'comment', // comment, reComment
    isOpen: false,
    isModify : false, // true, false
    placeholder : '훈훈한 댓글 부탁드려요.',
    itemId: '',
    userId: '',
    commentContent: '',
    myImgId: '', // myImgId
    anonymousYn: 'N',
    commentSecretYn: 'N',
    commentId: '',
    upperCommentId: null,
    deleteDate: '',
    onSubmit: null, // submit callback
    isAnonymous: false,
    isSecret: false,
    onAttach: null,
    onChange: null,
    onCheck: null,
    cancelModify : null,
    cb: null,
    isCommentsWriteOpen: false,
    parentProps: null,
    loadingCallback: null,
    page: 'app'
}

export default CommentField;