import styled from "styled-components";

export const AuthContainer = styled.div`
    width : 100vw;
    height : 100vh;
    display : flex;
    align-items : center;
    justify-content : center;
`

export const AuthImageWrap = styled.div`
    display : flex;
    flex-direction : column;
    gap : 43px;
    justify-content : center;
    align-items : center;
`

export const LoadingImage = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 160px;
    height : 140px;
`

export const LoadingText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #191E29;
`