import styled from "styled-components";

export const EditNicknameModalBody = styled.div`
    margin-top: 10px;
    background: #F7F9FB;
    padding: 15px 0px 16px 23px;
    margin-left: -16px;
    margin-right: -16px;

`

export const EditNicknameModalGuides = styled.ul`
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #75818D;
`

export const EditNicknameModalGuide = styled.li`
`

export const EditNicknameInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`

export const EditNicknameInputWrap = styled.div`
  box-sizing: border-box;
  gap : 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 9px;
  margin: 20px 0px;
  width: 100%;

  background: #FFFFFF;
  border: 1px solid #E5EBF0;
  border-radius: 12px;
  
  &.focus {
    border : 1px solid #3A6AF6;
  }
`

export const EditNicknameInput = styled.input`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 145px;

  color: #191E29;
  border: none;
  outline: none;
  flex-grow : 1;
  
  ::placeholder {
      color: #AEB7C0;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #AEB7C0;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
      color: #AEB7C0;
  }
`

export const EditNicknameDeleteWrap = styled.div`
    
    display: flex;
    
    &.on {
        display : none;
    }
`

export const EditNicknameCountWrap = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color : rgba(174, 183, 192, 1);
    display : flex;
`

export const EditNicknameCount = styled.div`
    color : #191E29;
`

export const EditNicknameLen = styled.div`
    
`

export const EditNicknameCheckBtn = styled.div`
  justify-content: center;
  align-items: center;
  padding: 9px 18px;

  background: #191E29;
  border-radius: 100px;

  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #FFFFFF;
`

export const EditNicknameValidResult = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;

  &.valid {
    color: #7C9EFF;
  }

  &.invalid {
    color: #FF7171;
  }
`