import styled from 'styled-components';

export const Container = styled.div`
    display: block; 
    width: 100%; 
    height: 100vh;
`;


export const Body = styled.div` 
    display: block;
    width: 100%; 
    height: 100%;
    overflow-y: overlay;
    background: #ffffff;
    position: relative;
    overflow: hidden;
`

export const BodyRight = styled.div`
    display: block; 
    float: right; 
    width: 333px; 
    overflow:overlay;
    background: #ffffff; 
    border: 2px solid #eaeaea; 
    border-bottom: none; 
    height : calc(100vh - 87px);
    padding : 30px 20px;
    
    &::-webkit-scrollbar {
        display: none;
        // width: 20px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 7px solid transparent;
    }
    
    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
`