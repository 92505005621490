import { action, makeObservable, observable } from "mobx";
import * as React from 'react';

class ModalToastPopupStore {

    /**
     * alert : 확인, 취소      (모달토스트)
     * modalAlert : 확인, 취소 (모달 -> 모달토스트)
     * file : 확인
     * image : 닫기
     * guide : 가이드 팝업
     */

    page = 'alert'

    /**
     * 팝업창
     * 열기, 닫기
     */
    isOpen = false;

    /**
     * *******************************************
     * 확인버튼(true), 취소버튼(false)
     * *******************************************
     */
    isCancel = false;

    /**
     * 팝업 제목
     */
    titleTxt = '';

    /**
     * text cancel 내용
     */
    cancelText = '';

    /**
     * text ok 내용
     */
    okText = '';

    /**
     * image modal일 경우 images 저장
     */
    images = [];

    /**
     * 초기 선택한 image Id
     */
    current = '';

    /**
     * 초기 선택한 image index
     */
    currentIndex = 0;

    /**
     * 첨부 실패한 파일들
     */
    failFiles = [];

    /**
     * 전체 실패인지 확인
     */
    isFailAll = false;

    /**
     * modal 최상단 타이틀
     * @type {string}
     */
    title = '';

    /**
     * guide title
     * @type {string}
     */
    guideTitle = '';

    /**
     * guide text
     * @type {string}
     */
    guideText = '';

    /**
     * 닫기 callBack
     * 창이 닫았을 시 발생 콜백 함수
     */
    closeCallBack = () => {};

    constructor() {
        makeObservable(this, {
            page : observable,
            isOpen: observable,
            isCancel : observable,
            titleTxt: observable,
            cancelText: observable,
            okText: observable,
            images : observable,
            current : observable,
            currentIndex : observable,
            failFiles : observable,
            title : observable,
            guideTitle : observable,
            guideText : observable,
            initDefault: action,
            initImages : action,
            initFiles : action,
            initGuide : action,
            openModalPopup: action,
            closeModalPopup: action,
            setCancelPopup: action
        });
    }


    /**
     *
     * 모달창 처음 세팅
     */
    initDefault = (page, titleTxt, okText, cancelText, closeCallBack) => {
        this.isCancel = false;

        this.page = page;
        this.titleTxt = titleTxt;
        this.okText = okText;
        this.cancelText = cancelText;

        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }
    };

    initImages = (page, images, current, index, closeCallBack) => {
        this.page = page;
        this.images = images;
        this.current = current;
        this.currentIndex = index;

        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }
    }

    /**
     * file confirm modal 띄우기
     * @param page
     * @param failFiles
     * @param closeCallBack
     */
    initFiles = (page, failFiles, isFailAll, closeCallBack) => {
        this.page = page;
        this.failFiles = failFiles;
        this.isFailAll = isFailAll;
        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }
    }

    /**
     * guide modal 띄우기
     *
     * page : guide, guide-alert
     */
    initGuide = (page, titleTxt, guideTitle, guideText, okText, cancelText, closeCallBack) => {
        this.isCancel = false;

        this.page = page;
        this.titleTxt = titleTxt;
        this.guideTitle = guideTitle;
        this.guideText = guideText;
        this.okText = okText;
        this.cancelText = cancelText;

        if (closeCallBack && typeof closeCallBack === 'function') {
            this.closeCallBack = closeCallBack;
        }
    }

    /**
     * 모달창 오픈
     */
    openModalPopup = () => {
        this.isOpen = true;
    }

    /**
     * 모달창 닫기
     */
    closeModalPopup = () => {
        this.images = [];
        this.current = '';
        this.currentIndex = 0;
        this.failFiles = [];
        this.isOpen = false;
        this.closeCallBack();
        this.closeCallBack = () => {};
    }

    /**
     * 확인, 취소
     */
    setCancelPopup = (isCancel) => {
        this.isCancel = isCancel;
    }
}


export default ModalToastPopupStore;