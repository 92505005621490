import {observer} from "mobx-react";
import {
    GroupDataBar,
    GroupDataImage,
    GroupDataImageWrap,
    GroupDataInfoBottom,
    GroupDataInfoDetail,
    GroupDataInfoLi,
    GroupDataInfoTextBottom,
    GroupDataInfoTitle,
    GroupDataInfoUl,
    GroupDataInfoWrap,
    GroupDataInfText,
    GroupDataWrap,
    GroupHeaderUrl,
    GroupHeaderUrlImg,
    GroupItemContainer,
    GroupItemPreBtn,
    GroupItemPreBtnImg,
    GroupNickNameWrap,
    SearchBarText,
    SearchWrap
} from "./GroupInformationStyles";
import React, {useEffect, useRef, useState} from "react";
import env from '../../constants/env';
import copy from "copy-to-clipboard";
import SearchPrevBtn from "../../resources/mImages/array_prev.svg";
import routes from "../../constants/routes.json";
import {useTranslation} from "react-i18next";
import {useStores} from "../../stores";
import {stringToCost} from "../../utils/textHandler";
import {useHistory, useLocation} from "react-router-dom";

import groupUrlImg from "../../resources/images/group_info_url.png";
import groupNickImg from "../../resources/icons/nickIcon.png";
import {receiveResponse} from "../../utils/api";
import {IconButton} from "../../components/presentationals/global";
import {ServerConfig} from "../../commons/config";
import isEmpty from 'is-empty';

const GroupItems = (props) => {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const {store} = useStores();
    const {userStore, toastPopupStore, modalToastPopupStore, defaultImageStore} = store;

    // group state
    const [group, setGroup] = useState([]);
    // 그룹 가입 여부
    const [isJoined, setIsJoined] = useState(false);
    // 메니저 여부
    const [isManager, setIsManager] = useState(false);

    // 무한 스크롤 중복 방지
    const preventRef = useRef(true);
    // 무한 스크롤 옵저버 요소
    const obsRef = useRef(null);
    // 마지막 페이지 등록 여부
    const endRef = useRef(null);


    /******************************************************************************************************************/

    /**
     * 컴포넌트
     */
        // 그룹 통계 컴포넌트
    const groupHeaderStats = (groupCount, type) => {
            let title = '';
            let text = '';

            if (type === 'u') {
                title = t('JOINING_MEMBERS');
                text = t('USER_CNT');
            }else if (type === 'i') {
                title = t('ITEMS_CNT');
                text = t('NUMBER_CNT');
            }else if (type === 'r') {
                title = t('GROUP_RANKING');
                text = t('RANK_CNT');
            }

            return <GroupDataInfoLi key={`group-detail-stats-${type}`} id={`${type}`}>
                {title}
                <br/>
                <GroupDataInfText>{(type==='r' ? (stringToCost(groupCount) || '-') : (stringToCost(groupCount) || 0))+ text}
                </GroupDataInfText>
            </GroupDataInfoLi>
        }

    /******************************************************************************************************************/

    /**
     * 04. 함수
     *
     */
    // 가입 했는지 여부 확인하는 함수
    // group을 사용하니 상관 없을듯
    const getIsJoined = (userList, userId) => {
        if(!userList || !userId) return false;
        return userList.findIndex(user=>user.userId === userId) !== -1;
    }

    // 관리자 여부
    const getIsManager = (group, userId) => {
        if(!group || !userId) return false;
        return group.managerId === userId;
    }

    // 그룹 디테일 조회 API 함수
    const getNoticeGroup = () => {
        receiveResponse({}, `groups/${props.match.params.id}`, 'get')
            .then((res)=>{
                if(res.status === 200) {
                    setGroup(res.data.group);
                    setIsJoined(getIsJoined(res.data.userList, userStore.user.userId));
                    setIsManager(getIsManager(res.data.group,userStore.user.userId));
                }
            })
            .catch((e)=>{
                console.log(e);
            });
    }

    // 그룹 탈퇴 API 함수
    const quitGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;

        await receiveResponse({},`groups/${group?.groupId}/quit`,'patch')
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(t('COMPLETE_QUIT_GROUP', { groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName}));
                        // groupDetailStore.init(group?.groupId, _userId);
                        getNoticeGroup();
                        userStore.updateInvitingGroups();
                        userStore.updateJoinGroups();
                    }
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }


    // 그룹 탈퇴하기 클릭 시 팝업 열기
    const openQuitPopup = () => {
        modalToastPopupStore.initGuide(
            'guide',
            '그룹을 탈퇴하시겠습니까?',
            '탈퇴하더라도 이전에 작성한 게시물은 삭제되지 않습니다.',
            '',
            t("YES"),
            t('NO'),
            quitGroup
        );
        modalToastPopupStore.openModalPopup();
    }



    /******************************************************************************************************************/

    /**
     * 05. 이벤트 함수
     *
     */

    // 뒤로가기 이벤트
    const onBackHistoryClickEvent = (e) => {
        e.preventDefault();
        history.goBack();
    }

    // 탈퇴하기 이벤트
    const onQuitGroupClickEvent = (e) => {
        openQuitPopup();
    }


    /******************************************************************************************************************/


    /**
     * 06. uesEffect
     *
     */

    // 그룹 상세 정보 세팅
    useEffect(()=>{
        if(!isEmpty(userStore.user)) getNoticeGroup();
    },[userStore.user]);


    /******************************************************************************************************************/


    /**
     * 07. render
     *
     */

    /**
     * 1. 참여중인 그룹은 4개까지, 초과 시 더보기 버튼
     * 2. 추천 그룹은 무한 스크롤
     * 3. 마우스 hover 시 pin visible > 클릭 시 pin 등록
     * 4. pin은 최대 4개까지 > 이상 클릭시 토스트 팝업
     * 5.
     *
     */
    return(
        <GroupItemContainer>

            {/* bar */}
            <SearchWrap>
                <GroupItemPreBtn onClick={(e)=>{onBackHistoryClickEvent(e)}}>
                    <GroupItemPreBtnImg src={SearchPrevBtn}></GroupItemPreBtnImg>
                </GroupItemPreBtn>
                <SearchBarText>{t("GROUP_INFORMATION")}</SearchBarText>
            </SearchWrap>

            {/*그룹 상세 통계*/}
            <GroupDataInfoWrap>

                <GroupDataImageWrap>
                    {
                        group?.imgId ?
                            <GroupDataImage
                                src={`${ServerConfig.default.IMG_URL}${group?.imgId}`} />
                            : null
                    }
                </GroupDataImageWrap>
                <GroupDataInfoTitle>
                    {group?.groupName}
                    <GroupHeaderUrl>
                        {'그룹 URL 복사'}
                        <GroupHeaderUrlImg src = {groupUrlImg} onClick = {
                            (e)=>{
                                copy(`${window.location.origin}${routes.APPLINK}${routes.GROUPS}/${group?.groupId}`);
                                toastPopupStore.openToastPopup('링크가 복사되었습니다.');
                            }
                        }/>
                    </GroupHeaderUrl>
                    <GroupNickNameWrap>
                        <IconButton
                            width={'20px'}
                            height={'20px'}
                            src={groupNickImg}
                            wrapperStyle={{marginRight: '6px'}}
                        />
                        {group?.managerNickname}
                    </GroupNickNameWrap>
                </GroupDataInfoTitle>

                <GroupDataWrap>
                    <GroupDataInfoDetail>{group?.groupDetail}</GroupDataInfoDetail>
                    <GroupDataInfoUl>
                        {groupHeaderStats(group?.userCount, 'u')}
                        <GroupDataBar/>
                        {groupHeaderStats(group?.itemCount, 'i')}
                        <GroupDataBar/>
                        {groupHeaderStats(group?.ranking, 'r')}
                    </GroupDataInfoUl>

                    { isJoined && !isManager ?
                            <GroupDataInfoBottom>
                                <GroupDataInfoTextBottom onClick={(e)=>{onQuitGroupClickEvent(e)}}>{t('DO_QUIT_GROUP')}</GroupDataInfoTextBottom>
                            </GroupDataInfoBottom>
                            : null
                    }
                </GroupDataWrap>

            </GroupDataInfoWrap>

        </GroupItemContainer>
    )
}

export default observer(GroupItems);