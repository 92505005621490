import ko from '../resources/lang/ko.json';

const env = {
    totalFileSize : 50 * 1024 * 1024,
    totalFileLength : 10,
    maxFileSize : 10,
    maxFileSizeByte : 10 * 1024 * 1024,
    imgFileFormsReg : /(.*?)\.(jpg|jpeg|png|gif|bmp)$/,
    imgFileForms : ['jpg','jpeg','png','gif','bmp'],
    otherFileFormsReg : /(.*?)\.(pdf|doc|docx|hwp|xls|xlsx|zip|ppt|pptx|mp4|mov)$/,
    otherFileForms : ['pdf','doc','docx','hwp','xls','xlsx','zip','ppt','pptx', 'mp4','mov'],
    pdfFileFormsReg : /(.*?)\.(pdf)$/,
    pdfFileForms : ['pdf'],
    wordFileFormsReg : /(.*?)\.(doc|docx|hwp)$/,
    wordFileForms : ['doc','docx','hwp'],
    excelFileFormsReg : /(.*?)\.(xls|xlsx)$/,
    excelFileForms : ['xls','xlsx'],
    zipFileFormsReg : /(.*?)\.(zip)$/,
    zipFileForms : ['zip'],
    pptFileFormsReg : /(.*?)\.(ppt|pptx)$/,
    pptFileForms : ['ppt', 'pptx'],
    mp4FileFormsReg : /(.*?)\.(mp4)$/,
    mp4FileForms : ['mp4'],
    movFileFormsReg : /(.*?)\.(mov)$/,
    movFileForms : ['mov'],
    ssoToken : process.env.NODE_ENV === 'production' ? 'ssotoken' : 'devssotoken',
    accessToken : process.env.NODE_ENV === 'production' ? 'macalonAccessToken' : 'macalondevAccessToken',
    refreshToken : process.env.NODE_ENV === 'production' ? 'macalonRefreshToken' : 'macalondevRefreshToken',
    portalLoginUrl : process.env.NODE_ENV === 'production' ? 'http://iken.kolon.com/SSO/Login' : 'http://ndeviken.kolon.com/SSO/Login',
    urlReg : /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    postReg : /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|\[\[{.*?}\]\])/gi,
    mentionReg : /\[\[(.*?)\]\]/gi,
    nicknameReg : /[^a-zA-Z0-9ㄱ-힣\s]/g,
    homeContentSize : 20,
    groupsContentSize : 20,
    groupItemsContentSize : 10,
    nicknameMaxLen : 10,
    moreCommentLen : 10,
    moreReCommentLen : 3,
    titleMaxLen : 100,
    contentMaxLen : 5000,
    commentMaxLen : 1000,
    applyContentMaxLen : 200,
    maxFileLen : 10,
    maxTitleLen : 100,
    maxKeywordsLen : 10,
    maxSearchWordLen : 20,
    maxCostLen : 9,
    maxContactLen : 12,
    maxVoteItemLen : 5,
    maxVoteItemValueLen : 15,
    maxGroupNameLen : 20,
    maxGroupDetailLen : 100,
    maxReportReasonLen : 100,
    searchWordsSize : 5,
    condoleModalText : "삼가 고인의 명복을 빕니다.\n[본부/팀 명]본부/팀 [임직원 명]님의 [  ]께서 별세하셨기에 삼가 알려드립니다.\n\n ● 고인 : \n ● 빈소 : \n ● 발인 : \n ● 장지 : \n ● 연락처 : ",
    marryModalText : "[임직원 명]님의 결혼식에 초대합니다.\n\n ● 일시 : \n ● 장소 : \n ● 연락처 : \n ● 마음 전하실 곳 : ",
    babyModalText :  "[임직원 명]님의 출산을 축하드립니다.\n행복하고 밝은 나날을 위해 많은 축하 부탁드립니다!\n\n ● 연락처 : ",
    modalOkButtonText : "네",
    modalCanclButtonText : "아니요",
    modalConfirmButtonText : "확인",
    modalDeleteButtonText : "삭제",
    modalDeleteCancelButtonText : "취소",
    modalGroupDropDownListText1 : "일반 게시글",
    modalGroupDropDownListText2 : "공지글",
    modalGroupNoticeMemo: "공지글로 등록하면 그룹 홈 게시글 최상단에 제목이 노출됩니다.",
    reportCount : 3,
    defaultTitlePlaceholder : '',
    footerMenuCommunicateNameText: '소통해요',
    footerMenuGroupNameText: '모여봐요',
    footerNoticeNameText: '알림',
    footerMenuProfileNameText: '프로필',
    communicateWriteInputText: '님, 어떤 내용으로 소통해 볼까요?',
    todayHotTopicText : '오늘의 핫 토픽',
    changeNicknameDate : 30,
    groupsJoinedText1 : '개',
    groupsJoinedText2 : '의 그룹에',
    groupsJoinedText3 : '명',
    groupsJoinedText4 : '의 임직원 분들이 함께하고 있어요!',
    groupsHomeText : '지금 모여봐요에서는',
    groupsMyHomeText : '내 그룹 새 글',
    groupsHomeEntireText : '전체보기',
    groupsHomeSearchBarText : '이런 그룹은 어때요?',
    groupsHomeSerachRankText : '랭킹',
    groupsHomeScoreRankText : '모여봐요 TOP4',
    groupsHomeJoinRankText : '가입자 수 폭등! 요즘 핫한 그룹',
    groupsHomeCreateDateRankText : '따끈따끈 신상 그룹',
    groupsHomeItemRankText : '조잘조잘 그룹',
    groupsHomeJoinBottomJoinRankText : '최근',
    groupsHomeJoinBottomCreateDateRankText : '개설',
    groupsHomeJoinBottomItemRankText : '등록',
    groupsHomeInterestCategoryText1: '카테고리 그룹',
    groupsHomeInterestCategoryText2: '개',
    groupsHomeInterestCategoryText3: '검색결과',
    groupsHomeInterestCategorySortText1: '랭킹순',
    groupsHomeInterestCategorySortText2: '개설순',
    groupsHomeSearchBarPlaceholder : '원하는 그룹을 검색해보세요.',
    noSearchText : '검색결과를 찾을 수 없어요',
    noImgItemsText : '등록된 사진이 없어요.',
    noFileItemsText : '등록된 파일이 없어요.',

    noSearchSubText : '검색할 단어를 변경하거나\n정확하게 입력했는지 확인해주세요',
    groupItemSearchBarText : '내 그룹 글 모아보기',
    groupItemSearchBarPlaceholder : '어떤 그룹을 찾고 계시나요?',
    groupStatsText1: '가입 멤버',
    groupStatsText2: '명',
    groupStatsText3: '게시글 수',
    groupStatsText4: '개',
    groupStatsText5: '그룹 랭킹',
    groupStatsText6: '위',
    groupNavItemText1: '게시글',
    groupNavItemText2: '보관함',
    groupNavStoreItemText1: '사진',
    groupNavStoreItemText2: '파일',
    groupNoticeText: '공지',
    noItemsText : '등록된 게시글이 없어요.',
    joinGroupSecretText : '비공개 그룹입니다.',
    //noItemsSubText : '검색할 단어를 변경하거나\n정확하게 입력했는지 확인해주세요',

    joinGroupPopupJoinText1 : '개의 글을 더 보고 싶다면',
    joinGroupPopupJoinText2 : '그룹에 가입해보세요.',
    joinGroupPopupInviteText1 : '그룹에서',
    joinGroupPopupInviteText2 : '그룹 초대 요청을 받았어요.',
    joinGroupPopupSecretJoinText1 : '그룹은 비밀 그룹입니다.',
    joinGroupPopupSecretJoinText2 : '가입 신청을 진행해 주세요!',
    joinGroupPopupSecretJoinText3 : '관리자 승인 후 활동 가능합니다.',
    joinGroupPopupButtonJoinText : '가입하기',
    joinGroupPopupButtonSecretJoinText : '가입 신청하기',
    joinGroupPopupButtonSecretJoinCancelText : '가입 신청 취소',
    joinGroupPopupButtonInviteDeclineText : '거절',
    joinGroupPopupButtonInviteAcceptText : '초대수락',


    joinGroupPopupToastAcceptText : '그룹에 가입되었습니다.',
    joinGroupPopupToastNotAcceptText : '그룹 멤버에게만 공개된 글입니다. 그룹 가입을 먼저 진행해 주세요.',
    joinGroupPopupToastAcceptApplyCompleteText : '가입 신청이 완료되었습니다. 관리자가 승인한 후 이용할 수 있습니다.',
    joinGroupPopupToastAcceptCancelText : '그룹에 가입을 취소하였습니다.',
    joinGroupPopupToastAcceptFailText : '그룹에 가입에 실패하였습니다.',
    joinGroupPupupToastQuitText : '그룹을 탈퇴하였습니다.',
    joinGroupPupupToastQuitFailText : '그룹을 탈퇴에 실패하였습니다.',
    joinGroupPupupToastInviteDeclineText : '그룹 초대를 거절하시겠습니까?',
    joinGroupPupupToastInviteDeclineOkText : '그룹 초대를 거절하였습니다.',
    joinGroupPopupToastInviteFailText : '그룹 초대에 실패하였습니다.',
    joinGroupPopupToastAcceptApplyText1 : '가입 신청이 완료되었습니다.',
    joinGroupPopupToastAcceptApplyText2 : '관리자 승인을 기다려 주세요!',

    joinGroupPopupModalApplyTitleText : '가입 신청하기',
    joinGroupPopupModalApplyContentText : '그룹 관리자에게 한마디! (Ex.열심히 활동하겠습니다.)',

    noticeGroupModalTitleText : '공지사항',
    noticeGroupModalNoListText : '등록된 공지글이 없어요',



    // 게시글 props key
    costKey : 'cost',
    contactKey : 'contact',
    // 소통해요 group id (링크 전달 시 사용)
    communicateGroupId : '0000000001',

    // locale code
    LOCALE_KO : 'KO',
    LOCALE_JA : 'JA',
    LOCALE_EN : 'EN',
    LOCALE_CHI : 'CHI',
    LOCALE_LIST : ['KO','JA','EN','CHI'],

    // 프로필, 작성글, 댓글, 즐겨 찾는 글 메뉴
    PROFILE_ITEM_MENUS : [],
    // 프로필 페이지에서 작성 글, 작성 댓글, 즐겨찾는 글 가져오는 크기
    PROFILE_ITEM_PAGE_SIZE : 20,


    ALLOWED_URL_PARAMETERS : ['itemId', 'notificationId'],
    FCM_API_KEY: "AIzaSyCTyvqfzrNqSL8VwkXAOkNfrudWz7GwBE4",
    FCM_AUTH_DOMAIN: "smart-work-team-570b1.firebaseapp.com",
    FCM_DATABASE_URL: "https://smart-work-team-570b1.firebaseio.com",
    FCM_PROJECT_ID: "smart-work-team-570b1",
    FCM_STORAGE_BUCKET: "smart-work-team-570b1.appspot.com",
    FCM_MESSAGING_SENDER_ID: "380156188390",
    FCM_APP_ID: "1:380156188390:web:74a17f4a0b4169373ecd27",
    FCM_VAPID_KEY : "BHp4Kh8sk0l9Gkioodfhn2gdS6oUWjn2gRvjr5cXV1WntVVvKUyOZ4CF9Jj60u7FffPtAYYE09O_PCPhnf_hGOM",


    APP_IKEN_DOMAIN: "apps.kolon.com",
    APP_IKEN_SCHEMA: "kolonapps",

    DEV_APP_IKEN_DOMAIN: "appdev.kolon.com",
    DEV_APP_IKEN_SCHEMA: "kolonappsdev",

    APP_DOMAIN: "m-macalon.kolon.com",
    APP_SCHEMA: "macalon",
    // APP_PACKAGE: "com.kolon.macalon",
    DEV_APP_DOMAIN: "m-macalondev.kolon.com",
    DEV_APP_SCHEMA: "macalondev",
    // DEV_APP_PACKAGE: "com.kolon.macalondev",

    LINK_DOMAIN : process.env.NODE_ENV === 'production' ? 'm-macalon.kolon.com' : 'm-macalondev.kolon.com',
    GET_NEW_NOTIFICATION_SIZE : 10000,
    GET_NEW_NOTICE_NOTIFICATION_SIZE : 999,
    MAX_SHOW_NOTIFICATION_LEN : 1000,
    REPORT_REASONS : Object.keys(ko).filter((k)=>k.startsWith('REPORT_REASONS_')),

    LOCKER_ITEMS_REQUEST_SIZE : 10,
    GROUP_ITEMS_REQUEST_SIZE : 10,
}


export default env;