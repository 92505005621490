import {
    BookmarkButton,
    CommentsWrap,
    Content,
    ContentContainer,
    ContentFiles,
    ContentFilesWrap,
    ContentOption,
    ContentOptions,
    ContentOptionText,
    ContentSection,
    ContentTitleAndSection,
    FamilyEventCommentWrap,
    FamilyEventContainer,
    FamilyEventCommentCount,
    FamilyEventCommentText,
    FamilyEventWrap,
    TitleText,
    TitleTextWrap,
    TitleTopic,
    TitleTopicWrap,
    TitleVoteCount,
    userInfoWrap,
    VoteWrap
} from "./FamilyEventStyles";
import {
    AttachFile,
    AttachImage,
    CommentField,
    IconButton,
    Loading,
    UserInfoTime
} from "../../components/presentationals/global";
import UnLikeIcon from '../../resources/icons/unlike.svg'
import LikeIcon from '../../resources/icons/like_filled_red.svg'
import CommentIcon from '../../resources/icons/comment.svg'
import BookmarkIcon from "../../resources/mImages/bookmarkIcon.svg";
import BookmarkFilledIcon from '../../resources/icons/bookmark_filled.svg'
import ReCommenIconImage from '../../resources/mImages/recomment.png';
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {receiveResponse} from "../../utils/api";
import routes from "../../constants/routes.json";
import Comment from "../../components/presentationals/global/Comment";
import {useStores} from "../../stores";
import {getFileList, getIsVideoFile, getIsZIPFile} from "../../utils/fileHandler";
import isEmpty from "is-empty";
import {ServerConfig} from "../../commons/config";
import {observer} from "mobx-react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {Highlighter, PostHighlighter} from "../../utils/textHandler";
import copy from "copy-to-clipboard";
import env from "../../constants/env";
import {createPropsContent, createTopicImg, getModalForm, getObjectFromString} from "../../utils/itemContentHandler";
import Vote from "../../components/presentationals/global/Vote";
import {getBoolFromYn} from "../../utils/boolHandler";
import {useTranslation} from "react-i18next";
import {appWebViewFileViewEvent, webViewAppNativeEvent} from "../../utils/appBridgeEvnet";
// import CancelIcon from "../../resources/icons/modal_cancel.svg";

const FamilyEvent = (props) => {

    const {store} = useStores();
    const {userStore, topicStore, toastPopupStore, modalPopupStore, modalToastPopupStore, modalDetailStore, itemStore, eventStore, authStore} = store;
    const isAppReady = authStore.isAppReady;
    const location = useLocation();
    const history = useHistory();
    const {page, onDelete, onError, highlight, onClose} = props;
    const {t} = useTranslation();

    // 게시글 부분 ref
    const contentRef = useRef();
    // popup 스크롤
    const scrollRef = useRef(null);

    /*  중요  (카드에서만 props 적용) */
    // 게시글 상세
    const [post, setPost] = useState(null);
    // 댓글 리스트
    const [comments, setComments] = useState([]);
    // 익명 여부
    const [isAnonymous, setIsAnonymous] = useState(false);
    // 댓글 text
    const [comment, setComment] = useState('');
    // 좋아요 여부
    const [isLike, setIsLike] = useState(false);
    // 게시글 메뉴 오픈
    const [isOpen, setIsOpen] = useState(false);
    // 신고하기 모달 오픈
    const [isOpenReport, setIsOpenReport] = useState(false);
    // 댓글 신고하기 모달
    const [isOpenCommentReport, setIsCommentOpenReport] = useState(false);
    // 게시글 수정 모달
    const [isOpenModify, setIsOpenModify] = useState(false);
    // 게시글 삭제 모달
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    // 이미지 모달 오픈 (본문)
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    // 선택한 이미지 (본문)
    const [clickImage, setClickImage] = useState(0);
    // 선택한 이미지 (댓글)
    const [clickCommentImage, setClickCommentImage] = useState(null);
    // 모달 이미지
    const [modalImages, setModalImages] = useState([]);
    // 최근(추천) 게시글
    const [recentPosts, setRecentPosts] = useState([]);
    // 아이템 id
    const [itemId, setItemId] = useState(null);
    // 로딩 완료 여부
    const [isLoading, setIsLoading] = useState(false);
    // 보여지는 부모 댓글 갯수
    const [parentCnt, setParentCnt] = useState(null);
    // 각 댓글의 보여지는 대댓글 갯수
    const [childrenCnt, setChildrenCnt] = useState(null);
    // 댓글 재정렬
    const [commentsState, setCommentsState] = useState({comments : []});
    // 글 더보기
    const [isMore, setIsMore] = useState(true);
    // // 댓글 더보기
    // const [isOpenComments, setIsOpenComments] = useState(true);
    // 댓글 상태 정보 (입력, 수정, 대댓글)
    const [commentInfo, setCommentInfo] = useState({
        commentType : 'comment', //comment, reComment
        isModify : false,
        isOpen : false, // 댓글창 오픈 여부
        commentProps : null,
        selectedCommentId : null // 선택중인 커멘트Id
    });
    // 데이터 동기화 이벤트 처리를 위한 object
    const e = eventStore.e;

    /******************************************************************************************************************/

    /**
     * 01. 아이템
     *
     */

        // ... (리스트 아이템)
    const menuItems = [
            {
                id:0,
                nameText: t('REPORT'),
                onClick : (e) => onReportItem(e)
            },
            {
                id:1,
                nameText: t('COPY_LINK'),
                onClick : (e)=> {
                    getLink();
                }
            }
        ]

    // ... (나의 리스트 아이템)
    const myMenuItems = [
        {
            id:0,
            nameText: t('MODIFY_ITEM'),
            onClick : (e) => onChangeItem(e)
        },
        {
            id: 2,
            nameText: t('DELETE_ITEM'),
            onClick: (e) => onDeleteItem(e)
        },
        {
            id:3,
            nameText : t('COPY_LINK'),
            onClick : (e)=> {
                getLink();
            }
        }
    ]

    // 그룹장일 경우 삭제하기 권한 추가
    const masterMenuItems = [
        {
            id:0,
            nameText : t('REPORT'),
            onClick : (e) => onReportItem(e)
        },
        {
            id:1,
            nameText : t('COPY_LINK'),
            onClick : (e)=> {
                getLink();
            }
        },
        {
            id: 2,
            nameText: t('DELETE_ITEM'),
            onClick: (e) => onDeleteItem(e)
        }
    ]

    // 공지글 (다른 사람이 작성 , 신고하기 제외)
    const noticeMenuItems = [
        {
            id:0,
            nameText: t('COPY_LINK'),
            onClick : (e)=> {
                getLink();
            }
        }
    ]

    // 공지글 (그룹장 메뉴, 신고하기 제외)
    const noticeMasterMenuItems = [
        {
            id:0,
            nameText : t('COPY_LINK'),
            onClick : (e)=> {
                getLink();
            }
        },
        {
            id: 1,
            nameText: t('DELETE_ITEM'),
            onClick: (e) => onDeleteItem(e)
        }
    ]


    /******************************************************************************************************************/

    /**
     * 02. 함수
     *
     */


    // <링크 전달하기> 링크 얻어오기
    const getLink = () => {
        if(post?.groupId===env.communicateGroupId){
            copy(`${window.location.origin}${routes.APPLINK}${routes.HOME}?itemId=${post.itemId}`);
        }else{
            copy(`${window.location.origin}${routes.APPLINK}${routes.GROUPS}/${post.groupId}?itemId=${post.itemId}`);
        }
        toastPopupStore.openToastPopup(t('COPY_LINK_COMPLETE'));
    }

    // <게시글> 상세조회 API
    const getDetails = () => {

        const _itemId = itemId;
        let _post;
        // 공지와 일반글 사이에 변경이 있는 경우
        let _isUpdated = false;

        return receiveResponse({}, `items/${_itemId}`,'get')
            .then((res)=>{
                if(res.status===200){
                    if(!res.data) throw new Error('no items');
                    // 공지에서 일반글이 된 경우
                    if(post) _isUpdated = getBoolFromYn(post?.noticeYn) !== getBoolFromYn(res.data.noticeYn);
                    setPost(res.data);
                    getFiles(res.data.fileInfoList);
                    _post = res.data;
                    return res.data.itemId;
                }
            })
            .then((itemId)=> {
                return receiveResponse({itemId}, 'comments', 'get')
            })
            .then((res)=>{
                if(res.status===200){
                    getCommentsState(res.data);
                    // popup일 경우, 모두 불러와 졌을 때 modal창을 켬
                    // modalDetailStore.openModalPopup();
                    setIsLoading(false);
                }
                return {post : _post, isUpdated : _isUpdated};
            })
            .catch((e)=>{
                // error 핸들러
                onErrorHandler();

                // 잘못된 링크로 전달되었을 경우, home으로
                // @todo group 게시글이 잘못 전달되었을 경우우
                const code = e.response.data.code;
                const message = e.response.data.message;

                if (code === 'item.reguser-deleted' || code === 'item.group-manager-deleted'
                    || code === 'item.manager-deleted' || code === 'item.reported') {
                    openAlertPopup(message);
                }else {
                    openAlertPopup(t('DELETED_ITEM'));
                }

            })
        // 링크 전달 시, PC에서
        // .finally(()=>{
        //     // URL에 들어가 있는 itemId 지우기
        //     const urlItemId = new URLSearchParams(location.search).get('itemId');
        //     if(type==='popup' && urlItemId && urlItemId === _itemId) window.history.replaceState({}, null, location.pathname);
        // })
    }

    // <게시글> 삭제 API
    const deleteBoardItem = (itemId) => {
        receiveResponse({}, `items/${post?.itemId}`, 'delete')
            .then(res=>{
                if(res.status===200) {
                    // 삭제 하는 경우 추천글 update
                    onUpdateRecommend();
                    // itemStore.updateRecommendCommunicateItems();
                    e.emit('delete',{itemId : post?.itemId}); // home에 이벤트 발행
                    // 공지사항 글인 경우, 이벤트 발행 (모여봐요, 공지사항 목록에서)
                    if(getBoolFromYn(post?.noticeYn)) e.emit('update-notice');

                    modalDetailStore.closeModalPopup();
                    toastPopupStore.openToastPopup(t('DELETE_ITEM_COMPLETE'));

                    // if(page==='c'){
                    //     if(!location.pathname.includes(routes.PROFILE_ACTIVITY)){
                    //         history.replace(routes.HOME);
                    //     }
                    // }

                    if(onDelete && typeof onDelete === 'function'){
                        onDelete();
                    }

                    // @todo
                    // 프로필에서 삭제하는 경우
                }
            }).catch((e)=>{
            console.log(e)
        });
    }

    // <게시글> 북마크 API
    const toggleBookmark = () => {
        let isBookmark = post?.bookmarkDate;
        let route = `items/${post?.itemId}`;
        let _post = post;

        if(isBookmark){
            route = `${route}/unbookmark`;
        }else{
            route = `${route}/bookmark`;
        }
        receiveResponse({},route,'patch').then((res)=>{
            if(res.status===200){
                if(isBookmark){
                    _post = {...post,bookmarkDate : false}
                    toastPopupStore.openToastPopup(t('UN_BOOKMARK_ITEM_COMPLETE'));
                }else{
                    _post = {...post,bookmarkDate : true}
                    toastPopupStore.openToastPopup(t('BOOKMARK_ITEM_COMPLETE'));
                }
                setPost(_post);
                onEventEmit('BOOKMARK_CHANGE',_post);
                // 내 활동 > 내 그룹에서 받을 수 있는 이벤트 emit
                if(matchPath(location.pathname,`${routes.PROFILE}/bookmark`)) e.emit('update-profile-bookmark',()=>{})
            }
        })
    }

    // <게시글> 없는 게시글에 접근 한 경우 callback
    const onErrorHandler = (e) => {
        if(onError && typeof onError === 'function'){
            onError();
        }
    }

    // <댓글> 더보기를 위한 댓글 데이터 형식 가공
    const getCommentsState = (comments) => {
        // @todo
        /**
         *
         * state
         * comment :
         * {
         *     cnt : 1,
         *     comments : [
         *         {
         *             commentId
         *             child : [],
         *             cnt : 1
         *         }
         *     ]
         * }
         *
         *
         * 댓글 삭제 시 : 대댓글 없는 경우는 카운트 -- 그렇지 않은 경우는 그대로 (대댓글 삭제시 --)
         * 댓글 수정 : 그대로
         * 댓글 추가 : 카운트 ++
         */

        let commentsState = { comments : [] };
        let newChildrenCnt = [];
        let parent = -1;
        let parentUserId;

        comments.forEach(comment=>{
            if(!comment?.upperCommentId){
                /**
                 * 2024.05.09
                 * 숨김 처리도 삭제처럼 기능 추가
                 * status === 'BLOCKED'
                 * */
                if((comment?.deleteDate || comment?.reportCount >= env.reportCount || comment?.status === 'BLOCKED')
                    && comments.findIndex(c=>!c?.deleteDate && c?.reportCount < env.reportCount && c?.status !=='BLOCKED'
                    && c.upperCommentId===comment.commentId)===-1) return;
                commentsState = {
                    ...commentsState,
                    comments : [...commentsState.comments, {comment : {...comment}, children : [] }]
                }
                parent+=1;
                newChildrenCnt[parent] = env.moreReCommentLen;
                parentUserId = comment.userId;
            }else{
                /**
                 * 2024.05.09
                 * 숨김 처리도 삭제처럼 기능 추가
                 * status === 'BLOCKED'
                 * */
                if(comment?.deleteDate || comment?.reportCount >= env.reportCount || comment?.status === 'BLOCKED') return;
                commentsState.comments[parent].children = [{...comment, parentUserId}, ...commentsState.comments[parent].children];
            }
        });

        if(!childrenCnt) setChildrenCnt(newChildrenCnt);
        if(!parentCnt) setParentCnt(env.moreCommentLen);
        setCommentsState(commentsState);


        return commentsState;
    }

    // <댓글> 대댓글 배열
    const loadMoreReComments = (index) => {
        let arr = [...childrenCnt];
        arr[index] = arr[index]+env.moreReCommentLen;
        setChildrenCnt(arr);
    }

    // <댓글> 댓글 등록, 수정
    const addComment = () => {
        // 댓글 카운트 다시 가져오기
        getDetails();
        // card에 event 발행
        onEventEmit('ADD_COMMENT');
        // 추천글 업데이트
        onUpdateRecommend();
    }

    // <댓글> 댓글 삭제
    const deleteComment = (commentId) => {
        receiveResponse({}, `comments/${commentId}`, 'delete').then(res=>{
            if(res.status===200){
            }
        }).then(()=>{
            return receiveResponse({itemId : itemId}, 'comments', 'get')
        }).then((res)=>{
            if(res.status===200){
                setComments(res.data);
                getCommentsState(res.data);
                toastPopupStore.openToastPopup('댓글이 삭제되었습니다.');
            }
        }).catch((e)=>{
            console.log(e)
        });
    }

    // <댓글> 댓글 신고
    const reportComment = (commentId, itemId, reason, cb) => {

        const params = {
            groupId : post.groupId,
            commentId : commentId,
            itemId : itemId
        }

        receiveResponse(params, `comments/${commentId}/report?reason=${reason}`, 'patch')
            .then(res=>{
                if(res.status===200) {
                    toastPopupStore.openToastPopup('댓글에 대한 신고가 완료되었습니다.');
                    if(typeof cb === 'function'){
                        cb();
                    }
                }
            })
            .then(()=>{
                return receiveResponse({itemId}, 'comments', 'get');
            })
            .then((res)=>{
                if(res.status===200){
                    setComments(res.data);
                    getCommentsState(res.data);
                }
            })
            .catch((e)=>{
                console.log(e)
            });
    }

    // <댓글> 파일<이미지, 다른파일> 구분하기
    const getFiles = (fileInfoList, type) => {
        let files = getFileList(fileInfoList);

        if (type === 'I') return files.imgs;
        else if(type === 'F') return files.others
        else return [];

    }

    // <댓글> 이미지 파일 만들기
    const createImgs = (imgFiles) => {
        return imgFiles.map((imgFile, index)=>
            <AttachImage
                type={'download'}
                width={'100px'}
                height={'100px'}
                onClick={(e)=> { openImageModal(imgFiles[index], imgFile?.fileId, index) }}
                src={`${ServerConfig.default.THUMBNAIL_URL}${imgFile?.fileId}`}
            />
        )
    }

    // <추천> (최근)글 API
    const getRecentItems = (topicId) => {
        receiveResponse({
            topicId: topicId,
            size: 5,
            exclusionItemIdList: [props.match?.params?.id],
        }, 'items', 'get').then(res => {
            if (res.status === 200) {
                setRecentPosts(res.data.list);
            }
        });
    };

    // 댓글 콜백 함수
    const commentCallback = (props, args) => {


        //01. 대댓글
        //02. 댓글, 대댓글 수정
        //03. 삭제 (자기 자신 삭제했을 시 초기화)
        if (props) {
            setCommentInfo((commentInfo)=>{
                return {
                    ...commentInfo,
                    commentType : args.type,          //comment, reComment
                    isModify : args.isModify,         // 수정 여부
                    isOpen : args.isOpen,             // 댓글창 오픈 여부
                    commentProps : props,
                    selectedCommentId: args.selectedCommentId  //선택된 댓글Id
                }
            });


            //*전에 꺼 초기화 시키기*/
            //이벤트 발생
            const beforeSelectedCommentId = commentInfo.selectedCommentId;
            // let eventName = "";
            //
            // //댓글
            // if (args.type === "comment") {
            //     //수정이면
            //     if(args.isModify) {
            //         eventName = "CLOSE_MODIFY_COMMENT"
            //     }
            // }else if (args.type === "reComment") {  //대댓글
            //
            // }
            // 자기 같은거면 이벤트 발생 x
            if (beforeSelectedCommentId === props.commentId) return;

            e.emit(`update-comment-${beforeSelectedCommentId}`, {});
        }

    }

    // 댓글 쓰기 콜백 함수 (취소, 수정, 남기기)
    const commentFieldCallback = () => {

        // // 댓글쓰기창 닫기 (초기화)
        // if (isOpenCommentsWrite) {

        //01.댓글 Comment 전달
        //이벤트 발생
        const beforeSelectedCommentId = commentInfo.selectedCommentId;
        e.emit(`update-comment-${beforeSelectedCommentId}`, {});

        //02. 댓글아이템 초기화
        setCommentInfo((commentInfo)=>{
            return {
                ...commentInfo,
                commentType : 'comment', //comment, reComment
                isModify : false,
                isOpen : false, // 댓글창 오픈 여부
                commentProps : null
            }
        });

    }

    //댓글창 로딩 콜백
    const loadingCallback = (isCommentLoading) => {
        if (isCommentLoading) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }

    /******************************************************************************************************************/

    /**
     * 03. 클릭 이벤트
     *
     */

    // 뒤로가기 닫기
    const onCloseHandler = (e) => {
        e.preventDefault();
        history.goBack();
    }

    // <게시글> 메뉴 toggle
    const openMenu = () => {
        setIsOpen(!isOpen);
    }

    // <게시글> 메뉴 close
    const closeMenu = () => {
        setIsOpen(false);
    }

    // <게시글> 수정 (팝업모달창 수정)
    const onChangeItem = (e) => {
        e.preventDefault();

        if (page === 'c'){
            //토픽 아이템스 수정일경우 한 배열만 등록
            let modifyTopicItem = (topicStore.topics || []).filter((topic) => topic.topicId === post.topicId).map((topic) => {
                let topicItem = {
                    id: topic.topicId,
                    name: topic.name,
                    nameText : topic.nameText
                }
                return topicItem
            });

            //모달 기본 설정
            modalPopupStore.initModify(
                "c",        //소통
                modifyTopicItem,
                post,
                onCloseCacnelPostModal,
                onCloseOkPostModal);
        }else { //모여봐요

            /**
             * 투표 기능 추가
             */
            let dropDownItems = [
                {
                    id: 0,
                    name : 'Default',
                    nameText: t('GROUP_MODAL_NORMAL_ITEM'),
                }
            ]
            let voteTopic = (topicStore.topics || []).filter(t=>t.name === 'topic.vote')[0];
            if(voteTopic) dropDownItems.push({id: voteTopic.topicId, name: voteTopic.name, nameText: voteTopic.nameText});

            //모달 기본 설정
            modalPopupStore.initModify(
                "g",        //그룹
                dropDownItems,
                {...post, canNotice : userStore.user.userId === post?.group?.managerId || getBoolFromYn(post?.group?.assistantYn)},
                onCloseCacnelPostModal,
                onCloseOkPostModal);
        }

    }

    // <게시글> 수정 (팝업모달창 수정)
    const onDeleteItem = (e) => {
        e.preventDefault();

        modalToastPopupStore.initDefault(
            'alert',
            '게시글을 정말 삭제하시겠어요?',
            env.modalDeleteButtonText,
            env.modalDeleteCancelButtonText,
            onCloseModalToastHandler
        );

        modalToastPopupStore.openModalPopup();

    }

    // <게시글> 모달 취소 닫기
    const onCloseCacnelPostModal = (e) => {
        //소통해요
        if (modalPopupStore.page === 'c' || modalPopupStore.page === 'g') {
            toastPopupStore.openToastPopup(t('CANCEL_MODIFY_ITEM'));
        }else if (modalPopupStore.page === 'r') {
            toastPopupStore.openToastPopup(t('CANCEL_REPORT_ITEM'));
        }
    }

    // <게시글> 모달 수정 닫기
    const onCloseOkPostModal = () => {

        modalPopupStore.closeModalPopup();

        //소통해요
        if (modalPopupStore.page === 'c' || modalPopupStore.page === 'g') {
            // 수정된 버전 불러오기
            getDetails()
                .then(res=> {
                    // 수정된 이벤트 발행
                    onEventEmit('MODIFY_ITEM', res.post);
                    // 공지사항에서 받을 수 있도록 이벤트 발행
                    e.emit('update-notice', {isUpdated : res.isUpdated});
                });

            toastPopupStore.openToastPopup(t('MODIFY_ITEM_COMPLETE'));
        }else if (modalPopupStore.page === 'r') {
            toastPopupStore.openToastPopup(t('REPORT_ITEM_COMPLETE'));
            if(post.reportCount+1 >= env.reportCount) {
                /**
                 * 신고 3번 된 경우
                 * 1. 소통해요 추천글에 있는 경우만 update
                 * 2. 글상세 팝업 끄기
                 * 3. home 다시 불러오기
                 */
                modalDetailStore.closeModalPopup();
                // home에 이벤트 발행
                e.emit('delete',{itemId : post?.itemId});
            }else{
                getDetails();
            }
        }

        // 추천 아이템들 갱신 (추천 글에 있는 경우에만)
        onUpdateRecommend();

    }

    // <게시글> 모달 토스트 닫기
    const onCloseModalToastHandler = () => {
        // 삭제(확인 cancel = true)
        if (modalToastPopupStore.isCancel){
            // 삭제 API 함수
            deleteBoardItem(post?.itemId);
            // home에서 이벤트를 받을 수 있도록
            // if(type==='popup') e.emit('delete',{itemId : post?.itemId});
        }
    }

    // <게시글> 좋아요 API
    const onLikeChange = () => {
        // like 아닌 경우
        let isLike = post?.likesDate;
        let route = `items/${post?.itemId}`;
        let _post = post;

        if(isLike){
            route = `${route}/unlike`;
        }else{
            route = `${route}/like`;
        }
        receiveResponse({},route,'patch').then((res)=>{
            if(res.status===200){
                if(res.data.result){
                    if(isLike) _post = {..._post, likesCount : res.data.count, likesDate : false}
                    else _post = {..._post, likesCount : res.data.count, likesDate : true}
                    setPost(_post);
                }
            }
        })
    }

    // 이미 신고한 게시글일 경우, 팝업 띄우기
    const openAlertPopup = (title) => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            title,
            '',
            null,
            env.modalConfirmButtonText,
            env.modalCanclButtonText,
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // <게시글> 신고하기 (팝업모달창 등록)
    const onReportItem = (e) => {
        if(post.reportDate){
            toastPopupStore.openToastPopup(t('ALREADY_REPORTED_ITEM'));
        }else{
            //모달 기본 설정n
            modalPopupStore.initAdd(
                "r",
                [],
                post,
                onCloseCacnelPostModal,
                onCloseOkPostModal
            );
        }
    }

    // <댓글> 파일 다운로드 이벤트
    const onFileDownload = (file) => {
        const fileName = `${file?.fileOriginName}.${file?.fileExt.toLowerCase()}`;
        if(getIsVideoFile(fileName) || getIsZIPFile(fileName)){
            toastPopupStore.openToastPopup(t('CANNOT_OPEN_FILE_IN_MOBILE'));
            return;
        }
        const fileId = file?.fileId
        const filePath = `${ServerConfig.default.FILE_URL}${fileId}`;
        //앱 이벤트
        webViewAppNativeEvent(fileId, filePath, fileName);
    }

    // <댓글> 이미지 모달 open (본문)
    const openImageModal = (imgFiles, fileId) => {

        const fileName = `${imgFiles?.fileOriginName}.${imgFiles?.fileExt.toLowerCase()}`;
        const filePath = `${ServerConfig.default.FILE_URL}${imgFiles?.fileId}`;

        webViewAppNativeEvent(fileId, filePath, fileName);
    }

    // // <댓글> 댓글 가져오기
    // const getComments = async (itemId) => {
    //     await receiveResponse({itemId}, 'comments', 'get').then(res=>{
    //         if(res.status === 200){
    //             getCommentsState(res.data);
    //             setIsOpenComments(true);
    //         }
    //     })
    // }

    // <이벤트> popup인 경우에만 이벤트 에미터 등록
    const onEventEmit = (event, post) => {
        // popup에서만 이벤트 발행
        e.emit(`update-${itemId}`, {itemId, event, post});
    }

    // <투표> 투표 callback
    const onVote = () => {
        getDetails();
        // popup에서 수정된 경우, 이벤트 발행
        onEventEmit('MODIFY_ITEM');
        // 추천글 업데이트
        onUpdateRecommend();
    }

    // <게시글> 게시글 수정 될 경우, 추천글 업데이트
    const onUpdateRecommend = () => {
        const _a = itemStore.recommendCommunicateItems;
        const _c_i = _a.congratulationList.findIndex(e=>e.itemId===post?.itemId);
        const _p_i = _a.partList.findIndex(e=>e.itemId===post?.itemId);
        const _r_i = _a.reactionList.findIndex(e=>e.itemId===post?.itemId);
        if(_c_i!==-1 || _p_i!==-1 || _r_i!==-1) itemStore.updateRecommendCommunicateItems();
    }

    // 상단 프로필 사진 누를 경우
    const onClickImgHandler = (userId) => {

        // itemDetail 닫고, 프로필 모달 열기
        // if(onClickImg && typeof onClickImg === 'function') onClickImg();
        // 프로필 조회 모달 열기
        // let _userId = userId
        // if(!userId) _userId = post?.regUserid;
        //
        // modalPopupStore.initAdd(
        //     'vp',null, {userId : _userId},()=>{},()=>{}
        // );

    }

    /**
     * 댓글 누를 경우 > 최초에는 api 요청
     * 열려있는 경우에는 api요청 안함
     */


    /******************************************************************************************************************/


    /**
     * 04. 컴포넌트
     *
     */

        // <댓글> 댓글 전체 컴포넌트
    const createCommentsList = (commentsState) => {

            if(!commentsState) return;

            const len = commentsState.comments.length;
            let commentsArr = [];

            /**
             * 2024.05.09
             * 숨김 처리도 삭제처럼 기능 추가
             * status === 'BLOCKED'
             * */
            commentsState.comments.forEach((parent,index)=>{

                if((parent.comment?.deleteDate || parent.comment.reportCount >= env.reportCount
                    || parent.comment?.status === 'BLOCKED') && parent.children.length === 0) return;

                if(index >= parentCnt) return;

                commentsArr.push(mapToComment(parent.comment, parent.children.length !== 0));

                const childrenLen = parent.children.length;

                if(childrenLen > childrenCnt[index]){
                    commentsArr.push(
                        <FamilyEventCommentWrap onClick={e=>loadMoreReComments(index)}>
                            <IconButton width={'18px'} height={'18px'} wrapperStyle={{alignSelf : 'center'}} src={ReCommenIconImage} />
                            <FamilyEventCommentText>{t('READ_MORE_RE_COMMENT')}
                                <FamilyEventCommentCount>{`(${childrenLen - childrenCnt[index]})`}</FamilyEventCommentCount>
                            </FamilyEventCommentText>
                        </FamilyEventCommentWrap>
                    )
                }

                parent.children.forEach((child,i) => {
                    // new -> old 순서일 경우
                    // if(i >= childrenCnt[index]) return;
                    // old -> new 순서
                    if(i <= (childrenLen-1) - childrenCnt[index]) return;
                    commentsArr.push(mapToComment(child, false));
                })

            });

            if(len > parentCnt){
                commentsArr.push(
                    <FamilyEventCommentWrap onClick={e=>setParentCnt(parentCnt+env.moreCommentLen)}>
                        <IconButton width={'18px'} height={'18px'} wrapperStyle={{alignSelf : 'center'}} src={ReCommenIconImage}/>
                        <FamilyEventCommentText>{t('READ_MORE_COMMENT')}
                            <FamilyEventCommentCount>{`(${len - parentCnt})`}</FamilyEventCommentCount>
                        </FamilyEventCommentText>
                    </FamilyEventCommentWrap>
                )
            }

            return commentsArr;
            // @todo
            // 마지막에서 부터 parentCnt보다 클경우는 숨김

        }


    // <댓글> 댓글 컴포넌트
    const mapToComment = (comment, hasChild) => {
        return <Comment
            {...comment}
            page={'web'}
            key={`comment-item-${comment?.commentId}`}
            itemRegUserId={post?.regUserid}
            type={comment?.upperCommentId ? 'reComment' : 'comment'}
            onSubmit={addComment}
            onDelete={addComment}
            onReport={addComment}
            onClickImg={onClickImgHandler}
            onToggleLike={e=>onEventEmit('COMMENT_LIKE_CHANGE')}
            hasChild={hasChild}
            callback={commentCallback}
        />
    }

    // <댓글> 파일 컴포넌트
    const createOthers = (otherFiles) => {
        return otherFiles.map(otherFile=>
            <AttachFile
                type={'download'}
                fileName={`${otherFile?.fileOriginName}.${otherFile?.fileExt.toLowerCase()}`}
                onClick={(e)=>onFileDownload(otherFile)}
            />
        )
    }

    // 권한에 따른 메뉴 가져오기
    const getMenus = () => {

        if(!post || !userStore.user) return;

        // @todo
        // 게시글 상세 조회할때, group 정보도 와야할듯

        if(post?.group){
            // 내 글인 경우 내 글 메뉴
            if(userStore?.user.userId === post?.regUserid) return myMenuItems;
            // 그룹 장일 경우
            if(userStore?.user.userId === post?.group.managerId) {
                if(getBoolFromYn(post?.noticeYn)) return noticeMasterMenuItems;
                else return masterMenuItems;
            }
            else{
                if(userStore?.user.userId===post?.regUserid) return myMenuItems;
                else {
                    // 다른 사람 작성한 공지사항 글인 경우, 신고하기 제외
                    if(getBoolFromYn(post?.noticeYn)) return noticeMenuItems;
                    return menuItems;
                }
            }
        }else{
            // 소통해요
            if(userStore?.user.userId===post?.regUserid) return myMenuItems;
            else return menuItems;
        }

    }

    // 시스템 공지글인지, 아닌지 판단
    const getIsSystemNotice = (post) => {
        if(!post) return false;
        if(post?.group?.groupId === env.communicateGroupId && getBoolFromYn(post.noticeYn)) return true;
        return false;
    }

    /******************************************************************************************************************/


    /**
     * 04. uesEffect
     *
     */

    // (링크)
    useEffect(()=>{
        const searchParams = new URLSearchParams(props.location.search);
        const itemId = searchParams.get('itemId');
        setItemId(itemId);
    },[]);

    // 공통
    useEffect(()=>{
        if (itemId) {
            getDetails();
        } else {
            //없을 시 오류
            // history.replace(routes.HOME);
        }
    },[itemId]);

    useLayoutEffect(()=>{

        // footerNav 없기에
        document.querySelector("#scroll-box").style.height = '100%';
        return () => {
            // setCommentInfo({
            //     commentType: 'comment', //comment, reComment
            //     isModify: false,
            //     isOpen: false, // 댓글창 오픈 여부
            //     commentProps: null,
            //     selectedCommentId: null // 선택중인 커멘트Id
            // });
            // setIsOpenComments(false);
            // setCommentsState( { comments : [] });
            // setParentCnt(null);
            // setChildrenCnt(null);
        }
    },[]);

    /******************************************************************************************************************/



    /**
     * 05. render
     *
     *
     * type
     * 01. card
     * 02. page
     */

    return(

        <>
            <Loading isLoading={isLoading}>

                <FamilyEventContainer>
                    <FamilyEventWrap id={'itemDetailModal'} ref={scrollRef}>
                        <ContentContainer>

                            {/* 01. 토픽*/}
                            <TitleTopicWrap>
                                <TitleTopic>{post ? post.topicNameText : ''}</TitleTopic>
                                {getModalForm(post?.topicName) === 'Vote' ? <TitleVoteCount>{`(${getObjectFromString(post?.voteStatus?.userCount)}명 참여)`}</TitleVoteCount> : null}
                            </TitleTopicWrap>

                            {/* 02. 아이콘, 닉네임, 회사명, 시간*/}
                            <userInfoWrap>
                                <UserInfoTime
                                    type={'popup'}
                                    nickname={
                                        post ?
                                            getBoolFromYn(post?.anonymousYn) ?
                                                userStore.user?.userId === post?.regUserid ?
                                                    t('ANONYMOUS_MINE') : t('ANONYMOUS')
                                                :
                                                getIsSystemNotice(post) ? t('MACALON_ADMIN') : post?.nickname
                                            : ''
                                    }
                                    companyName={post ? post?.companyName : ''}
                                    createDate={post ? post?.createDate : ''}
                                    anonymousYn={post?.anonymousYn}
                                    regUserImgId={post?.regUserImgId}
                                    onClickImg={onClickImgHandler}
                                    userInfoNicknameStyle={{}}
                                    UserInfoTextStyle={{}}
                                />
                            </userInfoWrap>

                            {/* 03. 본문*/}
                            <ContentTitleAndSection>
                                {/* title, content 만 */}
                                <Content>

                                    {/* 001. 제목*/}
                                    <TitleTextWrap>
                                        <TitleText isSearching={!isEmpty(highlight)}>
                                            {/* 검색 강조*/}
                                            {
                                                isEmpty(highlight) ? (post ? post.title : '') :
                                                    <Highlighter
                                                        text={post ? post.title : ''}
                                                        highlight={highlight}
                                                        type={'title'}
                                                    />
                                            }
                                        </TitleText>
                                    </TitleTextWrap>


                                    {/* 002. 투표*/}
                                    { getModalForm(post?.topicName) === 'Vote' ?
                                        <VoteWrap>
                                            <Vote
                                                type={'popup'}
                                                itemId={post?.itemId}
                                                items={post?.props}
                                                voteStatus={post?.voteStatus}
                                                voteLog={post?.voteLog}
                                                multipleYn={post?.multipleYn}
                                                onSubmit={onVote}
                                            />
                                        </VoteWrap> : null
                                    }


                                    {/* 003. 내용*/}
                                    <ContentSection ref={contentRef} isSearching={!isEmpty(highlight)}>
                                        {/* 검색 강조*/}
                                        {
                                            isEmpty(highlight) ? (post ? PostHighlighter(post.content) : '') :
                                                <Highlighter
                                                    text={post ? post.content : ''}
                                                    highlight={highlight}
                                                    type={'content'}
                                                />
                                        }
                                    </ContentSection>


                                    {/* 004. 중고 (삽니다, 팝니다) 컨텐츠 만들기 */}
                                    { getModalForm(post?.topicName) === 'Sell' || getModalForm(post?.topicName) === 'Buy' ?
                                        createPropsContent(post?.topicName, post?.props, post?.freecycleYn, post?.multipleYn) : null
                                    }

                                    {/* 005.이미지, 파일 */}
                                    <ContentFilesWrap>
                                        {!isEmpty(getFiles(post?.fileInfoList, 'I')) ?
                                            <ContentFiles image>
                                                {createImgs(getFiles(post?.fileInfoList, 'I'))}
                                            </ContentFiles> : null}

                                        {!isEmpty(getFiles(post?.fileInfoList, 'F')) ?
                                            <ContentFiles>
                                                {createOthers(getFiles(post?.fileInfoList, 'F'))}
                                            </ContentFiles> : null}
                                    </ContentFilesWrap>

                                </Content>
                            </ContentTitleAndSection>

                            {/* 05. 좋아요, 북마크 */}
                            <ContentOptions>
                                { getModalForm(post?.topicName) !== 'Obituary' ?
                                    <ContentOption onClick={(e) => {
                                        onLikeChange(e)
                                    }}>
                                        <IconButton
                                            width={'26px'}
                                            height={'26px'}
                                            src={post ? (post.likesDate ? LikeIcon : UnLikeIcon) : UnLikeIcon}
                                        />
                                        <ContentOptionText>{post ? post.likesCount : ''}</ContentOptionText>
                                    </ContentOption> : null
                                }
                                <ContentOption>
                                    <IconButton
                                        width={'26px'}
                                        height={'26px'}
                                        src={CommentIcon}
                                    />
                                    <ContentOptionText>{post ? post.commentCount : ''}</ContentOptionText>
                                </ContentOption>
                                {
                                    !getBoolFromYn(post?.noticeYn) ?
                                        <BookmarkButton>
                                            <IconButton
                                                width={'26px'}
                                                height={'26px'}
                                                src={post?.bookmarkDate ? BookmarkFilledIcon : BookmarkIcon}
                                                onClick={toggleBookmark}
                                            />
                                        </BookmarkButton> : null
                                }
                            </ContentOptions>

                        </ContentContainer>

                        {/* 06. 댓글 리스트 컴포넌트 */}
                        <CommentsWrap isOpenComments={true}>
                            {createCommentsList(commentsState)}
                        </CommentsWrap>

                    </FamilyEventWrap>
                </FamilyEventContainer>
            </Loading>

            {/* 07. 댓글 쓰기 컴포넌트 */}
            <CommentField
                {...(commentInfo.isModify ? commentInfo.commentProps : null)}
                type={commentInfo.commentType}
                selectedCommentId={commentInfo.selectedCommentId}
                isModify={commentInfo.isModify}
                upperCommentId={commentInfo.commentType === 'comment' ? null :
                    !commentInfo.isModify ? commentInfo.commentProps?.commentId :
                        commentInfo.commentProps?.upperCommentId}
                parentProps={commentInfo.commentType === 'reComment' ?
                    commentInfo.commentProps : null}
                isOpen={true}
                onSubmit={addComment}
                itemId={post?.itemId}
                placeholder={t('COMMENT_FILED_PLACEHOLDER')}
                callback={commentFieldCallback}           // 필드이벤트 (취소, 수정, 남기기)
                isCommentsWriteOpen={commentInfo.isOpen}  // 댓글쓰는창 (취소,남기기,수정창 오픈)
                loadingCallback={loadingCallback}         // 로딩창 (로딩여부)
                page={'web'}
            />

        </>
    )
}

FamilyEvent.defaultProps = {
    page : 'c', // 'c'소통, 'g'모여
    onDelete : null, // 게시글 삭제 함수,
    onError : null,  // 없는 게시글에 접근한 경우
    onClickKeyword : null, // 모여봐요에서 키워드 클릭 시
    highlight : '', // 검색 시 강조될 부분
    onClickImg : null // 이미지 클릭 시, 글 상세 모달 닫고, 프로필 모달 열기
}
export default observer(FamilyEvent);