import styled from "styled-components";

export const GroupInfoSettingContainer = styled.div`
    padding: 0px 16px 20px 16px;
    border-bottom: 8px solid #f1f5f9;
`

// 버튼 위의 내용까지의 영역
export const GroupInfoSettingWrap = styled.div`
    display : flex;
    flex-direction : column;
`

// 취소, 저장 버튼 wrap
export const GroupInfoSettingButtons = styled.div`
    display : flex;
    gap : 5px;
    justify-content : right;
    margin-top : 20px;
`

// 하나의 설정 아이템
export const SettingItem = styled.div`
    display : flex;
    flex-direction: column;
`

export const SettingBorder = styled.div`
    margin-left: -16px;
    margin-right: -16px;
    padding-top: 20px;
    border-bottom: 8px solid #f1f5f9;
`

export const SettingItemTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    padding: 20px 0px 5px 0px;
`

export const SettingItemValue = styled.div`
    width : ${({width})=>width || ''};
`

export const SettingImgWrap = styled.div`
    display : flex;
    flex-direction : column;
    align-items: center;
    margin-bottom : 10px;
    gap : 10px;
`

export const SettingImgText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    text-align: center;
    white-space : pre-wrap;
`

export const SettingCoverImgWrap = styled.div`
    display : flex;
    flex-direction : column;
    gap : 10px;
    align-items: center;
    margin-bottom : 20px;
`
