import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle,
} from "../global/ModalStyles";
import {Button, IconButton, Loading} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import DefaultImg from '../../../resources/icons/profile.png';
import AddImgIcon from '../../../resources/icons/add_img.svg';
import CheckedCircleIcon from '../../../resources/icons/checked_circle.svg';
import {
    EditProfileImgChoiceFileIcon,
    EditProfileImgChoiceItemWrap,
    EditProfileImgChoiceItem,
    EditProfileImgChoiceWrap,
    EditProfileImgModalBody,
    EditProfileImgSelectedImg,
    EditProfileImgSelectedImgWrap,
    EditProfileImgCheckedWrap,
    EditProfileImgCheckIcon,
} from "./EditProfileImgModalStyles";
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useStores} from "../../../stores";
import {receiveResponse} from "../../../utils/api";
import {ServerConfig} from "../../../commons/config";
import {getAvailableFiles} from "../../../utils/fileHandler";
import env from "../../../constants/env";
import {useTranslation} from "react-i18next";

const EditProfileImgModal = (props) => {

    // 다국어 처리에 대한 hook
    const {t} = useTranslation();

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    //데이터
    const data = modalPopupStore;

    const {onClose} = props;


    const {store} = useStores();
    const {userStore, toastPopupStore, modalToastPopupStore, defaultImageStore} = store;
    const currentImgId = userStore.user.imgId;

    // 파일 첨부 ref
    const fileRef = useRef();
    const scrollRef = useRef();

    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [choiceImgId, setChoiceImgId] = useState(userStore.user.imgId);
    const [changeable, setChangeable] = useState(null);
    const [provideImgs, setProvideImgs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // const getProvideImgIds = useCallback(() => {
    //     receiveResponse({useTo: 'U'}, 'imgs', 'get').then(res => {
    //         if (res.status === 200) {
    //             setProvideImgs([...res.data]);
    //         }
    //     });
    // }, []);

    useEffect(() => {
        // 기본 제공 이미지 조회
        if(defaultImageStore.defaultUserImgs!==null) setProvideImgs([...defaultImageStore.defaultUserImgs])
    }, [defaultImageStore.defaultUserImgs]);

    useLayoutEffect(()=>{
        // 모달이 닫힐 때 기본 이미지로 초기화
        setFile(null);
        if(fileUrl) URL.revokeObjectURL(fileUrl);
        setFileUrl(null);
        setChoiceImgId(userStore.user.imgId);
        setChangeable(currentImgId !== userStore.user.imgId);
        if(scrollRef && scrollRef.current) scrollRef.current.scrollTop = 0;
    },[data.isOpen]);

    const createProvideImgList = () => provideImgs.map(imgId => <EditProfileImgChoiceItemWrap key={`choice-${imgId}`}
                                                                                              onClick={() => selectProfileImg(imgId)}>
        <EditProfileImgChoiceItem src={`${ServerConfig.default.IMG_URL}${imgId}`}/>
        {choiceImgId === imgId ? <EditProfileImgCheckedWrap><EditProfileImgCheckIcon
            src={CheckedCircleIcon}/></EditProfileImgCheckedWrap> : null}
    </EditProfileImgChoiceItemWrap>);

    const selectProfileImg = (imgId) => {
        setChoiceImgId(imgId);
        // setFile(null);
        setChangeable(currentImgId !== imgId);
    };

    // 그룹 탈퇴하기 클릭 시 팝업 열기
    const openFileAlert = () => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            t('FILE_CONFIRM_POPUP_FAIL_ONLY_ONE'),
            t('ATTACH_STANDARD'),
            [t('ATTACH_STANDARD_SIZE',{maxFileSize : env.maxFileSize}), `${[...env.imgFileForms].join(', ').toUpperCase()}`],
            t('CONFIRM'),
            env.modalCanclButtonText,
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    const onChangeFile = (e) => {
        setIsLoading(true);
        getAvailableFiles(e.target.files).then(res=>{
            let _file = res;
            if(_file.others?.length > 0 || _file.fails?.length > 0) {
                openFileAlert();
            }else{
                if(fileUrl) URL.revokeObjectURL(fileUrl);
                setChoiceImgId(null);
                setFile(e.target.files[0]);
                setFileUrl(URL.createObjectURL(e.target.files[0]));
                setChangeable(true);
            }
            fileRef.current.value = '';
        }).finally(()=>{setIsLoading(false)});
    };

    const onSubmitHandler = () => {

        setIsLoading(true);

        const formData = new FormData();
        if (choiceImgId) {
            formData.append('imgId', choiceImgId);
        } else {
            formData.append('img', file);
        }

        receiveResponse(formData, 'user/change-img', 'post', {"Content-Type": "multipart/form-data"}).then(res => {
            if (res.status === 200) {
                userStore.setUser({imgId: res.data});
                toastPopupStore.openToastPopup(t('CHANGE_PROFILE_IMAGE'));
                modalPopupStore.closeModalPopup();
            } else {
                toastPopupStore.openToastPopup(t('FAIL_CHANGE_PROFILE_IMAGE'));
            }
        }).finally(()=>{setIsLoading(false)});
    };

    /**
     * 닫기 이벤트
     * @param e
     */
    const onCloseHandler = (e) => {
        //닫기 버튼
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    return (
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            <Loading isLoading={isLoading}>
                {/* HEAD 컴포넌트 */}
                <ModalHeaderWrap>
                    <ModalTitle>{t("PROFILE_IMAGE_CHANGE")}</ModalTitle>
                    <ModalCloseWrap>
                        <IconButton width={'32px'}
                                    height={'32px'}
                                    onClick={e=>onCloseHandler()}
                                    src={CancelIcon}/>
                    </ModalCloseWrap>
                </ModalHeaderWrap>

                {/* 내용 컴포넌트 */}
                <ModalScrollBox ref={scrollRef}>
                    <EditProfileImgModalBody>
                        <EditProfileImgSelectedImgWrap>
                            <EditProfileImgSelectedImg
                                src={file && !choiceImgId ? fileUrl
                                    : choiceImgId ? `${ServerConfig.default.IMG_URL}${choiceImgId}`
                                        : DefaultImg}/>
                        </EditProfileImgSelectedImgWrap>
                        <EditProfileImgChoiceWrap>
                            <EditProfileImgChoiceItemWrap key={'choice-file'} onClick={() => fileRef.current.click()}>
                                <EditProfileImgChoiceFileIcon src={AddImgIcon}/>
                            </EditProfileImgChoiceItemWrap>
                            {
                                file || provideImgs.findIndex(imgId => imgId === userStore.user.imgId)===-1 ?
                                    <EditProfileImgChoiceItemWrap
                                        key={'choice-other-file'}
                                        onClick={(e)=> {
                                            if(file) {
                                                setChoiceImgId('');
                                                setChangeable(true);
                                            }
                                            else {
                                                setChoiceImgId(userStore.user.imgId);
                                                setChangeable(false);
                                            };
                                        }}>
                                        <EditProfileImgChoiceItem
                                            src={
                                                file ? fileUrl :
                                                    `${ServerConfig.default.IMG_URL}${userStore.user.imgId}`
                                            }
                                        />
                                        {
                                            file ?
                                                (!choiceImgId ?
                                                        <EditProfileImgCheckedWrap>
                                                            <EditProfileImgCheckIcon src={CheckedCircleIcon}/>
                                                        </EditProfileImgCheckedWrap> : null
                                                ) :
                                                (choiceImgId === userStore.user.imgId ?
                                                        <EditProfileImgCheckedWrap>
                                                            <EditProfileImgCheckIcon src={CheckedCircleIcon}/>
                                                        </EditProfileImgCheckedWrap> : null
                                                )
                                        }
                                    </EditProfileImgChoiceItemWrap> : null
                            }
                            {createProvideImgList()}
                        </EditProfileImgChoiceWrap>
                    </EditProfileImgModalBody>

                </ModalScrollBox>

                {/* BOTTOM 컴포넌트 */}
                <ModalBottomWrap>
                    <Button width={'100%'}
                            disabled={!changeable}
                            textStyle={{fontSize : '16px', lineHeight: "23px"}}
                            onClick={onSubmitHandler}>{t('SAVE')}</Button>
                </ModalBottomWrap>
                <input type={'file'} ref={fileRef} style={{display: 'none'}} accept={'image/*'} onChange={onChangeFile}/>
            </Loading>
        </ModalContainer>
    )
}

EditProfileImgModal.defaultProps = {
    onClose: null,
}

export default EditProfileImgModal;