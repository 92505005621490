import styled from "styled-components";

export const InvitingUserWrap = styled.div`
    position : relative;
    display : flex;
    background: #F7F9FB;
    border-radius: 10px;
    height: 74px;
    gap : 5px;
    align-items : center;
    padding: 16px;
`

export const InvitingUserInfoWrap = styled.div`
    flex-grow : 1;
    overflow : hidden;
   
`

export const InvitingUserEmail = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
`

export const InvitingUserInfo = styled.div`
    display : flex;
    gap : 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
`

export const InvitingUserName = styled.div`

`

export const InvitingUserCompany = styled.div`
    color: #75818D;
`

export const InviteMenuButton = styled.div`
    width : 24px;
    height : 24px;
    border-radius: 30px;
    z-index : 2;
    
    // &:hover {
    //     background: #EAEAEA;
    // }
`

export const InviteMenuWrap = styled.div`
    // position : absolute;
    position : fixed;
    // right : 5px;
    display : flex;
    flex-direction : column;
    align-items : flex-end;
    z-index : 3;
    &.close {
        display : none;
    }
`

export const InviteMenu = styled.div`
    margin-top : -25px;
    // z-index : 3;
`