import styled from "styled-components";

export const FABWrap = styled.div`
    width : 50px;
    height : 50px;
    border-radius: 100%;
    display : flex;
    flex-wrap: wrap;
    flex-direction: row; /*수평 정렬*/
    align-items: center;
    justify-content: center;
    background : ${({theme,disabled})=>{
        if(disabled) return theme.colors.gray_disabled;
        return theme.colors.black;
    }};
    
    // &:hover {
    //     background : {({theme,disabled})=>{
    //         if(!disabled) return theme.colors.gray_100;  
    //     }}
    // };
    
    
`