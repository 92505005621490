import React, { useEffect } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import env from "../constants/env";
import routes from "../constants/routes.json";
import {getOS, getQueryStringParams} from '../utils/appBridgeEvnet';
import {AppLinkButton, AppLinkButtonText, AppLinkContainer, AppLinkText} from "./appLinkStyles";
import {LoadingImage} from "../pages/auth/AuthStyles";
import LoadingImg from "../resources/icons/loading_img.svg";
import {useTranslation} from "react-i18next";

const AppLink = () => {

    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    // 버튼 누르는 경우, 마카롱 설치 페이지로 이동하는 이벤트
    const toDownloadUrl = () => {

        const appIkenSchema = process.env.NODE_ENV === 'production' ? env.APP_IKEN_SCHEMA : env.DEV_APP_IKEN_SCHEMA;
        const ikenDomain = process.env.NODE_ENV === 'production' ? env.APP_IKEN_DOMAIN : env.DEV_APP_IKEN_DOMAIN;
        const appIkenStoreUrl = `${appIkenSchema}://?url=https://${ikenDomain}`;

        window.location.href = appIkenStoreUrl;
    }

    useEffect(()=>{

        const os = getOS();

        const pathName =location.pathname.replace('/applink/', '');
        const search = location.search;
        const searchParam = getQueryStringParams(location.search);

        // 01.  WEB용 경조사 LINK
        if (pathName === routes.FAMILYEVENT.slice(1)) {
            history.push({
                pathname : routes.AUTH,
                state : { redirect : routes.FAMILYEVENT },
                search
            })
            return;
        }

        // //02.   안드로이드 앱 LINK
        if (os === 'Android' || os === 'IOS') {
            const appSchema = process.env.NODE_ENV === 'production' ? env.APP_SCHEMA : env.DEV_APP_SCHEMA;
            const defaultDomain = process.env.NODE_ENV === 'production' ? env.APP_DOMAIN : env.DEV_APP_DOMAIN;
            const domain = `${defaultDomain}` + '/' + pathName + search;
            const uri = `${appSchema}://?url=https://${domain}`;
            console.log('Android-link uri', uri);

            window.location.href = uri;  //앱실행

        }else if (os === "WEB") {  // 03. WEB으로 LINK
            let webSearch = search;
            let webPathName = pathName;

            //웹페이지는 appply가 link가 아니라 텝 state로 관리해서
            if (webPathName.includes('apply')) {
                webPathName = webPathName.replace('/apply', '');

                // 01.공지아이디 있을경우
                if (search.includes('notificationId')) {
                    webSearch = webSearch + '&id=apply';
                }else {
                    webSearch = '?id=apply';
                }
            }

            const url = process.env.NODE_ENV === 'production' ? "https://macalon.kolon.com/" : "https://macalondev.kolon.com/";
            window.location.replace(url + webPathName + webSearch);
        }
    },[]);

    return (

        // 마카롱 앱으로 가기 버튼 누르는 경우
        getOS() !== 'WEB' ?
            <AppLinkContainer>
                <LoadingImage src={LoadingImg} />
                <AppLinkText>{t('APPLINK_TEXT')}</AppLinkText>
                <AppLinkButton onClick={toDownloadUrl}>
                    <AppLinkButtonText>{t('MOVE_TO_INSTALL_MACALON')}</AppLinkButtonText>
                </AppLinkButton>
            </AppLinkContainer> : null
    )
}

export default AppLink
