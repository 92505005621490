import {
    ProfileGroupActivity,
    ProfileGroupButtons,
    ProfileGroupInfo,
    ProfileGroupInfoNameActive,
    ProfileGroupInfoWrap,
    ProfileGroupName,
    ProfileGroupNameText,
    ProfileGroupWrap
} from "./ProfileGroupStyles";
import PinIcon from "../../../resources/icons/pin_filled.svg";
import UnPinIcon from "../../../resources/icons/pin_normal.svg";
import {Button, IconButton} from "./index";
import {useCallback} from "react";
import {receiveResponse} from "../../../utils/api";
import {getDDayFromDate, getISOFormat, timeForToday} from "../../../utils/dateHandler";
import {useStores} from "../../../stores";
import {ServerConfig} from "../../../commons/config";
import Img from "./Img";
import MasterIcon from '../../../resources/mImages/icon_manager.png';
import {useTranslation} from "react-i18next";
import routes from "../../../constants/routes.json";
import {useHistory} from "react-router-dom";
import env from "../../../constants/env";

const ProfileGroup = (props) => {

    const {group, type, onSubmit} = props;

    const history = useHistory();
    const {store} = useStores();
    const {userStore, modalToastPopupStore, toastPopupStore, defaultImageStore} = store;
    const {t} = useTranslation();

    /**
     * =====================================================================
     * API
     */
        // 그룹 pin toggle (정렬)
    const togglePin = useCallback((joinGroups,groupId) => {
            let route;
            let _group = joinGroups.find(group=>group.groupId === groupId);
            if(_group.pinDate){
                route = `groups/${groupId}/unpin`
            }else{
                route = `groups/${groupId}/pin`
            }
            // 정렬하는 로직 필요
            receiveResponse({}, route, 'patch').then(res => {
                if(res.status === 200){
                    if(res.data){
                        userStore.setJoinGroups(joinGroups.map((group)=>{
                            if(group.groupId===groupId){
                                return {...group, pinDate : (group.pinDate ? null : getISOFormat())}
                            }
                            return group;
                        }))
                    }
                }
            }).catch((e)=>{
                console.log(e);
            });
        },[]);

    // 그룹 폐쇄 보류
    const cancelCloseGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse(
            {},
            `groups/${group.groupId}/cancel-close`,
            'patch',
            {'Content-Type': 'multipart/form-data'}
        )
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(t('CANCEL_APPLY_CLOSE_GROUP'));
                        userStore.updateJoinGroups();
                        onSubmitHandler()
                    }
                }
            })
            .catch(e=>{
                toastPopupStore.openToastPopup('그룹 폐쇄 취소를 실패하였습니다.');
                console.log(e);
            })
    }

    // 그룹 폐쇄 취소 모달 띄우기
    const openCancelCloseGroupPopup = () => {
        modalToastPopupStore.initDefault(
            'alert',
            '그룹 폐쇄를 취소하시겠습니까?',
            '네',
            '아니요',
            cancelCloseGroup
        );
        modalToastPopupStore.openModalPopup();
    }

    // 그룹 탈퇴 api
    const quitGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse({},`groups/${group.groupId}/quit`,'patch')
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(t('COMPLETE_QUIT_GROUP', { groupName : group.groupName.length > 8 ? `${group.groupName.substr(0,8)}...` : group.groupName}));
                        userStore.updateInvitingGroups();
                        userStore.updateJoinGroups();
                        onSubmitHandler()
                    }
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    // 그룹 탈퇴하기 클릭 시 팝업 열기
    const openQuitPopup = () => {
        modalToastPopupStore.initGuide(
            'guide',
            '그룹을 탈퇴하시겠습니까?',
            '탈퇴하더라도 이전에 작성한 게시물은 삭제되지 않습니다.',
            '',
            env.modalOkButtonText,
            env.modalCanclButtonText,
            quitGroup
        );
        modalToastPopupStore.openModalPopup();
    }

    // 가입 신청 취소
    const cancelApplyingGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse({},`groups/${group.groupId}/cancel-apply`,'patch')
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(
                            t('COMPLETE_CANCEL_APPLY_GROUP')
                        );
                        userStore.updateApplicatedGroups();
                        onSubmitHandler()
                    }
                }
            })
            .catch(e=>{
                toastPopupStore.openToastPopup('가입 신청 취소를 실패했습니다.');
                console.log(e);
            })
    }

    // 그룹 폐쇄 취소 모달 띄우기
    const openCancelApplyingGroupPopup = () => {
        modalToastPopupStore.initDefault(
            'alert',
            '가입 신청을 취소하시겠습니까?',
            '네',
            '아니요',
            cancelApplyingGroup
        );
        modalToastPopupStore.openModalPopup();
    }

    // 초대 수락
    const acceptInvite = async () => {
        await receiveResponse(
            {},
            `groups/${group.groupId}/accept-invite`,
            'patch',
            {"Content-Type": "multipart/form-data"}
        )
            .then((res)=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(t('COMPLETE_JOIN_GROUP', { groupName : group.groupName.length > 8 ? `${group.groupName.substr(0,8)}...` : group.groupName}));
                        userStore.updateJoinGroups();
                        userStore.updateInvitingGroups();
                        // @todo 참여중인 목록으로 이동
                        onSubmitHandler();
                    }
                }
            })
            .catch(e=>{
                console.log(e);
                toastPopupStore.openToastPopup('초대 수락에 실패했습니다.');
            })
    }

    // 초대 거절
    const declineInvite = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse(
            {},
            `groups/${group.groupId}/decline-invite`,
            'patch',
            {"Content-Type": "multipart/form-data"}
        )
            .then((res)=>{
                if(res.status===200){
                    if(res.data){
                        userStore.updateInvitingGroups();
                        toastPopupStore.openToastPopup(t('REJECT_INVITE_GROUP', { groupName : group?.groupName.length > 8 ? `${group?.groupName.substr(0,8)}...` : group?.groupName}));
                    }
                }
            })
            .catch(e=>{
                console.log(e);
                toastPopupStore.openToastPopup('초대 거절에 실패했습니다.');
            })
    }

    // 가입 거절 모달 띄우기
    const openDeclinePopup = () => {
        modalToastPopupStore.initDefault(
            'alert',
            t('CONFIRM_REJECT_INVITE_GROUP', {groupName : group.groupName}),
            t('YES'),
            t('NO'),
            declineInvite
        );
        modalToastPopupStore.openModalPopup();
    }

    /**
     * API
     * =====================================================================
     */

    // 버튼 생성
    const createButton = () => {

            if(!group || !userStore.user) return;

            // 참여중인 그룹
            if(type==='PARTICIPATING_GROUP'){
                if(group.managerId === userStore.user.userId){
                    // 폐쇄 중일 때
                    if(group.closingDate) {
                        return(
                            <Button
                                shape={'circle'}
                                wrapperStyle={{alignItems : 'center', padding : '5px 10px'}}
                                width={'fit-content'}
                                height={'32px'}
                                textStyle={{lineHeight:'22px'}}
                                type={'secondary'}
                                onClick={openCancelCloseGroupPopup}
                            >{t('CANCEL_CLOSE_GROUP')}</Button>
                        )
                    }
                    return(
                        <Button
                            shape={'circle'}
                            wrapperStyle={{alignItems : 'center', padding : '5px 18px'}}
                            width={'fit-content'}
                            height={'32px'}
                            textStyle={{lineHeight:'22px'}}
                            type={'secondary'}
                            onClick={moveToGroupSetting}
                        >{t('SETTING_GROUP')}</Button>
                    )
                }else{
                    return(
                        <Button
                            shape={'circle'}
                            wrapperStyle={{alignItems : 'center', padding : '5px 18px'}}
                            width={'fit-content'}
                            height={'32px'}
                            textStyle={{lineHeight:'22px'}}
                            type={'secondary'}
                            onClick={openQuitPopup}
                        >{t('QUIT_GROUP')}</Button>
                    )
                }
            }else if(type==='APPLYING_GROUP'){
                return(
                    <Button
                        shape={'circle'}
                        wrapperStyle={{alignItems : 'center', padding : '5px 18px'}}
                        width={'fit-content'}
                        height={'32px'}
                        textStyle={{lineHeight:'22px'}}
                        type={'secondary'}
                        onClick={openCancelApplyingGroupPopup}
                    >{t('CANCEL_APPLYING_GROUP')}</Button>
                )
            }else if(type==='INVITING_GROUP'){
                return(
                    <>
                        <Button
                            shape={'circle'}
                            wrapperStyle={{alignItems : 'center', padding : '5px 18px'}}
                            width={'fit-content'}
                            height={'32px'}
                            textStyle={{lineHeight:'22px'}}
                            type={'secondary'}
                            onClick={openDeclinePopup}
                        >{t('REJECT')}</Button>
                        <Button
                            shape={'circle'}
                            wrapperStyle={{alignItems : 'center', padding : '5px 18px'}}
                            width={'fit-content'}
                            height={'32px'}
                            textStyle={{lineHeight:'22px'}}
                            type={'mobile'}
                            onClick={acceptInvite}
                        >{t('ACCEPT')}</Button>
                    </>
                )
            }
        }

    /**
     * ============================================================================
     */
    const moveToGroupSetting = () => {
        history.push({
            pathname : `${routes.GROUP_SETTING}/${group.groupId}`
        })
    }

    const onSubmitHandler = () => {
        if(onSubmit && typeof onSubmit === 'function') onSubmit();
    }

    return(



        <ProfileGroupWrap>
            {
                type==='PARTICIPATING_GROUP' ?
                    <IconButton
                        width={'32px'}
                        height={'32px'}
                        onClick={e=> {
                            e.stopPropagation();
                            togglePin(userStore.joinGroups, group?.groupId)
                        }}
                        src={group?.pinDate ? PinIcon : UnPinIcon}
                    /> : null
            }
            <ProfileGroupInfoWrap className={group?.closingDate ? 'closing' : ''}>
                <ProfileGroupInfoNameActive>
                    <ProfileGroupInfo>
                        {
                            defaultImageStore.defaultGroupImgs ?
                                <Img
                                    src={
                                        defaultImageStore.defaultGroupImgs.findIndex(img=>img === group?.imgId)!==-1 ?
                                            `${ServerConfig.default.IMG_URL}${group?.imgId}` :
                                            `${ServerConfig.default.IMG_THUMBNAIL_URL}${group?.imgId}`
                                    }
                                    wrapStyle={{width : '32px', height : '32px', flex : 'none', borderRadius: '8px' }}
                                    onClick={e=>history.push(`${routes.GROUPS}/${group.groupId}`)}
                                /> : null
                        }
                        <ProfileGroupName>
                            { group.managerId === userStore.user.userId ?
                                <IconButton src={MasterIcon} height={'20px'} wrapperStyle = {{ alignSelf: 'center'}}/> : null}
                            <ProfileGroupNameText>{group.groupName}</ProfileGroupNameText>
                        </ProfileGroupName>
                    </ProfileGroupInfo>
                    <ProfileGroupActivity className={group.closingDate ? 'closing' : ''}>
                    {
                        group.closingDate ?
                            `${t("CLOSE_GROUP_APPLYING")} : D-${getDDayFromDate(group?.closingDate)} ` :
                            `${t("LAST_ACTIVITY")} : ${group.lastActivityDate ?
                                timeForToday(group.lastActivityDate)
                                : timeForToday(group.createDate)}`

                    }
                    </ProfileGroupActivity>
                </ProfileGroupInfoNameActive>
                <ProfileGroupButtons>{createButton()}</ProfileGroupButtons>
            </ProfileGroupInfoWrap>

        </ProfileGroupWrap>
    )
}

ProfileGroup.defaultProps = {
    type : '', // PARTICIPATING_GROUP, APPLYING_GROUP, INVITING_GROUP
    group : null,
    onSubmit : null
}

export default ProfileGroup;