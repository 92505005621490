import styled from "styled-components";

export const NoResultWrap = styled.div`
    text-align : center;
    height : fit-content;
`

export const NoResultImageWrap = styled.div`
    width : 124px;
    height : 124px;
    margin : 0 auto;
`

export const NoResultImage = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  height: 100%;
  border-radius: 88px;
`

export const NoResultTitleWrap = styled.div`
    text-align : center;
    color : #75818D;
    margin-top : 16px;
`

export const NoResultTitle = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin-bottom : 4px;
    color: #AEB7C0;
`

export const NoResultSubTitle = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    white-space: pre-wrap;
`