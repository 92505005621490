import styled from "styled-components";

export const ProfileItemsContainer = styled.div`
    width : 100%;
    height: calc(100% - 43px);
    background: #f7f9fb;
`

export const SearchWrap = styled.div`
    height: 43px;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const ProfileItemsPreBtn = styled.div`
    position: absolute;
    top: 10px; 
    left: 16px;
`

export const ProfileItemsPreBtnImg = styled.img`
`

export const ProfileItemsHeaderSettingWrap = styled.div`
    position: absolute;
    top: 10px; 
    right: 16px;
`

export const ProfileItemsTitleWrap = styled.div`
    position: relative;
    background: #ffffff;
`

export const ProfileItemsTitle = styled.h2`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding: 10px 0px 10px 0px;
`

export const ProfileItemsWrap = styled.div`
    
`

export const ProfileItemsNav = styled.div`
    padding: 8px 0px 8px 16px;
    background: #ffffff;
    display: flex;
    overflow-x: auto; 
`

export const ProfileItemsButtons = styled.div`
    display : flex;
    gap : 6px;
    flex-grow : 1;
`

export const ProfileItemsList = styled.div`
    padding: 20px 16px 10px 16px;
    background: #f7f9fb;
`

export const ProfileItemsDropWrap = styled.div`
    background: #ffffff;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 10px;
`

export const ProfileItemsNoResultWrap = styled.div`
    padding: 20px 16px 0px 16px;
    flex-direction: column;
    height: calc(100% - 66px);  // margin-bottom: 10px; 때문에 위에
    width : 100%;
    display : flex;
    align-items : center;
    // justify-content : center;
`

export const ProfileNoResultWrap = styled.div`
    padding: 0px 16px 30px 16px;
    flex-direction: column;
    height: 100%;
    width : 100%;
    display : flex;
    align-items : center;
    justify-content : center;
`