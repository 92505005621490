import styled from "styled-components";


export const HomeSearchWrap = styled.div` 
     padding: 20px 0px 0px 16px; 
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05); 
     align-items : center; 
     justify-content : center; 
     position : sticky; 
     top : 0; 
     left : 0; 
     background: #FFFFFF; 
     z-index: 1;
     
     &.down {
        position : relative;
     }
     
     &.show{
        position : sticky;
     }
`

export const SearchBar = styled.div` 
     display: flex;
     width: 100%;
`

export const SearchBarRight = styled.div` 
     display: flex;
     width: 100%;
     justify-content: flex-end;
     padding-right: 16px;
`

export const SearchBarTextWrap = styled.div` 
    display: flex;
    align-items: center;
    width: 110px;
    padding-right: 7px;
`

export const SearchBarText = styled.h2` 
    font-weight: 700;
    width: 100px;
    font-size: 16px;
    line-height: 23px;
    color: #191E29;
`

export const SearchBarSearch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    
    color: ${({selected})=> {
        if(selected) {
            return '#3A6AF6;'
        }else {
            return '#FFFFFF;'
        }
    }}
    
    border : ${({selected, disabled})=> {
        if(disabled) {
            return '1px solid #EAEAEA;';
        }
        else if(selected) {
            return '1px solid #3A6AF6;';
        }
        return '1px solid #EAEAEA;';
        
    }}
    
    background: ${({selected, disabled})=> {
        if(disabled){
            return '#F2F4F6;';
        }
        if(selected) {
            return '#E7EDFF;';
        }
    }}
`

export const SearchBarBookmark = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-sizing: border-box;
    background: #FFFFFF;
    /* gray_Stroke */

    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    
    background : ${({disabled})=>{
        if(disabled){
            return '#F2F4F6';
        }else {
            return '#FFFFFF';
        }
    }};
        
    color : ${({disabled})=>{
        if(disabled){
            return '#AEB7C0';
        }
    }};

`

export const SearchBarVisible = styled.div` 
     &.hidden { display:none; }
     display: flex;
     width: 100%;
     padding: 10px 20px 0px 0px;
`

export const TopicsWrap = styled.div`
    display: flex; 
    overflow-x: auto; 
    margin-top: 17px; 
    padding-bottom: 21px; 
`

export const HomeSearchSubTitle = styled.div`
    padding: 15px 0px 10px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight : 500;
    width : 100%;
    word-break : break-all;
`

export const HomeSearchSubTitleWord = styled.span`
    font-weight : 700;
`

export const HomeSearchResultWrap = styled.div`
    padding-top : 30px;
    text-align : center;
    width : 100%;
`

export const HomeBodyWrap = styled.div` 

`


export const HomeContentWrap = styled.div`
    width: 100%;
    display: inline-block;
    font-size: 0px;
`

export const HomeQuickWrap = styled.div`
    position: fixed; 
    bottom: 77px; 
    right: 20px; 
    z-index: 3;
`

export const HomeFABWrap = styled.div`
    // position : absolute;
    // bottom : 90px;
    // right : 60px;
    // z-index : 3;
    // &.hide{
    //     display : none;
    // }
    
    display: block; 
    width: 50px; 
    height: 50px; 
    filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.1)); 
    margin: 10px 0px 0px 0px;
    
    &.hide{
        display : none;
    }
`

