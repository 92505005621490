import styled from "styled-components";

export const ConfirmModalContainer = styled.div`
    width : 100vw;
    height : 100vh;
    background-color : rgba(25,30,41,0.3);
    display : flex;
    align-items : center;
    justify-content : center;
    position : fixed;
    top : 0;
    left : 0;
    z-index : 6;
    &.hidden{display:none;}
`

export const ConfirmModalWrap = styled.div`
    background : ${({theme})=>theme.colors.white};
    padding : 20px;
    border-radius: 30px;
    position : relative;
    width : 320px;
`

export const ConfirmModalAlertTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    margin-bottom : 10px;
`

export const ConfirmModalTitle = styled.div`
    display : flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color : ${({theme})=>theme.colors.black};
    // min-height : 46px;
    margin-bottom : 20px;
    justify-content : center;
    white-space : pre-wrap;
    
    &.guide {
        margin-bottom : 10px;
    }
    
    &.guide-alert{
        margin-bottom : 10px;
        justify-content : flex-start;
        text-align : left;
    }
    
`

export const ConfirmModalButtons = styled.div`
    justify-content : center;
    display : flex;
    gap : 10px;
`