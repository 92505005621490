import styled from "styled-components";

export const GroupApplySettingContainer = styled.div`
    height : 100%;
    padding: 20px 16px 10px 16px;
    display : flex;
    flex-direction : column;
`

export const GroupApplyTitle = styled.div`
    padding-bottom : 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
`

export const GroupApplyList = styled.div`
    display : flex;
    flex-direction : column;
    gap : 20px;
    flex-grow : 1;
`

export const GroupApplyNoResultWrap = styled.div`
    width : 100%;
    height : 400px;
    display : flex;
    align-items : center;
    justify-content : center;
`