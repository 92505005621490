import {useEffect} from "react";
import {useStores} from "../stores";
import {observer} from "mobx-react";
import {getOS, webViewAppFirebaseEvent} from "./appBridgeEvnet";

export const AppBridge = () => {

    const {store} = useStores();
    const authStore = store.authStore;

    useEffect(() => {

        // 앱일 경우
        if (getOS() !== 'WEB') {
            //01. app -> web (mobile token)
            webViewAppFirebaseEvent((token) => {
                console.log('app token', token);
                authStore.setAppToken(token);
            })

            window.addEventListener("flutterInAppWebViewPlatformReady", ()=>{});
            //02. 앱 이벤트 연결 true
            authStore.setIsAppReady(true);
        }

        return () => {

            // 앱일 경우
            if (getOS() !== 'WEB') {
                window.removeEventListener("flutterInAppWebViewPlatformReady", ()=>{});
                //02. 앱 이벤트 연결 false
                authStore.setIsAppReady(false);
            }
        }
    }, []);

    return null;
}

export default observer(AppBridge);



