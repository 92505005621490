import React from 'react';
import {Link} from 'react-router-dom';
import {
    GroupsTopListTopStyle,
    GroupsTopListWrapStyle,
    GroupsTopCard,
    GroupsTopCardWrap,
    GroupsTopListBottom,
    GroupsTopListDtBottom,
    GroupsTopListDdBottom,
    GroupsTopCardImg,
    GroupsTopListDdBestBottom,
    GroupsTopListDdBestImgBottom,
    GroupsTopListDdBestStrongBottom
} from "./GroupsTopStyles";
import {useStores} from "../../../stores";
import {ServerConfig} from "../../../commons/config";
import routes from '../../../constants/routes.json';

import {getDDayFromDate} from "../../../utils/dateHandler";
import {getBoolFromYn} from "../../../utils/boolHandler";
import DefaultImg from "../../../resources/icons/profile.png";
import {observer} from "mobx-react";

const GroupsTopListComponent = (probs) => {

    const {store} = useStores();
    const {userStore, modalDetailStore, defaultImageStore} = store;

    const {joinGroupsYn, item, callBackOnClick} = probs;
    const key = `groups-hot-${item.itemId}`;


    // 상세창 열기
    const onItemDetailPopupOpenEvent = (item) => {

        // 폐쇄된 그룹이나 강퇴당한 그룹일 경우 팝업 열지않기 추가
        if(!item.group) return;

        let _kickDate = item.group?.kickDate;
        let _closingDate = getDDayFromDate(item.group?.closingDate);

        if(!_kickDate && _closingDate >= 0) {
            onOpenItem(item);
        }

    }

    // 내 그룹 새 글에서 전달 받은 state있으면 popup open
    const onOpenItem = (item) => {
        if(item?.itemId){
            modalDetailStore.init(
                'g',             //'c', 'g' 타입
                item.itemId           // 아이템Id
            );
        }
    }

    const bottomComponent = (joinGroupsYn) => {
        // 모달 폼 형식

        switch (joinGroupsYn) {

            // 내가 가입하지 않았을 경우 추천 리스트
            case 'N' : {
                return (
                    <Link to={{pathname: `${routes.GROUPS}/${item?.groupId}`, state: {itemId: item.itemId}}}>
                        <GroupsTopListBottom>
                            <GroupsTopListDtBottom>{item?.title}</GroupsTopListDtBottom>
                            <GroupsTopListDdBottom>{item?.content}</GroupsTopListDdBottom>
                        </GroupsTopListBottom>
                    </Link>
                )
            }
            // 내가 가입한 그룹 추천 리스트  (바로 팝업창 열기)
            case 'Y' : {
                return (
                    <Link onClick={()=>{onItemDetailPopupOpenEvent(item)}}>
                        <GroupsTopListBottom>
                            <GroupsTopListDtBottom>{item?.title}</GroupsTopListDtBottom>
                            <GroupsTopListDdBottom>{item?.content}</GroupsTopListDdBottom>
                            <GroupsTopListDdBestBottom>
                                {
                                    defaultImageStore.defaultUserImgs ?
                                        <GroupsTopListDdBestImgBottom
                                            src={
                                                getBoolFromYn(item?.anonymousYn) ?
                                                    DefaultImg :
                                                    defaultImageStore.defaultUserImgs.findIndex(img=>img === item?.regUserImgId)!==-1 ?
                                                        `${ServerConfig.default.IMG_URL}${item?.regUserImgId}` :
                                                        `${ServerConfig.default.IMG_THUMBNAIL_URL}${item?.regUserImgId}`
                                            }
                                            onError={({currentTarget}) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = DefaultImg;
                                            }}
                                        /> : null
                                }
                                <GroupsTopListDdBestStrongBottom>{
                                    getBoolFromYn(item?.anonymousYn) ? '익명' : item?.nickname}</GroupsTopListDdBestStrongBottom>
                                {`${item?.companyName}`}
                            </GroupsTopListDdBestBottom>
                        </GroupsTopListBottom>
                    </Link>
                )
            }
            default: {
                return (
                    null
                )
            }
        }
    }

    return(
        <GroupsTopListWrapStyle id = {item?.itemId} key = {key}>
            <GroupsTopCard className = {joinGroupsYn === 'Y' ? 'isMyGroups' : ''} >
                <Link to={{pathname : `${routes.GROUPS}/${item?.groupId}`}}>
                    <GroupsTopCardWrap>
                        {
                            defaultImageStore.defaultGroupImgs ?
                                <GroupsTopCardImg
                                    src={
                                        defaultImageStore.defaultGroupImgs.findIndex(img=>img === item.group?.imgId)!==-1 ?
                                                `${ServerConfig.default.IMG_URL}${item.group?.imgId}` :
                                                `${ServerConfig.default.IMG_THUMBNAIL_URL}${item.group?.imgId}`
                                    }
                                /> : null
                        }
                        <GroupsTopListTopStyle>{item.group?.groupName}</GroupsTopListTopStyle>
                        {/*{*/}
                        {/*    getBoolFromYn(joinGroupsYn) ?*/}
                        {/*        <GroupsTopListTopDate>{getDateFromNoTime(item?.createDate)}</GroupsTopListTopDate> : null*/}
                        {/*}*/}
                    </GroupsTopCardWrap>
                </Link>
                {bottomComponent(joinGroupsYn)}
            </GroupsTopCard>
        </GroupsTopListWrapStyle>
    )
}

export default observer(GroupsTopListComponent);