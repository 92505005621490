import styled from "styled-components";

// export const ButtonWrap = styled.button`
//
//     margin-top : 20px;
//     font-weight : 500;
//     text-align : center;
//     outline : none;
//     display : block;
//     border-radius: 100px;
//     box-sizing : border-box;
//
//     width : ${({width})=>{
//         if(width) return width;
//     }};
//
//     font-size : ${({size})=>{
//         if(size==='s'){
//             return '12px';
//         }else if(size==='m'){
//             return '14px';
//         }else if(size==='l'){
//             return '16px';
//         }
//     }};
//
//     line-height : ${({size})=>{
//         if(size==='s'){
//             return '17px';
//         }else if(size==='m'){
//             return '20px';
//         }else if(size==='l'){
//             return '23px';
//         }
//     }};
//
//     padding : ${({size})=>{
//         if(size==='s'){
//             return '9.5px 18px';
//         }else if(size==='m'){
//             return '11px 24px';
//         }else if(size==='l'){
//             return '12px 36px';
//         }
//     }};
//
//     color : ${({type,disabled,theme})=>{
//
//         if(disabled){
//             return theme.colors.gray_50;
//         }
//
//         if(type==='primary'){
//             return theme.colors.white;
//         }else if(type==='secondary'){
//             return theme.colors.black;
//         }
//     }};
//
//     background : ${({type,disabled,theme})=>{
//
//         if(disabled){
//             return theme.colors.gray_disabled;
//         }
//
//         if(type==='primary'){
//             return theme.colors.black;
//         }else if(type==='secondary'){
//             return theme.colors.white;
//         }
//     }};
//
//     border : ${({type,theme})=>{
//         if(type==='secondary'){
//             return `1px solid ${theme.colors.gray_stroke_100}`;
//         }else{
//             return 'none';
//         }
//     }};
//
//     cursor : ${({disabled})=>{
//
//         if(disabled){
//             return 'inherit';
//         }
//
//         return 'pointer';
//     }};
//
//     &:hover {
//         background : ${({type,disabled,theme})=>{
//             if(disabled){
//                 return;
//             }
//             if(type==='primary'){
//                 return '#353C49';
//             }else if(type==='secondary'){
//                 return theme.colors.gray_hover;
//             }
//         }};
//     }
// `

export const ButtonWrap = styled.div`

    display : flex;
    align-items : center;
    justify-content : center;
    box-sizing : border-box;
    width : ${({width})=>width ? width : '102px'};
    height : ${({height})=>height ? height : '102px'};
    
    background: ${({type, disabled})=>{
        if(disabled) return '#F2F4F6';
        if(type==='secondary') return '#FFFFFF';
        else if(type==='tertiary') return '#AEB7C0';
        else if(type=='primary') return '#191E29';
        else if(type=='mobile') return '#3A6AF6';
        return '#191E29';
    }};
    
    border-radius : ${({shape})=>{
        if(shape==='circle') return '100vh';
        return '14px';
    }};
    
    border : ${({type,disabled})=>{
        
        if(disabled) return '1px solid #F2F4F6';
        
        if(type==='secondary'){
            if(!disabled) return '1px solid #E5EBF0';
        }else if(type==='tertiary') {
            if(!disabled) return '1px solid #AEB7C0';
        }else if(type=='primary') {
            if(!disabled) return '1px solid #191E29';
        }else if(type=='mobile') {
            if(!disabled) return '1px solid #3A6AF6';
        }
        return '1px solid #191E29';
        
    }};
    
    
    // &:hover {
    //    
    //     background: {({type, disabled})=>{
    //         if(disabled) return;
    //         if(type==='secondary') return '#DEE4EA';
    //         else if(type==='tertiary') return '#75818D';
    //         else if(type=='primary') return '#353C49';
    //         else if(type=='mobile') return '#3A6AF6';
    //         return '#353C49';
    //     }};
    //    
    //     // border : none;
    // }
    
`

export const ButtonText = styled.div`

    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    
    color: ${({type, disabled})=>{
        if(disabled) return '#AEB7C0';
        if(type==='secondary') return '#191E29';
        else if(type==='tertiary') return '#FEFEFE';
        else if(type==='primary') return '#F1F5F9';
        else if(type==='mobile') return '#FFFFFF';
        return '#F1F5F9';
    }};
`