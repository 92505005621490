import {useTranslation} from "react-i18next";
import {useStores} from "../../../stores";
import {
    GroupModalImgs,
    GroupStickerAddImg,
    GroupStickerAddImgWrap,
    GroupStickerCheckIcon,
    GroupStickerImg,
    GroupStickerImgWrap,
    GroupStickerItem,
    GroupStickerSelectedWrap
} from "./CreateGroupModalStyles";
import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle
} from "../global/ModalStyles";
import {Button, IconButton} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import isEmpty from "is-empty";
import AddGroupImgIcon from "../../../resources/icons/add_group_img.svg";
import CheckedCircleIcon from "../../../resources/icons/checked_circle.svg";
import {useCallback, useLayoutEffect, useRef, useState} from "react";
import {ServerConfig} from "../../../commons/config";
import {receiveResponse} from "../../../utils/api";
import {getAvailableFiles} from "../../../utils/fileHandler";
import env from "../../../constants/env";

const EditGroupImageModal = (props) => {

    /**
     * modalPopupStore.detailItem에 group 정보가 들어있음
     */

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const data = modalPopupStore;
    const group = data.detailItem;

    const {type} = props;

    //다국어 처리에 대한 hook
    const {t} = useTranslation();
    const {store} = useStores();
    const {userStore, toastPopupStore, interestStore, modalToastPopupStore, defaultImageStore} = store;

    // 현재 선택된 이미지
    const [selectedImg, setSelectedImg] = useState(null);
    // imgs
    const [provideImgs, setProvideImgs] = useState([]);
    // 파일 첨부 ref
    const fileRef = useRef();
    // 파일 state
    const [file, setFile] = useState(null);
    // 파일 url state
    const [fileUrl, setFileUrl] = useState(null);
    // 로딩 중 state
    const [isLoading, setIsLoading] = useState(false);

    const scrollRef = useRef();

    // 그룹
    // 닫기 이벤트
    const onCloseHandler = (e) => {
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    // 초기 세팅
    const initdata = () => {
        setSelectedImg(null);
        setFile(null);
    }

    // 파일 변경 이벤트
    const onChangeFile = (e) => {

        if(e.target.files.length===0) return;
        setIsLoading(true);

        getAvailableFiles(e.target.files).then(res=>{
            let _file = res;
            if(_file.others?.length > 0 || _file.fails?.length > 0) {
                openFileAlert();
            }else{
                setSelectedImg(null);
                setFile(e.target.files[0]);
                if(fileUrl) URL.revokeObjectURL(fileUrl);
                setFileUrl(URL.createObjectURL(e.target.files[0]));
            }
            fileRef.current.value = '';
        }).finally(()=>{setIsLoading(false)});
    };


    const openFileAlert = () => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            t('FILE_CONFIRM_POPUP_FAIL_ONLY_ONE'),
            t('ATTACH_STANDARD'),
            [t('ATTACH_STANDARD_SIZE',{maxFileSize : env.maxFileSize}), `${[...env.imgFileForms].join(', ').toUpperCase()}`],
            t('CONFIRM'),
            env.modalCanclButtonText,
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 이미지 저장
    const onSubmitHandler = (e) => {
        data.submitCallBack(selectedImg ? selectedImg : file);
        modalPopupStore.closeModalPopup();
    }

    /**
     * 컴포넌트
     * @param e
     */
    // 이미지 생성 컴포넌트
    const createGroupImgs = () => {
            return provideImgs.map(imgId=>{
                return <GroupStickerItem className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}>
                    <GroupStickerImgWrap
                        className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}
                        onClick={()=> {
                            setSelectedImg(imgId);
                        }}
                    >
                        <GroupStickerImg src={`${ServerConfig.default.IMG_URL}${imgId}`}/>
                        {
                            imgId === selectedImg ?
                                <GroupStickerSelectedWrap>
                                    <GroupStickerCheckIcon src={CheckedCircleIcon}/>
                                </GroupStickerSelectedWrap> : null
                        }
                    </GroupStickerImgWrap>
                </GroupStickerItem>
            })
        }

    // 기본이미지인지, 선택한 이미지 인지 판단
    const getSelectedImgIndex = () => {
        if(!provideImgs) return;
        return provideImgs.findIndex(imgId=>imgId === group.selectedImgId);
    }

    // button disabled
    const isDisabled = () => {
        if(!file && (!selectedImg || !group?.selectedImgId)) return true;
        if(file && !selectedImg) return false;
        // 파일이 있는 경우 (초기 상태 아님, 파일 선택 이후)
        if(selectedImg === group.selectedImgId) return true;
    }

    // 이미지 선택 현황을 초기화 함
    const initSelected = () => {
        if(fileUrl) URL.revokeObjectURL(fileUrl);
        if(group.imgFile){
            setFile(group.imgFile);
            setFileUrl(URL.createObjectURL(group.imgFile));
            setSelectedImg(group.selectedImgId);
        }else{
            let index = getSelectedImgIndex();
            if(index!==-1){
                setSelectedImg(provideImgs[index]);
            }else{
                setSelectedImg(group.imgId);
            }
        }
    }

    /**
     * ===============================================================================
     * API
     */
    //     // 기본 제공되는 관심사 이미지 가져오기
    // const getProvideImages = useCallback(async () => {
    //         await receiveResponse({useTo : 'I'},'imgs','get')
    //             .then(res=>{
    //                 if(res.status===200){
    //                     setProvideImgs([...res.data]);
    //                 }
    //             })
    //             .catch(e=>{
    //                 console.log(e);
    //             })
    //     },[])

    /**
     * API
     * ===============================================================================
     *
     */

    useLayoutEffect(()=>{
        if (data.isOpen) {
            // 기본 제공 이미지를 조회
            initSelected();
            if(!isEmpty(provideImgs)) initSelected();
            else setProvideImgs(defaultImageStore.defaultGroupImgs);
            if(scrollRef && scrollRef.current ) scrollRef.current.scrollTop = 0;

        }else{
            if(fileUrl) URL.revokeObjectURL(fileUrl);
            if(file) setFile(null);
        }
    },[data.isOpen]);

    useLayoutEffect(()=>{
        if(provideImgs) initSelected();
    },[provideImgs])

    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalTitle>{type==='cover-img' ? t('CHANGE_COVER_COLOR') : t('CHANGE_REP_IMAGE')}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'}
                                height={'32px'}
                                onClick={onCloseHandler}
                                src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollBox ref={scrollRef}>
                <GroupModalImgs className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}>
                    <GroupStickerItem className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}>
                        <GroupStickerImgWrap
                            border={true}
                            onClick={(e)=>fileRef.current.click()}
                            className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}
                        >
                            <GroupStickerAddImgWrap>
                                <GroupStickerAddImg src={AddGroupImgIcon}/>
                            </GroupStickerAddImgWrap>
                        </GroupStickerImgWrap>
                    </GroupStickerItem>

                    {/* 파일 선택 시 나타남 */}
                    {
                        file || provideImgs.findIndex(imgId => imgId === group.imgId)===-1 ?
                            <GroupStickerItem className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}>
                                <GroupStickerImgWrap
                                    border={true}
                                    onClick={(e)=> {
                                        if(file) setSelectedImg('');
                                        else setSelectedImg(group.imgId);
                                    }}
                                    className={type==='cover-img' ? 'coverImg-setting' : 'img-setting'}
                                >
                                    {
                                        <GroupStickerImg
                                            src={
                                                file ? fileUrl :
                                                    `${ServerConfig.default.IMG_URL}${group.imgId}`
                                            }
                                        />
                                    }
                                    {
                                        file ?
                                            (!selectedImg ?
                                                <GroupStickerSelectedWrap>
                                                    <GroupStickerCheckIcon src={CheckedCircleIcon}/>
                                                </GroupStickerSelectedWrap> : null) :
                                            (selectedImg === group.imgId ?
                                                <GroupStickerSelectedWrap>
                                                    <GroupStickerCheckIcon src={CheckedCircleIcon}/>
                                                </GroupStickerSelectedWrap> : null)
                                    }
                                </GroupStickerImgWrap>
                            </GroupStickerItem> : null

                    }
                    {createGroupImgs()}
                </GroupModalImgs>

            </ModalScrollBox>

            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button
                    textStyle={{fontSize : '16px', lineHeight: "23px"}}
                    shape={'circle'}
                    width={'100%'}
                    disabled={isDisabled()}
                    onClick={onSubmitHandler}
                >{t('SAVE')}</Button>
            </ModalBottomWrap>

            <input type={'file'} ref={fileRef} style={{display: 'none'}} accept={'image/*'} onChange={onChangeFile}/>
        </ModalContainer>
    )
}

EditGroupImageModal.defaultProps = {
    type : 'img' // 'img', 'cover-img'
}
export default EditGroupImageModal;