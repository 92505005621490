import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalSubTitle,
    ModalTitle,
} from "../global/ModalStyles";
import {Button, IconButton} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";
import DeleteAllIcon from "../../../resources/icons/circle_delete.svg";

import {
    EditNicknameCount,
    EditNicknameCountWrap,
    EditNicknameDeleteWrap,
    EditNicknameInput,
    EditNicknameInputContainer,
    EditNicknameInputWrap,
    EditNicknameLen,
    EditNicknameModalBody,
    EditNicknameModalGuide,
    EditNicknameModalGuides,
    EditNicknameValidResult
} from "./EditNicknameModalStyles";
import {useLayoutEffect, useRef, useState} from "react";
import {useStores} from "../../../stores";
import {receiveResponse} from "../../../utils/api";
import {validateNicknameChangeDate} from "../../../utils/dateHandler";
import env from "../../../constants/env";
import {useTranslation} from "react-i18next";

const EditNicknameModal = (props) => {

    const {t} = useTranslation();
    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    //데이터
    const data = modalPopupStore;

    const {onClose} = props;
    const {store} = useStores();
    const {userStore, toastPopupStore, modalToastPopupStore} = store;
    const [inputNickname, setInputNickname] = useState(userStore.user.nickName);
    const [changeable, setChangeable] = useState(null);
    const [isFocus, setIsFocus] = useState(true);
    const [reason , setReason] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const inputRef = useRef(null);

    const changeInputNickname = (e) => {

        const value = e.target.value;
        const regex = new RegExp(`^[a-zA-Z0-9ㄱ-힣\s]{0,${env.nicknameMaxLen}}$`,'gi');
        const check = regex.test(value);

        if(!check && value) return;

        setChangeable(null);
        setInputNickname(value);

        if(!isEditing) setIsEditing(true);
    };


    const onResetInputNickname = (e) => {
        setInputNickname('');
        setChangeable(null);
    }

    const checkNickname = () => {
        // 이전 닉네임과 동일한지 체크
        if(inputNickname === userStore.user.nickName) {
            setReason(t('SAME_WITH_PREV_NICKNAME'));
            setChangeable(false);
            setIsEditing(false);
            return;
        }

        receiveResponse({}, `user/nickNameCheck/${encodeURIComponent(inputNickname)}`, 'post')
            .then(res => {
                if (res.status === 200) {
                    if(res.data) {
                        setReason(t('EXIST_NICKNAME'));
                        setChangeable(!res.data);
                    }
                    return res.data;
                }
            })
            .then((result)=>{
                if(result) return;
                return receiveResponse({}, `user/get/${userStore.user.userId}/nickname-change-date`, 'get')
            })
            .then((res)=>{
                if(res.status===200){
                    if(!validateNicknameChangeDate(res.data)) {
                        setReason(t('MAX_NICKNAME_CHANGE_CNT'));
                        setChangeable(false);
                    }else{
                        setReason(t('CAN_CHANGE_NICKNAME'));
                        setChangeable(true);
                    }
                }
            }).finally(()=>{setIsEditing(false);})
    };

    const onSubmitHandler = () => {
        if (!modalToastPopupStore.isCancel) return;
        receiveResponse({nickname: inputNickname}, 'user/change-nickname', 'patch', {"Content-Type": "multipart/form-data"}).then(res => {
            if (res.status === 200) {
                userStore.setUser({nickName: inputNickname});
                toastPopupStore.openToastPopup(t('CHANGE_NICKNAME'));
                modalPopupStore.closeModalPopup();
            } else toastPopupStore.openToastPopup(t('FAIL_CHANGE_NICKNAME'));
        })
    };

    /**
     * 닫기 이벤트
     * @param e
     */
    const onCloseHandler = (e) => {
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    /**
     * 변경 중 변경하기 모달 닫기
     */
    const onOpenClosePopup = () => {
        if(inputNickname.length!==0 && userStore.user.nickName!==inputNickname){
            modalToastPopupStore.initDefault(
                'alert',
                t('CANCEL_EDIT_NICKNAME_POPUP'),
                env.modalOkButtonText,
                env.modalCanclButtonText,
                e=>{if(modalToastPopupStore.isCancel) onCloseHandler()}
            );
            modalToastPopupStore.openModalPopup();
        }else{
            onCloseHandler();
        }
    }

    const onOpenChangePopup = () => {
        modalToastPopupStore.initGuide(
            'guide',
            t('CONFIRM_CHANGE_NICKNAME_POPUP'),
            t('CONFIRM_CHANGE_NICKNAME_POPUP_GUIDE'),
            '',
            env.modalOkButtonText,
            env.modalCanclButtonText,
            onSubmitHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    useLayoutEffect(()=>{
        if(data.isOpen) {
            setInputNickname(userStore.user.nickName);
            setChangeable(null);
            setReason(null);
            setIsEditing(false);
            if(inputRef.current) inputRef.current.focus();
        }
    },[data.isOpen]);

    return (
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalTitle>{t('EDIT_NICKNAME_MODAL_TITLE')}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'}
                                height={'32px'}
                                onClick={e=>onOpenClosePopup()}
                                src={CancelIcon}/>
                </ModalCloseWrap>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollBox>
                <EditNicknameModalBody>
                    <ModalSubTitle>{t('EDIT_NICKNAME_MODAL_SUBTITLE')}</ModalSubTitle>
                    <EditNicknameModalGuides>
                        <EditNicknameModalGuide key={`guide-1`}>{t('EDIT_NICKNAME_MODAL_GUIDE_1')}</EditNicknameModalGuide>
                        <EditNicknameModalGuide key={`guide-2`}>{t('EDIT_NICKNAME_MODAL_GUIDE_2')}</EditNicknameModalGuide>
                    </EditNicknameModalGuides>
                </EditNicknameModalBody>

                <EditNicknameInputContainer>
                    <EditNicknameInputWrap className={isFocus ? 'focus' : ''}>
                        <EditNicknameInput onChange={changeInputNickname}
                                           placeholder={t('EDIT_NICKNAME_MODAL_PLACEHOLDER')}
                                           value={inputNickname}
                                           maxLength={env.nicknameMaxLen}
                                           onFocus={e=>setIsFocus(true)}
                                           onBlur={e=>setIsFocus(false)}
                                           ref={inputRef}

                        />
                        <EditNicknameDeleteWrap
                            className={inputNickname.length !== 0 && isFocus ? '' : 'on'}
                            onMouseDown={e=>e.preventDefault()}
                        >
                            <IconButton
                                imgStyle={{width:'20px', height: '20px'}}
                                src={DeleteAllIcon}
                                onClick={e=>onResetInputNickname(e)}
                            />
                        </EditNicknameDeleteWrap>
                        <EditNicknameCountWrap>
                            <EditNicknameCount>{inputNickname.length}</EditNicknameCount><EditNicknameLen>/10</EditNicknameLen>
                        </EditNicknameCountWrap>
                    </EditNicknameInputWrap>
                    <Button
                        type={'mobile'}
                        width={'90px'}
                        height={'40px'}
                        textStyle={{fontSize : '14px', lineHeight: '20px' }}
                        wrapperStyle={{padding  : '5px 18px',  borderRadius : '12px'}}
                        onClick={checkNickname}
                        onMouseDown={e=>e.preventDefault()}
                        disabled={inputNickname?.length === 0 || !isEditing}
                    >{t('EXIST_CHECK')}</Button>
                </EditNicknameInputContainer>

                <EditNicknameValidResult
                    className={changeable === true ? 'valid' : changeable === false ? 'invalid' : null}>
                    {changeable === true ? t('CAN_CHANGE_NICKNAME') : null}
                    {changeable === false ? reason : null}
                </EditNicknameValidResult>
            </ModalScrollBox>

            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button width={'100%'}
                        disabled={!changeable}
                        textStyle={{fontSize : '16px', lineHeight: "23px"}}
                        onClick={e=>onOpenChangePopup()}>{t('SAVE')}</Button>
            </ModalBottomWrap>

        </ModalContainer>
    )
}

EditNicknameModal.defaultProps = {
    onClose: null,
}

export default EditNicknameModal;