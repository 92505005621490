import {
    ModalBottomWrap,
    ModalCloseWrap,
    ModalContainer,
    ModalHeaderWrap,
    ModalScrollBox,
    ModalTitle
} from "../global/ModalStyles";
import {Button, IconButton} from "../global";
import CancelIcon from "../../../resources/icons/modal_cancel.svg";

import {useEffect, useLayoutEffect, useState} from "react";
import isEmpty from "is-empty";
import {Language, Languages, LanguageText} from "./LanguageModalStyles";
import {useStores} from "../../../stores";
import env from "../../../constants/env";
import {useTranslation} from "react-i18next";
import {getLanguageIcon} from "../../../utils/languageHandler";
import {receiveResponse} from "../../../utils/api";

const LanguageModal = (props) => {

    const modalPopupStore = props.modalPopupStore;
    const goHistoryBack = props.goHistoryBack;
    const data = modalPopupStore;
    const {store} = useStores();
    const {userStore, toastPopupStore} = store;

    const {i18n,t} = useTranslation();

    const [selected, setSelected] = useState(userStore?.user?.locale);

    // 모달 창 닫기
    const onCloseHandler = (e) => {
        if(goHistoryBack && typeof goHistoryBack === 'function') {
            goHistoryBack();
        }
    }

    // 언어 설정
    const onSubmitHandler = async () => {
        /*@todo 언어 설정 api가 들어가야 함 */
        await receiveResponse({},`user/change-locale?language=${selected.toLowerCase()}`,'patch')
            .then(res=>{
                if(res.status===200){
                    i18n.changeLanguage(selected.toLowerCase());
                    // userStore user 초기화
                    userStore.updateUser();
                    // @todo toastPopup 추가
                    modalPopupStore.closeModalPopup();
                    toastPopupStore.openToastPopup(t('LANGUAGE_CHANGE_COMPLETE',{ language : t(selected)}))
                }
            })
            .catch(e=>{
                // @todo toastPopup 추가 (실패한 경우)
            })
    }

    // 언어 리스트 생성
    const createLanguages = () => {
        return env.LOCALE_LIST.map(locale => (
            <Language key={`language-${locale}`} className={selected===locale ? 'active' : ''} onClick={e=>setSelected(locale)}>
                <IconButton src={getLanguageIcon(locale.toUpperCase())} />
                <LanguageText>{t(locale)}</LanguageText>
            </Language>
        ))
    }

    useEffect(()=>{
        // userStore에서 locale 설정
        if(!isEmpty(userStore.user.locale)) setSelected(userStore.user.locale.toUpperCase());
    },[userStore.user])

    useLayoutEffect(()=>{
        if(!isEmpty(userStore.user.locale)) setSelected(userStore.user.locale.toUpperCase());
    },[data.isOpen]);

    return(
        <ModalContainer className={!data.isOpen ? 'hidden' : null}>

            {/* HEAD 컴포넌트 */}
            <ModalHeaderWrap>
                <ModalTitle>{t('LANGUAGE_SETTING')}</ModalTitle>
                <ModalCloseWrap>
                    <IconButton width={'32px'}
                                height={'32px'}
                                src={CancelIcon}
                                onClick={onCloseHandler}
                    />
                </ModalCloseWrap>
            </ModalHeaderWrap>

            {/* 내용 컴포넌트 */}
            <ModalScrollBox>
                <Languages>
                    {createLanguages()}
                </Languages>
            </ModalScrollBox>

            {/* BOTTOM 컴포넌트 */}
            <ModalBottomWrap>
                <Button
                    shape={'circle'}
                    width={'100%'}
                    textStyle={{fontSize : '16px', lineHeight: "23px"}}
                    onClick={onSubmitHandler}
                    disabled={userStore.user.locale.toUpperCase() === selected}
                >설정하기</Button>
            </ModalBottomWrap>

        </ModalContainer>
    )
}

LanguageModal.defaultProps = {

}

export default LanguageModal