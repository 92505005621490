import styled from "styled-components";

export const ProfileGroupWrap = styled.div`
    display : flex;
    gap : 5px;
    align-items : center;
`

export const ProfileGroupInfoWrap = styled.div`
    background: #FFFFFF;
    border: 1px solid #F1F5F9;
    border-radius: 10px;
    padding: 12px 20px;
    display : flex;
    justify-content: space-between;
    flex-grow : 1;
    
    &.closing {
        background: #FFF4F4;
    }
`

export const ProfileGroupInfoNameActive = styled.div`
    display: flex;
    flex-direction: column;
`

export const ProfileGroupInfo = styled.div`
    flex-grow : 1;
    display : flex;
    margin-bottom: 6px;
`

export const ProfileGroupName = styled.div`
    width: 120px;
    padding-left: 6px;
    display : flex;
    gap : 5px;
`

export const ProfileGroupNameText = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
`

export const ProfileGroupActivity = styled.div`
    font-weight: 500; 
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    
    &.closing {
        color : #FF7171;
    }
`

export const ProfileGroupQuit = styled.div`
    align-items: center;
    display: flex;
    width: 33px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #FF7171;
`

export const ProfileGroupButtons = styled.div`
    display : flex;
    gap : 5px;
    align-items: center;
    flex : none;
`