
/*이벤트 */

/********************************************** WEB ----> APP (호출)  **********************************************/

// 앱 파일첨부 뷰어
export const appWebViewFileViewEvent = (isAppReady, fid, filePath) => {

    // fid = `${image?.fileId}`;
    // filePath = `${ServerConfig.default.FILE_URL}`${image?.fileId}`;
    if (isAppReady) {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('webToAppFileView', 'hasExt=false&fid=' + `${fid}` + '&filePath=' + `${filePath}`).then(() => {
                console.log('webToAppFileView');

            });
        }
    }
}

// 앱 설정 페이지 이동
export const appWebViewSettingEvent = (isAppReady) => {
    if (isAppReady) {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('webToAppSetting', 'openSetting').then(() => {
                console.log('webToAppSetting');
            });
        }
    }
}

// 앱 푸시알림 설정
export const appWebViewPushEvent = (isAppReady, isPush) => {
    if (isAppReady) {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('webToAppPush', 'isPush=' + `${isPush}`).then(() => {
                console.log('webToAppPush');
            });
        }
    }
}

// 로그인 페이지 이동
export const appWebViewBackEvent = (isAppReady) => {
    if (isAppReady) {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('webToAppBack', 'goBack').then(() => {
                console.log('webToAppBack');
            });
        }
    }
}


// 로그아웃 성공 ( 앱 닫기 )
export const appWebViewLogout = (isAppReady, isSuccess) => {
    if (isAppReady) {
        if (window.flutter_inappwebview) {

            if (isSuccess) {
                window.flutter_inappwebview.callHandler('webToAppLogout', 'success').then(() => {
                    console.log('webToAppLogout success');
                });
            }else {
                window.flutter_inappwebview.callHandler('webToAppLogout', 'fail').then(() => {
                    console.log('webToAppLogout fail');
                });
            }
        }
    }
}

// 인증 성공 ( 링크 이동 )
export const appWebViewAuth = (isSuccess) => {
    if (window.flutter_inappwebview) {
        if (isSuccess) {
            window.flutter_inappwebview.callHandler('webToAppAuth', 'success').then(() => {
                console.log('webToAppLogout success');
            });
        }else {
            window.flutter_inappwebview.callHandler('webToAppAuth', 'fail').then(() => {
                console.log('webToAppLogout fail');
            });
        }
    }
}

// 2023.12.18
// 모바일 경조사 페이지 이벤트 호출 (캡처 가능하게)
export const appWebViewCapturePage = (isSuccess) => {
    if (window.flutter_inappwebview) {
        if (isSuccess) {
            window.flutter_inappwebview.callHandler('webToAppCapturePage', true).then(() => {
                console.log('webToAppCapturePage true');
            });
        }else {
            window.flutter_inappwebview.callHandler('webToAppCapturePage', false).then(() => {
                console.log('webToAppCapturePage false');
            });
        }
    }
}

/********************************************** APP----> WEB(호출)  **********************************************/

// 모바일 Token 발급
export const webViewAppFirebaseEvent = (callBack) => {
    // App디바이스Id받기
    // 자바스크립트에서 메시지 받기
    window.setFcmToken = (token) => {
        callBack(token);
    }
}

// 로그아웃 버튼 클릭 확인
export const webViewAppLogOutEvent = (callBack) => {
    // 앱에서 로그아웃 버튼 클릭했다는 내용
    // 자바스크립트에서 메시지 받기
    window.getIsLogout = (isLogout) => {
        callBack(isLogout);
    }
}


/********************************************** 경조사 페이지 (navtive) **********************************************/


//
export const webViewAppNativeEvent = (fid, filePath, fileName) => {

    const os = getOS();

    //안드로이드
    if (os === 'Android') {
        window.MACALONGJS.callAttachView('hasExt=false&fid=' + `${fid}` + '&filePath=' + `${filePath}` + '&fileName=' + `${fileName}`);
    } else if (os === 'IOS') {  //아이폰
        window.webkit.messageHandlers.MACALONGJS.postMessage('hasExt=false&fid=' + `${fid}` + '&filePath=' + `${filePath}` + '&fileName=' + `${fileName}`);
    }

}

/********************************************** OS 판별 **********************************************/


export const getOS = () => {

    const isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i) == null ? false : true;
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i) == null ? false : true;
        },
        IOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i) == null ? false : true;
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i) == null ? false : true;
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.IOS() || isMobile.Opera() || isMobile.Windows());
        }
    }

    if(isMobile.any()){
        if(isMobile.Android()){
            return 'Android'
        }else if(isMobile.IOS()){
            return 'IOS'
        }else if(isMobile.BlackBerry()){
            return 'BlackBerry'
        }else if(isMobile.Opera()){
            return 'Opera'
        }else if(isMobile.Windows()){
            return 'Windows'
        }
    }

    // /* 앱에서 왔을 경우 */
    // if (navigator.userAgent.includes("macalonmobile")) {
    //     return "InApp"
    // }

    return 'WEB'
}


/********************************************** 쿼리 Search 주소 **********************************************/

export const  getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};




