import styled from "styled-components";

export const ApplyingUserWrap = styled.div`
    width : 100%;
`

export const ApplyingUserContentWrap = styled.div`
    padding: 16px 10px 15px 10px;
    display : flex;
    flex-direction: column;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #F1F5F9;
    border-radius: 20px;
`

export const ApplyingUserContent = styled.div`
    padding: 12px 0px 20px 4px;
    flex: none;
    flex-grow: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #AEB7C0;
`

export const ApplyingUserButtons = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    gap : 5px;
`