import {useStores} from "../../../stores";
import {observer} from "mobx-react";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    ProfileItemsButtons,
    ProfileItemsContainer,
    ProfileItemsDropWrap,
    ProfileItemsList,
    ProfileItemsNav,
    ProfileItemsNoResultWrap,
    ProfileItemsPreBtn,
    ProfileItemsPreBtnImg,
    ProfileItemsTitle,
    ProfileNoResultWrap,
    SearchWrap
} from "./ProfileItemsStyles";
import {Button, DropDown, NoResult} from "../global";
import CommunicateIcon from "../../../resources/icons/profile_setting_communicate.svg";
import GroupIcon from "../../../resources/icons/profile_setting_group.svg";
import React, {useEffect, useRef, useState} from "react";
import {ThreeDots} from "react-loader-spinner";
import {receiveResponse} from "../../../utils/api";
import ActivityItem from "../global/ActivityItem";
import env from "../../../constants/env";
import NoPostImg from "../../../resources/icons/no_post.svg";
import routes from "../../../constants/routes.json";
import {getDDayFromDate} from "../../../utils/dateHandler";
import SearchPrevBtn from "../../../resources/mImages/array_prev.svg";

const ProfileBookmark = (props) => {

    const {store} = useStores();
    const {userStore, modalPopupStore, modalDetailStore, eventStore, toastPopupStore, modalToastPopupStore} = store;
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();
    const e = eventStore.e;

    // 무한 스크롤 중복 방지
    const preventRef = useRef(null);
    // 무한 스크롤 옵저버 요소
    const obsRef = useRef(null);
    // 마지막 페이지 등록 여부
    const endRef = useRef(null);

    const visibleRef = useRef(true);

    const {title} = props;

    const [itemsState, setItemsState] = useState({activeMenu : 0, activeGroup : 0, page : 0})
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [items, setItems] = useState(null);
    const [isVisible, setIsVisible] = useState(true);

    const menus = [
        {type : 'F', name : t('COMMUNICATE'), icon : CommunicateIcon},
        {type : 'G', name : t('GROUP'), icon: GroupIcon}
    ];

    const dropdownGroups = [
        {id : 'all', name : 'all', nameText : '전체 그룹'},
        ...((userStore.joinGroups || []).map(group=>({id : group.groupId, name : group.groupName, nameText : group.groupName})))
    ]

    /******************************************************************************************************************/
    /**
     * 컴포넌트
     * @param e
     */

    // 소통해요, 모여봐요 버튼 생성
    const createMenus = () => {
        return menus.map((menu,idx)=>(
            <Button
                shape={'circle'}
                wrapperStyle={{alignItems : 'center', padding: '5px 22px 4px 21px'}}
                width={'fit-content'}
                height={'28px'}
                textStyle={{lineHeight:'17px', display : 'flex', gap : '6px'}}
                type={idx === itemsState.activeMenu ? 'primary' : 'secondary'}
                onClick={e=>onClickMenu(idx)}
            >{menu.name}</Button>
        ))
    }

    // item list 생성
    const createItems = () => {
        return items?.map((item, i)=>(
            <ActivityItem
                item={item}
                type={'bookmark'}
                title={item.title}
                content={item.content}
                regDate={item.regDate}
                groupName={item.group?.groupName}
                imgId={item.group?.imgId}
                page={menus[itemsState.activeMenu].type}
                key={`activity-bookmark-${item.itemId}`}
                onClick={e=>onClickItem(item)}
                onClickGroup={item.group ? e=>onClickGroup(item.group) : null}
                onClickBookmark={e=>unBookmark(item.itemId)}
            />
        ))
    }

    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */

    // 뒤로가기 이벤트
    const onBackHistoryClickEvent = (e) => {
        e.preventDefault();
        history.goBack();
    }

    // 버튼 누르는 경우
    const onClickMenu = (idx) => {
        //로딩아닐경우만
        if (!isLoading) {
            if (itemsState.activeMenu !== idx) {
                setIsLoading(true);
                preventRef.current = false;
                setItemsState((itemsState)=>{
                    return {
                        ...itemsState,
                        page: 0,
                        activeMenu : idx,
                        activeGroup : 0
                    }
                });
            }
        }
    }

    // group 영억 클릭 시
    const onClickGroup = (group) => {
        history.push(`${routes.GROUPS}/${group.groupId}`);
    }

    // 게시글 클릭 시, 팝업 띄우기
    const openAlertPopup = (title) => {
        modalToastPopupStore.initGuide(
            'guide-alert',
            title,
            '',
            null,
            env.modalConfirmButtonText,
            env.modalCanclButtonText,
            ()=>{}
        );
        modalToastPopupStore.openModalPopup();
    }

    // 게시글 영역 클릭 시
    const onClickItem = (item) => {
        if(item.group){
            const _group = item.group;
            // 폐쇄된 경우
            if(getDDayFromDate(_group.closingDate) <= 0) openAlertPopup(t('MY_PROFILE_CLOSE_ALERT'));
            // 강퇴된 경우
            else if(_group.kickDate) openAlertPopup(t('MY_PROFILE_KICK_ALERT'));
            // 탈퇴한 경우 (가입한 리스트에 없을 경우)
            else if(userStore.joinGroups.findIndex(group=>group.groupId===_group.groupId)===-1) openAlertPopup(t('MY_PROFILE_QUIT_ALERT'));
            else{
                // 그렇지 않은 경우에는 글 상세 모달 오픈
                openItemDetailModal(item.itemId);
            }
        }else{
            openItemDetailModal(item.itemId);
        }
    }

    // 모여봐요 > dropdown 선택
    const onClickDropdown = (index) => {
        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            preventRef.current = false;
            setItemsState({
                ...itemsState,
                page: 0,
                activeGroup: index
            })
        }
    }

    // item detail modal
    const openItemDetailModal = (itemId) => {
        modalDetailStore.init(
            menus[itemsState.activeMenu].type === 'F' ? 'c' : 'g',             //'c', 'g' 타입
            itemId           // 아이템Id
        );
    }


    /******************************************************************************************************************/
    /**
     * 함수
     * @param e
     */


    // 이벤트 변경 되었을 때, 게시글 다시 불러오기 위한 초기화 함수
    const initData = () => {
        preventRef.current = false;
        endRef.current = false;
        setItems(null);
        setIsLoading(true);
        setItemsState(itemsState=>{
            return {...itemsState, page: 0}
        })
    }

    // <이벤트> 이벤트 리스너 등록 (게시글 삭제 이벤트)
    const createEventListener = () => {
        e.removeAllListeners('delete',()=>{});
        e.removeAllListeners('update-profile-bookmark',()=>{})
        // card 삭제 시, 게시글 업데이트
        e.on('delete', (args)=>{
            initData();
        });
        e.on('update-profile-bookmark',(args)=>{
            initData()
        })

    }

    // 소통해요 내 글 가져오기
    const getCommunicateItems = async (itemsState) => {

        const page = itemsState.page;
        const type = menus[itemsState.activeMenu].type;

        let param = {
            size : env.PROFILE_ITEM_PAGE_SIZE,
            target: "bookmark",
            page,type
        }

        if (type==='G') {
            if(dropdownGroups[itemsState.activeGroup].id !== 'all')
                param = {...param, groupId : dropdownGroups[itemsState.activeGroup].id}
        }

        await receiveResponse(param, 'items', 'get')
            .then(res=>{
                if(res.status===200){
                    if(page===0){
                        setItems([...res.data.list]);
                    }else{
                        setItems([...items].concat(res.data.list));
                    }
                    endRef.current = res.data.end;
                    preventRef.current = true;
                }
            })
            .catch(e=>{
                setError(true);
                console.log(e);
            })
            .finally(()=>{
                setIsLoading(false);
            })
    }

    // <게시글> 북마크 해제
    const unBookmark = (itemId) => {
        receiveResponse({},`items/${itemId}/unbookmark`,'patch').then((res)=>{
            if(res.status===200){
                // 내 활동 > 내 그룹에서 받을 수 있는 이벤트 emit
                toastPopupStore.openToastPopup(t('UN_BOOKMARK_ITEM_COMPLETE'));
                if(matchPath(location.pathname,`${routes.PROFILE}/bookmark`)) e.emit('update-profile-bookmark',()=>{})
            }
        })
    }

    // Observer 핸들러 함수 (무한스크롤을 위한 함수)
    const obsHandler = ((entries) => {

        const target = entries[0];

        setIsVisible(target.isIntersecting);
        visibleRef.current = target.isIntersecting;

        if(target.isIntersecting && preventRef.current){

            if(endRef.current) return;
            preventRef.current = false;
            setItemsState((itemsState)=>{
                return {
                    ...itemsState,
                    page : itemsState.page + 1
                }
            });

        }
    });

    /******************************************************************************************************************/
    /**
     * useEffect
     * @param e
     */

    // obs 핸들러 등록
    useEffect(()=>{
        createEventListener();
        const observer = new IntersectionObserver(obsHandler, {
            threshold : 0
        });
        // observer에 관찰 요소 등록
        if(obsRef.current) observer.observe(obsRef.current);
        return ()=>{
            observer.disconnect();
            e.removeAllListeners('delete',()=>{});
            e.removeAllListeners('update-profile-bookmark',()=>{});
        }
    },[]);

    useEffect(()=>{
        // 최초에는 여기서 불러옴
        getCommunicateItems(itemsState);
    },[itemsState]);

    useEffect(()=>{
        if(obsRef && obsRef.current && !endRef.current && preventRef.current && items && items?.length > 0){
            if(window.innerHeight > obsRef.current.getBoundingClientRect().y){
                setItemsState((itemsState) => {
                    return {
                        ...itemsState,
                        page: itemsState.page + 1
                    }
                });
            }
        }
    },[items])

    return(

        <>
            {/*헤더*/}
            <SearchWrap>
                <ProfileItemsPreBtn onClick={(e)=>{onBackHistoryClickEvent(e)}}>
                    <ProfileItemsPreBtnImg src={SearchPrevBtn}></ProfileItemsPreBtnImg>
                </ProfileItemsPreBtn>
                {/* title 부분 */}
                <ProfileItemsTitle>{title}</ProfileItemsTitle>
            </SearchWrap>

            <ProfileItemsContainer>

                {/* topic */}
                <ProfileItemsNav>
                    <ProfileItemsButtons>
                        {createMenus()}
                    </ProfileItemsButtons>
                </ProfileItemsNav>

                {
                    // 로딩중이면 로딩바 표시
                    isLoading ?
                        //로딩바
                        <ThreeDots
                            height={"10"}
                            wrapperStyle={{
                                width : '100%',
                                justifyContent : 'center',
                                height : '100%',
                                paddingTop : '30px'}}
                            color={'#3A6AF6'}
                        /> :
                        items ?
                            items.length > 0 ?
                                <ProfileItemsList>
                                    { menus[itemsState.activeMenu].type === 'G' ?
                                        <ProfileItemsDropWrap>
                                            <DropDown
                                                items={dropdownGroups}
                                                selectedIndex={itemsState?.activeGroup}
                                                onSelect={onClickDropdown}
                                            />
                                        </ProfileItemsDropWrap> : null
                                    }
                                    {createItems()}
                                    {/*스크롤 감지*/}
                                </ProfileItemsList> :
                                <ProfileItemsNoResultWrap>
                                    { menus[itemsState.activeMenu].type === 'G' ?
                                        <ProfileItemsDropWrap>
                                            <DropDown
                                                items={dropdownGroups}
                                                selectedIndex={itemsState?.activeGroup}
                                                onSelect={onClickDropdown}
                                            />
                                        </ProfileItemsDropWrap> : null
                                    }
                                    <ProfileNoResultWrap>
                                        <NoResult src={NoPostImg} title={'등록된 즐겨찾는 글이 없어요.'}/>
                                    </ProfileNoResultWrap>
                                </ProfileItemsNoResultWrap> : null

                }
                <div ref={obsRef} style={{background: '#f7f9fb', padding : '10px'}}></div>
            </ProfileItemsContainer>
        </>
    )
}

ProfileBookmark.defaultProps = {

}

export default observer(ProfileBookmark);