import styled from "styled-components";

export const GroupsCardStyle = styled.li`    
      &:nth-child(4n) {
         margin-right: 0px !important;
      }
`

export const GroupsCardWrap = styled.div`
      position: relative; 
      display: block; 
      padding: 11px 40px 11px 20px; 
      height: 108px;
`

export const GroupsCardNumber = styled.div`
      position: absolute;
      top: 9px; 
      left: 33px;
`

export const GroupsCardNumberSpan = styled.span`
      font-weight: 700;
      font-size: 13px;
      line-height: 21px;
      color: #FFFFFF;
      position: relative;
      display: block;
      text-align: center;
`

export const GroupsCardNumberImg = styled.img`
        width: 22px; 
        height: 31px; 
        display: block; 
        padding-top: 2px; 
        font-weight: 700; 
        font-size: 13px; 
        line-height: 21px; 
        text-align: center; 
        color: #ffffff; 
        position: absolute; 
        top: 7px; 
        left: 26px;
`

export const GroupsCardImg = styled.img`
        width: 86px; 
        height: 86px; 
        background-size: cover; 
        background-color: #D9D9D9; 
        border-radius: 12px; 
        float: left;
`

export const GroupsCardInterest = styled.div`
      position: absolute;
      top: 72px;
      left: 24px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 22px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 3px 7px;
      gap: 5px;
`

export const GroupsCardInterestSpan = styled.span`
    color: #3A6AF6;
    padding-right: 9px;
   
`

export const GroupsCardDl = styled.dl`
    padding-left: 102px;
`

export const GroupsCardDt = styled.div`
    display: flex;
    width: 100%;
    padding: 1px 0px 2px 0px;
`

export const GroupsCardText = styled.span`
    font-weight: 700; 
    font-size: 16px; 
    line-height: 23px; 
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: nowrap;
`

export const GroupsCardDtLockImg = styled.img`
    width: 25px;
    height: 25px;
`


export const GroupsCardDd = styled.dd`
`

export const GroupsCardDdSpanWrap = styled.div`
    display: flex;
`

export const GroupsCardSpan = styled.span`
    font-weight: 500; 
    font-size: 15px; 
    line-height: 22px; 
    color: #75818D; 
    display: block; 
    padding: 1px 0px 0px 2px;
`

export const GroupsCardSpanImg = styled.img`
`

export const GroupsCardP = styled.p`
    font-weight: 400; 
    font-size: 12px; 
    line-height: 19px; 
    color: #191E29; 
    display: block; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    word-break: break-word;
    height:38px;
`

export const GroupsCardSelectBottom = styled.span`
    font-weight: 500; 
    font-size: 12px; 
    line-height: 17px; 
    color: #75818D; 
    display: block; 
    margin-top: 4px; 
    position: relative;
`

export const GroupsCardSelectBottomStrong = styled.strong`
    padding-right: 2px;
    font-weight: 700; 
    color: #191E29;
`
