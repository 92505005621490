import {
    SettingUserCompany,
    SettingUserImage,
    SettingUserImageWrap,
    SettingUserInfoWrap,
    SettingUserMenu,
    SettingUserMenuButton,
    SettingUserMenuWrap,
    SettingUserName,
    SettingUserWrap
} from "./SettingUserStyles";
import {IconButton, List} from "./index";
import MoreIcon from "../../../resources/icons/more.svg";
import MasterIcon from '../../../resources/mImages/icon_manager.png';
import AssistantIcon from "../../../resources/mImages/icon_deputy.png";
import {useState} from "react";
import {useStores} from "../../../stores";
import ProfileIcon from "../../../resources/icons/profile.png";
import {ServerConfig} from "../../../commons/config";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {receiveResponse} from "../../../utils/api";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";

const SettingUser = (props) => {

    const {store} = useStores();
    const {toastPopupStore, modalToastPopupStore, modalPopupStore, defaultImageStore} = store;
    const {user, groupId, onAppoint, onDismiss, onKick, onHandOver, isManager, isAssistant} = props;
    const {t} = useTranslation();

    const [openMenu, setOpenMenu] = useState(false);

    // 그룹 부관리자 메뉴
    const assistantMenuItems = [
        {id:0, nameText:t('DISMISS_ASSISTANT'), onClick : (e)=> {openDismissModal()}},
        {id:1, nameText:t('HAND_OVER'), onClick : (e)=> {openHandOverModal()}},
        {id:2, nameText:t('KICK'), onClick : (e)=> {openKickModal()}}
    ];

    // 일반 멤버 메뉴
    const memberMenuItems = [
        {id:0, nameText:t('APPOINT_ASSISTANT'), onClick : (e)=> {openAppointModal()}},
        {id:1, nameText:t('HAND_OVER'), onClick : (e)=> {openHandOverModal()}},
        {id:2, nameText:t('KICK'), onClick : (e)=> {openKickModal()}}
    ]

    const openSettingMenu = () => {
        setOpenMenu(!openMenu);
    }

    // 리스트 blur callback
    const onBlurMenu = (e) => {
        setOpenMenu(false);
    }

    // 그룹 관리자로 변경 modal 띄우기
    const openAppointModal = () => {
        modalToastPopupStore.initGuide(
            'guide',
            t('CONFIRM_APPOINT_ASSISTANT'),
            t('CONFIRM_APPOINT_ASSISTANT_GUIDE'),
            '',
            t('YES'),
            t('NO'),
            onAppointAssistantHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 일반 멤버로 변경 modal 띄우기
    const openDismissModal = () => {
        modalToastPopupStore.initDefault(
            'alert',
            t('CONFIRM_DISMISS_ASSISTANT'),
            t('YES'),
            t('NO'),
            onDismissAssistantHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 그룹장 위임 modal 띄우기
    const openHandOverModal = () => {
        modalToastPopupStore.initGuide(
            'guide',
            t('CONFIRM_HAND_OVER'),
            t('CONFIRM_HAND_OVER_GUIDE'),
            '',
            t('YES'),
            t('NO'),
            onHandOverHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 강제 탈퇴 modal 띄우기
    const openKickModal = () => {
        modalToastPopupStore.initGuide(
            'guide',
            t('CONFIRM_KICK'),
            t('CONFIRM_KICK_GUIDE'),
            '',
            t('YES'),
            t('NO'),
            onKickHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    /**
     * ======================================================================
     * API
     */
        // 부관리자 일반 멤버로 변경
    const onDismissAssistantHandler = async () => {
            if(!modalToastPopupStore.isCancel) return;
            const userId = user?.userId
            await receiveResponse(
                {userId},
                `groups/${groupId}/dismiss-assistant`,
                'patch',
                {'Content-Type': 'multipart/form-data'} )
                .then(res=>{
                    if(res.status===200){
                        if(res.data){
                            // @todo 해임 이후 다시 불러와야 함
                            toastPopupStore.openToastPopup(t('COMPLETE_DISMISS_ASSISTANT', {nickname : user?.nickName}));
                            if(onDismiss && typeof onDismiss === "function") onDismiss();
                        }
                    }
                })
        }

    // 일반 멤버를 부관리자로
    const onAppointAssistantHandler = async () => {
        if(!modalToastPopupStore.isCancel) return;
        const userId = user?.userId
        await receiveResponse(
            {userId},
            `groups/${groupId}/appoint-assistant`,
            'patch',
            {'Content-Type': 'multipart/form-data'} )
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        // @todo 임명 이후 다시 불러와야 함
                        toastPopupStore.openToastPopup(t('COMPLETE_APPOINT_ASSISTANT', {nickname : user?.nickName}));
                        if(onAppoint && typeof onAppoint === "function") onAppoint();
                    }
                }
            })
    }

    // 그룹장 위임
    const onHandOverHandler = async () => {
        if(!modalToastPopupStore.isCancel) return;
        const userId = user?.userId
        await receiveResponse(
            {userId},
            `groups/${groupId}/hand-over`,
            'patch',
            {'Content-Type': 'multipart/form-data'} )
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        // @todo 그룹장 위임 이후 다시 불러와야 함
                        toastPopupStore.openToastPopup(t('COMPLETE_HANDOVER_MANAGER', {nickname : user?.nickName}));
                        if(onHandOver && typeof onHandOver === "function") onHandOver();
                    }
                }
            })
    }

    // 강제 탈퇴
    const onKickHandler = async () => {
        if(!modalToastPopupStore.isCancel) return;
        const userId = user?.userId
        await receiveResponse(
            {userId},
            `groups/${groupId}/kick?preventRejoin=true`,
            'patch',
            {'Content-Type': 'multipart/form-data'} )
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        // @todo 강퇴 이후 다시 불러와야 함
                        toastPopupStore.openToastPopup(t('COMPLETE_KICK_MEMBER', {nickname : user?.nickName}));
                        if(onKick && typeof onKick === "function") onKick();
                    }
                }
            })
    }

    //프로필 상세 이동
    const onClickHandler = (e) => {
        modalPopupStore.initAdd(
                'vp',null,{userId : user.userId},()=>{},()=>{});
    };

    /**
     * API END
     * ======================================================================
     */

    return(
        <SettingUserWrap>
            <SettingUserImageWrap onClick={onClickHandler}>
                {
                    defaultImageStore.defaultUserImgs ?
                        <SettingUserImage
                            src={
                                user.imgId ?
                                    defaultImageStore.defaultUserImgs.findIndex(img=>img === user?.imgId)!==-1 ?
                                        `${ServerConfig.default.IMG_URL}${user?.imgId}` :
                                        `${ServerConfig.default.IMG_THUMBNAIL_URL}${user?.imgId}`
                                    : ProfileIcon
                            }
                            onError={(e)=>{e.target.onerror = null; e.target.src= ProfileIcon}}
                        /> : null
                }
            </SettingUserImageWrap>
            <SettingUserInfoWrap>
                <SettingUserName>
                    { ( getBoolFromYn(user?.managerYn) || getBoolFromYn(user?.assistantYn) ) ?
                        <IconButton
                            width={'20px'}
                            height={'20px'}
                            src={
                                getBoolFromYn(user?.managerYn) ? MasterIcon
                                    : getBoolFromYn(user?.assistantYn) ? AssistantIcon
                                        : null
                            }
                        /> : null
                    }
                    {user?.nickName}
                </SettingUserName>
                <SettingUserCompany>{user?.companyName}</SettingUserCompany>
            </SettingUserInfoWrap>
            {   //관리자이면서 자기가 아닐경우
                (isManager) && !getBoolFromYn(user?.managerYn) ?
                    <SettingUserMenuWrap>
                        <SettingUserMenuButton>
                            <IconButton src={MoreIcon} width={'24px'} height={'24px'} onClick={openSettingMenu}/>
                        </SettingUserMenuButton>
                        {
                            openMenu ?
                                <SettingUserMenu>
                                    <List
                                        items={
                                            getBoolFromYn(user?.assistantYn) ?
                                                assistantMenuItems : memberMenuItems
                                        }
                                        onBlur={onBlurMenu}
                                        type={'thin'}
                                    />
                                </SettingUserMenu> : null
                        }
                    </SettingUserMenuWrap> : null
            }
        </SettingUserWrap>
    )
}

SettingUser.defaultProps = {
    groupId : '',
    user : null,
    onAppoint : null,
    onDismiss : null,
    onKick : null,
    onHandOver : null
}

export default observer(SettingUser);