import styled from "styled-components";


export const ProfileSettingContainerWrap = styled.div`
    width: 100%;
`


export const ProfileSettingContainer = styled.div`
    padding: 30px 0px 0px 0px;
    background: #F7F9FB;
`

export const ProfileSettingTitleWrap = styled.div`
    display : flex;
`

export const ProfileSettingTitle = styled.div`
    flex-grow : 1;
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    color: #191E29;
`

export const ProfileLanguageWrap = styled.div`
    display : flex;
    flex : none;
    gap : 5px;
    align-items : center;
`

export const ProfileLanguage = styled.div`
    font-size: 17px;
    line-height: 18px;
    color: #191E29;
`

export const ProfileSettingUserInfoWrap = styled.div`
    display : flex;
    align-items : center;
    flex-direction : column;
    padding-bottom : 20px;
`


export const ProfileSettingImageWrap = styled.div`
    position : relative;
    width : 80px;
    height : 80px;
`

export const ProfileSettingImage = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 100%;
    height : 100%;
    border-radius : 50%;
`

export const ProfileSettingImageEditIcon = styled.img.attrs(props => ({
    src: props.src
}))`
    width: 31px;
    height: 31px;
    position: absolute;
    bottom: -2px;
    right: -2px;
    background: #FFFFFF;
    border: 2px solid #EAEAEA;
    border-radius : 50%;
`

export const ProfileSettingUserInfo = styled.div`
    margin-top : 10px;
    display : flex;
    flex-direction : column;
    align-items : center;
`

export const ProfileSettingNicknameWrap = styled.div`
    display : flex; 
    gap : 2px;
    justify-content : center;
`

export const ProfileSettingNickname = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    display : flex;
    gap : 2px;
`

export const ProfileSettingCompany = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #AEB7C0;
`

export const ProfileSettingStateWrap = styled.ul`
    background: #FFFFFF;
    border: 1px solid #F1F5F9;
    border-radius: 12px;
    margin: 12px 30px 0px;
    width: calc(100% - 60px);
`

export const ProfileSettingState = styled.li`
    display: inline-block;
    text-align: center;
    width: calc( 33.33333%  );
    margin: 23px 0px 25px 0px;
    border-right: 1px solid #E5EBF0;
    
    &:last-child {
        border: 0px solid #F1F5F9;
    }
 
`

export const ProfileSettingStateText = styled.div`
    flex-grow : 1;
    color: #191E29;
    padding-bottom: 2px;
    
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    
    
    word-break : break-all;
    text-overflow:ellipsis; 
    white-space:nowrap;
    overflow : hidden;
`

export const ProfileSettingStateCntWrap = styled.div`
    
`

export const ProfileSettingStateCnt = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: ${({last})=> last ? '#353C49' : '#3A6AF6'}; ; 
`

export const ProfileSettingStickersWrap = styled.div`
    position: relative;
    width: calc(100% - 60px);
    background: #FFFFFF;
    border: 1px solid #F1F5F9;
    border-radius: 12px;
    margin: 10px 30px 0px 30px;
    padding: 5px 16px 5px 10px;
`

export const RepStickerWrap = styled.div`
    display : flex;
    align-items : center;
`

export const RepStickerInfoWrap = styled.div`

`

export const RepStickerDesc = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 2px;
    color: #191E29;
`

export const RepStickerCriteria = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #191E29;
`

export const ProfileSettingStickers = styled.div`
    display : flex;
    gap : 18px 19px;
    flex-wrap : wrap;
    margin-top : 20px;
`

export const StickerInfoWrap = styled.div`
    display : flex;
    gap : 7px;
    flex-direction : column;
    align-items : center;
    width : 104px;
`

export const StickerInfoName = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #191E29;
`

export const StickerImgWrap = styled.div`
    position: relative;
    border: 1px solid #E5EBF0;
    margin-right: 10px;
    
    &.rep {
        border : none;
    }
`

export const StickerImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 60px;
  height: 60px;
  object-fit: contain;
`

export const StickerRepWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  border: 2px solid #353C49;
  overflow: hidden;
`

export const StickerRepTxt = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  padding: 1px 4px 0 5px;

  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  color: #FFFFFF;

  background: #353C49;
  border-radius: 0 0 0 10px;
`

export const StickerLockWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  border-radius: 10px;
  background-color: #F7F9FB;
`

export const StickerLockImg = styled.img.attrs(props => ({
    src: props.src
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`

export const ProfileMenusWrap = styled.div`
    padding: 20px 0px 25px 16px;
    background-color: #ffffff;
`

export const ProfileMenusTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    margin-bottom: 10px;
`

export const ProfileMenus = styled.div`
    padding: 0px 16px 0px 0px;
    margin: 15px 0px 15px 0px;
`



export const ProfileBottomWrap = styled.div`
    padding: 0px 0px 30px 32px;
    background-color: #ffffff;
`

export const ProfileBottomText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #353C49;
`