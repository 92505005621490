import styled from "styled-components";


// export const ScrollDiv = styled.div`
//     display: block;
//     float: left;
//     width: 1117px;
//     overflow-y:
//     overlay;
//     background: #ffffff;
//     height : ${({height, heightAdjust}) => height ? height : heightAdjust ? `calc(100% + ${heightAdjust})` : '100%'};
// `

export const ScrollDiv = styled.div`
    display: block;
    width : 100%;
    height: calc(100% - 62px);
    overflow-x: hidden;

    &::-webkit-scrollbar {
        // width: 24px;
        display: none;
    }
    

    &::-webkit-scrollbar-thumb {
        background-color: #E5EBF0; 
        border-radius: 26px; 
        background-clip: padding-box; 
        border: 10px solid transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: none; 
        border-radius: 26px;
    }
    
`

export const ScrollDivWrap = styled.div`
    padding-bottom: 62px;
    width: 100%;
`