import styled from "styled-components";

export const NoticeModalBody = styled.div`
    // height : 680px;
    align-items : center;
    justify-content : center;
    display : flex;
    height : 100%;
    box-sizing : border-box;
    padding-bottom : 10px;
`

export const NoticeListWrap = styled.div`
    display: block;
    padding-top: 10px;
    width : 100%;
    height: 100%;
`

export const NoticeNoResultWrap = styled.div`
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
`

// export const NoticeModalWrap = styled.div`
//     background : ${({theme})=>theme.colors.white};
//     padding : ${({result})=>{
//         if(result){
//             return '30px 16px 30px 30px';
//         }
//         return '30px';
//     }};
//     border-radius: 30px;
//     width : 520px;
//     position : relative;
// `


export const NoticeItemWrap = styled.div`
    padding : 20px 16px 15px 20px;
    border: 1px solid #EAEAEA;
    border-radius: 15px;
    margin-bottom: 10px;
`

export const NoticeItemTitle = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    font-weight: 700;
    margin-bottom: 8px;
    
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const NoticeItemContent = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    font-weight: 400;
    margin-bottom: 15px;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const NoticeItemDate = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AEB7C0;
    text-align: right;

`