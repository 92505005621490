import styled from "styled-components";

export const GroupSettingWrap = styled.div`
    width : 100%;
    background : transparent;
`

// export const GroupSettingContainer = styled.div`
//     width : 100%;
//     background : transparent;
//     height : calc(100vh - 87px);
// `

export const GroupSttingTopWrap = styled.div`
    height: 43px;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
`

export const GroupSettingTitle = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #191E29;
    // padding : 40px 70px 30px 70px;
`

export const GroupManagemenetTitle = styled.h4`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
`



export const GroupSttingCloseWrap = styled.div`
    position : absolute;
    top: 8px;
    right : 16px;
`



export const GroupSttingUserWrap = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 10px 0px 15px 16px;
`

export const GroupSttingUserTitle = styled.h4`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    margin-bottom: 15px; 
`

export const GroupSttingUserInfoWrap = styled.div`
    padding-left: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
`

export const GroupSettingUserInfoImgWrap = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 25px;
`

export const GroupSettingUserInfoImg = styled.img`
    width : 100%;
    height : 100%;
    border-radius: 25px;
`

export const GroupSettingUserInfo = styled.div`
    display : flex;
    flex-direction : column;
    gap: 3px;
`

export const GroupSettingNicknameWrap = styled.div`
    display : flex; 
    gap : 5px;
`

export const GroupSettingNickname = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #191E29;
    display : flex;
`

export const GroupSettingCompany = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #AEB7C0;
`

export const GroupSettingTabWrap = styled.div`
    padding : 0 16px;

`

export const GroupSttingMemberTitle = styled.h4`
    padding: 15px 0px 0px 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
    
    &.paddingBottom {
        padding: 15px 0px 0px 0px;
    }
    
    & > span  {
        float: right;
        padding: 0px 16px 0px 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #AEB7C0;
    }
`

export const GroupSettingTabMenus = styled.div`
    padding-top : 20px;
    display : flex;
    flex-direction: column;
    gap : 10px;
`

export const GroupSettingTabMenu =  styled.div`
    position : relative;
    padding : 14px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #353C49;
    width : 100%;
    background: #F7F7F7;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    //
    // &.active {
    //     background: #3A6AF6;
    //     font-weight: 500;
    //     color: #ffffff;
    //     border: none;
    // }
`

export const GroupsSettingImgWrap =  styled.div`
    width: 24px;
    height: 24px;
    
`

export const GroupSettingImg =  styled.img`
    width: 100%;
    height: 100%;
`

export const GroupsSettingBtnWrap =  styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: 16px;
`

export const GroupSettingUnRead = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    background: #FF7171;
    border-radius : 100%;
    top : 10px;
    right : 10px;
`

/*export const GroupMemberExitWrap = styled.div`
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 62px;
    bottom: 0px;
    background: #FFFFFF;
    border-top: 1px solid #F1F5F9;
`

export const GroupMemberExit = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 62px;
    color: #AEB7C0;
    padding-left: 18px;

`*/