import {
    Input,
    RecentSearchWords,
    RecentSearchWordText,
    RecentSearchWordWrap,
    RecentSearchWrap,
    SearchFieldContainer
} from "./SearchFieldStyles";
import {IconButton} from ".";
import {useEffect, useRef, useState} from "react";
import SearchDeleteIcon from '../../../resources/icons/search_delete.svg';
import SearchIcon from '../../../resources/icons/search.svg';
import SearchRecentDeleteIcon from '../../../resources/icons/search_recent_delete.svg';
import {receiveResponse} from "../../../utils/api";
import env from "../../../constants/env";
import {getMaxLenCheck} from "../../../utils/boolHandler";

const SearchField = (props) => {

    const {placeholder, onChange, value, onSearch, onDelete, onFocus, type} = props;
    // focus 상태
    const [isFocus, setIsFocus] = useState(false);
    // 최근 검색어 오픈
    const [isOpen, setIsOpen] = useState(false);

    // 최근 검색어
    const [recentWords, setRecentWords] = useState([]);
    // 검색어 (컴포넌트용 검색어)
    const [word, setWord] = useState(value);

    const element = useRef();

    /**
     * @param e
     * 검색어(input) 변경 이벤트
     */
    const onChangeHandler = (e) => {

        const value = e.target.value;
        const regex = new RegExp(`^[a-zA-Z0-9ㄱ-힣\\s]{0,${env.maxSearchWordLen}}$`,'gi');
        const check = regex.test(value);

        if(!check && value) return;

        // 글자수 안이면
        if(getMaxLenCheck(e.target.value.length, env.maxSearchWordLen)) {
            setWord(e.target.value);
        }

        if(e.target.value.length === 0) {
            if(onChange && typeof onChange === 'function'){
                onChange(e.target.value);
            }
        }
        if(e.target.value.length > 0) setIsOpen(false);
        else setIsOpen(true);
    }

    /**
     * @param e
     * focus 되는 경우 callback
     */
    const onFocusHandler = (e) => {
        e.preventDefault();
        if(onFocus && typeof onFocus === 'function') onFocus();
        setIsFocus(true);
        if(word.length===0) setIsOpen(true);

    }

    /**
     * @param e
     * 최근 검색어 조회
     */
    const getRecentWords = () => {
        receiveResponse({type, page :0, size : env.searchWordsSize},'recent-search-words')
            .then((res)=>{
                if(res.status===200){
                    setRecentWords(res.data);
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    /**
     * 최근 검색어 삭제
     * @param word : 최근검색어
     */
    const deleteRecentWord = (word) => {
        receiveResponse({}, `recent-search-words?searchWord=${encodeURIComponent(word)}&type=${type}`,'delete')
            .then((res)=>{
                if(res.status===200){
                    getRecentWords();
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    /**
     *
     * @param word
     * 검색 시 콜백 있으면 사용
     */
    const onSearchHandler = (word) => {
        if(onSearch && typeof onSearch === 'function'){
            if(!word.replaceAll(' ','')) return;
            onSearch(word);
            element.current.blur();
            setIsFocus(false);
        }
    }

    /**
     * @param e
     * @param recentWord
     * 최근 검색어 클릭 시 검색 실행
     */
    const onClickWordHandler = (e,recentWord) => {
        // word가 ''였을 경우 > setWord 되기 전에 onBlur가 됨
        setWord(recentWord);
        onSearchHandler(recentWord);
    }

    /**
     * @param e
     * enter 입력 시 검색 실행
     */
    const onPressEnter = (e) => {
        if(e.key==='Enter') {
            onSearchHandler(word);
        }
    }

    /**
     * @param e
     * 입력된 검색어 삭제
     */
    const onDeleteHandler = (e) => {
        setWord('');
        if(onDelete && typeof onDelete === 'function'){
            onDelete(e);
        }
        setIsFocus(true);
        setIsOpen(true);
        element.current.focus();
    }

    const onBlur = (e) => {

        if(onChange && typeof onChange === 'function'){
            onChange(e.target.value);
        }

        setIsFocus(false);
        setIsOpen(false);
    }

    const handleMouseDown = (e) => e.preventDefault()

    // 최근 검색어 리스트 생성
    const createRecentWord = (recentWords) => {
        return recentWords.map((recentWord)=>
            <RecentSearchWordWrap>
                <RecentSearchWordText
                    onClick={(e)=>onClickWordHandler(e,recentWord)}
                >{recentWord.replaceAll(' ', '\u00A0')}</RecentSearchWordText>
                <IconButton
                    width={'16px'}
                    height={'16px'}
                    onClick={e=>deleteRecentWord(recentWord)}
                    src={SearchRecentDeleteIcon}
                />
            </RecentSearchWordWrap>
        )

    }

    useEffect(()=>{
        setWord(value);
    },[value]);

    useEffect(()=>{
        if(isOpen) getRecentWords();
    },[isOpen])

    return(
        <SearchFieldContainer className={isFocus ? 'focus' : ''}>
            <IconButton
                width={'22px'}
                height={'22px'}
                src={SearchIcon}
                onClick={e => onSearchHandler(word)}
            />
            <Input
                maxLength={env.maxSearchWordLen}
                value={word}
                onChange={onChangeHandler}
                onKeyPress={e=>onPressEnter(e)}
                placeholder={placeholder}
                onBlur={e=>onBlur(e)}
                onFocus={onFocusHandler}
                ref={element}
                isFocus={isFocus}
            />
            {
                word ?
                    <IconButton
                        width={'18px'}
                        height={'18px'}
                        src={SearchDeleteIcon}
                        onClick={onDeleteHandler}
                    /> : null
            }
            {
                isOpen && recentWords.length!==0 ?
                    <RecentSearchWrap isOpen={isOpen} onMouseDown={handleMouseDown}>
                        <RecentSearchWords>
                            {createRecentWord(recentWords)}
                        </RecentSearchWords>
                    </RecentSearchWrap>
                    : null
            }
        </SearchFieldContainer>
    )
}

SearchField.defaultProps = {
    placeholder :'Enter Text',
    onChange : null,
    value : '',
    onSearch : null,
    onDelete : null,
    onFocus : null,
    type : 'FI' // FI, GI, G
}

export default SearchField;