import styled from "styled-components";


export const GroupHomeWrap = styled.div`
    position: relative;
    background: #F7F9FB;
    width: 100%;
    height: calc(100% - 43px);
`

export const SearchWrap = styled.div`
    height: 43px;
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 2;
`

export const GroupItemPreBtn = styled.div`
    position: absolute;
    top: 10px; 
    left: 16px;
`

export const GroupItemPreBtnImg = styled.img`
`


export const GroupHeaderSettingWrap = styled.div`
    position: absolute;
    top: 10px; 
    right: 16px;
`


export const GroupHeaderSettingImg = styled.img`
    &.hidden { display: none; }
    
    vertical-align: initial;
    margin-right : 6px;
`

export const SearchBarVisible = styled.div` 
     &.show { display:flex; }
     display: none;
     width: 100%;
     padding: 10px 20px 10px 16px;
     background: #ffffff;
`

export const SearchBarBookmark = styled.div`

    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-sizing: border-box;
    background: #FFFFFF;
    /* gray_Stroke */

    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    radius: 10px;

    background : ${({disabled})=>{
        if(disabled){
            return '#F2F4F6;';
        }else {
            return '#FFFFFF;';
        }
    }}; 
    
    color : ${({disabled})=>{
        if(disabled){
            return '#AEB7C0;';
        }
    }};

`

export const GroupHeaderWrap = styled.div`
    display: block;
    height: 150px;
`

export const GroupHeaderImgWarp = styled.div`
    background: #ffffff;
    display: flex;
    margin-top: -90px;
    align-items: center;
    justify-content: center;
`

export const GroupHeaderImg = styled.img`
    width: 120px;
    height: 120px;
    object-fit : contain;
    display: inline-block;
    position: relative;
    background: rgb(217, 217, 217);
    border-radius: 12px;
`

export const GroupHeaderCoverImg = styled.img`
    height : 120px;
    width: 100%;
`

export const GroupInformationWrap = styled.div`
    height: 116px;
    padding: 36px 16px 20px;
    background: #ffffff;
`

export const GroupInformationDataWrap = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;

    min-width : 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    word-break: break-all;
    -webkit-box-orient: vertical;
`

export const GroupInformationInfoWrap = styled.div`
    padding-top: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3A6AF6;
    display : inline-block;
`

export const GroupInformationInfoBtn = styled.img`
`

export const GroupDataWrap = styled.div`
    position: relative;
    display: block;
`

export const GroupWrap = styled.div`
    position: relative; 
    display: block;
    height: 100%;
    // padding: 46px 0px 0px 0px !important;
`

export const GroupTabWrap = styled.div`
    height: 45px;
    position: sticky;
    top: 43px;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    z-index: 2;
    padding: 5px 0px 0px 16px;
`

export const GroupTabUl = styled.ul`
   
`

export const GroupTabLi = styled.li`  
    float:left; 
    text-align: center; 
    border-bottom: none; 
    
    display: inline-block;
    width: 100px;
    position: relative;
    color: #AEB7C0;
    padding: 5px 0px 15px;
    
    color : ${({selected})=> {
        if(selected) {
            return '#353C49;'
        }else {
            return '#AEB7C0;'
        }
    }}
    
     border-bottom : ${({selected})=> {
        if(selected) {
            return '2px solid #191E29;'
        }
    }}
`

export const GroupTabText = styled.div`
    text-align: center; 
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%; 
    display: block;
`


export const SearchBarRight = styled.div` 
    display: flex;
    position: absolute;
    top: 8px;
    right: 16px;
    align-items: center;
`

export const SearchBarSearch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    
    border : ${({selected, disabled})=> {
        if(disabled) {
            return '1px solid #EAEAEA;';
        }
        else if(selected) {
            return '1px solid #3A6AF6;';
        }
        return '1px solid #EAEAEA;';
    }}
    
    background: ${({selected, disabled})=> {
        if(disabled){
            return '#F2F4F6;';
        }
        if(selected) {
            return '#E7EDFF;'
        }
    }}
    
    &.hidden { display:none; }
`

export const GroupListWrap = styled.div`
    padding-left: 16px;
    background: #F7F9FB;
`

export const GroupNoResultWrap = styled.div`
    padding-top: 30px;
    text-align : center;
    width : 100%;
`

export const GroupListNoticeTitle = styled.h4`
    padding-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
`

export const GroupListNoticeWrap = styled.div`
    background: #FFFFFF; 
    display: flex; 
    border: 1px solid #F1F5F9;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    padding: 12px 16px;
    margin: 10px 16px 0px 0px;
    align-items : center;
    gap : 12px;
`

export const GroupListNoticeImgWrap = styled.div`
    // padding: 0px 15px 0px 19px;
`

export const GroupListNotice = styled.div`
    // width: 280px; 
    // height: 30px;
    display : flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical; 

`
export const GroupListAllNotice = styled.div`

    font-weight: 400; 
    font-size: 14px; 
    line-height: 23px;
    float: right;
    padding-right: 10px;
    
    // &::before {
    //     display: inline-block;
    //     position: relative;
    //     top: 3px;
    //     left: -20px;
    //     width: 2px;
    //     height: 19px;
    //     background-color: #EAEAEA;
    //     content: '';
    // }
`

export const GroupListNoticeImg = styled.img`
    border: none;
    vertical-align: top;
`

export const GroupList = styled.div`
    padding-top: 25px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const GroupListTitle = styled.h4`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #75818D;
`

export const GroupListPaging = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    width: calc(100% + 16px);
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px 0px;
    margin-left: -16px;
    font-size: 14px; 
    line-height: 20px; 
    color: #AEB7C0; 
    text-align: center;
    background: #FFFFFF; 
    
    & > div {
        width: 20px; 
        height: 20px; 
        display: inline-block; 
        text-align: center;
    }
`

export const GroupListPagingPrevNext = styled.div`
   
`

export const GroupListPagingPrevImg = styled.img`
    width: 100%;
    height: 100%;
    display: ${({ display }) => {
    if (display) {
        return 'none;';
    }
}}
`

export const GroupListPagingNextImg = styled.img`
    width: 100%;
    height: 100%;
    display: ${({ display }) => {
        if (display) {
            return 'none;';
        }
    }}
`



export const GroupListPagingNumber = styled.div`

    background: ${({ selected }) => {
        if (selected) {
            return '#FFFFFF;';
        }
    }}
    
    font-weight: ${({ selected }) => {
        if (selected) {
            return '700;';
        }
    }} 
  
    color: ${({ selected }) => {
        if (selected) {
            return '#191E29;';
        }
    }} 
     
`

export const GroupFABWrap = styled.div`
    // position : absolute;
    // bottom : 90px;
    // right : 60px;
    // z-index : 3;
    // &.hide{
    //     display : none;
    // }
    
    display: block; 
    width: 50px; 
    height: 50px; 
    filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.1)); 
    margin: 10px 0px 0px 0px;
    
    &.hide{
        display : none;
    }
`

export const GroupQuickWrap = styled.div`
    position: fixed; 
    bottom: 120px; 
    right: 20px; 
    z-index: 3;
`

export const GroupSearchSubTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    font-weight : 400;
`

export const GroupSearchSubTitleWord = styled.span`
    font-weight : 700;
`

export const GroupResultWrap = styled.div`
    padding-top: 90px;
    text-align : center;
`

export const GroupResultWrapTitle = styled.div`
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #75818D;
    margin-top : 10px;
`

export const GroupStoreContainer = styled.div`
    width : 100%;
`

export const GroupStoreNavWrap = styled.div`
    display: flex;
    width: 100%;
    background: #F7F9FB;
    margin-left: -16px;
    padding: 16px;
    position: relative;
`

export const GroupStoreNavMyFileWrap = styled.div`
    display : flex;
    width: 100%;
    justify-content: flex-end;
`


export const GroupFilesWrap = styled.div`
    width: 100%;
    height: 100%;
    display : flex;
    flex-wrap: wrap;
    gap : 10px;
    padding-bottom: 30px;
`

export const GroupNotJoinedCloseWrap = styled.div`
    position: fixed;
    bottom: 62px;
    width : 100%;
    background: #F7F9FB;
    opacity: 0.9;
    padding : 20px 0 20px 0;
    display : flex;
    flex-direction : column;
    align-items : center;
    -webkit-align-items: center;
    -webkit-box-align: center;
`

export const GroupNotJoinedContainer = styled.div`
    width : 100%;
    position : absolute;
    top: 0px;
    left: 0px;
    z-index : 4;
    display: block;
`

export const GroupNotJoinedTransparentContainer = styled.div`
    backdrop-filter: blur(1px);
    height: 180px;
    background : transparent;
    background: linear-gradient(180deg, #F7F9FB 0%, rgba(247, 249, 251, 0) 100%); 
    transform: rotate(-180deg);
`

export const GroupKickWrap = styled.div`
    height: calc(100vh - 552px);
    width : 100%;
    color: #191E29;
    background: #F7F9FB;
    opacity: 0.9;
    // position : absolute;
    // bottom : 0;
    padding : 42px 0;
    display : flex;
    flex-direction : column;
    align-items : center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
`

export const GroupNotJoinedWrap = styled.div`
    width : 100%;
    height: 100%;
    padding-bottom: 20px;
    background: #F7F9FB;
    color: #191E29;
    opacity: 0.9;
    display : flex;
    flex-direction : column;
    align-items : center;
    -webkit-align-items: center;
    -webkit-box-align: center;
`

export const GroupNotJoinedText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #191E29;
    margin-bottom : 20px;
    display: flex;
    flex-direction: column;
    white-space : pre-wrap;
`

export const GroupNotJoinedTextWrap = styled.div`
    display: flex;
    gap: 10px;
`

export const GroupNotJoinedTitleText = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    justify-content: center;
    white-space : pre-wrap;
`

export const GroupNotJoinedTextStrong = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    padding-right: 7px;
    
    max-width: 100px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical; 
`

export const GroupLockerDateWrap = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 10px;
`

export const GroupLockerDate = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #75818D;
`

export const GroupLockerImgWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const GroupLockerImgNickName = styled.div`
    padding-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #75818D;
    
`

export const GroupCloseResultWrap = styled.div`
    height : calc(100vh - 61px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`
