import {
    AuthErrorWrap,
    AuthErrorLogoWrap,
    AuthErrorLogoImg,
    AuthErrorContent,
    AuthErrorTitle
} from "./errorStyles";
import {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";

import {Button} from "../../../components/presentationals/global";
import {useStores} from "../../../stores";
import Logo from "../../../resources/images/macalon_login_logo.png";
import {appWebViewBackEvent} from "../../../utils/appBridgeEvnet";

const Error = (props) => {

    /**
     * 01. 전역변수
     *
     */

    // 전역변수
    const {store} = useStores();
    const {topicStore, authStore, interestStore, userStore} = store;
    const isAppReady = authStore.isAppReady;

    // 다국어 처리에 대한 hook
    const {t} = useTranslation();

    /**
     * state값 유지 (뒤로가기시)
     * 02. state값 관리
     *
     */


    /******************************************************************************************************************/

    /**
     * 04. 함수
     *
     */


    /******************************************************************************************************************/

    /**
     * 05. 클릭 이벤트 함수
     *
     */

    // 돌아가기 저장 이벤트
    const onBackLoginAppEvent = () => {
        //앱 이벤트
        appWebViewBackEvent(isAppReady);
    }

    /******************************************************************************************************************/

    useEffect(()=>{
    },[]);

    /******************************************************************************************************************/

    /**
     * 07. render
     *
     */
    return(
        <AuthErrorWrap>

            {/*title*/}
            <AuthErrorLogoWrap>
                <AuthErrorLogoImg src = {Logo}/>
            </AuthErrorLogoWrap>


            {/*content*/}
            <AuthErrorContent>

                <AuthErrorTitle>
                    {t("AUTH_ERROR_FAIL_MESSAGE")}
                    <br/>
                    {t("AUTH_ERROR_FAIL_REDIRECT_MESSAGE")}
                </AuthErrorTitle>

                <Button
                    shape={'circle'}
                    width={'100%'}
                    onClick={onBackLoginAppEvent}
                    wrapperStyle={{
                        padding: '12px 36px',
                        gap: '10px',
                        borderRadius: '4px',
                        border: 'none',
                        marginBottom: '220px'
                    }}
                    textStyle={{
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: '23px'
                    }}
                >{t('AUTH_ERROR_RETURN_MESSAGE')}</Button>

            </AuthErrorContent>

        </AuthErrorWrap>
    )
}

export default observer(Error);