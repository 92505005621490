import styled from "styled-components";

export const AttachFileWrap = styled.div`
    position : relative;
    width : 150px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px;
    gap: 2px;
    border-radius: 65px;
    border : ${({theme})=>`1px solid ${theme.colors.gray_stroke_100}`};
    background : ${({theme})=>theme.colors.white};
    
    // &:hover {
    //     background : {({theme,type})=> {
    //         // if(type==='download') return;
    //        
    //         return theme.colors.gray_hover
    //     }};
    // }
`

export const AttachFileName = styled.div`
    width : 82px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding-right: 7px;
    // flex: none;
    color : ${({theme})=>theme.colors.black};
    display : flex;
`

export const FileName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
`

export const FileExt = styled.div`
    flex : none;
    flex-grow : 1;
`

