import {ImgContent, ImgWrap} from "./ImgStyles";
import EmptyIcon from "../../../resources/icons/attach_img_gray.svg";
import {useState} from "react";
import isEmpty from "is-empty";

const Img = (props) => {

    const {src = null, isIcon = false, wrapStyle = {}, imgStyle = {}, children = null, onClick = null} = props;

    const [useDefaultBg, setUseDefaultBg] = useState(isEmpty(src));

    const onClickHandler = (e) => {
        if (onClick && typeof onClick === 'function') {
            onClick(e);
        }
    };

    return <ImgWrap className={`${useDefaultBg || isIcon ? 'default-bg' : ''}`} style={wrapStyle} onClick={onClickHandler}>
                <ImgContent src={src ?? EmptyIcon} style={imgStyle}
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = EmptyIcon;
                                setUseDefaultBg(true);
                            }}/>
                {children}
            </ImgWrap>
}

export default Img;