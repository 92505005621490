import styled from "styled-components";

export const CardContainer = styled.div`
    display: block;
    padding: 20px 16px 0px 0px;
    background: #F7F9FB;
    z-index : 0;
`

export const CardHeader = styled.div`
    height: 24px;
    position: relative;
    display : flex;
    gap : 7px;
    align-items : center;
    margin-bottom : 12px;
`

export const CardHeaderImg = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 10px;
`

export const CardHeaderName = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #191E29;
    width: calc(100% - 60px);
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const CardWrap = styled.div`
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    display: block;
    padding: 20px;
    position : relative;
    
    // border: ${({theme})=>`1px solid ${theme.colors.gray_stroke_50}`};
    // padding : 30px;
    // width : 489px;
    // height : 310px;
    //
    //
    // // ============================
    // position : relative;
    // box-sizing: border-box;
    // display : flex;
    // // width: 490px;
    // // height: 180px;
    // background: ${({theme})=>theme.colors.white};
    // border: ${({theme})=>`1px solid ${theme.colors.gray_stroke_50}`};
    // box-shadow: 2px 4px 20px rgba(117, 129, 141, 0.04);
    // border-radius: 30px;
      
    padding : ${({type})=>{
        if(type==='group-board'){
            return '20px 30px';
        } else if(type==='comment') {
            return '20px';
        }
        return '20px';
    }};
    //
    // flex : none;
`

export const Section = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 0px;
    // flex : 1;
    // overflow : hidden;
    
`

export const TitleContentImgWrap = styled.div`
    display : flex;
    gap: 10px;
    align-items: flex-start;
`

export const TitleContentWrap = styled.div`
    display : flex;
    flex-direction: column;
    // flex-grow: 1;
    flex : 1;
    overflow : hidden;
`

export const TitleWrap = styled.div`
    padding-bottom : 6px;
    display : flex;
`

export const TitleIcon = styled.img.attrs(props=>({
    src : props.src
}))`
    display : block;
    flex : none;
    width: 24px;
    height: 24px;
    background: ${({theme})=>theme.colors.white};
    margin-right : ${({type})=>{
        if(type==='board'){
            return '6px;'
        }
        return '0px';
    }};
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    

    color: ${({isSearch,theme})=>{
        if(isSearch){
            return theme.colors.gray_50;
        }
        return theme.colors.black;
    }};
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break : break-all;
`

export const ContentWrap = styled.div`
    display: flex;
    width: 100%;
   
`

export const Content = styled.div`
    height : 37px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break : break-all;

    color: ${({isSearch,theme})=>{
        if(isSearch){
            return theme.colors.gray_50;
        }
        return '#AEB7C0';
    }};

`

export const UserInfoWrap = styled.div`
    margin-top : 10px;
    padding-top : 10px;
    border-top: 1px solid #E5EBF0;
`

export const CardImageWrap = styled.div`
    position: relative;
    width : 60px;
    height : 60px;
    flex: none;
`

export const CardImage = styled.img.attrs(props => ({
    src: props.src,
}))`
    width : 100%;
    height : 100%;
    border-radius: 10px;
`

export const CardImageCntWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 8px 2px 6px;
    position: absolute;
    background: #191E29;
    opacity: 0.8;
    border-radius: 65px;
    right : 0px;
    bottom : 4px;
`

export const CardImageCnt = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
`

export const CardImageInfo = styled.div`
  width: 100%;
  padding: 0 1em;

  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  color: #191E29;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const OptionsWrap = styled.div`
    display : inline-block;
    align-items : center;
    gap : 10px;
`

export const OptionWrap = styled.div`
    display : flex;
    position : relative;
    align-items : center;
    gap : 18px;
    margin-top: 7px;
`

export const Option = styled.div`
    display : flex;
    align-items : center;
    gap : 2px;
`

export const OptionBookmark = styled.div`
    display: flex;
    flex : none;
`

export const OptionIcon = styled.img.attrs(props=>({
    src : props.src
}))`
    width : 16px;
    height : 16px;
`

export const OptionText = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #313131;
`

export const OptionTime = styled.div`
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #AEB7C0;
    text-align : right;
`

export const Category = styled.div`
    font-size: 10px;
    line-height: 14px;
    color: ${({isSearch,theme})=>{
        if(isSearch){
            return theme.colors.gray_50;
        }
        return theme.colors.blue;
    }};
`

export const GroupBadge = styled.div`
  font-weight: 700;
  font-size: 9px;
  line-height: 13px;
  color: #FFFFFF;

  background: #AEB7C0;
  border-radius: 6.5px;
  padding: 0 5px;
`
export const CommentInfo = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #7C9EFF;
  display: flex;
  align-items: center;
`
export const CommentInfoContent = styled.div`
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CommentWriteDate = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #AEB7C0;
`

export const PinWrap = styled.div`
    position : absolute;
    // top : 30px;
    // right : 30px;
`

export const InvitedBtnWrap = styled.div`
  margin-top:10px;
  
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
`
export const InvitedBtn = styled.div`
  width: 208px;
  height: 42px;

  font-weight: 500;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  vertical-align: middle;

  border-radius: 14px;
  
  &.gray {
    color: #75818D;
    background: #DEE4EA;
  }

  &.black {
    color: #F1F5F9;
    background: #191E29;
  }
`