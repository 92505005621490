import {observer} from "mobx-react";
import {
    GroupManagemenetTitle,
    GroupSettingCompany,
    GroupSettingImg,
    GroupSettingNickname,
    GroupSettingNicknameWrap,
    GroupSettingTabMenu,
    GroupSettingTabMenus,
    GroupSettingTabWrap,
    GroupSettingTitle,
    GroupSettingUserInfo,
    GroupSettingUserInfoImg,
    GroupSettingUserInfoImgWrap,
    GroupSettingWrap,
    GroupsSettingBtnWrap,
    GroupsSettingImgWrap,
    GroupSttingCloseWrap,
    GroupSttingMemberTitle,
    GroupSttingTopWrap,
    GroupSttingUserInfoWrap,
    GroupSttingUserTitle,
    GroupSttingUserWrap
} from "./GroupSettingStyles";
import {useEffect, useState} from "react";
import GroupMemberSetting from "../../components/presentationals/groupSetting/GroupMemberSetting";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import routes from "../../constants/routes.json";
import {receiveResponse} from "../../utils/api";
import {useStores} from "../../stores";
import {IconButton} from "../../components/presentationals/global";
import CancelIcon from "../../resources/icons/modal_cancel.svg";
import informationSettingImg from "../../resources/icons/informationSettingIcon.png";
import joinSettingImg from "../../resources/icons/joinSettingIcon.png";
import exitSettingImg from "../../resources/icons/exitSettingIcon.png";
import inviteSettingImg from "../../resources/icons/inviteSettingIcon.png";
import selectBtnImg from "../../resources/mImages/group_invitation_arr.png";
import {useTranslation} from "react-i18next";
import {ServerConfig} from "../../commons/config";
import DefaultImg from "../../resources/icons/profile.png";
import ManagerIcon from "../../resources/mImages/icon_manager.png"
import AssistantIcon from "../../resources/mImages/icon_deputy.png";

const GroupSetting = (props) => {

    const {t} = useTranslation();
    const {store} = useStores();
    const {userStore, toastPopupStore, groupDetailStore, modalPopupStore, modalToastPopupStore, defaultImageStore, onSubmit} = store;
    const location = useLocation();
    const history = useHistory();

    // 선택된 tab
    const [selected, setSelected] = useState(null);

    // 탭 메뉴 (방장일 경우, 부 방장일경우 다름)
    const managerMenu = ['그룹 정보 설정', '그룹 멤버 설정','가입 신청 설정', '그룹 폐쇄'];

    // 탭 메뉴 (방장일 경우, 부 방장일경우 다름)
    const menus = [
        {id : 'info', name : t('GROUP_INFO_SETTING'), img: informationSettingImg},
        {id : 'apply', name : t('APPLY_SETTING'), img: joinSettingImg},
        {id : 'close', name : t('CLOSE_GROUP'), img: exitSettingImg}
    ]

    // 탭 메뉴2 (방장일 경우, 부 방장일경우 다름)
    const menus2 = [
        {id : 'invite', name : t('INVITE_GROUP'), img: inviteSettingImg}
    ]

    // 탭 메뉴 선택
    const onClickMenu = (id) => {

        setSelected(id);

        const match = matchPath(location.pathname,{
            path : `${routes.GROUP_SETTING}/:id`
        })

        if(match && match.isExact){
            if(id === 'info') history.push(`${routes.GROUP_SETTING_INFORMATION}/${match.params.id}`);
            else if (id === 'apply') history.push(`${routes.GROUP_SETTING_APPLY}/${match.params.id}`);
            else if (id === 'close') history.push(`${routes.GROUP_SETTING_CLOSE}/${match.params.id}`);
            else if (id === 'invite') {
                // 초대하기 클릭시 모달 열기
                modalPopupStore.initAdd( 'i', null, groupDetailStore.group, () => {}, () => {});
            }
        }
    }

    // 상단의 tab menu 생성
    const createTabMenus = (menus) => {

        if(!groupDetailStore.userList || !userStore.user.userId || !groupDetailStore.group) return;

        let _isManager = groupDetailStore.isManager;
        let _isAssistant = groupDetailStore.isAssistant;
        let _isJoiner = groupDetailStore.isJoined;
        let _isSecret = groupDetailStore.group?.privateYn === 'Y' ? true : false;

        return menus.map(
            (menu, index)=> {

                // 매니저, 부매너지 아니고 회원일 경우( 비밀일경우 )
                if (!_isManager && !_isAssistant && _isJoiner && _isSecret) {
                    if (menu.id === 'info' || menu.id === 'close' || menu.id === 'apply' || menu.id === 'invite') return;
                }

                // 매니저, 부매너지 아니고 회원일 경우
                if (!_isManager && !_isAssistant && _isJoiner) {
                    if (menu.id === 'info' || menu.id === 'close' || menu.id === 'apply') return;
                }

                // 부매너지일 경우
                if(!_isManager && _isAssistant){
                    if (menu.id === 'info' || menu.id === 'close') return;
                }

                return (
                    <GroupSettingTabMenu
                        className={selected === menu.id ? 'active' : ''}
                        onClick={e => onClickMenu(menu.id)}
                    >
                        <GroupsSettingImgWrap>
                            <GroupSettingImg src={menu.img}/>
                        </GroupsSettingImgWrap>
                        {createTabMenuContent(menu)}
                        <GroupsSettingBtnWrap>
                            <GroupSettingImg src={selectBtnImg}/>
                        </GroupsSettingBtnWrap>
                    </GroupSettingTabMenu>
                )
            }
        )
    }

    // 가입 신청 설정일 경우
    const createTabMenuContent = (menu) => {
        const _applyingUserList = groupDetailStore.applyingUserList;
        if(menu.id==='apply'){
            if(_applyingUserList){
                return (
                    <>
                        {_applyingUserList.length > 0 ? `${menu.name}(${_applyingUserList.length})` : menu.name}
                    </>
                )
            }
        }
        return menu.name;
    }

    // 탭 선택
    const initSelectedTab = () => {
        const _state = location.state;
        const _isManager = groupDetailStore.isManager;
        if(_state?.id){
            setSelected(_state.id);
        }else{
            if(!selected) setSelected(_isManager ? 'info' : 'apply');
            else{
                if(!_isManager) setSelected('apply');
            }
        }
    }

    // 권한이 없을 경우, 그룹 상세 페이지로 돌려보내기
    const denyAccess = () => {
        const match = matchPath(location.pathname,{
            path : `${routes.GROUP_SETTING}/:id`
        })
        if(match && match.isExact){
            history.replace(`${routes.GROUPS}/${match.params.id}`);
        }
    }

    /**
     ************* 상단
     * x버튼 클릭시
     * @param e
     */
    const onCloseHandler = (e) => {
        e.preventDefault();
        history.goBack();
    }

    const onSubmitHandler = () => {
        if(onSubmit && typeof onSubmit === 'function') onSubmit();

        history.replace(`${routes.GROUPS}/${groupDetailStore?.group?.groupId}`);
    }


    //프로필 상세 이동
    const onClickHandler = (e) => {
        modalPopupStore.initAdd(
            'vp',null,{userId : userStore.user.userId},()=>{},()=>{});
    };


    /**
     * ====================================================================
     * API
     */
    // 그룹 정보 받아오기
    const getGroupInfo = async () => {

        const _userId = userStore.user.userId;

        const match = matchPath(location.pathname,{
            path : `${routes.GROUP_SETTING}/:id`
        })

        if(match && match.isExact){
            await groupDetailStore.init(match.params.id, _userId, denyAccess, location.pathname)
                .then(()=>{
                    initSelectedTab();
                })
        }
    }

    // 그룹 정보 업데이트
    // 참여중인 그룹 리스트 업데이트 필요
    const updateGroupInfo = async (cb) => {
        const _userId = userStore.user.userId;
        const _group = groupDetailStore.group;
        await groupDetailStore.init(_group.groupId,_userId,denyAccess,location.pathname)
            .then(()=>{
                if(cb && typeof cb === 'function') cb();
            })
        await userStore.updateJoinGroups();
    }

    // 그룹 탈퇴 api
    const quitGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse({},`groups/${groupDetailStore?.group?.groupId}/quit`,'patch')
            .then(res=>{
                if(res.status===200){
                    if(res.data){
                        toastPopupStore.openToastPopup(
                            t('COMPLETE_QUIT_GROUP',
                                { groupName : groupDetailStore?.group?.groupName.length > 8 ? `${groupDetailStore?.group?.groupName.substr(0,8)}...` : groupDetailStore?.group?.groupName}
                            ));
                        userStore.updateInvitingGroups();
                        userStore.updateJoinGroups();
                        onSubmitHandler();
                    }
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }

    useEffect(()=>{
        // user 정보 있을때만 조회 가능
        if(userStore.user.userId) getGroupInfo();
    },[userStore.user.userId]);

    return(
        <GroupSettingWrap>

                {/* 그룹관리 X */}
                <GroupSttingTopWrap>
                    <GroupSettingTitle>{t("GROUP_MANAGEMENT")}</GroupSettingTitle>
                    {/* x버튼 컴포넌트*/}
                    <GroupSttingCloseWrap>
                        <IconButton width={'32px'} height={'32px'} onClick={onCloseHandler}
                                    src={CancelIcon}/>
                    </GroupSttingCloseWrap>
                </GroupSttingTopWrap>

               {/* 내 정보 */}
                <GroupSttingUserWrap>
                    <GroupSttingUserTitle>{t("MY_INFORMATION")}</GroupSttingUserTitle>
                    <GroupSttingUserInfoWrap>
                        <GroupSettingUserInfoImgWrap onClick={onClickHandler}>
                            <GroupSettingUserInfoImg
                                src={
                                    userStore.user.imgId  && defaultImageStore.defaultUserImgs
                                        ? defaultImageStore.defaultUserImgs.findIndex(img=>img === userStore.user.imgId)!==-1 ?
                                            `${ServerConfig.default.IMG_URL}${userStore.user.imgId}` :
                                            `${ServerConfig.default.IMG_THUMBNAIL_URL}${userStore.user.imgId}`
                                        : DefaultImg}
                            />
                        </GroupSettingUserInfoImgWrap>
                        {/* 닉네임, 회사 부분 */}
                        <GroupSettingUserInfo>
                            <GroupSettingNicknameWrap>
                                { groupDetailStore.isManager ?
                                    <img src={ManagerIcon}/> :
                                    groupDetailStore.isAssistant ? <img src={AssistantIcon}/> : null
                                }
                                <GroupSettingNickname>{userStore.user.nickName}</GroupSettingNickname>
                            </GroupSettingNicknameWrap>
                            <GroupSettingCompany>{userStore.user.companyName}</GroupSettingCompany>
                        </GroupSettingUserInfo>
                    </GroupSttingUserInfoWrap>
                </GroupSttingUserWrap>


                {/* 그룹 설정 */}
                { groupDetailStore.group && (groupDetailStore.isManager || groupDetailStore.isAssistant) ?
                    <GroupSettingTabWrap>
                        <GroupManagemenetTitle>{t("GROUP_SETTING")}</GroupManagemenetTitle>
                        <GroupSettingTabMenus>
                            {createTabMenus(menus)}
                        </GroupSettingTabMenus>
                    </GroupSettingTabWrap> : null
                }

                {/* 초대 그룹 */}
                <GroupSettingTabWrap>
                    <GroupSttingMemberTitle>
                        {t("GROUP_MEMBER")}
                        { groupDetailStore?.userList ?
                            <span>{'총 ' + groupDetailStore?.userList?.length + '명'}</span> : null
                        }
                    </GroupSttingMemberTitle>
                    { (groupDetailStore?.isManager || groupDetailStore?.isAssistant
                        || (groupDetailStore?.isJoined && groupDetailStore?.group?.privateYn === 'N' )) ?
                        <GroupSettingTabMenus>
                            {createTabMenus(menus2)}
                        </GroupSettingTabMenus> : null }

                </GroupSettingTabWrap>

                <GroupMemberSetting
                    group={groupDetailStore?.group}
                    isManager={groupDetailStore?.isManager}
                    isAssistant={groupDetailStore?.isAssistant}
                    userList={groupDetailStore?.userList}
                    onAppoint={updateGroupInfo}
                    onDismiss={updateGroupInfo}
                    onKick={updateGroupInfo}
                    onHandOver={e=>{updateGroupInfo(()=>setSelected('apply'))}}
                />

        </GroupSettingWrap>
    )
}

GroupSetting.defaultProps = {
    onSubmit: null
}

export default observer(GroupSetting);