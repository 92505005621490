exports.default = {
    // api development url
    // API_URL: 'http://localhost:8080/api/',
    // THUMBNAIL_URL : 'http://localhost:8080/api/files/thumb/',
    // FILE_URL : 'http://localhost:8080/api/files/file/',
    // IMG_URL : 'http://localhost:8080/api/imgs/',

    // API_URL: 'https://m-macalondev.kolon.com/api/',
    // THUMBNAIL_URL : 'https://m-macalondev.kolon.com/api/files/thumb/',
    // FILE_URL : 'https://m-macalondev.kolon.com/api/files/file/',
    // IMG_URL : 'https://m-macalondev.kolon.com/api/imgs/',

    API_URL: 'https://m-macalondev.kolon.com/api/',
    THUMBNAIL_URL : 'https://m-macalondev.kolon.com/api/files/thumb/',
    FILE_URL : 'https://m-macalondev.kolon.com/api/files/file/',
    IMG_URL : 'https://m-macalondev.kolon.com/api/imgs/',
    // 프로필 사진, 그룹 대표 이미지 썸네일 용
    IMG_THUMBNAIL_URL : 'https://m-macalondev.kolon.com/api/imgs/thumb/',

       // API_URL: 'https://203.225.252.217/api/',
       // THUMBNAIL_URL : 'https://203.225.252.217/api/files/thumb/',
       // FILE_URL : 'https://203.225.252.217/api/files/file/',
       // IMG_URL : 'https://203.225.252.217/api/imgs/',
};