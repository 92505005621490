import styled from "styled-components";

export const StickerDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin : 76px 0 28px 0;
  // height: 700px;
`

export const StickerDetailTitle = styled.div`
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 32px;
  line-height: 46px;

  color: #FFFFFF;
`

export const StickerDetailDesc = styled.div`
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: #FFFFFF;
`

export const StickerDetailCriteria = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: #848484;
`
