import {Input, TextFieldWrap} from "./TextFieldStyles";
import {Button, IconButton} from ".";
import {useEffect, useRef, useState} from "react";
import DeleteIcon from '../../../resources/icons/search_delete.svg';

const TextField = (props) => {

    const {
        size='s',
        type='normal',
        placeholder='Enter Text',
        onEnter=null,
        onChange=null,
        value='',
        buttonText='text',
        onClick=null,
        onFocus=null,
        onBlur=null,
        onClickDelete=null,
        disabled=false,
        buttonDisabled=true,
        textStyle=null,
        wrapperStyle=null
    } = props;

    const [isFocus, setIsFocus] = useState(false);
    const [textFieldWord, setTextFieldWord] = useState(value);

    const element = useRef();

    const onFocusHandler = (e) => {
        e.preventDefault();
        setIsFocus(true);
        if(onFocus && typeof onFocus === 'function'){
            onFocus();
        }
        console.log('test');
    }

    const onBlurHandler = (e) => {
        setIsFocus(false);
        if(onBlur && typeof onBlur === 'function'){
            onBlur();
        }
        console.log('blur');
    }

    const onChangeHandler = e => {
        setTextFieldWord(e.target.value);
        if(onChange && typeof onChange === 'function'){
            onChange(e.target.value);
        }
    }

    const onClickDeleteHandler = e => {
        if(onClickDelete && typeof onClickDelete === 'function'){
            onClickDelete();
        }
    }

    useEffect(()=>{
        setTextFieldWord(value);
    },[value])

    useEffect(()=>{
        if(disabled){
            if(type==='invite') element.current.disabled = true;
        }else{
            if(type==='invite') element.current.disabled = false;
        }
    },[disabled])

    return(
        <TextFieldWrap size={size} isFocus={isFocus} type={type}>
            <Input
                size={size}
                type={type}
                value={textFieldWord}
                onChange={(e) => onChangeHandler(e)}
                placeholder={placeholder}
                onBlur={e=>onBlurHandler(e)}
                onFocus={onFocusHandler}
                ref={element}
            />
            {
                type === 'invite' ?
                    (
                        value!=='' ?
                            <IconButton
                                width={'18px'}
                                height={'18px'}
                                src={DeleteIcon}
                                onClick={onClickDeleteHandler}
                            /> :
                            <div style={{width : '18px', height : '18px'}}></div>
                    ) : null
            }
            <Button
                shape={'circle'}
                onClick={onClick}
                type={'mobile'}
                size={'s'}
                width={'81px'}
                height={'36px'}
                wrapperStyle={{...wrapperStyle}}
                textStyle={{fontSize: '12px', ...textStyle}}
                disabled={buttonDisabled}>{buttonText}</Button>
        </TextFieldWrap>
    )
}

/**
 *
 * props : size, type, placeholder, onEnter
 */
TextField.defaultProps = {
    size : 's',
    type : 'normal', // normal, invite
    placeholder :'Enter Text',
    onEnter : null,
    onChange : null,
    value : '',
    buttonText : 'text',
    onClick : null,
    onClickDelete : null,
    buttonDisabled : true
}
export default TextField;