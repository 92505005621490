import {
    InviteMenu,
    InviteMenuButton, InviteMenuWrap,
    InvitingUserCompany,
    InvitingUserEmail,
    InvitingUserInfo, InvitingUserInfoWrap,
    InvitingUserName,
    InvitingUserWrap
} from "./InvitingUserStyles";
import {useStores} from "../../../stores";
import {useLayoutEffect, useRef, useState} from "react";
import {IconButton, List} from "./index";
import MoreIcon from "../../../resources/icons/more.svg";

const InvitingUser = (props) => {

    const {store} = useStores();
    const {toastPopupStore, modalToastPopupStore, eventStore} = store;
    const {user, groupId, onCancel, onReInvite} = props;

    // 메뉴 오픈
    const [openMenu, setOpenMenu] = useState(false);

    // wrapper ref
    const wrapRef = useRef(null);

    // menu ref
    const menuRef = useRef(null);

    // user info wrap ref
    const infoWrapRef = useRef(null);

    // 초대중인 사용자 메뉴
    const menuItems = [
        // 취소 시 팝업 창 open,
        {id:0, nameText:'초대 취소', onClick : (e)=> onCancelHandler()},
        {id:1, nameText:'초대장 재전송', onClick : (e)=> onReInviteHandler()}
    ];

    const e = eventStore.e;

    // 댓글 메뉴 오픈 함수
    const openInviteMenu = (e) => {
        setOpenMenu(!openMenu);
    }

    // 리스트 blur callback
    const onBlurMenu = (e) => {
        setOpenMenu(false);
    }

    // toggel menu
    const toggleMenu = () => {
        // console.log(wrapRef.current.getBoundingClientRect());
        // console.log(menuRef.current.getBoundingClientRect());
        // menuRef.current.style.top =
        //     wrapRef.current.getBoundingClientRect().top+
        //     wrapRef.current.getBoundingClientRect().height + 'px';
        // menuRef.current.style.right =
        //     (wrapRef.current.getBoundingClientRect().right) -
        //     (wrapRef.current.getBoundingClientRect().width) + 'px';
        setOpenMenu(!openMenu);
    }

    // 초대 취소 팝업 오픈
    const openCancelInvitePopup = (e) => {
        e.preventDefault();
        modalToastPopupStore.initDefault(
            'alert',
            `${user.name}님에게 보낸 초대를 취소하시겠습니까?`,
            '네',
            '아니요',
            onCancelHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 재초대 팝업 오픈
    const openReInvitePopup = (e) => {
        e.preventDefault();
        modalToastPopupStore.initDefault(
            'alert',
            `${user.name}님에게 초대장을 재전송하시겠습니까?`,
            '네',
            '아니요',
            onReInviteHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // 초대 취소 핸들러
    const onCancelHandler = async () => {
        // // @todo 초대 취소 callback
        // if(!modalToastPopupStore.isCancel) return;
        // await receiveResponse({userId : user?.userId ?? user?.account},`groups/${groupId}/cancel-invite`,'patch',{'Content-Type': 'multipart/form-data'})
        //     .then(res=>{
        //         if(res.status===200){
        //             if(res.data){
        //                 if(onCancel && typeof onCancel === 'function') onCancel();
        //                 toastPopupStore.openToastPopup(`${user?.name}님에게 보낸 초대를 취소하였습니다.`);
        //             }
        //         }
        //     })
        if(onCancel && typeof onCancel === 'function') onCancel();
    }

    // 초대장 재전송 핸들러
    const onReInviteHandler = async () => {
        // if(!modalToastPopupStore.isCancel) return;
        // await receiveResponse({userId : user?.userId ?? user?.account},`groups/${groupId}/invite`,'patch',{'Content-Type': 'multipart/form-data'})
        //     .then(res=>{
        //         if(res.status===200){
        //             if(onReInvite && typeof onReInvite === 'function') onReInvite();
        //             toastPopupStore.openToastPopup(`${user?.name}님에게 다시 초대장을 보냈습니다.`);
        //         }
        //     })
        if(onReInvite && typeof onReInvite === 'function') onReInvite();
    }

    // invitingUsers 스크롤 막기 (InvitingModal에)
    const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    useLayoutEffect(()=>{
        if(openMenu){
            menuRef.current.style.top =
                wrapRef.current.getBoundingClientRect().top+
                wrapRef.current.getBoundingClientRect().height + 'px';
            menuRef.current.style.right =
                (wrapRef.current.getBoundingClientRect().right) -
                (wrapRef.current.getBoundingClientRect().width) + 'px';

            e.emit('invitingUsers-scroll','prevent');
        }else{
            e.emit('invitingUsers-scroll','move');
        }
    },[openMenu]);


    return(
        <InvitingUserWrap ref={wrapRef}>
            <InvitingUserInfoWrap ref={infoWrapRef}>
                <InvitingUserEmail>{user.mail}</InvitingUserEmail>
                <InvitingUserInfo>
                    <InvitingUserName>{`${user?.name} ${user?.titleName}`}</InvitingUserName>
                    <InvitingUserCompany>{`${user?.companyName}`}</InvitingUserCompany>
                </InvitingUserInfo>
            </InvitingUserInfoWrap>
            <InviteMenuButton>
                {/*<IconButton src={MoreIcon} width={'24px'} height={'24px'} onClick={openInviteMenu}/>*/}
                <IconButton src={MoreIcon} width={'24px'} height={'24px'} onClick={toggleMenu}/>
            </InviteMenuButton>
            {
                openMenu ?
                    <InviteMenuWrap ref={menuRef} className={openMenu ? 'open' : 'close'}>
                        <InviteMenu>
                            <List
                                items={menuItems}
                                onBlur={onBlurMenu}
                                type={'thin'}
                            />
                        </InviteMenu>
                    </InviteMenuWrap> : null
            }
            {/*<InviteMenuWrap ref={menuRef} className={openMenu ? 'open' : 'close'}>*/}
            {/*    <InviteMenu>*/}
            {/*        <List*/}
            {/*            items={menuItems}*/}
            {/*            onBlur={onBlurMenu}*/}
            {/*            type={'thin'}*/}
            {/*        />*/}
            {/*    </InviteMenu>*/}
            {/*</InviteMenuWrap>*/}
        </InvitingUserWrap>
    )
}

InvitingUser.defaultProps = {
    groupId : '',
    user : null,
    onCancel : null,
    onReInvite : null
}

export default InvitingUser;