import env from "../constants/env";
import {t} from "i18next";

const moment = require("moment");

export const getDateFrom = (date) => {

    if(moment().isSame(date,"day")){
        return moment(date).format(`${t('TODAY')} HH:mm`);
    }else{
        return moment(date).format('YYYY.MM.DD HH:mm');
    }
}

export const getDateFromNoTime = (date) => {

    if(moment().isSame(date,"day")){
        return moment(date).format(`${t('TODAY')} HH:mm`);
    }else{
        return moment(date).format('YYYY.MM.DD');
    }
}

export const getTimeFrom = (date) => {
    return moment(date).format('HH:mm');
}

export const getOnlyDateFrom = (date) => {
    return moment(date).format('YYYY.MM.DD');
}

export const getISOFormat = (date = new Date()) => {
    return new Date(date - new Date().getTimezoneOffset() * 60000).toISOString();
}

export const timeForToday = (value) => {
    const today = new Date();
    const timeValue = new Date(value);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if (betweenTime < 1) return t('JUST_BEFORE');
    if (betweenTime < 60) {
        // return `${betweenTime}분 전`;
        return t('MINUTES_BEFORE', {minutes : betweenTime })
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
        // return `${betweenTimeHour}시간 전`;
        return t('HOURS_BEFORE', {hours : betweenTimeHour })
    }

    // const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    // if (betweenTimeDay < 7) {
    //     return `${betweenTimeDay}일전`;
    // }

    return moment(value).format('YYYY.MM.DD');

}

export const validateNicknameChangeDate = (date) => {

    if(!date) return true;

    const now = moment();
    const changeDate = moment(date);

    if(now.diff(changeDate,'days') < env.changeNicknameDate) return false;
    return true;
}

export const getDDayFromDate = (date) => {

    if(!date) return Infinity;
    const now = moment();
    const closeDate = moment(date);
    return Math.ceil(moment.duration(closeDate.diff(now)).asDays());
    // return now.diff(now,'d');
}