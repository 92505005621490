exports.default = {
    // api production url
    // API_URL: 'http://localhost:3100/production'

    API_URL: 'https://m-macalon.kolon.com/api/',
    THUMBNAIL_URL : 'https://m-macalon.kolon.com/api/files/thumb/',
    FILE_URL : 'https://m-macalon.kolon.com/api/files/file/',
    IMG_URL : 'https://m-macalon.kolon.com/api/imgs/',
    // 프로필 사진, 그룹 대표 이미지 썸네일 용
    IMG_THUMBNAIL_URL : 'https://m-macalon.kolon.com/api/imgs/thumb/',
};