import {
    ProfileBottomText,
    ProfileBottomWrap,
    ProfileMenus,
    ProfileMenusTitle,
    ProfileMenusWrap,
    ProfileSettingCompany,
    ProfileSettingContainer,
    ProfileSettingContainerWrap,
    ProfileSettingImage,
    ProfileSettingImageEditIcon,
    ProfileSettingImageWrap,
    ProfileSettingNickname,
    ProfileSettingNicknameWrap,
    ProfileSettingState,
    ProfileSettingStateCnt,
    ProfileSettingStateCntWrap,
    ProfileSettingStateText,
    ProfileSettingStateWrap,
    ProfileSettingStickersWrap,
    ProfileSettingUserInfo,
    ProfileSettingUserInfoWrap,
    RepStickerCriteria,
    RepStickerDesc,
    RepStickerInfoWrap,
    RepStickerWrap,
    StickerImg,
    StickerImgWrap,
    StickerInfoName,
    StickerInfoWrap,
    StickerLockImg,
    StickerLockWrap,
    StickerRepTxt,
    StickerRepWrap
} from "./ProfileSettingStyles";

import routes from "../../../constants/routes.json";
import isEmpty from "is-empty";

import {IconButton} from "../global";
import SettingIcon from "../../../resources/icons/profile_setting.svg"
import {useStores} from "../../../stores";
import {ServerConfig} from "../../../commons/config";
import DefaultImg from "../../../resources/icons/profile.png";
import EditIcon from "../../../resources/icons/profile_modify.svg";
import arrayImg from "../../../resources/mImages/group_invitation_arr.png";
import {observer} from "mobx-react";
import {stringToCost} from "../../../utils/textHandler";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import LockFilledIcon from "../../../resources/icons/lock_filled.svg";
import {useTranslation} from "react-i18next";
import {ProfileMenu, ProfileMenuImg} from "../../../pages/profile/ProfileStyles";
import {appWebViewSettingEvent} from "../../../utils/appBridgeEvnet";

const ProfileSetting = (props) => {

    const {store} = useStores();
    const {userStore, modalPopupStore, authStore} = store;
    const isAppReady = authStore.isAppReady;
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();

    const {title} = props;

    // menus
    const menus = [
        {name: '작성 글', type: 'items'},
        {name: '작성 댓글', type: 'comment'},
        {name: '즐겨 찾는 글', type: 'bookmark'},
        {name: '내 그룹', type: 'myGroups'}
    ]

    // 선택된 tab
    const [selected, setSelected] = useState(0);

    // 대표 스티커
    const [repIdx, setRepIdx] = useState(null)


    /******************************************************************************************************************/
    /**
     * 이벤트
     * @param e
     */
    
    
    // 프로필 이미지 수정 창 오픈
    const onOpenEditProfileModal = (e) => {
        e.preventDefault();
        //모달 기본 설정
        modalPopupStore.initAdd(
            'p',null,null,()=>{},()=>{}
        );
    }

    // 닉네임 수정 창 오픈
    const onOpenEditNicknameModal = (e) => {
        e.preventDefault();
        //모달 기본 설정
        modalPopupStore.initAdd(
            'n',null,null,()=>{},()=>{}
        );
    }

    // 스티커 디테일 모달 열기
    const openStickerDetailModal = (stickerId) => {
        modalPopupStore.initAdd(
            'sv',null,[stickerId],()=>{},()=>{}
        );
        modalPopupStore.openModalPopup();
    }

    // 언어 설정 모달 열기
    // 커버 이미지 수정 클릭시 모달 열기
    const openLanguageModal = (stickerId) => {
        modalPopupStore.initAdd(
            'ls',null,null,()=>{},()=>{}
        );
        modalPopupStore.openModalPopup();
    }

    // 메뉴 클릭
    const onClickMenu = (index) => {
        setSelected(index);
        history.push(`${routes.PROFILE}/${menus[index].type}`);
    }

    // 스티커 수정 창 오픈
    const onOpenEditStickerModal = (e) => {
        e.preventDefault();
        //모달 기본 설정
        modalPopupStore.initAdd(
            's',null,null,()=>{},()=>{}
        );
    }

    // 로그아웃 설정 페이지 이벤트
    const onSettingAppEvent = () => {
        //앱 이벤트
        appWebViewSettingEvent(isAppReady);
    }

    /******************************************************************************************************************/
    /**
     * 컴포넌트
     * @param e
     */

    // 왼쪽 메뉴 생성
    const createMenus = () => {
        return menus.map((menu,index)=>
            <ProfileMenu onClick={e=>onClickMenu(index)}>
                {menu.name}
                <ProfileMenuImg src={arrayImg}/>
            </ProfileMenu>
        )
    }

    //스티커 생성
    const createStickers = () => {
        if(isEmpty(userStore.stickers)) return;
        return userStore.stickers.map(sticker=>(
            <StickerInfoWrap key={`profile-setting-sticker-${sticker.stickerId}`}>
                <StickerImgWrap onClick={sticker.owned ? e=>openStickerDetailModal(sticker.stickerId) : null}>
                    <StickerImg src={`${ServerConfig.default.IMG_URL}${sticker.imgId}`} />
                    {sticker.represent ? <StickerRepWrap><StickerRepTxt children={'대표'}/></StickerRepWrap> : null}
                    {!sticker.owned ? <StickerLockWrap><StickerLockImg src={LockFilledIcon}/></StickerLockWrap> : null}
                </StickerImgWrap>
                <StickerInfoName>{sticker.nameText}</StickerInfoName>
            </StickerInfoWrap>
        ))
    }

    /******************************************************************************************************************/
    /**
     * useEffect
     * @param e
     */


    // 메뉴 찾아서 선택
    useEffect(()=>{
        let index = menus.findIndex((menu)=> location.pathname.includes(menu.type));
        setSelected(index);
    },[location.pathname]);

    // 대표 스티커 설정
    useEffect(()=>{
        if(!isEmpty(userStore.stickers)){
            const idx = userStore.stickers.findIndex(sticker => sticker.represent);
            if(idx!==-1) setRepIdx(idx);
        }
    },[userStore.stickers]);


    /******************************************************************************************************************/
    /**
     * render
     * @param e
     */


    return(
        <ProfileSettingContainerWrap>
            <ProfileSettingContainer>
                {/* 이미지, 닉네임, 회사이름 부분*/}
                <ProfileSettingUserInfoWrap>
                    {/* 프로필 사진 보이는 부분 */}
                    <ProfileSettingImageWrap>
                        <ProfileSettingImage
                            src={userStore.user.imgId && userStore.user.nickName !== '' ?
                                `${ServerConfig.default.IMG_URL}${userStore.user.imgId}` :
                                DefaultImg}
                        />
                        {/* 프로필 사진 변경 아이콘 */}
                        <ProfileSettingImageEditIcon src={SettingIcon} onClick={onOpenEditProfileModal}/>
                    </ProfileSettingImageWrap>
                    {/* 닉네임, 회사 부분 */}
                    <ProfileSettingUserInfo>
                        <ProfileSettingNicknameWrap>
                            <ProfileSettingNickname>{userStore.user.nickName === '' ? t('EXIT_NICKNAME') : userStore.user.nickName}</ProfileSettingNickname>
                            {/* 닉네임 변경 아이콘 */}
                            <IconButton src={EditIcon} width={'22px'} height={'22px'} onClick={onOpenEditNicknameModal}/>
                        </ProfileSettingNicknameWrap>
                        <ProfileSettingCompany>{userStore.user.nickName === '' ? '' : userStore.user.companyName}</ProfileSettingCompany>
                    </ProfileSettingUserInfo>
                    {/*테이블 (작성글, 작성댓글, 받은 좋아요) */}
                    <ProfileSettingStateWrap>
                        <ProfileSettingState>
                            <ProfileSettingStateText>{'작성 글'}</ProfileSettingStateText>
                            <ProfileSettingStateCntWrap onClick={e=>history.push(`${routes.PROFILE}/items`)}>
                                <ProfileSettingStateCnt>{stringToCost(userStore.userState.itemCount) || 0}</ProfileSettingStateCnt>
                            </ProfileSettingStateCntWrap>
                        </ProfileSettingState>
                        <ProfileSettingState>
                            <ProfileSettingStateText>{'작성 댓글'}</ProfileSettingStateText>
                            <ProfileSettingStateCntWrap onClick={e=>history.push(`${routes.PROFILE}/comment`)}>
                                <ProfileSettingStateCnt>{stringToCost(userStore.userState.commentCount) || 0}</ProfileSettingStateCnt>
                            </ProfileSettingStateCntWrap>
                        </ProfileSettingState>
                        <ProfileSettingState>
                            <ProfileSettingStateText>{'받은 좋아요'}</ProfileSettingStateText>
                            <ProfileSettingStateCntWrap>
                                <ProfileSettingStateCnt last = {true} >{stringToCost(userStore.userState.likesCount) || 0}</ProfileSettingStateCnt>
                            </ProfileSettingStateCntWrap>
                        </ProfileSettingState>
                    </ProfileSettingStateWrap>
                    {/*스티커 목록 */}
                    <ProfileSettingStickersWrap>
                        {/* 대표 스티커 */}
                        <RepStickerWrap onClick={onOpenEditStickerModal}>
                            <StickerImgWrap className={'rep'}>
                                {
                                    repIdx !== null ?
                                        <StickerImg src={`${ServerConfig.default.IMG_URL}${userStore.stickers[repIdx]?.imgId}`}/>
                                        : null
                                }
                            </StickerImgWrap>
                            <RepStickerInfoWrap>
                                <RepStickerDesc>{userStore.stickers[repIdx]?.nameText}</RepStickerDesc>
                                <RepStickerCriteria>{userStore.stickers[repIdx]?.descriptionText}</RepStickerCriteria>
                            </RepStickerInfoWrap>
                        </RepStickerWrap>
                        {/*/!* 스티커 목록 *!/*/}
                        {/*<ProfileSettingStickers>*/}
                        {/*    {createStickers()}*/}
                        {/*</ProfileSettingStickers>*/}
                    </ProfileSettingStickersWrap>
                </ProfileSettingUserInfoWrap>

                {/* 내 활동 선택*/}
                <ProfileMenusWrap>
                    <ProfileMenusTitle>{'내 활동'}</ProfileMenusTitle>
                    <ProfileMenus>
                        {createMenus()}
                    </ProfileMenus>
                </ProfileMenusWrap>

                {/*로그아웃*/}
                <ProfileBottomWrap onClick={onSettingAppEvent}>
                    <ProfileBottomText>{'로그아웃'}</ProfileBottomText>
                </ProfileBottomWrap>

            </ProfileSettingContainer>
        </ProfileSettingContainerWrap>
    )
}

ProfileSetting.defaultProps = {

}

export default observer(ProfileSetting);