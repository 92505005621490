import styled from "styled-components";

export const GroupItemContainer = styled.div`
    background: #F7F9FB;
    width: 100%;
    min-height : 100%;
    display : flex;
    flex-direction : column;
    
    &.isLoading {
        height: 100%;
    }
`

export const GroupItemLoadingContainer = styled.div`
    background: #F7F9FB;
    width: 100%;
    height: 100%;
`

export const GroupItemPreBtn = styled.div`
    position: absolute;
    top: 10px; 
    left: 16px;
    z-index : 1;
`

export const GroupItemPreBtnImg = styled.img`
`

export const SearchWrap = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 1;
    padding-right: 16px;
    width: 100%;
`

export const SearchBarText = styled.h2`
    font-weight: 700; 
    font-size: 16px; 
    line-height: 23px; 
    text-align: center;
    padding: 10px 0px 10px 0px;
    position : relative;
`

export const SearchBarVisible = styled.div` 
     &.hidden { display:none; }
     display: flex;
     width: 100%;
     padding: 10px 0px 16px 16px;
`

export const SearchBarCollectWrap = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
`

export const SearchBarWrap = styled.div`
    float: right;
`

export const SearchBarBookmark = styled.div`

    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-sizing: border-box;
    background: #FFFFFF;
    /* gray_Stroke */

    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 24px;
    height: 24px;

    background : ${({disabled})=>{
        if(disabled){
            return '#F2F4F6;';
        }else {
            return '#FFFFFF;';
        }
    }};
    
    color : ${({disabled})=>{
        if(disabled){
            return '#AEB7C0;';
        }
    }};
    
`

export const GroupItemListContainer = styled.div`
    padding: 0px 0px 30px 16px;
    display: flex;
    flex-direction: column;
    background: #F7F9FB;

    flex-grow : 1;
    
    // padding: 40px 0px 40px 0px;
    // gap: 40px 20px;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
`

export const GroupItemsNoResultWrap = styled.div`
    text-align : center;
    width : 100%;
    height : 100%;
    display : flex;
    flex-grow : 1;
    align-items : center;
    justify-content : center;
    
    
`
