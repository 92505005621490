import React, {useEffect, useRef, useState} from 'react'
import {useStores} from "../stores";
import {useHistory, useLocation} from "react-router-dom";

import jwtDecode from "jwt-decode";
import {getCookieValue, validateJwt} from "../utils/ssoHandler";
import isEmpty from 'is-empty';

import {Body, Container} from './LayoutStyles';
import FooterNav from './FooterNav';
import {Modal, ScrollBox, ToastPopup} from "../components/presentationals/global";
import ModalToastPopup from "../components/presentationals/global/ModalToastPopup";
import ModalDetail from "../components/presentationals/global/ModalDetail";
import ModalDetailParent from "../components/presentationals/global/ModalDetailParent";
import routes from "../constants/routes.json";
import {observer} from "mobx-react";
import {throttle} from "lodash";
import env from "../constants/env";
import {getQueryStringParams} from "../utils/appBridgeEvnet";
import {receiveResponse} from "../utils/api";

const Layout = ({children}) => {

    const {pathname} = useLocation();
    const {store} = useStores();
    const {userStore, topicStore, interestStore, itemStore, modalPopupStore, defaultImageStore} = store;
    const [newStickerIds, setNewStickerIds] = useState([]);
    const history = useHistory();

    // (소통해요) 글쓰기 아이콘 스크롤 요소
    const scrollRef = useRef(null);

    const [scrollTop, setScrollTop] = useState(0);
    const [scrollDirection, setScrollDirection] = useState('top');
    const prevScrollTop = useRef(0);
    const prevHomeSearchWrapState = useRef(false);

    // 스크롤 방향에 따라서 헤더, top버튼 사라지기
    const handleScroll = throttle(() => {

        const searchWrap = document.getElementById('search_wrap');
        if(searchWrap){
            const searchWrap = document.getElementById('search_wrap');
            const currentHomeSearchWrapState = searchWrap.classList.contains('show');
            const currentScrollTop = scrollRef.current.scrollTop;

            if(prevHomeSearchWrapState.current===currentHomeSearchWrapState){
                setScrollTop(currentScrollTop);
                setScrollDirection(currentScrollTop <= 0 ? 'top' : prevScrollTop.current < currentScrollTop ? 'down' : 'up');
            }
            prevScrollTop.current = currentScrollTop;
            prevHomeSearchWrapState.current = currentHomeSearchWrapState;
        }else{
            const currentScrollTop = scrollRef.current.scrollTop;
            setScrollTop(currentScrollTop);
            setScrollDirection(currentScrollTop <= 0 ? 'top' : prevScrollTop.current < currentScrollTop ? 'down' : 'up');
            prevScrollTop.current = currentScrollTop;
        }
    }, 50); // throttle 시간 (단위: ms)

    /******************************************************************************************************************/


    /** 중요 스크롤 **/
    //pathname이 다를 경우만 스크롤 0 초기화
    useEffect(()=>{
        if (history.action === 'PUSH') {
            const el = scrollRef.current;
            if(el) el.scrollTop = 0;
        }

        if(scrollRef && scrollRef.current) {
            scrollRef.current.addEventListener('scroll',handleScroll);
        }

        /*푸시 알림 처리*/
        if (history.location.search) {
            const searchParamObject = getQueryStringParams(history.location.search);
            const notificationId = searchParamObject.notificationId;

            //notificationId 있을 경우
            if (notificationId) {
                // 읽음 처리
                receiveResponse({}, `notifications/${notificationId}/mark-as-read`, 'patch').then(res => {
                    if (res.status === 200) {
                        // //성공 (주소에 search값 없애기)
                        console.log('notification success');
                        // window.history.replaceState({}, null, pathname);
                    }
                });
            }
        }

        return ()=> {
            if(scrollRef && scrollRef.current) {
                scrollRef.current.removeEventListener('scroll',handleScroll);
            }
        }

    },[pathname]);

    useEffect(()=>{
        const refresh = getCookieValue(env.refreshToken);
        if(validateJwt(refresh)){
            (async function(){
                defaultImageStore.init();
                userStore.init(jwtDecode(refresh).userId);
                topicStore.init();
                interestStore.init();
                itemStore.init();
            })();
        }

    },[]);

    // 페이지에 접근한 경우, 새로운 스티커 있는지 확인, 있으면 stickerDetailModal 띄우기
    useEffect(() => {
        setNewStickerIds(userStore.stickers.filter(s => s.owned && !s.confirm).map(s => s.stickerId) ?? []);
    }, [userStore.stickers]);

    useEffect(()=>{
        if (pathname === routes.FAMILYEVENT) return;
        if(!isEmpty(newStickerIds)){
            modalPopupStore.initAdd(
                'sv',null,newStickerIds,()=>{},() => setNewStickerIds([])
            );
        }
    },[newStickerIds])

    useEffect(()=>{
        /**
         * up으로 바뀔 경우, home_search_wrap의 position을 relative로 바꿈
         */
        const searchWrap = document.getElementById('search_wrap');
        const modalWrite = document.getElementById('modalWrite');

        if(!scrollDirection) return;

        if(scrollDirection === 'top'){
            if(searchWrap) searchWrap?.classList?.remove('down');
            if(modalWrite) modalWrite?.classList?.add('hide');
            return;
        }

        if(scrollDirection === 'down') {
            if(searchWrap) searchWrap?.classList?.add('down');
            if(modalWrite) modalWrite?.classList?.add('hide');
        } else {
            if(searchWrap) searchWrap?.classList?.remove('down');
            if(modalWrite) modalWrite?.classList?.remove('hide');
        }

    },[scrollDirection]);
    /******************************************************************************************************************/


    return (
        <Container>

            {/**  <<<<<<<<<<<<<<<<<<<<<<<<<메인>>>>>>>>>>>>>>>>>>>>>>>>>>  **/}
            {/** 메인 컴포넌트  **/}
            <Body>
                <ScrollBox id={'scroll-box'} ref={scrollRef}>
                    {children}
                </ScrollBox>
            </Body>

            {/**  <<<<<<<<<<<<<<<<<<<<<<<<<Footer Nav>>>>>>>>>>>>>>>>>>>>>>>>>>  **/}
            {/* Web일경우 숨기기 */}
            {pathname === routes.FAMILYEVENT ? null : <FooterNav/>}



            {/**  <<<<<<<<<<<<<<<<<<<<<<<<<기타>>>>>>>>>>>>>>>>>>>>>>>>>>  **/}
            {/* 상위 1번째 */}
            {/** 모달 공지 팝업 컴포넌트 **/}
            <ModalDetailParent/>

            {/* 상위 2번째 */}
            {/** 모달 디테일 팝업 컴포넌트 **/}
            <ModalDetail/>

            {/* 상위 3번째 */}
            {/** 모달 컴포넌트  **/}
            <Modal/>

            {/* 상위 4번째 */}
            {/** 모달 토스트 팝업 컴포넌트 **/}
            <ModalToastPopup/>

            {/** 토스트팝업 컴포넌트  **/}
            <ToastPopup/>

        </Container>
    )
}

export default observer(Layout);