import {
    ButtonsWrap,
    GroupCloseSettingContainer,
    GroupCloseSettingDate,
    GroupCloseSettingDateWrap,
    GroupCloseSettingItem,
    GroupCloseSettingItemDesc,
    GroupCloseSettingItemTitle,
    GroupCloseSettingItemValue
} from "./GroupCloseSettingStyles";
import {Button} from "../global";
import {receiveResponse} from "../../../utils/api";
import {getDDayFromDate} from "../../../utils/dateHandler";
import env from "../../../constants/env";
import {useStores} from "../../../stores";
import {useTranslation} from "react-i18next";

const GroupCloseSetting = (props) => {

    const {t} = useTranslation();
    const {store} = useStores();
    const {modalToastPopupStore, toastPopupStore} = store;
    const {group, onSubmit} = props;

    /**
     * ======================================================================
     * API
     *
     */
    // 그룹 폐쇄 신청
    const onCloseGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse(
            {},
            `groups/${group.groupId}/close`,
            'patch',
            {'Content-Type': 'multipart/form-data'}
        ).then(res=>{
            if(res.status===200){
                if(res.data){
                    onSubmitHandler();
                    toastPopupStore.openToastPopup(t('COMPLETE_APPLY_CLOSE_GROUP'));
                }
            }
        })
        .catch(e=>{
            console.log(e);
        })
    }

    // 그룹 폐쇄 보류
    const onCancelCloseGroup = async () => {
        if(!modalToastPopupStore.isCancel) return;
        await receiveResponse(
            {},
            `groups/${group.groupId}/cancel-close`,
            'patch',
            {'Content-Type': 'multipart/form-data'}
        ).then(res=>{
                if(res.status===200){
                    if(res.data){
                        onSubmitHandler();
                        toastPopupStore.openToastPopup(t('CANCEL_APPLY_CLOSE_GROUP'));
                    }
                }
        })
        .catch(e=>{
            console.log(e);
        })
    }

    /**
     * API END
     * ======================================================================
     */

    /**
     * =======================================================================
     * Open Modal
     */
        // 일반 멤버로 변경 modal 띄우기
    const openCloseGroupModal = () => {
            modalToastPopupStore.initGuide(
                'guide',
                t('GROUP_CLOSE_POPUP_TITLE'),
                t('GROUP_CLOSE_POPUP_GUIDE'),
                '',
                env.modalOkButtonText,
                env.modalCanclButtonText,
                onCloseGroup
            );
            modalToastPopupStore.openModalPopup();
        }

    // 그룹장 위임 modal 띄우기
    const openCancelCloseGroupModal = () => {
        modalToastPopupStore.initDefault(
            'alert',
            t('GROUP_CLOSE_CANCEL_POPUP_TITLE'),
            '네',
            '아니요',
            onCancelCloseGroup
        );
        modalToastPopupStore.openModalPopup();
    }

    /**
     * Open Modal END
     * =======================================================================
     *
     */

    const onSubmitHandler = () =>{
        if(onSubmit && typeof onSubmit === 'function') onSubmit();
    }

    return(
        <GroupCloseSettingContainer>
            <GroupCloseSettingItem>
                <GroupCloseSettingItemValue>
                    <GroupCloseSettingItemTitle>{t("GROUP_SETTING_CLOSE_TITLE")}</GroupCloseSettingItemTitle>
                    <GroupCloseSettingItemDesc>{t('GROUP_SETTING_CLOSE_DESC1')}</GroupCloseSettingItemDesc>
                    <GroupCloseSettingItemDesc>{t('GROUP_SETTING_CLOSE_DESC2')}</GroupCloseSettingItemDesc>
                    <GroupCloseSettingItemDesc>{t('GROUP_SETTING_CLOSE_DESC3')}</GroupCloseSettingItemDesc>
                </GroupCloseSettingItemValue>
            </GroupCloseSettingItem>
            <ButtonsWrap>
                <Button
                    height={'47px'}
                    width={group?.closingDate ? '201px' : '135px'}
                    shape={'circle'}
                    type={'secondary'}
                    textStyle={{fontSize: '16px', lineHeight: '23px'}}
                    onClick={group?.closingDate ? openCancelCloseGroupModal : openCloseGroupModal}
                >{
                    group?.closingDate ? <GroupCloseSettingDateWrap>
                            {t('CANCEL_CLOSE_GROUP')}
                            <GroupCloseSettingDate>{`D-${getDDayFromDate(group?.closingDate)}`}</GroupCloseSettingDate>
                        </GroupCloseSettingDateWrap>
                    : t('CLOSE_GROUP')
                }
                </Button>
            </ButtonsWrap>
        </GroupCloseSettingContainer>
    )
}

GroupCloseSetting.defaultProps = {
    group : null,
    onSubmit : null
}

export default GroupCloseSetting;