import {observer} from "mobx-react";
import {Card, IconButton, NoResult, SearchField} from "../../components/presentationals/global";
import {
    GroupItemContainer,
    GroupItemListContainer,
    GroupItemPreBtn,
    GroupItemPreBtnImg,
    GroupItemsNoResultWrap,
    SearchBarBookmark,
    SearchBarCollectWrap,
    SearchBarText,
    SearchBarVisible,
    SearchWrap
} from "./GroupItemsStyles";
import React, {useContext, useEffect, useLayoutEffect} from "react";
import isEmpty from "is-empty";
import {ThreeDots} from "react-loader-spinner";
import env from '../../constants/env';
import BookmarkFilledIcon from "../../resources/icons/bookmark_filled.svg";
import BookmarkIcon from "../../resources/mImages/bookmarkIcon.svg";
import BookmarkDisabledIcon from "../../resources/icons/bookmark_gray.svg";
import SearchPrevBtn from "../../resources/mImages/array_prev.svg";
import {BackHistoryContext} from "../../utils/backHistoryProvider";
import useBackControl from "../../utils/useBackControl";
import {SearchBarRight, SearchBarSearch} from "../groups/GroupsStyles";
import SearchImg from "../../resources/mImages/searchIcon.svg";
import SearchSelectedImg from "../../resources/mImages/searchSelectedIcon.svg";
import NoResultImg from "../../resources/icons/no_result.svg";
import NoPostImg from "../../resources/icons/no_post.svg";
import {useTranslation} from "react-i18next";
import {getDDayFromDate} from "../../utils/dateHandler";
import {useLocation} from "react-router-dom";
import {HomeSearchSubTitle, HomeSearchSubTitleWord} from "../home/HomeStyles";
import useDidMountEffect from "../../utils/useDidMountEffect";
import SearchDisabledImg from "../../resources/mImages/search_disabled.png";


const GroupItems = (props) => {

    const {t} = useTranslation();
    const {itemStore, history, modalDetailStore, topicStore, eventStore} = props;
    const location = useLocation();
    // event 처리 위한 object
    const e = eventStore.e;

    const backContext = useContext(BackHistoryContext).current;
    // 전 변수값 담아두기
    const { useRemState, useRemRef} = useBackControl('GroupItems');

    // group state
    const [groupItemsState, setGroupItemsState] = useRemState(null, 'groupItemsState');

    const [isLoading, setIsLoading] = useRemState(true, 'isLoading');
    const [total, setTotal] = useRemState(0, 'total');
    const [recommendMyGroupItems, setRecommendMyGroupItems] = useRemState(null, 'recommendMyGroupItems');

    // 돋보기 클릭 <모바일 추가>
    const [searchView, setSearchView] = useRemState(false, 'searchView');
    // 입력 검색어
    const [enterSearchWord, setEnterSearchWord] = useRemState(props.location?.state?.keyword, 'enterSearchWord');
    // 무한 스크롤 중복 방지
    const preventRef = useRemRef(false, 'preventRef');
    // 무한 스크롤 옵저버 요소
    const obsRef = useRemRef(null, 'obsRef');
    // 마지막 페이지 등록 여부
    const endRef = useRemRef(null, 'endRef');


    /******************************************************************************************************************/

    /**
     * 아이템 리스트
     * @param list
     * @param type
     * @returns {unknown[]}
     */
    const itemGroups = (list) => {
        return (list || []).map((item, index) => {
            return <Card id={index}
                         isVote={item.topicId === (topicStore.topics || []).filter(t=>t.name === 'topic.vote')[0]?.topicId}
                         key={`groups-all-${item.groupId}`}
                         isSearch={!isEmpty(groupItemsState.searchWord)}
                         type={'all'}
                         item={item}
                         highlight={isEmpty(groupItemsState.searchWord) ? '' : groupItemsState.searchWord}
                         onClick={()=>{onItemDetailPopupOpenEvent(item)}}
            />
        });
    }

    const noResult = () => {
        return (
            <NoResult
                src={!isEmpty(groupItemsState.searchWord) && recommendMyGroupItems.length === 0 ? NoResultImg : NoPostImg}
                title={!isEmpty(groupItemsState.searchWord) && recommendMyGroupItems.length === 0 ? t('NO_SEARCH_ITEMS') : t('NO_ITEMS')}
                subtitle={!isEmpty(groupItemsState.searchWord) && recommendMyGroupItems.length === 0 ? t('NO_SEARCH_ITEMS_SUBTITLE') : ''}
            />
        )
    }

    /******************************************************************************************************************/

    /**
     * 04. 함수
     *
     */

        // 추천 리스트
    const getRecommendMyGroupItems = (groupItemsState) => {

            if(!groupItemsState) return;

            // 1. 검색어
            const searchWord = groupItemsState?.searchWord;
            // 2. 북마크
            const checkBookmark = groupItemsState?.checkBookmark;
            // 3. 페이지
            const page = groupItemsState?.page;

            let params = {
                size: env.groupItemsContentSize,
                page: page
            }

            //검색어
            if (!isEmpty(searchWord)) {
                params = {...params, searchWord: searchWord}
            }

            // 북마크
            if (checkBookmark) params = {...params, bookmarkOnly: true};

            // 추천 나의 그룹 아이템
            itemStore.selectRecommendMyGroupItems(params, () => {
                if (page === 0) {
                    //02. 흥미
                    setRecommendMyGroupItems([...itemStore.recommendMyGroupItems.list]);
                } else {
                    //02. 흥미
                    setRecommendMyGroupItems([...recommendMyGroupItems].concat(itemStore.recommendMyGroupItems.list));
                }
                setTotal(itemStore.recommendMyGroupItems.total);
                setIsLoading(false);
                preventRef.current = true;
                endRef.current = itemStore.recommendMyGroupItems.end;
            });

        };

    // 내 그룹 새 글에서 전달 받은 state있으면 popup open
    const onOpenItem = (item) => {
        if(item?.itemId){
            modalDetailStore.init(
                'g',             //'c', 'g' 타입
                item.itemId           // 아이템Id
            );
        }
    }

    // <이벤트> 이벤트 리스너 등록 (게시글 삭제 이벤트)
    // const createEventListener = () => {
    //     e.removeAllListeners('card-update',()=>{});
    //     e.on('card-update', ()=>{
    //         getRecommendMyGroupItems(groupItemsState);
    //     });
    // }

    /******************************************************************************************************************/

    /**
     * 05. 이벤트 함수
     *
     */

    // 뒤로가기 이벤트
    const onBackHistoryClickEvent = (e) => {
        e.preventDefault();
        history.goBack();
    }


    // observer 핸들러 함수
    const obsHandler = ((entries) => {
            const target = entries[0];
            if (target.isIntersecting && preventRef.current) {
                if (endRef.current) return;
                preventRef.current = false;

                setGroupItemsState(groupItemsState => {
                    return {
                        ...groupItemsState,
                        page: groupItemsState.page + 1
                    }
                });

            }
        });

    // enter search word change 함수
    const onSearchWordChange = (value) => {
        setEnterSearchWord(value);
        if(!value){
            if(groupItemsState.searchWord){
                if (!isLoading) {
                    preventRef.current = false;
                    setIsLoading(true);
                    setGroupItemsState((groupItemsState) => {
                        return {
                            ...groupItemsState,
                            searchWord: '',
                            page: 0
                        }
                    });
                }
            }
        }
    }

    // search word 전부 지우기
    const onSearchWordDelete = (e) => {
        e.preventDefault();
        preventRef.current = false;

        setEnterSearchWord('');

        //로딩아닐경우만
        if (!isLoading) {

            setIsLoading(true);
            setGroupItemsState((groupItemsState) => {
                return {
                    ...groupItemsState,
                    searchWord: '',
                    page: 0
                }
            });
        }
    }

    // 검색 함수
    const onSearchWord = (searchWord) => {
        preventRef.current = false;
        setEnterSearchWord(searchWord);

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);
            setGroupItemsState((groupItemsState) => {
                return {
                    ...groupItemsState,
                    searchWord: searchWord,
                    checkBookmark: false,
                    page: 0
                }
            });
        }
    }

    // 북마크 클릭 이벤트
    const onToggleBookmark = (e) => {
        preventRef.current = false;
        e.preventDefault();

        //로딩아닐경우만
        if (!isLoading) {
            setIsLoading(true);

            setGroupItemsState((groupItemsState) => {
                return {
                    ...groupItemsState,
                    page: 0,
                    checkBookmark: !groupItemsState.checkBookmark,
                }
            });
        }
    }

    // 돋보기 클릭 이벤트
    const onSearchClickEvent = (e) => {
        e.preventDefault();
        if(groupItemsState?.searchWord) return;
        setSearchView(!searchView);
    }

    // 상세창 열기
    const onItemDetailPopupOpenEvent = (item) => {

        // 폐쇄된 그룹이나 강퇴당한 그룹일 경우 팝업 열지않기 추가
        if(!item.group) return;

        let _kickDate = item.group?.kickDate;
        let _closingDate = getDDayFromDate(item.group?.closingDate);

        if(!_kickDate && _closingDate >= 0) {
            onOpenItem(item);
        }

    }

    /******************************************************************************************************************/


    /**
     * 06. uesEffect
     *
     */

    // useEffect(() => {
    //     // 페이지 이동 백 아닐경우만
    //     /**
    //      * push일 경우, 여기서 이전 state로 한번 불러오는 것 같음
    //      */
    //     console.log(backContext.isBack,'$$$$$$$$$$$$$$$$$$$$$$$');
    //     if (!backContext.isBack) getRecommendMyGroupItems(groupItemsState);
    //     else backContext.isBack = false;
    // }, [groupItemsState]);

    useDidMountEffect(()=>{
        getRecommendMyGroupItems(groupItemsState);
    },[groupItemsState]);

    // /** 중요 **/
    // /*  데이터 유지 관리 떄문에 페이지 이동 시 홈이면 초기화 */
    // // 새로 페이지 왔을 경우는 초기화
    useLayoutEffect(()=>{
        /** 중요 **/
        /*  데이터 유지 관리 떄문에 페이지 이동 시 홈이면 초기화 */
        // 새로 페이지 왔을 경우는 초기화
        // 페이지 이동 백 아닐경우만
        if(backContext.isBack) backContext.isBack = false;

        if(groupItemsState===null){
            setGroupItemsState((groupItemsState)=>{
                return {
                    ...groupItemsState,
                    page: 0,
                    searchWord: '',
                    checkBookmark: false
                }
            });
        }else{
            if (history.action === 'PUSH' || history.action === 'POP') {
                preventRef.current = false;
                endRef.current = false;
                setEnterSearchWord('');
                setRecommendMyGroupItems([]);
                setIsLoading(true);
                setGroupItemsState((groupItemsState)=>{
                    return {
                        ...groupItemsState,
                        page: 0,
                        searchWord: '',
                        checkBookmark: false
                    }
                });
            }
            // else if (history.action === 'POP') {
            //     setGroupItemsState((groupItemsState)=>{
            //         return {
            //             ...groupItemsState
            //         }
            //     });
            // }
        }
    },[]);

    useEffect(() => {
        // 삭제에 대한 event 리스너 생성
        // createEventListener();
        // observer 생성
        const observer = new IntersectionObserver(obsHandler, {
            threshold: 0
        });
        // observer에 관찰 요소 등록
        if (obsRef.current) observer.observe(obsRef.current);
        return () => {
            // e.removeAllListeners('card-update',()=>{});
            observer.disconnect();
        };
    }, []);

    useEffect(()=>{
        if(obsRef && obsRef.current && !endRef.current && recommendMyGroupItems && recommendMyGroupItems?.length > 0){
            if(window.innerHeight > obsRef.current.getBoundingClientRect().y){
                setGroupItemsState((groupItemsState) => {
                    return {
                        ...groupItemsState,
                        page: groupItemsState.page + 1
                    }
                });
            }
        }
    },[recommendMyGroupItems])

    /******************************************************************************************************************/


    /**
     * 07. render
     *
     */

    /**
     * 1. 참여중인 그룹은 4개까지, 초과 시 더보기 버튼
     * 2. 추천 그룹은 무한 스크롤
     * 3. 마우스 hover 시 pin visible > 클릭 시 pin 등록
     * 4. pin은 최대 4개까지 > 이상 클릭시 토스트 팝업
     * 5.
     *
     */
    return (
        <>
            <GroupItemContainer className={isLoading ? 'isLoading' : ''}>

                {/* 검색 */}
                <SearchWrap>
                    <GroupItemPreBtn onClick={(e)=>{onBackHistoryClickEvent(e)}}>
                        <GroupItemPreBtnImg src={SearchPrevBtn}></GroupItemPreBtnImg>
                    </GroupItemPreBtn>
                    <SearchBarText>{t("GROUP_ALL_ITEMS")}</SearchBarText>
                    <SearchBarCollectWrap>
                        <SearchBarBookmark disabled={groupItemsState?.searchWord}>
                            <IconButton
                                width={'24px'}
                                height={'24px'}
                                src={
                                    groupItemsState?.searchWord ? BookmarkDisabledIcon :
                                        groupItemsState?.checkBookmark ? BookmarkFilledIcon : BookmarkIcon
                                }
                                onClick={groupItemsState?.searchWord === '' ? onToggleBookmark : null}
                            />
                        </SearchBarBookmark>
                        <SearchBarRight>
                            <SearchBarSearch selected={searchView} disabled={groupItemsState?.searchWord}>
                                <IconButton
                                    width={'20px'}
                                    height={'20px'}
                                    src={
                                        searchView ?
                                            groupItemsState?.searchWord ? SearchDisabledImg : SearchSelectedImg
                                            : SearchImg
                                    }
                                    onClick={onSearchClickEvent}
                                />
                            </SearchBarSearch>
                        </SearchBarRight>
                    </SearchBarCollectWrap>
                    {/* 검색 창 */}
                    <SearchBarVisible className={!searchView ? 'hidden' : null}>
                        <SearchField
                            placeholder={t('GROUP_ITEM_SEARCH_PLACEHOLDER')}
                            type={'GI'}
                            value={enterSearchWord}
                            onChange={onSearchWordChange}
                            onDelete={onSearchWordDelete}
                            onSearch={onSearchWord}
                        />
                    </SearchBarVisible>
                </SearchWrap>

                { isLoading ?
                        <ThreeDots
                            height={"10"}
                            wrapperStyle={{
                                width : '100%',
                                justifyContent : 'center',
                                height : '100%',
                                paddingTop : '30px'
                            }}
                            color={'#3A6AF6'}
                        /> :

                    <GroupItemListContainer>
                        {
                            !isEmpty(groupItemsState?.searchWord) ?
                                //홈서치 타이틀
                                <HomeSearchSubTitle>
                                    <HomeSearchSubTitleWord>{groupItemsState?.searchWord?.replaceAll(' ', '\u00A0')}</HomeSearchSubTitleWord>
                                    {` ${t('SEARCH_RESULT')} `}
                                    <HomeSearchSubTitleWord>{total}</HomeSearchSubTitleWord>
                                    {t('NUMBER_CASES')}
                                </HomeSearchSubTitle> : null
                        }
                        {
                            recommendMyGroupItems && recommendMyGroupItems?.length !== 0 ?
                                itemGroups(recommendMyGroupItems) :
                                <GroupItemsNoResultWrap>{noResult()}</GroupItemsNoResultWrap>
                        }
                    </GroupItemListContainer>
                }
                {/*/!*스크롤 감지*!/*/}
                <div ref={obsRef} style={{padding: '1px'}}></div>
            </GroupItemContainer>
        </>
    )
}

export default observer(GroupItems);