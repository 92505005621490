import styled from "styled-components";


export const ImgWrap = styled.div`
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 10px;
      overflow: hidden;
      flex-wrap : nowrap;
      
      &.default-bg {
        background-color: #F2F4F6;
      }
`

export const ImgContent = styled.img.attrs(props => ({
    src: props.src
}))`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    
      max-width: 100%;
      max-height: 100%;
`