import {
    InfoCompany,
    InfoNickname,
    ProfileContainer,
    ProfileImage,
    ProfileImageEditIcon,
    ProfileImageWrap,
    ProfileInfoRow,
    ProfileInfoRowSpan,
    ProfileInfoWrap
} from "./ProfileStyles";
import DefaultImg from '../../../resources/icons/profile.png';
import SettingIcon from '../../../resources/icons/profile_setting.svg';
import EditIcon from '../../../resources/icons/profile_modify.svg';
import Master from '../../../resources/icons/master.svg';
import AssistantIcon from "../../../resources/icons/assistant.svg";
import {ServerConfig} from "../../../commons/config";
import {IconButton} from "./index";
import {useStores} from "../../../stores";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";

const Profile = (props) => {

    const {store} = useStores();
    const {modalPopupStore, defaultImageStore} = store;
    const {t} = useTranslation();
    /**
     *
     * @type {string} master, assistant, profile-view, profile-edit, with-time, normal, invite
     */
    const {
        type='profile-view',
        onClick=null,
        src='',
        user={nickName: '코오롱닉네임', name : '이름', companyName : '코오롱베니트', userId : 'abc', titleName : '주임', imgId: undefined},
        time='방금',
        useThumb=true
    } = props;

    // 프로필 이미지 수정 창 오픈
    const onOpenEditProfileModal = (e) => {
        e.preventDefault();
        //모달 기본 설정
        modalPopupStore.initAdd(
            'p',null,null,()=>{},()=>{}
        );
    }

    // 닉네임 수정 창 오픈
    const onOpenEditNicknameModal = (e) => {
        e.preventDefault();
        //모달 기본 설정
        modalPopupStore.initAdd(
            'n',null,null,()=>{},()=>{}
        );
    }

    const onClickHandler = (e) => {

        if(type!=='profile-edit' && type!=='profile-view'){
            modalPopupStore.initAdd(
                'vp',null,{userId : user.userId},()=>{},()=>{}
            );
        }

        if(onClick && typeof onClick === 'function'){
            onClick(e);
        }
    };

    return(
        <>
            <ProfileContainer>

                <ProfileImageWrap type={type} onClick={type!=='profile-edit' && type!=='profile-view' ? onClickHandler : null}>
                    {
                        defaultImageStore.defaultUserImgs ?
                            <ProfileImage
                                src={
                                    user?.imgId === null || user?.nickName === '' ? DefaultImg :
                                        !useThumb ? `${ServerConfig.default.IMG_URL}${user.imgId}` :
                                            defaultImageStore.defaultUserImgs.findIndex(img => img === user.imgId) !== -1 ?
                                                `${ServerConfig.default.IMG_URL}${user.imgId}` :
                                                `${ServerConfig.default.IMG_THUMBNAIL_URL}${user.imgId}`
                                }
                            /> : null
                    }
                    {type==='profile-edit' ? <ProfileImageEditIcon src={SettingIcon} onClick={onOpenEditProfileModal}/> : null}
                </ProfileImageWrap>

                <ProfileInfoWrap type={type}>

                    <ProfileInfoRow>
                        <InfoNickname>
                            {type==='invite' ? `${user?.userId}@kolon.com` :
                                user?.nickName === '' ? t('EXIT_NICKNAME') : user?.nickName }
                        </InfoNickname>
                        {type==='master' ?
                            <IconButton src={Master} height={'12px'} /> :
                            type==='assistant' ?
                                <IconButton src={AssistantIcon} height={'12px'} />
                                : null}
                        {type==='profile-edit' ? <IconButton src={EditIcon} onClick={onOpenEditNicknameModal} width={'20px'} height={'20px'}/> : null}
                    </ProfileInfoRow>
                    {
                        user?.nickName === '' ? null :
                            <>
                                <ProfileInfoRowSpan/>
                                <InfoCompany>{user?.companyName}</InfoCompany>
                            </>
                    }
                </ProfileInfoWrap>
            </ProfileContainer>
        </>
    )
}

export default observer(Profile);