import {LoadingWrap} from "./LoadingStyles";
import {ThreeDots} from "react-loader-spinner";
const Loading = (props) => {

    const {isLoading} = props;

    return(
        <LoadingWrap>
            {
                isLoading ?
                    <ThreeDots
                        height={"10"}
                        wrapperStyle={{
                            width : '100%',
                            justifyContent : 'center',
                            alignItems : 'center',
                            height : '100%',
                            position : 'absolute',
                            zIndex : '6',
                            background : 'rgba(53,60,73,0.3)'
                        }}
                        color={'#3A6AF6'}
                    /> : null
            }
            {props.children}
        </LoadingWrap>
    );
}

Loading.defaultProps = {
    isLoading : false
}
export default Loading;