import {
    GroupInfoSettingContainer,
    GroupInfoSettingWrap,
    SettingBorder,
    SettingImgText,
    SettingImgWrap,
    SettingItem,
    SettingItemTitle,
    SettingItemValue
} from "./GroupInfoSettingStyles";
import {Button, Checkbox, DropDown} from "../global";
import React, {useEffect, useRef, useState} from "react";
import {numberToText, removeBlank} from "../../../utils/textHandler";
import env from "../../../constants/env";
import isEmpty from "is-empty";
import {useStores} from "../../../stores";
import {
    GroupCheckbox,
    GroupCheckboxDesc,
    GroupCheckboxWrap,
    GroupModalCount,
    GroupModalDetailCountWrap,
    GroupModalDetailInput,
    GroupModalDetailInputWrap,
    GroupModalNameCountWrap,
    GroupModalNameInput,
    GroupModalNameInputWrap
} from "../modal/CreateGroupModalStyles";
import UnSecretIcon from "../../../resources/icons/group_icon.svg";
import SecretIcon from "../../../resources/icons/group_icon_check.svg";
import Img from "../global/Img";
import {observer} from "mobx-react";
import {useHistory, useLocation} from "react-router-dom";
import {receiveResponse} from "../../../utils/api";
import {getBoolFromYn} from "../../../utils/boolHandler";
import {ServerConfig} from "../../../commons/config";
import {useTranslation} from "react-i18next";
import {
    InformationBtnImg,
    InformationPreBtn,
    InformationSave, InformationTabContentWrap, InformationTabWrap,
    InformationTitle,
    InformationTopWrap
} from "../../../pages/groupSetting/information/InformationStyles";
import SearchPrevBtn from "../../../resources/mImages/array_prev.svg";

const GroupInfoSetting = (props) => {

    /**
     * cover img 이미지 업로드 안한 경우에는 null로 설정
     * 그룹 생성할 때 카테고리에 매핑된 색으로 설정됨
     *
     * coverImg 없는 경우 > 색상 매핑
     * file 등록하면 coverImg null로
     *
     */
    const {store} = useStores();
    const {interestStore, modalPopupStore, userStore, toastPopupStore, modalToastPopupStore} = store;
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();
    const {groupInfo, onSubmit, onHistoryBack} = props;

    // get interests
    const getInterests = () => {
        return [
            ...interestStore.interests.filter((interest) => interest.interestId !== '0000000000').map(interest=>({
                id:interest.interestId,
                nameText : interest.nameText,
                imgId : interest.imgId
            }))
        ]
    }
    // 그룹 state (바뀐지 안바뀐지 판단하기 위함)
    const [group, setGroup] = useState(null);
    // 드롭다운 아이템
    const [items, setItems] = useState(getInterests());
    // 현재 선택된 관심사 (드롭다운)
    const [selectedInterestIndex, setSelectedInterestIndex] = useState(undefined);
    // 그룹 명
    const [groupName, setGroupName] = useState('');
    // 그룹 소개
    const [groupDetail, setGroupDetail] = useState('');
    // 공개, 비공개 여부
    const [privateYn, setPrivateYn] = useState('');
    // 대표 이미지
    const [imgId, setImgId] = useState('');
    // 커버 이미지
    const [coverImgId, setCoverImgId] = useState('');

    // 대표 이미지 파일
    const [imgFile, setImgFile] = useState(null);
    // 대표 이미지 파일 url
    const [imgFileUrl, setImgFileUrl] = useState(null);
    // 커버 이미지 파일
    const [coverImgFile, setCoverImgFile] = useState(null);
    // 파일 state
    const [file, setFile] = useState(null);
    // 제목 포커스
    const [isTitleFocus, setIsTitleFocus] = useState(false);
    // 내용 포커스
    const [isContentFocus, setIsContentFocus] = useState(false);

    // // 파일 첨부 ref
    // const fileRef = useRef();

    // 모달 드롭다운 선택
    const onSelectCategory = (index) => {
        setSelectedInterestIndex(index);
    }

    // 그룹 네임 변경 이벤트
    const onChangeGroupName = e => {
        setGroupName(e.target.value);
    }

    // 그룹 상세 변경 이벤트
    const onChangeGroupDetail = e => {
        setGroupDetail(e.target.value.replaceAll('\n', ''));
    }

    // // toggle 이벤트
    // const onTogglePrivateYnGroup = e => {
    //     if(privateYn==='Y') setPrivateYn('N');
    //     else if(privateYn==='N') setPrivateYn('Y');
    // }
    //
    // // 파일 변경 이벤트
    // const onChangeFile = (e) => {
    //     if(e.target.files.length===0){
    //         return;
    //     }
    //     setFile(e.target.files[0]);
    // };

    // 이미지 변경 이후 설정 (modal에 넘겨줌)
    const onChangeImg = (arg) => {
        if(imgFileUrl) URL.revokeObjectURL(imgFileUrl);
        // file의 형식이 string일 경우 (imgId로)
        if(typeof arg === 'string') {
            setImgId(arg);
        }
        // 그렇지 않은 경우 (File Object)
        else {
            setImgId('');
            setImgFile(arg);
            setImgFileUrl(URL.createObjectURL(arg));
        }

        toastPopupStore.openToastPopup(t('COMPLETE_CHANGE_GROUP_IMAGE'));
    }

    // 커버 이미지 변경 이후 설정
    const onChangeCoverImg = (arg) => {
        setCoverImgId(arg);
        toastPopupStore.openToastPopup(t('COMPLETE_CHANGE_GROUP_COVER'));
    }

    // 대표 이미지 수정 클릭시 모달 열기
    const openEditImageModal = () => {
        modalPopupStore.initAdd(
            'gi',null, {...group, imgFile, selectedImgId : imgId},()=>{},onChangeImg
        );
    }

    // 커버 이미지 수정 클릭시 모달 열기
    const openEditCoverImageModal = () => {
        modalPopupStore.initAdd(
            'gci',null, {...group, selectedCoverImgId : coverImgId},()=>{},onChangeCoverImg
        );
    }

    // group 초기화
    const initGroup = (group) => {

        if(!group) return;

        setSelectedInterestIndex(items.findIndex(e=>e.id === group?.interestId));
        setGroup(group);
        setGroupDetail(group.groupDetail);
        setGroupName(group.groupName);
        setImgId(group.imgId);
        setPrivateYn(group.privateYn);
        setCoverImgId(group.coverImgId);
        setImgFile(null);
        if(imgFileUrl) URL.revokeObjectURL(imgFileUrl);
        setImgFileUrl(null);
    }

    // // 편집중인지 확인
    // const canInitInfo = () => {
    //
    //     if(isEmpty(group) || isEmpty(items) || isEmpty(removeBlank(groupName)) || isEmpty(removeBlank(groupDetail))
    //         || selectedInterestIndex===undefined || isEmpty(privateYn)) return false;
    //
    //     if(group.groupName!==groupName) return true;
    //     if(group.groupDetail!==groupDetail) return true;
    //     if(group.interestId!==items[selectedInterestIndex]?.id) return true;
    //     if(group.privateYn!==privateYn) return true;
    //
    //     // 대표 이미지가 다른지 확인
    //     if(imgFile){
    //         if(group.imgId !== imgId) return true;
    //         return true;
    //     }else{
    //         if(group.imgId !== imgId) return true;
    //     }
    //
    //     // 커버 이미지가 다른지 확인
    //     if(group.coverImgId!==coverImgId) return true;
    //     return false;
    // }

    const canSave = () => {

        if(isEmpty(group) || isEmpty(items) || isEmpty(removeBlank(groupName)) || isEmpty(removeBlank(groupDetail))
            || selectedInterestIndex===undefined || isEmpty(privateYn)) return false;

        if(groupName.length > env.maxGroupNameLen) return false;
        if(groupDetail.length > env.maxGroupDetailLen) return false;

        if(group.groupName!==groupName) return true;
        if(group.groupDetail!==groupDetail) return true;
        if(group.interestId!==items[selectedInterestIndex]?.id) return true;
        if(group.privateYn!==privateYn) return true;

        // 대표 이미지가 다른지 확인
        if(imgFile){
            if(group.imgId !== imgId) return true;
            return true;
        }else{
            if(group.imgId !== imgId) return true;
        }

        // 커버 이미지가 다른지 확인
        if(group.coverImgId!==coverImgId) return true;
        return false;
    }

    // // 저장 취소 시 callback
    // const onSaveCancel = () => {
    //     if(!modalToastPopupStore.isCancel) return;
    //     initGroup(group);
    //     toastPopupStore.openToastPopup(t('INIT_GROUP_SETTING'));
    // }

    // 저장 클릭시 모달 띄우기
    const openSaveModal = () => {

        if(!canSave()) return;

        modalToastPopupStore.initDefault(
            'alert',
            t('CONFIRM_CHANGE_GROUP_INFO'),
            t('YES'),
            t('NO'),
            onSubmitHandler
        );
        modalToastPopupStore.openModalPopup();
    }

    // // 저장 취소 클릭시 모달 띄우기
    // const openSaveCancelModal = () => {
    //     modalToastPopupStore.initDefault(
    //         'alert',
    //         t('CONFIRM_INIT_CHANGE_GROUP_INFO'),
    //         t('YES'),
    //         t('NO'),
    //         onSaveCancel
    //     );
    //     modalToastPopupStore.openModalPopup();
    // }

    /**
     * ========================================================================================
     * API
     */
    const onSubmitHandler = async () => {
            if(!modalToastPopupStore.isCancel) return;
            // 설정 완료하면 페이지는 그대로, 바뀐 값으로 재설정
            const formData = new FormData();

            const _group = {
                coverImgId : coverImgId,
                groupName,
                groupDetail,
                groupType: 'M',
                interestId : items[selectedInterestIndex].id,
                privateYn
            }

            Object.keys(_group).forEach(key => formData.append(key, _group[key]));

            if(imgId) formData.append('imgId' , imgId);
            else formData.append('img',imgFile);

            await receiveResponse(formData, `groups/${group.groupId}`, 'post', {"Content-Type": "multipart/form-data"}).then(res => {
                if (res.status === 200) {
                    toastPopupStore.openToastPopup(t('COMPLETE_GROUP_SETTING'));
                    userStore.updateJoinGroups();
                    // getGroupInfo();
                    if(onSubmit && typeof onSubmit === 'function') onSubmit();
                } else {
                    toastPopupStore.openToastPopup(t('FAIL_CHANGE_GROUP_INFO'));
                }
            });
        }

    useEffect(()=>{
        let items = getInterests();
        setItems(items);
        setSelectedInterestIndex(items.findIndex(e=>e.id === group?.interestId));
    },[interestStore.interests])

    useEffect(()=>{
        initGroup(props.groupInfo);
    },[props.groupInfo]);

    useEffect(()=>{
        return ()=>{if(imgFileUrl) URL.revokeObjectURL(imgFileUrl)}
    },[]);

    return(
        <>
            {/* 그룹관리 X */}
            <InformationTopWrap>
                <InformationPreBtn onClick={(e)=>{ if(onHistoryBack && typeof onHistoryBack === 'function') onHistoryBack();}}>
                    <InformationBtnImg src={SearchPrevBtn}/>
                </InformationPreBtn>
                <InformationTitle>{t("GROUP_INFORMATION_SETTING")}</InformationTitle>
                <InformationSave className={canSave() ? 'on' : ''}
                                 onClick={openSaveModal}
                >저장</InformationSave>
            </InformationTopWrap>

            <InformationTabWrap>
                <InformationTabContentWrap>

                    <GroupInfoSettingContainer>
                        {/*그룹 정보 설정*/}
                        <GroupInfoSettingWrap>

                            <SettingItem>
                                {/*카테고리*/}
                                <SettingItemTitle>{t("GROUP_CATEGORY")}</SettingItemTitle>
                                <SettingItemValue width={'100%'}>
                                    <DropDown
                                        width={'100%'}
                                        items={items}
                                        selectedIndex={selectedInterestIndex}
                                        onSelect={onSelectCategory}
                                    />
                                </SettingItemValue>
                            </SettingItem>

                            <SettingItem>
                                {/*그룹명*/}
                                <SettingItemTitle>{t("GROUP_NAME")}</SettingItemTitle>
                                <SettingItemValue width={'100%'}>
                                    <GroupModalNameInputWrap className={isTitleFocus ? 'focus' : ''} disabled={groupName?.length > env.maxGroupNameLen}>
                                        <GroupModalNameInput
                                            placeholder={t('CREATE_GROUP_NAME_PLACEHOLDER')}
                                            value={groupName}
                                            onChange={onChangeGroupName}
                                            onFocus={e=>setIsTitleFocus(true)}
                                            onBlur={e=>setIsTitleFocus(false)}
                                        />
                                        <GroupModalNameCountWrap>
                                            <GroupModalCount entered={groupName?.length || 0 !== 0}
                                                             disabled={
                                                                 groupName?.length > env.maxGroupNameLen}>{groupName.length}</GroupModalCount>
                                            {`/${env.maxGroupNameLen}`}
                                        </GroupModalNameCountWrap>
                                    </GroupModalNameInputWrap>
                                </SettingItemValue>
                            </SettingItem>

                            <SettingItem>
                                {/*그룹소개*/}
                                <SettingItemTitle>{t("GROUP_INTRODUCE")}</SettingItemTitle>
                                <SettingItemValue width={'100%'}>
                                    <GroupModalDetailInputWrap className={isContentFocus ? 'focus' : ''} disabled={groupDetail?.length > env.maxGroupDetailLen}>
                                        <GroupModalDetailInput
                                            placeholder={t('CREATE_GROUP_DETAIL_PLACEHOLDER')}
                                            value={groupDetail}
                                            onChange={onChangeGroupDetail}
                                            onFocus={e=>setIsContentFocus(true)}
                                            onBlur={e=>setIsContentFocus(false)}
                                        />
                                        <GroupModalDetailCountWrap>
                                            <GroupModalCount entered={groupDetail?.length || 0 !== 0}
                                                             disabled={
                                                                 groupDetail?.length > env.maxGroupDetailLen}>{numberToText(groupDetail?.length || 0)}</GroupModalCount>
                                            {`/${numberToText(env.maxGroupDetailLen)}`}
                                        </GroupModalDetailCountWrap>
                                    </GroupModalDetailInputWrap>
                                </SettingItemValue>
                            </SettingItem>

                            {/*공개 여부*/}
                            <SettingItem>
                                <SettingItemTitle>{t("GROUP_OPEN_YN")}</SettingItemTitle>
                                <SettingItemValue width={'100%'}>
                                    <GroupCheckboxWrap>
                                        <GroupCheckbox>
                                            <Checkbox
                                                checked={!getBoolFromYn(privateYn)}
                                                label={t("CREATE_GROUP_OPEN")}
                                                icon={UnSecretIcon}
                                                checkedIcon={SecretIcon}
                                                onCheck={e=>setPrivateYn('N')}
                                            />
                                            <GroupCheckboxDesc>{`(${t('JOIN_IMMEDIATELY')})`}</GroupCheckboxDesc>
                                        </GroupCheckbox>
                                        <GroupCheckbox>
                                            <Checkbox
                                                checked={getBoolFromYn(privateYn)}
                                                label={t("CREATE_GROUP_CLOSE")}
                                                icon={UnSecretIcon}
                                                checkedIcon={SecretIcon}
                                                onCheck={e=>setPrivateYn('Y')}
                                            />
                                            <GroupCheckboxDesc>{`(${t('REQUIRE_MANAGER_CONFIRM')})`}</GroupCheckboxDesc>
                                        </GroupCheckbox>
                                    </GroupCheckboxWrap>
                                </SettingItemValue>
                            </SettingItem>

                            <SettingBorder/>

                            {/*대표 이미지*/}
                            <SettingItem>
                                <SettingItemTitle>{t('REP_IMAGE')}</SettingItemTitle>
                                <SettingItemValue>
                                    <SettingImgWrap>
                                        <Img
                                            src={
                                                imgId ?
                                                    `${ServerConfig.default.IMG_URL}${imgId}` :
                                                    imgFile ? imgFileUrl : null
                                            }
                                            wrapStyle={{width : '100px', height : '100px', flex : 'none'}}
                                        />
                                        <Button
                                            width={'93px'}
                                            height={'36px'}
                                            type={'secondary'}
                                            shape={'circle'}
                                            textStyle={{fontSize: '12px'}}
                                            onClick={openEditImageModal}
                                        >{t('MODIFY_REP_IMAGE')}</Button>
                                        <SettingImgText>
                                            {t('RECOMMENDING_IMAGE_SIZE')}
                                        </SettingImgText>
                                    </SettingImgWrap>
                                </SettingItemValue>
                            </SettingItem>

                            {/*커버 색상*/}
                            <SettingItem>
                                <SettingItemTitle>{t("GROUP_COVER_COLOR")}</SettingItemTitle>
                                <SettingItemValue>
                                    <SettingImgWrap>
                                        <Img
                                            src={`${ServerConfig.default.IMG_URL}${coverImgId}`}
                                            wrapStyle={{width : '100%', height : '81px', flex : 'none', border : '1px solid #E5EBF0', borderRadius : '6px'}}
                                            imgStyle={{width : '100%', height : '100%'}}
                                        />
                                        <Button
                                            width={'93px'}
                                            height={'36px'}
                                            type={'secondary'}
                                            shape={'circle'}
                                            textStyle={{fontSize: '12px'}}
                                            onClick={openEditCoverImageModal}
                                        >{t('MODIFY_COVER_COLOR')}</Button>
                                        {/*<SettingImgText>*/}
                                        {/*    {'권장 이미지 사이즈'}*/}
                                        {/*    <br/>*/}
                                        {/*    {'1117*260px'}*/}
                                        {/*</SettingImgText>*/}
                                    </SettingImgWrap>
                                </SettingItemValue>
                            </SettingItem>

                        </GroupInfoSettingWrap>

                    </GroupInfoSettingContainer>
                </InformationTabContentWrap>
            </InformationTabWrap>
        </>
    )
}

GroupInfoSetting.defaultProps = {
    groupInfo : null,
    onSubmit : null,
    onHistoryBack: null
}

export default observer(GroupInfoSetting);