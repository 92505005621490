import {ModalContainer} from "../../global/ModalStyles";
import Item from "../../../../pages/item/Item";
import {useLayoutEffect, useRef} from "react";
import {useStores} from "../../../../stores";

const ItemDetail = (props) => {

    const {onClose, itemId, onDelete, onError, type, page, isOpen} = props;

    const {store} = useStores();
    const {modalDetailStore} = store;
    const containerRef = useRef(null);
    const scrollRef = useRef(null);

    const onDeleteHandler = (e) => {
        if(onDelete && typeof onDelete === 'function'){
            //window.History.Back 뒤로가기 (모달 window)
            onDelete(itemId);
        }
    }

    const onErrorHandler = (e) => {
        if(onError && typeof onError === 'function'){
            //window.History.Back 뒤로가기 (모달 window)
            onError();
        }
    }

    const onClickModalContainer = (e) => {
        if(e.target === containerRef.current){
            onClose(e);
        }
    };


    return(
        <ModalContainer ref={containerRef} className={!isOpen ? 'hidden' : null} onClick={onClickModalContainer}>
            <Item
                isOpen={modalDetailStore.isOpen}
                onClose={onClose}
                page={page}
                type={type}
                itemId={itemId}
                onDelete={onDeleteHandler}
                onError={onErrorHandler}
            />
        </ModalContainer>
    )
}

ItemDetail.defaultProps = {
    onClose : null,
    onDelete : null,
    onError :  null
}

export default ItemDetail;